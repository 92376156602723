export enum EXPORT_OPTIONS {
  EXPORT_XLS = 'Export All (XLS)',
  EXPORT_CSV = 'Export All (CSV)',
}

export const CYBER_ARK_FRAMEWORKS = ['CAIQ', 'CAIQ_V1'];

//* Email type ENUM
export enum EMAIL_TYPE {
  NOTIFY_USER_NEW_TASK_ASSIGN = 'NOTIFY_USER_NEW_TASK_ASSIGN',
}
