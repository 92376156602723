import { Component, Output, EventEmitter, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'cygov-username-icon',
  templateUrl: './username-icon.component.html',
  styleUrls: ['./username-icon.component.scss'],
})
export class UsernameIconComponent implements OnInit {
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeUser: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() height = '3';
  @Input() username = '';
  @Input() bgColor = 'black';
  @Input() document = false;
  @Input() docFormat = '';
  @Input() deleteFlag = false;
  @Input() isManager = false;
  @Input() isSelected = false;
  @Input() dotInitials = true;
  @Input() greyOutColor = false;
  @Input() integrationUser;
  @Input() highlightUser: boolean = false;
  @Input() showInitials: boolean = true;
  @Input() currentLoggedInUser = false;

  // integration's
  intHeight = '3';

  letterSpacing = true;
  textSize = 14;
  docColor = {
    doc: '#155576',
    xls: '#03cf70',
    csv: '#007f44',
    pdf: '#5808B5',
    zip: '#B93293',
    jpg: '#b10540',
    jpe: '#b10540',
    png: '#BA791E',
    gif: '#e3b809',
  };
  initials = '';
  @Input() crossWidth: string;

  constructor() {}

  ngOnInit(): void {
    this.initializeInitials();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.username && !changes.username.firstChange) {
      this.initials = '';
      this.initializeInitials();
    }
  }

  calculateTextSize(): void {
    const iconSize = Math.round(parseFloat(this.height));
    this.textSize = iconSize + 7 + (iconSize - 1);
  }
  initializeInitials(): void {
    this.crossWidth = this.crossWidth ? this.crossWidth : String((parseFloat(this.height) / 6) * 2.5);
    if (!this.document) {
      const subNamesList = this.username
        ? this.username
            .split(' ')
            .filter(value => value)
            .filter((value, index) => index < 2)
        : [];
      subNamesList.forEach(value => {
        this.initials += value[0];
        if (this.dotInitials && subNamesList.length > 1) {
          this.initials += '.';
          this.dotInitials = false;
          this.letterSpacing = false;
        }
      });
    } else {
      const x = this.docFormat.split('.');
      const y = x[x.length - 1];
      if (y.length >= 4) {
        this.initials = y.substring(0, 3);
      } else {
        this.initials = y;
      }
      this.bgColor = this.docColor[this.initials];
    }

    this.calculateTextSize();
  }

  removeAssignedUser(): void {
    this.removeUser.emit(true);
  }
}
