<div *ngIf="!isCollection" class="ui-tools" [ngClass]="{ multiEntity: isMultiEntityRoute }">
  <div class="search-and-filters">
    <div *ngIf="!isGroupView" class=" lato-16-n-vw title">{{isMultiEntityRoute? 'MULTI
      ENTITY'
      : 'MANAGEMENT'}}</div>

    <div *ngIf="isGroupView" class="lato-16-n-vw title">Group View</div>

    <div class="filter-by">
      <label class="lato-16-n-vw" for="filter-select">Filter by</label>
      <cygov-select id="filter-select" [ngClass]="{ 'cross-btn-margin': isGroupView }"
        [items]="filterOptions" placeholder="All"
        [customArrow]="isGroupView || isMultiEntityRoute || isFromManagement"
        [(ngModelValue)]="filterBy" (ngModelValueChange)="filterChanged($event)">
      </cygov-select>
    </div>

    <div class="sort-by">
      <label class="lato-16-n-vw" for="sort-select">Sort by</label>
      <cygov-select id="sort-select" [items]="sortOptions" placeholder="All"
        [customArrow]="isGroupView || isMultiEntityRoute || isFromManagement"
        [ngClass]="{ 'cross-btn-margin': isGroupView }" [(ngModelValue)]="sortBy"
        (ngModelValueChange)="sortChanged(sortBy)"></cygov-select>
    </div>
    <div class="search-wrapper">
      <div class="search-icon" cygovSvgIcon [svgName]="'new-search-icon'"></div>
      <input type="text" class="form-control search" aria-label="Search" placeholder="Search"
        name="search" [(ngModel)]="queryTextfromInput"
        (keyup)="inputBasedSearch(queryTextfromInput)" spellcheck="true" />
    </div>

    <div class="show-average-box" *ngIf="(averageScore || averageScore===0) && showAvgBox">
      <cygov-average-box [average]="averageScore" [total]="10"></cygov-average-box>
    </div>
  </div>
  <div class="view-layout">
    <div class="list-layout" *ngIf="showLayoutBtns">
      <div class="view-icon" cygovSvgIcon [svgName]="'cards-icon'" ngbTooltip="Grid View"
        placement="top" (click)="viewChanged(true)" [ngClass]="{ active: cardView }"></div>
      <div class="view-icon" cygovSvgIcon [svgName]="'list-icon'" ngbTooltip="List View"
        placement="top" (click)="viewChanged(false)" [ngClass]="{ active: !cardView }"></div>
      <!-- old implementation of delete client -->
      <!-- <div class="delete-icon" cygovSvgIcon [svgName]="'trash-icon-3'"
        *ngIf="(showAddMPL || showDeleteEntity) && !isMultiEntityRoute" cygovUserPermission (click)="showDeletePopUp()">
      </div> -->
      <div class="edit-icon" cygovSvgIcon [svgName]="'edit-icon'"
        *ngIf="(showAddMPL || showDeleteEntity) && !isMultiEntityRoute && !isEditMode"
        cygovUserPermission (click)="enableEditMode()">
      </div>
      <div class="cross-icon" cygovSvgIcon [svgName]="'x-icon-type2'" *ngIf="isEditMode"
        (click)="disableEditMode()">
      </div>
    </div>
    <button class="btn-type-link add-client-btn"
      *ngIf="showAddEntity && !multiEntityMode && !groupViewMode"
      (click)="showAddEntityNew ? addNewClientModal() : addClientModal()" [disabled]="!isAdmin"
      cygovUserPermission>
      <div *ngIf="!isMultiEntityRoute" class="add-client-icon" cygovSvgIcon
        [svgName]="'add-client-icon'"></div>
      <div *ngIf="isMultiEntityRoute && !multiEntityMode && !groupViewMode"
        class="lato-18-n-vw status-circle">+
      </div>

      <span *ngIf="!multiEntityMode &&!isGroupView && !groupViewMode"
        [ngClass]="{ isMultiEntity: isMultiEntityRoute }"
        class="lato-18-n-vw capitalize add-client-text">{{isMultiEntityRoute? 'New Entity' :
        'Add Client'}}</span>

      <span *ngIf="isGroupView && !groupViewMode"
        [ngClass]="{ 'isMultiEntity': isMultiEntityRoute }"
        class="lato-18-n-vw capitalize add-client-text">{{'New'}}
      </span>
    </button>



    <button class="btn-type-link add-client-btn" *ngIf="showAddMPL" (click)="addEntityMPLModal()">
      <div class="add-client-icon" cygovSvgIcon [svgName]="'add-client-icon'"></div>
      <span class="lato-18-n-vw capitalize">New</span>
    </button>
  </div>
</div>

<div *ngIf="isCollection" class="ui-tools p-0">
  <div class="search-and-filters">
    <div class="lato-16-n-vw uppercase mr-120 sub-entity-name">
      Collection Overview
    </div>
    <cygov-select [items]="filterOptions" placeholder="All" [(ngModelValue)]="filterBy"
      (ngModelValueChange)="filterChanged($event)">
    </cygov-select>
  </div>
</div>