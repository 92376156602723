import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersSettingsComponent } from './users-settings.component';
import { CircularProgressModule } from '../shared/circular-progress/circular-progress.module';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProgressBarModule } from '../shared/progress-bar/progress-bar.module';
import { UsersSettingsRoutes } from './users-settings.routes';
import { UiToolsModule } from '../shared/ui-tools/ui-tools.module';
import { CygovLoaderModule } from '../shared/cygov-loader/cygov-loader.module';
import { EntityLeaderRoleGuard } from 'app/shared/guards/entity-leader-role.guard';
import { SubEntityLeaderRoleGuard } from 'app/shared/guards/sub-entity-leader-role.guard';
import { UserListComponent } from './all-user-list/user-list/user-list.component';
import { UserListHeaderComponent } from './all-user-list/user-list-header/user-list-header.component';
import { UserItemComponent } from './all-user-list/user-item/user-item.component';
import { CheckboxModule } from '../shared/checkbox/checkbox.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SelectModule } from '../shared/select/select.module';
import { NgbModalModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UserActionsModule } from '../shared/user-actions/user-actions.module';
import { AddUserSettingsModalModule } from '../shared/add-user-settings-modal/add-user-settings-modal.module';
import { UsernameIconModule } from '../shared/username-icon/username-icon.module';
import { CygovButtonModule } from 'app/shared/cygov-button/cygov-button.module';
import { RolePermissionComponent } from './role-permission/role-permission.component';
import { UserPermissionModule } from 'app/shared/user-permission/user-permission.module';
import { CustomDropDownModule } from 'app/shared/custom-drop-down/custom-drop-down.module';
import { ToggleButtonModule } from 'app/shared/toggle-button/toggle-button.module';
import { DomainFrameworksListingsModule } from 'app/shared/domain-frameworks-listings/domain-frameworks-listings.module';
import { NewFrameworkRequestModalComponent } from './new-framework-request-modal/new-framework-request-modal.component';
import { ThirdPartyModule } from 'app/third-party/third-party.module';

@NgModule({
  declarations: [
    UsersSettingsComponent,
    UserListComponent,
    UserListHeaderComponent,
    UserItemComponent,
    RolePermissionComponent,
    NewFrameworkRequestModalComponent,
  ],
  imports: [
    UsernameIconModule,
    CommonModule,
    FormsModule,
    RouterModule,
    SvgIconModule,
    CheckboxModule,
    NgbTooltipModule,
    SelectModule,
    CygovButtonModule,
    ProgressBarModule,
    AddUserSettingsModalModule,
    CircularProgressModule,
    UiToolsModule,
    PipesModule,
    InfiniteScrollModule,
    UsersSettingsRoutes,
    UserActionsModule,
    CygovLoaderModule,
    PipesModule,
    NgbModalModule,
    CygovButtonModule,
    ReactiveFormsModule,
    UserPermissionModule,
    NgbModule,
    CustomDropDownModule,
    ToggleButtonModule,
    DomainFrameworksListingsModule,
    // ThirdPartyModule,
  ],
  providers: [EntityLeaderRoleGuard, SubEntityLeaderRoleGuard, FormBuilder],
})
export class UsersSettingsModule {}
