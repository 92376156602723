<div class="modal-body">
    <div class="modal-header">
        <div class="message">ADDING HITRUST FRAMEWORK</div>
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
            (click)="setModalResult(false)">
        </div>
    </div>
    <div class="body">
        <div class="message-row message-margin">
            <span class="lato-20-n-vw"> In order to add <span class="framework-name">HITRUST</span>
                framework please
                upload your csv file</span>
        </div>
        <div class="btns-group">
            <button class="btn btn-confirm" (click)="setModalResult(true)"><span
                    class="lato-14-n-vw txt-white">Upload</span></button>
            <button class="btn btn-cancel" (click)="setModalResult(false)"><span
                    class="lato-14-n-vw txt-white">cancel</span></button>
        </div>
        <div class="footer">
            <span class="lato-12-n-vw">
                *By clicking on the upload button, I confirm that I have the necessary license to
                use this framework
            </span>
        </div>
    </div>
</div>