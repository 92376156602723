import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { UtilsService } from '../utils.service';

@Directive({
  selector: '[cygovScoreColor]',
})
export class ScoreColorDirective implements OnChanges {
  @Input() score: number;
  @Input() isText: boolean;
  @Input() maxScore: number = 10;
  @Input() removeIsTextDependency: boolean = false;
  @Input() isFromSmartMapping: boolean = false;
  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const score: string = changes.score.currentValue;
    const isText: boolean = changes.isText ? changes.isText.currentValue : false;
    // isDefined
    if (!(typeof score === 'undefined' || score === null)) {
      if (UtilsService.isBnB) {
        this.addScoreClassBnb(score, this.removeIsTextDependency ? false : isText);
      } else if (UtilsService.isCyberArk) {
        this.addScoreClassCyberArk(score, this.removeIsTextDependency ? false : isText);
      } else {
        this.addScoreClass(score, this.removeIsTextDependency ? false : isText);
      }
    }
  }

  private addScoreClass(score, isText) {
    let className = null;
    const s = score / this.maxScore;
    if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
      className = this.beecherDomainColors(score);
    } else if (this.isFromSmartMapping) {
      switch (true) {
        case s === 0:
          className = 'score-low';
          break;
        default:
          className = 'score-high';
          break;
      }
    } else {
      switch (true) {
        case s >= 98 && s <= 100:
          className = '';
          break;
        case s <= 0.33:
          className = 'score-low';
          break;
        case s < 0.66:
          className = 'score-medium';
          break;
        case s >= 0.66:
          className = 'score-high';
          break;
      }
    }
    className += isText ? '-stroke' : '';
    // removing previous classes
    const modes = ['score-low', 'score-medium', 'score-high'];
    modes.forEach(mode => {
      const classN = mode + '-stroke';
      this.renderer.removeClass(this.element.nativeElement, mode);
      this.renderer.removeClass(this.element.nativeElement, classN);
    });
    // adding the new class
    this.renderer.addClass(this.element.nativeElement, className);
  }

  /**
   * Setting color for scores in CyberArk
   * @param score
   * @param isTExt
   * @returns - ClassName; Low, Medium or High
   */
  private addScoreClassCyberArk(score, isText) {
    let className = null;
    switch (true) {
      case score > 9:
        className = 'cyberArk-score-high';
        break;
      case score > 5:
        className = 'cyberArk-score-medium';
        break;
      case score >= 0:
        className = 'cyberArk-score-low';
        break;
    }

    className += isText ? '-stroke' : '';
    // removing previous classes
    const modes = ['cyberArk-score-low', 'cyberArk-score-medium', 'cyberArk-score-high'];
    modes.forEach(mode => {
      const classN = mode + '-stroke';
      this.renderer.removeClass(this.element.nativeElement, mode);
      this.renderer.removeClass(this.element.nativeElement, classN);
    });
    // adding the new class
    this.renderer.addClass(this.element.nativeElement, className);
  }

  private addScoreClassBnb(score, isText) {
    let className = null;
    const s = score / this.maxScore;
    switch (true) {
      case s <= 0.4:
        className = 'bnb-score-lowest';
        break;
      case s <= 0.6:
        className = 'bnb-score-low';
        break;
      case s <= 0.8:
        className = 'bnb-score-medium';
        break;
      case s < 1:
        className = 'bnb-score-high';
        break;
      case s === 1:
        className = 'bnb-score-highest';
        break;
    }
    className += isText ? '-stroke' : '';
    // removing previous classes
    const modes = ['bnb-score-lowest', 'bnb-score-low', 'bnb-score-medium', 'bnb-score-high', 'bnb-score-highest'];
    modes.forEach(mode => {
      const classN = mode + '-stroke';
      this.renderer.removeClass(this.element.nativeElement, mode);
      this.renderer.removeClass(this.element.nativeElement, classN);
    });
    // adding the new class
    this.renderer.addClass(this.element.nativeElement, className);
  }

  /**
   * Setting color for scores in Beecher Domain
   * @param score
   * @returns - ClassName; Low, Medium or High
   */
  private beecherDomainColors(score) {
    let className = 'score-';
    switch (true) {
      case score < 7:
        className += 'low';
        break;
      case score < 8:
        className += 'medium';
        break;
      case score >= 8:
        className += 'high';
        break;
    }
    return className;
  }
}
