import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TableHeader, TableHeaderState } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteUserModalComponent } from '../../../shared/user-actions/delete-user-modal/delete-user-modal.component';
import { GetUserQuery, UpdateUserInput } from '../../../API.service';
import { InvitationSentModalComponent } from '../../../shared/user-actions/invitation-sent-modal/invitation-sent-modal.component';
import { AuthService } from '../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../../../shared/utils.service';
import { RoleEnum } from 'app/API.service';
import pluralize from 'pluralize';
import { UsersSettingConstant } from './../../users-setting.constant';
import { IUserData } from '../user-list/user-list.component';

type ModifiedGetUserQuery = {
  access: string;
  isSelected: boolean;
  user: GetUserQuery;
};

@Component({
  selector: 'cygov-user-list-header',
  templateUrl: './user-list-header.component.html',
  styleUrls: ['./user-list-header.component.scss'],
})
export class UserListHeaderComponent implements OnInit {
  @Input() selectedUsers: ModifiedGetUserQuery[];
  @Input() userList: IUserData[];
  @Input() isEditMode: boolean;
  @Output() onSortChanged = new EventEmitter<any>();
  @Output() onUnselectAll = new EventEmitter<any>();
  @Output() onDeleted = new EventEmitter<any[]>();

  headers: TableHeader[];
  headerState: TableHeaderState;
  isInviteSent: boolean;
  sortDirections = SortDirectionEnum;
  currentSortedColumn = 'Name';
  vendorUsers: IUserData[];
  // editAbleFields = ['name', 'role', 'permission', 'access'];
  editAbleFields = [];

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.headerState = new TableHeaderState();
    this.headerState.setInitialHeaders(this.getHeadersData());
    this.isInviteSent = false;
  }

  ngOnInit(): void {
    // Getting vendor users as we cannot delete last vendor user
    this.vendorUsers = this.userList.filter(userObj => {
      if (userObj.user && userObj.user.role && userObj.user.role === RoleEnum.VENDOR) {
        return userObj;
      }
    });
  }

  getHeadersData(): any {
    return UsersSettingConstant.headerData;
  }

  isEditable(name: string): boolean {
    return this.editAbleFields.includes(name.toLowerCase());
  }
  onHeaderClicked(header: TableHeader) {
    if (header && header.isSortApplicable) {
      this.currentSortedColumn = header.name;
      this.headerState.setHeaderState(header);
      this.onSortChanged.emit(header);
    }
  }

  onClickDelete() {
    const modalRef = this.modalService.open(DeleteUserModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'delete-modal',
      backdropClass: 'delete-backdrop-modal',
    });

    modalRef.componentInstance.modalResult.subscribe((newUser: UpdateUserInput) => {
      console.log(newUser);
      this.deleteUsers(this.selectedUsers).then(() => {
        modalRef.close();
      });
    });

    modalRef.componentInstance.users = this.selectedUsers;
  }

  /**
   *To check there are more than one user for vendor. If not, then don't delete that user
   */
  checkUserCount(users): any {
    users = users.filter(userObj => {
      if (userObj.user && userObj.user.role && userObj.user.role === RoleEnum.VENDOR) {
        let count = 0;
        let vendorObj;
        for (const vendor of this.vendorUsers) {
          if (userObj.user.entityId === vendor.user.entityId) {
            vendorObj = vendor;
            count = count + 1;
          }
        }
        const index = this.vendorUsers.indexOf(vendorObj);
        this.vendorUsers.splice(index, 1);
        if (count >= 2) {
          return userObj;
        }
      } else {
        return userObj;
      }
    });
    return users;
  }

  async deleteUsers(users: any[]) {
    try {
      users = this.checkUserCount(users);
      console.log(users);
      this.toastr.info(`Deleting ${pluralize('user', users.length)}...`);
      await Promise.all(users.map(async ({ user: { id } }) => await this.authService.removeUser(id)));
      this.toastr.success(`${pluralize('User', users.length)} deleted!`);
      if (users.length !== this.selectedUsers.length) {
        this.toastr.info('Cannot delete last vendor user.');
      }
      this.onDeleted.emit(users);
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  onClickInvite() {
    if (this.selectedUsers.length > 1) {
      this.selectedUsers.forEach(selectedUser => {
        if (selectedUser.user.isCognitoUser) {
          this.toastr.info('Some Users are registered Already. Unselect them first');
        }
      });
    } else if (this.selectedUsers[0].user.isCognitoUser) {
      this.toastr.info('Already sent invite to this user');
    } else {
      this.toastr.info('Sending invite...');
      this.inviteUsers(this.selectedUsers).then(() => {
        if (this.isInviteSent) {
          const modalRef = this.modalService.open(InvitationSentModalComponent, {
            centered: true,
            size: 'lg',
            windowClass: 'invitation-sent-modal',
            backdropClass: 'invitation-sent-backdrop-modal',
          });

          modalRef.result.then(() => {
            this.onUnselectAll.emit();
          });

          modalRef.componentInstance.users = this.selectedUsers;
        }
      });
    }
  }

  async inviteUsers(users: any[]) {
    this.isInviteSent = true;
    try {
      await Promise.all(
        users.map(async ({ user: { id, email, name } }) => {
          await this.authService.sendInviteEmail(id, email, name);
        })
      );
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.isInviteSent = false;
      this.toastr.error(message);
    }
  }
}
