import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  transform(items: any[], value: any, column = null): any {
    if (!items) {
      return items;
    }
    // array of objects
    if (column) {
      return items.filter(item => item[column] === value);
    } else {
      // array of string or integer etc
      return items.filter(item => item === value);
    }
  }
}
