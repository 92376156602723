import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-jira-confirmation-modal',
  templateUrl: './jira-confirmation-modal.component.html',
  styleUrls: ['./jira-confirmation-modal.component.scss'],
})
export class JiraConfirmationModalComponent implements OnInit {
  @Input() message = 'Are you sure?';
  @Input() customOptionButtons = [];
  @Output() modalResult = new EventEmitter<boolean>();

  // optionButtons = ['Yes', 'No'];
  optionsCount = 0;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.optionsCount = this.customOptionButtons ? this.customOptionButtons.length : 0;
  }
}
