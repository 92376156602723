import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UploadStateEnum } from './upload-file.enum';
import { FileService } from 'app/shared/file.service';
import { GetUserQuery } from 'app/API.service';
import * as JSZip from 'jszip';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  uploadState: BehaviorSubject<string>;
  uploadProcessComplete = new BehaviorSubject<boolean>(false);
  currentUser: GetUserQuery;
  public readableObject = new Subject<any[]>();
  file = new BehaviorSubject<any>(null);
  isFake: boolean = false;
  supportZip: JSZip;

  constructor(
    private fileService: FileService,
    private userService: UserService
  ) {
    this.uploadState = new BehaviorSubject<string>(UploadStateEnum.UPLOAD_NOT_STARTED);
  }

  setUploadState(state: string): void {
    this.uploadState.next(state);
  }

  setFile(file: any): void {
    this.file.next(file);
  }

  getFile(): BehaviorSubject<any> {
    return this.file;
  }

  setReadableObjectArray(obj: any[]): void {
    this.readableObject.next(obj);
  }

  setProcessComplete(state: boolean) {
    this.uploadProcessComplete.next(state);
  }

  getProcessComplete(): BehaviorSubject<boolean> {
    return this.uploadProcessComplete;
  }

  getUploadState(): BehaviorSubject<string> {
    return this.uploadState;
  }

  async getFileFromPath(path: string): Promise<any | string> {
    return await this.fileService.downloadFileFromS3(path);
  }

  async setCurrentUser(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser();
  }

  getCurrentUser(): GetUserQuery {
    return this.currentUser;
  }

  setUploadType(status: boolean) {
    this.isFake = status;
  }

  getUploadType(): boolean {
    return this.isFake;
  }

  setSupportZip(zip: JSZip): void {
    this.supportZip = zip;
  }

  getSupportZip(): JSZip {
    return this.supportZip;
  }

  resetEverything(): void {
    this.uploadState.next(UploadStateEnum.UPLOAD_NOT_STARTED);
    this.uploadProcessComplete.next(false);
    this.file.next(null);
  }
}
