import { SelectModule } from './../select/select.module';
import { CustomDropDownModule } from './../custom-drop-down/custom-drop-down.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserSettingsModalComponent } from './add-user-settings-modal.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FormsModule } from '@angular/forms';
import { CygovButtonModule } from '../cygov-button/cygov-button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    FormsModule,
    SelectModule,
    CygovButtonModule,
    CheckboxModule,
    CustomDropDownModule,
  ],
  declarations: [AddUserSettingsModalComponent],
  exports: [AddUserSettingsModalComponent],
})
export class AddUserSettingsModalModule {}
