import { Component, Input, OnInit } from '@angular/core';
import { ReassessmentEnum, ScoresInput } from 'app/API.service';

@Component({
  selector: 'cygov-entity-progress-bars',
  templateUrl: './entity-progress-bars.component.html',
  styleUrls: ['./entity-progress-bars.component.scss'],
})
export class EntityProgressBarsComponent implements OnInit {
  @Input() scores: ScoresInput;
  @Input() height: number;
  @Input() horizontalView = false;
  @Input() isParticipant = false;
  @Input() showReassessmentScores = ReassessmentEnum.NOT_INITIATED;
  @Input() archiveMode = false;
  reassessmentEnum = ReassessmentEnum;

  constructor() {}

  ngOnInit(): void {
    if (!this.height) {
      this.height = 20;
    }
  }
}
