import { ConfirmationModalModule } from './../confirmation-modal/confirmation-modal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsComponent } from './questions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { PipesModule } from '../pipes/pipes.module';
import { DeniedModalComponent } from './denied-modal/denied-modal.component';
import { SelectModule } from '../select/select.module';
import { QuestionCardUiComponent } from './question-card-ui/question-card-ui.component';
import { UsernameIconModule } from 'app/shared/username-icon/username-icon.module';
import { FileIconModule } from 'app/shared/file-icon/file-icon.module';
import { CygovSliderModule } from '../cygov-slider/cygov-slider.module';
import { CygovLoaderModule } from '../cygov-loader/cygov-loader.module';
// moved from app.module - build-optimization-1
import { SunburstService } from 'app/break-down/sunburst.service';
import { CygovButtonModule } from '../cygov-button/cygov-button.module';

@NgModule({
  providers: [SunburstService],
  declarations: [QuestionsComponent, DeniedModalComponent, QuestionCardUiComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    SvgIconModule,
    PipesModule,
    ConfirmationModalModule,
    NgbModule,
    SelectModule,
    UsernameIconModule,
    FileIconModule,
    CygovSliderModule,
    CygovLoaderModule,
    CygovButtonModule,
  ],
  exports: [QuestionsComponent, QuestionCardUiComponent],
})
export class QuestionsModule {}
