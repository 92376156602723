import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';
import * as JSZip from 'jszip';
import { FileTypeEnum } from 'app/API.service';
import { NGXLogger } from 'ngx-logger';
import { FileService } from '../file.service';
@Component({
  selector: 'cygov-mid-market-file-upload',
  templateUrl: './mid-market-file-upload.component.html',
  styleUrls: ['./mid-market-file-upload.component.scss'],
})
export class MidMarketFileUploadComponent implements OnInit {
  @Output() closeUpload = new EventEmitter<boolean>();
  @Output() closeModalClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveFiles = new EventEmitter<any>();
  @Output() removeFile = new EventEmitter<any>();
  @Input() existingFiles: any = [];
  uploader: FileUploader = new FileUploader({ url: 'path_to_api' });
  startUpload = true;
  progressBars = {};
  zipSaved = false;
  isBnbCyberSite = UtilsService.isBnBCyberSite;
  isValidate: boolean = false;
  zipFiles: any = [];
  //* Mid market allowed file extensions for artifacts
  midMarketAllowedFileExtensions: string[] = ['doc', 'docx', 'pdf', 'txt', 'jpg', 'jpeg', 'png'];

  constructor(
    private toastr: ToastrService,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {}
  fileValidation(file: File): boolean {
    if (!FileService.validateFileType(file, FileTypeEnum.MIDMARKET_FILES)) {
      this.toastr.info(
        `Only the following file types are supported: ${this.midMarketAllowedFileExtensions.join(', ')}.`
      );
      return false;
    }
    if (FileService.validateFileSize(file)) {
      const maxFileSizeInMB = FileService.fileSize / 1048576;
      this.toastr.info(`File size cannot be greater than ${maxFileSizeInMB} MB.`);
      return false;
    } else {
      return true;
    }
  }
  handleFileInput(files: File[]): void {
    this.zipSaved = false;
    for (const file of files) {
      this.isValidate = this.fileValidation(file);
      if (this.isValidate) {
        // this filteration is for uploader queue
        // uploader queue is maintaining the cache of unwanted files too that's why we need to remove unwanted files
        const arr = this.uploader.queue.filter((f: any) => {
          const ext = /\.([^.]+)$/.exec(f.file.name)[1];
          if (this.midMarketAllowedFileExtensions.includes(ext)) {
            return f.file;
          }
        });
        this.uploader.queue = arr;
        this.progressBars = { ...this.progressBars, ...{ [file.name]: -1 } };
        this.calculateProgress(file.name);
      }
    }
    if (this.isValidate && this.uploader.queue.length !== 0) {
      this.startUpload = false;
      this.uploadFiles(files);
    }
  }

  private async uploadFiles(files: File[]): Promise<void> {
    try {
      // storing zipfiles multiple instances
      this.zipFiles = (
        await Promise.all(
          Array.from(files).map(async file => {
            if (
              FileService.validateFileSize(file) ||
              !FileService.validateFileType(file, FileTypeEnum.MIDMARKET_FILES)
            ) {
              return null;
            }
            const fileInstance = new JSZip().file(file.name, file);
            return {
              name: file.name,
              file: (await fileInstance.generateAsync({ type: 'blob' })) as any,
            };
          })
        )
      ).filter(file => UtilsService.isDefined(file));

      // merged the newly uploaded files with existing files
      if (this.existingFiles.length) {
        const mergedArr = [...this.existingFiles];
        for (const file of this.zipFiles) {
          const isNameUnique = mergedArr.every(exFile => exFile.name.toLowerCase() !== file.name.toLowerCase());
          if (isNameUnique) {
            mergedArr.push(file);
          }
        }
        this.zipFiles = mergedArr;
      }
      this.completeAllProgress();
      setTimeout(() => {
        this.saveFiles.emit(this.zipFiles);
      }, 1000);
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed to Upload files');
    }
  }

  calculateProgress(name: string): void {
    const x = setInterval(() => {
      this.progressBars[name] += 10;
      if (this.zipSaved) {
        this.progressBars[name] = this.progressBars[name] + (99 - this.progressBars[name]);
      }
      if (this.progressBars[name] >= 100) {
        clearInterval(x);
        // after successfully uploading the file close the modal
        this.closeModalClicked.emit(true);
      }
    }, 1000);
  }

  completeAllProgress(): void {
    this.zipSaved = true;
    setTimeout(() => {
      this.zipSaved = false;
    }, 1000);
  }
  closeModal(): void {
    this.closeModalClicked.emit(true);
  }
  /**
   * This function will remove the file from the list
   * @param file selected file
   */
  deleteFile(file: any): void {
    this.removeFile.emit(file);
  }
}
