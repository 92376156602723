/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onUpdateEntityByRootId: OnUpdateEntityByRootIdSubscription;
  onCreateUser: OnCreateUserSubscription;
  onUpdateUser: OnUpdateUserSubscription;
  onDeleteUser: OnDeleteUserSubscription;
  onCreateTags: OnCreateTagsSubscription;
  onUpdateTags: OnUpdateTagsSubscription;
  onDeleteTags: OnDeleteTagsSubscription;
  onCreateRole: OnCreateRoleSubscription;
  onUpdateRole: OnUpdateRoleSubscription;
  onDeleteRole: OnDeleteRoleSubscription;
  onCreateRiskLineChart: OnCreateRiskLineChartSubscription;
  onUpdateRiskLineChart: OnUpdateRiskLineChartSubscription;
  onDeleteRiskLineChart: OnDeleteRiskLineChartSubscription;
  onCreateGroup: OnCreateGroupSubscription;
  onUpdateGroup: OnUpdateGroupSubscription;
  onDeleteGroup: OnDeleteGroupSubscription;
  onCreateQuestionData: OnCreateQuestionDataSubscription;
  onUpdateQuestionData: OnUpdateQuestionDataSubscription;
  onDeleteQuestionData: OnDeleteQuestionDataSubscription;
  onCreateArchivedUser: OnCreateArchivedUserSubscription;
  onUpdateArchivedUser: OnUpdateArchivedUserSubscription;
  onDeleteArchivedUser: OnDeleteArchivedUserSubscription;
  onCreateEntity: OnCreateEntitySubscription;
  onUpdateEntity: OnUpdateEntitySubscription;
  onDeleteEntity: OnDeleteEntitySubscription;
  onCreateGraph: OnCreateGraphSubscription;
  onUpdateGraph: OnUpdateGraphSubscription;
  onDeleteGraph: OnDeleteGraphSubscription;
  onCreateRevision: OnCreateRevisionSubscription;
  onUpdateRevision: OnUpdateRevisionSubscription;
  onDeleteRevision: OnDeleteRevisionSubscription;
  onCreateVendorsDetail: OnCreateVendorsDetailSubscription;
  onUpdateVendorsDetail: OnUpdateVendorsDetailSubscription;
  onDeleteVendorsDetail: OnDeleteVendorsDetailSubscription;
  onCreateFrameworkScores: OnCreateFrameworkScoresSubscription;
  onUpdateFrameworkScores: OnUpdateFrameworkScoresSubscription;
  onDeleteFrameworkScores: OnDeleteFrameworkScoresSubscription;
  onCreateIntelligence: OnCreateIntelligenceSubscription;
  onUpdateIntelligence: OnUpdateIntelligenceSubscription;
  onDeleteIntelligence: OnDeleteIntelligenceSubscription;
  onCreateBreaches: OnCreateBreachesSubscription;
  onUpdateBreaches: OnUpdateBreachesSubscription;
  onDeleteBreaches: OnDeleteBreachesSubscription;
  onCreateExternalThreats: OnCreateExternalThreatsSubscription;
  onUpdateExternalThreats: OnUpdateExternalThreatsSubscription;
  onDeleteExternalThreats: OnDeleteExternalThreatsSubscription;
  onCreateOthers: OnCreateOthersSubscription;
  onUpdateOthers: OnUpdateOthersSubscription;
  onDeleteOthers: OnDeleteOthersSubscription;
  onCreateQuestionnaireCompletion: OnCreateQuestionnaireCompletionSubscription;
  onUpdateQuestionnaireCompletion: OnUpdateQuestionnaireCompletionSubscription;
  onDeleteQuestionnaireCompletion: OnDeleteQuestionnaireCompletionSubscription;
  onCreateAnswer: OnCreateAnswerSubscription;
  onUpdateAnswer: OnUpdateAnswerSubscription;
  onDeleteAnswer: OnDeleteAnswerSubscription;
  onCreateAssignment: OnCreateAssignmentSubscription;
  onUpdateAssignment: OnUpdateAssignmentSubscription;
  onDeleteAssignment: OnDeleteAssignmentSubscription;
  onCreateDisableAssignment: OnCreateDisableAssignmentSubscription;
  onUpdateDisableAssignment: OnUpdateDisableAssignmentSubscription;
  onDeleteDisableAssignment: OnDeleteDisableAssignmentSubscription;
  onCreateAssessment: OnCreateAssessmentSubscription;
  onUpdateAssessment: OnUpdateAssessmentSubscription;
  onDeleteAssessment: OnDeleteAssessmentSubscription;
  onCreateIntegrationMappings: OnCreateIntegrationMappingsSubscription;
  onUpdateIntegrationMappings: OnUpdateIntegrationMappingsSubscription;
  onDeleteIntegrationMappings: OnDeleteIntegrationMappingsSubscription;
  onCreateQuestionSettings: OnCreateQuestionSettingsSubscription;
  onUpdateQuestionSettings: OnUpdateQuestionSettingsSubscription;
  onDeleteQuestionSettings: OnDeleteQuestionSettingsSubscription;
  onCreateTaskAction: OnCreateTaskActionSubscription;
  onUpdateTaskAction: OnUpdateTaskActionSubscription;
  onDeleteTaskAction: OnDeleteTaskActionSubscription;
  onCreateUsersAssignmentMapper: OnCreateUsersAssignmentMapperSubscription;
  onUpdateUsersAssignmentMapper: OnUpdateUsersAssignmentMapperSubscription;
  onDeleteUsersAssignmentMapper: OnDeleteUsersAssignmentMapperSubscription;
  onCreateFrameworkSettings: OnCreateFrameworkSettingsSubscription;
  onUpdateFrameworkSettings: OnUpdateFrameworkSettingsSubscription;
  onDeleteFrameworkSettings: OnDeleteFrameworkSettingsSubscription;
  onCreateCustomTask: OnCreateCustomTaskSubscription;
  onUpdateCustomTask: OnUpdateCustomTaskSubscription;
  onDeleteCustomTask: OnDeleteCustomTaskSubscription;
  onCreateCustomTaskAssignment: OnCreateCustomTaskAssignmentSubscription;
  onUpdateCustomTaskAssignment: OnUpdateCustomTaskAssignmentSubscription;
  onDeleteCustomTaskAssignment: OnDeleteCustomTaskAssignmentSubscription;
  onCreateAssessmentStandardFramework: OnCreateAssessmentStandardFrameworkSubscription;
  onUpdateAssessmentStandardFramework: OnUpdateAssessmentStandardFrameworkSubscription;
  onDeleteAssessmentStandardFramework: OnDeleteAssessmentStandardFrameworkSubscription;
  onCreateFrameworkManager: OnCreateFrameworkManagerSubscription;
  onUpdateFrameworkManager: OnUpdateFrameworkManagerSubscription;
  onDeleteFrameworkManager: OnDeleteFrameworkManagerSubscription;
  onCreateComment: OnCreateCommentSubscription;
  onUpdateComment: OnUpdateCommentSubscription;
  onDeleteComment: OnDeleteCommentSubscription;
  onCreateExport: OnCreateExportSubscription;
  onUpdateExport: OnUpdateExportSubscription;
  onDeleteExport: OnDeleteExportSubscription;
  onCreateReport: OnCreateReportSubscription;
  onUpdateReport: OnUpdateReportSubscription;
  onDeleteReport: OnDeleteReportSubscription;
  onCreateRisk: OnCreateRiskSubscription;
  onUpdateRisk: OnUpdateRiskSubscription;
  onDeleteRisk: OnDeleteRiskSubscription;
  onCreateRiskOwnerAssignment: OnCreateRiskOwnerAssignmentSubscription;
  onUpdateRiskOwnerAssignment: OnUpdateRiskOwnerAssignmentSubscription;
  onDeleteRiskOwnerAssignment: OnDeleteRiskOwnerAssignmentSubscription;
  onCreateSavedFilter: OnCreateSavedFilterSubscription;
  onUpdateSavedFilter: OnUpdateSavedFilterSubscription;
  onDeleteSavedFilter: OnDeleteSavedFilterSubscription;
  onCreateDomainSettings: OnCreateDomainSettingsSubscription;
  onUpdateDomainSettings: OnUpdateDomainSettingsSubscription;
  onDeleteDomainSettings: OnDeleteDomainSettingsSubscription;
  onCreateExecutiveSummary: OnCreateExecutiveSummarySubscription;
  onUpdateExecutiveSummary: OnUpdateExecutiveSummarySubscription;
  onDeleteExecutiveSummary: OnDeleteExecutiveSummarySubscription;
  onCreateNetskopeProject: OnCreateNetskopeProjectSubscription;
  onUpdateNetskopeProject: OnUpdateNetskopeProjectSubscription;
  onDeleteNetskopeProject: OnDeleteNetskopeProjectSubscription;
  onCreateEntityLayers: OnCreateEntityLayersSubscription;
  onUpdateEntityLayers: OnUpdateEntityLayersSubscription;
  onDeleteEntityLayers: OnDeleteEntityLayersSubscription;
  onCreateEntityWizard: OnCreateEntityWizardSubscription;
  onUpdateEntityWizard: OnUpdateEntityWizardSubscription;
  onDeleteEntityWizard: OnDeleteEntityWizardSubscription;
  onCreateLogs: OnCreateLogsSubscription;
  onUpdateLogs: OnUpdateLogsSubscription;
  onDeleteLogs: OnDeleteLogsSubscription;
  onCreateVersions: OnCreateVersionsSubscription;
  onUpdateVersions: OnUpdateVersionsSubscription;
  onDeleteVersions: OnDeleteVersionsSubscription;
  onCreateDataState: OnCreateDataStateSubscription;
  onUpdateDataState: OnUpdateDataStateSubscription;
  onDeleteDataState: OnDeleteDataStateSubscription;
  onCreateSmartMappingDetail: OnCreateSmartMappingDetailSubscription;
  onUpdateSmartMappingDetail: OnUpdateSmartMappingDetailSubscription;
  onDeleteSmartMappingDetail: OnDeleteSmartMappingDetailSubscription;
  onCreateApiCallRegistry: OnCreateApiCallRegistrySubscription;
  onUpdateApiCallRegistry: OnUpdateApiCallRegistrySubscription;
  onDeleteApiCallRegistry: OnDeleteApiCallRegistrySubscription;
  onCreateBreachData: OnCreateBreachDataSubscription;
  onUpdateBreachData: OnUpdateBreachDataSubscription;
  onDeleteBreachData: OnDeleteBreachDataSubscription;
};

export type CreateFrameworksNodesInput = {
  frameworkList?: Array<Framework> | null;
  accessGroups: Array<string>;
  vendorRawData?: string | null;
};

export type Framework = {
  name: string;
  type?: EntityTypeEnum | null;
};

export enum EntityTypeEnum {
  ROOT_ENTITY = "ROOT_ENTITY",
  CHILD_ENTITY = "CHILD_ENTITY",
  VENDOR = "VENDOR",
  RISK_FRAMEWORK = "RISK_FRAMEWORK",
  COMPLIANCE_FRAMEWORK = "COMPLIANCE_FRAMEWORK",
  FRAMEWORK_NODE = "FRAMEWORK_NODE"
}

export type IDList = {
  __typename: "IDList";
  ids?: Array<string | null> | null;
};

export type AssignUser = {
  __typename: "AssignUser";
  createdAssignments?: Array<Assignment | null> | null;
  deletedAssignments?: Array<Assignment | null> | null;
};

export type Assignment = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: User | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type User = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export enum RoleEnum {
  ADMIN = "ADMIN",
  MSSP = "MSSP",
  LEADER = "LEADER",
  VENDOR = "VENDOR",
  PARTICIPANT = "PARTICIPANT",
  CUSTOM = "CUSTOM",
  ENTITY_LEADER = "ENTITY_LEADER"
}

export enum MidMarketEnum {
  STEP_1 = "STEP_1",
  STEP_2 = "STEP_2",
  COMPLETED = "COMPLETED"
}

export type QuestionnaireCompletion = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: User | null;
  assessment?: Assessment | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Assessment = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<StandardFramework | null> | null;
  standardFrameworkList?: ModelAssessmentStandardFrameworkConnection | null;
  timeline?: Timeline | null;
  rootId: string;
  childId: string;
  scores?: Scores | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: Exports | null;
  createdAt: string;
  updatedAt: string;
};

export type StandardFramework = {
  __typename: "StandardFramework";
  id: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilter | null;
};

export enum StandardType {
  COMPLIANCE_FRAMEWORK = "COMPLIANCE_FRAMEWORK",
  RISK_FRAMEWORK = "RISK_FRAMEWORK",
  ARTIFACT = "ARTIFACT",
  FOURTH_PARTY = "FOURTH_PARTY",
  VENDOR_FRAMEWORK = "VENDOR_FRAMEWORK"
}

export type StandardFilter = {
  __typename: "StandardFilter";
  impact?: ImpactEnum | null;
};

export enum ImpactEnum {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  NA = "NA"
}

export type ModelAssessmentStandardFrameworkConnection = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<AssessmentStandardFramework | null>;
  nextToken?: string | null;
};

export type AssessmentStandardFramework = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilter | null;
  timeline?: Timeline | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: Assessment | null;
  selectedChapters?: Array<string | null> | null;
  managers?: ModelFrameworkManagerConnection | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: EntityQuestionSettings | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: User | null;
  signature?: SignatureObject | null;
  lockedByUserID?: string | null;
  lockedBy?: User | null;
  createdAt: string;
  updatedAt: string;
};

export type Timeline = {
  __typename: "Timeline";
  initiationDate?: number | null;
  collectionDate?: number | null;
  nextReviewDate?: number | null;
  frequency?: FrequencyCheckEnum | null;
  days?: number | null;
  nextStartingDate?: number | null;
  reassessmentNotifier?: number | null;
  vendorOnBoardingDate?: number | null;
  isAutoReminder?: boolean | null;
  isAutoReassessment?: boolean | null;
};

export enum FrequencyCheckEnum {
  ANNUAL = "ANNUAL",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  QUARTERLY = "QUARTERLY",
  MANUAL = "MANUAL"
}

export type ModelFrameworkManagerConnection = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<FrameworkManager | null>;
  nextToken?: string | null;
};

export type FrameworkManager = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: User | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export enum ReassessmentEnum {
  NOT_INITIATED = "NOT_INITIATED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED"
}

export type EntityQuestionSettings = {
  __typename: "EntityQuestionSettings";
  isApprovalRequired?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isCollaborative?: boolean | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
};

export enum DocumentType {
  DOCUMENTS = "DOCUMENTS",
  SCANS = "SCANS",
  OTHERS = "OTHERS",
  CERTIFICATIONS = "CERTIFICATIONS"
}

export type SignatureObject = {
  __typename: "SignatureObject";
  title?: string | null;
  printName?: string | null;
  disclaimerDate?: number | null;
  image?: string | null;
  text?: string | null;
  draw?: string | null;
  signDate?: string | null;
};

export type Scores = {
  __typename: "Scores";
  total?: number | null;
  target?: number | null;
  collection?: number | null;
  remediation?: number | null;
  reassessment?: number | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  totalGaps?: number | null;
  criticalGaps?: number | null;
  notApplicable?: boolean | null;
  top20?: Scores | null;
  maturity?: number | null;
  tierScores?: string | null;
  totalReadinessTasks?: number | null;
  totalComplianceTasks?: number | null;
  totalInternalTasks?: number | null;
};

export type Exports = {
  __typename: "Exports";
  jiraEntityIds?: Array<string | null> | null;
  serviceNowEntityIds?: Array<string | null> | null;
};

export type CreateUserInput = {
  id?: string | null;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  roleId?: ModelIDInput | null;
  isViewOnly?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  entityIds?: ModelStringInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  lastLogin?: ModelIntInput | null;
  invitationDate?: ModelIntInput | null;
  reminderDate?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  domain?: ModelStringInput | null;
  temporaryHigherRank?: ModelBooleanInput | null;
  entityLayerIds?: ModelStringInput | null;
  clickThrough?: ModelBooleanInput | null;
  clickThroughDate?: ModelStringInput | null;
  projectManagerEmail?: ModelStringInput | null;
  isGlobalParticipant?: ModelBooleanInput | null;
  onBoardingStatus?: ModelMidMarketEnumInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelRoleEnumInput = {
  eq?: RoleEnum | null;
  ne?: RoleEnum | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelMidMarketEnumInput = {
  eq?: MidMarketEnum | null;
  ne?: MidMarketEnum | null;
};

export type UpdateUserInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
};

export type DeleteUserInput = {
  id: string;
};

export type CreateTagsInput = {
  id?: string | null;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
};

export type ModelTagsConditionInput = {
  name?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  domain?: ModelStringInput | null;
  targetId?: ModelStringInput | null;
  assessmentFrameworkKey?: ModelStringInput | null;
  referenceId?: ModelIDInput | null;
  questionRefId?: ModelIDInput | null;
  and?: Array<ModelTagsConditionInput | null> | null;
  or?: Array<ModelTagsConditionInput | null> | null;
  not?: ModelTagsConditionInput | null;
};

export type Tags = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: QuestionData | null;
  createdAt: string;
  updatedAt: string;
};

export type QuestionData = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export enum ReviewerActions {
  WAITING = "WAITING",
  APPROVED = "APPROVED",
  DENIED = "DENIED"
}

export type UpdateTagsInput = {
  id: string;
  name?: string | null;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
};

export type DeleteTagsInput = {
  id: string;
};

export type CreateRoleInput = {
  id?: string | null;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type ModelRoleConditionInput = {
  name?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  entityIds?: ModelStringInput | null;
  vendorIds?: ModelStringInput | null;
  isRootEntityAccess?: ModelBooleanInput | null;
  defaultOrEntityId?: ModelStringInput | null;
  screenPermissions?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  parentId?: ModelStringInput | null;
  and?: Array<ModelRoleConditionInput | null> | null;
  or?: Array<ModelRoleConditionInput | null> | null;
  not?: ModelRoleConditionInput | null;
};

export type Role = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type UpdateRoleInput = {
  id: string;
  name?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId?: string | null;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type DeleteRoleInput = {
  id: string;
};

export type CreateRiskLineChartInput = {
  id?: string | null;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum RiskImpactEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL"
}

export type ModelRiskLineChartConditionInput = {
  assessmentId?: ModelStringInput | null;
  riskId?: ModelStringInput | null;
  residual?: ModelRiskImpactEnumInput | null;
  inherent?: ModelRiskImpactEnumInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelRiskLineChartConditionInput | null> | null;
  or?: Array<ModelRiskLineChartConditionInput | null> | null;
  not?: ModelRiskLineChartConditionInput | null;
};

export type ModelRiskImpactEnumInput = {
  eq?: RiskImpactEnum | null;
  ne?: RiskImpactEnum | null;
};

export type RiskLineChart = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateRiskLineChartInput = {
  id: string;
  assessmentId?: string | null;
  riskId?: string | null;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteRiskLineChartInput = {
  id: string;
};

export type CreateGroupInput = {
  id?: string | null;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
};

export type ModelGroupConditionInput = {
  name?: ModelStringInput | null;
  rootId?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  and?: Array<ModelGroupConditionInput | null> | null;
  or?: Array<ModelGroupConditionInput | null> | null;
  not?: ModelGroupConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Group = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateGroupInput = {
  id: string;
  name?: string | null;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
};

export type DeleteGroupInput = {
  id: string;
};

export type CreateQuestionDataInput = {
  id?: string | null;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
};

export type ModelQuestionDataConditionInput = {
  childId?: ModelStringInput | null;
  frameworkKey?: ModelStringInput | null;
  function?: ModelStringInput | null;
  category?: ModelStringInput | null;
  subCategory?: ModelStringInput | null;
  controlNameLabel?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  rootId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  reviewStatus?: ModelReviewerActionsInput | null;
  resentBackStatus?: ModelBooleanInput | null;
  and?: Array<ModelQuestionDataConditionInput | null> | null;
  or?: Array<ModelQuestionDataConditionInput | null> | null;
  not?: ModelQuestionDataConditionInput | null;
};

export type ModelReviewerActionsInput = {
  eq?: ReviewerActions | null;
  ne?: ReviewerActions | null;
};

export type UpdateQuestionDataInput = {
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
};

export type DeleteQuestionDataInput = {
  id: string;
};

export type CreateArchivedUserInput = {
  id?: string | null;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
};

export type ModelArchivedUserConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  domain?: ModelStringInput | null;
  and?: Array<ModelArchivedUserConditionInput | null> | null;
  or?: Array<ModelArchivedUserConditionInput | null> | null;
  not?: ModelArchivedUserConditionInput | null;
};

export type ArchivedUser = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type UpdateArchivedUserInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  role?: RoleEnum | null;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
};

export type DeleteArchivedUserInput = {
  id: string;
};

export type CreateEntityInput = {
  id?: string | null;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: TimelineInput | null;
  scores?: ScoresInput | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: S3FileInput | null;
  integrations?: Array<IntegrationsInput | null> | null;
  externalFeed?: Array<IntegrationsInput | null> | null;
  defaultSetting?: DefaultSettingInput | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  scName?: string | null;
  defaultQuestionSettings?: EntityQuestionSettingsInput | null;
  KMS?: KMSDataInput | null;
  AI?: AIDataInput | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: SolutionStatusDataInput | null;
  vendors?: VendorsDataInput | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
};

export enum CollectionStatusEnum {
  HAS_NOT_BEGUN = "HAS_NOT_BEGUN",
  IN_PROCESS = "IN_PROCESS",
  COMPLETED = "COMPLETED"
}

export type TimelineInput = {
  initiationDate?: number | null;
  collectionDate?: number | null;
  nextReviewDate?: number | null;
  frequency?: FrequencyCheckEnum | null;
  days?: number | null;
  nextStartingDate?: number | null;
  reassessmentNotifier?: number | null;
  vendorOnBoardingDate?: number | null;
  isAutoReminder?: boolean | null;
  isAutoReassessment?: boolean | null;
};

export type ScoresInput = {
  total?: number | null;
  target?: number | null;
  collection?: number | null;
  remediation?: number | null;
  reassessment?: number | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  totalGaps?: number | null;
  criticalGaps?: number | null;
  notApplicable?: boolean | null;
  top20?: ScoresInput | null;
  maturity?: number | null;
  tierScores?: string | null;
  totalReadinessTasks?: number | null;
  totalComplianceTasks?: number | null;
  totalInternalTasks?: number | null;
};

export type S3FileInput = {
  id?: string | null;
  name: string;
  names?: string | null;
  entityId?: string | null;
  fileType: FileTypeEnum;
  contentType?: string | null;
  date?: number | null;
  body?: string | null;
  length?: number | null;
  key?: string | null;
  wizardId?: string | null;
  url?: string | null;
};

export enum FileTypeEnum {
  LOGO = "LOGO",
  ARTIFACTS = "ARTIFACTS",
  REVISIONS = "REVISIONS",
  ALERTS = "ALERTS",
  MEDIA = "MEDIA",
  ASSESSMENTS = "ASSESSMENTS",
  FRAMEWORK_TREES = "FRAMEWORK_TREES",
  FRAMEWORKS = "FRAMEWORKS",
  INDUSTRY_FRAMEWORKS = "INDUSTRY_FRAMEWORKS",
  BOARD_REPORTS = "BOARD_REPORTS",
  BnB_REPORTS = "BnB_REPORTS",
  VENDOR_ARTIFACTS_TEMPLATE = "VENDOR_ARTIFACTS_TEMPLATE",
  MANUAL_ARTIFACT = "MANUAL_ARTIFACT",
  CLIENT_WIZARD_FILES = "CLIENT_WIZARD_FILES",
  MIDMARKET_FILES = "MIDMARKET_FILES",
  E_SIGNATURE = "E_SIGNATURE"
}

export type IntegrationsInput = {
  name: string;
  credentials?: string | null;
  status?: StatusEnum | null;
  isEnable?: boolean | null;
  isAuth?: boolean | null;
  inheritFromParent?: boolean | null;
  webhookUpdatedAt?: number | null;
};

export enum StatusEnum {
  FAILED = "FAILED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}

export type DefaultSettingInput = {
  dueDateInDays?: number | null;
  frequency?: string | null;
  frequencyDays?: number | null;
  assessmentDays?: number | null;
  isAutoReassessment?: boolean | null;
  surveyLevel?: string | null;
  artifacts?: Array<QualificationInput | null> | null;
  standards?: Array<QualificationInput | null> | null;
  customRequirements?: Array<QualificationInput | null> | null;
  customCertifications?: Array<QualificationInput | null> | null;
  accessLevel?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<QualificationInput | null> | null;
  activeScan?: boolean | null;
  notifications?: string | null;
  selectedRisk?: string | null;
  selectedLevel?: string | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  domains?: string | null;
  otherData?: string | null;
  fourthPartyWeightage?: number | null;
  enbdDomain?: Array<string | null> | null;
};

export type QualificationInput = {
  name?: string | null;
  isQualify?: boolean | null;
  templateURL?: S3FileInput | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  level?: string | null;
};

export type EntityQuestionSettingsInput = {
  isApprovalRequired?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isCollaborative?: boolean | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
};

export type KMSDataInput = {
  status?: KMSStatus | null;
  version?: number | null;
  key?: string | null;
  alias?: string | null;
  dataKeyEncrypted?: string | null;
  newDataKeyEncrypted?: string | null;
  newKeyId?: string | null;
  rotations?: RotationInput | null;
  userId?: string | null;
};

export enum KMSStatus {
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  FAILED = "FAILED"
}

export type RotationInput = {
  count?: number | null;
  status?: KMSStatus | null;
  lastRotation?: number | null;
  firstRotationDate?: number | null;
};

export type AIDataInput = {
  credits?: number | null;
  date?: number | null;
  isActive?: boolean | null;
  usedCredits?: number | null;
  userName?: string | null;
};

export type SolutionStatusDataInput = {
  firstParty?: boolean | null;
  thirdParty?: boolean | null;
  boardView?: boolean | null;
};

export type VendorsDataInput = {
  vendorsTotalCount?: number | null;
  totalVendorsCreated?: number | null;
};

export type ModelEntityConditionInput = {
  name?: ModelStringInput | null;
  users?: ModelIDInput | null;
  completionStatus?: ModelCollectionStatusEnumInput | null;
  accessGroups?: ModelStringInput | null;
  participantGroup?: ModelStringInput | null;
  rootEntityId?: ModelIDInput | null;
  childEntityId?: ModelIDInput | null;
  frameworkId?: ModelIDInput | null;
  frameworkName?: ModelStringInput | null;
  frameworkType?: ModelEntityTypeEnumInput | null;
  parentId?: ModelIDInput | null;
  entityType?: ModelEntityTypeEnumInput | null;
  projectManager?: ModelIDInput | null;
  projectManagerName?: ModelStringInput | null;
  projectDeadline?: ModelIntInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  industry?: ModelStringInput | null;
  domain?: ModelStringInput | null;
  activeAssessmentId?: ModelIDInput | null;
  tierSelected?: ModelIntInput | null;
  scName?: ModelStringInput | null;
  upperdeckRoute?: ModelStringInput | null;
  isLocked?: ModelBooleanInput | null;
  eSignFinalized?: ModelBooleanInput | null;
  weightageScore?: ModelFloatInput | null;
  upperdeckSetting?: ModelStringInput | null;
  domainScanned?: ModelBooleanInput | null;
  domainScanTimes?: ModelStringInput | null;
  domainScanEnabledForEntity?: ModelBooleanInput | null;
  frameworkSettings?: ModelStringInput | null;
  usedFrameworkUniqueCount?: ModelIntInput | null;
  spiderChartSetting?: ModelStringInput | null;
  isVendorInLimit?: ModelBooleanInput | null;
  and?: Array<ModelEntityConditionInput | null> | null;
  or?: Array<ModelEntityConditionInput | null> | null;
  not?: ModelEntityConditionInput | null;
};

export type ModelCollectionStatusEnumInput = {
  eq?: CollectionStatusEnum | null;
  ne?: CollectionStatusEnum | null;
};

export type ModelEntityTypeEnumInput = {
  eq?: EntityTypeEnum | null;
  ne?: EntityTypeEnum | null;
};

export type Entity = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: Timeline | null;
  scores?: Scores | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: S3File | null;
  integrations?: Array<Integrations | null> | null;
  externalFeed?: Array<Integrations | null> | null;
  defaultSetting?: DefaultSetting | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: VendorsDetail | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: Assessment | null;
  scName?: string | null;
  defaultQuestionSettings?: EntityQuestionSettings | null;
  KMS?: KMSData | null;
  AI?: AIData | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: SolutionStatusData | null;
  vendors?: VendorsData | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type S3File = {
  __typename: "S3File";
  id?: string | null;
  name: string;
  names?: string | null;
  entityId: string;
  fileType: FileTypeEnum;
  date?: number | null;
  contentType?: string | null;
  length?: number | null;
  key?: string | null;
};

export type Integrations = {
  __typename: "Integrations";
  name: string;
  credentials?: string | null;
  status?: StatusEnum | null;
  isEnable?: boolean | null;
  isAuth?: boolean | null;
  inheritFromParent?: boolean | null;
  webhookUpdatedAt?: number | null;
};

export type DefaultSetting = {
  __typename: "DefaultSetting";
  dueDateInDays?: number | null;
  frequency?: string | null;
  frequencyDays?: number | null;
  assessmentDays?: number | null;
  isAutoReassessment?: boolean | null;
  surveyLevel?: string | null;
  artifacts?: Array<Qualification | null> | null;
  standards?: Array<Qualification | null> | null;
  customRequirements?: Array<Qualification | null> | null;
  customCertifications?: Array<Qualification | null> | null;
  accessLevel?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<Qualification | null> | null;
  activeScan?: boolean | null;
  notifications?: string | null;
  selectedRisk?: string | null;
  selectedLevel?: string | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  domains?: string | null;
  otherData?: string | null;
  fourthPartyWeightage?: number | null;
  enbdDomain?: Array<string | null> | null;
};

export type Qualification = {
  __typename: "Qualification";
  name?: string | null;
  isQualify?: boolean | null;
  templateURL?: S3File | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  level?: string | null;
};

export type VendorsDetail = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<Qualification | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: Timeline | null;
  approvalDate?: number | null;
  questionnaires?: Array<Questionnaire | null> | null;
  artifacts?: Artifacts | null;
  intelligence?: Intelligence | null;
  primaryPoc?: ShortRef | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<Qualification | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<RiskFinancialImpact | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export enum VendorStatusEnum {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  IN_PROCESS = "IN_PROCESS",
  DENIED = "DENIED",
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  DELETE = "DELETE"
}

export enum AccessLevelEnum {
  PHYSICAL = "PHYSICAL",
  SOFTWARE_PROVIDER = "SOFTWARE_PROVIDER",
  INTERNAL_NETWORK = "INTERNAL_NETWORK"
}

export type Questionnaire = {
  __typename: "Questionnaire";
  tag?: string | null;
  status?: QuestionnaireStatus | null;
  criticalGaps?: Array<string | null> | null;
  allGaps?: string | null;
};

export type QuestionnaireStatus = {
  __typename: "QuestionnaireStatus";
  totalQuestions?: number | null;
  completedQuestions?: number | null;
};

export type Artifacts = {
  __typename: "Artifacts";
  documents?: Array<S3File | null> | null;
  scans?: Array<S3File | null> | null;
  insurances?: Array<S3File | null> | null;
  certifications?: Array<S3File | null> | null;
};

export type Intelligence = {
  __typename: "Intelligence";
  id: string;
  breaches?: ModelBreachesConnection | null;
  externalThreats?: ModelExternalThreatsConnection | null;
  others?: ModelOthersConnection | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelBreachesConnection = {
  __typename: "ModelBreachesConnection";
  items: Array<Breaches | null>;
  nextToken?: string | null;
};

export type Breaches = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelExternalThreatsConnection = {
  __typename: "ModelExternalThreatsConnection";
  items: Array<ExternalThreats | null>;
  nextToken?: string | null;
};

export type ExternalThreats = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<ThreatDetails | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ThreatDetails = {
  __typename: "ThreatDetails";
  ip?: string | null;
  port?: number | null;
  cve?: string | null;
  cvss?: number | null;
};

export type ModelOthersConnection = {
  __typename: "ModelOthersConnection";
  items: Array<Others | null>;
  nextToken?: string | null;
};

export type Others = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ShortRef = {
  __typename: "ShortRef";
  name?: string | null;
  id?: string | null;
  email?: string | null;
};

export type RiskFinancialImpact = {
  __typename: "RiskFinancialImpact";
  type?: string | null;
  minimum?: number | null;
  mostLikely?: number | null;
  maximum?: number | null;
  confidence?: RiskConfidenceEnum | null;
  stepData?: string | null;
};

export enum RiskConfidenceEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH"
}

export enum FrameworkLevelEnum {
  LIGHT = "LIGHT",
  MODERATE = "MODERATE",
  HIGH = "HIGH",
  FULL = "FULL",
  ONPREM = "ONPREM"
}

export type KMSData = {
  __typename: "KMSData";
  status?: KMSStatus | null;
  version?: number | null;
  key?: string | null;
  alias?: string | null;
  dataKeyEncrypted?: string | null;
  newDataKeyEncrypted?: string | null;
  newKeyId?: string | null;
  rotations?: Rotation | null;
  userId?: string | null;
};

export type Rotation = {
  __typename: "Rotation";
  count?: number | null;
  status?: KMSStatus | null;
  lastRotation?: number | null;
  firstRotationDate?: number | null;
};

export type AIData = {
  __typename: "AIData";
  credits?: number | null;
  date?: number | null;
  isActive?: boolean | null;
  usedCredits?: number | null;
  userName?: string | null;
};

export type SolutionStatusData = {
  __typename: "SolutionStatusData";
  firstParty?: boolean | null;
  thirdParty?: boolean | null;
  boardView?: boolean | null;
};

export type VendorsData = {
  __typename: "VendorsData";
  vendorsTotalCount?: number | null;
  totalVendorsCreated?: number | null;
};

export type UpdateEntityInput = {
  id: string;
  name?: string | null;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups?: Array<string> | null;
  participantGroup?: Array<string> | null;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: TimelineInput | null;
  scores?: ScoresInput | null;
  entityType?: EntityTypeEnum | null;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: S3FileInput | null;
  integrations?: Array<IntegrationsInput | null> | null;
  externalFeed?: Array<IntegrationsInput | null> | null;
  defaultSetting?: DefaultSettingInput | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  industry?: string | null;
  domain?: string | null;
  activeAssessmentId?: string | null;
  tierSelected?: number | null;
  scName?: string | null;
  defaultQuestionSettings?: EntityQuestionSettingsInput | null;
  KMS?: KMSDataInput | null;
  AI?: AIDataInput | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: SolutionStatusDataInput | null;
  vendors?: VendorsDataInput | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
};

export type DeleteEntityInput = {
  id: string;
};

export type CreateGraphInput = {
  id?: string | null;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: EntityDataInput | null;
  groups?: Array<GroupGraphInputInput | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
};

export type EntityDataInput = {
  type?: string | null;
  data?: Array<string | null> | null;
};

export type GroupGraphInputInput = {
  id?: string | null;
  name?: string | null;
  tags?: Array<string | null> | null;
};

export type ModelGraphConditionInput = {
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  rootId?: ModelStringInput | null;
  status?: ModelStringInput | null;
  entities?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  timeSpan?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelGraphConditionInput | null> | null;
  or?: Array<ModelGraphConditionInput | null> | null;
  not?: ModelGraphConditionInput | null;
};

export type Graph = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: EntityData | null;
  groups?: Array<GroupGraphInput | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type EntityData = {
  __typename: "EntityData";
  type?: string | null;
  data?: Array<string | null> | null;
};

export type GroupGraphInput = {
  __typename: "GroupGraphInput";
  id?: string | null;
  name?: string | null;
  tags?: Array<string | null> | null;
};

export type UpdateGraphInput = {
  id: string;
  name?: string | null;
  type?: string | null;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: EntityDataInput | null;
  groups?: Array<GroupGraphInputInput | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
};

export type DeleteGraphInput = {
  id: string;
};

export type CreateRevisionInput = {
  id?: string | null;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
};

export type ModelRevisionConditionInput = {
  frameworkKey?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  groupId?: ModelStringInput | null;
  date?: ModelStringInput | null;
  rootId?: ModelStringInput | null;
  tagId?: ModelStringInput | null;
  scores?: ModelStringInput | null;
  and?: Array<ModelRevisionConditionInput | null> | null;
  or?: Array<ModelRevisionConditionInput | null> | null;
  not?: ModelRevisionConditionInput | null;
};

export type Revision = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRevisionInput = {
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
};

export type DeleteRevisionInput = {
  id: string;
};

export type CreateVendorsDetailInput = {
  id?: string | null;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: TimelineInput | null;
  approvalDate?: number | null;
  questionnaires?: Array<QuestionnaireInput | null> | null;
  artifacts?: ArtifactsInput | null;
  primaryPoc?: ShortRefInput | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<QualificationInput | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<RiskFinancialImpactInput | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
};

export type QuestionnaireInput = {
  tag?: string | null;
  status?: QuestionnaireStatusInput | null;
  criticalGaps?: Array<string | null> | null;
  allGaps?: string | null;
};

export type QuestionnaireStatusInput = {
  totalQuestions?: number | null;
  completedQuestions?: number | null;
};

export type ArtifactsInput = {
  documents?: Array<S3FileInput | null> | null;
  scans?: Array<S3FileInput | null> | null;
  insurances?: Array<S3FileInput | null> | null;
  certifications?: Array<S3FileInput | null> | null;
};

export type ShortRefInput = {
  name?: string | null;
  id?: string | null;
  email?: string | null;
};

export type RiskFinancialImpactInput = {
  type?: string | null;
  minimum?: number | null;
  mostLikely?: number | null;
  maximum?: number | null;
  confidence?: RiskConfidenceEnum | null;
  stepData?: string | null;
};

export type ModelVendorsDetailConditionInput = {
  impact?: ModelIntInput | null;
  externalScan?: ModelFloatInput | null;
  financialRisk?: ModelFloatInput | null;
  criticalGaps?: ModelIntInput | null;
  status?: ModelVendorStatusEnumInput | null;
  probability?: ModelIntInput | null;
  website?: ModelStringInput | null;
  location?: ModelStringInput | null;
  size?: ModelStringInput | null;
  description?: ModelStringInput | null;
  businessPointOfContactId?: ModelStringInput | null;
  accessLevel?: ModelAccessLevelEnumInput | null;
  collectionStatus?: ModelCollectionStatusEnumInput | null;
  approvalDate?: ModelIntInput | null;
  stage?: ModelVendorStatusEnumInput | null;
  activeScan?: ModelBooleanInput | null;
  domain?: ModelStringInput | null;
  privacyData?: ModelBooleanInput | null;
  primaryLoss?: ModelFloatInput | null;
  smartTags?: ModelStringInput | null;
  employees?: ModelIntInput | null;
  domains?: ModelStringInput | null;
  isThirdParty?: ModelBooleanInput | null;
  isFourthParty?: ModelBooleanInput | null;
  fourthPartyDomains?: ModelStringInput | null;
  level?: ModelFrameworkLevelEnumInput | null;
  fourthPartyActiveScan?: ModelBooleanInput | null;
  intelligenceScanScore?: ModelFloatInput | null;
  fourthPartyScanScore?: ModelFloatInput | null;
  isThirdPartyScanCompleted?: ModelBooleanInput | null;
  priority?: ModelImpactEnumInput | null;
  identification?: ModelImpactEnumInput | null;
  materialityRating?: ModelStringInput | null;
  enbdCategory?: ModelStringInput | null;
  enbdDomain?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  projectManagerPhoneNumber?: ModelStringInput | null;
  businessUnits?: ModelStringInput | null;
  category?: ModelStringInput | null;
  dealValue?: ModelIntInput | null;
  products?: ModelStringInput | null;
  ticketID?: ModelStringInput | null;
  tempUsersData?: ModelStringInput | null;
  and?: Array<ModelVendorsDetailConditionInput | null> | null;
  or?: Array<ModelVendorsDetailConditionInput | null> | null;
  not?: ModelVendorsDetailConditionInput | null;
};

export type ModelVendorStatusEnumInput = {
  eq?: VendorStatusEnum | null;
  ne?: VendorStatusEnum | null;
};

export type ModelAccessLevelEnumInput = {
  eq?: AccessLevelEnum | null;
  ne?: AccessLevelEnum | null;
};

export type ModelFrameworkLevelEnumInput = {
  eq?: FrameworkLevelEnum | null;
  ne?: FrameworkLevelEnum | null;
};

export type ModelImpactEnumInput = {
  eq?: ImpactEnum | null;
  ne?: ImpactEnum | null;
};

export type UpdateVendorsDetailInput = {
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: TimelineInput | null;
  approvalDate?: number | null;
  questionnaires?: Array<QuestionnaireInput | null> | null;
  artifacts?: ArtifactsInput | null;
  primaryPoc?: ShortRefInput | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<QualificationInput | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<RiskFinancialImpactInput | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
};

export type DeleteVendorsDetailInput = {
  id: string;
};

export type CreateFrameworkScoresInput = {
  id?: string | null;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: ScoresInput | null;
  groups?: string | null;
  maturity?: number | null;
};

export type ModelFrameworkScoresConditionInput = {
  assessmentId?: ModelIDInput | null;
  type?: ModelStandardTypeInput | null;
  total?: ModelFloatInput | null;
  target?: ModelFloatInput | null;
  collection?: ModelFloatInput | null;
  remediation?: ModelFloatInput | null;
  reassessment?: ModelFloatInput | null;
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  totalGaps?: ModelIntInput | null;
  criticalGaps?: ModelIntInput | null;
  notApplicable?: ModelBooleanInput | null;
  assignmentCount?: ModelIntInput | null;
  riskStandardsCount?: ModelIntInput | null;
  complianceStandardsCount?: ModelIntInput | null;
  standardsCount?: ModelIntInput | null;
  groups?: ModelStringInput | null;
  maturity?: ModelIntInput | null;
  and?: Array<ModelFrameworkScoresConditionInput | null> | null;
  or?: Array<ModelFrameworkScoresConditionInput | null> | null;
  not?: ModelFrameworkScoresConditionInput | null;
};

export type ModelStandardTypeInput = {
  eq?: StandardType | null;
  ne?: StandardType | null;
};

export type FrameworkScores = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: Scores | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFrameworkScoresInput = {
  id: string;
  assessmentId?: string | null;
  type?: StandardType | null;
  total?: number | null;
  target?: number | null;
  collection?: number | null;
  remediation?: number | null;
  reassessment?: number | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  totalGaps?: number | null;
  criticalGaps?: number | null;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: ScoresInput | null;
  groups?: string | null;
  maturity?: number | null;
};

export type DeleteFrameworkScoresInput = {
  id: string;
};

export type CreateIntelligenceInput = {
  id?: string | null;
};

export type ModelIntelligenceConditionInput = {
  and?: Array<ModelIntelligenceConditionInput | null> | null;
  or?: Array<ModelIntelligenceConditionInput | null> | null;
  not?: ModelIntelligenceConditionInput | null;
};

export type UpdateIntelligenceInput = {
  id: string;
};

export type DeleteIntelligenceInput = {
  id: string;
};

export type CreateBreachesInput = {
  id?: string | null;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
};

export type ModelBreachesConditionInput = {
  vendorId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  year?: ModelIntInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  leaks?: ModelIntInput | null;
  data?: ModelStringInput | null;
  status?: ModelStringInput | null;
  email?: ModelStringInput | null;
  and?: Array<ModelBreachesConditionInput | null> | null;
  or?: Array<ModelBreachesConditionInput | null> | null;
  not?: ModelBreachesConditionInput | null;
};

export type UpdateBreachesInput = {
  id: string;
  vendorId?: string | null;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
};

export type DeleteBreachesInput = {
  id: string;
};

export type CreateExternalThreatsInput = {
  id?: string | null;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<ThreatDetailsInput | null> | null;
  status?: string | null;
};

export type ThreatDetailsInput = {
  ip?: string | null;
  port?: number | null;
  cve?: string | null;
  cvss?: number | null;
};

export type ModelExternalThreatsConditionInput = {
  vendorId?: ModelIDInput | null;
  source?: ModelStringInput | null;
  findings?: ModelStringInput | null;
  riskScore?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelExternalThreatsConditionInput | null> | null;
  or?: Array<ModelExternalThreatsConditionInput | null> | null;
  not?: ModelExternalThreatsConditionInput | null;
};

export type UpdateExternalThreatsInput = {
  id: string;
  vendorId?: string | null;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<ThreatDetailsInput | null> | null;
  status?: string | null;
};

export type DeleteExternalThreatsInput = {
  id: string;
};

export type CreateOthersInput = {
  id?: string | null;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
};

export type ModelOthersConditionInput = {
  vendorId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  severity?: ModelStringInput | null;
  description?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelOthersConditionInput | null> | null;
  or?: Array<ModelOthersConditionInput | null> | null;
  not?: ModelOthersConditionInput | null;
};

export type UpdateOthersInput = {
  id: string;
  vendorId?: string | null;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
};

export type DeleteOthersInput = {
  id: string;
};

export type CreateQuestionnaireCompletionInput = {
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelQuestionnaireCompletionConditionInput = {
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  userId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentMap?: ModelStringInput | null;
  firstAnswer?: ModelIntInput | null;
  lastAnswer?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelQuestionnaireCompletionConditionInput | null> | null;
  or?: Array<ModelQuestionnaireCompletionConditionInput | null> | null;
  not?: ModelQuestionnaireCompletionConditionInput | null;
};

export type UpdateQuestionnaireCompletionInput = {
  id: string;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId?: string | null;
  assessmentId?: string | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteQuestionnaireCompletionInput = {
  id: string;
};

export type CreateAnswerInput = {
  id?: string | null;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<S3FileInput | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
};

export enum RiskAction {
  REMEDIATED = "REMEDIATED",
  ACCEPTED = "ACCEPTED",
  IGNORED = "IGNORED",
  MANAGED = "MANAGED"
}

export enum AnswerOrigin {
  INFO_SEC = "INFO_SEC",
  INFO_SEC_AUTO = "INFO_SEC_AUTO",
  GROUPING = "GROUPING",
  REASSESS = "REASSESS"
}

export type ModelAnswerConditionInput = {
  createdAt?: ModelStringInput | null;
  answer?: ModelFloatInput | null;
  answerName?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  riskAction?: ModelRiskActionInput | null;
  frameworkName?: ModelStringInput | null;
  questionId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentID?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  isActionTaken?: ModelBooleanInput | null;
  triggerStopper?: ModelBooleanInput | null;
  origin?: ModelAnswerOriginInput | null;
  auditStatus?: ModelStringInput | null;
  isUptodate?: ModelBooleanInput | null;
  reviewerActionStatus?: ModelReviewerActionsInput | null;
  and?: Array<ModelAnswerConditionInput | null> | null;
  or?: Array<ModelAnswerConditionInput | null> | null;
  not?: ModelAnswerConditionInput | null;
};

export type ModelRiskActionInput = {
  eq?: RiskAction | null;
  ne?: RiskAction | null;
};

export type ModelAnswerOriginInput = {
  eq?: AnswerOrigin | null;
  ne?: AnswerOrigin | null;
};

export type Answer = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<S3File | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: User | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type UpdateAnswerInput = {
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<S3FileInput | null> | null;
  userId?: string | null;
  riskAction?: RiskAction | null;
  frameworkName?: string | null;
  questionId?: string | null;
  date?: number | null;
  assessmentId?: string | null;
  assignmentID?: string | null;
  left?: number | null;
  right?: number | null;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
};

export type DeleteAnswerInput = {
  id: string;
};

export type CreateAssignmentInput = {
  id?: string | null;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type ModelAssignmentConditionInput = {
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  disable?: ModelBooleanInput | null;
  deletedUser?: ModelBooleanInput | null;
  frameworkKey?: ModelStringInput | null;
  and?: Array<ModelAssignmentConditionInput | null> | null;
  or?: Array<ModelAssignmentConditionInput | null> | null;
  not?: ModelAssignmentConditionInput | null;
};

export type UpdateAssignmentInput = {
  id: string;
  userId?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
  left?: number | null;
  right?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type DeleteAssignmentInput = {
  id: string;
};

export type CreateDisableAssignmentInput = {
  id?: string | null;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type ModelDisableAssignmentConditionInput = {
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  disable?: ModelBooleanInput | null;
  and?: Array<ModelDisableAssignmentConditionInput | null> | null;
  or?: Array<ModelDisableAssignmentConditionInput | null> | null;
  not?: ModelDisableAssignmentConditionInput | null;
};

export type DisableAssignment = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: User | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type UpdateDisableAssignmentInput = {
  id: string;
  userId?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
  left?: number | null;
  right?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type DeleteDisableAssignmentInput = {
  id: string;
};

export type CreateAssessmentInput = {
  id?: string | null;
  standardList?: Array<StandardFrameworkInput | null> | null;
  timeline?: TimelineInput | null;
  rootId: string;
  childId: string;
  scores?: ScoresInput | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: ExportsInput | null;
};

export type StandardFrameworkInput = {
  id: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilterInput | null;
};

export type StandardFilterInput = {
  impact?: ImpactEnum | null;
};

export type ExportsInput = {
  jiraEntityIds?: Array<string | null> | null;
  serviceNowEntityIds?: Array<string | null> | null;
};

export type ModelAssessmentConditionInput = {
  rootId?: ModelIDInput | null;
  childId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  managers?: ModelStringInput | null;
  and?: Array<ModelAssessmentConditionInput | null> | null;
  or?: Array<ModelAssessmentConditionInput | null> | null;
  not?: ModelAssessmentConditionInput | null;
};

export type UpdateAssessmentInput = {
  id: string;
  standardList?: Array<StandardFrameworkInput | null> | null;
  timeline?: TimelineInput | null;
  rootId?: string | null;
  childId?: string | null;
  scores?: ScoresInput | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: ExportsInput | null;
};

export type DeleteAssessmentInput = {
  id: string;
};

export type CreateIntegrationMappingsInput = {
  id?: string | null;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
};

export type ModelIntegrationMappingsConditionInput = {
  name?: ModelStringInput | null;
  projectKey?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  and?: Array<ModelIntegrationMappingsConditionInput | null> | null;
  or?: Array<ModelIntegrationMappingsConditionInput | null> | null;
  not?: ModelIntegrationMappingsConditionInput | null;
};

export type IntegrationMappings = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIntegrationMappingsInput = {
  id: string;
  name?: string | null;
  projectKey?: string | null;
  fileName?: string | null;
};

export type DeleteIntegrationMappingsInput = {
  id: string;
};

export type CreateQuestionSettingsInput = {
  id?: string | null;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type ModelQuestionSettingsConditionInput = {
  questionId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  isCollaborative?: ModelBooleanInput | null;
  isArtifactsMandatory?: ModelBooleanInput | null;
  isCommentsMandatory?: ModelBooleanInput | null;
  isApprovalRequired?: ModelBooleanInput | null;
  suggestedArtifactAnswers?: ModelStringInput | null;
  requiredCommentAnswers?: ModelStringInput | null;
  versionFrequency?: ModelStringInput | null;
  isIntegrationActive?: ModelBooleanInput | null;
  isSmartMappingActive?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  isInfoSec?: ModelBooleanInput | null;
  frameworkName?: ModelStringInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  and?: Array<ModelQuestionSettingsConditionInput | null> | null;
  or?: Array<ModelQuestionSettingsConditionInput | null> | null;
  not?: ModelQuestionSettingsConditionInput | null;
};

export type QuestionSettings = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type UpdateQuestionSettingsInput = {
  id: string;
  questionId?: string | null;
  assessmentId?: string | null;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type DeleteQuestionSettingsInput = {
  id: string;
};

export type CreateTaskActionInput = {
  id?: string | null;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<ConnectedControlInput | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
};

export type ConnectedControlInput = {
  id?: string | null;
  name?: string | null;
  label?: string | null;
};

export type ModelTaskActionConditionInput = {
  createdAt?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  action?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  taskAssignmentID?: ModelIDInput | null;
  questionId?: ModelIDInput | null;
  frameworkName?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  triggerStopper?: ModelBooleanInput | null;
  searchString?: ModelStringInput | null;
  questionIds?: ModelIDInput | null;
  smartMappingIds?: ModelStringInput | null;
  title?: ModelStringInput | null;
  impact?: ModelStringInput | null;
  maturity?: ModelFloatInput | null;
  type?: ModelStringInput | null;
  description?: ModelStringInput | null;
  tabName?: ModelStringInput | null;
  managerAction?: ModelStringInput | null;
  rootEntityId?: ModelIDInput | null;
  dueDate?: ModelIntInput | null;
  and?: Array<ModelTaskActionConditionInput | null> | null;
  or?: Array<ModelTaskActionConditionInput | null> | null;
  not?: ModelTaskActionConditionInput | null;
};

export type TaskAction = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: User | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<ConnectedControl | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type ConnectedControl = {
  __typename: "ConnectedControl";
  id?: string | null;
  name?: string | null;
  label?: string | null;
};

export type UpdateTaskActionInput = {
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId?: string | null;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName?: string | null;
  userId?: string | null;
  date?: number | null;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<ConnectedControlInput | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
};

export type DeleteTaskActionInput = {
  id: string;
};

export type CreateUsersAssignmentMapperInput = {
  id?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
};

export type ModelUsersAssignmentMapperConditionInput = {
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  assessmentId?: ModelIDInput | null;
  taskId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelUsersAssignmentMapperConditionInput | null> | null;
  or?: Array<ModelUsersAssignmentMapperConditionInput | null> | null;
  not?: ModelUsersAssignmentMapperConditionInput | null;
};

export type UsersAssignmentMapper = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: User | null;
};

export type UpdateUsersAssignmentMapperInput = {
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
};

export type DeleteUsersAssignmentMapperInput = {
  id: string;
};

export type CreateFrameworkSettingsInput = {
  id?: string | null;
  settings?: string | null;
};

export type ModelFrameworkSettingsConditionInput = {
  settings?: ModelStringInput | null;
  and?: Array<ModelFrameworkSettingsConditionInput | null> | null;
  or?: Array<ModelFrameworkSettingsConditionInput | null> | null;
  not?: ModelFrameworkSettingsConditionInput | null;
};

export type FrameworkSettings = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFrameworkSettingsInput = {
  id: string;
  settings?: string | null;
};

export type DeleteFrameworkSettingsInput = {
  id: string;
};

export type CreateCustomTaskInput = {
  id?: string | null;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
};

export enum TaskStatusEnum {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  IGNORED = "IGNORED"
}

export type ModelCustomTaskConditionInput = {
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  impact?: ModelImpactEnumInput | null;
  description?: ModelStringInput | null;
  status?: ModelTaskStatusEnumInput | null;
  assessmentId?: ModelIDInput | null;
  customTodo?: ModelStringInput | null;
  dueDate?: ModelIntInput | null;
  and?: Array<ModelCustomTaskConditionInput | null> | null;
  or?: Array<ModelCustomTaskConditionInput | null> | null;
  not?: ModelCustomTaskConditionInput | null;
};

export type ModelTaskStatusEnumInput = {
  eq?: TaskStatusEnum | null;
  ne?: TaskStatusEnum | null;
};

export type CustomTask = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<Qualification | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomTaskInput = {
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId?: string | null;
  customTodo?: string | null;
  dueDate?: number | null;
};

export type DeleteCustomTaskInput = {
  id: string;
};

export type CreateCustomTaskAssignmentInput = {
  id?: string | null;
  userId: string;
  targetId: string;
  assessmentId: string;
};

export type ModelCustomTaskAssignmentConditionInput = {
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  and?: Array<ModelCustomTaskAssignmentConditionInput | null> | null;
  or?: Array<ModelCustomTaskAssignmentConditionInput | null> | null;
  not?: ModelCustomTaskAssignmentConditionInput | null;
};

export type CustomTaskAssignment = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: User | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomTaskAssignmentInput = {
  id: string;
  userId?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
};

export type DeleteCustomTaskAssignmentInput = {
  id: string;
};

export type CreateAssessmentStandardFrameworkInput = {
  id?: string | null;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilterInput | null;
  timeline?: TimelineInput | null;
  assessmentId: string;
  managerId?: string | null;
  selectedChapters?: Array<string | null> | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: EntityQuestionSettingsInput | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signature?: SignatureObjectInput | null;
  lockedByUserID?: string | null;
};

export type SignatureObjectInput = {
  title?: string | null;
  printName?: string | null;
  disclaimerDate?: number | null;
  image?: string | null;
  text?: string | null;
  draw?: string | null;
  signDate?: string | null;
};

export type ModelAssessmentStandardFrameworkConditionInput = {
  key?: ModelStringInput | null;
  type?: ModelStandardTypeInput | null;
  fileName?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  selectedChapters?: ModelStringInput | null;
  not_added?: ModelBooleanInput | null;
  archived?: ModelBooleanInput | null;
  reassessmentStatus?: ModelReassessmentEnumInput | null;
  documentCategory?: ModelDocumentTypeInput | null;
  documentType?: ModelStringInput | null;
  suggestedArtifact?: ModelStringInput | null;
  suggestedComments?: ModelStringInput | null;
  level?: ModelStringInput | null;
  newStartReassessDate?: ModelIntInput | null;
  signatureUserID?: ModelIDInput | null;
  isLocked?: ModelBooleanInput | null;
  lockedByUserID?: ModelIDInput | null;
  and?: Array<ModelAssessmentStandardFrameworkConditionInput | null> | null;
  or?: Array<ModelAssessmentStandardFrameworkConditionInput | null> | null;
  not?: ModelAssessmentStandardFrameworkConditionInput | null;
};

export type ModelReassessmentEnumInput = {
  eq?: ReassessmentEnum | null;
  ne?: ReassessmentEnum | null;
};

export type ModelDocumentTypeInput = {
  eq?: DocumentType | null;
  ne?: DocumentType | null;
};

export type UpdateAssessmentStandardFrameworkInput = {
  id: string;
  key?: string | null;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilterInput | null;
  timeline?: TimelineInput | null;
  assessmentId?: string | null;
  managerId?: string | null;
  selectedChapters?: Array<string | null> | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: EntityQuestionSettingsInput | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signature?: SignatureObjectInput | null;
  lockedByUserID?: string | null;
};

export type DeleteAssessmentStandardFrameworkInput = {
  id: string;
};

export type CreateFrameworkManagerInput = {
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
};

export type ModelFrameworkManagerConditionInput = {
  assessmentId?: ModelIDInput | null;
  standardFrameworkId?: ModelStringInput | null;
  managerId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  removedQuestions?: ModelStringInput | null;
  and?: Array<ModelFrameworkManagerConditionInput | null> | null;
  or?: Array<ModelFrameworkManagerConditionInput | null> | null;
  not?: ModelFrameworkManagerConditionInput | null;
};

export type UpdateFrameworkManagerInput = {
  id: string;
  assessmentId?: string | null;
  standardFrameworkId?: string | null;
  managerId?: string | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
};

export type DeleteFrameworkManagerInput = {
  id: string;
};

export type CreateCommentInput = {
  id?: string | null;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
};

export enum ExportSourceEnum {
  CYGOV = "CYGOV",
  JIRA = "JIRA",
  SERVICENOW = "SERVICENOW"
}

export type ModelCommentConditionInput = {
  latestSource?: ModelExportSourceEnumInput | null;
  comment?: ModelStringInput | null;
  date?: ModelIntInput | null;
  questionId?: ModelStringInput | null;
  riskId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  type?: ModelStringInput | null;
  sType?: ModelStringInput | null;
  framework?: ModelStringInput | null;
  srcFramework?: ModelStringInput | null;
  and?: Array<ModelCommentConditionInput | null> | null;
  or?: Array<ModelCommentConditionInput | null> | null;
  not?: ModelCommentConditionInput | null;
};

export type ModelExportSourceEnumInput = {
  eq?: ExportSourceEnum | null;
  ne?: ExportSourceEnum | null;
};

export type Comment = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: ModelExportConnection | null;
  user?: ModelUserConnection | null;
  archivedUser?: ArchivedUser | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ModelExportConnection = {
  __typename: "ModelExportConnection";
  items: Array<Export | null>;
  nextToken?: string | null;
};

export type Export = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export enum CategoryEnum {
  ANSWER = "ANSWER",
  COMMENT = "COMMENT",
  ENTITY = "ENTITY"
}

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type UpdateCommentInput = {
  id: string;
  latestSource?: ExportSourceEnum | null;
  comment?: string | null;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId?: string | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
};

export type DeleteCommentInput = {
  id: string;
};

export type CreateExportInput = {
  id?: string | null;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
};

export type ModelExportConditionInput = {
  destination?: ModelExportSourceEnumInput | null;
  destinationCategoryId?: ModelIDInput | null;
  category?: ModelCategoryEnumInput | null;
  categoryId?: ModelStringInput | null;
  date?: ModelIntInput | null;
  and?: Array<ModelExportConditionInput | null> | null;
  or?: Array<ModelExportConditionInput | null> | null;
  not?: ModelExportConditionInput | null;
};

export type ModelCategoryEnumInput = {
  eq?: CategoryEnum | null;
  ne?: CategoryEnum | null;
};

export type UpdateExportInput = {
  id: string;
  destination?: ExportSourceEnum | null;
  destinationCategoryId?: string | null;
  category?: CategoryEnum | null;
  categoryId?: string | null;
  date?: number | null;
};

export type DeleteExportInput = {
  id: string;
};

export type CreateReportInput = {
  id?: string | null;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
};

export enum ReportTypeEnum {
  QUARTERLY = "QUARTERLY",
  MONTHLY = "MONTHLY",
  CUSTOM = "CUSTOM"
}

export type ModelReportConditionInput = {
  entityId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  managerName?: ModelStringInput | null;
  startDate?: ModelIntInput | null;
  endDate?: ModelIntInput | null;
  type?: ModelReportTypeEnumInput | null;
  isDraft?: ModelBooleanInput | null;
  previousReports?: ModelIDInput | null;
  createdAt?: ModelIntInput | null;
  and?: Array<ModelReportConditionInput | null> | null;
  or?: Array<ModelReportConditionInput | null> | null;
  not?: ModelReportConditionInput | null;
};

export type ModelReportTypeEnumInput = {
  eq?: ReportTypeEnum | null;
  ne?: ReportTypeEnum | null;
};

export type Report = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type UpdateReportInput = {
  id: string;
  entityId?: string | null;
  name?: string | null;
  managerName?: string | null;
  startDate?: number | null;
  endDate?: number | null;
  type?: ReportTypeEnum | null;
  isDraft?: boolean | null;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
};

export type DeleteReportInput = {
  id: string;
};

export type CreateRiskInput = {
  id?: string | null;
  idTitle: string;
  entityId?: string | null;
  subEntityId: string;
  assessmentId: string;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<RiskFinancialImpactInput | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<RiskQuestionScoreInput | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<RiskTaskObjectInput | null> | null;
  riskControlNames?: Array<RiskManualControlInput | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export enum RiskTypeEnum {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
  HARD_MANUAL = "HARD_MANUAL",
  AI = "AI"
}

export enum RiskProbabilityEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL"
}

export enum RiskStatusEnum {
  MITIGATED = "MITIGATED",
  ACCEPTED = "ACCEPTED",
  TRANSFERRED = "TRANSFERRED",
  OPEN = "OPEN",
  ESCALATED = "ESCALATED"
}

export enum RiskStatusUpdatedByEnum {
  PLATFORM = "PLATFORM",
  OWNER = "OWNER"
}

export enum RiskCostRemediationEnum {
  LOW = "LOW",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM"
}

export enum RiskPriorityEnum {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH"
}

export type RiskQuestionScoreInput = {
  controlId?: string | null;
  questionOrder?: number | null;
  score?: number | null;
  remediationStatus?: string | null;
  impact?: boolean | null;
  probability?: boolean | null;
  weight?: number | null;
  left?: number | null;
  right?: number | null;
};

export type RiskTaskObjectInput = {
  title?: string | null;
  status?: TaskStatusEnum | null;
  id?: string | null;
};

export type RiskManualControlInput = {
  title?: string | null;
  id?: string | null;
  mitigationPlan?: string | null;
  label?: string | null;
};

export type ModelRiskConditionInput = {
  idTitle?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  subEntityId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  type?: ModelRiskTypeEnumInput | null;
  disable?: ModelBooleanInput | null;
  controlIds?: ModelStringInput | null;
  riskTitle?: ModelStringInput | null;
  riskDescription?: ModelStringInput | null;
  riskCategory?: ModelStringInput | null;
  relatedAssets?: ModelStringInput | null;
  residualImpact?: ModelRiskImpactEnumInput | null;
  residualProbability?: ModelRiskProbabilityEnumInput | null;
  riskImpact?: ModelRiskImpactEnumInput | null;
  riskProbability?: ModelRiskProbabilityEnumInput | null;
  inherentRisk?: ModelFloatInput | null;
  residualRisk?: ModelFloatInput | null;
  riskStatus?: ModelRiskStatusEnumInput | null;
  riskStatusUpdatedBy?: ModelRiskStatusUpdatedByEnumInput | null;
  notes?: ModelStringInput | null;
  costToRemediate?: ModelIntInput | null;
  costToRemediateType?: ModelRiskCostRemediationEnumInput | null;
  primaryLoss?: ModelIntInput | null;
  riskPriority?: ModelRiskPriorityEnumInput | null;
  hidden?: ModelBooleanInput | null;
  financialToggle?: ModelBooleanInput | null;
  riskTags?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  updatedAt?: ModelIntInput | null;
  riskTasksIds?: ModelStringInput | null;
  isArchived?: ModelBooleanInput | null;
  isEscalated?: ModelBooleanInput | null;
  sensitivity?: ModelFloatInput | null;
  effectiveness?: ModelFloatInput | null;
  deadline?: ModelIntInput | null;
  riskOwnerIds?: ModelStringInput | null;
  and?: Array<ModelRiskConditionInput | null> | null;
  or?: Array<ModelRiskConditionInput | null> | null;
  not?: ModelRiskConditionInput | null;
};

export type ModelRiskTypeEnumInput = {
  eq?: RiskTypeEnum | null;
  ne?: RiskTypeEnum | null;
};

export type ModelRiskProbabilityEnumInput = {
  eq?: RiskProbabilityEnum | null;
  ne?: RiskProbabilityEnum | null;
};

export type ModelRiskStatusEnumInput = {
  eq?: RiskStatusEnum | null;
  ne?: RiskStatusEnum | null;
};

export type ModelRiskStatusUpdatedByEnumInput = {
  eq?: RiskStatusUpdatedByEnum | null;
  ne?: RiskStatusUpdatedByEnum | null;
};

export type ModelRiskCostRemediationEnumInput = {
  eq?: RiskCostRemediationEnum | null;
  ne?: RiskCostRemediationEnum | null;
};

export type ModelRiskPriorityEnumInput = {
  eq?: RiskPriorityEnum | null;
  ne?: RiskPriorityEnum | null;
};

export type Risk = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: Entity | null;
  subEntityId: string;
  subEntity?: Entity | null;
  assessmentId: string;
  assessment?: Assessment | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<RiskFinancialImpact | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<RiskQuestionScore | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<RiskTaskObject | null> | null;
  riskControlNames?: Array<RiskManualControl | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type RiskQuestionScore = {
  __typename: "RiskQuestionScore";
  controlId?: string | null;
  questionOrder?: number | null;
  score?: number | null;
  remediationStatus?: string | null;
  impact?: boolean | null;
  probability?: boolean | null;
  weight?: number | null;
  left?: number | null;
  right?: number | null;
};

export type RiskTaskObject = {
  __typename: "RiskTaskObject";
  title?: string | null;
  status?: TaskStatusEnum | null;
  id?: string | null;
};

export type RiskManualControl = {
  __typename: "RiskManualControl";
  title?: string | null;
  id?: string | null;
  mitigationPlan?: string | null;
  label?: string | null;
};

export type UpdateRiskInput = {
  id: string;
  idTitle?: string | null;
  entityId?: string | null;
  subEntityId?: string | null;
  assessmentId?: string | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle?: string | null;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact?: RiskImpactEnum | null;
  riskProbability?: RiskProbabilityEnum | null;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus?: RiskStatusEnum | null;
  riskStatusUpdatedBy?: RiskStatusUpdatedByEnum | null;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType?: RiskCostRemediationEnum | null;
  primaryLoss?: number | null;
  financialImpact?: Array<RiskFinancialImpactInput | null> | null;
  riskPriority?: RiskPriorityEnum | null;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<RiskQuestionScoreInput | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<RiskTaskObjectInput | null> | null;
  riskControlNames?: Array<RiskManualControlInput | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type DeleteRiskInput = {
  id: string;
};

export type CreateRiskOwnerAssignmentInput = {
  id?: string | null;
  subEntityId: string;
  userId: string;
  riskId: string;
};

export type ModelRiskOwnerAssignmentConditionInput = {
  subEntityId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  riskId?: ModelIDInput | null;
  and?: Array<ModelRiskOwnerAssignmentConditionInput | null> | null;
  or?: Array<ModelRiskOwnerAssignmentConditionInput | null> | null;
  not?: ModelRiskOwnerAssignmentConditionInput | null;
};

export type RiskOwnerAssignment = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: Risk | null;
  user?: User | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRiskOwnerAssignmentInput = {
  id: string;
  subEntityId?: string | null;
  userId?: string | null;
  riskId?: string | null;
};

export type DeleteRiskOwnerAssignmentInput = {
  id: string;
};

export type CreateSavedFilterInput = {
  id?: string | null;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
};

export enum FilterTypeEnum {
  RISK = "RISK",
  COLLECTION = "COLLECTION",
  CONTROLS = "CONTROLS"
}

export type ModelSavedFilterConditionInput = {
  filterObject?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  entityId?: ModelIDInput | null;
  type?: ModelFilterTypeEnumInput | null;
  and?: Array<ModelSavedFilterConditionInput | null> | null;
  or?: Array<ModelSavedFilterConditionInput | null> | null;
  not?: ModelSavedFilterConditionInput | null;
};

export type ModelFilterTypeEnumInput = {
  eq?: FilterTypeEnum | null;
  ne?: FilterTypeEnum | null;
};

export type SavedFilter = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSavedFilterInput = {
  id: string;
  filterObject?: string | null;
  userId?: string | null;
  entityId?: string | null;
  type?: FilterTypeEnum | null;
};

export type DeleteSavedFilterInput = {
  id: string;
};

export type CreateDomainSettingsInput = {
  id?: string | null;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type ModelDomainSettingsConditionInput = {
  domain?: ModelStringInput | null;
  key?: ModelStringInput | null;
  average?: ModelFloatInput | null;
  allowedRoutes?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  allowedFeatures?: ModelStringInput | null;
  settings?: ModelStringInput | null;
  kmsStatus?: ModelBooleanInput | null;
  and?: Array<ModelDomainSettingsConditionInput | null> | null;
  or?: Array<ModelDomainSettingsConditionInput | null> | null;
  not?: ModelDomainSettingsConditionInput | null;
};

export type DomainSettings = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type UpdateDomainSettingsInput = {
  id: string;
  domain?: string | null;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type DeleteDomainSettingsInput = {
  id: string;
};

export type CreateExecutiveSummaryInput = {
  id?: string | null;
  entityId: string;
  title: string;
  subjectList?: Array<SummarySubjectInput | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type SummarySubjectInput = {
  id: string;
  title: string;
  description: string;
  toggle: boolean;
  createdAt?: number | null;
  updatedAt?: number | null;
};

export type ModelExecutiveSummaryConditionInput = {
  entityId?: ModelStringInput | null;
  title?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelExecutiveSummaryConditionInput | null> | null;
  or?: Array<ModelExecutiveSummaryConditionInput | null> | null;
  not?: ModelExecutiveSummaryConditionInput | null;
};

export type ExecutiveSummary = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<SummarySubject | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type SummarySubject = {
  __typename: "SummarySubject";
  id: string;
  title: string;
  description: string;
  toggle: boolean;
  createdAt?: number | null;
  updatedAt?: number | null;
};

export type UpdateExecutiveSummaryInput = {
  id: string;
  entityId?: string | null;
  title?: string | null;
  subjectList?: Array<SummarySubjectInput | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteExecutiveSummaryInput = {
  id: string;
};

export type CreateNetskopeProjectInput = {
  id?: string | null;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: CurrentProgressInput | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum ProgressStatusEnum {
  ON_SCHEDULE = "ON_SCHEDULE",
  SLIGHTLY_BEHIND_SCHEDULE = "SLIGHTLY_BEHIND_SCHEDULE",
  BEHIND_SCHEDULE = "BEHIND_SCHEDULE"
}

export type CurrentProgressInput = {
  startDate: number;
  endDate: number;
  status: string;
};

export type ModelNetskopeProjectConditionInput = {
  entityId?: ModelStringInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  previous?: ModelProgressStatusEnumInput | null;
  budget?: ModelIntInput | null;
  comments?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNetskopeProjectConditionInput | null> | null;
  or?: Array<ModelNetskopeProjectConditionInput | null> | null;
  not?: ModelNetskopeProjectConditionInput | null;
};

export type ModelProgressStatusEnumInput = {
  eq?: ProgressStatusEnum | null;
  ne?: ProgressStatusEnum | null;
};

export type NetskopeProject = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: CurrentProgress | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CurrentProgress = {
  __typename: "CurrentProgress";
  startDate: number;
  endDate: number;
  status: string;
};

export type UpdateNetskopeProjectInput = {
  id: string;
  entityId?: string | null;
  title?: string | null;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: CurrentProgressInput | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteNetskopeProjectInput = {
  id: string;
};

export type CreateEntityLayersInput = {
  id?: string | null;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: BnbEntityScoreInput;
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: S3FileInput | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export enum BnbLayerEnum {
  ENTERPRISE = "ENTERPRISE",
  DIVISION = "DIVISION",
  REGION = "REGION"
}

export type BnbEntityScoreInput = {
  total: number;
  collection: number;
  remediation: number;
};

export type ModelEntityLayersConditionInput = {
  name?: ModelStringInput | null;
  managerName?: ModelStringInput | null;
  type?: ModelBnbLayerEnumInput | null;
  inherent?: ModelStringInput | null;
  residual?: ModelStringInput | null;
  parentId?: ModelIDInput | null;
  srName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  domain?: ModelStringInput | null;
  and?: Array<ModelEntityLayersConditionInput | null> | null;
  or?: Array<ModelEntityLayersConditionInput | null> | null;
  not?: ModelEntityLayersConditionInput | null;
};

export type ModelBnbLayerEnumInput = {
  eq?: BnbLayerEnum | null;
  ne?: BnbLayerEnum | null;
};

export type EntityLayers = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: BnbEntityScore;
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: S3File | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type BnbEntityScore = {
  __typename: "BnbEntityScore";
  total: number;
  collection: number;
  remediation: number;
};

export type UpdateEntityLayersInput = {
  id: string;
  name?: string | null;
  managerName?: string | null;
  type?: BnbLayerEnum | null;
  score?: BnbEntityScoreInput | null;
  inherent?: string | null;
  residual?: string | null;
  parentId?: string | null;
  logo?: S3FileInput | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type DeleteEntityLayersInput = {
  id: string;
};

export type CreateEntityWizardInput = {
  id?: string | null;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  parentId?: string | null;
  score: BnbEntityScoreInput;
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type ModelEntityWizardConditionInput = {
  isDraft?: ModelBooleanInput | null;
  organizational?: ModelStringInput | null;
  financial?: ModelStringInput | null;
  frameworks?: ModelStringInput | null;
  dataScope?: ModelStringInput | null;
  technical?: ModelStringInput | null;
  riskScenarios?: ModelStringInput | null;
  accountDetails?: ModelStringInput | null;
  currentYear?: ModelStringInput | null;
  parentId?: ModelIDInput | null;
  inherent?: ModelStringInput | null;
  residual?: ModelStringInput | null;
  domainScanned?: ModelBooleanInput | null;
  domainScanTimes?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  riskFramework?: ModelStringInput | null;
  clientDomain?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  applicantInfo?: ModelStringInput | null;
  revenueDetails?: ModelStringInput | null;
  dataInventory?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  and?: Array<ModelEntityWizardConditionInput | null> | null;
  or?: Array<ModelEntityWizardConditionInput | null> | null;
  not?: ModelEntityWizardConditionInput | null;
};

export type EntityWizard = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: Entity | null;
  parentId?: string | null;
  score: BnbEntityScore;
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type UpdateEntityWizardInput = {
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  parentId?: string | null;
  score?: BnbEntityScoreInput | null;
  inherent?: string | null;
  residual?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain?: string | null;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type DeleteEntityWizardInput = {
  id: string;
};

export type CreateLogsInput = {
  id?: string | null;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
};

export type ModelLogsConditionInput = {
  message?: ModelStringInput | null;
  type?: ModelStringInput | null;
  targetId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelLogsConditionInput | null> | null;
  or?: Array<ModelLogsConditionInput | null> | null;
  not?: ModelLogsConditionInput | null;
};

export type Logs = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: User | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateLogsInput = {
  id: string;
  message?: string | null;
  type?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
  userID?: string | null;
};

export type DeleteLogsInput = {
  id: string;
};

export type CreateVersionsInput = {
  id?: string | null;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
};

export type ModelVersionsConditionInput = {
  data?: ModelStringInput | null;
  type?: ModelStringInput | null;
  targetId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  and?: Array<ModelVersionsConditionInput | null> | null;
  or?: Array<ModelVersionsConditionInput | null> | null;
  not?: ModelVersionsConditionInput | null;
};

export type Versions = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVersionsInput = {
  id: string;
  data?: string | null;
  type?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
};

export type DeleteVersionsInput = {
  id: string;
};

export type CreateDataStateInput = {
  id?: string | null;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
};

export enum StateStatusEnum {
  COMPLETED = "COMPLETED",
  IN_PROCESS = "IN_PROCESS",
  PENDING = "PENDING",
  FAILED = "FAILED"
}

export enum StateTypeEnum {
  VENDOR = "VENDOR"
}

export type ModelDataStateConditionInput = {
  state?: ModelStateStatusEnumInput | null;
  parentId?: ModelStringInput | null;
  message?: ModelStringInput | null;
  detail?: ModelStringInput | null;
  type?: ModelStateTypeEnumInput | null;
  and?: Array<ModelDataStateConditionInput | null> | null;
  or?: Array<ModelDataStateConditionInput | null> | null;
  not?: ModelDataStateConditionInput | null;
};

export type ModelStateStatusEnumInput = {
  eq?: StateStatusEnum | null;
  ne?: StateStatusEnum | null;
};

export type ModelStateTypeEnumInput = {
  eq?: StateTypeEnum | null;
  ne?: StateTypeEnum | null;
};

export type DataState = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDataStateInput = {
  id: string;
  state?: StateStatusEnum | null;
  parentId?: string | null;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
};

export type DeleteDataStateInput = {
  id: string;
};

export type CreateSmartMappingDetailInput = {
  id?: string | null;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSmartMappingDetailConditionInput = {
  uniqueId?: ModelIDInput | null;
  metadata?: ModelStringInput | null;
  answer?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSmartMappingDetailConditionInput | null> | null;
  or?: Array<ModelSmartMappingDetailConditionInput | null> | null;
  not?: ModelSmartMappingDetailConditionInput | null;
};

export type SmartMappingDetail = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSmartMappingDetailInput = {
  id: string;
  uniqueId?: string | null;
  metadata?: string | null;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSmartMappingDetailInput = {
  id: string;
};

export type CreateApiCallRegistryInput = {
  id?: string | null;
  callMaker: string;
  createdAt?: string | null;
};

export type ModelApiCallRegistryConditionInput = {
  callMaker?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelApiCallRegistryConditionInput | null> | null;
  or?: Array<ModelApiCallRegistryConditionInput | null> | null;
  not?: ModelApiCallRegistryConditionInput | null;
};

export type ApiCallRegistry = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateApiCallRegistryInput = {
  id: string;
  callMaker?: string | null;
  createdAt?: string | null;
};

export type DeleteApiCallRegistryInput = {
  id: string;
};

export type CreateBreachDataInput = {
  id?: string | null;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
};

export type ModelBreachDataConditionInput = {
  domain?: ModelStringInput | null;
  breaches?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelBreachDataConditionInput | null> | null;
  or?: Array<ModelBreachDataConditionInput | null> | null;
  not?: ModelBreachDataConditionInput | null;
};

export type BreachData = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateBreachDataInput = {
  id: string;
  domain?: string | null;
  breaches?: string | null;
  createdAt?: string | null;
};

export type DeleteBreachDataInput = {
  id: string;
};

export type Alert = {
  __typename: "Alert";
  id?: string | null;
  type?: string | null;
  content?: string | null;
  priority?: number | null;
};

export type JiraAuthInput = {
  host: string;
  email: string;
  apiToken: string;
  project?: string | null;
  bearerTokenAuth?: boolean | null;
};

export type ServiceNowAuthInput = {
  host: string;
  username: string;
  password: string;
  clientSecret: string;
  clientId: string;
};

export type Rapid7Input = {
  entityId: string;
  action: string;
  siteId?: string | null;
  page?: number | null;
  activePluginId?: string | null;
  independentWithoutEntity?: boolean | null;
  origin?: string | null;
};

export type TenableDetailsInput = {
  query?: QueryAnalysis | null;
  sourceType?: string | null;
  type?: string | null;
  scanID?: string | null;
};

export type QueryAnalysis = {
  name?: string | null;
  type?: string | null;
  tool?: string | null;
  startOffset?: number | null;
  endOffset?: number | null;
  filters?: Array<FilterObject | null> | null;
  view?: string | null;
};

export type FilterObject = {
  filterName?: string | null;
  operator?: string | null;
  value?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  roleId?: ModelIDInput | null;
  isViewOnly?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  entityIds?: ModelStringInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  lastLogin?: ModelIntInput | null;
  invitationDate?: ModelIntInput | null;
  reminderDate?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  domain?: ModelStringInput | null;
  temporaryHigherRank?: ModelBooleanInput | null;
  entityLayerIds?: ModelStringInput | null;
  clickThrough?: ModelBooleanInput | null;
  clickThroughDate?: ModelStringInput | null;
  projectManagerEmail?: ModelStringInput | null;
  isGlobalParticipant?: ModelBooleanInput | null;
  onBoardingStatus?: ModelMidMarketEnumInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelTagsFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  domain?: ModelStringInput | null;
  targetId?: ModelStringInput | null;
  assessmentFrameworkKey?: ModelStringInput | null;
  referenceId?: ModelIDInput | null;
  questionRefId?: ModelIDInput | null;
  and?: Array<ModelTagsFilterInput | null> | null;
  or?: Array<ModelTagsFilterInput | null> | null;
  not?: ModelTagsFilterInput | null;
};

export type ModelTagsConnection = {
  __typename: "ModelTagsConnection";
  items: Array<Tags | null>;
  nextToken?: string | null;
};

export type ModelRoleFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  entityIds?: ModelStringInput | null;
  vendorIds?: ModelStringInput | null;
  isRootEntityAccess?: ModelBooleanInput | null;
  defaultOrEntityId?: ModelStringInput | null;
  screenPermissions?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  parentId?: ModelStringInput | null;
  and?: Array<ModelRoleFilterInput | null> | null;
  or?: Array<ModelRoleFilterInput | null> | null;
  not?: ModelRoleFilterInput | null;
};

export type ModelRoleConnection = {
  __typename: "ModelRoleConnection";
  items: Array<Role | null>;
  nextToken?: string | null;
};

export type ModelRiskLineChartFilterInput = {
  id?: ModelIDInput | null;
  assessmentId?: ModelStringInput | null;
  riskId?: ModelStringInput | null;
  residual?: ModelRiskImpactEnumInput | null;
  inherent?: ModelRiskImpactEnumInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelRiskLineChartFilterInput | null> | null;
  or?: Array<ModelRiskLineChartFilterInput | null> | null;
  not?: ModelRiskLineChartFilterInput | null;
};

export type ModelRiskLineChartConnection = {
  __typename: "ModelRiskLineChartConnection";
  items: Array<RiskLineChart | null>;
  nextToken?: string | null;
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  rootId?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  and?: Array<ModelGroupFilterInput | null> | null;
  or?: Array<ModelGroupFilterInput | null> | null;
  not?: ModelGroupFilterInput | null;
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection";
  items: Array<Group | null>;
  nextToken?: string | null;
};

export type ModelQuestionDataFilterInput = {
  id?: ModelIDInput | null;
  childId?: ModelStringInput | null;
  frameworkKey?: ModelStringInput | null;
  function?: ModelStringInput | null;
  category?: ModelStringInput | null;
  subCategory?: ModelStringInput | null;
  controlNameLabel?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  rootId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  reviewStatus?: ModelReviewerActionsInput | null;
  resentBackStatus?: ModelBooleanInput | null;
  and?: Array<ModelQuestionDataFilterInput | null> | null;
  or?: Array<ModelQuestionDataFilterInput | null> | null;
  not?: ModelQuestionDataFilterInput | null;
};

export type ModelQuestionDataConnection = {
  __typename: "ModelQuestionDataConnection";
  items: Array<QuestionData | null>;
  nextToken?: string | null;
};

export type ModelArchivedUserFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  domain?: ModelStringInput | null;
  and?: Array<ModelArchivedUserFilterInput | null> | null;
  or?: Array<ModelArchivedUserFilterInput | null> | null;
  not?: ModelArchivedUserFilterInput | null;
};

export type ModelArchivedUserConnection = {
  __typename: "ModelArchivedUserConnection";
  items: Array<ArchivedUser | null>;
  nextToken?: string | null;
};

export type ModelEntityFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  users?: ModelIDInput | null;
  completionStatus?: ModelCollectionStatusEnumInput | null;
  accessGroups?: ModelStringInput | null;
  participantGroup?: ModelStringInput | null;
  rootEntityId?: ModelIDInput | null;
  childEntityId?: ModelIDInput | null;
  frameworkId?: ModelIDInput | null;
  frameworkName?: ModelStringInput | null;
  frameworkType?: ModelEntityTypeEnumInput | null;
  parentId?: ModelIDInput | null;
  entityType?: ModelEntityTypeEnumInput | null;
  projectManager?: ModelIDInput | null;
  projectManagerName?: ModelStringInput | null;
  projectDeadline?: ModelIntInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  industry?: ModelStringInput | null;
  domain?: ModelStringInput | null;
  activeAssessmentId?: ModelIDInput | null;
  tierSelected?: ModelIntInput | null;
  scName?: ModelStringInput | null;
  upperdeckRoute?: ModelStringInput | null;
  isLocked?: ModelBooleanInput | null;
  eSignFinalized?: ModelBooleanInput | null;
  weightageScore?: ModelFloatInput | null;
  upperdeckSetting?: ModelStringInput | null;
  domainScanned?: ModelBooleanInput | null;
  domainScanTimes?: ModelStringInput | null;
  domainScanEnabledForEntity?: ModelBooleanInput | null;
  frameworkSettings?: ModelStringInput | null;
  usedFrameworkUniqueCount?: ModelIntInput | null;
  spiderChartSetting?: ModelStringInput | null;
  isVendorInLimit?: ModelBooleanInput | null;
  and?: Array<ModelEntityFilterInput | null> | null;
  or?: Array<ModelEntityFilterInput | null> | null;
  not?: ModelEntityFilterInput | null;
};

export type ModelEntityConnection = {
  __typename: "ModelEntityConnection";
  items: Array<Entity | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput = {
  eq?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  le?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  lt?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  ge?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  gt?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  between?: Array<ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null> | null;
  beginsWith?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
};

export type ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput = {
  frameworkType?: EntityTypeEnum | null;
  frameworkName?: string | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelGraphFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  rootId?: ModelStringInput | null;
  status?: ModelStringInput | null;
  entities?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  timeSpan?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelGraphFilterInput | null> | null;
  or?: Array<ModelGraphFilterInput | null> | null;
  not?: ModelGraphFilterInput | null;
};

export type ModelGraphConnection = {
  __typename: "ModelGraphConnection";
  items: Array<Graph | null>;
  nextToken?: string | null;
};

export type ModelRevisionFilterInput = {
  id?: ModelIDInput | null;
  frameworkKey?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  groupId?: ModelStringInput | null;
  date?: ModelStringInput | null;
  rootId?: ModelStringInput | null;
  tagId?: ModelStringInput | null;
  scores?: ModelStringInput | null;
  and?: Array<ModelRevisionFilterInput | null> | null;
  or?: Array<ModelRevisionFilterInput | null> | null;
  not?: ModelRevisionFilterInput | null;
};

export type ModelRevisionConnection = {
  __typename: "ModelRevisionConnection";
  items: Array<Revision | null>;
  nextToken?: string | null;
};

export type ModelVendorsDetailFilterInput = {
  id?: ModelIDInput | null;
  impact?: ModelIntInput | null;
  externalScan?: ModelFloatInput | null;
  financialRisk?: ModelFloatInput | null;
  criticalGaps?: ModelIntInput | null;
  status?: ModelVendorStatusEnumInput | null;
  probability?: ModelIntInput | null;
  website?: ModelStringInput | null;
  location?: ModelStringInput | null;
  size?: ModelStringInput | null;
  description?: ModelStringInput | null;
  businessPointOfContactId?: ModelStringInput | null;
  accessLevel?: ModelAccessLevelEnumInput | null;
  collectionStatus?: ModelCollectionStatusEnumInput | null;
  approvalDate?: ModelIntInput | null;
  stage?: ModelVendorStatusEnumInput | null;
  activeScan?: ModelBooleanInput | null;
  domain?: ModelStringInput | null;
  privacyData?: ModelBooleanInput | null;
  primaryLoss?: ModelFloatInput | null;
  smartTags?: ModelStringInput | null;
  employees?: ModelIntInput | null;
  domains?: ModelStringInput | null;
  isThirdParty?: ModelBooleanInput | null;
  isFourthParty?: ModelBooleanInput | null;
  fourthPartyDomains?: ModelStringInput | null;
  level?: ModelFrameworkLevelEnumInput | null;
  fourthPartyActiveScan?: ModelBooleanInput | null;
  intelligenceScanScore?: ModelFloatInput | null;
  fourthPartyScanScore?: ModelFloatInput | null;
  isThirdPartyScanCompleted?: ModelBooleanInput | null;
  priority?: ModelImpactEnumInput | null;
  identification?: ModelImpactEnumInput | null;
  materialityRating?: ModelStringInput | null;
  enbdCategory?: ModelStringInput | null;
  enbdDomain?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  projectManagerPhoneNumber?: ModelStringInput | null;
  businessUnits?: ModelStringInput | null;
  category?: ModelStringInput | null;
  dealValue?: ModelIntInput | null;
  products?: ModelStringInput | null;
  ticketID?: ModelStringInput | null;
  tempUsersData?: ModelStringInput | null;
  and?: Array<ModelVendorsDetailFilterInput | null> | null;
  or?: Array<ModelVendorsDetailFilterInput | null> | null;
  not?: ModelVendorsDetailFilterInput | null;
};

export type ModelVendorsDetailConnection = {
  __typename: "ModelVendorsDetailConnection";
  items: Array<VendorsDetail | null>;
  nextToken?: string | null;
};

export type ModelFrameworkScoresFilterInput = {
  id?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  type?: ModelStandardTypeInput | null;
  total?: ModelFloatInput | null;
  target?: ModelFloatInput | null;
  collection?: ModelFloatInput | null;
  remediation?: ModelFloatInput | null;
  reassessment?: ModelFloatInput | null;
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  totalGaps?: ModelIntInput | null;
  criticalGaps?: ModelIntInput | null;
  notApplicable?: ModelBooleanInput | null;
  assignmentCount?: ModelIntInput | null;
  riskStandardsCount?: ModelIntInput | null;
  complianceStandardsCount?: ModelIntInput | null;
  standardsCount?: ModelIntInput | null;
  groups?: ModelStringInput | null;
  maturity?: ModelIntInput | null;
  and?: Array<ModelFrameworkScoresFilterInput | null> | null;
  or?: Array<ModelFrameworkScoresFilterInput | null> | null;
  not?: ModelFrameworkScoresFilterInput | null;
};

export type ModelFrameworkScoresConnection = {
  __typename: "ModelFrameworkScoresConnection";
  items: Array<FrameworkScores | null>;
  nextToken?: string | null;
};

export type ModelIntelligenceFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelIntelligenceFilterInput | null> | null;
  or?: Array<ModelIntelligenceFilterInput | null> | null;
  not?: ModelIntelligenceFilterInput | null;
};

export type ModelIntelligenceConnection = {
  __typename: "ModelIntelligenceConnection";
  items: Array<Intelligence | null>;
  nextToken?: string | null;
};

export type ModelBreachesFilterInput = {
  id?: ModelIDInput | null;
  vendorId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  year?: ModelIntInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  leaks?: ModelIntInput | null;
  data?: ModelStringInput | null;
  status?: ModelStringInput | null;
  email?: ModelStringInput | null;
  and?: Array<ModelBreachesFilterInput | null> | null;
  or?: Array<ModelBreachesFilterInput | null> | null;
  not?: ModelBreachesFilterInput | null;
};

export type ModelExternalThreatsFilterInput = {
  id?: ModelIDInput | null;
  vendorId?: ModelIDInput | null;
  source?: ModelStringInput | null;
  findings?: ModelStringInput | null;
  riskScore?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelExternalThreatsFilterInput | null> | null;
  or?: Array<ModelExternalThreatsFilterInput | null> | null;
  not?: ModelExternalThreatsFilterInput | null;
};

export type ModelOthersFilterInput = {
  id?: ModelIDInput | null;
  vendorId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  severity?: ModelStringInput | null;
  description?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelOthersFilterInput | null> | null;
  or?: Array<ModelOthersFilterInput | null> | null;
  not?: ModelOthersFilterInput | null;
};

export type ModelQuestionnaireCompletionFilterInput = {
  id?: ModelIDInput | null;
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  userId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentMap?: ModelStringInput | null;
  firstAnswer?: ModelIntInput | null;
  lastAnswer?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelQuestionnaireCompletionFilterInput | null> | null;
  or?: Array<ModelQuestionnaireCompletionFilterInput | null> | null;
  not?: ModelQuestionnaireCompletionFilterInput | null;
};

export type ModelQuestionnaireCompletionConnection = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<QuestionnaireCompletion | null>;
  nextToken?: string | null;
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  answer?: ModelFloatInput | null;
  answerName?: ModelStringInput | null;
  comment?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  riskAction?: ModelRiskActionInput | null;
  frameworkName?: ModelStringInput | null;
  questionId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentID?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  isActionTaken?: ModelBooleanInput | null;
  triggerStopper?: ModelBooleanInput | null;
  origin?: ModelAnswerOriginInput | null;
  auditStatus?: ModelStringInput | null;
  isUptodate?: ModelBooleanInput | null;
  reviewerActionStatus?: ModelReviewerActionsInput | null;
  and?: Array<ModelAnswerFilterInput | null> | null;
  or?: Array<ModelAnswerFilterInput | null> | null;
  not?: ModelAnswerFilterInput | null;
};

export type ModelAnswerConnection = {
  __typename: "ModelAnswerConnection";
  items: Array<Answer | null>;
  nextToken?: string | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput = {
  eq?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  le?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  lt?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  ge?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  gt?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  between?: Array<ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null> | null;
  beginsWith?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
};

export type ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput = {
  questionId?: string | null;
  userId?: string | null;
};

export type ModelAssignmentFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  disable?: ModelBooleanInput | null;
  deletedUser?: ModelBooleanInput | null;
  frameworkKey?: ModelStringInput | null;
  and?: Array<ModelAssignmentFilterInput | null> | null;
  or?: Array<ModelAssignmentFilterInput | null> | null;
  not?: ModelAssignmentFilterInput | null;
};

export type ModelAssignmentConnection = {
  __typename: "ModelAssignmentConnection";
  items: Array<Assignment | null>;
  nextToken?: string | null;
};

export type ModelDisableAssignmentFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  disable?: ModelBooleanInput | null;
  and?: Array<ModelDisableAssignmentFilterInput | null> | null;
  or?: Array<ModelDisableAssignmentFilterInput | null> | null;
  not?: ModelDisableAssignmentFilterInput | null;
};

export type ModelDisableAssignmentConnection = {
  __typename: "ModelDisableAssignmentConnection";
  items: Array<DisableAssignment | null>;
  nextToken?: string | null;
};

export type ModelAssessmentFilterInput = {
  id?: ModelIDInput | null;
  rootId?: ModelIDInput | null;
  childId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  managers?: ModelStringInput | null;
  and?: Array<ModelAssessmentFilterInput | null> | null;
  or?: Array<ModelAssessmentFilterInput | null> | null;
  not?: ModelAssessmentFilterInput | null;
};

export type ModelAssessmentConnection = {
  __typename: "ModelAssessmentConnection";
  items: Array<Assessment | null>;
  nextToken?: string | null;
};

export type ModelIntegrationMappingsFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  projectKey?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  and?: Array<ModelIntegrationMappingsFilterInput | null> | null;
  or?: Array<ModelIntegrationMappingsFilterInput | null> | null;
  not?: ModelIntegrationMappingsFilterInput | null;
};

export type ModelIntegrationMappingsConnection = {
  __typename: "ModelIntegrationMappingsConnection";
  items: Array<IntegrationMappings | null>;
  nextToken?: string | null;
};

export type ModelQuestionSettingsFilterInput = {
  id?: ModelIDInput | null;
  questionId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  isCollaborative?: ModelBooleanInput | null;
  isArtifactsMandatory?: ModelBooleanInput | null;
  isCommentsMandatory?: ModelBooleanInput | null;
  isApprovalRequired?: ModelBooleanInput | null;
  suggestedArtifactAnswers?: ModelStringInput | null;
  requiredCommentAnswers?: ModelStringInput | null;
  versionFrequency?: ModelStringInput | null;
  isIntegrationActive?: ModelBooleanInput | null;
  isSmartMappingActive?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  isInfoSec?: ModelBooleanInput | null;
  frameworkName?: ModelStringInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  and?: Array<ModelQuestionSettingsFilterInput | null> | null;
  or?: Array<ModelQuestionSettingsFilterInput | null> | null;
  not?: ModelQuestionSettingsFilterInput | null;
};

export type ModelQuestionSettingsConnection = {
  __typename: "ModelQuestionSettingsConnection";
  items: Array<QuestionSettings | null>;
  nextToken?: string | null;
};

export type ModelTaskActionFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  action?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  taskAssignmentID?: ModelIDInput | null;
  questionId?: ModelIDInput | null;
  frameworkName?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  triggerStopper?: ModelBooleanInput | null;
  searchString?: ModelStringInput | null;
  questionIds?: ModelIDInput | null;
  smartMappingIds?: ModelStringInput | null;
  title?: ModelStringInput | null;
  impact?: ModelStringInput | null;
  maturity?: ModelFloatInput | null;
  type?: ModelStringInput | null;
  description?: ModelStringInput | null;
  tabName?: ModelStringInput | null;
  managerAction?: ModelStringInput | null;
  rootEntityId?: ModelIDInput | null;
  dueDate?: ModelIntInput | null;
  and?: Array<ModelTaskActionFilterInput | null> | null;
  or?: Array<ModelTaskActionFilterInput | null> | null;
  not?: ModelTaskActionFilterInput | null;
};

export type ModelTaskActionConnection = {
  __typename: "ModelTaskActionConnection";
  items: Array<TaskAction | null>;
  nextToken?: string | null;
};

export type ModelUsersAssignmentMapperFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  score?: ModelFloatInput | null;
  assessmentId?: ModelIDInput | null;
  taskId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelUsersAssignmentMapperFilterInput | null> | null;
  or?: Array<ModelUsersAssignmentMapperFilterInput | null> | null;
  not?: ModelUsersAssignmentMapperFilterInput | null;
};

export type ModelUsersAssignmentMapperConnection = {
  __typename: "ModelUsersAssignmentMapperConnection";
  items: Array<UsersAssignmentMapper | null>;
  nextToken?: string | null;
};

export type ModelFrameworkSettingsFilterInput = {
  id?: ModelIDInput | null;
  settings?: ModelStringInput | null;
  and?: Array<ModelFrameworkSettingsFilterInput | null> | null;
  or?: Array<ModelFrameworkSettingsFilterInput | null> | null;
  not?: ModelFrameworkSettingsFilterInput | null;
};

export type ModelFrameworkSettingsConnection = {
  __typename: "ModelFrameworkSettingsConnection";
  items: Array<FrameworkSettings | null>;
  nextToken?: string | null;
};

export type ModelCustomTaskFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  impact?: ModelImpactEnumInput | null;
  description?: ModelStringInput | null;
  status?: ModelTaskStatusEnumInput | null;
  assessmentId?: ModelIDInput | null;
  customTodo?: ModelStringInput | null;
  dueDate?: ModelIntInput | null;
  and?: Array<ModelCustomTaskFilterInput | null> | null;
  or?: Array<ModelCustomTaskFilterInput | null> | null;
  not?: ModelCustomTaskFilterInput | null;
};

export type ModelCustomTaskConnection = {
  __typename: "ModelCustomTaskConnection";
  items: Array<CustomTask | null>;
  nextToken?: string | null;
};

export type ModelCustomTaskAssignmentFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  and?: Array<ModelCustomTaskAssignmentFilterInput | null> | null;
  or?: Array<ModelCustomTaskAssignmentFilterInput | null> | null;
  not?: ModelCustomTaskAssignmentFilterInput | null;
};

export type ModelCustomTaskAssignmentConnection = {
  __typename: "ModelCustomTaskAssignmentConnection";
  items: Array<CustomTaskAssignment | null>;
  nextToken?: string | null;
};

export type ModelAssessmentStandardFrameworkFilterInput = {
  id?: ModelStringInput | null;
  key?: ModelStringInput | null;
  type?: ModelStandardTypeInput | null;
  fileName?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  selectedChapters?: ModelStringInput | null;
  not_added?: ModelBooleanInput | null;
  archived?: ModelBooleanInput | null;
  reassessmentStatus?: ModelReassessmentEnumInput | null;
  documentCategory?: ModelDocumentTypeInput | null;
  documentType?: ModelStringInput | null;
  suggestedArtifact?: ModelStringInput | null;
  suggestedComments?: ModelStringInput | null;
  level?: ModelStringInput | null;
  newStartReassessDate?: ModelIntInput | null;
  signatureUserID?: ModelIDInput | null;
  isLocked?: ModelBooleanInput | null;
  lockedByUserID?: ModelIDInput | null;
  and?: Array<ModelAssessmentStandardFrameworkFilterInput | null> | null;
  or?: Array<ModelAssessmentStandardFrameworkFilterInput | null> | null;
  not?: ModelAssessmentStandardFrameworkFilterInput | null;
};

export type ModelFrameworkManagerFilterInput = {
  id?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  standardFrameworkId?: ModelStringInput | null;
  managerId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  removedQuestions?: ModelStringInput | null;
  and?: Array<ModelFrameworkManagerFilterInput | null> | null;
  or?: Array<ModelFrameworkManagerFilterInput | null> | null;
  not?: ModelFrameworkManagerFilterInput | null;
};

export type ModelCommentFilterInput = {
  id?: ModelStringInput | null;
  latestSource?: ModelExportSourceEnumInput | null;
  comment?: ModelStringInput | null;
  date?: ModelIntInput | null;
  questionId?: ModelStringInput | null;
  riskId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  type?: ModelStringInput | null;
  sType?: ModelStringInput | null;
  framework?: ModelStringInput | null;
  srcFramework?: ModelStringInput | null;
  and?: Array<ModelCommentFilterInput | null> | null;
  or?: Array<ModelCommentFilterInput | null> | null;
  not?: ModelCommentFilterInput | null;
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection";
  items: Array<Comment | null>;
  nextToken?: string | null;
};

export type ModelExportFilterInput = {
  id?: ModelStringInput | null;
  destination?: ModelExportSourceEnumInput | null;
  destinationCategoryId?: ModelIDInput | null;
  category?: ModelCategoryEnumInput | null;
  categoryId?: ModelStringInput | null;
  date?: ModelIntInput | null;
  and?: Array<ModelExportFilterInput | null> | null;
  or?: Array<ModelExportFilterInput | null> | null;
  not?: ModelExportFilterInput | null;
};

export type ModelReportFilterInput = {
  id?: ModelStringInput | null;
  entityId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  managerName?: ModelStringInput | null;
  startDate?: ModelIntInput | null;
  endDate?: ModelIntInput | null;
  type?: ModelReportTypeEnumInput | null;
  isDraft?: ModelBooleanInput | null;
  previousReports?: ModelIDInput | null;
  createdAt?: ModelIntInput | null;
  and?: Array<ModelReportFilterInput | null> | null;
  or?: Array<ModelReportFilterInput | null> | null;
  not?: ModelReportFilterInput | null;
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection";
  items: Array<Report | null>;
  nextToken?: string | null;
};

export type ModelRiskFilterInput = {
  id?: ModelIDInput | null;
  idTitle?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  subEntityId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  type?: ModelRiskTypeEnumInput | null;
  disable?: ModelBooleanInput | null;
  controlIds?: ModelStringInput | null;
  riskTitle?: ModelStringInput | null;
  riskDescription?: ModelStringInput | null;
  riskCategory?: ModelStringInput | null;
  relatedAssets?: ModelStringInput | null;
  residualImpact?: ModelRiskImpactEnumInput | null;
  residualProbability?: ModelRiskProbabilityEnumInput | null;
  riskImpact?: ModelRiskImpactEnumInput | null;
  riskProbability?: ModelRiskProbabilityEnumInput | null;
  inherentRisk?: ModelFloatInput | null;
  residualRisk?: ModelFloatInput | null;
  riskStatus?: ModelRiskStatusEnumInput | null;
  riskStatusUpdatedBy?: ModelRiskStatusUpdatedByEnumInput | null;
  notes?: ModelStringInput | null;
  costToRemediate?: ModelIntInput | null;
  costToRemediateType?: ModelRiskCostRemediationEnumInput | null;
  primaryLoss?: ModelIntInput | null;
  riskPriority?: ModelRiskPriorityEnumInput | null;
  hidden?: ModelBooleanInput | null;
  financialToggle?: ModelBooleanInput | null;
  riskTags?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  updatedAt?: ModelIntInput | null;
  riskTasksIds?: ModelStringInput | null;
  isArchived?: ModelBooleanInput | null;
  isEscalated?: ModelBooleanInput | null;
  sensitivity?: ModelFloatInput | null;
  effectiveness?: ModelFloatInput | null;
  deadline?: ModelIntInput | null;
  riskOwnerIds?: ModelStringInput | null;
  and?: Array<ModelRiskFilterInput | null> | null;
  or?: Array<ModelRiskFilterInput | null> | null;
  not?: ModelRiskFilterInput | null;
};

export type ModelRiskConnection = {
  __typename: "ModelRiskConnection";
  items: Array<Risk | null>;
  nextToken?: string | null;
};

export type ModelRiskOwnerAssignmentFilterInput = {
  id?: ModelIDInput | null;
  subEntityId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  riskId?: ModelIDInput | null;
  and?: Array<ModelRiskOwnerAssignmentFilterInput | null> | null;
  or?: Array<ModelRiskOwnerAssignmentFilterInput | null> | null;
  not?: ModelRiskOwnerAssignmentFilterInput | null;
};

export type ModelRiskOwnerAssignmentConnection = {
  __typename: "ModelRiskOwnerAssignmentConnection";
  items: Array<RiskOwnerAssignment | null>;
  nextToken?: string | null;
};

export type ModelSavedFilterFilterInput = {
  id?: ModelIDInput | null;
  filterObject?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  entityId?: ModelIDInput | null;
  type?: ModelFilterTypeEnumInput | null;
  and?: Array<ModelSavedFilterFilterInput | null> | null;
  or?: Array<ModelSavedFilterFilterInput | null> | null;
  not?: ModelSavedFilterFilterInput | null;
};

export type ModelSavedFilterConnection = {
  __typename: "ModelSavedFilterConnection";
  items: Array<SavedFilter | null>;
  nextToken?: string | null;
};

export type ModelDomainSettingsFilterInput = {
  id?: ModelIDInput | null;
  domain?: ModelStringInput | null;
  key?: ModelStringInput | null;
  average?: ModelFloatInput | null;
  allowedRoutes?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  allowedFeatures?: ModelStringInput | null;
  settings?: ModelStringInput | null;
  kmsStatus?: ModelBooleanInput | null;
  and?: Array<ModelDomainSettingsFilterInput | null> | null;
  or?: Array<ModelDomainSettingsFilterInput | null> | null;
  not?: ModelDomainSettingsFilterInput | null;
};

export type ModelDomainSettingsConnection = {
  __typename: "ModelDomainSettingsConnection";
  items: Array<DomainSettings | null>;
  nextToken?: string | null;
};

export type ModelExecutiveSummaryFilterInput = {
  id?: ModelIDInput | null;
  entityId?: ModelStringInput | null;
  title?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelExecutiveSummaryFilterInput | null> | null;
  or?: Array<ModelExecutiveSummaryFilterInput | null> | null;
  not?: ModelExecutiveSummaryFilterInput | null;
};

export type ModelExecutiveSummaryConnection = {
  __typename: "ModelExecutiveSummaryConnection";
  items: Array<ExecutiveSummary | null>;
  nextToken?: string | null;
};

export type ModelNetskopeProjectFilterInput = {
  id?: ModelIDInput | null;
  entityId?: ModelStringInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  previous?: ModelProgressStatusEnumInput | null;
  budget?: ModelIntInput | null;
  comments?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelNetskopeProjectFilterInput | null> | null;
  or?: Array<ModelNetskopeProjectFilterInput | null> | null;
  not?: ModelNetskopeProjectFilterInput | null;
};

export type ModelNetskopeProjectConnection = {
  __typename: "ModelNetskopeProjectConnection";
  items: Array<NetskopeProject | null>;
  nextToken?: string | null;
};

export type ModelEntityLayersFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  managerName?: ModelStringInput | null;
  type?: ModelBnbLayerEnumInput | null;
  inherent?: ModelStringInput | null;
  residual?: ModelStringInput | null;
  parentId?: ModelIDInput | null;
  srName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  domain?: ModelStringInput | null;
  and?: Array<ModelEntityLayersFilterInput | null> | null;
  or?: Array<ModelEntityLayersFilterInput | null> | null;
  not?: ModelEntityLayersFilterInput | null;
};

export type ModelEntityLayersConnection = {
  __typename: "ModelEntityLayersConnection";
  items: Array<EntityLayers | null>;
  nextToken?: string | null;
};

export type ModelEntityWizardFilterInput = {
  id?: ModelIDInput | null;
  isDraft?: ModelBooleanInput | null;
  organizational?: ModelStringInput | null;
  financial?: ModelStringInput | null;
  frameworks?: ModelStringInput | null;
  dataScope?: ModelStringInput | null;
  technical?: ModelStringInput | null;
  riskScenarios?: ModelStringInput | null;
  accountDetails?: ModelStringInput | null;
  currentYear?: ModelStringInput | null;
  parentId?: ModelIDInput | null;
  inherent?: ModelStringInput | null;
  residual?: ModelStringInput | null;
  domainScanned?: ModelBooleanInput | null;
  domainScanTimes?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  riskFramework?: ModelStringInput | null;
  clientDomain?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  applicantInfo?: ModelStringInput | null;
  revenueDetails?: ModelStringInput | null;
  dataInventory?: ModelStringInput | null;
  updatedBy?: ModelStringInput | null;
  and?: Array<ModelEntityWizardFilterInput | null> | null;
  or?: Array<ModelEntityWizardFilterInput | null> | null;
  not?: ModelEntityWizardFilterInput | null;
};

export type ModelEntityWizardConnection = {
  __typename: "ModelEntityWizardConnection";
  items: Array<EntityWizard | null>;
  nextToken?: string | null;
};

export type ModelLogsFilterInput = {
  id?: ModelIDInput | null;
  message?: ModelStringInput | null;
  type?: ModelStringInput | null;
  targetId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  userID?: ModelIDInput | null;
  and?: Array<ModelLogsFilterInput | null> | null;
  or?: Array<ModelLogsFilterInput | null> | null;
  not?: ModelLogsFilterInput | null;
};

export type ModelLogsConnection = {
  __typename: "ModelLogsConnection";
  items: Array<Logs | null>;
  nextToken?: string | null;
};

export type ModelVersionsFilterInput = {
  id?: ModelIDInput | null;
  data?: ModelStringInput | null;
  type?: ModelStringInput | null;
  targetId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  and?: Array<ModelVersionsFilterInput | null> | null;
  or?: Array<ModelVersionsFilterInput | null> | null;
  not?: ModelVersionsFilterInput | null;
};

export type ModelVersionsConnection = {
  __typename: "ModelVersionsConnection";
  items: Array<Versions | null>;
  nextToken?: string | null;
};

export type ModelDataStateFilterInput = {
  id?: ModelIDInput | null;
  state?: ModelStateStatusEnumInput | null;
  parentId?: ModelStringInput | null;
  message?: ModelStringInput | null;
  detail?: ModelStringInput | null;
  type?: ModelStateTypeEnumInput | null;
  and?: Array<ModelDataStateFilterInput | null> | null;
  or?: Array<ModelDataStateFilterInput | null> | null;
  not?: ModelDataStateFilterInput | null;
};

export type ModelDataStateConnection = {
  __typename: "ModelDataStateConnection";
  items: Array<DataState | null>;
  nextToken?: string | null;
};

export type ModelSmartMappingDetailFilterInput = {
  id?: ModelIDInput | null;
  uniqueId?: ModelIDInput | null;
  metadata?: ModelStringInput | null;
  answer?: ModelFloatInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSmartMappingDetailFilterInput | null> | null;
  or?: Array<ModelSmartMappingDetailFilterInput | null> | null;
  not?: ModelSmartMappingDetailFilterInput | null;
};

export type ModelSmartMappingDetailConnection = {
  __typename: "ModelSmartMappingDetailConnection";
  items: Array<SmartMappingDetail | null>;
  nextToken?: string | null;
};

export type ModelApiCallRegistryFilterInput = {
  id?: ModelIDInput | null;
  callMaker?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelApiCallRegistryFilterInput | null> | null;
  or?: Array<ModelApiCallRegistryFilterInput | null> | null;
  not?: ModelApiCallRegistryFilterInput | null;
};

export type ModelApiCallRegistryConnection = {
  __typename: "ModelApiCallRegistryConnection";
  items: Array<ApiCallRegistry | null>;
  nextToken?: string | null;
};

export type ModelBreachDataFilterInput = {
  id?: ModelIDInput | null;
  domain?: ModelStringInput | null;
  breaches?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelBreachDataFilterInput | null> | null;
  or?: Array<ModelBreachDataFilterInput | null> | null;
  not?: ModelBreachDataFilterInput | null;
};

export type ModelBreachDataConnection = {
  __typename: "ModelBreachDataConnection";
  items: Array<BreachData | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  role?: ModelSubscriptionStringInput | null;
  roleId?: ModelSubscriptionIDInput | null;
  isViewOnly?: ModelSubscriptionBooleanInput | null;
  phone?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionIDInput | null;
  entityIds?: ModelSubscriptionStringInput | null;
  isCognitoUser?: ModelSubscriptionBooleanInput | null;
  questionMap?: ModelSubscriptionStringInput | null;
  createdBy?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionIntInput | null;
  lastLogin?: ModelSubscriptionIntInput | null;
  invitationDate?: ModelSubscriptionIntInput | null;
  reminderDate?: ModelSubscriptionIntInput | null;
  modifiedBy?: ModelSubscriptionStringInput | null;
  modifiedAt?: ModelSubscriptionIntInput | null;
  domain?: ModelSubscriptionStringInput | null;
  temporaryHigherRank?: ModelSubscriptionBooleanInput | null;
  entityLayerIds?: ModelSubscriptionStringInput | null;
  clickThrough?: ModelSubscriptionBooleanInput | null;
  clickThroughDate?: ModelSubscriptionStringInput | null;
  projectManagerEmail?: ModelSubscriptionStringInput | null;
  isGlobalParticipant?: ModelSubscriptionBooleanInput | null;
  onBoardingStatus?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionUserFilterInput | null> | null;
  or?: Array<ModelSubscriptionUserFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionTagsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionIDInput | null;
  domain?: ModelSubscriptionStringInput | null;
  targetId?: ModelSubscriptionStringInput | null;
  assessmentFrameworkKey?: ModelSubscriptionStringInput | null;
  referenceId?: ModelSubscriptionIDInput | null;
  questionRefId?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionTagsFilterInput | null> | null;
  or?: Array<ModelSubscriptionTagsFilterInput | null> | null;
};

export type ModelSubscriptionRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionIDInput | null;
  entityIds?: ModelSubscriptionStringInput | null;
  vendorIds?: ModelSubscriptionStringInput | null;
  isRootEntityAccess?: ModelSubscriptionBooleanInput | null;
  defaultOrEntityId?: ModelSubscriptionStringInput | null;
  screenPermissions?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  parentId?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionRoleFilterInput | null> | null;
  or?: Array<ModelSubscriptionRoleFilterInput | null> | null;
};

export type ModelSubscriptionRiskLineChartFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionStringInput | null;
  riskId?: ModelSubscriptionStringInput | null;
  residual?: ModelSubscriptionStringInput | null;
  inherent?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionRiskLineChartFilterInput | null> | null;
  or?: Array<ModelSubscriptionRiskLineChartFilterInput | null> | null;
};

export type ModelSubscriptionGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  rootId?: ModelSubscriptionStringInput | null;
  tags?: ModelSubscriptionStringInput | null;
  score?: ModelSubscriptionFloatInput | null;
  and?: Array<ModelSubscriptionGroupFilterInput | null> | null;
  or?: Array<ModelSubscriptionGroupFilterInput | null> | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionQuestionDataFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  childId?: ModelSubscriptionStringInput | null;
  frameworkKey?: ModelSubscriptionStringInput | null;
  function?: ModelSubscriptionStringInput | null;
  category?: ModelSubscriptionStringInput | null;
  subCategory?: ModelSubscriptionStringInput | null;
  controlNameLabel?: ModelSubscriptionStringInput | null;
  score?: ModelSubscriptionFloatInput | null;
  rootId?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  reviewStatus?: ModelSubscriptionStringInput | null;
  resentBackStatus?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionQuestionDataFilterInput | null> | null;
  or?: Array<ModelSubscriptionQuestionDataFilterInput | null> | null;
};

export type ModelSubscriptionArchivedUserFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  role?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionIDInput | null;
  isCognitoUser?: ModelSubscriptionBooleanInput | null;
  questionMap?: ModelSubscriptionStringInput | null;
  createdBy?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionIntInput | null;
  modifiedBy?: ModelSubscriptionStringInput | null;
  modifiedAt?: ModelSubscriptionIntInput | null;
  domain?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionArchivedUserFilterInput | null> | null;
  or?: Array<ModelSubscriptionArchivedUserFilterInput | null> | null;
};

export type ModelSubscriptionEntityFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  users?: ModelSubscriptionIDInput | null;
  completionStatus?: ModelSubscriptionStringInput | null;
  accessGroups?: ModelSubscriptionStringInput | null;
  participantGroup?: ModelSubscriptionStringInput | null;
  rootEntityId?: ModelSubscriptionIDInput | null;
  childEntityId?: ModelSubscriptionIDInput | null;
  frameworkId?: ModelSubscriptionIDInput | null;
  frameworkName?: ModelSubscriptionStringInput | null;
  frameworkType?: ModelSubscriptionStringInput | null;
  parentId?: ModelSubscriptionIDInput | null;
  entityType?: ModelSubscriptionStringInput | null;
  projectManager?: ModelSubscriptionIDInput | null;
  projectManagerName?: ModelSubscriptionStringInput | null;
  projectDeadline?: ModelSubscriptionIntInput | null;
  createdBy?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionIntInput | null;
  modifiedBy?: ModelSubscriptionStringInput | null;
  modifiedAt?: ModelSubscriptionIntInput | null;
  industry?: ModelSubscriptionStringInput | null;
  domain?: ModelSubscriptionStringInput | null;
  activeAssessmentId?: ModelSubscriptionIDInput | null;
  tierSelected?: ModelSubscriptionIntInput | null;
  scName?: ModelSubscriptionStringInput | null;
  upperdeckRoute?: ModelSubscriptionStringInput | null;
  isLocked?: ModelSubscriptionBooleanInput | null;
  eSignFinalized?: ModelSubscriptionBooleanInput | null;
  weightageScore?: ModelSubscriptionFloatInput | null;
  upperdeckSetting?: ModelSubscriptionStringInput | null;
  domainScanned?: ModelSubscriptionBooleanInput | null;
  domainScanTimes?: ModelSubscriptionStringInput | null;
  domainScanEnabledForEntity?: ModelSubscriptionBooleanInput | null;
  frameworkSettings?: ModelSubscriptionStringInput | null;
  usedFrameworkUniqueCount?: ModelSubscriptionIntInput | null;
  spiderChartSetting?: ModelSubscriptionStringInput | null;
  isVendorInLimit?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionEntityFilterInput | null> | null;
  or?: Array<ModelSubscriptionEntityFilterInput | null> | null;
};

export type ModelSubscriptionGraphFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  rootId?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  entities?: ModelSubscriptionStringInput | null;
  tags?: ModelSubscriptionStringInput | null;
  createdBy?: ModelSubscriptionStringInput | null;
  timeSpan?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionGraphFilterInput | null> | null;
  or?: Array<ModelSubscriptionGraphFilterInput | null> | null;
};

export type ModelSubscriptionRevisionFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  frameworkKey?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionStringInput | null;
  groupId?: ModelSubscriptionStringInput | null;
  date?: ModelSubscriptionStringInput | null;
  rootId?: ModelSubscriptionStringInput | null;
  tagId?: ModelSubscriptionStringInput | null;
  scores?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionRevisionFilterInput | null> | null;
  or?: Array<ModelSubscriptionRevisionFilterInput | null> | null;
};

export type ModelSubscriptionVendorsDetailFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  impact?: ModelSubscriptionIntInput | null;
  externalScan?: ModelSubscriptionFloatInput | null;
  financialRisk?: ModelSubscriptionFloatInput | null;
  criticalGaps?: ModelSubscriptionIntInput | null;
  status?: ModelSubscriptionStringInput | null;
  probability?: ModelSubscriptionIntInput | null;
  website?: ModelSubscriptionStringInput | null;
  location?: ModelSubscriptionStringInput | null;
  size?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  businessPointOfContactId?: ModelSubscriptionStringInput | null;
  accessLevel?: ModelSubscriptionStringInput | null;
  collectionStatus?: ModelSubscriptionStringInput | null;
  approvalDate?: ModelSubscriptionIntInput | null;
  stage?: ModelSubscriptionStringInput | null;
  activeScan?: ModelSubscriptionBooleanInput | null;
  domain?: ModelSubscriptionStringInput | null;
  privacyData?: ModelSubscriptionBooleanInput | null;
  primaryLoss?: ModelSubscriptionFloatInput | null;
  smartTags?: ModelSubscriptionStringInput | null;
  employees?: ModelSubscriptionIntInput | null;
  domains?: ModelSubscriptionStringInput | null;
  isThirdParty?: ModelSubscriptionBooleanInput | null;
  isFourthParty?: ModelSubscriptionBooleanInput | null;
  fourthPartyDomains?: ModelSubscriptionStringInput | null;
  level?: ModelSubscriptionStringInput | null;
  fourthPartyActiveScan?: ModelSubscriptionBooleanInput | null;
  intelligenceScanScore?: ModelSubscriptionFloatInput | null;
  fourthPartyScanScore?: ModelSubscriptionFloatInput | null;
  isThirdPartyScanCompleted?: ModelSubscriptionBooleanInput | null;
  priority?: ModelSubscriptionStringInput | null;
  identification?: ModelSubscriptionStringInput | null;
  materialityRating?: ModelSubscriptionStringInput | null;
  enbdCategory?: ModelSubscriptionStringInput | null;
  enbdDomain?: ModelSubscriptionStringInput | null;
  phoneNumber?: ModelSubscriptionStringInput | null;
  projectManagerPhoneNumber?: ModelSubscriptionStringInput | null;
  businessUnits?: ModelSubscriptionStringInput | null;
  category?: ModelSubscriptionStringInput | null;
  dealValue?: ModelSubscriptionIntInput | null;
  products?: ModelSubscriptionStringInput | null;
  ticketID?: ModelSubscriptionStringInput | null;
  tempUsersData?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVendorsDetailFilterInput | null> | null;
  or?: Array<ModelSubscriptionVendorsDetailFilterInput | null> | null;
};

export type ModelSubscriptionFrameworkScoresFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  type?: ModelSubscriptionStringInput | null;
  total?: ModelSubscriptionFloatInput | null;
  target?: ModelSubscriptionFloatInput | null;
  collection?: ModelSubscriptionFloatInput | null;
  remediation?: ModelSubscriptionFloatInput | null;
  reassessment?: ModelSubscriptionFloatInput | null;
  totalQuestions?: ModelSubscriptionIntInput | null;
  completedQuestions?: ModelSubscriptionIntInput | null;
  totalGaps?: ModelSubscriptionIntInput | null;
  criticalGaps?: ModelSubscriptionIntInput | null;
  notApplicable?: ModelSubscriptionBooleanInput | null;
  assignmentCount?: ModelSubscriptionIntInput | null;
  riskStandardsCount?: ModelSubscriptionIntInput | null;
  complianceStandardsCount?: ModelSubscriptionIntInput | null;
  standardsCount?: ModelSubscriptionIntInput | null;
  groups?: ModelSubscriptionStringInput | null;
  maturity?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionFrameworkScoresFilterInput | null> | null;
  or?: Array<ModelSubscriptionFrameworkScoresFilterInput | null> | null;
};

export type ModelSubscriptionIntelligenceFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionIntelligenceFilterInput | null> | null;
  or?: Array<ModelSubscriptionIntelligenceFilterInput | null> | null;
};

export type ModelSubscriptionBreachesFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  vendorId?: ModelSubscriptionIDInput | null;
  date?: ModelSubscriptionIntInput | null;
  year?: ModelSubscriptionIntInput | null;
  name?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  leaks?: ModelSubscriptionIntInput | null;
  data?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBreachesFilterInput | null> | null;
  or?: Array<ModelSubscriptionBreachesFilterInput | null> | null;
};

export type ModelSubscriptionExternalThreatsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  vendorId?: ModelSubscriptionIDInput | null;
  source?: ModelSubscriptionStringInput | null;
  findings?: ModelSubscriptionStringInput | null;
  riskScore?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionExternalThreatsFilterInput | null> | null;
  or?: Array<ModelSubscriptionExternalThreatsFilterInput | null> | null;
};

export type ModelSubscriptionOthersFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  vendorId?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  label?: ModelSubscriptionStringInput | null;
  severity?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionOthersFilterInput | null> | null;
  or?: Array<ModelSubscriptionOthersFilterInput | null> | null;
};

export type ModelSubscriptionQuestionnaireCompletionFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  totalQuestions?: ModelSubscriptionIntInput | null;
  completedQuestions?: ModelSubscriptionIntInput | null;
  userId?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  assignmentMap?: ModelSubscriptionStringInput | null;
  firstAnswer?: ModelSubscriptionIntInput | null;
  lastAnswer?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionQuestionnaireCompletionFilterInput | null> | null;
  or?: Array<ModelSubscriptionQuestionnaireCompletionFilterInput | null> | null;
};

export type ModelSubscriptionAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  answer?: ModelSubscriptionFloatInput | null;
  answerName?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionIDInput | null;
  riskAction?: ModelSubscriptionStringInput | null;
  frameworkName?: ModelSubscriptionStringInput | null;
  questionId?: ModelSubscriptionIDInput | null;
  date?: ModelSubscriptionIntInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  assignmentID?: ModelSubscriptionIDInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  isActionTaken?: ModelSubscriptionBooleanInput | null;
  triggerStopper?: ModelSubscriptionBooleanInput | null;
  origin?: ModelSubscriptionStringInput | null;
  auditStatus?: ModelSubscriptionStringInput | null;
  isUptodate?: ModelSubscriptionBooleanInput | null;
  reviewerActionStatus?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAnswerFilterInput | null> | null;
  or?: Array<ModelSubscriptionAnswerFilterInput | null> | null;
};

export type ModelSubscriptionAssignmentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  userId?: ModelSubscriptionIDInput | null;
  targetId?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  disable?: ModelSubscriptionBooleanInput | null;
  deletedUser?: ModelSubscriptionBooleanInput | null;
  frameworkKey?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAssignmentFilterInput | null> | null;
  or?: Array<ModelSubscriptionAssignmentFilterInput | null> | null;
};

export type ModelSubscriptionDisableAssignmentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  userId?: ModelSubscriptionIDInput | null;
  targetId?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  disable?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionDisableAssignmentFilterInput | null> | null;
  or?: Array<ModelSubscriptionDisableAssignmentFilterInput | null> | null;
};

export type ModelSubscriptionAssessmentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  rootId?: ModelSubscriptionIDInput | null;
  childId?: ModelSubscriptionIDInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  managers?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAssessmentFilterInput | null> | null;
  or?: Array<ModelSubscriptionAssessmentFilterInput | null> | null;
};

export type ModelSubscriptionIntegrationMappingsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  projectKey?: ModelSubscriptionStringInput | null;
  fileName?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionIntegrationMappingsFilterInput | null> | null;
  or?: Array<ModelSubscriptionIntegrationMappingsFilterInput | null> | null;
};

export type ModelSubscriptionQuestionSettingsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  questionId?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  isCollaborative?: ModelSubscriptionBooleanInput | null;
  isArtifactsMandatory?: ModelSubscriptionBooleanInput | null;
  isCommentsMandatory?: ModelSubscriptionBooleanInput | null;
  isApprovalRequired?: ModelSubscriptionBooleanInput | null;
  suggestedArtifactAnswers?: ModelSubscriptionStringInput | null;
  requiredCommentAnswers?: ModelSubscriptionStringInput | null;
  versionFrequency?: ModelSubscriptionStringInput | null;
  isIntegrationActive?: ModelSubscriptionBooleanInput | null;
  isSmartMappingActive?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  isInfoSec?: ModelSubscriptionBooleanInput | null;
  frameworkName?: ModelSubscriptionStringInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionQuestionSettingsFilterInput | null> | null;
  or?: Array<ModelSubscriptionQuestionSettingsFilterInput | null> | null;
};

export type ModelSubscriptionTaskActionFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  score?: ModelSubscriptionFloatInput | null;
  action?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  taskAssignmentID?: ModelSubscriptionIDInput | null;
  questionId?: ModelSubscriptionIDInput | null;
  frameworkName?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionIDInput | null;
  date?: ModelSubscriptionIntInput | null;
  triggerStopper?: ModelSubscriptionBooleanInput | null;
  searchString?: ModelSubscriptionStringInput | null;
  questionIds?: ModelSubscriptionIDInput | null;
  smartMappingIds?: ModelSubscriptionStringInput | null;
  title?: ModelSubscriptionStringInput | null;
  impact?: ModelSubscriptionStringInput | null;
  maturity?: ModelSubscriptionFloatInput | null;
  type?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  tabName?: ModelSubscriptionStringInput | null;
  managerAction?: ModelSubscriptionStringInput | null;
  rootEntityId?: ModelSubscriptionIDInput | null;
  dueDate?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionTaskActionFilterInput | null> | null;
  or?: Array<ModelSubscriptionTaskActionFilterInput | null> | null;
};

export type ModelSubscriptionUsersAssignmentMapperFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  score?: ModelSubscriptionFloatInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  taskId?: ModelSubscriptionIDInput | null;
  userId?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionUsersAssignmentMapperFilterInput | null> | null;
  or?: Array<ModelSubscriptionUsersAssignmentMapperFilterInput | null> | null;
};

export type ModelSubscriptionFrameworkSettingsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  settings?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionFrameworkSettingsFilterInput | null> | null;
  or?: Array<ModelSubscriptionFrameworkSettingsFilterInput | null> | null;
};

export type ModelSubscriptionCustomTaskFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  label?: ModelSubscriptionStringInput | null;
  impact?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  customTodo?: ModelSubscriptionStringInput | null;
  dueDate?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionCustomTaskFilterInput | null> | null;
  or?: Array<ModelSubscriptionCustomTaskFilterInput | null> | null;
};

export type ModelSubscriptionCustomTaskAssignmentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  userId?: ModelSubscriptionIDInput | null;
  targetId?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionCustomTaskAssignmentFilterInput | null> | null;
  or?: Array<ModelSubscriptionCustomTaskAssignmentFilterInput | null> | null;
};

export type ModelSubscriptionAssessmentStandardFrameworkFilterInput = {
  id?: ModelSubscriptionStringInput | null;
  key?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  fileName?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  managerId?: ModelSubscriptionIDInput | null;
  selectedChapters?: ModelSubscriptionStringInput | null;
  not_added?: ModelSubscriptionBooleanInput | null;
  archived?: ModelSubscriptionBooleanInput | null;
  reassessmentStatus?: ModelSubscriptionStringInput | null;
  documentCategory?: ModelSubscriptionStringInput | null;
  documentType?: ModelSubscriptionStringInput | null;
  suggestedArtifact?: ModelSubscriptionStringInput | null;
  suggestedComments?: ModelSubscriptionStringInput | null;
  level?: ModelSubscriptionStringInput | null;
  newStartReassessDate?: ModelSubscriptionIntInput | null;
  signatureUserID?: ModelSubscriptionIDInput | null;
  isLocked?: ModelSubscriptionBooleanInput | null;
  lockedByUserID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionAssessmentStandardFrameworkFilterInput | null> | null;
  or?: Array<ModelSubscriptionAssessmentStandardFrameworkFilterInput | null> | null;
};

export type ModelSubscriptionFrameworkManagerFilterInput = {
  id?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  standardFrameworkId?: ModelSubscriptionStringInput | null;
  managerId?: ModelSubscriptionIDInput | null;
  left?: ModelSubscriptionIntInput | null;
  right?: ModelSubscriptionIntInput | null;
  removedQuestions?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionFrameworkManagerFilterInput | null> | null;
  or?: Array<ModelSubscriptionFrameworkManagerFilterInput | null> | null;
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionStringInput | null;
  latestSource?: ModelSubscriptionStringInput | null;
  comment?: ModelSubscriptionStringInput | null;
  date?: ModelSubscriptionIntInput | null;
  questionId?: ModelSubscriptionStringInput | null;
  riskId?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionIDInput | null;
  type?: ModelSubscriptionStringInput | null;
  sType?: ModelSubscriptionStringInput | null;
  framework?: ModelSubscriptionStringInput | null;
  srcFramework?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCommentFilterInput | null> | null;
  or?: Array<ModelSubscriptionCommentFilterInput | null> | null;
};

export type ModelSubscriptionExportFilterInput = {
  id?: ModelSubscriptionStringInput | null;
  destination?: ModelSubscriptionStringInput | null;
  destinationCategoryId?: ModelSubscriptionIDInput | null;
  category?: ModelSubscriptionStringInput | null;
  categoryId?: ModelSubscriptionStringInput | null;
  date?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionExportFilterInput | null> | null;
  or?: Array<ModelSubscriptionExportFilterInput | null> | null;
};

export type ModelSubscriptionReportFilterInput = {
  id?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  managerName?: ModelSubscriptionStringInput | null;
  startDate?: ModelSubscriptionIntInput | null;
  endDate?: ModelSubscriptionIntInput | null;
  type?: ModelSubscriptionStringInput | null;
  isDraft?: ModelSubscriptionBooleanInput | null;
  previousReports?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionIntInput | null;
  and?: Array<ModelSubscriptionReportFilterInput | null> | null;
  or?: Array<ModelSubscriptionReportFilterInput | null> | null;
};

export type ModelSubscriptionRiskFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  idTitle?: ModelSubscriptionStringInput | null;
  entityId?: ModelSubscriptionIDInput | null;
  subEntityId?: ModelSubscriptionIDInput | null;
  assessmentId?: ModelSubscriptionIDInput | null;
  type?: ModelSubscriptionStringInput | null;
  disable?: ModelSubscriptionBooleanInput | null;
  controlIds?: ModelSubscriptionStringInput | null;
  riskTitle?: ModelSubscriptionStringInput | null;
  riskDescription?: ModelSubscriptionStringInput | null;
  riskCategory?: ModelSubscriptionStringInput | null;
  relatedAssets?: ModelSubscriptionStringInput | null;
  residualImpact?: ModelSubscriptionStringInput | null;
  residualProbability?: ModelSubscriptionStringInput | null;
  riskImpact?: ModelSubscriptionStringInput | null;
  riskProbability?: ModelSubscriptionStringInput | null;
  inherentRisk?: ModelSubscriptionFloatInput | null;
  residualRisk?: ModelSubscriptionFloatInput | null;
  riskStatus?: ModelSubscriptionStringInput | null;
  riskStatusUpdatedBy?: ModelSubscriptionStringInput | null;
  notes?: ModelSubscriptionStringInput | null;
  costToRemediate?: ModelSubscriptionIntInput | null;
  costToRemediateType?: ModelSubscriptionStringInput | null;
  primaryLoss?: ModelSubscriptionIntInput | null;
  riskPriority?: ModelSubscriptionStringInput | null;
  hidden?: ModelSubscriptionBooleanInput | null;
  financialToggle?: ModelSubscriptionBooleanInput | null;
  riskTags?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionIntInput | null;
  updatedAt?: ModelSubscriptionIntInput | null;
  riskTasksIds?: ModelSubscriptionStringInput | null;
  isArchived?: ModelSubscriptionBooleanInput | null;
  isEscalated?: ModelSubscriptionBooleanInput | null;
  sensitivity?: ModelSubscriptionFloatInput | null;
  effectiveness?: ModelSubscriptionFloatInput | null;
  deadline?: ModelSubscriptionIntInput | null;
  riskOwnerIds?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionRiskFilterInput | null> | null;
  or?: Array<ModelSubscriptionRiskFilterInput | null> | null;
};

export type ModelSubscriptionRiskOwnerAssignmentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  subEntityId?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionIDInput | null;
  riskId?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionRiskOwnerAssignmentFilterInput | null> | null;
  or?: Array<ModelSubscriptionRiskOwnerAssignmentFilterInput | null> | null;
};

export type ModelSubscriptionSavedFilterFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  filterObject?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionIDInput | null;
  entityId?: ModelSubscriptionIDInput | null;
  type?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSavedFilterFilterInput | null> | null;
  or?: Array<ModelSubscriptionSavedFilterFilterInput | null> | null;
};

export type ModelSubscriptionDomainSettingsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  domain?: ModelSubscriptionStringInput | null;
  key?: ModelSubscriptionStringInput | null;
  average?: ModelSubscriptionFloatInput | null;
  allowedRoutes?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  allowedFeatures?: ModelSubscriptionStringInput | null;
  settings?: ModelSubscriptionStringInput | null;
  kmsStatus?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionDomainSettingsFilterInput | null> | null;
  or?: Array<ModelSubscriptionDomainSettingsFilterInput | null> | null;
};

export type ModelSubscriptionExecutiveSummaryFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  entityId?: ModelSubscriptionStringInput | null;
  title?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionExecutiveSummaryFilterInput | null> | null;
  or?: Array<ModelSubscriptionExecutiveSummaryFilterInput | null> | null;
};

export type ModelSubscriptionNetskopeProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  entityId?: ModelSubscriptionStringInput | null;
  title?: ModelSubscriptionStringInput | null;
  description?: ModelSubscriptionStringInput | null;
  previous?: ModelSubscriptionStringInput | null;
  budget?: ModelSubscriptionIntInput | null;
  comments?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionNetskopeProjectFilterInput | null> | null;
  or?: Array<ModelSubscriptionNetskopeProjectFilterInput | null> | null;
};

export type ModelSubscriptionEntityLayersFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  managerName?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  inherent?: ModelSubscriptionStringInput | null;
  residual?: ModelSubscriptionStringInput | null;
  parentId?: ModelSubscriptionIDInput | null;
  srName?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  domain?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEntityLayersFilterInput | null> | null;
  or?: Array<ModelSubscriptionEntityLayersFilterInput | null> | null;
};

export type ModelSubscriptionEntityWizardFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  isDraft?: ModelSubscriptionBooleanInput | null;
  organizational?: ModelSubscriptionStringInput | null;
  financial?: ModelSubscriptionStringInput | null;
  frameworks?: ModelSubscriptionStringInput | null;
  dataScope?: ModelSubscriptionStringInput | null;
  technical?: ModelSubscriptionStringInput | null;
  riskScenarios?: ModelSubscriptionStringInput | null;
  accountDetails?: ModelSubscriptionStringInput | null;
  currentYear?: ModelSubscriptionStringInput | null;
  parentId?: ModelSubscriptionIDInput | null;
  inherent?: ModelSubscriptionStringInput | null;
  residual?: ModelSubscriptionStringInput | null;
  domainScanned?: ModelSubscriptionBooleanInput | null;
  domainScanTimes?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  riskFramework?: ModelSubscriptionStringInput | null;
  clientDomain?: ModelSubscriptionStringInput | null;
  userId?: ModelSubscriptionStringInput | null;
  applicantInfo?: ModelSubscriptionStringInput | null;
  revenueDetails?: ModelSubscriptionStringInput | null;
  dataInventory?: ModelSubscriptionStringInput | null;
  updatedBy?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEntityWizardFilterInput | null> | null;
  or?: Array<ModelSubscriptionEntityWizardFilterInput | null> | null;
};

export type ModelSubscriptionLogsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  message?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  targetId?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionStringInput | null;
  userID?: ModelSubscriptionIDInput | null;
  and?: Array<ModelSubscriptionLogsFilterInput | null> | null;
  or?: Array<ModelSubscriptionLogsFilterInput | null> | null;
};

export type ModelSubscriptionVersionsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  data?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  targetId?: ModelSubscriptionStringInput | null;
  assessmentId?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVersionsFilterInput | null> | null;
  or?: Array<ModelSubscriptionVersionsFilterInput | null> | null;
};

export type ModelSubscriptionDataStateFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  state?: ModelSubscriptionStringInput | null;
  parentId?: ModelSubscriptionStringInput | null;
  message?: ModelSubscriptionStringInput | null;
  detail?: ModelSubscriptionStringInput | null;
  type?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionDataStateFilterInput | null> | null;
  or?: Array<ModelSubscriptionDataStateFilterInput | null> | null;
};

export type ModelSubscriptionSmartMappingDetailFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  uniqueId?: ModelSubscriptionIDInput | null;
  metadata?: ModelSubscriptionStringInput | null;
  answer?: ModelSubscriptionFloatInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSmartMappingDetailFilterInput | null> | null;
  or?: Array<ModelSubscriptionSmartMappingDetailFilterInput | null> | null;
};

export type ModelSubscriptionApiCallRegistryFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  callMaker?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionApiCallRegistryFilterInput | null> | null;
  or?: Array<ModelSubscriptionApiCallRegistryFilterInput | null> | null;
};

export type ModelSubscriptionBreachDataFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  domain?: ModelSubscriptionStringInput | null;
  breaches?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBreachDataFilterInput | null> | null;
  or?: Array<ModelSubscriptionBreachDataFilterInput | null> | null;
};

export type CreateFrameworksNodesMutation = {
  __typename: "IDList";
  ids?: Array<string | null> | null;
};

export type AssignUserMutation = {
  __typename: "AssignUser";
  createdAssignments?: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null> | null;
  deletedAssignments?: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null> | null;
};

export type IncrementQuestionnaireAnswerMutation = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type RemoveUserAssignmentMutation = {
  __typename: "AssignUser";
  createdAssignments?: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null> | null;
  deletedAssignments?: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null> | null;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type CreateTagsMutation = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTagsMutation = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTagsMutation = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateRoleMutation = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type UpdateRoleMutation = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type DeleteRoleMutation = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type CreateRiskLineChartMutation = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateRiskLineChartMutation = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteRiskLineChartMutation = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateGroupMutation = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateGroupMutation = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteGroupMutation = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateQuestionDataMutation = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateQuestionDataMutation = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteQuestionDataMutation = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateArchivedUserMutation = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type UpdateArchivedUserMutation = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type DeleteArchivedUserMutation = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type CreateEntityMutation = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type UpdateEntityMutation = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type DeleteEntityMutation = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type CreateGraphMutation = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateGraphMutation = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type DeleteGraphMutation = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type CreateRevisionMutation = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRevisionMutation = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRevisionMutation = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateVendorsDetailMutation = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVendorsDetailMutation = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVendorsDetailMutation = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateFrameworkScoresMutation = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFrameworkScoresMutation = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteFrameworkScoresMutation = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateIntelligenceMutation = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIntelligenceMutation = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIntelligenceMutation = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateBreachesMutation = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBreachesMutation = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteBreachesMutation = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateExternalThreatsMutation = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateExternalThreatsMutation = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteExternalThreatsMutation = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateOthersMutation = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOthersMutation = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOthersMutation = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateQuestionnaireCompletionMutation = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateQuestionnaireCompletionMutation = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteQuestionnaireCompletionMutation = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateAnswerMutation = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type UpdateAnswerMutation = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type DeleteAnswerMutation = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type CreateAssignmentMutation = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type UpdateAssignmentMutation = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type DeleteAssignmentMutation = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type CreateDisableAssignmentMutation = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type UpdateDisableAssignmentMutation = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type DeleteDisableAssignmentMutation = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type CreateAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateIntegrationMappingsMutation = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateIntegrationMappingsMutation = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteIntegrationMappingsMutation = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateQuestionSettingsMutation = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type UpdateQuestionSettingsMutation = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type DeleteQuestionSettingsMutation = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type CreateTaskActionMutation = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type UpdateTaskActionMutation = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type DeleteTaskActionMutation = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type CreateUsersAssignmentMapperMutation = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type UpdateUsersAssignmentMapperMutation = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type DeleteUsersAssignmentMapperMutation = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type CreateFrameworkSettingsMutation = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFrameworkSettingsMutation = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteFrameworkSettingsMutation = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomTaskMutation = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomTaskMutation = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCustomTaskMutation = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCustomTaskAssignmentMutation = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCustomTaskAssignmentMutation = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCustomTaskAssignmentMutation = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAssessmentStandardFrameworkMutation = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAssessmentStandardFrameworkMutation = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAssessmentStandardFrameworkMutation = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateFrameworkManagerMutation = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateFrameworkManagerMutation = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteFrameworkManagerMutation = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCommentMutation = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCommentMutation = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCommentMutation = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateExportMutation = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateExportMutation = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteExportMutation = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateReportMutation = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type UpdateReportMutation = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type DeleteReportMutation = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type CreateRiskMutation = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type UpdateRiskMutation = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type DeleteRiskMutation = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type CreateRiskOwnerAssignmentMutation = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRiskOwnerAssignmentMutation = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRiskOwnerAssignmentMutation = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSavedFilterMutation = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSavedFilterMutation = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSavedFilterMutation = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateDomainSettingsMutation = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type UpdateDomainSettingsMutation = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type DeleteDomainSettingsMutation = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type CreateExecutiveSummaryMutation = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateExecutiveSummaryMutation = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteExecutiveSummaryMutation = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateNetskopeProjectMutation = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateNetskopeProjectMutation = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteNetskopeProjectMutation = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateEntityLayersMutation = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type UpdateEntityLayersMutation = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type DeleteEntityLayersMutation = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type CreateEntityWizardMutation = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type UpdateEntityWizardMutation = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type DeleteEntityWizardMutation = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type CreateLogsMutation = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateLogsMutation = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteLogsMutation = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateVersionsMutation = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateVersionsMutation = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteVersionsMutation = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateDataStateMutation = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDataStateMutation = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteDataStateMutation = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateSmartMappingDetailMutation = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSmartMappingDetailMutation = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSmartMappingDetailMutation = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type CreateApiCallRegistryMutation = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateApiCallRegistryMutation = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type DeleteApiCallRegistryMutation = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type CreateBreachDataMutation = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type UpdateBreachDataMutation = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type DeleteBreachDataMutation = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type ListAlertsQuery = {
  __typename: "Alert";
  id?: string | null;
  type?: string | null;
  content?: string | null;
  priority?: number | null;
};

export type RetrieveUsersQuery = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UserByEmailQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UserRoleQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UserByRoleIdQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type UserByEntityIdQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetTagsQuery = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTagsQuery = {
  __typename: "ModelTagsConnection";
  items: Array<{
    __typename: "Tags";
    id: string;
    name: string;
    entityId?: string | null;
    domain?: string | null;
    targetId?: string | null;
    assessmentFrameworkKey?: string | null;
    referenceId?: string | null;
    questionRefId?: string | null;
    questionData?: {
      __typename: "QuestionData";
      id: string;
      childId?: string | null;
      frameworkKey?: string | null;
      function?: string | null;
      category?: string | null;
      subCategory?: string | null;
      controlNameLabel?: string | null;
      score?: number | null;
      rootId?: string | null;
      assessmentId?: string | null;
      name?: string | null;
      left?: number | null;
      right?: number | null;
      reviewStatus?: ReviewerActions | null;
      resentBackStatus?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TagsByEntityIdQuery = {
  __typename: "ModelTagsConnection";
  items: Array<{
    __typename: "Tags";
    id: string;
    name: string;
    entityId?: string | null;
    domain?: string | null;
    targetId?: string | null;
    assessmentFrameworkKey?: string | null;
    referenceId?: string | null;
    questionRefId?: string | null;
    questionData?: {
      __typename: "QuestionData";
      id: string;
      childId?: string | null;
      frameworkKey?: string | null;
      function?: string | null;
      category?: string | null;
      subCategory?: string | null;
      controlNameLabel?: string | null;
      score?: number | null;
      rootId?: string | null;
      assessmentId?: string | null;
      name?: string | null;
      left?: number | null;
      right?: number | null;
      reviewStatus?: ReviewerActions | null;
      resentBackStatus?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TagsByTargetIdQuery = {
  __typename: "ModelTagsConnection";
  items: Array<{
    __typename: "Tags";
    id: string;
    name: string;
    entityId?: string | null;
    domain?: string | null;
    targetId?: string | null;
    assessmentFrameworkKey?: string | null;
    referenceId?: string | null;
    questionRefId?: string | null;
    questionData?: {
      __typename: "QuestionData";
      id: string;
      childId?: string | null;
      frameworkKey?: string | null;
      function?: string | null;
      category?: string | null;
      subCategory?: string | null;
      controlNameLabel?: string | null;
      score?: number | null;
      rootId?: string | null;
      assessmentId?: string | null;
      name?: string | null;
      left?: number | null;
      right?: number | null;
      reviewStatus?: ReviewerActions | null;
      resentBackStatus?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TagsByAssessmentFrameworkKeyQuery = {
  __typename: "ModelTagsConnection";
  items: Array<{
    __typename: "Tags";
    id: string;
    name: string;
    entityId?: string | null;
    domain?: string | null;
    targetId?: string | null;
    assessmentFrameworkKey?: string | null;
    referenceId?: string | null;
    questionRefId?: string | null;
    questionData?: {
      __typename: "QuestionData";
      id: string;
      childId?: string | null;
      frameworkKey?: string | null;
      function?: string | null;
      category?: string | null;
      subCategory?: string | null;
      controlNameLabel?: string | null;
      score?: number | null;
      rootId?: string | null;
      assessmentId?: string | null;
      name?: string | null;
      left?: number | null;
      right?: number | null;
      reviewStatus?: ReviewerActions | null;
      resentBackStatus?: boolean | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRoleQuery = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type ListRolesQuery = {
  __typename: "ModelRoleConnection";
  items: Array<{
    __typename: "Role";
    id: string;
    name: string;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    vendorIds?: Array<string | null> | null;
    isRootEntityAccess?: boolean | null;
    defaultOrEntityId: string;
    screenPermissions?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    parentId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type RolesByDefaultOrEntityIdQuery = {
  __typename: "ModelRoleConnection";
  items: Array<{
    __typename: "Role";
    id: string;
    name: string;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    vendorIds?: Array<string | null> | null;
    isRootEntityAccess?: boolean | null;
    defaultOrEntityId: string;
    screenPermissions?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    parentId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetRiskLineChartQuery = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListRiskLineChartsQuery = {
  __typename: "ModelRiskLineChartConnection";
  items: Array<{
    __typename: "RiskLineChart";
    id: string;
    assessmentId: string;
    riskId: string;
    residual?: RiskImpactEnum | null;
    inherent?: RiskImpactEnum | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetGroupQuery = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListGroupsQuery = {
  __typename: "ModelGroupConnection";
  items: Array<{
    __typename: "Group";
    id: string;
    name: string;
    rootId?: string | null;
    tags?: Array<string | null> | null;
    score?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GroupsByRootIdQuery = {
  __typename: "ModelGroupConnection";
  items: Array<{
    __typename: "Group";
    id: string;
    name: string;
    rootId?: string | null;
    tags?: Array<string | null> | null;
    score?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetQuestionDataQuery = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListQuestionDataQuery = {
  __typename: "ModelQuestionDataConnection";
  items: Array<{
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type QuestionsByAssessmentIdQuery = {
  __typename: "ModelQuestionDataConnection";
  items: Array<{
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetArchivedUserQuery = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type ListArchivedUsersQuery = {
  __typename: "ModelArchivedUserConnection";
  items: Array<{
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ArchivedUserRoleQuery = {
  __typename: "ModelArchivedUserConnection";
  items: Array<{
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ArchivedUserByEntityIdQuery = {
  __typename: "ModelArchivedUserConnection";
  items: Array<{
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEntityQuery = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type ListEntitiesQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntityRootEntityByTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntityRootEntityByTypeAndFrameworkNameQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntityChildEntityByTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntityFrameworkQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntityParentQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntitiesByEntityTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntitiesByDomainAndEntityTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntitiesByDomainAndCreatedAtQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type EntityByAssessmentIdQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetGraphQuery = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type ListGraphsQuery = {
  __typename: "ModelGraphConnection";
  items: Array<{
    __typename: "Graph";
    id: string;
    name: string;
    type: string;
    rootId?: string | null;
    status?: string | null;
    entities?: Array<string | null> | null;
    domainOrFunctions?: {
      __typename: "EntityData";
      type?: string | null;
      data?: Array<string | null> | null;
    } | null;
    groups?: Array<{
      __typename: "GroupGraphInput";
      id?: string | null;
      name?: string | null;
      tags?: Array<string | null> | null;
    } | null> | null;
    tags?: Array<string | null> | null;
    createdBy?: string | null;
    timeSpan?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GraphsByRootIdQuery = {
  __typename: "ModelGraphConnection";
  items: Array<{
    __typename: "Graph";
    id: string;
    name: string;
    type: string;
    rootId?: string | null;
    status?: string | null;
    entities?: Array<string | null> | null;
    domainOrFunctions?: {
      __typename: "EntityData";
      type?: string | null;
      data?: Array<string | null> | null;
    } | null;
    groups?: Array<{
      __typename: "GroupGraphInput";
      id?: string | null;
      name?: string | null;
      tags?: Array<string | null> | null;
    } | null> | null;
    tags?: Array<string | null> | null;
    createdBy?: string | null;
    timeSpan?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRevisionQuery = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRevisionsQuery = {
  __typename: "ModelRevisionConnection";
  items: Array<{
    __typename: "Revision";
    id: string;
    frameworkKey?: string | null;
    assessmentId?: string | null;
    groupId?: string | null;
    date?: string | null;
    rootId?: string | null;
    tagId?: string | null;
    scores?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVendorsDetailQuery = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListVendorsDetailsQuery = {
  __typename: "ModelVendorsDetailConnection";
  items: Array<{
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetFrameworkScoresQuery = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListFrameworkScoresQuery = {
  __typename: "ModelFrameworkScoresConnection";
  items: Array<{
    __typename: "FrameworkScores";
    id: string;
    assessmentId: string;
    type: StandardType;
    total: number;
    target: number;
    collection: number;
    remediation: number;
    reassessment?: number | null;
    totalQuestions: number;
    completedQuestions: number;
    totalGaps: number;
    criticalGaps: number;
    notApplicable?: boolean | null;
    assignmentCount?: number | null;
    riskStandardsCount?: number | null;
    complianceStandardsCount?: number | null;
    standardsCount?: number | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    groups?: string | null;
    maturity?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type FrameworkScoresByAssessmentQuery = {
  __typename: "ModelFrameworkScoresConnection";
  items: Array<{
    __typename: "FrameworkScores";
    id: string;
    assessmentId: string;
    type: StandardType;
    total: number;
    target: number;
    collection: number;
    remediation: number;
    reassessment?: number | null;
    totalQuestions: number;
    completedQuestions: number;
    totalGaps: number;
    criticalGaps: number;
    notApplicable?: boolean | null;
    assignmentCount?: number | null;
    riskStandardsCount?: number | null;
    complianceStandardsCount?: number | null;
    standardsCount?: number | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    groups?: string | null;
    maturity?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIntelligenceQuery = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListIntelligencesQuery = {
  __typename: "ModelIntelligenceConnection";
  items: Array<{
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetBreachesQuery = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListBreachesQuery = {
  __typename: "ModelBreachesConnection";
  items: Array<{
    __typename: "Breaches";
    id: string;
    vendorId: string;
    date?: number | null;
    year?: number | null;
    name?: string | null;
    description?: string | null;
    leaks?: number | null;
    data?: Array<string | null> | null;
    status?: string | null;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type BreachesByVendorIdAndStatusQuery = {
  __typename: "ModelBreachesConnection";
  items: Array<{
    __typename: "Breaches";
    id: string;
    vendorId: string;
    date?: number | null;
    year?: number | null;
    name?: string | null;
    description?: string | null;
    leaks?: number | null;
    data?: Array<string | null> | null;
    status?: string | null;
    email?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetExternalThreatsQuery = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListExternalThreatsQuery = {
  __typename: "ModelExternalThreatsConnection";
  items: Array<{
    __typename: "ExternalThreats";
    id: string;
    vendorId: string;
    source?: string | null;
    findings?: Array<string | null> | null;
    riskScore?: string | null;
    details?: Array<{
      __typename: "ThreatDetails";
      ip?: string | null;
      port?: number | null;
      cve?: string | null;
      cvss?: number | null;
    } | null> | null;
    status?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ExternalThreatsByVendorIdAndStatusQuery = {
  __typename: "ModelExternalThreatsConnection";
  items: Array<{
    __typename: "ExternalThreats";
    id: string;
    vendorId: string;
    source?: string | null;
    findings?: Array<string | null> | null;
    riskScore?: string | null;
    details?: Array<{
      __typename: "ThreatDetails";
      ip?: string | null;
      port?: number | null;
      cve?: string | null;
      cvss?: number | null;
    } | null> | null;
    status?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetOthersQuery = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListOthersQuery = {
  __typename: "ModelOthersConnection";
  items: Array<{
    __typename: "Others";
    id: string;
    vendorId: string;
    name?: string | null;
    label?: string | null;
    severity?: string | null;
    description?: string | null;
    status?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OthersByVendorIdAndStatusQuery = {
  __typename: "ModelOthersConnection";
  items: Array<{
    __typename: "Others";
    id: string;
    vendorId: string;
    name?: string | null;
    label?: string | null;
    severity?: string | null;
    description?: string | null;
    status?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetQuestionnaireCompletionQuery = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListQuestionnaireCompletionsQuery = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<{
    __typename: "QuestionnaireCompletion";
    id?: string | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    userId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignmentMap?: string | null;
    firstAnswer?: number | null;
    lastAnswer?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CompletionByUserQuery = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<{
    __typename: "QuestionnaireCompletion";
    id?: string | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    userId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignmentMap?: string | null;
    firstAnswer?: number | null;
    lastAnswer?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type CompletionByAssessmentQuery = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<{
    __typename: "QuestionnaireCompletion";
    id?: string | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    userId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assignmentMap?: string | null;
    firstAnswer?: number | null;
    lastAnswer?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetAnswerQuery = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type ListAnswersQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByUserIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByQuestionIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByQuestionIdAndUserQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByAssessmentIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByAssessmentIdAndUserIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByAssessmentQuestionUserQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByAssessmentIdAndQuestionIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AnswersByAssessmentIdAndFrameworkKeyQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt?: string | null;
    answer?: number | null;
    answerName?: string | null;
    comment?: string | null;
    file?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    userId: string;
    riskAction?: RiskAction | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    questionId?: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken?: boolean | null;
    triggerStopper?: boolean | null;
    origin?: AnswerOrigin | null;
    auditStatus?: string | null;
    isUptodate?: boolean | null;
    reviewerActionStatus?: ReviewerActions | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssignmentQuery = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type ListAssignmentsQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type AssignmentsByUserIdQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type AssignmentsByUserIdAndAssessmentQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type AssignmentsByAssessmentIdQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
    frameworkKey?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetDisableAssignmentQuery = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type ListDisableAssignmentsQuery = {
  __typename: "ModelDisableAssignmentConnection";
  items: Array<{
    __typename: "DisableAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
  } | null>;
  nextToken?: string | null;
};

export type DisableAssignmentsByUserIdQuery = {
  __typename: "ModelDisableAssignmentConnection";
  items: Array<{
    __typename: "DisableAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
  } | null>;
  nextToken?: string | null;
};

export type DisableAssignmentsByUserIdAndAssessmentQuery = {
  __typename: "ModelDisableAssignmentConnection";
  items: Array<{
    __typename: "DisableAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
  } | null>;
  nextToken?: string | null;
};

export type DisableAssignmentsByAssessmentIdQuery = {
  __typename: "ModelDisableAssignmentConnection";
  items: Array<{
    __typename: "DisableAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
  } | null>;
  nextToken?: string | null;
};

export type GetAssessmentQuery = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAssessmentsQuery = {
  __typename: "ModelAssessmentConnection";
  items: Array<{
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AssessmentsByRootIdQuery = {
  __typename: "ModelAssessmentConnection";
  items: Array<{
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type AssessmentsByChildIdQuery = {
  __typename: "ModelAssessmentConnection";
  items: Array<{
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetIntegrationMappingsQuery = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListIntegrationMappingsQuery = {
  __typename: "ModelIntegrationMappingsConnection";
  items: Array<{
    __typename: "IntegrationMappings";
    id: string;
    name: string;
    projectKey?: string | null;
    fileName?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type IntegrationMappingsByProjectKeyQuery = {
  __typename: "ModelIntegrationMappingsConnection";
  items: Array<{
    __typename: "IntegrationMappings";
    id: string;
    name: string;
    projectKey?: string | null;
    fileName?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetQuestionSettingsQuery = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type ListQuestionSettingsQuery = {
  __typename: "ModelQuestionSettingsConnection";
  items: Array<{
    __typename: "QuestionSettings";
    id: string;
    questionId: string;
    assessmentId: string;
    isCollaborative?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isApprovalRequired?: boolean | null;
    suggestedArtifactAnswers?: string | null;
    requiredCommentAnswers?: string | null;
    versionFrequency?: Array<string | null> | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isInfoSec?: boolean | null;
    frameworkName?: string | null;
    left?: number | null;
    right?: number | null;
  } | null>;
  nextToken?: string | null;
};

export type QuestionSettingsByQuestionIdQuery = {
  __typename: "ModelQuestionSettingsConnection";
  items: Array<{
    __typename: "QuestionSettings";
    id: string;
    questionId: string;
    assessmentId: string;
    isCollaborative?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isApprovalRequired?: boolean | null;
    suggestedArtifactAnswers?: string | null;
    requiredCommentAnswers?: string | null;
    versionFrequency?: Array<string | null> | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isInfoSec?: boolean | null;
    frameworkName?: string | null;
    left?: number | null;
    right?: number | null;
  } | null>;
  nextToken?: string | null;
};

export type QuestionSettingsByAssessmentIdQuery = {
  __typename: "ModelQuestionSettingsConnection";
  items: Array<{
    __typename: "QuestionSettings";
    id: string;
    questionId: string;
    assessmentId: string;
    isCollaborative?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isApprovalRequired?: boolean | null;
    suggestedArtifactAnswers?: string | null;
    requiredCommentAnswers?: string | null;
    versionFrequency?: Array<string | null> | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    isInfoSec?: boolean | null;
    frameworkName?: string | null;
    left?: number | null;
    right?: number | null;
  } | null>;
  nextToken?: string | null;
};

export type GetTaskActionQuery = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type ListTaskActionsQuery = {
  __typename: "ModelTaskActionConnection";
  items: Array<{
    __typename: "TaskAction";
    id: string;
    createdAt?: string | null;
    score?: number | null;
    action?: string | null;
    assessmentId: string;
    taskAssignmentID?: string | null;
    questionId?: string | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    userId?: string | null;
    date: number;
    triggerStopper?: boolean | null;
    searchString?: string | null;
    questionIds?: Array<string | null> | null;
    smartMappingIds?: Array<string | null> | null;
    title?: string | null;
    impact?: string | null;
    maturity?: number | null;
    controlsConnected?: Array<{
      __typename: "ConnectedControl";
      id?: string | null;
      name?: string | null;
      label?: string | null;
    } | null> | null;
    type?: string | null;
    description?: string | null;
    tabName?: string | null;
    managerAction?: string | null;
    rootEntityId?: string | null;
    dueDate?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TaskActionsByAssessmentIdQuery = {
  __typename: "ModelTaskActionConnection";
  items: Array<{
    __typename: "TaskAction";
    id: string;
    createdAt?: string | null;
    score?: number | null;
    action?: string | null;
    assessmentId: string;
    taskAssignmentID?: string | null;
    questionId?: string | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    userId?: string | null;
    date: number;
    triggerStopper?: boolean | null;
    searchString?: string | null;
    questionIds?: Array<string | null> | null;
    smartMappingIds?: Array<string | null> | null;
    title?: string | null;
    impact?: string | null;
    maturity?: number | null;
    controlsConnected?: Array<{
      __typename: "ConnectedControl";
      id?: string | null;
      name?: string | null;
      label?: string | null;
    } | null> | null;
    type?: string | null;
    description?: string | null;
    tabName?: string | null;
    managerAction?: string | null;
    rootEntityId?: string | null;
    dueDate?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TaskActionsByAssessmentIdAndTabNameQuery = {
  __typename: "ModelTaskActionConnection";
  items: Array<{
    __typename: "TaskAction";
    id: string;
    createdAt?: string | null;
    score?: number | null;
    action?: string | null;
    assessmentId: string;
    taskAssignmentID?: string | null;
    questionId?: string | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    userId?: string | null;
    date: number;
    triggerStopper?: boolean | null;
    searchString?: string | null;
    questionIds?: Array<string | null> | null;
    smartMappingIds?: Array<string | null> | null;
    title?: string | null;
    impact?: string | null;
    maturity?: number | null;
    controlsConnected?: Array<{
      __typename: "ConnectedControl";
      id?: string | null;
      name?: string | null;
      label?: string | null;
    } | null> | null;
    type?: string | null;
    description?: string | null;
    tabName?: string | null;
    managerAction?: string | null;
    rootEntityId?: string | null;
    dueDate?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TaskActionsByUserIdQuery = {
  __typename: "ModelTaskActionConnection";
  items: Array<{
    __typename: "TaskAction";
    id: string;
    createdAt?: string | null;
    score?: number | null;
    action?: string | null;
    assessmentId: string;
    taskAssignmentID?: string | null;
    questionId?: string | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    userId?: string | null;
    date: number;
    triggerStopper?: boolean | null;
    searchString?: string | null;
    questionIds?: Array<string | null> | null;
    smartMappingIds?: Array<string | null> | null;
    title?: string | null;
    impact?: string | null;
    maturity?: number | null;
    controlsConnected?: Array<{
      __typename: "ConnectedControl";
      id?: string | null;
      name?: string | null;
      label?: string | null;
    } | null> | null;
    type?: string | null;
    description?: string | null;
    tabName?: string | null;
    managerAction?: string | null;
    rootEntityId?: string | null;
    dueDate?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type TaskActionsByRootEntityIdQuery = {
  __typename: "ModelTaskActionConnection";
  items: Array<{
    __typename: "TaskAction";
    id: string;
    createdAt?: string | null;
    score?: number | null;
    action?: string | null;
    assessmentId: string;
    taskAssignmentID?: string | null;
    questionId?: string | null;
    frameworkName: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    userId?: string | null;
    date: number;
    triggerStopper?: boolean | null;
    searchString?: string | null;
    questionIds?: Array<string | null> | null;
    smartMappingIds?: Array<string | null> | null;
    title?: string | null;
    impact?: string | null;
    maturity?: number | null;
    controlsConnected?: Array<{
      __typename: "ConnectedControl";
      id?: string | null;
      name?: string | null;
      label?: string | null;
    } | null> | null;
    type?: string | null;
    description?: string | null;
    tabName?: string | null;
    managerAction?: string | null;
    rootEntityId?: string | null;
    dueDate?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetUsersAssignmentMapperQuery = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type ListUsersAssignmentMappersQuery = {
  __typename: "ModelUsersAssignmentMapperConnection";
  items: Array<{
    __typename: "UsersAssignmentMapper";
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    score?: number | null;
    assessmentId?: string | null;
    taskId?: string | null;
    userId?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type UsersByAssessmentIdQuery = {
  __typename: "ModelUsersAssignmentMapperConnection";
  items: Array<{
    __typename: "UsersAssignmentMapper";
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    score?: number | null;
    assessmentId?: string | null;
    taskId?: string | null;
    userId?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type UsersByTaskIdQuery = {
  __typename: "ModelUsersAssignmentMapperConnection";
  items: Array<{
    __typename: "UsersAssignmentMapper";
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    score?: number | null;
    assessmentId?: string | null;
    taskId?: string | null;
    userId?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TasksByUserIdQuery = {
  __typename: "ModelUsersAssignmentMapperConnection";
  items: Array<{
    __typename: "UsersAssignmentMapper";
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    score?: number | null;
    assessmentId?: string | null;
    taskId?: string | null;
    userId?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type TasksByUserIdAndAssessmentIdQuery = {
  __typename: "ModelUsersAssignmentMapperConnection";
  items: Array<{
    __typename: "UsersAssignmentMapper";
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    score?: number | null;
    assessmentId?: string | null;
    taskId?: string | null;
    userId?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type GetFrameworkSettingsQuery = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListFrameworkSettingsQuery = {
  __typename: "ModelFrameworkSettingsConnection";
  items: Array<{
    __typename: "FrameworkSettings";
    id: string;
    settings?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomTaskQuery = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCustomTasksQuery = {
  __typename: "ModelCustomTaskConnection";
  items: Array<{
    __typename: "CustomTask";
    id: string;
    name?: string | null;
    label?: string | null;
    impact?: ImpactEnum | null;
    description?: string | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: TaskStatusEnum | null;
    assessmentId: string;
    customTodo?: string | null;
    dueDate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CustomTasksByAssessmentIdQuery = {
  __typename: "ModelCustomTaskConnection";
  items: Array<{
    __typename: "CustomTask";
    id: string;
    name?: string | null;
    label?: string | null;
    impact?: ImpactEnum | null;
    description?: string | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: TaskStatusEnum | null;
    assessmentId: string;
    customTodo?: string | null;
    dueDate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCustomTaskAssignmentQuery = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCustomTaskAssignmentsQuery = {
  __typename: "ModelCustomTaskAssignmentConnection";
  items: Array<{
    __typename: "CustomTaskAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CustomTaskAssignmentsByAssessmentIdQuery = {
  __typename: "ModelCustomTaskAssignmentConnection";
  items: Array<{
    __typename: "CustomTaskAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetAssessmentStandardFrameworkQuery = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAssessmentStandardFrameworksQuery = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<{
    __typename: "AssessmentStandardFramework";
    id: string;
    key: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    assessmentId: string;
    managerId?: string | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    selectedChapters?: Array<string | null> | null;
    managers?: {
      __typename: "ModelFrameworkManagerConnection";
      items: Array<{
        __typename: "FrameworkManager";
        id?: string | null;
        assessmentId: string;
        standardFrameworkId: string;
        managerId: string;
        user?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        left?: number | null;
        right?: number | null;
        removedQuestions?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    not_added?: boolean | null;
    archived?: boolean | null;
    reassessmentStatus?: ReassessmentEnum | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    suggestedArtifact?: string | null;
    suggestedComments?: string | null;
    level?: string | null;
    newStartReassessDate?: number | null;
    signatureUserID?: string | null;
    isLocked?: boolean | null;
    signedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    signature?: {
      __typename: "SignatureObject";
      title?: string | null;
      printName?: string | null;
      disclaimerDate?: number | null;
      image?: string | null;
      text?: string | null;
      draw?: string | null;
      signDate?: string | null;
    } | null;
    lockedByUserID?: string | null;
    lockedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StandardFrameworksByAssessmentIdQuery = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<{
    __typename: "AssessmentStandardFramework";
    id: string;
    key: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    assessmentId: string;
    managerId?: string | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    selectedChapters?: Array<string | null> | null;
    managers?: {
      __typename: "ModelFrameworkManagerConnection";
      items: Array<{
        __typename: "FrameworkManager";
        id?: string | null;
        assessmentId: string;
        standardFrameworkId: string;
        managerId: string;
        user?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        left?: number | null;
        right?: number | null;
        removedQuestions?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    not_added?: boolean | null;
    archived?: boolean | null;
    reassessmentStatus?: ReassessmentEnum | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    suggestedArtifact?: string | null;
    suggestedComments?: string | null;
    level?: string | null;
    newStartReassessDate?: number | null;
    signatureUserID?: string | null;
    isLocked?: boolean | null;
    signedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    signature?: {
      __typename: "SignatureObject";
      title?: string | null;
      printName?: string | null;
      disclaimerDate?: number | null;
      image?: string | null;
      text?: string | null;
      draw?: string | null;
      signDate?: string | null;
    } | null;
    lockedByUserID?: string | null;
    lockedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StandardFrameworksBySignatureUserIdQuery = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<{
    __typename: "AssessmentStandardFramework";
    id: string;
    key: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    assessmentId: string;
    managerId?: string | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    selectedChapters?: Array<string | null> | null;
    managers?: {
      __typename: "ModelFrameworkManagerConnection";
      items: Array<{
        __typename: "FrameworkManager";
        id?: string | null;
        assessmentId: string;
        standardFrameworkId: string;
        managerId: string;
        user?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        left?: number | null;
        right?: number | null;
        removedQuestions?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    not_added?: boolean | null;
    archived?: boolean | null;
    reassessmentStatus?: ReassessmentEnum | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    suggestedArtifact?: string | null;
    suggestedComments?: string | null;
    level?: string | null;
    newStartReassessDate?: number | null;
    signatureUserID?: string | null;
    isLocked?: boolean | null;
    signedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    signature?: {
      __typename: "SignatureObject";
      title?: string | null;
      printName?: string | null;
      disclaimerDate?: number | null;
      image?: string | null;
      text?: string | null;
      draw?: string | null;
      signDate?: string | null;
    } | null;
    lockedByUserID?: string | null;
    lockedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type StandardFrameworksByLockedUserIdQuery = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<{
    __typename: "AssessmentStandardFramework";
    id: string;
    key: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    assessmentId: string;
    managerId?: string | null;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    selectedChapters?: Array<string | null> | null;
    managers?: {
      __typename: "ModelFrameworkManagerConnection";
      items: Array<{
        __typename: "FrameworkManager";
        id?: string | null;
        assessmentId: string;
        standardFrameworkId: string;
        managerId: string;
        user?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        left?: number | null;
        right?: number | null;
        removedQuestions?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    not_added?: boolean | null;
    archived?: boolean | null;
    reassessmentStatus?: ReassessmentEnum | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    suggestedArtifact?: string | null;
    suggestedComments?: string | null;
    level?: string | null;
    newStartReassessDate?: number | null;
    signatureUserID?: string | null;
    isLocked?: boolean | null;
    signedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    signature?: {
      __typename: "SignatureObject";
      title?: string | null;
      printName?: string | null;
      disclaimerDate?: number | null;
      image?: string | null;
      text?: string | null;
      draw?: string | null;
      signDate?: string | null;
    } | null;
    lockedByUserID?: string | null;
    lockedBy?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetFrameworkManagerQuery = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListFrameworkManagersQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id?: string | null;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left?: number | null;
    right?: number | null;
    removedQuestions?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type FrameworkManagersByAssessmentIdQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id?: string | null;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left?: number | null;
    right?: number | null;
    removedQuestions?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type FrameworkManagersByStandardFrameworkIdQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id?: string | null;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left?: number | null;
    right?: number | null;
    removedQuestions?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type FrameworkManagersByManagerIdQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id?: string | null;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    left?: number | null;
    right?: number | null;
    removedQuestions?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetCommentQuery = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListCommentsQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date?: number | null;
    questionId?: string | null;
    riskId?: string | null;
    assessmentId?: string | null;
    userId: string;
    exports?: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category?: CategoryEnum | null;
        categoryId: string;
        date?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    user?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    archivedUser?: {
      __typename: "ArchivedUser";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone?: string | null;
      entityId?: string | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      updatedAt: string;
    } | null;
    type?: string | null;
    sType?: string | null;
    framework?: string | null;
    srcFramework?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CommentsByQuestionIdQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date?: number | null;
    questionId?: string | null;
    riskId?: string | null;
    assessmentId?: string | null;
    userId: string;
    exports?: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category?: CategoryEnum | null;
        categoryId: string;
        date?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    user?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    archivedUser?: {
      __typename: "ArchivedUser";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone?: string | null;
      entityId?: string | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      updatedAt: string;
    } | null;
    type?: string | null;
    sType?: string | null;
    framework?: string | null;
    srcFramework?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CommentsByRiskIdQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date?: number | null;
    questionId?: string | null;
    riskId?: string | null;
    assessmentId?: string | null;
    userId: string;
    exports?: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category?: CategoryEnum | null;
        categoryId: string;
        date?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    user?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    archivedUser?: {
      __typename: "ArchivedUser";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone?: string | null;
      entityId?: string | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      updatedAt: string;
    } | null;
    type?: string | null;
    sType?: string | null;
    framework?: string | null;
    srcFramework?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CommentByAssessmentIdQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date?: number | null;
    questionId?: string | null;
    riskId?: string | null;
    assessmentId?: string | null;
    userId: string;
    exports?: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category?: CategoryEnum | null;
        categoryId: string;
        date?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    user?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    archivedUser?: {
      __typename: "ArchivedUser";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone?: string | null;
      entityId?: string | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      updatedAt: string;
    } | null;
    type?: string | null;
    sType?: string | null;
    framework?: string | null;
    srcFramework?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CommentsByAssessmentIdAndQuestionIdQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date?: number | null;
    questionId?: string | null;
    riskId?: string | null;
    assessmentId?: string | null;
    userId: string;
    exports?: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category?: CategoryEnum | null;
        categoryId: string;
        date?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    user?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    archivedUser?: {
      __typename: "ArchivedUser";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone?: string | null;
      entityId?: string | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      updatedAt: string;
    } | null;
    type?: string | null;
    sType?: string | null;
    framework?: string | null;
    srcFramework?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type CommentsByFrameworkAndAssessmentIdQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date?: number | null;
    questionId?: string | null;
    riskId?: string | null;
    assessmentId?: string | null;
    userId: string;
    exports?: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category?: CategoryEnum | null;
        categoryId: string;
        date?: number | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    user?: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    archivedUser?: {
      __typename: "ArchivedUser";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone?: string | null;
      entityId?: string | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      updatedAt: string;
    } | null;
    type?: string | null;
    sType?: string | null;
    framework?: string | null;
    srcFramework?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetExportQuery = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListExportsQuery = {
  __typename: "ModelExportConnection";
  items: Array<{
    __typename: "Export";
    id: string;
    destination: ExportSourceEnum;
    destinationCategoryId: string;
    category?: CategoryEnum | null;
    categoryId: string;
    date?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ExportsByDestinationCategoryIdQuery = {
  __typename: "ModelExportConnection";
  items: Array<{
    __typename: "Export";
    id: string;
    destination: ExportSourceEnum;
    destinationCategoryId: string;
    category?: CategoryEnum | null;
    categoryId: string;
    date?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ExportsByCategoryIdQuery = {
  __typename: "ModelExportConnection";
  items: Array<{
    __typename: "Export";
    id: string;
    destination: ExportSourceEnum;
    destinationCategoryId: string;
    category?: CategoryEnum | null;
    categoryId: string;
    date?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetReportQuery = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type ListReportsQuery = {
  __typename: "ModelReportConnection";
  items: Array<{
    __typename: "Report";
    id: string;
    entityId: string;
    name: string;
    managerName?: string | null;
    startDate: number;
    endDate: number;
    type: ReportTypeEnum;
    isDraft: boolean;
    previousReports?: Array<string | null> | null;
    createdAt?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ReportByEntityIdQuery = {
  __typename: "ModelReportConnection";
  items: Array<{
    __typename: "Report";
    id: string;
    entityId: string;
    name: string;
    managerName?: string | null;
    startDate: number;
    endDate: number;
    type: ReportTypeEnum;
    isDraft: boolean;
    previousReports?: Array<string | null> | null;
    createdAt?: number | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetRiskQuery = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type ListRisksQuery = {
  __typename: "ModelRiskConnection";
  items: Array<{
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type RiskByEntityIdQuery = {
  __typename: "ModelRiskConnection";
  items: Array<{
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type RiskByAssessmentIdQuery = {
  __typename: "ModelRiskConnection";
  items: Array<{
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type GetRiskOwnerAssignmentQuery = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListRiskOwnerAssignmentsQuery = {
  __typename: "ModelRiskOwnerAssignmentConnection";
  items: Array<{
    __typename: "RiskOwnerAssignment";
    id: string;
    subEntityId: string;
    userId: string;
    riskId: string;
    risk?: {
      __typename: "Risk";
      id: string;
      idTitle: string;
      entityId?: string | null;
      entity?: {
        __typename: "Entity";
        id: string;
        name: string;
        users?: Array<string | null> | null;
        completionStatus?: CollectionStatusEnum | null;
        accessGroups: Array<string>;
        participantGroup: Array<string>;
        rootEntityId?: string | null;
        childEntityId?: string | null;
        frameworkId?: string | null;
        frameworkName?: string | null;
        frameworkType?: EntityTypeEnum | null;
        parentId?: string | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        entityType: EntityTypeEnum;
        projectManager?: string | null;
        projectManagerName?: string | null;
        projectDeadline?: number | null;
        logo?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        integrations?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        externalFeed?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        defaultSetting?: {
          __typename: "DefaultSetting";
          dueDateInDays?: number | null;
          frequency?: string | null;
          frequencyDays?: number | null;
          assessmentDays?: number | null;
          isAutoReassessment?: boolean | null;
          surveyLevel?: string | null;
          artifacts?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          standards?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customRequirements?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customCertifications?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          accessLevel?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          activeScan?: boolean | null;
          notifications?: string | null;
          selectedRisk?: string | null;
          selectedLevel?: string | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          domains?: string | null;
          otherData?: string | null;
          fourthPartyWeightage?: number | null;
          enbdDomain?: Array<string | null> | null;
        } | null;
        createdBy?: string | null;
        createdAt?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        vendorDetails?: {
          __typename: "VendorsDetail";
          id: string;
          impact?: number | null;
          externalScan?: number | null;
          financialRisk?: number | null;
          criticalGaps?: number | null;
          standardsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          status?: VendorStatusEnum | null;
          probability?: number | null;
          website?: string | null;
          location?: string | null;
          size?: string | null;
          description?: string | null;
          businessPointOfContactId?: string | null;
          accessLevel?: AccessLevelEnum | null;
          collectionStatus?: CollectionStatusEnum | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          approvalDate?: number | null;
          questionnaires?: Array<{
            __typename: "Questionnaire";
            tag?: string | null;
            criticalGaps?: Array<string | null> | null;
            allGaps?: string | null;
          } | null> | null;
          artifacts?: {
            __typename: "Artifacts";
          } | null;
          intelligence?: {
            __typename: "Intelligence";
            id: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          primaryPoc?: {
            __typename: "ShortRef";
            name?: string | null;
            id?: string | null;
            email?: string | null;
          } | null;
          stage?: VendorStatusEnum | null;
          activeScan?: boolean | null;
          domain?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          primaryLoss?: number | null;
          financialImpact?: Array<{
            __typename: "RiskFinancialImpact";
            type?: string | null;
            minimum?: number | null;
            mostLikely?: number | null;
            maximum?: number | null;
            confidence?: RiskConfidenceEnum | null;
            stepData?: string | null;
          } | null> | null;
          smartTags?: Array<string | null> | null;
          employees?: number | null;
          domains?: Array<string | null> | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          fourthPartyDomains?: string | null;
          level?: FrameworkLevelEnum | null;
          fourthPartyActiveScan?: boolean | null;
          intelligenceScanScore?: number | null;
          fourthPartyScanScore?: number | null;
          isThirdPartyScanCompleted?: boolean | null;
          priority?: ImpactEnum | null;
          identification?: ImpactEnum | null;
          materialityRating?: Array<string | null> | null;
          enbdCategory?: Array<string | null> | null;
          enbdDomain?: Array<string | null> | null;
          phoneNumber?: string | null;
          projectManagerPhoneNumber?: string | null;
          businessUnits?: string | null;
          category?: string | null;
          dealValue?: number | null;
          products?: string | null;
          ticketID?: string | null;
          tempUsersData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        industry?: string | null;
        domain: string;
        activeAssessmentId: string;
        tierSelected?: number | null;
        activeAssessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        scName?: string | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        KMS?: {
          __typename: "KMSData";
          status?: KMSStatus | null;
          version?: number | null;
          key?: string | null;
          alias?: string | null;
          dataKeyEncrypted?: string | null;
          newDataKeyEncrypted?: string | null;
          newKeyId?: string | null;
          rotations?: {
            __typename: "Rotation";
            count?: number | null;
            status?: KMSStatus | null;
            lastRotation?: number | null;
            firstRotationDate?: number | null;
          } | null;
          userId?: string | null;
        } | null;
        AI?: {
          __typename: "AIData";
          credits?: number | null;
          date?: number | null;
          isActive?: boolean | null;
          usedCredits?: number | null;
          userName?: string | null;
        } | null;
        upperdeckRoute?: string | null;
        isLocked?: boolean | null;
        eSignFinalized?: boolean | null;
        weightageScore?: number | null;
        upperdeckSetting?: string | null;
        domainScanned?: boolean | null;
        domainScanTimes?: string | null;
        domainScanEnabledForEntity?: boolean | null;
        solutionStatus?: {
          __typename: "SolutionStatusData";
          firstParty?: boolean | null;
          thirdParty?: boolean | null;
          boardView?: boolean | null;
        } | null;
        vendors?: {
          __typename: "VendorsData";
          vendorsTotalCount?: number | null;
          totalVendorsCreated?: number | null;
        } | null;
        frameworkSettings?: string | null;
        usedFrameworkUniqueCount?: number | null;
        spiderChartSetting?: string | null;
        isVendorInLimit?: boolean | null;
        updatedAt: string;
      } | null;
      subEntityId: string;
      subEntity?: {
        __typename: "Entity";
        id: string;
        name: string;
        users?: Array<string | null> | null;
        completionStatus?: CollectionStatusEnum | null;
        accessGroups: Array<string>;
        participantGroup: Array<string>;
        rootEntityId?: string | null;
        childEntityId?: string | null;
        frameworkId?: string | null;
        frameworkName?: string | null;
        frameworkType?: EntityTypeEnum | null;
        parentId?: string | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        entityType: EntityTypeEnum;
        projectManager?: string | null;
        projectManagerName?: string | null;
        projectDeadline?: number | null;
        logo?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        integrations?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        externalFeed?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        defaultSetting?: {
          __typename: "DefaultSetting";
          dueDateInDays?: number | null;
          frequency?: string | null;
          frequencyDays?: number | null;
          assessmentDays?: number | null;
          isAutoReassessment?: boolean | null;
          surveyLevel?: string | null;
          artifacts?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          standards?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customRequirements?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customCertifications?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          accessLevel?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          activeScan?: boolean | null;
          notifications?: string | null;
          selectedRisk?: string | null;
          selectedLevel?: string | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          domains?: string | null;
          otherData?: string | null;
          fourthPartyWeightage?: number | null;
          enbdDomain?: Array<string | null> | null;
        } | null;
        createdBy?: string | null;
        createdAt?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        vendorDetails?: {
          __typename: "VendorsDetail";
          id: string;
          impact?: number | null;
          externalScan?: number | null;
          financialRisk?: number | null;
          criticalGaps?: number | null;
          standardsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          status?: VendorStatusEnum | null;
          probability?: number | null;
          website?: string | null;
          location?: string | null;
          size?: string | null;
          description?: string | null;
          businessPointOfContactId?: string | null;
          accessLevel?: AccessLevelEnum | null;
          collectionStatus?: CollectionStatusEnum | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          approvalDate?: number | null;
          questionnaires?: Array<{
            __typename: "Questionnaire";
            tag?: string | null;
            criticalGaps?: Array<string | null> | null;
            allGaps?: string | null;
          } | null> | null;
          artifacts?: {
            __typename: "Artifacts";
          } | null;
          intelligence?: {
            __typename: "Intelligence";
            id: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          primaryPoc?: {
            __typename: "ShortRef";
            name?: string | null;
            id?: string | null;
            email?: string | null;
          } | null;
          stage?: VendorStatusEnum | null;
          activeScan?: boolean | null;
          domain?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          primaryLoss?: number | null;
          financialImpact?: Array<{
            __typename: "RiskFinancialImpact";
            type?: string | null;
            minimum?: number | null;
            mostLikely?: number | null;
            maximum?: number | null;
            confidence?: RiskConfidenceEnum | null;
            stepData?: string | null;
          } | null> | null;
          smartTags?: Array<string | null> | null;
          employees?: number | null;
          domains?: Array<string | null> | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          fourthPartyDomains?: string | null;
          level?: FrameworkLevelEnum | null;
          fourthPartyActiveScan?: boolean | null;
          intelligenceScanScore?: number | null;
          fourthPartyScanScore?: number | null;
          isThirdPartyScanCompleted?: boolean | null;
          priority?: ImpactEnum | null;
          identification?: ImpactEnum | null;
          materialityRating?: Array<string | null> | null;
          enbdCategory?: Array<string | null> | null;
          enbdDomain?: Array<string | null> | null;
          phoneNumber?: string | null;
          projectManagerPhoneNumber?: string | null;
          businessUnits?: string | null;
          category?: string | null;
          dealValue?: number | null;
          products?: string | null;
          ticketID?: string | null;
          tempUsersData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        industry?: string | null;
        domain: string;
        activeAssessmentId: string;
        tierSelected?: number | null;
        activeAssessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        scName?: string | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        KMS?: {
          __typename: "KMSData";
          status?: KMSStatus | null;
          version?: number | null;
          key?: string | null;
          alias?: string | null;
          dataKeyEncrypted?: string | null;
          newDataKeyEncrypted?: string | null;
          newKeyId?: string | null;
          rotations?: {
            __typename: "Rotation";
            count?: number | null;
            status?: KMSStatus | null;
            lastRotation?: number | null;
            firstRotationDate?: number | null;
          } | null;
          userId?: string | null;
        } | null;
        AI?: {
          __typename: "AIData";
          credits?: number | null;
          date?: number | null;
          isActive?: boolean | null;
          usedCredits?: number | null;
          userName?: string | null;
        } | null;
        upperdeckRoute?: string | null;
        isLocked?: boolean | null;
        eSignFinalized?: boolean | null;
        weightageScore?: number | null;
        upperdeckSetting?: string | null;
        domainScanned?: boolean | null;
        domainScanTimes?: string | null;
        domainScanEnabledForEntity?: boolean | null;
        solutionStatus?: {
          __typename: "SolutionStatusData";
          firstParty?: boolean | null;
          thirdParty?: boolean | null;
          boardView?: boolean | null;
        } | null;
        vendors?: {
          __typename: "VendorsData";
          vendorsTotalCount?: number | null;
          totalVendorsCreated?: number | null;
        } | null;
        frameworkSettings?: string | null;
        usedFrameworkUniqueCount?: number | null;
        spiderChartSetting?: string | null;
        isVendorInLimit?: boolean | null;
        updatedAt: string;
      } | null;
      assessmentId: string;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      type?: RiskTypeEnum | null;
      disable?: boolean | null;
      controlIds?: Array<string | null> | null;
      riskTitle: string;
      riskDescription?: string | null;
      riskCategory?: string | null;
      relatedAssets?: Array<string | null> | null;
      residualImpact?: RiskImpactEnum | null;
      residualProbability?: RiskProbabilityEnum | null;
      riskImpact: RiskImpactEnum;
      riskProbability: RiskProbabilityEnum;
      inherentRisk?: number | null;
      residualRisk?: number | null;
      riskStatus: RiskStatusEnum;
      riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
      notes?: string | null;
      costToRemediate?: number | null;
      costToRemediateType: RiskCostRemediationEnum;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      riskPriority: RiskPriorityEnum;
      hidden?: boolean | null;
      financialToggle?: boolean | null;
      scoreHistory?: Array<{
        __typename: "RiskQuestionScore";
        controlId?: string | null;
        questionOrder?: number | null;
        score?: number | null;
        remediationStatus?: string | null;
        impact?: boolean | null;
        probability?: boolean | null;
        weight?: number | null;
        left?: number | null;
        right?: number | null;
      } | null> | null;
      riskTags?: Array<string | null> | null;
      riskTasks?: Array<{
        __typename: "RiskTaskObject";
        title?: string | null;
        status?: TaskStatusEnum | null;
        id?: string | null;
      } | null> | null;
      riskControlNames?: Array<{
        __typename: "RiskManualControl";
        title?: string | null;
        id?: string | null;
        mitigationPlan?: string | null;
        label?: string | null;
      } | null> | null;
      createdAt?: number | null;
      updatedAt?: number | null;
      riskTasksIds?: Array<string | null> | null;
      isArchived?: boolean | null;
      isEscalated?: boolean | null;
      sensitivity?: number | null;
      effectiveness?: number | null;
      deadline?: number | null;
      riskOwnerIds?: Array<string | null> | null;
    } | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RiskAssignmentByUserIdQuery = {
  __typename: "ModelRiskOwnerAssignmentConnection";
  items: Array<{
    __typename: "RiskOwnerAssignment";
    id: string;
    subEntityId: string;
    userId: string;
    riskId: string;
    risk?: {
      __typename: "Risk";
      id: string;
      idTitle: string;
      entityId?: string | null;
      entity?: {
        __typename: "Entity";
        id: string;
        name: string;
        users?: Array<string | null> | null;
        completionStatus?: CollectionStatusEnum | null;
        accessGroups: Array<string>;
        participantGroup: Array<string>;
        rootEntityId?: string | null;
        childEntityId?: string | null;
        frameworkId?: string | null;
        frameworkName?: string | null;
        frameworkType?: EntityTypeEnum | null;
        parentId?: string | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        entityType: EntityTypeEnum;
        projectManager?: string | null;
        projectManagerName?: string | null;
        projectDeadline?: number | null;
        logo?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        integrations?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        externalFeed?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        defaultSetting?: {
          __typename: "DefaultSetting";
          dueDateInDays?: number | null;
          frequency?: string | null;
          frequencyDays?: number | null;
          assessmentDays?: number | null;
          isAutoReassessment?: boolean | null;
          surveyLevel?: string | null;
          artifacts?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          standards?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customRequirements?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customCertifications?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          accessLevel?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          activeScan?: boolean | null;
          notifications?: string | null;
          selectedRisk?: string | null;
          selectedLevel?: string | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          domains?: string | null;
          otherData?: string | null;
          fourthPartyWeightage?: number | null;
          enbdDomain?: Array<string | null> | null;
        } | null;
        createdBy?: string | null;
        createdAt?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        vendorDetails?: {
          __typename: "VendorsDetail";
          id: string;
          impact?: number | null;
          externalScan?: number | null;
          financialRisk?: number | null;
          criticalGaps?: number | null;
          standardsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          status?: VendorStatusEnum | null;
          probability?: number | null;
          website?: string | null;
          location?: string | null;
          size?: string | null;
          description?: string | null;
          businessPointOfContactId?: string | null;
          accessLevel?: AccessLevelEnum | null;
          collectionStatus?: CollectionStatusEnum | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          approvalDate?: number | null;
          questionnaires?: Array<{
            __typename: "Questionnaire";
            tag?: string | null;
            criticalGaps?: Array<string | null> | null;
            allGaps?: string | null;
          } | null> | null;
          artifacts?: {
            __typename: "Artifacts";
          } | null;
          intelligence?: {
            __typename: "Intelligence";
            id: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          primaryPoc?: {
            __typename: "ShortRef";
            name?: string | null;
            id?: string | null;
            email?: string | null;
          } | null;
          stage?: VendorStatusEnum | null;
          activeScan?: boolean | null;
          domain?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          primaryLoss?: number | null;
          financialImpact?: Array<{
            __typename: "RiskFinancialImpact";
            type?: string | null;
            minimum?: number | null;
            mostLikely?: number | null;
            maximum?: number | null;
            confidence?: RiskConfidenceEnum | null;
            stepData?: string | null;
          } | null> | null;
          smartTags?: Array<string | null> | null;
          employees?: number | null;
          domains?: Array<string | null> | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          fourthPartyDomains?: string | null;
          level?: FrameworkLevelEnum | null;
          fourthPartyActiveScan?: boolean | null;
          intelligenceScanScore?: number | null;
          fourthPartyScanScore?: number | null;
          isThirdPartyScanCompleted?: boolean | null;
          priority?: ImpactEnum | null;
          identification?: ImpactEnum | null;
          materialityRating?: Array<string | null> | null;
          enbdCategory?: Array<string | null> | null;
          enbdDomain?: Array<string | null> | null;
          phoneNumber?: string | null;
          projectManagerPhoneNumber?: string | null;
          businessUnits?: string | null;
          category?: string | null;
          dealValue?: number | null;
          products?: string | null;
          ticketID?: string | null;
          tempUsersData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        industry?: string | null;
        domain: string;
        activeAssessmentId: string;
        tierSelected?: number | null;
        activeAssessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        scName?: string | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        KMS?: {
          __typename: "KMSData";
          status?: KMSStatus | null;
          version?: number | null;
          key?: string | null;
          alias?: string | null;
          dataKeyEncrypted?: string | null;
          newDataKeyEncrypted?: string | null;
          newKeyId?: string | null;
          rotations?: {
            __typename: "Rotation";
            count?: number | null;
            status?: KMSStatus | null;
            lastRotation?: number | null;
            firstRotationDate?: number | null;
          } | null;
          userId?: string | null;
        } | null;
        AI?: {
          __typename: "AIData";
          credits?: number | null;
          date?: number | null;
          isActive?: boolean | null;
          usedCredits?: number | null;
          userName?: string | null;
        } | null;
        upperdeckRoute?: string | null;
        isLocked?: boolean | null;
        eSignFinalized?: boolean | null;
        weightageScore?: number | null;
        upperdeckSetting?: string | null;
        domainScanned?: boolean | null;
        domainScanTimes?: string | null;
        domainScanEnabledForEntity?: boolean | null;
        solutionStatus?: {
          __typename: "SolutionStatusData";
          firstParty?: boolean | null;
          thirdParty?: boolean | null;
          boardView?: boolean | null;
        } | null;
        vendors?: {
          __typename: "VendorsData";
          vendorsTotalCount?: number | null;
          totalVendorsCreated?: number | null;
        } | null;
        frameworkSettings?: string | null;
        usedFrameworkUniqueCount?: number | null;
        spiderChartSetting?: string | null;
        isVendorInLimit?: boolean | null;
        updatedAt: string;
      } | null;
      subEntityId: string;
      subEntity?: {
        __typename: "Entity";
        id: string;
        name: string;
        users?: Array<string | null> | null;
        completionStatus?: CollectionStatusEnum | null;
        accessGroups: Array<string>;
        participantGroup: Array<string>;
        rootEntityId?: string | null;
        childEntityId?: string | null;
        frameworkId?: string | null;
        frameworkName?: string | null;
        frameworkType?: EntityTypeEnum | null;
        parentId?: string | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        entityType: EntityTypeEnum;
        projectManager?: string | null;
        projectManagerName?: string | null;
        projectDeadline?: number | null;
        logo?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        integrations?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        externalFeed?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        defaultSetting?: {
          __typename: "DefaultSetting";
          dueDateInDays?: number | null;
          frequency?: string | null;
          frequencyDays?: number | null;
          assessmentDays?: number | null;
          isAutoReassessment?: boolean | null;
          surveyLevel?: string | null;
          artifacts?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          standards?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customRequirements?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customCertifications?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          accessLevel?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          activeScan?: boolean | null;
          notifications?: string | null;
          selectedRisk?: string | null;
          selectedLevel?: string | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          domains?: string | null;
          otherData?: string | null;
          fourthPartyWeightage?: number | null;
          enbdDomain?: Array<string | null> | null;
        } | null;
        createdBy?: string | null;
        createdAt?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        vendorDetails?: {
          __typename: "VendorsDetail";
          id: string;
          impact?: number | null;
          externalScan?: number | null;
          financialRisk?: number | null;
          criticalGaps?: number | null;
          standardsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          status?: VendorStatusEnum | null;
          probability?: number | null;
          website?: string | null;
          location?: string | null;
          size?: string | null;
          description?: string | null;
          businessPointOfContactId?: string | null;
          accessLevel?: AccessLevelEnum | null;
          collectionStatus?: CollectionStatusEnum | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          approvalDate?: number | null;
          questionnaires?: Array<{
            __typename: "Questionnaire";
            tag?: string | null;
            criticalGaps?: Array<string | null> | null;
            allGaps?: string | null;
          } | null> | null;
          artifacts?: {
            __typename: "Artifacts";
          } | null;
          intelligence?: {
            __typename: "Intelligence";
            id: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          primaryPoc?: {
            __typename: "ShortRef";
            name?: string | null;
            id?: string | null;
            email?: string | null;
          } | null;
          stage?: VendorStatusEnum | null;
          activeScan?: boolean | null;
          domain?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          primaryLoss?: number | null;
          financialImpact?: Array<{
            __typename: "RiskFinancialImpact";
            type?: string | null;
            minimum?: number | null;
            mostLikely?: number | null;
            maximum?: number | null;
            confidence?: RiskConfidenceEnum | null;
            stepData?: string | null;
          } | null> | null;
          smartTags?: Array<string | null> | null;
          employees?: number | null;
          domains?: Array<string | null> | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          fourthPartyDomains?: string | null;
          level?: FrameworkLevelEnum | null;
          fourthPartyActiveScan?: boolean | null;
          intelligenceScanScore?: number | null;
          fourthPartyScanScore?: number | null;
          isThirdPartyScanCompleted?: boolean | null;
          priority?: ImpactEnum | null;
          identification?: ImpactEnum | null;
          materialityRating?: Array<string | null> | null;
          enbdCategory?: Array<string | null> | null;
          enbdDomain?: Array<string | null> | null;
          phoneNumber?: string | null;
          projectManagerPhoneNumber?: string | null;
          businessUnits?: string | null;
          category?: string | null;
          dealValue?: number | null;
          products?: string | null;
          ticketID?: string | null;
          tempUsersData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        industry?: string | null;
        domain: string;
        activeAssessmentId: string;
        tierSelected?: number | null;
        activeAssessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        scName?: string | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        KMS?: {
          __typename: "KMSData";
          status?: KMSStatus | null;
          version?: number | null;
          key?: string | null;
          alias?: string | null;
          dataKeyEncrypted?: string | null;
          newDataKeyEncrypted?: string | null;
          newKeyId?: string | null;
          rotations?: {
            __typename: "Rotation";
            count?: number | null;
            status?: KMSStatus | null;
            lastRotation?: number | null;
            firstRotationDate?: number | null;
          } | null;
          userId?: string | null;
        } | null;
        AI?: {
          __typename: "AIData";
          credits?: number | null;
          date?: number | null;
          isActive?: boolean | null;
          usedCredits?: number | null;
          userName?: string | null;
        } | null;
        upperdeckRoute?: string | null;
        isLocked?: boolean | null;
        eSignFinalized?: boolean | null;
        weightageScore?: number | null;
        upperdeckSetting?: string | null;
        domainScanned?: boolean | null;
        domainScanTimes?: string | null;
        domainScanEnabledForEntity?: boolean | null;
        solutionStatus?: {
          __typename: "SolutionStatusData";
          firstParty?: boolean | null;
          thirdParty?: boolean | null;
          boardView?: boolean | null;
        } | null;
        vendors?: {
          __typename: "VendorsData";
          vendorsTotalCount?: number | null;
          totalVendorsCreated?: number | null;
        } | null;
        frameworkSettings?: string | null;
        usedFrameworkUniqueCount?: number | null;
        spiderChartSetting?: string | null;
        isVendorInLimit?: boolean | null;
        updatedAt: string;
      } | null;
      assessmentId: string;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      type?: RiskTypeEnum | null;
      disable?: boolean | null;
      controlIds?: Array<string | null> | null;
      riskTitle: string;
      riskDescription?: string | null;
      riskCategory?: string | null;
      relatedAssets?: Array<string | null> | null;
      residualImpact?: RiskImpactEnum | null;
      residualProbability?: RiskProbabilityEnum | null;
      riskImpact: RiskImpactEnum;
      riskProbability: RiskProbabilityEnum;
      inherentRisk?: number | null;
      residualRisk?: number | null;
      riskStatus: RiskStatusEnum;
      riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
      notes?: string | null;
      costToRemediate?: number | null;
      costToRemediateType: RiskCostRemediationEnum;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      riskPriority: RiskPriorityEnum;
      hidden?: boolean | null;
      financialToggle?: boolean | null;
      scoreHistory?: Array<{
        __typename: "RiskQuestionScore";
        controlId?: string | null;
        questionOrder?: number | null;
        score?: number | null;
        remediationStatus?: string | null;
        impact?: boolean | null;
        probability?: boolean | null;
        weight?: number | null;
        left?: number | null;
        right?: number | null;
      } | null> | null;
      riskTags?: Array<string | null> | null;
      riskTasks?: Array<{
        __typename: "RiskTaskObject";
        title?: string | null;
        status?: TaskStatusEnum | null;
        id?: string | null;
      } | null> | null;
      riskControlNames?: Array<{
        __typename: "RiskManualControl";
        title?: string | null;
        id?: string | null;
        mitigationPlan?: string | null;
        label?: string | null;
      } | null> | null;
      createdAt?: number | null;
      updatedAt?: number | null;
      riskTasksIds?: Array<string | null> | null;
      isArchived?: boolean | null;
      isEscalated?: boolean | null;
      sensitivity?: number | null;
      effectiveness?: number | null;
      deadline?: number | null;
      riskOwnerIds?: Array<string | null> | null;
    } | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RiskAssignmentByRiskIdQuery = {
  __typename: "ModelRiskOwnerAssignmentConnection";
  items: Array<{
    __typename: "RiskOwnerAssignment";
    id: string;
    subEntityId: string;
    userId: string;
    riskId: string;
    risk?: {
      __typename: "Risk";
      id: string;
      idTitle: string;
      entityId?: string | null;
      entity?: {
        __typename: "Entity";
        id: string;
        name: string;
        users?: Array<string | null> | null;
        completionStatus?: CollectionStatusEnum | null;
        accessGroups: Array<string>;
        participantGroup: Array<string>;
        rootEntityId?: string | null;
        childEntityId?: string | null;
        frameworkId?: string | null;
        frameworkName?: string | null;
        frameworkType?: EntityTypeEnum | null;
        parentId?: string | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        entityType: EntityTypeEnum;
        projectManager?: string | null;
        projectManagerName?: string | null;
        projectDeadline?: number | null;
        logo?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        integrations?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        externalFeed?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        defaultSetting?: {
          __typename: "DefaultSetting";
          dueDateInDays?: number | null;
          frequency?: string | null;
          frequencyDays?: number | null;
          assessmentDays?: number | null;
          isAutoReassessment?: boolean | null;
          surveyLevel?: string | null;
          artifacts?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          standards?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customRequirements?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customCertifications?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          accessLevel?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          activeScan?: boolean | null;
          notifications?: string | null;
          selectedRisk?: string | null;
          selectedLevel?: string | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          domains?: string | null;
          otherData?: string | null;
          fourthPartyWeightage?: number | null;
          enbdDomain?: Array<string | null> | null;
        } | null;
        createdBy?: string | null;
        createdAt?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        vendorDetails?: {
          __typename: "VendorsDetail";
          id: string;
          impact?: number | null;
          externalScan?: number | null;
          financialRisk?: number | null;
          criticalGaps?: number | null;
          standardsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          status?: VendorStatusEnum | null;
          probability?: number | null;
          website?: string | null;
          location?: string | null;
          size?: string | null;
          description?: string | null;
          businessPointOfContactId?: string | null;
          accessLevel?: AccessLevelEnum | null;
          collectionStatus?: CollectionStatusEnum | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          approvalDate?: number | null;
          questionnaires?: Array<{
            __typename: "Questionnaire";
            tag?: string | null;
            criticalGaps?: Array<string | null> | null;
            allGaps?: string | null;
          } | null> | null;
          artifacts?: {
            __typename: "Artifacts";
          } | null;
          intelligence?: {
            __typename: "Intelligence";
            id: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          primaryPoc?: {
            __typename: "ShortRef";
            name?: string | null;
            id?: string | null;
            email?: string | null;
          } | null;
          stage?: VendorStatusEnum | null;
          activeScan?: boolean | null;
          domain?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          primaryLoss?: number | null;
          financialImpact?: Array<{
            __typename: "RiskFinancialImpact";
            type?: string | null;
            minimum?: number | null;
            mostLikely?: number | null;
            maximum?: number | null;
            confidence?: RiskConfidenceEnum | null;
            stepData?: string | null;
          } | null> | null;
          smartTags?: Array<string | null> | null;
          employees?: number | null;
          domains?: Array<string | null> | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          fourthPartyDomains?: string | null;
          level?: FrameworkLevelEnum | null;
          fourthPartyActiveScan?: boolean | null;
          intelligenceScanScore?: number | null;
          fourthPartyScanScore?: number | null;
          isThirdPartyScanCompleted?: boolean | null;
          priority?: ImpactEnum | null;
          identification?: ImpactEnum | null;
          materialityRating?: Array<string | null> | null;
          enbdCategory?: Array<string | null> | null;
          enbdDomain?: Array<string | null> | null;
          phoneNumber?: string | null;
          projectManagerPhoneNumber?: string | null;
          businessUnits?: string | null;
          category?: string | null;
          dealValue?: number | null;
          products?: string | null;
          ticketID?: string | null;
          tempUsersData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        industry?: string | null;
        domain: string;
        activeAssessmentId: string;
        tierSelected?: number | null;
        activeAssessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        scName?: string | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        KMS?: {
          __typename: "KMSData";
          status?: KMSStatus | null;
          version?: number | null;
          key?: string | null;
          alias?: string | null;
          dataKeyEncrypted?: string | null;
          newDataKeyEncrypted?: string | null;
          newKeyId?: string | null;
          rotations?: {
            __typename: "Rotation";
            count?: number | null;
            status?: KMSStatus | null;
            lastRotation?: number | null;
            firstRotationDate?: number | null;
          } | null;
          userId?: string | null;
        } | null;
        AI?: {
          __typename: "AIData";
          credits?: number | null;
          date?: number | null;
          isActive?: boolean | null;
          usedCredits?: number | null;
          userName?: string | null;
        } | null;
        upperdeckRoute?: string | null;
        isLocked?: boolean | null;
        eSignFinalized?: boolean | null;
        weightageScore?: number | null;
        upperdeckSetting?: string | null;
        domainScanned?: boolean | null;
        domainScanTimes?: string | null;
        domainScanEnabledForEntity?: boolean | null;
        solutionStatus?: {
          __typename: "SolutionStatusData";
          firstParty?: boolean | null;
          thirdParty?: boolean | null;
          boardView?: boolean | null;
        } | null;
        vendors?: {
          __typename: "VendorsData";
          vendorsTotalCount?: number | null;
          totalVendorsCreated?: number | null;
        } | null;
        frameworkSettings?: string | null;
        usedFrameworkUniqueCount?: number | null;
        spiderChartSetting?: string | null;
        isVendorInLimit?: boolean | null;
        updatedAt: string;
      } | null;
      subEntityId: string;
      subEntity?: {
        __typename: "Entity";
        id: string;
        name: string;
        users?: Array<string | null> | null;
        completionStatus?: CollectionStatusEnum | null;
        accessGroups: Array<string>;
        participantGroup: Array<string>;
        rootEntityId?: string | null;
        childEntityId?: string | null;
        frameworkId?: string | null;
        frameworkName?: string | null;
        frameworkType?: EntityTypeEnum | null;
        parentId?: string | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        entityType: EntityTypeEnum;
        projectManager?: string | null;
        projectManagerName?: string | null;
        projectDeadline?: number | null;
        logo?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        integrations?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        externalFeed?: Array<{
          __typename: "Integrations";
          name: string;
          credentials?: string | null;
          status?: StatusEnum | null;
          isEnable?: boolean | null;
          isAuth?: boolean | null;
          inheritFromParent?: boolean | null;
          webhookUpdatedAt?: number | null;
        } | null> | null;
        defaultSetting?: {
          __typename: "DefaultSetting";
          dueDateInDays?: number | null;
          frequency?: string | null;
          frequencyDays?: number | null;
          assessmentDays?: number | null;
          isAutoReassessment?: boolean | null;
          surveyLevel?: string | null;
          artifacts?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          standards?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customRequirements?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          customCertifications?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          accessLevel?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          activeScan?: boolean | null;
          notifications?: string | null;
          selectedRisk?: string | null;
          selectedLevel?: string | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          domains?: string | null;
          otherData?: string | null;
          fourthPartyWeightage?: number | null;
          enbdDomain?: Array<string | null> | null;
        } | null;
        createdBy?: string | null;
        createdAt?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        vendorDetails?: {
          __typename: "VendorsDetail";
          id: string;
          impact?: number | null;
          externalScan?: number | null;
          financialRisk?: number | null;
          criticalGaps?: number | null;
          standardsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          status?: VendorStatusEnum | null;
          probability?: number | null;
          website?: string | null;
          location?: string | null;
          size?: string | null;
          description?: string | null;
          businessPointOfContactId?: string | null;
          accessLevel?: AccessLevelEnum | null;
          collectionStatus?: CollectionStatusEnum | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          approvalDate?: number | null;
          questionnaires?: Array<{
            __typename: "Questionnaire";
            tag?: string | null;
            criticalGaps?: Array<string | null> | null;
            allGaps?: string | null;
          } | null> | null;
          artifacts?: {
            __typename: "Artifacts";
          } | null;
          intelligence?: {
            __typename: "Intelligence";
            id: string;
            createdAt: string;
            updatedAt: string;
          } | null;
          primaryPoc?: {
            __typename: "ShortRef";
            name?: string | null;
            id?: string | null;
            email?: string | null;
          } | null;
          stage?: VendorStatusEnum | null;
          activeScan?: boolean | null;
          domain?: string | null;
          privacyData?: boolean | null;
          impactsQualify?: Array<{
            __typename: "Qualification";
            name?: string | null;
            isQualify?: boolean | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            level?: string | null;
          } | null> | null;
          primaryLoss?: number | null;
          financialImpact?: Array<{
            __typename: "RiskFinancialImpact";
            type?: string | null;
            minimum?: number | null;
            mostLikely?: number | null;
            maximum?: number | null;
            confidence?: RiskConfidenceEnum | null;
            stepData?: string | null;
          } | null> | null;
          smartTags?: Array<string | null> | null;
          employees?: number | null;
          domains?: Array<string | null> | null;
          isThirdParty?: boolean | null;
          isFourthParty?: boolean | null;
          fourthPartyDomains?: string | null;
          level?: FrameworkLevelEnum | null;
          fourthPartyActiveScan?: boolean | null;
          intelligenceScanScore?: number | null;
          fourthPartyScanScore?: number | null;
          isThirdPartyScanCompleted?: boolean | null;
          priority?: ImpactEnum | null;
          identification?: ImpactEnum | null;
          materialityRating?: Array<string | null> | null;
          enbdCategory?: Array<string | null> | null;
          enbdDomain?: Array<string | null> | null;
          phoneNumber?: string | null;
          projectManagerPhoneNumber?: string | null;
          businessUnits?: string | null;
          category?: string | null;
          dealValue?: number | null;
          products?: string | null;
          ticketID?: string | null;
          tempUsersData?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        industry?: string | null;
        domain: string;
        activeAssessmentId: string;
        tierSelected?: number | null;
        activeAssessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        scName?: string | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        KMS?: {
          __typename: "KMSData";
          status?: KMSStatus | null;
          version?: number | null;
          key?: string | null;
          alias?: string | null;
          dataKeyEncrypted?: string | null;
          newDataKeyEncrypted?: string | null;
          newKeyId?: string | null;
          rotations?: {
            __typename: "Rotation";
            count?: number | null;
            status?: KMSStatus | null;
            lastRotation?: number | null;
            firstRotationDate?: number | null;
          } | null;
          userId?: string | null;
        } | null;
        AI?: {
          __typename: "AIData";
          credits?: number | null;
          date?: number | null;
          isActive?: boolean | null;
          usedCredits?: number | null;
          userName?: string | null;
        } | null;
        upperdeckRoute?: string | null;
        isLocked?: boolean | null;
        eSignFinalized?: boolean | null;
        weightageScore?: number | null;
        upperdeckSetting?: string | null;
        domainScanned?: boolean | null;
        domainScanTimes?: string | null;
        domainScanEnabledForEntity?: boolean | null;
        solutionStatus?: {
          __typename: "SolutionStatusData";
          firstParty?: boolean | null;
          thirdParty?: boolean | null;
          boardView?: boolean | null;
        } | null;
        vendors?: {
          __typename: "VendorsData";
          vendorsTotalCount?: number | null;
          totalVendorsCreated?: number | null;
        } | null;
        frameworkSettings?: string | null;
        usedFrameworkUniqueCount?: number | null;
        spiderChartSetting?: string | null;
        isVendorInLimit?: boolean | null;
        updatedAt: string;
      } | null;
      assessmentId: string;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      type?: RiskTypeEnum | null;
      disable?: boolean | null;
      controlIds?: Array<string | null> | null;
      riskTitle: string;
      riskDescription?: string | null;
      riskCategory?: string | null;
      relatedAssets?: Array<string | null> | null;
      residualImpact?: RiskImpactEnum | null;
      residualProbability?: RiskProbabilityEnum | null;
      riskImpact: RiskImpactEnum;
      riskProbability: RiskProbabilityEnum;
      inherentRisk?: number | null;
      residualRisk?: number | null;
      riskStatus: RiskStatusEnum;
      riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
      notes?: string | null;
      costToRemediate?: number | null;
      costToRemediateType: RiskCostRemediationEnum;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      riskPriority: RiskPriorityEnum;
      hidden?: boolean | null;
      financialToggle?: boolean | null;
      scoreHistory?: Array<{
        __typename: "RiskQuestionScore";
        controlId?: string | null;
        questionOrder?: number | null;
        score?: number | null;
        remediationStatus?: string | null;
        impact?: boolean | null;
        probability?: boolean | null;
        weight?: number | null;
        left?: number | null;
        right?: number | null;
      } | null> | null;
      riskTags?: Array<string | null> | null;
      riskTasks?: Array<{
        __typename: "RiskTaskObject";
        title?: string | null;
        status?: TaskStatusEnum | null;
        id?: string | null;
      } | null> | null;
      riskControlNames?: Array<{
        __typename: "RiskManualControl";
        title?: string | null;
        id?: string | null;
        mitigationPlan?: string | null;
        label?: string | null;
      } | null> | null;
      createdAt?: number | null;
      updatedAt?: number | null;
      riskTasksIds?: Array<string | null> | null;
      isArchived?: boolean | null;
      isEscalated?: boolean | null;
      sensitivity?: number | null;
      effectiveness?: number | null;
      deadline?: number | null;
      riskOwnerIds?: Array<string | null> | null;
    } | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSavedFilterQuery = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSavedFiltersQuery = {
  __typename: "ModelSavedFilterConnection";
  items: Array<{
    __typename: "SavedFilter";
    id: string;
    filterObject?: string | null;
    userId: string;
    entityId: string;
    type?: FilterTypeEnum | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SavedFiltersByUserIdQuery = {
  __typename: "ModelSavedFilterConnection";
  items: Array<{
    __typename: "SavedFilter";
    id: string;
    filterObject?: string | null;
    userId: string;
    entityId: string;
    type?: FilterTypeEnum | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SavedFiltersByEntityIdQuery = {
  __typename: "ModelSavedFilterConnection";
  items: Array<{
    __typename: "SavedFilter";
    id: string;
    filterObject?: string | null;
    userId: string;
    entityId: string;
    type?: FilterTypeEnum | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SavedFiltersByEntityIdAndUserIdQuery = {
  __typename: "ModelSavedFilterConnection";
  items: Array<{
    __typename: "SavedFilter";
    id: string;
    filterObject?: string | null;
    userId: string;
    entityId: string;
    type?: FilterTypeEnum | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDomainSettingsQuery = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type ListDomainSettingsQuery = {
  __typename: "ModelDomainSettingsConnection";
  items: Array<{
    __typename: "DomainSettings";
    id: string;
    domain: string;
    key?: string | null;
    average?: number | null;
    allowedRoutes?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    allowedFeatures?: string | null;
    settings?: string | null;
    kmsStatus?: boolean | null;
  } | null>;
  nextToken?: string | null;
};

export type DomainSettingByDomainQuery = {
  __typename: "ModelDomainSettingsConnection";
  items: Array<{
    __typename: "DomainSettings";
    id: string;
    domain: string;
    key?: string | null;
    average?: number | null;
    allowedRoutes?: Array<string> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    allowedFeatures?: string | null;
    settings?: string | null;
    kmsStatus?: boolean | null;
  } | null>;
  nextToken?: string | null;
};

export type GetExecutiveSummaryQuery = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListExecutiveSummariesQuery = {
  __typename: "ModelExecutiveSummaryConnection";
  items: Array<{
    __typename: "ExecutiveSummary";
    id: string;
    entityId: string;
    title: string;
    subjectList?: Array<{
      __typename: "SummarySubject";
      id: string;
      title: string;
      description: string;
      toggle: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type SummaryByEntityIdQuery = {
  __typename: "ModelExecutiveSummaryConnection";
  items: Array<{
    __typename: "ExecutiveSummary";
    id: string;
    entityId: string;
    title: string;
    subjectList?: Array<{
      __typename: "SummarySubject";
      id: string;
      title: string;
      description: string;
      toggle: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
    } | null> | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetNetskopeProjectQuery = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListNetskopeProjectsQuery = {
  __typename: "ModelNetskopeProjectConnection";
  items: Array<{
    __typename: "NetskopeProject";
    id: string;
    entityId: string;
    title: string;
    description?: string | null;
    previous?: ProgressStatusEnum | null;
    currentProgress?: {
      __typename: "CurrentProgress";
      startDate: number;
      endDate: number;
      status: string;
    } | null;
    budget?: number | null;
    comments?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ProjectsByEntityIdQuery = {
  __typename: "ModelNetskopeProjectConnection";
  items: Array<{
    __typename: "NetskopeProject";
    id: string;
    entityId: string;
    title: string;
    description?: string | null;
    previous?: ProgressStatusEnum | null;
    currentProgress?: {
      __typename: "CurrentProgress";
      startDate: number;
      endDate: number;
      status: string;
    } | null;
    budget?: number | null;
    comments?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEntityLayersQuery = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type ListEntityLayersQuery = {
  __typename: "ModelEntityLayersConnection";
  items: Array<{
    __typename: "EntityLayers";
    id: string;
    name: string;
    managerName: string;
    type: BnbLayerEnum;
    score: {
      __typename: "BnbEntityScore";
      total: number;
      collection: number;
      remediation: number;
    };
    inherent: string;
    residual: string;
    parentId?: string | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    srName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    domain?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EntityLayersByParentIdQuery = {
  __typename: "ModelEntityLayersConnection";
  items: Array<{
    __typename: "EntityLayers";
    id: string;
    name: string;
    managerName: string;
    type: BnbLayerEnum;
    score: {
      __typename: "BnbEntityScore";
      total: number;
      collection: number;
      remediation: number;
    };
    inherent: string;
    residual: string;
    parentId?: string | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    srName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    domain?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEntityWizardQuery = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type ListEntityWizardsQuery = {
  __typename: "ModelEntityWizardConnection";
  items: Array<{
    __typename: "EntityWizard";
    id: string;
    isDraft?: boolean | null;
    organizational?: string | null;
    financial?: string | null;
    frameworks?: Array<string | null> | null;
    dataScope?: string | null;
    technical?: string | null;
    riskScenarios?: string | null;
    accountDetails?: string | null;
    currentYear?: string | null;
    rootEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    parentId?: string | null;
    score: {
      __typename: "BnbEntityScore";
      total: number;
      collection: number;
      remediation: number;
    };
    inherent: string;
    residual: string;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    riskFramework?: string | null;
    clientDomain: string;
    userId?: string | null;
    applicantInfo?: string | null;
    revenueDetails?: string | null;
    dataInventory?: string | null;
    updatedBy?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EntityWizardByParentIdQuery = {
  __typename: "ModelEntityWizardConnection";
  items: Array<{
    __typename: "EntityWizard";
    id: string;
    isDraft?: boolean | null;
    organizational?: string | null;
    financial?: string | null;
    frameworks?: Array<string | null> | null;
    dataScope?: string | null;
    technical?: string | null;
    riskScenarios?: string | null;
    accountDetails?: string | null;
    currentYear?: string | null;
    rootEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    parentId?: string | null;
    score: {
      __typename: "BnbEntityScore";
      total: number;
      collection: number;
      remediation: number;
    };
    inherent: string;
    residual: string;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    riskFramework?: string | null;
    clientDomain: string;
    userId?: string | null;
    applicantInfo?: string | null;
    revenueDetails?: string | null;
    dataInventory?: string | null;
    updatedBy?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type EntityWizardByParentAndDomainQuery = {
  __typename: "ModelEntityWizardConnection";
  items: Array<{
    __typename: "EntityWizard";
    id: string;
    isDraft?: boolean | null;
    organizational?: string | null;
    financial?: string | null;
    frameworks?: Array<string | null> | null;
    dataScope?: string | null;
    technical?: string | null;
    riskScenarios?: string | null;
    accountDetails?: string | null;
    currentYear?: string | null;
    rootEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    parentId?: string | null;
    score: {
      __typename: "BnbEntityScore";
      total: number;
      collection: number;
      remediation: number;
    };
    inherent: string;
    residual: string;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    riskFramework?: string | null;
    clientDomain: string;
    userId?: string | null;
    applicantInfo?: string | null;
    revenueDetails?: string | null;
    dataInventory?: string | null;
    updatedBy?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetLogsQuery = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListLogsQuery = {
  __typename: "ModelLogsConnection";
  items: Array<{
    __typename: "Logs";
    id: string;
    message: string;
    type: string;
    targetId: string;
    assessmentId: string;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type LogsByTargetIdQuery = {
  __typename: "ModelLogsConnection";
  items: Array<{
    __typename: "Logs";
    id: string;
    message: string;
    type: string;
    targetId: string;
    assessmentId: string;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type LogsByTargetIdAndTypeQuery = {
  __typename: "ModelLogsConnection";
  items: Array<{
    __typename: "Logs";
    id: string;
    message: string;
    type: string;
    targetId: string;
    assessmentId: string;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type LogsByAssessmentIdQuery = {
  __typename: "ModelLogsConnection";
  items: Array<{
    __typename: "Logs";
    id: string;
    message: string;
    type: string;
    targetId: string;
    assessmentId: string;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type LogsByAssessmentIdAndTypeQuery = {
  __typename: "ModelLogsConnection";
  items: Array<{
    __typename: "Logs";
    id: string;
    message: string;
    type: string;
    targetId: string;
    assessmentId: string;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type LogsByUserIdQuery = {
  __typename: "ModelLogsConnection";
  items: Array<{
    __typename: "Logs";
    id: string;
    message: string;
    type: string;
    targetId: string;
    assessmentId: string;
    userID?: string | null;
    user?: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetVersionsQuery = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type ListVersionsQuery = {
  __typename: "ModelVersionsConnection";
  items: Array<{
    __typename: "Versions";
    id: string;
    data: string;
    type: string;
    targetId: string;
    assessmentId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VersionsByTargetIdQuery = {
  __typename: "ModelVersionsConnection";
  items: Array<{
    __typename: "Versions";
    id: string;
    data: string;
    type: string;
    targetId: string;
    assessmentId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VersionsByTargetIdAndTypeQuery = {
  __typename: "ModelVersionsConnection";
  items: Array<{
    __typename: "Versions";
    id: string;
    data: string;
    type: string;
    targetId: string;
    assessmentId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VersionsByAssessmentIdQuery = {
  __typename: "ModelVersionsConnection";
  items: Array<{
    __typename: "Versions";
    id: string;
    data: string;
    type: string;
    targetId: string;
    assessmentId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type VersionsByAssessmentIdAndTypeQuery = {
  __typename: "ModelVersionsConnection";
  items: Array<{
    __typename: "Versions";
    id: string;
    data: string;
    type: string;
    targetId: string;
    assessmentId: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetDataStateQuery = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type ListDataStatesQuery = {
  __typename: "ModelDataStateConnection";
  items: Array<{
    __typename: "DataState";
    id: string;
    state: StateStatusEnum;
    parentId: string;
    message?: string | null;
    detail?: string | null;
    type?: StateTypeEnum | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type DataStatesByParentIdQuery = {
  __typename: "ModelDataStateConnection";
  items: Array<{
    __typename: "DataState";
    id: string;
    state: StateStatusEnum;
    parentId: string;
    message?: string | null;
    detail?: string | null;
    type?: StateTypeEnum | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetSmartMappingDetailQuery = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListSmartMappingDetailsQuery = {
  __typename: "ModelSmartMappingDetailConnection";
  items: Array<{
    __typename: "SmartMappingDetail";
    id: string;
    uniqueId: string;
    metadata: string;
    answer?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type MetadataBySmartMapIdQuery = {
  __typename: "ModelSmartMappingDetailConnection";
  items: Array<{
    __typename: "SmartMappingDetail";
    id: string;
    uniqueId: string;
    metadata: string;
    answer?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetApiCallRegistryQuery = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type ListApiCallRegistriesQuery = {
  __typename: "ModelApiCallRegistryConnection";
  items: Array<{
    __typename: "ApiCallRegistry";
    id: string;
    callMaker: string;
    createdAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type RecordByCallMakerQuery = {
  __typename: "ModelApiCallRegistryConnection";
  items: Array<{
    __typename: "ApiCallRegistry";
    id: string;
    callMaker: string;
    createdAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetBreachDataQuery = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type ListBreachDataQuery = {
  __typename: "ModelBreachDataConnection";
  items: Array<{
    __typename: "BreachData";
    id: string;
    domain: string;
    breaches?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type BreachesByDomainQuery = {
  __typename: "ModelBreachDataConnection";
  items: Array<{
    __typename: "BreachData";
    id: string;
    domain: string;
    breaches?: string | null;
    createdAt?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnUpdateEntityByRootIdSubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  isGlobalParticipant?: boolean | null;
  onBoardingStatus?: MidMarketEnum | null;
  updatedAt: string;
};

export type OnCreateTagsSubscription = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTagsSubscription = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTagsSubscription = {
  __typename: "Tags";
  id: string;
  name: string;
  entityId?: string | null;
  domain?: string | null;
  targetId?: string | null;
  assessmentFrameworkKey?: string | null;
  referenceId?: string | null;
  questionRefId?: string | null;
  questionData?: {
    __typename: "QuestionData";
    id: string;
    childId?: string | null;
    frameworkKey?: string | null;
    function?: string | null;
    category?: string | null;
    subCategory?: string | null;
    controlNameLabel?: string | null;
    score?: number | null;
    rootId?: string | null;
    assessmentId?: string | null;
    name?: string | null;
    left?: number | null;
    right?: number | null;
    reviewStatus?: ReviewerActions | null;
    resentBackStatus?: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateRoleSubscription = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type OnUpdateRoleSubscription = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type OnDeleteRoleSubscription = {
  __typename: "Role";
  id: string;
  name: string;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  vendorIds?: Array<string | null> | null;
  isRootEntityAccess?: boolean | null;
  defaultOrEntityId: string;
  screenPermissions?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentId?: string | null;
};

export type OnCreateRiskLineChartSubscription = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateRiskLineChartSubscription = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteRiskLineChartSubscription = {
  __typename: "RiskLineChart";
  id: string;
  assessmentId: string;
  riskId: string;
  residual?: RiskImpactEnum | null;
  inherent?: RiskImpactEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateGroupSubscription = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateGroupSubscription = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteGroupSubscription = {
  __typename: "Group";
  id: string;
  name: string;
  rootId?: string | null;
  tags?: Array<string | null> | null;
  score?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateQuestionDataSubscription = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateQuestionDataSubscription = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteQuestionDataSubscription = {
  __typename: "QuestionData";
  id: string;
  childId?: string | null;
  frameworkKey?: string | null;
  function?: string | null;
  category?: string | null;
  subCategory?: string | null;
  controlNameLabel?: string | null;
  score?: number | null;
  rootId?: string | null;
  assessmentId?: string | null;
  name?: string | null;
  left?: number | null;
  right?: number | null;
  reviewStatus?: ReviewerActions | null;
  resentBackStatus?: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateArchivedUserSubscription = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type OnUpdateArchivedUserSubscription = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type OnDeleteArchivedUserSubscription = {
  __typename: "ArchivedUser";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  updatedAt: string;
};

export type OnCreateEntitySubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type OnUpdateEntitySubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type OnDeleteEntitySubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  integrations?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  externalFeed?: Array<{
    __typename: "Integrations";
    name: string;
    credentials?: string | null;
    status?: StatusEnum | null;
    isEnable?: boolean | null;
    isAuth?: boolean | null;
    inheritFromParent?: boolean | null;
    webhookUpdatedAt?: number | null;
  } | null> | null;
  defaultSetting?: {
    __typename: "DefaultSetting";
    dueDateInDays?: number | null;
    frequency?: string | null;
    frequencyDays?: number | null;
    assessmentDays?: number | null;
    isAutoReassessment?: boolean | null;
    surveyLevel?: string | null;
    artifacts?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    standards?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customRequirements?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    customCertifications?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    accessLevel?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    activeScan?: boolean | null;
    notifications?: string | null;
    selectedRisk?: string | null;
    selectedLevel?: string | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    domains?: string | null;
    otherData?: string | null;
    fourthPartyWeightage?: number | null;
    enbdDomain?: Array<string | null> | null;
  } | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  vendorDetails?: {
    __typename: "VendorsDetail";
    id: string;
    impact?: number | null;
    externalScan?: number | null;
    financialRisk?: number | null;
    criticalGaps?: number | null;
    standardsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    status?: VendorStatusEnum | null;
    probability?: number | null;
    website?: string | null;
    location?: string | null;
    size?: string | null;
    description?: string | null;
    businessPointOfContactId?: string | null;
    accessLevel?: AccessLevelEnum | null;
    collectionStatus?: CollectionStatusEnum | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    approvalDate?: number | null;
    questionnaires?: Array<{
      __typename: "Questionnaire";
      tag?: string | null;
      status?: {
        __typename: "QuestionnaireStatus";
        totalQuestions?: number | null;
        completedQuestions?: number | null;
      } | null;
      criticalGaps?: Array<string | null> | null;
      allGaps?: string | null;
    } | null> | null;
    artifacts?: {
      __typename: "Artifacts";
      documents?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      scans?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      insurances?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
      certifications?: Array<{
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null> | null;
    } | null;
    intelligence?: {
      __typename: "Intelligence";
      id: string;
      breaches?: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date?: number | null;
          year?: number | null;
          name?: string | null;
          description?: string | null;
          leaks?: number | null;
          data?: Array<string | null> | null;
          status?: string | null;
          email?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      externalThreats?: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source?: string | null;
          findings?: Array<string | null> | null;
          riskScore?: string | null;
          details?: Array<{
            __typename: "ThreatDetails";
            ip?: string | null;
            port?: number | null;
            cve?: string | null;
            cvss?: number | null;
          } | null> | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      others?: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name?: string | null;
          label?: string | null;
          severity?: string | null;
          description?: string | null;
          status?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    primaryPoc?: {
      __typename: "ShortRef";
      name?: string | null;
      id?: string | null;
      email?: string | null;
    } | null;
    stage?: VendorStatusEnum | null;
    activeScan?: boolean | null;
    domain?: string | null;
    privacyData?: boolean | null;
    impactsQualify?: Array<{
      __typename: "Qualification";
      name?: string | null;
      isQualify?: boolean | null;
      templateURL?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      level?: string | null;
    } | null> | null;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    smartTags?: Array<string | null> | null;
    employees?: number | null;
    domains?: Array<string | null> | null;
    isThirdParty?: boolean | null;
    isFourthParty?: boolean | null;
    fourthPartyDomains?: string | null;
    level?: FrameworkLevelEnum | null;
    fourthPartyActiveScan?: boolean | null;
    intelligenceScanScore?: number | null;
    fourthPartyScanScore?: number | null;
    isThirdPartyScanCompleted?: boolean | null;
    priority?: ImpactEnum | null;
    identification?: ImpactEnum | null;
    materialityRating?: Array<string | null> | null;
    enbdCategory?: Array<string | null> | null;
    enbdDomain?: Array<string | null> | null;
    phoneNumber?: string | null;
    projectManagerPhoneNumber?: string | null;
    businessUnits?: string | null;
    category?: string | null;
    dealValue?: number | null;
    products?: string | null;
    ticketID?: string | null;
    tempUsersData?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  industry?: string | null;
  domain: string;
  activeAssessmentId: string;
  tierSelected?: number | null;
  activeAssessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  scName?: string | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  KMS?: {
    __typename: "KMSData";
    status?: KMSStatus | null;
    version?: number | null;
    key?: string | null;
    alias?: string | null;
    dataKeyEncrypted?: string | null;
    newDataKeyEncrypted?: string | null;
    newKeyId?: string | null;
    rotations?: {
      __typename: "Rotation";
      count?: number | null;
      status?: KMSStatus | null;
      lastRotation?: number | null;
      firstRotationDate?: number | null;
    } | null;
    userId?: string | null;
  } | null;
  AI?: {
    __typename: "AIData";
    credits?: number | null;
    date?: number | null;
    isActive?: boolean | null;
    usedCredits?: number | null;
    userName?: string | null;
  } | null;
  upperdeckRoute?: string | null;
  isLocked?: boolean | null;
  eSignFinalized?: boolean | null;
  weightageScore?: number | null;
  upperdeckSetting?: string | null;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  domainScanEnabledForEntity?: boolean | null;
  solutionStatus?: {
    __typename: "SolutionStatusData";
    firstParty?: boolean | null;
    thirdParty?: boolean | null;
    boardView?: boolean | null;
  } | null;
  vendors?: {
    __typename: "VendorsData";
    vendorsTotalCount?: number | null;
    totalVendorsCreated?: number | null;
  } | null;
  frameworkSettings?: string | null;
  usedFrameworkUniqueCount?: number | null;
  spiderChartSetting?: string | null;
  isVendorInLimit?: boolean | null;
  updatedAt: string;
};

export type OnCreateGraphSubscription = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnUpdateGraphSubscription = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnDeleteGraphSubscription = {
  __typename: "Graph";
  id: string;
  name: string;
  type: string;
  rootId?: string | null;
  status?: string | null;
  entities?: Array<string | null> | null;
  domainOrFunctions?: {
    __typename: "EntityData";
    type?: string | null;
    data?: Array<string | null> | null;
  } | null;
  groups?: Array<{
    __typename: "GroupGraphInput";
    id?: string | null;
    name?: string | null;
    tags?: Array<string | null> | null;
  } | null> | null;
  tags?: Array<string | null> | null;
  createdBy?: string | null;
  timeSpan?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnCreateRevisionSubscription = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRevisionSubscription = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRevisionSubscription = {
  __typename: "Revision";
  id: string;
  frameworkKey?: string | null;
  assessmentId?: string | null;
  groupId?: string | null;
  date?: string | null;
  rootId?: string | null;
  tagId?: string | null;
  scores?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVendorsDetailSubscription = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVendorsDetailSubscription = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVendorsDetailSubscription = {
  __typename: "VendorsDetail";
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  description?: string | null;
  businessPointOfContactId?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  approvalDate?: number | null;
  questionnaires?: Array<{
    __typename: "Questionnaire";
    tag?: string | null;
    status?: {
      __typename: "QuestionnaireStatus";
      totalQuestions?: number | null;
      completedQuestions?: number | null;
    } | null;
    criticalGaps?: Array<string | null> | null;
    allGaps?: string | null;
  } | null> | null;
  artifacts?: {
    __typename: "Artifacts";
    documents?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    scans?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    insurances?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
    certifications?: Array<{
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
  intelligence?: {
    __typename: "Intelligence";
    id: string;
    breaches?: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date?: number | null;
        year?: number | null;
        name?: string | null;
        description?: string | null;
        leaks?: number | null;
        data?: Array<string | null> | null;
        status?: string | null;
        email?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    externalThreats?: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source?: string | null;
        findings?: Array<string | null> | null;
        riskScore?: string | null;
        details?: Array<{
          __typename: "ThreatDetails";
          ip?: string | null;
          port?: number | null;
          cve?: string | null;
          cvss?: number | null;
        } | null> | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    others?: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name?: string | null;
        label?: string | null;
        severity?: string | null;
        description?: string | null;
        status?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  primaryPoc?: {
    __typename: "ShortRef";
    name?: string | null;
    id?: string | null;
    email?: string | null;
  } | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  smartTags?: Array<string | null> | null;
  employees?: number | null;
  domains?: Array<string | null> | null;
  isThirdParty?: boolean | null;
  isFourthParty?: boolean | null;
  fourthPartyDomains?: string | null;
  level?: FrameworkLevelEnum | null;
  fourthPartyActiveScan?: boolean | null;
  intelligenceScanScore?: number | null;
  fourthPartyScanScore?: number | null;
  isThirdPartyScanCompleted?: boolean | null;
  priority?: ImpactEnum | null;
  identification?: ImpactEnum | null;
  materialityRating?: Array<string | null> | null;
  enbdCategory?: Array<string | null> | null;
  enbdDomain?: Array<string | null> | null;
  phoneNumber?: string | null;
  projectManagerPhoneNumber?: string | null;
  businessUnits?: string | null;
  category?: string | null;
  dealValue?: number | null;
  products?: string | null;
  ticketID?: string | null;
  tempUsersData?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateFrameworkScoresSubscription = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateFrameworkScoresSubscription = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteFrameworkScoresSubscription = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  reassessment?: number | null;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  groups?: string | null;
  maturity?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIntelligenceSubscription = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIntelligenceSubscription = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIntelligenceSubscription = {
  __typename: "Intelligence";
  id: string;
  breaches?: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date?: number | null;
      year?: number | null;
      name?: string | null;
      description?: string | null;
      leaks?: number | null;
      data?: Array<string | null> | null;
      status?: string | null;
      email?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  externalThreats?: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source?: string | null;
      findings?: Array<string | null> | null;
      riskScore?: string | null;
      details?: Array<{
        __typename: "ThreatDetails";
        ip?: string | null;
        port?: number | null;
        cve?: string | null;
        cvss?: number | null;
      } | null> | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  others?: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name?: string | null;
      label?: string | null;
      severity?: string | null;
      description?: string | null;
      status?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateBreachesSubscription = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateBreachesSubscription = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteBreachesSubscription = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date?: number | null;
  year?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
  email?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateExternalThreatsSubscription = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateExternalThreatsSubscription = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteExternalThreatsSubscription = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<{
    __typename: "ThreatDetails";
    ip?: string | null;
    port?: number | null;
    cve?: string | null;
    cvss?: number | null;
  } | null> | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateOthersSubscription = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOthersSubscription = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOthersSubscription = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateQuestionnaireCompletionSubscription = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateQuestionnaireCompletionSubscription = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteQuestionnaireCompletionSubscription = {
  __typename: "QuestionnaireCompletion";
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateAnswerSubscription = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type OnUpdateAnswerSubscription = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type OnDeleteAnswerSubscription = {
  __typename: "Answer";
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  answerName?: string | null;
  comment?: string | null;
  file?: Array<{
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
  triggerStopper?: boolean | null;
  origin?: AnswerOrigin | null;
  auditStatus?: string | null;
  isUptodate?: boolean | null;
  reviewerActionStatus?: ReviewerActions | null;
  updatedAt: string;
};

export type OnCreateAssignmentSubscription = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type OnUpdateAssignmentSubscription = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type OnDeleteAssignmentSubscription = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
  deletedUser?: boolean | null;
  frameworkKey?: string | null;
};

export type OnCreateDisableAssignmentSubscription = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type OnUpdateDisableAssignmentSubscription = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type OnDeleteDisableAssignmentSubscription = {
  __typename: "DisableAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left: number;
  right: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  disable?: boolean | null;
};

export type OnCreateAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  standardList?: Array<{
    __typename: "StandardFramework";
    id: string;
    type?: StandardType | null;
    fileName?: string | null;
    filter?: {
      __typename: "StandardFilter";
      impact?: ImpactEnum | null;
    } | null;
  } | null> | null;
  standardFrameworkList?: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      assessmentId: string;
      managerId?: string | null;
      assessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      selectedChapters?: Array<string | null> | null;
      managers?: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id?: string | null;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
          user?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          left?: number | null;
          right?: number | null;
          removedQuestions?: string | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      not_added?: boolean | null;
      archived?: boolean | null;
      reassessmentStatus?: ReassessmentEnum | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      documentCategory?: DocumentType | null;
      documentType?: string | null;
      suggestedArtifact?: string | null;
      suggestedComments?: string | null;
      level?: string | null;
      newStartReassessDate?: number | null;
      signatureUserID?: string | null;
      isLocked?: boolean | null;
      signedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      signature?: {
        __typename: "SignatureObject";
        title?: string | null;
        printName?: string | null;
        disclaimerDate?: number | null;
        image?: string | null;
        text?: string | null;
        draw?: string | null;
        signDate?: string | null;
      } | null;
      lockedByUserID?: string | null;
      lockedBy?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  rootId: string;
  childId: string;
  scores?: {
    __typename: "Scores";
    total?: number | null;
    target?: number | null;
    collection?: number | null;
    remediation?: number | null;
    reassessment?: number | null;
    totalQuestions?: number | null;
    completedQuestions?: number | null;
    totalGaps?: number | null;
    criticalGaps?: number | null;
    notApplicable?: boolean | null;
    top20?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    maturity?: number | null;
    tierScores?: string | null;
    totalReadinessTasks?: number | null;
    totalComplianceTasks?: number | null;
    totalInternalTasks?: number | null;
  } | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: {
    __typename: "Exports";
    jiraEntityIds?: Array<string | null> | null;
    serviceNowEntityIds?: Array<string | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateIntegrationMappingsSubscription = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateIntegrationMappingsSubscription = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteIntegrationMappingsSubscription = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateQuestionSettingsSubscription = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type OnUpdateQuestionSettingsSubscription = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type OnDeleteQuestionSettingsSubscription = {
  __typename: "QuestionSettings";
  id: string;
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isCommentsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
  suggestedArtifactAnswers?: string | null;
  requiredCommentAnswers?: string | null;
  versionFrequency?: Array<string | null> | null;
  isIntegrationActive?: boolean | null;
  isSmartMappingActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isInfoSec?: boolean | null;
  frameworkName?: string | null;
  left?: number | null;
  right?: number | null;
};

export type OnCreateTaskActionSubscription = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type OnUpdateTaskActionSubscription = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type OnDeleteTaskActionSubscription = {
  __typename: "TaskAction";
  id: string;
  createdAt?: string | null;
  score?: number | null;
  action?: string | null;
  assessmentId: string;
  taskAssignmentID?: string | null;
  questionId?: string | null;
  frameworkName: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  userId?: string | null;
  date: number;
  triggerStopper?: boolean | null;
  searchString?: string | null;
  questionIds?: Array<string | null> | null;
  smartMappingIds?: Array<string | null> | null;
  title?: string | null;
  impact?: string | null;
  maturity?: number | null;
  controlsConnected?: Array<{
    __typename: "ConnectedControl";
    id?: string | null;
    name?: string | null;
    label?: string | null;
  } | null> | null;
  type?: string | null;
  description?: string | null;
  tabName?: string | null;
  managerAction?: string | null;
  rootEntityId?: string | null;
  dueDate?: number | null;
  updatedAt: string;
};

export type OnCreateUsersAssignmentMapperSubscription = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type OnUpdateUsersAssignmentMapperSubscription = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type OnDeleteUsersAssignmentMapperSubscription = {
  __typename: "UsersAssignmentMapper";
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  score?: number | null;
  assessmentId?: string | null;
  taskId?: string | null;
  userId?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
};

export type OnCreateFrameworkSettingsSubscription = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateFrameworkSettingsSubscription = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteFrameworkSettingsSubscription = {
  __typename: "FrameworkSettings";
  id: string;
  settings?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomTaskSubscription = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCustomTaskSubscription = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCustomTaskSubscription = {
  __typename: "CustomTask";
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<{
    __typename: "Qualification";
    name?: string | null;
    isQualify?: boolean | null;
    templateURL?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    documentCategory?: DocumentType | null;
    documentType?: string | null;
    level?: string | null;
  } | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
  customTodo?: string | null;
  dueDate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCustomTaskAssignmentSubscription = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCustomTaskAssignmentSubscription = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCustomTaskAssignmentSubscription = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAssessmentStandardFrameworkSubscription = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAssessmentStandardFrameworkSubscription = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAssessmentStandardFrameworkSubscription = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: {
    __typename: "StandardFilter";
    impact?: ImpactEnum | null;
  } | null;
  timeline?: {
    __typename: "Timeline";
    initiationDate?: number | null;
    collectionDate?: number | null;
    nextReviewDate?: number | null;
    frequency?: FrequencyCheckEnum | null;
    days?: number | null;
    nextStartingDate?: number | null;
    reassessmentNotifier?: number | null;
    vendorOnBoardingDate?: number | null;
    isAutoReminder?: boolean | null;
    isAutoReassessment?: boolean | null;
  } | null;
  assessmentId: string;
  managerId?: string | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  selectedChapters?: Array<string | null> | null;
  managers?: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id?: string | null;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
      user?: {
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role?: RoleEnum | null;
        roleId?: string | null;
        isViewOnly?: boolean | null;
        phone?: string | null;
        entityId?: string | null;
        entityIds?: Array<string | null> | null;
        isCognitoUser?: boolean | null;
        questionMap?: string | null;
        createdBy?: string | null;
        createdAt?: number | null;
        lastLogin?: number | null;
        invitationDate?: number | null;
        reminderDate?: number | null;
        modifiedBy?: string | null;
        modifiedAt?: number | null;
        domain?: string | null;
        temporaryHigherRank?: boolean | null;
        entityLayerIds?: Array<string | null> | null;
        clickThrough?: boolean | null;
        clickThroughDate?: string | null;
        projectManagerEmail?: string | null;
        isGlobalParticipant?: boolean | null;
        onBoardingStatus?: MidMarketEnum | null;
        updatedAt: string;
      } | null;
      left?: number | null;
      right?: number | null;
      removedQuestions?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  not_added?: boolean | null;
  archived?: boolean | null;
  reassessmentStatus?: ReassessmentEnum | null;
  defaultQuestionSettings?: {
    __typename: "EntityQuestionSettings";
    isApprovalRequired?: boolean | null;
    isArtifactsMandatory?: boolean | null;
    isCommentsMandatory?: boolean | null;
    isCollaborative?: boolean | null;
    isIntegrationActive?: boolean | null;
    isSmartMappingActive?: boolean | null;
  } | null;
  documentCategory?: DocumentType | null;
  documentType?: string | null;
  suggestedArtifact?: string | null;
  suggestedComments?: string | null;
  level?: string | null;
  newStartReassessDate?: number | null;
  signatureUserID?: string | null;
  isLocked?: boolean | null;
  signedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  signature?: {
    __typename: "SignatureObject";
    title?: string | null;
    printName?: string | null;
    disclaimerDate?: number | null;
    image?: string | null;
    text?: string | null;
    draw?: string | null;
    signDate?: string | null;
  } | null;
  lockedByUserID?: string | null;
  lockedBy?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateFrameworkManagerSubscription = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateFrameworkManagerSubscription = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteFrameworkManagerSubscription = {
  __typename: "FrameworkManager";
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  left?: number | null;
  right?: number | null;
  removedQuestions?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCommentSubscription = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCommentSubscription = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCommentSubscription = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  riskId?: string | null;
  assessmentId?: string | null;
  userId: string;
  exports?: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category?: CategoryEnum | null;
      categoryId: string;
      date?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  user?: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      isGlobalParticipant?: boolean | null;
      onBoardingStatus?: MidMarketEnum | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  archivedUser?: {
    __typename: "ArchivedUser";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone?: string | null;
    entityId?: string | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    updatedAt: string;
  } | null;
  type?: string | null;
  sType?: string | null;
  framework?: string | null;
  srcFramework?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateExportSubscription = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateExportSubscription = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteExportSubscription = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateReportSubscription = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type OnUpdateReportSubscription = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type OnDeleteReportSubscription = {
  __typename: "Report";
  id: string;
  entityId: string;
  name: string;
  managerName?: string | null;
  startDate: number;
  endDate: number;
  type: ReportTypeEnum;
  isDraft: boolean;
  previousReports?: Array<string | null> | null;
  createdAt?: number | null;
  updatedAt: string;
};

export type OnCreateRiskSubscription = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type OnUpdateRiskSubscription = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type OnDeleteRiskSubscription = {
  __typename: "Risk";
  id: string;
  idTitle: string;
  entityId?: string | null;
  entity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  subEntityId: string;
  subEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  assessmentId: string;
  assessment?: {
    __typename: "Assessment";
    id: string;
    standardList?: Array<{
      __typename: "StandardFramework";
      id: string;
      type?: StandardType | null;
      fileName?: string | null;
      filter?: {
        __typename: "StandardFilter";
        impact?: ImpactEnum | null;
      } | null;
    } | null> | null;
    standardFrameworkList?: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        assessmentId: string;
        managerId?: string | null;
        assessment?: {
          __typename: "Assessment";
          id: string;
          standardList?: Array<{
            __typename: "StandardFramework";
            id: string;
            type?: StandardType | null;
            fileName?: string | null;
          } | null> | null;
          standardFrameworkList?: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken?: string | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          rootId: string;
          childId: string;
          scores?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          left?: number | null;
          right?: number | null;
          managers?: Array<string | null> | null;
          exports?: {
            __typename: "Exports";
            jiraEntityIds?: Array<string | null> | null;
            serviceNowEntityIds?: Array<string | null> | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        selectedChapters?: Array<string | null> | null;
        managers?: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id?: string | null;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
            left?: number | null;
            right?: number | null;
            removedQuestions?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        not_added?: boolean | null;
        archived?: boolean | null;
        reassessmentStatus?: ReassessmentEnum | null;
        defaultQuestionSettings?: {
          __typename: "EntityQuestionSettings";
          isApprovalRequired?: boolean | null;
          isArtifactsMandatory?: boolean | null;
          isCommentsMandatory?: boolean | null;
          isCollaborative?: boolean | null;
          isIntegrationActive?: boolean | null;
          isSmartMappingActive?: boolean | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        suggestedArtifact?: string | null;
        suggestedComments?: string | null;
        level?: string | null;
        newStartReassessDate?: number | null;
        signatureUserID?: string | null;
        isLocked?: boolean | null;
        signedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        signature?: {
          __typename: "SignatureObject";
          title?: string | null;
          printName?: string | null;
          disclaimerDate?: number | null;
          image?: string | null;
          text?: string | null;
          draw?: string | null;
          signDate?: string | null;
        } | null;
        lockedByUserID?: string | null;
        lockedBy?: {
          __typename: "User";
          id: string;
          name: string;
          email: string;
          role?: RoleEnum | null;
          roleId?: string | null;
          isViewOnly?: boolean | null;
          phone?: string | null;
          entityId?: string | null;
          entityIds?: Array<string | null> | null;
          isCognitoUser?: boolean | null;
          questionMap?: string | null;
          createdBy?: string | null;
          createdAt?: number | null;
          lastLogin?: number | null;
          invitationDate?: number | null;
          reminderDate?: number | null;
          modifiedBy?: string | null;
          modifiedAt?: number | null;
          domain?: string | null;
          temporaryHigherRank?: boolean | null;
          entityLayerIds?: Array<string | null> | null;
          clickThrough?: boolean | null;
          clickThroughDate?: string | null;
          projectManagerEmail?: string | null;
          isGlobalParticipant?: boolean | null;
          onBoardingStatus?: MidMarketEnum | null;
          updatedAt: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    rootId: string;
    childId: string;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    left?: number | null;
    right?: number | null;
    managers?: Array<string | null> | null;
    exports?: {
      __typename: "Exports";
      jiraEntityIds?: Array<string | null> | null;
      serviceNowEntityIds?: Array<string | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  type?: RiskTypeEnum | null;
  disable?: boolean | null;
  controlIds?: Array<string | null> | null;
  riskTitle: string;
  riskDescription?: string | null;
  riskCategory?: string | null;
  relatedAssets?: Array<string | null> | null;
  residualImpact?: RiskImpactEnum | null;
  residualProbability?: RiskProbabilityEnum | null;
  riskImpact: RiskImpactEnum;
  riskProbability: RiskProbabilityEnum;
  inherentRisk?: number | null;
  residualRisk?: number | null;
  riskStatus: RiskStatusEnum;
  riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
  notes?: string | null;
  costToRemediate?: number | null;
  costToRemediateType: RiskCostRemediationEnum;
  primaryLoss?: number | null;
  financialImpact?: Array<{
    __typename: "RiskFinancialImpact";
    type?: string | null;
    minimum?: number | null;
    mostLikely?: number | null;
    maximum?: number | null;
    confidence?: RiskConfidenceEnum | null;
    stepData?: string | null;
  } | null> | null;
  riskPriority: RiskPriorityEnum;
  hidden?: boolean | null;
  financialToggle?: boolean | null;
  scoreHistory?: Array<{
    __typename: "RiskQuestionScore";
    controlId?: string | null;
    questionOrder?: number | null;
    score?: number | null;
    remediationStatus?: string | null;
    impact?: boolean | null;
    probability?: boolean | null;
    weight?: number | null;
    left?: number | null;
    right?: number | null;
  } | null> | null;
  riskTags?: Array<string | null> | null;
  riskTasks?: Array<{
    __typename: "RiskTaskObject";
    title?: string | null;
    status?: TaskStatusEnum | null;
    id?: string | null;
  } | null> | null;
  riskControlNames?: Array<{
    __typename: "RiskManualControl";
    title?: string | null;
    id?: string | null;
    mitigationPlan?: string | null;
    label?: string | null;
  } | null> | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  riskTasksIds?: Array<string | null> | null;
  isArchived?: boolean | null;
  isEscalated?: boolean | null;
  sensitivity?: number | null;
  effectiveness?: number | null;
  deadline?: number | null;
  riskOwnerIds?: Array<string | null> | null;
};

export type OnCreateRiskOwnerAssignmentSubscription = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRiskOwnerAssignmentSubscription = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRiskOwnerAssignmentSubscription = {
  __typename: "RiskOwnerAssignment";
  id: string;
  subEntityId: string;
  userId: string;
  riskId: string;
  risk?: {
    __typename: "Risk";
    id: string;
    idTitle: string;
    entityId?: string | null;
    entity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    subEntityId: string;
    subEntity?: {
      __typename: "Entity";
      id: string;
      name: string;
      users?: Array<string | null> | null;
      completionStatus?: CollectionStatusEnum | null;
      accessGroups: Array<string>;
      participantGroup: Array<string>;
      rootEntityId?: string | null;
      childEntityId?: string | null;
      frameworkId?: string | null;
      frameworkName?: string | null;
      frameworkType?: EntityTypeEnum | null;
      parentId?: string | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      entityType: EntityTypeEnum;
      projectManager?: string | null;
      projectManagerName?: string | null;
      projectDeadline?: number | null;
      logo?: {
        __typename: "S3File";
        id?: string | null;
        name: string;
        names?: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date?: number | null;
        contentType?: string | null;
        length?: number | null;
        key?: string | null;
      } | null;
      integrations?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      externalFeed?: Array<{
        __typename: "Integrations";
        name: string;
        credentials?: string | null;
        status?: StatusEnum | null;
        isEnable?: boolean | null;
        isAuth?: boolean | null;
        inheritFromParent?: boolean | null;
        webhookUpdatedAt?: number | null;
      } | null> | null;
      defaultSetting?: {
        __typename: "DefaultSetting";
        dueDateInDays?: number | null;
        frequency?: string | null;
        frequencyDays?: number | null;
        assessmentDays?: number | null;
        isAutoReassessment?: boolean | null;
        surveyLevel?: string | null;
        artifacts?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        standards?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customRequirements?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        customCertifications?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        accessLevel?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        activeScan?: boolean | null;
        notifications?: string | null;
        selectedRisk?: string | null;
        selectedLevel?: string | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        domains?: string | null;
        otherData?: string | null;
        fourthPartyWeightage?: number | null;
        enbdDomain?: Array<string | null> | null;
      } | null;
      createdBy?: string | null;
      createdAt?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      vendorDetails?: {
        __typename: "VendorsDetail";
        id: string;
        impact?: number | null;
        externalScan?: number | null;
        financialRisk?: number | null;
        criticalGaps?: number | null;
        standardsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        status?: VendorStatusEnum | null;
        probability?: number | null;
        website?: string | null;
        location?: string | null;
        size?: string | null;
        description?: string | null;
        businessPointOfContactId?: string | null;
        accessLevel?: AccessLevelEnum | null;
        collectionStatus?: CollectionStatusEnum | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        approvalDate?: number | null;
        questionnaires?: Array<{
          __typename: "Questionnaire";
          tag?: string | null;
          status?: {
            __typename: "QuestionnaireStatus";
            totalQuestions?: number | null;
            completedQuestions?: number | null;
          } | null;
          criticalGaps?: Array<string | null> | null;
          allGaps?: string | null;
        } | null> | null;
        artifacts?: {
          __typename: "Artifacts";
          documents?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          scans?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          insurances?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
          certifications?: Array<{
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null> | null;
        } | null;
        intelligence?: {
          __typename: "Intelligence";
          id: string;
          breaches?: {
            __typename: "ModelBreachesConnection";
            nextToken?: string | null;
          } | null;
          externalThreats?: {
            __typename: "ModelExternalThreatsConnection";
            nextToken?: string | null;
          } | null;
          others?: {
            __typename: "ModelOthersConnection";
            nextToken?: string | null;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        primaryPoc?: {
          __typename: "ShortRef";
          name?: string | null;
          id?: string | null;
          email?: string | null;
        } | null;
        stage?: VendorStatusEnum | null;
        activeScan?: boolean | null;
        domain?: string | null;
        privacyData?: boolean | null;
        impactsQualify?: Array<{
          __typename: "Qualification";
          name?: string | null;
          isQualify?: boolean | null;
          templateURL?: {
            __typename: "S3File";
            id?: string | null;
            name: string;
            names?: string | null;
            entityId: string;
            fileType: FileTypeEnum;
            date?: number | null;
            contentType?: string | null;
            length?: number | null;
            key?: string | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          level?: string | null;
        } | null> | null;
        primaryLoss?: number | null;
        financialImpact?: Array<{
          __typename: "RiskFinancialImpact";
          type?: string | null;
          minimum?: number | null;
          mostLikely?: number | null;
          maximum?: number | null;
          confidence?: RiskConfidenceEnum | null;
          stepData?: string | null;
        } | null> | null;
        smartTags?: Array<string | null> | null;
        employees?: number | null;
        domains?: Array<string | null> | null;
        isThirdParty?: boolean | null;
        isFourthParty?: boolean | null;
        fourthPartyDomains?: string | null;
        level?: FrameworkLevelEnum | null;
        fourthPartyActiveScan?: boolean | null;
        intelligenceScanScore?: number | null;
        fourthPartyScanScore?: number | null;
        isThirdPartyScanCompleted?: boolean | null;
        priority?: ImpactEnum | null;
        identification?: ImpactEnum | null;
        materialityRating?: Array<string | null> | null;
        enbdCategory?: Array<string | null> | null;
        enbdDomain?: Array<string | null> | null;
        phoneNumber?: string | null;
        projectManagerPhoneNumber?: string | null;
        businessUnits?: string | null;
        category?: string | null;
        dealValue?: number | null;
        products?: string | null;
        ticketID?: string | null;
        tempUsersData?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      industry?: string | null;
      domain: string;
      activeAssessmentId: string;
      tierSelected?: number | null;
      activeAssessment?: {
        __typename: "Assessment";
        id: string;
        standardList?: Array<{
          __typename: "StandardFramework";
          id: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
        } | null> | null;
        standardFrameworkList?: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type?: StandardType | null;
            fileName?: string | null;
            assessmentId: string;
            managerId?: string | null;
            selectedChapters?: Array<string | null> | null;
            not_added?: boolean | null;
            archived?: boolean | null;
            reassessmentStatus?: ReassessmentEnum | null;
            documentCategory?: DocumentType | null;
            documentType?: string | null;
            suggestedArtifact?: string | null;
            suggestedComments?: string | null;
            level?: string | null;
            newStartReassessDate?: number | null;
            signatureUserID?: string | null;
            isLocked?: boolean | null;
            lockedByUserID?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        timeline?: {
          __typename: "Timeline";
          initiationDate?: number | null;
          collectionDate?: number | null;
          nextReviewDate?: number | null;
          frequency?: FrequencyCheckEnum | null;
          days?: number | null;
          nextStartingDate?: number | null;
          reassessmentNotifier?: number | null;
          vendorOnBoardingDate?: number | null;
          isAutoReminder?: boolean | null;
          isAutoReassessment?: boolean | null;
        } | null;
        rootId: string;
        childId: string;
        scores?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        left?: number | null;
        right?: number | null;
        managers?: Array<string | null> | null;
        exports?: {
          __typename: "Exports";
          jiraEntityIds?: Array<string | null> | null;
          serviceNowEntityIds?: Array<string | null> | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      scName?: string | null;
      defaultQuestionSettings?: {
        __typename: "EntityQuestionSettings";
        isApprovalRequired?: boolean | null;
        isArtifactsMandatory?: boolean | null;
        isCommentsMandatory?: boolean | null;
        isCollaborative?: boolean | null;
        isIntegrationActive?: boolean | null;
        isSmartMappingActive?: boolean | null;
      } | null;
      KMS?: {
        __typename: "KMSData";
        status?: KMSStatus | null;
        version?: number | null;
        key?: string | null;
        alias?: string | null;
        dataKeyEncrypted?: string | null;
        newDataKeyEncrypted?: string | null;
        newKeyId?: string | null;
        rotations?: {
          __typename: "Rotation";
          count?: number | null;
          status?: KMSStatus | null;
          lastRotation?: number | null;
          firstRotationDate?: number | null;
        } | null;
        userId?: string | null;
      } | null;
      AI?: {
        __typename: "AIData";
        credits?: number | null;
        date?: number | null;
        isActive?: boolean | null;
        usedCredits?: number | null;
        userName?: string | null;
      } | null;
      upperdeckRoute?: string | null;
      isLocked?: boolean | null;
      eSignFinalized?: boolean | null;
      weightageScore?: number | null;
      upperdeckSetting?: string | null;
      domainScanned?: boolean | null;
      domainScanTimes?: string | null;
      domainScanEnabledForEntity?: boolean | null;
      solutionStatus?: {
        __typename: "SolutionStatusData";
        firstParty?: boolean | null;
        thirdParty?: boolean | null;
        boardView?: boolean | null;
      } | null;
      vendors?: {
        __typename: "VendorsData";
        vendorsTotalCount?: number | null;
        totalVendorsCreated?: number | null;
      } | null;
      frameworkSettings?: string | null;
      usedFrameworkUniqueCount?: number | null;
      spiderChartSetting?: string | null;
      isVendorInLimit?: boolean | null;
      updatedAt: string;
    } | null;
    assessmentId: string;
    assessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    type?: RiskTypeEnum | null;
    disable?: boolean | null;
    controlIds?: Array<string | null> | null;
    riskTitle: string;
    riskDescription?: string | null;
    riskCategory?: string | null;
    relatedAssets?: Array<string | null> | null;
    residualImpact?: RiskImpactEnum | null;
    residualProbability?: RiskProbabilityEnum | null;
    riskImpact: RiskImpactEnum;
    riskProbability: RiskProbabilityEnum;
    inherentRisk?: number | null;
    residualRisk?: number | null;
    riskStatus: RiskStatusEnum;
    riskStatusUpdatedBy: RiskStatusUpdatedByEnum;
    notes?: string | null;
    costToRemediate?: number | null;
    costToRemediateType: RiskCostRemediationEnum;
    primaryLoss?: number | null;
    financialImpact?: Array<{
      __typename: "RiskFinancialImpact";
      type?: string | null;
      minimum?: number | null;
      mostLikely?: number | null;
      maximum?: number | null;
      confidence?: RiskConfidenceEnum | null;
      stepData?: string | null;
    } | null> | null;
    riskPriority: RiskPriorityEnum;
    hidden?: boolean | null;
    financialToggle?: boolean | null;
    scoreHistory?: Array<{
      __typename: "RiskQuestionScore";
      controlId?: string | null;
      questionOrder?: number | null;
      score?: number | null;
      remediationStatus?: string | null;
      impact?: boolean | null;
      probability?: boolean | null;
      weight?: number | null;
      left?: number | null;
      right?: number | null;
    } | null> | null;
    riskTags?: Array<string | null> | null;
    riskTasks?: Array<{
      __typename: "RiskTaskObject";
      title?: string | null;
      status?: TaskStatusEnum | null;
      id?: string | null;
    } | null> | null;
    riskControlNames?: Array<{
      __typename: "RiskManualControl";
      title?: string | null;
      id?: string | null;
      mitigationPlan?: string | null;
      label?: string | null;
    } | null> | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    riskTasksIds?: Array<string | null> | null;
    isArchived?: boolean | null;
    isEscalated?: boolean | null;
    sensitivity?: number | null;
    effectiveness?: number | null;
    deadline?: number | null;
    riskOwnerIds?: Array<string | null> | null;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSavedFilterSubscription = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSavedFilterSubscription = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSavedFilterSubscription = {
  __typename: "SavedFilter";
  id: string;
  filterObject?: string | null;
  userId: string;
  entityId: string;
  type?: FilterTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDomainSettingsSubscription = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type OnUpdateDomainSettingsSubscription = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type OnDeleteDomainSettingsSubscription = {
  __typename: "DomainSettings";
  id: string;
  domain: string;
  key?: string | null;
  average?: number | null;
  allowedRoutes?: Array<string> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  allowedFeatures?: string | null;
  settings?: string | null;
  kmsStatus?: boolean | null;
};

export type OnCreateExecutiveSummarySubscription = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateExecutiveSummarySubscription = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteExecutiveSummarySubscription = {
  __typename: "ExecutiveSummary";
  id: string;
  entityId: string;
  title: string;
  subjectList?: Array<{
    __typename: "SummarySubject";
    id: string;
    title: string;
    description: string;
    toggle: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateNetskopeProjectSubscription = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateNetskopeProjectSubscription = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteNetskopeProjectSubscription = {
  __typename: "NetskopeProject";
  id: string;
  entityId: string;
  title: string;
  description?: string | null;
  previous?: ProgressStatusEnum | null;
  currentProgress?: {
    __typename: "CurrentProgress";
    startDate: number;
    endDate: number;
    status: string;
  } | null;
  budget?: number | null;
  comments?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateEntityLayersSubscription = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type OnUpdateEntityLayersSubscription = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type OnDeleteEntityLayersSubscription = {
  __typename: "EntityLayers";
  id: string;
  name: string;
  managerName: string;
  type: BnbLayerEnum;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  parentId?: string | null;
  logo?: {
    __typename: "S3File";
    id?: string | null;
    name: string;
    names?: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date?: number | null;
    contentType?: string | null;
    length?: number | null;
    key?: string | null;
  } | null;
  srName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  domain?: string | null;
};

export type OnCreateEntityWizardSubscription = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type OnUpdateEntityWizardSubscription = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type OnDeleteEntityWizardSubscription = {
  __typename: "EntityWizard";
  id: string;
  isDraft?: boolean | null;
  organizational?: string | null;
  financial?: string | null;
  frameworks?: Array<string | null> | null;
  dataScope?: string | null;
  technical?: string | null;
  riskScenarios?: string | null;
  accountDetails?: string | null;
  currentYear?: string | null;
  rootEntity?: {
    __typename: "Entity";
    id: string;
    name: string;
    users?: Array<string | null> | null;
    completionStatus?: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId?: string | null;
    childEntityId?: string | null;
    frameworkId?: string | null;
    frameworkName?: string | null;
    frameworkType?: EntityTypeEnum | null;
    parentId?: string | null;
    timeline?: {
      __typename: "Timeline";
      initiationDate?: number | null;
      collectionDate?: number | null;
      nextReviewDate?: number | null;
      frequency?: FrequencyCheckEnum | null;
      days?: number | null;
      nextStartingDate?: number | null;
      reassessmentNotifier?: number | null;
      vendorOnBoardingDate?: number | null;
      isAutoReminder?: boolean | null;
      isAutoReassessment?: boolean | null;
    } | null;
    scores?: {
      __typename: "Scores";
      total?: number | null;
      target?: number | null;
      collection?: number | null;
      remediation?: number | null;
      reassessment?: number | null;
      totalQuestions?: number | null;
      completedQuestions?: number | null;
      totalGaps?: number | null;
      criticalGaps?: number | null;
      notApplicable?: boolean | null;
      top20?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      maturity?: number | null;
      tierScores?: string | null;
      totalReadinessTasks?: number | null;
      totalComplianceTasks?: number | null;
      totalInternalTasks?: number | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager?: string | null;
    projectManagerName?: string | null;
    projectDeadline?: number | null;
    logo?: {
      __typename: "S3File";
      id?: string | null;
      name: string;
      names?: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date?: number | null;
      contentType?: string | null;
      length?: number | null;
      key?: string | null;
    } | null;
    integrations?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    externalFeed?: Array<{
      __typename: "Integrations";
      name: string;
      credentials?: string | null;
      status?: StatusEnum | null;
      isEnable?: boolean | null;
      isAuth?: boolean | null;
      inheritFromParent?: boolean | null;
      webhookUpdatedAt?: number | null;
    } | null> | null;
    defaultSetting?: {
      __typename: "DefaultSetting";
      dueDateInDays?: number | null;
      frequency?: string | null;
      frequencyDays?: number | null;
      assessmentDays?: number | null;
      isAutoReassessment?: boolean | null;
      surveyLevel?: string | null;
      artifacts?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      standards?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customRequirements?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      customCertifications?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      accessLevel?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      activeScan?: boolean | null;
      notifications?: string | null;
      selectedRisk?: string | null;
      selectedLevel?: string | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      domains?: string | null;
      otherData?: string | null;
      fourthPartyWeightage?: number | null;
      enbdDomain?: Array<string | null> | null;
    } | null;
    createdBy?: string | null;
    createdAt?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    vendorDetails?: {
      __typename: "VendorsDetail";
      id: string;
      impact?: number | null;
      externalScan?: number | null;
      financialRisk?: number | null;
      criticalGaps?: number | null;
      standardsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      status?: VendorStatusEnum | null;
      probability?: number | null;
      website?: string | null;
      location?: string | null;
      size?: string | null;
      description?: string | null;
      businessPointOfContactId?: string | null;
      accessLevel?: AccessLevelEnum | null;
      collectionStatus?: CollectionStatusEnum | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      approvalDate?: number | null;
      questionnaires?: Array<{
        __typename: "Questionnaire";
        tag?: string | null;
        status?: {
          __typename: "QuestionnaireStatus";
          totalQuestions?: number | null;
          completedQuestions?: number | null;
        } | null;
        criticalGaps?: Array<string | null> | null;
        allGaps?: string | null;
      } | null> | null;
      artifacts?: {
        __typename: "Artifacts";
        documents?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        scans?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        insurances?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
        certifications?: Array<{
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null> | null;
      } | null;
      intelligence?: {
        __typename: "Intelligence";
        id: string;
        breaches?: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date?: number | null;
            year?: number | null;
            name?: string | null;
            description?: string | null;
            leaks?: number | null;
            data?: Array<string | null> | null;
            status?: string | null;
            email?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        externalThreats?: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source?: string | null;
            findings?: Array<string | null> | null;
            riskScore?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        others?: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name?: string | null;
            label?: string | null;
            severity?: string | null;
            description?: string | null;
            status?: string | null;
            createdAt: string;
            updatedAt: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      primaryPoc?: {
        __typename: "ShortRef";
        name?: string | null;
        id?: string | null;
        email?: string | null;
      } | null;
      stage?: VendorStatusEnum | null;
      activeScan?: boolean | null;
      domain?: string | null;
      privacyData?: boolean | null;
      impactsQualify?: Array<{
        __typename: "Qualification";
        name?: string | null;
        isQualify?: boolean | null;
        templateURL?: {
          __typename: "S3File";
          id?: string | null;
          name: string;
          names?: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date?: number | null;
          contentType?: string | null;
          length?: number | null;
          key?: string | null;
        } | null;
        documentCategory?: DocumentType | null;
        documentType?: string | null;
        level?: string | null;
      } | null> | null;
      primaryLoss?: number | null;
      financialImpact?: Array<{
        __typename: "RiskFinancialImpact";
        type?: string | null;
        minimum?: number | null;
        mostLikely?: number | null;
        maximum?: number | null;
        confidence?: RiskConfidenceEnum | null;
        stepData?: string | null;
      } | null> | null;
      smartTags?: Array<string | null> | null;
      employees?: number | null;
      domains?: Array<string | null> | null;
      isThirdParty?: boolean | null;
      isFourthParty?: boolean | null;
      fourthPartyDomains?: string | null;
      level?: FrameworkLevelEnum | null;
      fourthPartyActiveScan?: boolean | null;
      intelligenceScanScore?: number | null;
      fourthPartyScanScore?: number | null;
      isThirdPartyScanCompleted?: boolean | null;
      priority?: ImpactEnum | null;
      identification?: ImpactEnum | null;
      materialityRating?: Array<string | null> | null;
      enbdCategory?: Array<string | null> | null;
      enbdDomain?: Array<string | null> | null;
      phoneNumber?: string | null;
      projectManagerPhoneNumber?: string | null;
      businessUnits?: string | null;
      category?: string | null;
      dealValue?: number | null;
      products?: string | null;
      ticketID?: string | null;
      tempUsersData?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    industry?: string | null;
    domain: string;
    activeAssessmentId: string;
    tierSelected?: number | null;
    activeAssessment?: {
      __typename: "Assessment";
      id: string;
      standardList?: Array<{
        __typename: "StandardFramework";
        id: string;
        type?: StandardType | null;
        fileName?: string | null;
        filter?: {
          __typename: "StandardFilter";
          impact?: ImpactEnum | null;
        } | null;
      } | null> | null;
      standardFrameworkList?: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type?: StandardType | null;
          fileName?: string | null;
          filter?: {
            __typename: "StandardFilter";
            impact?: ImpactEnum | null;
          } | null;
          timeline?: {
            __typename: "Timeline";
            initiationDate?: number | null;
            collectionDate?: number | null;
            nextReviewDate?: number | null;
            frequency?: FrequencyCheckEnum | null;
            days?: number | null;
            nextStartingDate?: number | null;
            reassessmentNotifier?: number | null;
            vendorOnBoardingDate?: number | null;
            isAutoReminder?: boolean | null;
            isAutoReassessment?: boolean | null;
          } | null;
          assessmentId: string;
          managerId?: string | null;
          assessment?: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left?: number | null;
            right?: number | null;
            managers?: Array<string | null> | null;
            createdAt: string;
            updatedAt: string;
          } | null;
          selectedChapters?: Array<string | null> | null;
          managers?: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken?: string | null;
          } | null;
          not_added?: boolean | null;
          archived?: boolean | null;
          reassessmentStatus?: ReassessmentEnum | null;
          defaultQuestionSettings?: {
            __typename: "EntityQuestionSettings";
            isApprovalRequired?: boolean | null;
            isArtifactsMandatory?: boolean | null;
            isCommentsMandatory?: boolean | null;
            isCollaborative?: boolean | null;
            isIntegrationActive?: boolean | null;
            isSmartMappingActive?: boolean | null;
          } | null;
          documentCategory?: DocumentType | null;
          documentType?: string | null;
          suggestedArtifact?: string | null;
          suggestedComments?: string | null;
          level?: string | null;
          newStartReassessDate?: number | null;
          signatureUserID?: string | null;
          isLocked?: boolean | null;
          signedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          signature?: {
            __typename: "SignatureObject";
            title?: string | null;
            printName?: string | null;
            disclaimerDate?: number | null;
            image?: string | null;
            text?: string | null;
            draw?: string | null;
            signDate?: string | null;
          } | null;
          lockedByUserID?: string | null;
          lockedBy?: {
            __typename: "User";
            id: string;
            name: string;
            email: string;
            role?: RoleEnum | null;
            roleId?: string | null;
            isViewOnly?: boolean | null;
            phone?: string | null;
            entityId?: string | null;
            entityIds?: Array<string | null> | null;
            isCognitoUser?: boolean | null;
            questionMap?: string | null;
            createdBy?: string | null;
            createdAt?: number | null;
            lastLogin?: number | null;
            invitationDate?: number | null;
            reminderDate?: number | null;
            modifiedBy?: string | null;
            modifiedAt?: number | null;
            domain?: string | null;
            temporaryHigherRank?: boolean | null;
            entityLayerIds?: Array<string | null> | null;
            clickThrough?: boolean | null;
            clickThroughDate?: string | null;
            projectManagerEmail?: string | null;
            isGlobalParticipant?: boolean | null;
            onBoardingStatus?: MidMarketEnum | null;
            updatedAt: string;
          } | null;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      timeline?: {
        __typename: "Timeline";
        initiationDate?: number | null;
        collectionDate?: number | null;
        nextReviewDate?: number | null;
        frequency?: FrequencyCheckEnum | null;
        days?: number | null;
        nextStartingDate?: number | null;
        reassessmentNotifier?: number | null;
        vendorOnBoardingDate?: number | null;
        isAutoReminder?: boolean | null;
        isAutoReassessment?: boolean | null;
      } | null;
      rootId: string;
      childId: string;
      scores?: {
        __typename: "Scores";
        total?: number | null;
        target?: number | null;
        collection?: number | null;
        remediation?: number | null;
        reassessment?: number | null;
        totalQuestions?: number | null;
        completedQuestions?: number | null;
        totalGaps?: number | null;
        criticalGaps?: number | null;
        notApplicable?: boolean | null;
        top20?: {
          __typename: "Scores";
          total?: number | null;
          target?: number | null;
          collection?: number | null;
          remediation?: number | null;
          reassessment?: number | null;
          totalQuestions?: number | null;
          completedQuestions?: number | null;
          totalGaps?: number | null;
          criticalGaps?: number | null;
          notApplicable?: boolean | null;
          top20?: {
            __typename: "Scores";
            total?: number | null;
            target?: number | null;
            collection?: number | null;
            remediation?: number | null;
            reassessment?: number | null;
            totalQuestions?: number | null;
            completedQuestions?: number | null;
            totalGaps?: number | null;
            criticalGaps?: number | null;
            notApplicable?: boolean | null;
            maturity?: number | null;
            tierScores?: string | null;
            totalReadinessTasks?: number | null;
            totalComplianceTasks?: number | null;
            totalInternalTasks?: number | null;
          } | null;
          maturity?: number | null;
          tierScores?: string | null;
          totalReadinessTasks?: number | null;
          totalComplianceTasks?: number | null;
          totalInternalTasks?: number | null;
        } | null;
        maturity?: number | null;
        tierScores?: string | null;
        totalReadinessTasks?: number | null;
        totalComplianceTasks?: number | null;
        totalInternalTasks?: number | null;
      } | null;
      left?: number | null;
      right?: number | null;
      managers?: Array<string | null> | null;
      exports?: {
        __typename: "Exports";
        jiraEntityIds?: Array<string | null> | null;
        serviceNowEntityIds?: Array<string | null> | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    scName?: string | null;
    defaultQuestionSettings?: {
      __typename: "EntityQuestionSettings";
      isApprovalRequired?: boolean | null;
      isArtifactsMandatory?: boolean | null;
      isCommentsMandatory?: boolean | null;
      isCollaborative?: boolean | null;
      isIntegrationActive?: boolean | null;
      isSmartMappingActive?: boolean | null;
    } | null;
    KMS?: {
      __typename: "KMSData";
      status?: KMSStatus | null;
      version?: number | null;
      key?: string | null;
      alias?: string | null;
      dataKeyEncrypted?: string | null;
      newDataKeyEncrypted?: string | null;
      newKeyId?: string | null;
      rotations?: {
        __typename: "Rotation";
        count?: number | null;
        status?: KMSStatus | null;
        lastRotation?: number | null;
        firstRotationDate?: number | null;
      } | null;
      userId?: string | null;
    } | null;
    AI?: {
      __typename: "AIData";
      credits?: number | null;
      date?: number | null;
      isActive?: boolean | null;
      usedCredits?: number | null;
      userName?: string | null;
    } | null;
    upperdeckRoute?: string | null;
    isLocked?: boolean | null;
    eSignFinalized?: boolean | null;
    weightageScore?: number | null;
    upperdeckSetting?: string | null;
    domainScanned?: boolean | null;
    domainScanTimes?: string | null;
    domainScanEnabledForEntity?: boolean | null;
    solutionStatus?: {
      __typename: "SolutionStatusData";
      firstParty?: boolean | null;
      thirdParty?: boolean | null;
      boardView?: boolean | null;
    } | null;
    vendors?: {
      __typename: "VendorsData";
      vendorsTotalCount?: number | null;
      totalVendorsCreated?: number | null;
    } | null;
    frameworkSettings?: string | null;
    usedFrameworkUniqueCount?: number | null;
    spiderChartSetting?: string | null;
    isVendorInLimit?: boolean | null;
    updatedAt: string;
  } | null;
  parentId?: string | null;
  score: {
    __typename: "BnbEntityScore";
    total: number;
    collection: number;
    remediation: number;
  };
  inherent: string;
  residual: string;
  domainScanned?: boolean | null;
  domainScanTimes?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  riskFramework?: string | null;
  clientDomain: string;
  userId?: string | null;
  applicantInfo?: string | null;
  revenueDetails?: string | null;
  dataInventory?: string | null;
  updatedBy?: string | null;
};

export type OnCreateLogsSubscription = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateLogsSubscription = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteLogsSubscription = {
  __typename: "Logs";
  id: string;
  message: string;
  type: string;
  targetId: string;
  assessmentId: string;
  userID?: string | null;
  user?: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role?: RoleEnum | null;
    roleId?: string | null;
    isViewOnly?: boolean | null;
    phone?: string | null;
    entityId?: string | null;
    entityIds?: Array<string | null> | null;
    isCognitoUser?: boolean | null;
    questionMap?: string | null;
    createdBy?: string | null;
    createdAt?: number | null;
    lastLogin?: number | null;
    invitationDate?: number | null;
    reminderDate?: number | null;
    modifiedBy?: string | null;
    modifiedAt?: number | null;
    domain?: string | null;
    temporaryHigherRank?: boolean | null;
    entityLayerIds?: Array<string | null> | null;
    clickThrough?: boolean | null;
    clickThroughDate?: string | null;
    projectManagerEmail?: string | null;
    isGlobalParticipant?: boolean | null;
    onBoardingStatus?: MidMarketEnum | null;
    updatedAt: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateVersionsSubscription = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateVersionsSubscription = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteVersionsSubscription = {
  __typename: "Versions";
  id: string;
  data: string;
  type: string;
  targetId: string;
  assessmentId: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateDataStateSubscription = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateDataStateSubscription = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteDataStateSubscription = {
  __typename: "DataState";
  id: string;
  state: StateStatusEnum;
  parentId: string;
  message?: string | null;
  detail?: string | null;
  type?: StateTypeEnum | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateSmartMappingDetailSubscription = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnUpdateSmartMappingDetailSubscription = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnDeleteSmartMappingDetailSubscription = {
  __typename: "SmartMappingDetail";
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type OnCreateApiCallRegistrySubscription = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnUpdateApiCallRegistrySubscription = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnDeleteApiCallRegistrySubscription = {
  __typename: "ApiCallRegistry";
  id: string;
  callMaker: string;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnCreateBreachDataSubscription = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnUpdateBreachDataSubscription = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

export type OnDeleteBreachDataSubscription = {
  __typename: "BreachData";
  id: string;
  domain: string;
  breaches?: string | null;
  createdAt?: string | null;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateFakeData(entityId: string): Promise<boolean | null> {
    const statement = `mutation CreateFakeData($entityId: String!) {
        createFakeData(entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.createFakeData;
  }
  async CreateFrameworksNodes(
    input?: CreateFrameworksNodesInput
  ): Promise<CreateFrameworksNodesMutation> {
    const statement = `mutation CreateFrameworksNodes($input: CreateFrameworksNodesInput) {
        createFrameworksNodes(input: $input) {
          __typename
          ids
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworksNodesMutation>response.data.createFrameworksNodes;
  }
  async AddCognitoUser(payload: string): Promise<string | null> {
    const statement = `mutation AddCognitoUser($payload: String!) {
        addCognitoUser(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.addCognitoUser;
  }
  async DeleteCognitoUser(payload: string): Promise<string | null> {
    const statement = `mutation DeleteCognitoUser($payload: String!) {
        deleteCognitoUser(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.deleteCognitoUser;
  }
  async SendInviteEmail(payload: string): Promise<string | null> {
    const statement = `mutation SendInviteEmail($payload: String!) {
        sendInviteEmail(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.sendInviteEmail;
  }
  async SendReminderEmail(payload: string): Promise<string | null> {
    const statement = `mutation SendReminderEmail($payload: String!) {
        sendReminderEmail(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.sendReminderEmail;
  }
  async AddCognitoGroup(payload: string): Promise<string | null> {
    const statement = `mutation AddCognitoGroup($payload: String!) {
        addCognitoGroup(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.addCognitoGroup;
  }
  async AssignUser(payload: string): Promise<AssignUserMutation> {
    const statement = `mutation AssignUser($payload: String!) {
        assignUser(payload: $payload) {
          __typename
          createdAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          deletedAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignUserMutation>response.data.assignUser;
  }
  async AssignQuestion(payload: string): Promise<string | null> {
    const statement = `mutation AssignQuestion($payload: String!) {
        assignQuestion(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.assignQuestion;
  }
  async CalcAssessmentScore(
    assessmentId: string,
    framework: string
  ): Promise<string | null> {
    const statement = `mutation CalcAssessmentScore($assessmentId: String!, $framework: String!) {
        calcAssessmentScore(assessmentId: $assessmentId, framework: $framework)
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
      framework
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.calcAssessmentScore;
  }
  async AssignAssessment(payload: string): Promise<string | null> {
    const statement = `mutation AssignAssessment($payload: String!) {
        assignAssessment(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.assignAssessment;
  }
  async SaveIntegrationDetails(payload: string): Promise<string | null> {
    const statement = `mutation SaveIntegrationDetails($payload: String!) {
        saveIntegrationDetails(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.saveIntegrationDetails;
  }
  async IncrementQuestionnaireAnswer(
    id: string,
    value?: number
  ): Promise<IncrementQuestionnaireAnswerMutation> {
    const statement = `mutation IncrementQuestionnaireAnswer($id: String!, $value: Int) {
        incrementQuestionnaireAnswer(id: $id, value: $value) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    if (value) {
      gqlAPIServiceArguments.value = value;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncrementQuestionnaireAnswerMutation>(
      response.data.incrementQuestionnaireAnswer
    );
  }
  async RemoveUserAssignment(
    payload: string
  ): Promise<RemoveUserAssignmentMutation> {
    const statement = `mutation RemoveUserAssignment($payload: String!) {
        removeUserAssignment(payload: $payload) {
          __typename
          createdAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          deletedAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RemoveUserAssignmentMutation>response.data.removeUserAssignment;
  }
  async GenerateEntityData(payload: string): Promise<string | null> {
    const statement = `mutation GenerateEntityData($payload: String!) {
        generateEntityData(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.generateEntityData;
  }
  async SentSupportTicket(payload: string): Promise<string | null> {
    const statement = `mutation SentSupportTicket($payload: String!) {
        sentSupportTicket(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.sentSupportTicket;
  }
  async CreateDeleteTagAndQuestionData(
    payload: string
  ): Promise<string | null> {
    const statement = `mutation CreateDeleteTagAndQuestionData($payload: String!) {
        createDeleteTagAndQuestionData(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.createDeleteTagAndQuestionData;
  }
  async CalcGroupScores(payload: string): Promise<string | null> {
    const statement = `mutation CalcGroupScores($payload: String!) {
        calcGroupScores(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.calcGroupScores;
  }
  async HandleIntegrationDetails(payload: string): Promise<string | null> {
    const statement = `mutation HandleIntegrationDetails($payload: AWSJSON!) {
        handleIntegrationDetails(payload: $payload)
      }`;
    const gqlAPIServiceArguments: any = {
      payload
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.handleIntegrationDetails;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateTags(
    input: CreateTagsInput,
    condition?: ModelTagsConditionInput
  ): Promise<CreateTagsMutation> {
    const statement = `mutation CreateTags($input: CreateTagsInput!, $condition: ModelTagsConditionInput) {
        createTags(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTagsMutation>response.data.createTags;
  }
  async UpdateTags(
    input: UpdateTagsInput,
    condition?: ModelTagsConditionInput
  ): Promise<UpdateTagsMutation> {
    const statement = `mutation UpdateTags($input: UpdateTagsInput!, $condition: ModelTagsConditionInput) {
        updateTags(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTagsMutation>response.data.updateTags;
  }
  async DeleteTags(
    input: DeleteTagsInput,
    condition?: ModelTagsConditionInput
  ): Promise<DeleteTagsMutation> {
    const statement = `mutation DeleteTags($input: DeleteTagsInput!, $condition: ModelTagsConditionInput) {
        deleteTags(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTagsMutation>response.data.deleteTags;
  }
  async CreateRole(
    input: CreateRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<CreateRoleMutation> {
    const statement = `mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
        createRole(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRoleMutation>response.data.createRole;
  }
  async UpdateRole(
    input: UpdateRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<UpdateRoleMutation> {
    const statement = `mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
        updateRole(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRoleMutation>response.data.updateRole;
  }
  async DeleteRole(
    input: DeleteRoleInput,
    condition?: ModelRoleConditionInput
  ): Promise<DeleteRoleMutation> {
    const statement = `mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
        deleteRole(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRoleMutation>response.data.deleteRole;
  }
  async CreateRiskLineChart(
    input: CreateRiskLineChartInput,
    condition?: ModelRiskLineChartConditionInput
  ): Promise<CreateRiskLineChartMutation> {
    const statement = `mutation CreateRiskLineChart($input: CreateRiskLineChartInput!, $condition: ModelRiskLineChartConditionInput) {
        createRiskLineChart(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRiskLineChartMutation>response.data.createRiskLineChart;
  }
  async UpdateRiskLineChart(
    input: UpdateRiskLineChartInput,
    condition?: ModelRiskLineChartConditionInput
  ): Promise<UpdateRiskLineChartMutation> {
    const statement = `mutation UpdateRiskLineChart($input: UpdateRiskLineChartInput!, $condition: ModelRiskLineChartConditionInput) {
        updateRiskLineChart(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRiskLineChartMutation>response.data.updateRiskLineChart;
  }
  async DeleteRiskLineChart(
    input: DeleteRiskLineChartInput,
    condition?: ModelRiskLineChartConditionInput
  ): Promise<DeleteRiskLineChartMutation> {
    const statement = `mutation DeleteRiskLineChart($input: DeleteRiskLineChartInput!, $condition: ModelRiskLineChartConditionInput) {
        deleteRiskLineChart(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRiskLineChartMutation>response.data.deleteRiskLineChart;
  }
  async CreateGroup(
    input: CreateGroupInput,
    condition?: ModelGroupConditionInput
  ): Promise<CreateGroupMutation> {
    const statement = `mutation CreateGroup($input: CreateGroupInput!, $condition: ModelGroupConditionInput) {
        createGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateGroupMutation>response.data.createGroup;
  }
  async UpdateGroup(
    input: UpdateGroupInput,
    condition?: ModelGroupConditionInput
  ): Promise<UpdateGroupMutation> {
    const statement = `mutation UpdateGroup($input: UpdateGroupInput!, $condition: ModelGroupConditionInput) {
        updateGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateGroupMutation>response.data.updateGroup;
  }
  async DeleteGroup(
    input: DeleteGroupInput,
    condition?: ModelGroupConditionInput
  ): Promise<DeleteGroupMutation> {
    const statement = `mutation DeleteGroup($input: DeleteGroupInput!, $condition: ModelGroupConditionInput) {
        deleteGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteGroupMutation>response.data.deleteGroup;
  }
  async CreateQuestionData(
    input: CreateQuestionDataInput,
    condition?: ModelQuestionDataConditionInput
  ): Promise<CreateQuestionDataMutation> {
    const statement = `mutation CreateQuestionData($input: CreateQuestionDataInput!, $condition: ModelQuestionDataConditionInput) {
        createQuestionData(input: $input, condition: $condition) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionDataMutation>response.data.createQuestionData;
  }
  async UpdateQuestionData(
    input: UpdateQuestionDataInput,
    condition?: ModelQuestionDataConditionInput
  ): Promise<UpdateQuestionDataMutation> {
    const statement = `mutation UpdateQuestionData($input: UpdateQuestionDataInput!, $condition: ModelQuestionDataConditionInput) {
        updateQuestionData(input: $input, condition: $condition) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionDataMutation>response.data.updateQuestionData;
  }
  async DeleteQuestionData(
    input: DeleteQuestionDataInput,
    condition?: ModelQuestionDataConditionInput
  ): Promise<DeleteQuestionDataMutation> {
    const statement = `mutation DeleteQuestionData($input: DeleteQuestionDataInput!, $condition: ModelQuestionDataConditionInput) {
        deleteQuestionData(input: $input, condition: $condition) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionDataMutation>response.data.deleteQuestionData;
  }
  async CreateArchivedUser(
    input: CreateArchivedUserInput,
    condition?: ModelArchivedUserConditionInput
  ): Promise<CreateArchivedUserMutation> {
    const statement = `mutation CreateArchivedUser($input: CreateArchivedUserInput!, $condition: ModelArchivedUserConditionInput) {
        createArchivedUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateArchivedUserMutation>response.data.createArchivedUser;
  }
  async UpdateArchivedUser(
    input: UpdateArchivedUserInput,
    condition?: ModelArchivedUserConditionInput
  ): Promise<UpdateArchivedUserMutation> {
    const statement = `mutation UpdateArchivedUser($input: UpdateArchivedUserInput!, $condition: ModelArchivedUserConditionInput) {
        updateArchivedUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateArchivedUserMutation>response.data.updateArchivedUser;
  }
  async DeleteArchivedUser(
    input: DeleteArchivedUserInput,
    condition?: ModelArchivedUserConditionInput
  ): Promise<DeleteArchivedUserMutation> {
    const statement = `mutation DeleteArchivedUser($input: DeleteArchivedUserInput!, $condition: ModelArchivedUserConditionInput) {
        deleteArchivedUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteArchivedUserMutation>response.data.deleteArchivedUser;
  }
  async CreateEntity(
    input: CreateEntityInput,
    condition?: ModelEntityConditionInput
  ): Promise<CreateEntityMutation> {
    const statement = `mutation CreateEntity($input: CreateEntityInput!, $condition: ModelEntityConditionInput) {
        createEntity(input: $input, condition: $condition) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEntityMutation>response.data.createEntity;
  }
  async UpdateEntity(
    input: UpdateEntityInput,
    condition?: ModelEntityConditionInput
  ): Promise<UpdateEntityMutation> {
    const statement = `mutation UpdateEntity($input: UpdateEntityInput!, $condition: ModelEntityConditionInput) {
        updateEntity(input: $input, condition: $condition) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEntityMutation>response.data.updateEntity;
  }
  async DeleteEntity(
    input: DeleteEntityInput,
    condition?: ModelEntityConditionInput
  ): Promise<DeleteEntityMutation> {
    const statement = `mutation DeleteEntity($input: DeleteEntityInput!, $condition: ModelEntityConditionInput) {
        deleteEntity(input: $input, condition: $condition) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEntityMutation>response.data.deleteEntity;
  }
  async CreateGraph(
    input: CreateGraphInput,
    condition?: ModelGraphConditionInput
  ): Promise<CreateGraphMutation> {
    const statement = `mutation CreateGraph($input: CreateGraphInput!, $condition: ModelGraphConditionInput) {
        createGraph(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateGraphMutation>response.data.createGraph;
  }
  async UpdateGraph(
    input: UpdateGraphInput,
    condition?: ModelGraphConditionInput
  ): Promise<UpdateGraphMutation> {
    const statement = `mutation UpdateGraph($input: UpdateGraphInput!, $condition: ModelGraphConditionInput) {
        updateGraph(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateGraphMutation>response.data.updateGraph;
  }
  async DeleteGraph(
    input: DeleteGraphInput,
    condition?: ModelGraphConditionInput
  ): Promise<DeleteGraphMutation> {
    const statement = `mutation DeleteGraph($input: DeleteGraphInput!, $condition: ModelGraphConditionInput) {
        deleteGraph(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteGraphMutation>response.data.deleteGraph;
  }
  async CreateRevision(
    input: CreateRevisionInput,
    condition?: ModelRevisionConditionInput
  ): Promise<CreateRevisionMutation> {
    const statement = `mutation CreateRevision($input: CreateRevisionInput!, $condition: ModelRevisionConditionInput) {
        createRevision(input: $input, condition: $condition) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRevisionMutation>response.data.createRevision;
  }
  async UpdateRevision(
    input: UpdateRevisionInput,
    condition?: ModelRevisionConditionInput
  ): Promise<UpdateRevisionMutation> {
    const statement = `mutation UpdateRevision($input: UpdateRevisionInput!, $condition: ModelRevisionConditionInput) {
        updateRevision(input: $input, condition: $condition) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRevisionMutation>response.data.updateRevision;
  }
  async DeleteRevision(
    input: DeleteRevisionInput,
    condition?: ModelRevisionConditionInput
  ): Promise<DeleteRevisionMutation> {
    const statement = `mutation DeleteRevision($input: DeleteRevisionInput!, $condition: ModelRevisionConditionInput) {
        deleteRevision(input: $input, condition: $condition) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRevisionMutation>response.data.deleteRevision;
  }
  async CreateVendorsDetail(
    input: CreateVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<CreateVendorsDetailMutation> {
    const statement = `mutation CreateVendorsDetail($input: CreateVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        createVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVendorsDetailMutation>response.data.createVendorsDetail;
  }
  async UpdateVendorsDetail(
    input: UpdateVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<UpdateVendorsDetailMutation> {
    const statement = `mutation UpdateVendorsDetail($input: UpdateVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        updateVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVendorsDetailMutation>response.data.updateVendorsDetail;
  }
  async DeleteVendorsDetail(
    input: DeleteVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<DeleteVendorsDetailMutation> {
    const statement = `mutation DeleteVendorsDetail($input: DeleteVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        deleteVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVendorsDetailMutation>response.data.deleteVendorsDetail;
  }
  async CreateFrameworkScores(
    input: CreateFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<CreateFrameworkScoresMutation> {
    const statement = `mutation CreateFrameworkScores($input: CreateFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        createFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworkScoresMutation>response.data.createFrameworkScores;
  }
  async UpdateFrameworkScores(
    input: UpdateFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<UpdateFrameworkScoresMutation> {
    const statement = `mutation UpdateFrameworkScores($input: UpdateFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        updateFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFrameworkScoresMutation>response.data.updateFrameworkScores;
  }
  async DeleteFrameworkScores(
    input: DeleteFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<DeleteFrameworkScoresMutation> {
    const statement = `mutation DeleteFrameworkScores($input: DeleteFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        deleteFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFrameworkScoresMutation>response.data.deleteFrameworkScores;
  }
  async CreateIntelligence(
    input: CreateIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<CreateIntelligenceMutation> {
    const statement = `mutation CreateIntelligence($input: CreateIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        createIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIntelligenceMutation>response.data.createIntelligence;
  }
  async UpdateIntelligence(
    input: UpdateIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<UpdateIntelligenceMutation> {
    const statement = `mutation UpdateIntelligence($input: UpdateIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        updateIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIntelligenceMutation>response.data.updateIntelligence;
  }
  async DeleteIntelligence(
    input: DeleteIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<DeleteIntelligenceMutation> {
    const statement = `mutation DeleteIntelligence($input: DeleteIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        deleteIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIntelligenceMutation>response.data.deleteIntelligence;
  }
  async CreateBreaches(
    input: CreateBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<CreateBreachesMutation> {
    const statement = `mutation CreateBreaches($input: CreateBreachesInput!, $condition: ModelBreachesConditionInput) {
        createBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBreachesMutation>response.data.createBreaches;
  }
  async UpdateBreaches(
    input: UpdateBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<UpdateBreachesMutation> {
    const statement = `mutation UpdateBreaches($input: UpdateBreachesInput!, $condition: ModelBreachesConditionInput) {
        updateBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBreachesMutation>response.data.updateBreaches;
  }
  async DeleteBreaches(
    input: DeleteBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<DeleteBreachesMutation> {
    const statement = `mutation DeleteBreaches($input: DeleteBreachesInput!, $condition: ModelBreachesConditionInput) {
        deleteBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBreachesMutation>response.data.deleteBreaches;
  }
  async CreateExternalThreats(
    input: CreateExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<CreateExternalThreatsMutation> {
    const statement = `mutation CreateExternalThreats($input: CreateExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
        createExternalThreats(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExternalThreatsMutation>response.data.createExternalThreats;
  }
  async UpdateExternalThreats(
    input: UpdateExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<UpdateExternalThreatsMutation> {
    const statement = `mutation UpdateExternalThreats($input: UpdateExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
        updateExternalThreats(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExternalThreatsMutation>response.data.updateExternalThreats;
  }
  async DeleteExternalThreats(
    input: DeleteExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<DeleteExternalThreatsMutation> {
    const statement = `mutation DeleteExternalThreats($input: DeleteExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
        deleteExternalThreats(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExternalThreatsMutation>response.data.deleteExternalThreats;
  }
  async CreateOthers(
    input: CreateOthersInput,
    condition?: ModelOthersConditionInput
  ): Promise<CreateOthersMutation> {
    const statement = `mutation CreateOthers($input: CreateOthersInput!, $condition: ModelOthersConditionInput) {
        createOthers(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOthersMutation>response.data.createOthers;
  }
  async UpdateOthers(
    input: UpdateOthersInput,
    condition?: ModelOthersConditionInput
  ): Promise<UpdateOthersMutation> {
    const statement = `mutation UpdateOthers($input: UpdateOthersInput!, $condition: ModelOthersConditionInput) {
        updateOthers(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOthersMutation>response.data.updateOthers;
  }
  async DeleteOthers(
    input: DeleteOthersInput,
    condition?: ModelOthersConditionInput
  ): Promise<DeleteOthersMutation> {
    const statement = `mutation DeleteOthers($input: DeleteOthersInput!, $condition: ModelOthersConditionInput) {
        deleteOthers(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOthersMutation>response.data.deleteOthers;
  }
  async CreateQuestionnaireCompletion(
    input: CreateQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<CreateQuestionnaireCompletionMutation> {
    const statement = `mutation CreateQuestionnaireCompletion($input: CreateQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
        createQuestionnaireCompletion(input: $input, condition: $condition) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionnaireCompletionMutation>(
      response.data.createQuestionnaireCompletion
    );
  }
  async UpdateQuestionnaireCompletion(
    input: UpdateQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<UpdateQuestionnaireCompletionMutation> {
    const statement = `mutation UpdateQuestionnaireCompletion($input: UpdateQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
        updateQuestionnaireCompletion(input: $input, condition: $condition) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionnaireCompletionMutation>(
      response.data.updateQuestionnaireCompletion
    );
  }
  async DeleteQuestionnaireCompletion(
    input: DeleteQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<DeleteQuestionnaireCompletionMutation> {
    const statement = `mutation DeleteQuestionnaireCompletion($input: DeleteQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
        deleteQuestionnaireCompletion(input: $input, condition: $condition) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionnaireCompletionMutation>(
      response.data.deleteQuestionnaireCompletion
    );
  }
  async CreateAnswer(
    input: CreateAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<CreateAnswerMutation> {
    const statement = `mutation CreateAnswer($input: CreateAnswerInput!, $condition: ModelAnswerConditionInput) {
        createAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnswerMutation>response.data.createAnswer;
  }
  async UpdateAnswer(
    input: UpdateAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<UpdateAnswerMutation> {
    const statement = `mutation UpdateAnswer($input: UpdateAnswerInput!, $condition: ModelAnswerConditionInput) {
        updateAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnswerMutation>response.data.updateAnswer;
  }
  async DeleteAnswer(
    input: DeleteAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<DeleteAnswerMutation> {
    const statement = `mutation DeleteAnswer($input: DeleteAnswerInput!, $condition: ModelAnswerConditionInput) {
        deleteAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnswerMutation>response.data.deleteAnswer;
  }
  async CreateAssignment(
    input: CreateAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<CreateAssignmentMutation> {
    const statement = `mutation CreateAssignment($input: CreateAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        createAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssignmentMutation>response.data.createAssignment;
  }
  async UpdateAssignment(
    input: UpdateAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<UpdateAssignmentMutation> {
    const statement = `mutation UpdateAssignment($input: UpdateAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        updateAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssignmentMutation>response.data.updateAssignment;
  }
  async DeleteAssignment(
    input: DeleteAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<DeleteAssignmentMutation> {
    const statement = `mutation DeleteAssignment($input: DeleteAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        deleteAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssignmentMutation>response.data.deleteAssignment;
  }
  async CreateDisableAssignment(
    input: CreateDisableAssignmentInput,
    condition?: ModelDisableAssignmentConditionInput
  ): Promise<CreateDisableAssignmentMutation> {
    const statement = `mutation CreateDisableAssignment($input: CreateDisableAssignmentInput!, $condition: ModelDisableAssignmentConditionInput) {
        createDisableAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDisableAssignmentMutation>(
      response.data.createDisableAssignment
    );
  }
  async UpdateDisableAssignment(
    input: UpdateDisableAssignmentInput,
    condition?: ModelDisableAssignmentConditionInput
  ): Promise<UpdateDisableAssignmentMutation> {
    const statement = `mutation UpdateDisableAssignment($input: UpdateDisableAssignmentInput!, $condition: ModelDisableAssignmentConditionInput) {
        updateDisableAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDisableAssignmentMutation>(
      response.data.updateDisableAssignment
    );
  }
  async DeleteDisableAssignment(
    input: DeleteDisableAssignmentInput,
    condition?: ModelDisableAssignmentConditionInput
  ): Promise<DeleteDisableAssignmentMutation> {
    const statement = `mutation DeleteDisableAssignment($input: DeleteDisableAssignmentInput!, $condition: ModelDisableAssignmentConditionInput) {
        deleteDisableAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDisableAssignmentMutation>(
      response.data.deleteDisableAssignment
    );
  }
  async CreateAssessment(
    input: CreateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<CreateAssessmentMutation> {
    const statement = `mutation CreateAssessment($input: CreateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        createAssessment(input: $input, condition: $condition) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssessmentMutation>response.data.createAssessment;
  }
  async UpdateAssessment(
    input: UpdateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<UpdateAssessmentMutation> {
    const statement = `mutation UpdateAssessment($input: UpdateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        updateAssessment(input: $input, condition: $condition) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssessmentMutation>response.data.updateAssessment;
  }
  async DeleteAssessment(
    input: DeleteAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<DeleteAssessmentMutation> {
    const statement = `mutation DeleteAssessment($input: DeleteAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        deleteAssessment(input: $input, condition: $condition) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssessmentMutation>response.data.deleteAssessment;
  }
  async CreateIntegrationMappings(
    input: CreateIntegrationMappingsInput,
    condition?: ModelIntegrationMappingsConditionInput
  ): Promise<CreateIntegrationMappingsMutation> {
    const statement = `mutation CreateIntegrationMappings($input: CreateIntegrationMappingsInput!, $condition: ModelIntegrationMappingsConditionInput) {
        createIntegrationMappings(input: $input, condition: $condition) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIntegrationMappingsMutation>(
      response.data.createIntegrationMappings
    );
  }
  async UpdateIntegrationMappings(
    input: UpdateIntegrationMappingsInput,
    condition?: ModelIntegrationMappingsConditionInput
  ): Promise<UpdateIntegrationMappingsMutation> {
    const statement = `mutation UpdateIntegrationMappings($input: UpdateIntegrationMappingsInput!, $condition: ModelIntegrationMappingsConditionInput) {
        updateIntegrationMappings(input: $input, condition: $condition) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIntegrationMappingsMutation>(
      response.data.updateIntegrationMappings
    );
  }
  async DeleteIntegrationMappings(
    input: DeleteIntegrationMappingsInput,
    condition?: ModelIntegrationMappingsConditionInput
  ): Promise<DeleteIntegrationMappingsMutation> {
    const statement = `mutation DeleteIntegrationMappings($input: DeleteIntegrationMappingsInput!, $condition: ModelIntegrationMappingsConditionInput) {
        deleteIntegrationMappings(input: $input, condition: $condition) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIntegrationMappingsMutation>(
      response.data.deleteIntegrationMappings
    );
  }
  async CreateQuestionSettings(
    input: CreateQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<CreateQuestionSettingsMutation> {
    const statement = `mutation CreateQuestionSettings($input: CreateQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        createQuestionSettings(input: $input, condition: $condition) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionSettingsMutation>response.data.createQuestionSettings;
  }
  async UpdateQuestionSettings(
    input: UpdateQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<UpdateQuestionSettingsMutation> {
    const statement = `mutation UpdateQuestionSettings($input: UpdateQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        updateQuestionSettings(input: $input, condition: $condition) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionSettingsMutation>response.data.updateQuestionSettings;
  }
  async DeleteQuestionSettings(
    input: DeleteQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<DeleteQuestionSettingsMutation> {
    const statement = `mutation DeleteQuestionSettings($input: DeleteQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        deleteQuestionSettings(input: $input, condition: $condition) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionSettingsMutation>response.data.deleteQuestionSettings;
  }
  async CreateTaskAction(
    input: CreateTaskActionInput,
    condition?: ModelTaskActionConditionInput
  ): Promise<CreateTaskActionMutation> {
    const statement = `mutation CreateTaskAction($input: CreateTaskActionInput!, $condition: ModelTaskActionConditionInput) {
        createTaskAction(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaskActionMutation>response.data.createTaskAction;
  }
  async UpdateTaskAction(
    input: UpdateTaskActionInput,
    condition?: ModelTaskActionConditionInput
  ): Promise<UpdateTaskActionMutation> {
    const statement = `mutation UpdateTaskAction($input: UpdateTaskActionInput!, $condition: ModelTaskActionConditionInput) {
        updateTaskAction(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaskActionMutation>response.data.updateTaskAction;
  }
  async DeleteTaskAction(
    input: DeleteTaskActionInput,
    condition?: ModelTaskActionConditionInput
  ): Promise<DeleteTaskActionMutation> {
    const statement = `mutation DeleteTaskAction($input: DeleteTaskActionInput!, $condition: ModelTaskActionConditionInput) {
        deleteTaskAction(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaskActionMutation>response.data.deleteTaskAction;
  }
  async CreateUsersAssignmentMapper(
    input: CreateUsersAssignmentMapperInput,
    condition?: ModelUsersAssignmentMapperConditionInput
  ): Promise<CreateUsersAssignmentMapperMutation> {
    const statement = `mutation CreateUsersAssignmentMapper($input: CreateUsersAssignmentMapperInput!, $condition: ModelUsersAssignmentMapperConditionInput) {
        createUsersAssignmentMapper(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUsersAssignmentMapperMutation>(
      response.data.createUsersAssignmentMapper
    );
  }
  async UpdateUsersAssignmentMapper(
    input: UpdateUsersAssignmentMapperInput,
    condition?: ModelUsersAssignmentMapperConditionInput
  ): Promise<UpdateUsersAssignmentMapperMutation> {
    const statement = `mutation UpdateUsersAssignmentMapper($input: UpdateUsersAssignmentMapperInput!, $condition: ModelUsersAssignmentMapperConditionInput) {
        updateUsersAssignmentMapper(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUsersAssignmentMapperMutation>(
      response.data.updateUsersAssignmentMapper
    );
  }
  async DeleteUsersAssignmentMapper(
    input: DeleteUsersAssignmentMapperInput,
    condition?: ModelUsersAssignmentMapperConditionInput
  ): Promise<DeleteUsersAssignmentMapperMutation> {
    const statement = `mutation DeleteUsersAssignmentMapper($input: DeleteUsersAssignmentMapperInput!, $condition: ModelUsersAssignmentMapperConditionInput) {
        deleteUsersAssignmentMapper(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUsersAssignmentMapperMutation>(
      response.data.deleteUsersAssignmentMapper
    );
  }
  async CreateFrameworkSettings(
    input: CreateFrameworkSettingsInput,
    condition?: ModelFrameworkSettingsConditionInput
  ): Promise<CreateFrameworkSettingsMutation> {
    const statement = `mutation CreateFrameworkSettings($input: CreateFrameworkSettingsInput!, $condition: ModelFrameworkSettingsConditionInput) {
        createFrameworkSettings(input: $input, condition: $condition) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworkSettingsMutation>(
      response.data.createFrameworkSettings
    );
  }
  async UpdateFrameworkSettings(
    input: UpdateFrameworkSettingsInput,
    condition?: ModelFrameworkSettingsConditionInput
  ): Promise<UpdateFrameworkSettingsMutation> {
    const statement = `mutation UpdateFrameworkSettings($input: UpdateFrameworkSettingsInput!, $condition: ModelFrameworkSettingsConditionInput) {
        updateFrameworkSettings(input: $input, condition: $condition) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFrameworkSettingsMutation>(
      response.data.updateFrameworkSettings
    );
  }
  async DeleteFrameworkSettings(
    input: DeleteFrameworkSettingsInput,
    condition?: ModelFrameworkSettingsConditionInput
  ): Promise<DeleteFrameworkSettingsMutation> {
    const statement = `mutation DeleteFrameworkSettings($input: DeleteFrameworkSettingsInput!, $condition: ModelFrameworkSettingsConditionInput) {
        deleteFrameworkSettings(input: $input, condition: $condition) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFrameworkSettingsMutation>(
      response.data.deleteFrameworkSettings
    );
  }
  async CreateCustomTask(
    input: CreateCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<CreateCustomTaskMutation> {
    const statement = `mutation CreateCustomTask($input: CreateCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        createCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomTaskMutation>response.data.createCustomTask;
  }
  async UpdateCustomTask(
    input: UpdateCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<UpdateCustomTaskMutation> {
    const statement = `mutation UpdateCustomTask($input: UpdateCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        updateCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomTaskMutation>response.data.updateCustomTask;
  }
  async DeleteCustomTask(
    input: DeleteCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<DeleteCustomTaskMutation> {
    const statement = `mutation DeleteCustomTask($input: DeleteCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        deleteCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomTaskMutation>response.data.deleteCustomTask;
  }
  async CreateCustomTaskAssignment(
    input: CreateCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<CreateCustomTaskAssignmentMutation> {
    const statement = `mutation CreateCustomTaskAssignment($input: CreateCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
        createCustomTaskAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomTaskAssignmentMutation>(
      response.data.createCustomTaskAssignment
    );
  }
  async UpdateCustomTaskAssignment(
    input: UpdateCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<UpdateCustomTaskAssignmentMutation> {
    const statement = `mutation UpdateCustomTaskAssignment($input: UpdateCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
        updateCustomTaskAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomTaskAssignmentMutation>(
      response.data.updateCustomTaskAssignment
    );
  }
  async DeleteCustomTaskAssignment(
    input: DeleteCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<DeleteCustomTaskAssignmentMutation> {
    const statement = `mutation DeleteCustomTaskAssignment($input: DeleteCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
        deleteCustomTaskAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomTaskAssignmentMutation>(
      response.data.deleteCustomTaskAssignment
    );
  }
  async CreateAssessmentStandardFramework(
    input: CreateAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<CreateAssessmentStandardFrameworkMutation> {
    const statement = `mutation CreateAssessmentStandardFramework($input: CreateAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        createAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssessmentStandardFrameworkMutation>(
      response.data.createAssessmentStandardFramework
    );
  }
  async UpdateAssessmentStandardFramework(
    input: UpdateAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<UpdateAssessmentStandardFrameworkMutation> {
    const statement = `mutation UpdateAssessmentStandardFramework($input: UpdateAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        updateAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssessmentStandardFrameworkMutation>(
      response.data.updateAssessmentStandardFramework
    );
  }
  async DeleteAssessmentStandardFramework(
    input: DeleteAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<DeleteAssessmentStandardFrameworkMutation> {
    const statement = `mutation DeleteAssessmentStandardFramework($input: DeleteAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        deleteAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssessmentStandardFrameworkMutation>(
      response.data.deleteAssessmentStandardFramework
    );
  }
  async CreateFrameworkManager(
    input: CreateFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<CreateFrameworkManagerMutation> {
    const statement = `mutation CreateFrameworkManager($input: CreateFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
        createFrameworkManager(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworkManagerMutation>response.data.createFrameworkManager;
  }
  async UpdateFrameworkManager(
    input: UpdateFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<UpdateFrameworkManagerMutation> {
    const statement = `mutation UpdateFrameworkManager($input: UpdateFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
        updateFrameworkManager(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFrameworkManagerMutation>response.data.updateFrameworkManager;
  }
  async DeleteFrameworkManager(
    input: DeleteFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<DeleteFrameworkManagerMutation> {
    const statement = `mutation DeleteFrameworkManager($input: DeleteFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
        deleteFrameworkManager(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFrameworkManagerMutation>response.data.deleteFrameworkManager;
  }
  async CreateComment(
    input: CreateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<CreateCommentMutation> {
    const statement = `mutation CreateComment($input: CreateCommentInput!, $condition: ModelCommentConditionInput) {
        createComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCommentMutation>response.data.createComment;
  }
  async UpdateComment(
    input: UpdateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<UpdateCommentMutation> {
    const statement = `mutation UpdateComment($input: UpdateCommentInput!, $condition: ModelCommentConditionInput) {
        updateComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCommentMutation>response.data.updateComment;
  }
  async DeleteComment(
    input: DeleteCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<DeleteCommentMutation> {
    const statement = `mutation DeleteComment($input: DeleteCommentInput!, $condition: ModelCommentConditionInput) {
        deleteComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCommentMutation>response.data.deleteComment;
  }
  async CreateExport(
    input: CreateExportInput,
    condition?: ModelExportConditionInput
  ): Promise<CreateExportMutation> {
    const statement = `mutation CreateExport($input: CreateExportInput!, $condition: ModelExportConditionInput) {
        createExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExportMutation>response.data.createExport;
  }
  async UpdateExport(
    input: UpdateExportInput,
    condition?: ModelExportConditionInput
  ): Promise<UpdateExportMutation> {
    const statement = `mutation UpdateExport($input: UpdateExportInput!, $condition: ModelExportConditionInput) {
        updateExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExportMutation>response.data.updateExport;
  }
  async DeleteExport(
    input: DeleteExportInput,
    condition?: ModelExportConditionInput
  ): Promise<DeleteExportMutation> {
    const statement = `mutation DeleteExport($input: DeleteExportInput!, $condition: ModelExportConditionInput) {
        deleteExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExportMutation>response.data.deleteExport;
  }
  async CreateReport(
    input: CreateReportInput,
    condition?: ModelReportConditionInput
  ): Promise<CreateReportMutation> {
    const statement = `mutation CreateReport($input: CreateReportInput!, $condition: ModelReportConditionInput) {
        createReport(input: $input, condition: $condition) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateReportMutation>response.data.createReport;
  }
  async UpdateReport(
    input: UpdateReportInput,
    condition?: ModelReportConditionInput
  ): Promise<UpdateReportMutation> {
    const statement = `mutation UpdateReport($input: UpdateReportInput!, $condition: ModelReportConditionInput) {
        updateReport(input: $input, condition: $condition) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateReportMutation>response.data.updateReport;
  }
  async DeleteReport(
    input: DeleteReportInput,
    condition?: ModelReportConditionInput
  ): Promise<DeleteReportMutation> {
    const statement = `mutation DeleteReport($input: DeleteReportInput!, $condition: ModelReportConditionInput) {
        deleteReport(input: $input, condition: $condition) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteReportMutation>response.data.deleteReport;
  }
  async CreateRisk(
    input: CreateRiskInput,
    condition?: ModelRiskConditionInput
  ): Promise<CreateRiskMutation> {
    const statement = `mutation CreateRisk($input: CreateRiskInput!, $condition: ModelRiskConditionInput) {
        createRisk(input: $input, condition: $condition) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRiskMutation>response.data.createRisk;
  }
  async UpdateRisk(
    input: UpdateRiskInput,
    condition?: ModelRiskConditionInput
  ): Promise<UpdateRiskMutation> {
    const statement = `mutation UpdateRisk($input: UpdateRiskInput!, $condition: ModelRiskConditionInput) {
        updateRisk(input: $input, condition: $condition) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRiskMutation>response.data.updateRisk;
  }
  async DeleteRisk(
    input: DeleteRiskInput,
    condition?: ModelRiskConditionInput
  ): Promise<DeleteRiskMutation> {
    const statement = `mutation DeleteRisk($input: DeleteRiskInput!, $condition: ModelRiskConditionInput) {
        deleteRisk(input: $input, condition: $condition) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRiskMutation>response.data.deleteRisk;
  }
  async CreateRiskOwnerAssignment(
    input: CreateRiskOwnerAssignmentInput,
    condition?: ModelRiskOwnerAssignmentConditionInput
  ): Promise<CreateRiskOwnerAssignmentMutation> {
    const statement = `mutation CreateRiskOwnerAssignment($input: CreateRiskOwnerAssignmentInput!, $condition: ModelRiskOwnerAssignmentConditionInput) {
        createRiskOwnerAssignment(input: $input, condition: $condition) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRiskOwnerAssignmentMutation>(
      response.data.createRiskOwnerAssignment
    );
  }
  async UpdateRiskOwnerAssignment(
    input: UpdateRiskOwnerAssignmentInput,
    condition?: ModelRiskOwnerAssignmentConditionInput
  ): Promise<UpdateRiskOwnerAssignmentMutation> {
    const statement = `mutation UpdateRiskOwnerAssignment($input: UpdateRiskOwnerAssignmentInput!, $condition: ModelRiskOwnerAssignmentConditionInput) {
        updateRiskOwnerAssignment(input: $input, condition: $condition) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRiskOwnerAssignmentMutation>(
      response.data.updateRiskOwnerAssignment
    );
  }
  async DeleteRiskOwnerAssignment(
    input: DeleteRiskOwnerAssignmentInput,
    condition?: ModelRiskOwnerAssignmentConditionInput
  ): Promise<DeleteRiskOwnerAssignmentMutation> {
    const statement = `mutation DeleteRiskOwnerAssignment($input: DeleteRiskOwnerAssignmentInput!, $condition: ModelRiskOwnerAssignmentConditionInput) {
        deleteRiskOwnerAssignment(input: $input, condition: $condition) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRiskOwnerAssignmentMutation>(
      response.data.deleteRiskOwnerAssignment
    );
  }
  async CreateSavedFilter(
    input: CreateSavedFilterInput,
    condition?: ModelSavedFilterConditionInput
  ): Promise<CreateSavedFilterMutation> {
    const statement = `mutation CreateSavedFilter($input: CreateSavedFilterInput!, $condition: ModelSavedFilterConditionInput) {
        createSavedFilter(input: $input, condition: $condition) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSavedFilterMutation>response.data.createSavedFilter;
  }
  async UpdateSavedFilter(
    input: UpdateSavedFilterInput,
    condition?: ModelSavedFilterConditionInput
  ): Promise<UpdateSavedFilterMutation> {
    const statement = `mutation UpdateSavedFilter($input: UpdateSavedFilterInput!, $condition: ModelSavedFilterConditionInput) {
        updateSavedFilter(input: $input, condition: $condition) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSavedFilterMutation>response.data.updateSavedFilter;
  }
  async DeleteSavedFilter(
    input: DeleteSavedFilterInput,
    condition?: ModelSavedFilterConditionInput
  ): Promise<DeleteSavedFilterMutation> {
    const statement = `mutation DeleteSavedFilter($input: DeleteSavedFilterInput!, $condition: ModelSavedFilterConditionInput) {
        deleteSavedFilter(input: $input, condition: $condition) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSavedFilterMutation>response.data.deleteSavedFilter;
  }
  async CreateDomainSettings(
    input: CreateDomainSettingsInput,
    condition?: ModelDomainSettingsConditionInput
  ): Promise<CreateDomainSettingsMutation> {
    const statement = `mutation CreateDomainSettings($input: CreateDomainSettingsInput!, $condition: ModelDomainSettingsConditionInput) {
        createDomainSettings(input: $input, condition: $condition) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDomainSettingsMutation>response.data.createDomainSettings;
  }
  async UpdateDomainSettings(
    input: UpdateDomainSettingsInput,
    condition?: ModelDomainSettingsConditionInput
  ): Promise<UpdateDomainSettingsMutation> {
    const statement = `mutation UpdateDomainSettings($input: UpdateDomainSettingsInput!, $condition: ModelDomainSettingsConditionInput) {
        updateDomainSettings(input: $input, condition: $condition) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDomainSettingsMutation>response.data.updateDomainSettings;
  }
  async DeleteDomainSettings(
    input: DeleteDomainSettingsInput,
    condition?: ModelDomainSettingsConditionInput
  ): Promise<DeleteDomainSettingsMutation> {
    const statement = `mutation DeleteDomainSettings($input: DeleteDomainSettingsInput!, $condition: ModelDomainSettingsConditionInput) {
        deleteDomainSettings(input: $input, condition: $condition) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDomainSettingsMutation>response.data.deleteDomainSettings;
  }
  async CreateExecutiveSummary(
    input: CreateExecutiveSummaryInput,
    condition?: ModelExecutiveSummaryConditionInput
  ): Promise<CreateExecutiveSummaryMutation> {
    const statement = `mutation CreateExecutiveSummary($input: CreateExecutiveSummaryInput!, $condition: ModelExecutiveSummaryConditionInput) {
        createExecutiveSummary(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExecutiveSummaryMutation>response.data.createExecutiveSummary;
  }
  async UpdateExecutiveSummary(
    input: UpdateExecutiveSummaryInput,
    condition?: ModelExecutiveSummaryConditionInput
  ): Promise<UpdateExecutiveSummaryMutation> {
    const statement = `mutation UpdateExecutiveSummary($input: UpdateExecutiveSummaryInput!, $condition: ModelExecutiveSummaryConditionInput) {
        updateExecutiveSummary(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExecutiveSummaryMutation>response.data.updateExecutiveSummary;
  }
  async DeleteExecutiveSummary(
    input: DeleteExecutiveSummaryInput,
    condition?: ModelExecutiveSummaryConditionInput
  ): Promise<DeleteExecutiveSummaryMutation> {
    const statement = `mutation DeleteExecutiveSummary($input: DeleteExecutiveSummaryInput!, $condition: ModelExecutiveSummaryConditionInput) {
        deleteExecutiveSummary(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExecutiveSummaryMutation>response.data.deleteExecutiveSummary;
  }
  async CreateNetskopeProject(
    input: CreateNetskopeProjectInput,
    condition?: ModelNetskopeProjectConditionInput
  ): Promise<CreateNetskopeProjectMutation> {
    const statement = `mutation CreateNetskopeProject($input: CreateNetskopeProjectInput!, $condition: ModelNetskopeProjectConditionInput) {
        createNetskopeProject(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNetskopeProjectMutation>response.data.createNetskopeProject;
  }
  async UpdateNetskopeProject(
    input: UpdateNetskopeProjectInput,
    condition?: ModelNetskopeProjectConditionInput
  ): Promise<UpdateNetskopeProjectMutation> {
    const statement = `mutation UpdateNetskopeProject($input: UpdateNetskopeProjectInput!, $condition: ModelNetskopeProjectConditionInput) {
        updateNetskopeProject(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNetskopeProjectMutation>response.data.updateNetskopeProject;
  }
  async DeleteNetskopeProject(
    input: DeleteNetskopeProjectInput,
    condition?: ModelNetskopeProjectConditionInput
  ): Promise<DeleteNetskopeProjectMutation> {
    const statement = `mutation DeleteNetskopeProject($input: DeleteNetskopeProjectInput!, $condition: ModelNetskopeProjectConditionInput) {
        deleteNetskopeProject(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNetskopeProjectMutation>response.data.deleteNetskopeProject;
  }
  async CreateEntityLayers(
    input: CreateEntityLayersInput,
    condition?: ModelEntityLayersConditionInput
  ): Promise<CreateEntityLayersMutation> {
    const statement = `mutation CreateEntityLayers($input: CreateEntityLayersInput!, $condition: ModelEntityLayersConditionInput) {
        createEntityLayers(input: $input, condition: $condition) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEntityLayersMutation>response.data.createEntityLayers;
  }
  async UpdateEntityLayers(
    input: UpdateEntityLayersInput,
    condition?: ModelEntityLayersConditionInput
  ): Promise<UpdateEntityLayersMutation> {
    const statement = `mutation UpdateEntityLayers($input: UpdateEntityLayersInput!, $condition: ModelEntityLayersConditionInput) {
        updateEntityLayers(input: $input, condition: $condition) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEntityLayersMutation>response.data.updateEntityLayers;
  }
  async DeleteEntityLayers(
    input: DeleteEntityLayersInput,
    condition?: ModelEntityLayersConditionInput
  ): Promise<DeleteEntityLayersMutation> {
    const statement = `mutation DeleteEntityLayers($input: DeleteEntityLayersInput!, $condition: ModelEntityLayersConditionInput) {
        deleteEntityLayers(input: $input, condition: $condition) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEntityLayersMutation>response.data.deleteEntityLayers;
  }
  async CreateEntityWizard(
    input: CreateEntityWizardInput,
    condition?: ModelEntityWizardConditionInput
  ): Promise<CreateEntityWizardMutation> {
    const statement = `mutation CreateEntityWizard($input: CreateEntityWizardInput!, $condition: ModelEntityWizardConditionInput) {
        createEntityWizard(input: $input, condition: $condition) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEntityWizardMutation>response.data.createEntityWizard;
  }
  async UpdateEntityWizard(
    input: UpdateEntityWizardInput,
    condition?: ModelEntityWizardConditionInput
  ): Promise<UpdateEntityWizardMutation> {
    const statement = `mutation UpdateEntityWizard($input: UpdateEntityWizardInput!, $condition: ModelEntityWizardConditionInput) {
        updateEntityWizard(input: $input, condition: $condition) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEntityWizardMutation>response.data.updateEntityWizard;
  }
  async DeleteEntityWizard(
    input: DeleteEntityWizardInput,
    condition?: ModelEntityWizardConditionInput
  ): Promise<DeleteEntityWizardMutation> {
    const statement = `mutation DeleteEntityWizard($input: DeleteEntityWizardInput!, $condition: ModelEntityWizardConditionInput) {
        deleteEntityWizard(input: $input, condition: $condition) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEntityWizardMutation>response.data.deleteEntityWizard;
  }
  async CreateLogs(
    input: CreateLogsInput,
    condition?: ModelLogsConditionInput
  ): Promise<CreateLogsMutation> {
    const statement = `mutation CreateLogs($input: CreateLogsInput!, $condition: ModelLogsConditionInput) {
        createLogs(input: $input, condition: $condition) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLogsMutation>response.data.createLogs;
  }
  async UpdateLogs(
    input: UpdateLogsInput,
    condition?: ModelLogsConditionInput
  ): Promise<UpdateLogsMutation> {
    const statement = `mutation UpdateLogs($input: UpdateLogsInput!, $condition: ModelLogsConditionInput) {
        updateLogs(input: $input, condition: $condition) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLogsMutation>response.data.updateLogs;
  }
  async DeleteLogs(
    input: DeleteLogsInput,
    condition?: ModelLogsConditionInput
  ): Promise<DeleteLogsMutation> {
    const statement = `mutation DeleteLogs($input: DeleteLogsInput!, $condition: ModelLogsConditionInput) {
        deleteLogs(input: $input, condition: $condition) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLogsMutation>response.data.deleteLogs;
  }
  async CreateVersions(
    input: CreateVersionsInput,
    condition?: ModelVersionsConditionInput
  ): Promise<CreateVersionsMutation> {
    const statement = `mutation CreateVersions($input: CreateVersionsInput!, $condition: ModelVersionsConditionInput) {
        createVersions(input: $input, condition: $condition) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVersionsMutation>response.data.createVersions;
  }
  async UpdateVersions(
    input: UpdateVersionsInput,
    condition?: ModelVersionsConditionInput
  ): Promise<UpdateVersionsMutation> {
    const statement = `mutation UpdateVersions($input: UpdateVersionsInput!, $condition: ModelVersionsConditionInput) {
        updateVersions(input: $input, condition: $condition) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVersionsMutation>response.data.updateVersions;
  }
  async DeleteVersions(
    input: DeleteVersionsInput,
    condition?: ModelVersionsConditionInput
  ): Promise<DeleteVersionsMutation> {
    const statement = `mutation DeleteVersions($input: DeleteVersionsInput!, $condition: ModelVersionsConditionInput) {
        deleteVersions(input: $input, condition: $condition) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVersionsMutation>response.data.deleteVersions;
  }
  async CreateDataState(
    input: CreateDataStateInput,
    condition?: ModelDataStateConditionInput
  ): Promise<CreateDataStateMutation> {
    const statement = `mutation CreateDataState($input: CreateDataStateInput!, $condition: ModelDataStateConditionInput) {
        createDataState(input: $input, condition: $condition) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDataStateMutation>response.data.createDataState;
  }
  async UpdateDataState(
    input: UpdateDataStateInput,
    condition?: ModelDataStateConditionInput
  ): Promise<UpdateDataStateMutation> {
    const statement = `mutation UpdateDataState($input: UpdateDataStateInput!, $condition: ModelDataStateConditionInput) {
        updateDataState(input: $input, condition: $condition) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDataStateMutation>response.data.updateDataState;
  }
  async DeleteDataState(
    input: DeleteDataStateInput,
    condition?: ModelDataStateConditionInput
  ): Promise<DeleteDataStateMutation> {
    const statement = `mutation DeleteDataState($input: DeleteDataStateInput!, $condition: ModelDataStateConditionInput) {
        deleteDataState(input: $input, condition: $condition) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDataStateMutation>response.data.deleteDataState;
  }
  async CreateSmartMappingDetail(
    input: CreateSmartMappingDetailInput,
    condition?: ModelSmartMappingDetailConditionInput
  ): Promise<CreateSmartMappingDetailMutation> {
    const statement = `mutation CreateSmartMappingDetail($input: CreateSmartMappingDetailInput!, $condition: ModelSmartMappingDetailConditionInput) {
        createSmartMappingDetail(input: $input, condition: $condition) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSmartMappingDetailMutation>(
      response.data.createSmartMappingDetail
    );
  }
  async UpdateSmartMappingDetail(
    input: UpdateSmartMappingDetailInput,
    condition?: ModelSmartMappingDetailConditionInput
  ): Promise<UpdateSmartMappingDetailMutation> {
    const statement = `mutation UpdateSmartMappingDetail($input: UpdateSmartMappingDetailInput!, $condition: ModelSmartMappingDetailConditionInput) {
        updateSmartMappingDetail(input: $input, condition: $condition) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSmartMappingDetailMutation>(
      response.data.updateSmartMappingDetail
    );
  }
  async DeleteSmartMappingDetail(
    input: DeleteSmartMappingDetailInput,
    condition?: ModelSmartMappingDetailConditionInput
  ): Promise<DeleteSmartMappingDetailMutation> {
    const statement = `mutation DeleteSmartMappingDetail($input: DeleteSmartMappingDetailInput!, $condition: ModelSmartMappingDetailConditionInput) {
        deleteSmartMappingDetail(input: $input, condition: $condition) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSmartMappingDetailMutation>(
      response.data.deleteSmartMappingDetail
    );
  }
  async CreateApiCallRegistry(
    input: CreateApiCallRegistryInput,
    condition?: ModelApiCallRegistryConditionInput
  ): Promise<CreateApiCallRegistryMutation> {
    const statement = `mutation CreateApiCallRegistry($input: CreateApiCallRegistryInput!, $condition: ModelApiCallRegistryConditionInput) {
        createApiCallRegistry(input: $input, condition: $condition) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateApiCallRegistryMutation>response.data.createApiCallRegistry;
  }
  async UpdateApiCallRegistry(
    input: UpdateApiCallRegistryInput,
    condition?: ModelApiCallRegistryConditionInput
  ): Promise<UpdateApiCallRegistryMutation> {
    const statement = `mutation UpdateApiCallRegistry($input: UpdateApiCallRegistryInput!, $condition: ModelApiCallRegistryConditionInput) {
        updateApiCallRegistry(input: $input, condition: $condition) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateApiCallRegistryMutation>response.data.updateApiCallRegistry;
  }
  async DeleteApiCallRegistry(
    input: DeleteApiCallRegistryInput,
    condition?: ModelApiCallRegistryConditionInput
  ): Promise<DeleteApiCallRegistryMutation> {
    const statement = `mutation DeleteApiCallRegistry($input: DeleteApiCallRegistryInput!, $condition: ModelApiCallRegistryConditionInput) {
        deleteApiCallRegistry(input: $input, condition: $condition) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteApiCallRegistryMutation>response.data.deleteApiCallRegistry;
  }
  async CreateBreachData(
    input: CreateBreachDataInput,
    condition?: ModelBreachDataConditionInput
  ): Promise<CreateBreachDataMutation> {
    const statement = `mutation CreateBreachData($input: CreateBreachDataInput!, $condition: ModelBreachDataConditionInput) {
        createBreachData(input: $input, condition: $condition) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBreachDataMutation>response.data.createBreachData;
  }
  async UpdateBreachData(
    input: UpdateBreachDataInput,
    condition?: ModelBreachDataConditionInput
  ): Promise<UpdateBreachDataMutation> {
    const statement = `mutation UpdateBreachData($input: UpdateBreachDataInput!, $condition: ModelBreachDataConditionInput) {
        updateBreachData(input: $input, condition: $condition) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBreachDataMutation>response.data.updateBreachData;
  }
  async DeleteBreachData(
    input: DeleteBreachDataInput,
    condition?: ModelBreachDataConditionInput
  ): Promise<DeleteBreachDataMutation> {
    const statement = `mutation DeleteBreachData($input: DeleteBreachDataInput!, $condition: ModelBreachDataConditionInput) {
        deleteBreachData(input: $input, condition: $condition) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBreachDataMutation>response.data.deleteBreachData;
  }
  async ListAlerts(
    industry: string,
    fileType: FileTypeEnum
  ): Promise<Array<ListAlertsQuery>> {
    const statement = `query ListAlerts($industry: String!, $fileType: FileTypeEnum!) {
        listAlerts(industry: $industry, fileType: $fileType) {
          __typename
          id
          type
          content
          priority
        }
      }`;
    const gqlAPIServiceArguments: any = {
      industry,
      fileType
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListAlertsQuery>>response.data.listAlerts;
  }
  async CreateNewRevision(): Promise<boolean | null> {
    const statement = `query CreateNewRevision {
        createNewRevision
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <boolean | null>response.data.createNewRevision;
  }
  async IsJiraAuthValid(jira: JiraAuthInput): Promise<boolean | null> {
    const statement = `query IsJiraAuthValid($jira: JiraAuthInput!) {
        isJiraAuthValid(jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      jira
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.isJiraAuthValid;
  }
  async ValidateJiraAuth(jira: JiraAuthInput): Promise<string | null> {
    const statement = `query ValidateJiraAuth($jira: JiraAuthInput!) {
        validateJiraAuth(jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      jira
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.validateJiraAuth;
  }
  async ValidateAndSaveJiraCredentials(
    jira: JiraAuthInput,
    entityId: string
  ): Promise<string | null> {
    const statement = `query ValidateAndSaveJiraCredentials($jira: JiraAuthInput!, $entityId: String!) {
        validateAndSaveJiraCredentials(jira: $jira, entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      jira,
      entityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.validateAndSaveJiraCredentials;
  }
  async DecryptThirdPartyIntegration(
    key: string,
    data: string
  ): Promise<string | null> {
    const statement = `query DecryptThirdPartyIntegration($key: String!, $data: String!) {
        decryptThirdPartyIntegration(key: $key, data: $data)
      }`;
    const gqlAPIServiceArguments: any = {
      key,
      data
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.decryptThirdPartyIntegration;
  }
  async ValidateServiceNowAuth(
    serviceNow: ServiceNowAuthInput
  ): Promise<string | null> {
    const statement = `query ValidateServiceNowAuth($serviceNow: ServiceNowAuthInput!) {
        validateServiceNowAuth(serviceNow: $serviceNow)
      }`;
    const gqlAPIServiceArguments: any = {
      serviceNow
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.validateServiceNowAuth;
  }
  async ExportToJira(
    managerId: string,
    assessment: string,
    jira: JiraAuthInput,
    entityId: string
  ): Promise<string | null> {
    const statement = `query ExportToJira($managerId: String!, $assessment: AWSJSON!, $jira: JiraAuthInput!, $entityId: String!) {
        exportToJira(
          managerId: $managerId
          assessment: $assessment
          jira: $jira
          entityId: $entityId
        )
      }`;
    const gqlAPIServiceArguments: any = {
      managerId,
      assessment,
      jira,
      entityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.exportToJira;
  }
  async ExportToServiceNow(
    managerId: string,
    assessment: string,
    serviceNow: ServiceNowAuthInput,
    entityId: string
  ): Promise<string | null> {
    const statement = `query ExportToServiceNow($managerId: String!, $assessment: AWSJSON!, $serviceNow: ServiceNowAuthInput!, $entityId: String!) {
        exportToServiceNow(
          managerId: $managerId
          assessment: $assessment
          serviceNow: $serviceNow
          entityId: $entityId
        )
      }`;
    const gqlAPIServiceArguments: any = {
      managerId,
      assessment,
      serviceNow,
      entityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.exportToServiceNow;
  }
  async UpdateJiraTicketStatus(
    questionId: string,
    assessmentId: string,
    jira: JiraAuthInput
  ): Promise<string | null> {
    const statement = `query UpdateJiraTicketStatus($questionId: String!, $assessmentId: String!, $jira: JiraAuthInput!) {
        updateJiraTicketStatus(
          questionId: $questionId
          assessmentId: $assessmentId
          jira: $jira
        )
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
      assessmentId,
      jira
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.updateJiraTicketStatus;
  }
  async UpdateJiraCredentials(
    assessmentId: string,
    entityId: string,
    jira?: JiraAuthInput
  ): Promise<string | null> {
    const statement = `query UpdateJiraCredentials($assessmentId: String!, $entityId: String!, $jira: JiraAuthInput) {
        updateJiraCredentials(
          assessmentId: $assessmentId
          entityId: $entityId
          jira: $jira
        )
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
      entityId
    };
    if (jira) {
      gqlAPIServiceArguments.jira = jira;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.updateJiraCredentials;
  }
  async GetRapid7Data(data?: Rapid7Input): Promise<string | null> {
    const statement = `query GetRapid7Data($data: Rapid7Input) {
        getRapid7Data(data: $data)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (data) {
      gqlAPIServiceArguments.data = data;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.getRapid7Data;
  }
  async TenableIntegration(
    actions?: string,
    tenable?: TenableDetailsInput
  ): Promise<string | null> {
    const statement = `query TenableIntegration($actions: String, $tenable: TenableDetailsInput) {
        tenableIntegration(actions: $actions, tenable: $tenable)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (actions) {
      gqlAPIServiceArguments.actions = actions;
    }
    if (tenable) {
      gqlAPIServiceArguments.tenable = tenable;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tenableIntegration;
  }
  async RetrieveUsers(
    filter?: string,
    limit?: number
  ): Promise<Array<RetrieveUsersQuery>> {
    const statement = `query RetrieveUsers($filter: AWSJSON, $limit: Int) {
        retrieveUsers(filter: $filter, limit: $limit) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<RetrieveUsersQuery>>response.data.retrieveUsers;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async UserByEmail(
    email: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByEmailQuery> {
    const statement = `query UserByEmail($email: AWSEmail!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByEmail(
          email: $email
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByEmailQuery>response.data.userByEmail;
  }
  async UserRole(
    role: RoleEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserRoleQuery> {
    const statement = `query UserRole($role: RoleEnum!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userRole(
          role: $role
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      role
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserRoleQuery>response.data.userRole;
  }
  async UserByRoleId(
    roleId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByRoleIdQuery> {
    const statement = `query UserByRoleId($roleId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByRoleId(
          roleId: $roleId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      roleId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByRoleIdQuery>response.data.userByRoleId;
  }
  async UserByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByEntityIdQuery> {
    const statement = `query UserByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByEntityIdQuery>response.data.userByEntityId;
  }
  async GetTags(id: string): Promise<GetTagsQuery> {
    const statement = `query GetTags($id: ID!) {
        getTags(id: $id) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTagsQuery>response.data.getTags;
  }
  async ListTags(
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTagsQuery> {
    const statement = `query ListTags($filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              reviewStatus
              resentBackStatus
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTagsQuery>response.data.listTags;
  }
  async TagsByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TagsByEntityIdQuery> {
    const statement = `query TagsByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        tagsByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              reviewStatus
              resentBackStatus
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TagsByEntityIdQuery>response.data.tagsByEntityId;
  }
  async TagsByTargetId(
    targetId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TagsByTargetIdQuery> {
    const statement = `query TagsByTargetId($targetId: String!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        tagsByTargetId(
          targetId: $targetId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              reviewStatus
              resentBackStatus
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TagsByTargetIdQuery>response.data.tagsByTargetId;
  }
  async TagsByAssessmentFrameworkKey(
    assessmentFrameworkKey: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TagsByAssessmentFrameworkKeyQuery> {
    const statement = `query TagsByAssessmentFrameworkKey($assessmentFrameworkKey: String!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        tagsByAssessmentFrameworkKey(
          assessmentFrameworkKey: $assessmentFrameworkKey
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              reviewStatus
              resentBackStatus
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentFrameworkKey
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TagsByAssessmentFrameworkKeyQuery>(
      response.data.tagsByAssessmentFrameworkKey
    );
  }
  async GetRole(id: string): Promise<GetRoleQuery> {
    const statement = `query GetRole($id: ID!) {
        getRole(id: $id) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRoleQuery>response.data.getRole;
  }
  async ListRoles(
    filter?: ModelRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRolesQuery> {
    const statement = `query ListRoles($filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
        listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            entityIds
            vendorIds
            isRootEntityAccess
            defaultOrEntityId
            screenPermissions
            createdAt
            updatedAt
            parentId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRolesQuery>response.data.listRoles;
  }
  async RolesByDefaultOrEntityId(
    defaultOrEntityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RolesByDefaultOrEntityIdQuery> {
    const statement = `query RolesByDefaultOrEntityId($defaultOrEntityId: String!, $sortDirection: ModelSortDirection, $filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
        rolesByDefaultOrEntityId(
          defaultOrEntityId: $defaultOrEntityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            entityId
            entityIds
            vendorIds
            isRootEntityAccess
            defaultOrEntityId
            screenPermissions
            createdAt
            updatedAt
            parentId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      defaultOrEntityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RolesByDefaultOrEntityIdQuery>(
      response.data.rolesByDefaultOrEntityId
    );
  }
  async GetRiskLineChart(id: string): Promise<GetRiskLineChartQuery> {
    const statement = `query GetRiskLineChart($id: ID!) {
        getRiskLineChart(id: $id) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRiskLineChartQuery>response.data.getRiskLineChart;
  }
  async ListRiskLineCharts(
    filter?: ModelRiskLineChartFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRiskLineChartsQuery> {
    const statement = `query ListRiskLineCharts($filter: ModelRiskLineChartFilterInput, $limit: Int, $nextToken: String) {
        listRiskLineCharts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            riskId
            residual
            inherent
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRiskLineChartsQuery>response.data.listRiskLineCharts;
  }
  async GetGroup(id: string): Promise<GetGroupQuery> {
    const statement = `query GetGroup($id: ID!) {
        getGroup(id: $id) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetGroupQuery>response.data.getGroup;
  }
  async ListGroups(
    filter?: ModelGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListGroupsQuery> {
    const statement = `query ListGroups($filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
        listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            rootId
            tags
            score
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListGroupsQuery>response.data.listGroups;
  }
  async GroupsByRootId(
    rootId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GroupsByRootIdQuery> {
    const statement = `query GroupsByRootId($rootId: String!, $sortDirection: ModelSortDirection, $filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
        groupsByRootId(
          rootId: $rootId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            rootId
            tags
            score
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GroupsByRootIdQuery>response.data.groupsByRootId;
  }
  async GetQuestionData(id: string): Promise<GetQuestionDataQuery> {
    const statement = `query GetQuestionData($id: ID!) {
        getQuestionData(id: $id) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionDataQuery>response.data.getQuestionData;
  }
  async ListQuestionData(
    filter?: ModelQuestionDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionDataQuery> {
    const statement = `query ListQuestionData($filter: ModelQuestionDataFilterInput, $limit: Int, $nextToken: String) {
        listQuestionData(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionDataQuery>response.data.listQuestionData;
  }
  async QuestionsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionsByAssessmentIdQuery> {
    const statement = `query QuestionsByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelQuestionDataFilterInput, $limit: Int, $nextToken: String) {
        questionsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QuestionsByAssessmentIdQuery>response.data.questionsByAssessmentId;
  }
  async GetArchivedUser(id: string): Promise<GetArchivedUserQuery> {
    const statement = `query GetArchivedUser($id: ID!) {
        getArchivedUser(id: $id) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetArchivedUserQuery>response.data.getArchivedUser;
  }
  async ListArchivedUsers(
    filter?: ModelArchivedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListArchivedUsersQuery> {
    const statement = `query ListArchivedUsers($filter: ModelArchivedUserFilterInput, $limit: Int, $nextToken: String) {
        listArchivedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListArchivedUsersQuery>response.data.listArchivedUsers;
  }
  async ArchivedUserRole(
    role: RoleEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchivedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivedUserRoleQuery> {
    const statement = `query ArchivedUserRole($role: RoleEnum!, $sortDirection: ModelSortDirection, $filter: ModelArchivedUserFilterInput, $limit: Int, $nextToken: String) {
        archivedUserRole(
          role: $role
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      role
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ArchivedUserRoleQuery>response.data.archivedUserRole;
  }
  async ArchivedUserByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchivedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivedUserByEntityIdQuery> {
    const statement = `query ArchivedUserByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelArchivedUserFilterInput, $limit: Int, $nextToken: String) {
        archivedUserByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ArchivedUserByEntityIdQuery>response.data.archivedUserByEntityId;
  }
  async GetEntity(id: string): Promise<GetEntityQuery> {
    const statement = `query GetEntity($id: ID!) {
        getEntity(id: $id) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEntityQuery>response.data.getEntity;
  }
  async ListEntities(
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntitiesQuery> {
    const statement = `query ListEntities($filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        listEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEntitiesQuery>response.data.listEntities;
  }
  async EntityRootEntityByType(
    rootEntityId: string,
    frameworkType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityRootEntityByTypeQuery> {
    const statement = `query EntityRootEntityByType($rootEntityId: ID!, $frameworkType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityRootEntityByType(
          rootEntityId: $rootEntityId
          frameworkType: $frameworkType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootEntityId
    };
    if (frameworkType) {
      gqlAPIServiceArguments.frameworkType = frameworkType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityRootEntityByTypeQuery>response.data.entityRootEntityByType;
  }
  async EntityRootEntityByTypeAndFrameworkName(
    rootEntityId: string,
    frameworkTypeFrameworkName?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityRootEntityByTypeAndFrameworkNameQuery> {
    const statement = `query EntityRootEntityByTypeAndFrameworkName($rootEntityId: ID!, $frameworkTypeFrameworkName: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityRootEntityByTypeAndFrameworkName(
          rootEntityId: $rootEntityId
          frameworkTypeFrameworkName: $frameworkTypeFrameworkName
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootEntityId
    };
    if (frameworkTypeFrameworkName) {
      gqlAPIServiceArguments.frameworkTypeFrameworkName = frameworkTypeFrameworkName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityRootEntityByTypeAndFrameworkNameQuery>(
      response.data.entityRootEntityByTypeAndFrameworkName
    );
  }
  async EntityChildEntityByType(
    childEntityId: string,
    frameworkType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityChildEntityByTypeQuery> {
    const statement = `query EntityChildEntityByType($childEntityId: ID!, $frameworkType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityChildEntityByType(
          childEntityId: $childEntityId
          frameworkType: $frameworkType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      childEntityId
    };
    if (frameworkType) {
      gqlAPIServiceArguments.frameworkType = frameworkType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityChildEntityByTypeQuery>response.data.entityChildEntityByType;
  }
  async EntityFramework(
    frameworkId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityFrameworkQuery> {
    const statement = `query EntityFramework($frameworkId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityFramework(
          frameworkId: $frameworkId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      frameworkId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityFrameworkQuery>response.data.entityFramework;
  }
  async EntityParent(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityParentQuery> {
    const statement = `query EntityParent($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityParent(
          parentId: $parentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityParentQuery>response.data.entityParent;
  }
  async EntitiesByEntityType(
    entityType: EntityTypeEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByEntityTypeQuery> {
    const statement = `query EntitiesByEntityType($entityType: EntityTypeEnum!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByEntityType(
          entityType: $entityType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityType
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntitiesByEntityTypeQuery>response.data.entitiesByEntityType;
  }
  async EntitiesByDomainAndEntityType(
    domain: string,
    entityType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByDomainAndEntityTypeQuery> {
    const statement = `query EntitiesByDomainAndEntityType($domain: String!, $entityType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByDomainAndEntityType(
          domain: $domain
          entityType: $entityType
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain
    };
    if (entityType) {
      gqlAPIServiceArguments.entityType = entityType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntitiesByDomainAndEntityTypeQuery>(
      response.data.entitiesByDomainAndEntityType
    );
  }
  async EntitiesByDomainAndCreatedAt(
    domain: string,
    createdAt?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByDomainAndCreatedAtQuery> {
    const statement = `query EntitiesByDomainAndCreatedAt($domain: String!, $createdAt: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByDomainAndCreatedAt(
          domain: $domain
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntitiesByDomainAndCreatedAtQuery>(
      response.data.entitiesByDomainAndCreatedAt
    );
  }
  async EntityByAssessmentId(
    activeAssessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityByAssessmentIdQuery> {
    const statement = `query EntityByAssessmentId($activeAssessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityByAssessmentId(
          activeAssessmentId: $activeAssessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      activeAssessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityByAssessmentIdQuery>response.data.entityByAssessmentId;
  }
  async GetGraph(id: string): Promise<GetGraphQuery> {
    const statement = `query GetGraph($id: ID!) {
        getGraph(id: $id) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetGraphQuery>response.data.getGraph;
  }
  async ListGraphs(
    filter?: ModelGraphFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListGraphsQuery> {
    const statement = `query ListGraphs($filter: ModelGraphFilterInput, $limit: Int, $nextToken: String) {
        listGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            type
            rootId
            status
            entities
            domainOrFunctions {
              __typename
              type
              data
            }
            groups {
              __typename
              id
              name
              tags
            }
            tags
            createdBy
            timeSpan
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListGraphsQuery>response.data.listGraphs;
  }
  async GraphsByRootId(
    rootId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelGraphFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GraphsByRootIdQuery> {
    const statement = `query GraphsByRootId($rootId: String!, $sortDirection: ModelSortDirection, $filter: ModelGraphFilterInput, $limit: Int, $nextToken: String) {
        graphsByRootId(
          rootId: $rootId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            rootId
            status
            entities
            domainOrFunctions {
              __typename
              type
              data
            }
            groups {
              __typename
              id
              name
              tags
            }
            tags
            createdBy
            timeSpan
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GraphsByRootIdQuery>response.data.graphsByRootId;
  }
  async GetRevision(id: string): Promise<GetRevisionQuery> {
    const statement = `query GetRevision($id: ID!) {
        getRevision(id: $id) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRevisionQuery>response.data.getRevision;
  }
  async ListRevisions(
    filter?: ModelRevisionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRevisionsQuery> {
    const statement = `query ListRevisions($filter: ModelRevisionFilterInput, $limit: Int, $nextToken: String) {
        listRevisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            frameworkKey
            assessmentId
            groupId
            date
            rootId
            tagId
            scores
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRevisionsQuery>response.data.listRevisions;
  }
  async GetVendorsDetail(id: string): Promise<GetVendorsDetailQuery> {
    const statement = `query GetVendorsDetail($id: ID!) {
        getVendorsDetail(id: $id) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVendorsDetailQuery>response.data.getVendorsDetail;
  }
  async ListVendorsDetails(
    filter?: ModelVendorsDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVendorsDetailsQuery> {
    const statement = `query ListVendorsDetails($filter: ModelVendorsDetailFilterInput, $limit: Int, $nextToken: String) {
        listVendorsDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVendorsDetailsQuery>response.data.listVendorsDetails;
  }
  async GetFrameworkScores(id: string): Promise<GetFrameworkScoresQuery> {
    const statement = `query GetFrameworkScores($id: ID!) {
        getFrameworkScores(id: $id) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFrameworkScoresQuery>response.data.getFrameworkScores;
  }
  async ListFrameworkScores(
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkScoresQuery> {
    const statement = `query ListFrameworkScores($filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            type
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            assignmentCount
            riskStandardsCount
            complianceStandardsCount
            standardsCount
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            groups
            maturity
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFrameworkScoresQuery>response.data.listFrameworkScores;
  }
  async FrameworkScoresByAssessment(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkScoresByAssessmentQuery> {
    const statement = `query FrameworkScoresByAssessment($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        FrameworkScoresByAssessment(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            assessmentId
            type
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            assignmentCount
            riskStandardsCount
            complianceStandardsCount
            standardsCount
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            groups
            maturity
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkScoresByAssessmentQuery>(
      response.data.FrameworkScoresByAssessment
    );
  }
  async GetIntelligence(id: string): Promise<GetIntelligenceQuery> {
    const statement = `query GetIntelligence($id: ID!) {
        getIntelligence(id: $id) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIntelligenceQuery>response.data.getIntelligence;
  }
  async ListIntelligences(
    filter?: ModelIntelligenceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntelligencesQuery> {
    const statement = `query ListIntelligences($filter: ModelIntelligenceFilterInput, $limit: Int, $nextToken: String) {
        listIntelligences(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIntelligencesQuery>response.data.listIntelligences;
  }
  async GetBreaches(id: string): Promise<GetBreachesQuery> {
    const statement = `query GetBreaches($id: ID!) {
        getBreaches(id: $id) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBreachesQuery>response.data.getBreaches;
  }
  async ListBreaches(
    filter?: ModelBreachesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBreachesQuery> {
    const statement = `query ListBreaches($filter: ModelBreachesFilterInput, $limit: Int, $nextToken: String) {
        listBreaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            date
            year
            name
            description
            leaks
            data
            status
            email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBreachesQuery>response.data.listBreaches;
  }
  async BreachesByVendorIdAndStatus(
    vendorId: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelBreachesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BreachesByVendorIdAndStatusQuery> {
    const statement = `query BreachesByVendorIdAndStatus($vendorId: ID!, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelBreachesFilterInput, $limit: Int, $nextToken: String) {
        BreachesByVendorIdAndStatus(
          vendorId: $vendorId
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vendorId
            date
            year
            name
            description
            leaks
            data
            status
            email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendorId
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BreachesByVendorIdAndStatusQuery>(
      response.data.BreachesByVendorIdAndStatus
    );
  }
  async GetExternalThreats(id: string): Promise<GetExternalThreatsQuery> {
    const statement = `query GetExternalThreats($id: ID!) {
        getExternalThreats(id: $id) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExternalThreatsQuery>response.data.getExternalThreats;
  }
  async ListExternalThreats(
    filter?: ModelExternalThreatsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExternalThreatsQuery> {
    const statement = `query ListExternalThreats($filter: ModelExternalThreatsFilterInput, $limit: Int, $nextToken: String) {
        listExternalThreats(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExternalThreatsQuery>response.data.listExternalThreats;
  }
  async ExternalThreatsByVendorIdAndStatus(
    vendorId: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelExternalThreatsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExternalThreatsByVendorIdAndStatusQuery> {
    const statement = `query ExternalThreatsByVendorIdAndStatus($vendorId: ID!, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelExternalThreatsFilterInput, $limit: Int, $nextToken: String) {
        ExternalThreatsByVendorIdAndStatus(
          vendorId: $vendorId
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendorId
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ExternalThreatsByVendorIdAndStatusQuery>(
      response.data.ExternalThreatsByVendorIdAndStatus
    );
  }
  async GetOthers(id: string): Promise<GetOthersQuery> {
    const statement = `query GetOthers($id: ID!) {
        getOthers(id: $id) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOthersQuery>response.data.getOthers;
  }
  async ListOthers(
    filter?: ModelOthersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOthersQuery> {
    const statement = `query ListOthers($filter: ModelOthersFilterInput, $limit: Int, $nextToken: String) {
        listOthers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOthersQuery>response.data.listOthers;
  }
  async OthersByVendorIdAndStatus(
    vendorId: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOthersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OthersByVendorIdAndStatusQuery> {
    const statement = `query OthersByVendorIdAndStatus($vendorId: ID!, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOthersFilterInput, $limit: Int, $nextToken: String) {
        OthersByVendorIdAndStatus(
          vendorId: $vendorId
          status: $status
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendorId
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OthersByVendorIdAndStatusQuery>(
      response.data.OthersByVendorIdAndStatus
    );
  }
  async GetQuestionnaireCompletion(
    id: string
  ): Promise<GetQuestionnaireCompletionQuery> {
    const statement = `query GetQuestionnaireCompletion($id: ID!) {
        getQuestionnaireCompletion(id: $id) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionnaireCompletionQuery>(
      response.data.getQuestionnaireCompletion
    );
  }
  async ListQuestionnaireCompletions(
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionnaireCompletionsQuery> {
    const statement = `query ListQuestionnaireCompletions($filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        listQuestionnaireCompletions(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            assignmentMap
            firstAnswer
            lastAnswer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionnaireCompletionsQuery>(
      response.data.listQuestionnaireCompletions
    );
  }
  async CompletionByUser(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CompletionByUserQuery> {
    const statement = `query CompletionByUser($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        CompletionByUser(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            assignmentMap
            firstAnswer
            lastAnswer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CompletionByUserQuery>response.data.CompletionByUser;
  }
  async CompletionByAssessment(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CompletionByAssessmentQuery> {
    const statement = `query CompletionByAssessment($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        CompletionByAssessment(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            assignmentMap
            firstAnswer
            lastAnswer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CompletionByAssessmentQuery>response.data.CompletionByAssessment;
  }
  async GetAnswer(id: string): Promise<GetAnswerQuery> {
    const statement = `query GetAnswer($id: ID!) {
        getAnswer(id: $id) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnswerQuery>response.data.getAnswer;
  }
  async ListAnswers(
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnswersQuery> {
    const statement = `query ListAnswers($filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnswersQuery>response.data.listAnswers;
  }
  async AnswersByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByUserIdQuery> {
    const statement = `query AnswersByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByUserIdQuery>response.data.answersByUserId;
  }
  async AnswersByQuestionId(
    questionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByQuestionIdQuery> {
    const statement = `query AnswersByQuestionId($questionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByQuestionId(
          questionId: $questionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      questionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByQuestionIdQuery>response.data.answersByQuestionId;
  }
  async AnswersByQuestionIdAndUser(
    questionId: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByQuestionIdAndUserQuery> {
    const statement = `query AnswersByQuestionIdAndUser($questionId: ID!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByQuestionIdAndUser(
          questionId: $questionId
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      questionId
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByQuestionIdAndUserQuery>(
      response.data.answersByQuestionIdAndUser
    );
  }
  async AnswersByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdQuery> {
    const statement = `query AnswersByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentIdQuery>response.data.answersByAssessmentId;
  }
  async AnswersByAssessmentIdAndUserId(
    assessmentId: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndUserIdQuery> {
    const statement = `query AnswersByAssessmentIdAndUserId($assessmentId: ID!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentIdAndUserId(
          assessmentId: $assessmentId
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentIdAndUserIdQuery>(
      response.data.answersByAssessmentIdAndUserId
    );
  }
  async AnswersByAssessmentQuestionUser(
    assessmentId: string,
    questionIdUserId?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentQuestionUserQuery> {
    const statement = `query AnswersByAssessmentQuestionUser($assessmentId: ID!, $questionIdUserId: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentQuestionUser(
          assessmentId: $assessmentId
          questionIdUserId: $questionIdUserId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (questionIdUserId) {
      gqlAPIServiceArguments.questionIdUserId = questionIdUserId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentQuestionUserQuery>(
      response.data.answersByAssessmentQuestionUser
    );
  }
  async AnswersByAssessmentIdAndQuestionId(
    assessmentId: string,
    questionId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndQuestionIdQuery> {
    const statement = `query AnswersByAssessmentIdAndQuestionId($assessmentId: ID!, $questionId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentIdAndQuestionId(
          assessmentId: $assessmentId
          questionId: $questionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (questionId) {
      gqlAPIServiceArguments.questionId = questionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentIdAndQuestionIdQuery>(
      response.data.answersByAssessmentIdAndQuestionId
    );
  }
  async AnswersByAssessmentIdAndFrameworkKey(
    assessmentId: string,
    frameworkName?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndFrameworkKeyQuery> {
    const statement = `query AnswersByAssessmentIdAndFrameworkKey($assessmentId: ID!, $frameworkName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentIdAndFrameworkKey(
          assessmentId: $assessmentId
          frameworkName: $frameworkName
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            answerName
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            triggerStopper
            origin
            auditStatus
            isUptodate
            reviewerActionStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (frameworkName) {
      gqlAPIServiceArguments.frameworkName = frameworkName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentIdAndFrameworkKeyQuery>(
      response.data.answersByAssessmentIdAndFrameworkKey
    );
  }
  async GetAssignment(id: string): Promise<GetAssignmentQuery> {
    const statement = `query GetAssignment($id: ID!) {
        getAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssignmentQuery>response.data.getAssignment;
  }
  async ListAssignments(
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssignmentsQuery> {
    const statement = `query ListAssignments($filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssignmentsQuery>response.data.listAssignments;
  }
  async AssignmentsByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByUserIdQuery> {
    const statement = `query AssignmentsByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignmentsByUserIdQuery>response.data.assignmentsByUserId;
  }
  async AssignmentsByUserIdAndAssessment(
    userId: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByUserIdAndAssessmentQuery> {
    const statement = `query AssignmentsByUserIdAndAssessment($userId: ID!, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByUserIdAndAssessment(
          userId: $userId
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignmentsByUserIdAndAssessmentQuery>(
      response.data.assignmentsByUserIdAndAssessment
    );
  }
  async AssignmentsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByAssessmentIdQuery> {
    const statement = `query AssignmentsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignmentsByAssessmentIdQuery>(
      response.data.assignmentsByAssessmentId
    );
  }
  async GetDisableAssignment(id: string): Promise<GetDisableAssignmentQuery> {
    const statement = `query GetDisableAssignment($id: ID!) {
        getDisableAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDisableAssignmentQuery>response.data.getDisableAssignment;
  }
  async ListDisableAssignments(
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDisableAssignmentsQuery> {
    const statement = `query ListDisableAssignments($filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listDisableAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDisableAssignmentsQuery>response.data.listDisableAssignments;
  }
  async DisableAssignmentsByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DisableAssignmentsByUserIdQuery> {
    const statement = `query DisableAssignmentsByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        disableAssignmentsByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DisableAssignmentsByUserIdQuery>(
      response.data.disableAssignmentsByUserId
    );
  }
  async DisableAssignmentsByUserIdAndAssessment(
    userId: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DisableAssignmentsByUserIdAndAssessmentQuery> {
    const statement = `query DisableAssignmentsByUserIdAndAssessment($userId: ID!, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        disableAssignmentsByUserIdAndAssessment(
          userId: $userId
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DisableAssignmentsByUserIdAndAssessmentQuery>(
      response.data.disableAssignmentsByUserIdAndAssessment
    );
  }
  async DisableAssignmentsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DisableAssignmentsByAssessmentIdQuery> {
    const statement = `query DisableAssignmentsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        disableAssignmentsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DisableAssignmentsByAssessmentIdQuery>(
      response.data.disableAssignmentsByAssessmentId
    );
  }
  async GetAssessment(id: string): Promise<GetAssessmentQuery> {
    const statement = `query GetAssessment($id: ID!) {
        getAssessment(id: $id) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentQuery>response.data.getAssessment;
  }
  async ListAssessments(
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentsQuery> {
    const statement = `query ListAssessments($filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssessmentsQuery>response.data.listAssessments;
  }
  async AssessmentsByRootId(
    rootId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssessmentsByRootIdQuery> {
    const statement = `query AssessmentsByRootId($rootId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        assessmentsByRootId(
          rootId: $rootId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssessmentsByRootIdQuery>response.data.assessmentsByRootId;
  }
  async AssessmentsByChildId(
    childId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssessmentsByChildIdQuery> {
    const statement = `query AssessmentsByChildId($childId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        assessmentsByChildId(
          childId: $childId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      childId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssessmentsByChildIdQuery>response.data.assessmentsByChildId;
  }
  async GetIntegrationMappings(
    id: string
  ): Promise<GetIntegrationMappingsQuery> {
    const statement = `query GetIntegrationMappings($id: ID!) {
        getIntegrationMappings(id: $id) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIntegrationMappingsQuery>response.data.getIntegrationMappings;
  }
  async ListIntegrationMappings(
    filter?: ModelIntegrationMappingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntegrationMappingsQuery> {
    const statement = `query ListIntegrationMappings($filter: ModelIntegrationMappingsFilterInput, $limit: Int, $nextToken: String) {
        listIntegrationMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            projectKey
            fileName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIntegrationMappingsQuery>response.data.listIntegrationMappings;
  }
  async IntegrationMappingsByProjectKey(
    projectKey: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIntegrationMappingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IntegrationMappingsByProjectKeyQuery> {
    const statement = `query IntegrationMappingsByProjectKey($projectKey: String!, $sortDirection: ModelSortDirection, $filter: ModelIntegrationMappingsFilterInput, $limit: Int, $nextToken: String) {
        integrationMappingsByProjectKey(
          projectKey: $projectKey
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            projectKey
            fileName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectKey
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IntegrationMappingsByProjectKeyQuery>(
      response.data.integrationMappingsByProjectKey
    );
  }
  async GetQuestionSettings(id: string): Promise<GetQuestionSettingsQuery> {
    const statement = `query GetQuestionSettings($id: ID!) {
        getQuestionSettings(id: $id) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionSettingsQuery>response.data.getQuestionSettings;
  }
  async ListQuestionSettings(
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionSettingsQuery> {
    const statement = `query ListQuestionSettings($filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        listQuestionSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isCommentsMandatory
            isApprovalRequired
            suggestedArtifactAnswers
            requiredCommentAnswers
            versionFrequency
            isIntegrationActive
            isSmartMappingActive
            createdAt
            updatedAt
            isInfoSec
            frameworkName
            left
            right
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionSettingsQuery>response.data.listQuestionSettings;
  }
  async QuestionSettingsByQuestionId(
    questionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionSettingsByQuestionIdQuery> {
    const statement = `query QuestionSettingsByQuestionId($questionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        QuestionSettingsByQuestionId(
          questionId: $questionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isCommentsMandatory
            isApprovalRequired
            suggestedArtifactAnswers
            requiredCommentAnswers
            versionFrequency
            isIntegrationActive
            isSmartMappingActive
            createdAt
            updatedAt
            isInfoSec
            frameworkName
            left
            right
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      questionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QuestionSettingsByQuestionIdQuery>(
      response.data.QuestionSettingsByQuestionId
    );
  }
  async QuestionSettingsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionSettingsByAssessmentIdQuery> {
    const statement = `query QuestionSettingsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        QuestionSettingsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isCommentsMandatory
            isApprovalRequired
            suggestedArtifactAnswers
            requiredCommentAnswers
            versionFrequency
            isIntegrationActive
            isSmartMappingActive
            createdAt
            updatedAt
            isInfoSec
            frameworkName
            left
            right
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QuestionSettingsByAssessmentIdQuery>(
      response.data.QuestionSettingsByAssessmentId
    );
  }
  async GetTaskAction(id: string): Promise<GetTaskActionQuery> {
    const statement = `query GetTaskAction($id: ID!) {
        getTaskAction(id: $id) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaskActionQuery>response.data.getTaskAction;
  }
  async ListTaskActions(
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaskActionsQuery> {
    const statement = `query ListTaskActions($filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        listTaskActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaskActionsQuery>response.data.listTaskActions;
  }
  async TaskActionsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByAssessmentIdQuery> {
    const statement = `query TaskActionsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaskActionsByAssessmentIdQuery>(
      response.data.taskActionsByAssessmentId
    );
  }
  async TaskActionsByAssessmentIdAndTabName(
    assessmentId: string,
    tabName?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByAssessmentIdAndTabNameQuery> {
    const statement = `query TaskActionsByAssessmentIdAndTabName($assessmentId: ID!, $tabName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByAssessmentIdAndTabName(
          assessmentId: $assessmentId
          tabName: $tabName
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (tabName) {
      gqlAPIServiceArguments.tabName = tabName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaskActionsByAssessmentIdAndTabNameQuery>(
      response.data.taskActionsByAssessmentIdAndTabName
    );
  }
  async TaskActionsByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByUserIdQuery> {
    const statement = `query TaskActionsByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaskActionsByUserIdQuery>response.data.taskActionsByUserId;
  }
  async TaskActionsByRootEntityId(
    rootEntityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByRootEntityIdQuery> {
    const statement = `query TaskActionsByRootEntityId($rootEntityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByRootEntityId(
          rootEntityId: $rootEntityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootEntityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TaskActionsByRootEntityIdQuery>(
      response.data.taskActionsByRootEntityId
    );
  }
  async GetUsersAssignmentMapper(
    id: string
  ): Promise<GetUsersAssignmentMapperQuery> {
    const statement = `query GetUsersAssignmentMapper($id: ID!) {
        getUsersAssignmentMapper(id: $id) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUsersAssignmentMapperQuery>(
      response.data.getUsersAssignmentMapper
    );
  }
  async ListUsersAssignmentMappers(
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersAssignmentMappersQuery> {
    const statement = `query ListUsersAssignmentMappers($filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        listUsersAssignmentMappers(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersAssignmentMappersQuery>(
      response.data.listUsersAssignmentMappers
    );
  }
  async UsersByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByAssessmentIdQuery> {
    const statement = `query UsersByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        usersByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UsersByAssessmentIdQuery>response.data.usersByAssessmentId;
  }
  async UsersByTaskId(
    taskId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByTaskIdQuery> {
    const statement = `query UsersByTaskId($taskId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        usersByTaskId(
          taskId: $taskId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      taskId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UsersByTaskIdQuery>response.data.usersByTaskId;
  }
  async TasksByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TasksByUserIdQuery> {
    const statement = `query TasksByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        tasksByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TasksByUserIdQuery>response.data.tasksByUserId;
  }
  async TasksByUserIdAndAssessmentId(
    userId: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TasksByUserIdAndAssessmentIdQuery> {
    const statement = `query TasksByUserIdAndAssessmentId($userId: ID!, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        tasksByUserIdAndAssessmentId(
          userId: $userId
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TasksByUserIdAndAssessmentIdQuery>(
      response.data.tasksByUserIdAndAssessmentId
    );
  }
  async GetFrameworkSettings(id: string): Promise<GetFrameworkSettingsQuery> {
    const statement = `query GetFrameworkSettings($id: ID!) {
        getFrameworkSettings(id: $id) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFrameworkSettingsQuery>response.data.getFrameworkSettings;
  }
  async ListFrameworkSettings(
    filter?: ModelFrameworkSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkSettingsQuery> {
    const statement = `query ListFrameworkSettings($filter: ModelFrameworkSettingsFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFrameworkSettingsQuery>response.data.listFrameworkSettings;
  }
  async GetCustomTask(id: string): Promise<GetCustomTaskQuery> {
    const statement = `query GetCustomTask($id: ID!) {
        getCustomTask(id: $id) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomTaskQuery>response.data.getCustomTask;
  }
  async ListCustomTasks(
    filter?: ModelCustomTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomTasksQuery> {
    const statement = `query ListCustomTasks($filter: ModelCustomTaskFilterInput, $limit: Int, $nextToken: String) {
        listCustomTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            label
            impact
            description
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            assessmentId
            customTodo
            dueDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomTasksQuery>response.data.listCustomTasks;
  }
  async CustomTasksByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomTasksByAssessmentIdQuery> {
    const statement = `query CustomTasksByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomTaskFilterInput, $limit: Int, $nextToken: String) {
        CustomTasksByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            label
            impact
            description
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            assessmentId
            customTodo
            dueDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomTasksByAssessmentIdQuery>(
      response.data.CustomTasksByAssessmentId
    );
  }
  async GetCustomTaskAssignment(
    id: string
  ): Promise<GetCustomTaskAssignmentQuery> {
    const statement = `query GetCustomTaskAssignment($id: ID!) {
        getCustomTaskAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomTaskAssignmentQuery>response.data.getCustomTaskAssignment;
  }
  async ListCustomTaskAssignments(
    filter?: ModelCustomTaskAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomTaskAssignmentsQuery> {
    const statement = `query ListCustomTaskAssignments($filter: ModelCustomTaskAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listCustomTaskAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomTaskAssignmentsQuery>(
      response.data.listCustomTaskAssignments
    );
  }
  async CustomTaskAssignmentsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomTaskAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomTaskAssignmentsByAssessmentIdQuery> {
    const statement = `query CustomTaskAssignmentsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomTaskAssignmentFilterInput, $limit: Int, $nextToken: String) {
        CustomTaskAssignmentsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomTaskAssignmentsByAssessmentIdQuery>(
      response.data.CustomTaskAssignmentsByAssessmentId
    );
  }
  async GetAssessmentStandardFramework(
    id: string
  ): Promise<GetAssessmentStandardFrameworkQuery> {
    const statement = `query GetAssessmentStandardFramework($id: ID!) {
        getAssessmentStandardFramework(id: $id) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentStandardFrameworkQuery>(
      response.data.getAssessmentStandardFramework
    );
  }
  async ListAssessmentStandardFrameworks(
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentStandardFrameworksQuery> {
    const statement = `query ListAssessmentStandardFrameworks($filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        listAssessmentStandardFrameworks(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              title
              printName
              disclaimerDate
              image
              text
              draw
              signDate
            }
            lockedByUserID
            lockedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssessmentStandardFrameworksQuery>(
      response.data.listAssessmentStandardFrameworks
    );
  }
  async StandardFrameworksByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StandardFrameworksByAssessmentIdQuery> {
    const statement = `query StandardFrameworksByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        standardFrameworksByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              title
              printName
              disclaimerDate
              image
              text
              draw
              signDate
            }
            lockedByUserID
            lockedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StandardFrameworksByAssessmentIdQuery>(
      response.data.standardFrameworksByAssessmentId
    );
  }
  async StandardFrameworksBySignatureUserId(
    signatureUserID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StandardFrameworksBySignatureUserIdQuery> {
    const statement = `query StandardFrameworksBySignatureUserId($signatureUserID: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        standardFrameworksBySignatureUserId(
          signatureUserID: $signatureUserID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              title
              printName
              disclaimerDate
              image
              text
              draw
              signDate
            }
            lockedByUserID
            lockedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      signatureUserID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StandardFrameworksBySignatureUserIdQuery>(
      response.data.standardFrameworksBySignatureUserId
    );
  }
  async StandardFrameworksByLockedUserId(
    lockedByUserID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StandardFrameworksByLockedUserIdQuery> {
    const statement = `query StandardFrameworksByLockedUserId($lockedByUserID: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        standardFrameworksByLockedUserId(
          lockedByUserID: $lockedByUserID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              title
              printName
              disclaimerDate
              image
              text
              draw
              signDate
            }
            lockedByUserID
            lockedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      lockedByUserID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StandardFrameworksByLockedUserIdQuery>(
      response.data.standardFrameworksByLockedUserId
    );
  }
  async GetFrameworkManager(id: string): Promise<GetFrameworkManagerQuery> {
    const statement = `query GetFrameworkManager($id: ID!) {
        getFrameworkManager(id: $id) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFrameworkManagerQuery>response.data.getFrameworkManager;
  }
  async ListFrameworkManagers(
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkManagersQuery> {
    const statement = `query ListFrameworkManagers($filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            removedQuestions
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFrameworkManagersQuery>response.data.listFrameworkManagers;
  }
  async FrameworkManagersByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByAssessmentIdQuery> {
    const statement = `query FrameworkManagersByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            removedQuestions
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkManagersByAssessmentIdQuery>(
      response.data.frameworkManagersByAssessmentId
    );
  }
  async FrameworkManagersByStandardFrameworkId(
    standardFrameworkId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByStandardFrameworkIdQuery> {
    const statement = `query FrameworkManagersByStandardFrameworkId($standardFrameworkId: String!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByStandardFrameworkId(
          standardFrameworkId: $standardFrameworkId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            removedQuestions
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      standardFrameworkId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkManagersByStandardFrameworkIdQuery>(
      response.data.frameworkManagersByStandardFrameworkId
    );
  }
  async FrameworkManagersByManagerId(
    managerId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByManagerIdQuery> {
    const statement = `query FrameworkManagersByManagerId($managerId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByManagerId(
          managerId: $managerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            removedQuestions
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      managerId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkManagersByManagerIdQuery>(
      response.data.frameworkManagersByManagerId
    );
  }
  async GetComment(id: string): Promise<GetCommentQuery> {
    const statement = `query GetComment($id: ID!) {
        getComment(id: $id) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCommentQuery>response.data.getComment;
  }
  async ListComments(
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCommentsQuery> {
    const statement = `query ListComments($filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            archivedUser {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCommentsQuery>response.data.listComments;
  }
  async CommentsByQuestionId(
    questionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByQuestionIdQuery> {
    const statement = `query CommentsByQuestionId($questionId: String!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByQuestionId(
          questionId: $questionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            archivedUser {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      questionId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentsByQuestionIdQuery>response.data.commentsByQuestionId;
  }
  async CommentsByRiskId(
    riskId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByRiskIdQuery> {
    const statement = `query CommentsByRiskId($riskId: String!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByRiskId(
          riskId: $riskId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            archivedUser {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      riskId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentsByRiskIdQuery>response.data.commentsByRiskId;
  }
  async CommentByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentByAssessmentIdQuery> {
    const statement = `query CommentByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        CommentByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            archivedUser {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentByAssessmentIdQuery>response.data.CommentByAssessmentId;
  }
  async CommentsByAssessmentIdAndQuestionId(
    assessmentId: string,
    questionId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByAssessmentIdAndQuestionIdQuery> {
    const statement = `query CommentsByAssessmentIdAndQuestionId($assessmentId: String!, $questionId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByAssessmentIdAndQuestionId(
          assessmentId: $assessmentId
          questionId: $questionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            archivedUser {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (questionId) {
      gqlAPIServiceArguments.questionId = questionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentsByAssessmentIdAndQuestionIdQuery>(
      response.data.commentsByAssessmentIdAndQuestionId
    );
  }
  async CommentsByFrameworkAndAssessmentId(
    framework: string,
    assessmentId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByFrameworkAndAssessmentIdQuery> {
    const statement = `query CommentsByFrameworkAndAssessmentId($framework: String!, $assessmentId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByFrameworkAndAssessmentId(
          framework: $framework
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            archivedUser {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      framework
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentsByFrameworkAndAssessmentIdQuery>(
      response.data.commentsByFrameworkAndAssessmentId
    );
  }
  async GetExport(id: string): Promise<GetExportQuery> {
    const statement = `query GetExport($id: ID!) {
        getExport(id: $id) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExportQuery>response.data.getExport;
  }
  async ListExports(
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExportsQuery> {
    const statement = `query ListExports($filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        listExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExportsQuery>response.data.listExports;
  }
  async ExportsByDestinationCategoryId(
    destinationCategoryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExportsByDestinationCategoryIdQuery> {
    const statement = `query ExportsByDestinationCategoryId($destinationCategoryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        exportsByDestinationCategoryId(
          destinationCategoryId: $destinationCategoryId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      destinationCategoryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ExportsByDestinationCategoryIdQuery>(
      response.data.exportsByDestinationCategoryId
    );
  }
  async ExportsByCategoryId(
    categoryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExportsByCategoryIdQuery> {
    const statement = `query ExportsByCategoryId($categoryId: String!, $sortDirection: ModelSortDirection, $filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        exportsByCategoryId(
          categoryId: $categoryId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      categoryId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ExportsByCategoryIdQuery>response.data.exportsByCategoryId;
  }
  async GetReport(id: string): Promise<GetReportQuery> {
    const statement = `query GetReport($id: ID!) {
        getReport(id: $id) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReportQuery>response.data.getReport;
  }
  async ListReports(
    filter?: ModelReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListReportsQuery> {
    const statement = `query ListReports($filter: ModelReportFilterInput, $limit: Int, $nextToken: String) {
        listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            name
            managerName
            startDate
            endDate
            type
            isDraft
            previousReports
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReportsQuery>response.data.listReports;
  }
  async ReportByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReportByEntityIdQuery> {
    const statement = `query ReportByEntityId($entityId: String!, $sortDirection: ModelSortDirection, $filter: ModelReportFilterInput, $limit: Int, $nextToken: String) {
        reportByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            entityId
            name
            managerName
            startDate
            endDate
            type
            isDraft
            previousReports
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ReportByEntityIdQuery>response.data.reportByEntityId;
  }
  async GetRisk(id: string): Promise<GetRiskQuery> {
    const statement = `query GetRisk($id: ID!) {
        getRisk(id: $id) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRiskQuery>response.data.getRisk;
  }
  async ListRisks(
    filter?: ModelRiskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRisksQuery> {
    const statement = `query ListRisks($filter: ModelRiskFilterInput, $limit: Int, $nextToken: String) {
        listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRisksQuery>response.data.listRisks;
  }
  async RiskByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskByEntityIdQuery> {
    const statement = `query RiskByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskFilterInput, $limit: Int, $nextToken: String) {
        riskByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RiskByEntityIdQuery>response.data.riskByEntityId;
  }
  async RiskByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskByAssessmentIdQuery> {
    const statement = `query RiskByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskFilterInput, $limit: Int, $nextToken: String) {
        riskByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RiskByAssessmentIdQuery>response.data.riskByAssessmentId;
  }
  async GetRiskOwnerAssignment(
    id: string
  ): Promise<GetRiskOwnerAssignmentQuery> {
    const statement = `query GetRiskOwnerAssignment($id: ID!) {
        getRiskOwnerAssignment(id: $id) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRiskOwnerAssignmentQuery>response.data.getRiskOwnerAssignment;
  }
  async ListRiskOwnerAssignments(
    filter?: ModelRiskOwnerAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRiskOwnerAssignmentsQuery> {
    const statement = `query ListRiskOwnerAssignments($filter: ModelRiskOwnerAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listRiskOwnerAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            subEntityId
            userId
            riskId
            risk {
              __typename
              id
              idTitle
              entityId
              entity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  frequencyDays
                  assessmentDays
                  isAutoReassessment
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  selectedLevel
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  phoneNumber
                  projectManagerPhoneNumber
                  businessUnits
                  category
                  dealValue
                  products
                  ticketID
                  tempUsersData
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                AI {
                  __typename
                  credits
                  date
                  isActive
                  usedCredits
                  userName
                }
                upperdeckRoute
                isLocked
                eSignFinalized
                weightageScore
                upperdeckSetting
                domainScanned
                domainScanTimes
                domainScanEnabledForEntity
                solutionStatus {
                  __typename
                  firstParty
                  thirdParty
                  boardView
                }
                vendors {
                  __typename
                  vendorsTotalCount
                  totalVendorsCreated
                }
                frameworkSettings
                usedFrameworkUniqueCount
                spiderChartSetting
                isVendorInLimit
                updatedAt
              }
              subEntityId
              subEntity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  frequencyDays
                  assessmentDays
                  isAutoReassessment
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  selectedLevel
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  phoneNumber
                  projectManagerPhoneNumber
                  businessUnits
                  category
                  dealValue
                  products
                  ticketID
                  tempUsersData
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                AI {
                  __typename
                  credits
                  date
                  isActive
                  usedCredits
                  userName
                }
                upperdeckRoute
                isLocked
                eSignFinalized
                weightageScore
                upperdeckSetting
                domainScanned
                domainScanTimes
                domainScanEnabledForEntity
                solutionStatus {
                  __typename
                  firstParty
                  thirdParty
                  boardView
                }
                vendors {
                  __typename
                  vendorsTotalCount
                  totalVendorsCreated
                }
                frameworkSettings
                usedFrameworkUniqueCount
                spiderChartSetting
                isVendorInLimit
                updatedAt
              }
              assessmentId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              type
              disable
              controlIds
              riskTitle
              riskDescription
              riskCategory
              relatedAssets
              residualImpact
              residualProbability
              riskImpact
              riskProbability
              inherentRisk
              residualRisk
              riskStatus
              riskStatusUpdatedBy
              notes
              costToRemediate
              costToRemediateType
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              riskPriority
              hidden
              financialToggle
              scoreHistory {
                __typename
                controlId
                questionOrder
                score
                remediationStatus
                impact
                probability
                weight
                left
                right
              }
              riskTags
              riskTasks {
                __typename
                title
                status
                id
              }
              riskControlNames {
                __typename
                title
                id
                mitigationPlan
                label
              }
              createdAt
              updatedAt
              riskTasksIds
              isArchived
              isEscalated
              sensitivity
              effectiveness
              deadline
              riskOwnerIds
            }
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRiskOwnerAssignmentsQuery>(
      response.data.listRiskOwnerAssignments
    );
  }
  async RiskAssignmentByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskOwnerAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskAssignmentByUserIdQuery> {
    const statement = `query RiskAssignmentByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskOwnerAssignmentFilterInput, $limit: Int, $nextToken: String) {
        riskAssignmentByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subEntityId
            userId
            riskId
            risk {
              __typename
              id
              idTitle
              entityId
              entity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  frequencyDays
                  assessmentDays
                  isAutoReassessment
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  selectedLevel
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  phoneNumber
                  projectManagerPhoneNumber
                  businessUnits
                  category
                  dealValue
                  products
                  ticketID
                  tempUsersData
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                AI {
                  __typename
                  credits
                  date
                  isActive
                  usedCredits
                  userName
                }
                upperdeckRoute
                isLocked
                eSignFinalized
                weightageScore
                upperdeckSetting
                domainScanned
                domainScanTimes
                domainScanEnabledForEntity
                solutionStatus {
                  __typename
                  firstParty
                  thirdParty
                  boardView
                }
                vendors {
                  __typename
                  vendorsTotalCount
                  totalVendorsCreated
                }
                frameworkSettings
                usedFrameworkUniqueCount
                spiderChartSetting
                isVendorInLimit
                updatedAt
              }
              subEntityId
              subEntity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  frequencyDays
                  assessmentDays
                  isAutoReassessment
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  selectedLevel
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  phoneNumber
                  projectManagerPhoneNumber
                  businessUnits
                  category
                  dealValue
                  products
                  ticketID
                  tempUsersData
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                AI {
                  __typename
                  credits
                  date
                  isActive
                  usedCredits
                  userName
                }
                upperdeckRoute
                isLocked
                eSignFinalized
                weightageScore
                upperdeckSetting
                domainScanned
                domainScanTimes
                domainScanEnabledForEntity
                solutionStatus {
                  __typename
                  firstParty
                  thirdParty
                  boardView
                }
                vendors {
                  __typename
                  vendorsTotalCount
                  totalVendorsCreated
                }
                frameworkSettings
                usedFrameworkUniqueCount
                spiderChartSetting
                isVendorInLimit
                updatedAt
              }
              assessmentId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              type
              disable
              controlIds
              riskTitle
              riskDescription
              riskCategory
              relatedAssets
              residualImpact
              residualProbability
              riskImpact
              riskProbability
              inherentRisk
              residualRisk
              riskStatus
              riskStatusUpdatedBy
              notes
              costToRemediate
              costToRemediateType
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              riskPriority
              hidden
              financialToggle
              scoreHistory {
                __typename
                controlId
                questionOrder
                score
                remediationStatus
                impact
                probability
                weight
                left
                right
              }
              riskTags
              riskTasks {
                __typename
                title
                status
                id
              }
              riskControlNames {
                __typename
                title
                id
                mitigationPlan
                label
              }
              createdAt
              updatedAt
              riskTasksIds
              isArchived
              isEscalated
              sensitivity
              effectiveness
              deadline
              riskOwnerIds
            }
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RiskAssignmentByUserIdQuery>response.data.riskAssignmentByUserId;
  }
  async RiskAssignmentByRiskId(
    riskId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskOwnerAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskAssignmentByRiskIdQuery> {
    const statement = `query RiskAssignmentByRiskId($riskId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskOwnerAssignmentFilterInput, $limit: Int, $nextToken: String) {
        riskAssignmentByRiskId(
          riskId: $riskId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subEntityId
            userId
            riskId
            risk {
              __typename
              id
              idTitle
              entityId
              entity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  frequencyDays
                  assessmentDays
                  isAutoReassessment
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  selectedLevel
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  phoneNumber
                  projectManagerPhoneNumber
                  businessUnits
                  category
                  dealValue
                  products
                  ticketID
                  tempUsersData
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                AI {
                  __typename
                  credits
                  date
                  isActive
                  usedCredits
                  userName
                }
                upperdeckRoute
                isLocked
                eSignFinalized
                weightageScore
                upperdeckSetting
                domainScanned
                domainScanTimes
                domainScanEnabledForEntity
                solutionStatus {
                  __typename
                  firstParty
                  thirdParty
                  boardView
                }
                vendors {
                  __typename
                  vendorsTotalCount
                  totalVendorsCreated
                }
                frameworkSettings
                usedFrameworkUniqueCount
                spiderChartSetting
                isVendorInLimit
                updatedAt
              }
              subEntityId
              subEntity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  frequencyDays
                  assessmentDays
                  isAutoReassessment
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  selectedLevel
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  phoneNumber
                  projectManagerPhoneNumber
                  businessUnits
                  category
                  dealValue
                  products
                  ticketID
                  tempUsersData
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                AI {
                  __typename
                  credits
                  date
                  isActive
                  usedCredits
                  userName
                }
                upperdeckRoute
                isLocked
                eSignFinalized
                weightageScore
                upperdeckSetting
                domainScanned
                domainScanTimes
                domainScanEnabledForEntity
                solutionStatus {
                  __typename
                  firstParty
                  thirdParty
                  boardView
                }
                vendors {
                  __typename
                  vendorsTotalCount
                  totalVendorsCreated
                }
                frameworkSettings
                usedFrameworkUniqueCount
                spiderChartSetting
                isVendorInLimit
                updatedAt
              }
              assessmentId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              type
              disable
              controlIds
              riskTitle
              riskDescription
              riskCategory
              relatedAssets
              residualImpact
              residualProbability
              riskImpact
              riskProbability
              inherentRisk
              residualRisk
              riskStatus
              riskStatusUpdatedBy
              notes
              costToRemediate
              costToRemediateType
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              riskPriority
              hidden
              financialToggle
              scoreHistory {
                __typename
                controlId
                questionOrder
                score
                remediationStatus
                impact
                probability
                weight
                left
                right
              }
              riskTags
              riskTasks {
                __typename
                title
                status
                id
              }
              riskControlNames {
                __typename
                title
                id
                mitigationPlan
                label
              }
              createdAt
              updatedAt
              riskTasksIds
              isArchived
              isEscalated
              sensitivity
              effectiveness
              deadline
              riskOwnerIds
            }
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      riskId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RiskAssignmentByRiskIdQuery>response.data.riskAssignmentByRiskId;
  }
  async GetSavedFilter(id: string): Promise<GetSavedFilterQuery> {
    const statement = `query GetSavedFilter($id: ID!) {
        getSavedFilter(id: $id) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSavedFilterQuery>response.data.getSavedFilter;
  }
  async ListSavedFilters(
    filter?: ModelSavedFilterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSavedFiltersQuery> {
    const statement = `query ListSavedFilters($filter: ModelSavedFilterFilterInput, $limit: Int, $nextToken: String) {
        listSavedFilters(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            filterObject
            userId
            entityId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSavedFiltersQuery>response.data.listSavedFilters;
  }
  async SavedFiltersByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSavedFilterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SavedFiltersByUserIdQuery> {
    const statement = `query SavedFiltersByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSavedFilterFilterInput, $limit: Int, $nextToken: String) {
        SavedFiltersByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            filterObject
            userId
            entityId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SavedFiltersByUserIdQuery>response.data.SavedFiltersByUserId;
  }
  async SavedFiltersByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSavedFilterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SavedFiltersByEntityIdQuery> {
    const statement = `query SavedFiltersByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSavedFilterFilterInput, $limit: Int, $nextToken: String) {
        SavedFiltersByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            filterObject
            userId
            entityId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SavedFiltersByEntityIdQuery>response.data.SavedFiltersByEntityId;
  }
  async SavedFiltersByEntityIdAndUserId(
    entityId: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSavedFilterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SavedFiltersByEntityIdAndUserIdQuery> {
    const statement = `query SavedFiltersByEntityIdAndUserId($entityId: ID!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSavedFilterFilterInput, $limit: Int, $nextToken: String) {
        SavedFiltersByEntityIdAndUserId(
          entityId: $entityId
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            filterObject
            userId
            entityId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SavedFiltersByEntityIdAndUserIdQuery>(
      response.data.SavedFiltersByEntityIdAndUserId
    );
  }
  async GetDomainSettings(id: string): Promise<GetDomainSettingsQuery> {
    const statement = `query GetDomainSettings($id: ID!) {
        getDomainSettings(id: $id) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDomainSettingsQuery>response.data.getDomainSettings;
  }
  async ListDomainSettings(
    filter?: ModelDomainSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDomainSettingsQuery> {
    const statement = `query ListDomainSettings($filter: ModelDomainSettingsFilterInput, $limit: Int, $nextToken: String) {
        listDomainSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            domain
            key
            average
            allowedRoutes
            createdAt
            updatedAt
            allowedFeatures
            settings
            kmsStatus
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDomainSettingsQuery>response.data.listDomainSettings;
  }
  async DomainSettingByDomain(
    domain: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDomainSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DomainSettingByDomainQuery> {
    const statement = `query DomainSettingByDomain($domain: String!, $sortDirection: ModelSortDirection, $filter: ModelDomainSettingsFilterInput, $limit: Int, $nextToken: String) {
        domainSettingByDomain(
          domain: $domain
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            domain
            key
            average
            allowedRoutes
            createdAt
            updatedAt
            allowedFeatures
            settings
            kmsStatus
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DomainSettingByDomainQuery>response.data.domainSettingByDomain;
  }
  async GetExecutiveSummary(id: string): Promise<GetExecutiveSummaryQuery> {
    const statement = `query GetExecutiveSummary($id: ID!) {
        getExecutiveSummary(id: $id) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExecutiveSummaryQuery>response.data.getExecutiveSummary;
  }
  async ListExecutiveSummaries(
    filter?: ModelExecutiveSummaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExecutiveSummariesQuery> {
    const statement = `query ListExecutiveSummaries($filter: ModelExecutiveSummaryFilterInput, $limit: Int, $nextToken: String) {
        listExecutiveSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            title
            subjectList {
              __typename
              id
              title
              description
              toggle
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExecutiveSummariesQuery>response.data.listExecutiveSummaries;
  }
  async SummaryByEntityId(
    entityId: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelExecutiveSummaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SummaryByEntityIdQuery> {
    const statement = `query SummaryByEntityId($entityId: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelExecutiveSummaryFilterInput, $limit: Int, $nextToken: String) {
        summaryByEntityId(
          entityId: $entityId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            entityId
            title
            subjectList {
              __typename
              id
              title
              description
              toggle
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SummaryByEntityIdQuery>response.data.summaryByEntityId;
  }
  async GetNetskopeProject(id: string): Promise<GetNetskopeProjectQuery> {
    const statement = `query GetNetskopeProject($id: ID!) {
        getNetskopeProject(id: $id) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNetskopeProjectQuery>response.data.getNetskopeProject;
  }
  async ListNetskopeProjects(
    filter?: ModelNetskopeProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNetskopeProjectsQuery> {
    const statement = `query ListNetskopeProjects($filter: ModelNetskopeProjectFilterInput, $limit: Int, $nextToken: String) {
        listNetskopeProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            title
            description
            previous
            currentProgress {
              __typename
              startDate
              endDate
              status
            }
            budget
            comments
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNetskopeProjectsQuery>response.data.listNetskopeProjects;
  }
  async ProjectsByEntityId(
    entityId: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelNetskopeProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProjectsByEntityIdQuery> {
    const statement = `query ProjectsByEntityId($entityId: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelNetskopeProjectFilterInput, $limit: Int, $nextToken: String) {
        projectsByEntityId(
          entityId: $entityId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            entityId
            title
            description
            previous
            currentProgress {
              __typename
              startDate
              endDate
              status
            }
            budget
            comments
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProjectsByEntityIdQuery>response.data.projectsByEntityId;
  }
  async GetEntityLayers(id: string): Promise<GetEntityLayersQuery> {
    const statement = `query GetEntityLayers($id: ID!) {
        getEntityLayers(id: $id) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEntityLayersQuery>response.data.getEntityLayers;
  }
  async ListEntityLayers(
    filter?: ModelEntityLayersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntityLayersQuery> {
    const statement = `query ListEntityLayers($filter: ModelEntityLayersFilterInput, $limit: Int, $nextToken: String) {
        listEntityLayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            managerName
            type
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            parentId
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            srName
            createdAt
            updatedAt
            domain
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEntityLayersQuery>response.data.listEntityLayers;
  }
  async EntityLayersByParentId(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityLayersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityLayersByParentIdQuery> {
    const statement = `query EntityLayersByParentId($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityLayersFilterInput, $limit: Int, $nextToken: String) {
        entityLayersByParentId(
          parentId: $parentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            managerName
            type
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            parentId
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            srName
            createdAt
            updatedAt
            domain
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityLayersByParentIdQuery>response.data.entityLayersByParentId;
  }
  async GetEntityWizard(id: string): Promise<GetEntityWizardQuery> {
    const statement = `query GetEntityWizard($id: ID!) {
        getEntityWizard(id: $id) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEntityWizardQuery>response.data.getEntityWizard;
  }
  async ListEntityWizards(
    filter?: ModelEntityWizardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntityWizardsQuery> {
    const statement = `query ListEntityWizards($filter: ModelEntityWizardFilterInput, $limit: Int, $nextToken: String) {
        listEntityWizards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            isDraft
            organizational
            financial
            frameworks
            dataScope
            technical
            riskScenarios
            accountDetails
            currentYear
            rootEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            parentId
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            domainScanned
            domainScanTimes
            createdAt
            updatedAt
            riskFramework
            clientDomain
            userId
            applicantInfo
            revenueDetails
            dataInventory
            updatedBy
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEntityWizardsQuery>response.data.listEntityWizards;
  }
  async EntityWizardByParentId(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityWizardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityWizardByParentIdQuery> {
    const statement = `query EntityWizardByParentId($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityWizardFilterInput, $limit: Int, $nextToken: String) {
        entityWizardByParentId(
          parentId: $parentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            isDraft
            organizational
            financial
            frameworks
            dataScope
            technical
            riskScenarios
            accountDetails
            currentYear
            rootEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            parentId
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            domainScanned
            domainScanTimes
            createdAt
            updatedAt
            riskFramework
            clientDomain
            userId
            applicantInfo
            revenueDetails
            dataInventory
            updatedBy
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityWizardByParentIdQuery>response.data.entityWizardByParentId;
  }
  async EntityWizardByParentAndDomain(
    clientDomain: string,
    parentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityWizardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityWizardByParentAndDomainQuery> {
    const statement = `query EntityWizardByParentAndDomain($clientDomain: String!, $parentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityWizardFilterInput, $limit: Int, $nextToken: String) {
        entityWizardByParentAndDomain(
          clientDomain: $clientDomain
          parentId: $parentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            isDraft
            organizational
            financial
            frameworks
            dataScope
            technical
            riskScenarios
            accountDetails
            currentYear
            rootEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            parentId
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            domainScanned
            domainScanTimes
            createdAt
            updatedAt
            riskFramework
            clientDomain
            userId
            applicantInfo
            revenueDetails
            dataInventory
            updatedBy
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientDomain
    };
    if (parentId) {
      gqlAPIServiceArguments.parentId = parentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityWizardByParentAndDomainQuery>(
      response.data.entityWizardByParentAndDomain
    );
  }
  async GetLogs(id: string): Promise<GetLogsQuery> {
    const statement = `query GetLogs($id: ID!) {
        getLogs(id: $id) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLogsQuery>response.data.getLogs;
  }
  async ListLogs(
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLogsQuery> {
    const statement = `query ListLogs($filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            userID
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLogsQuery>response.data.listLogs;
  }
  async LogsByTargetId(
    targetId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByTargetIdQuery> {
    const statement = `query LogsByTargetId($targetId: String!, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByTargetId(
          targetId: $targetId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            userID
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LogsByTargetIdQuery>response.data.logsByTargetId;
  }
  async LogsByTargetIdAndType(
    targetId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByTargetIdAndTypeQuery> {
    const statement = `query LogsByTargetIdAndType($targetId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByTargetIdAndType(
          targetId: $targetId
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            userID
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LogsByTargetIdAndTypeQuery>response.data.logsByTargetIdAndType;
  }
  async LogsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByAssessmentIdQuery> {
    const statement = `query LogsByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            userID
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LogsByAssessmentIdQuery>response.data.logsByAssessmentId;
  }
  async LogsByAssessmentIdAndType(
    assessmentId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByAssessmentIdAndTypeQuery> {
    const statement = `query LogsByAssessmentIdAndType($assessmentId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByAssessmentIdAndType(
          assessmentId: $assessmentId
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            userID
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LogsByAssessmentIdAndTypeQuery>(
      response.data.logsByAssessmentIdAndType
    );
  }
  async LogsByUserId(
    userID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByUserIdQuery> {
    const statement = `query LogsByUserId($userID: ID!, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByUserId(
          userID: $userID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            userID
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LogsByUserIdQuery>response.data.logsByUserId;
  }
  async GetVersions(id: string): Promise<GetVersionsQuery> {
    const statement = `query GetVersions($id: ID!) {
        getVersions(id: $id) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVersionsQuery>response.data.getVersions;
  }
  async ListVersions(
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVersionsQuery> {
    const statement = `query ListVersions($filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVersionsQuery>response.data.listVersions;
  }
  async VersionsByTargetId(
    targetId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByTargetIdQuery> {
    const statement = `query VersionsByTargetId($targetId: String!, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByTargetId(
          targetId: $targetId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VersionsByTargetIdQuery>response.data.versionsByTargetId;
  }
  async VersionsByTargetIdAndType(
    targetId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByTargetIdAndTypeQuery> {
    const statement = `query VersionsByTargetIdAndType($targetId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByTargetIdAndType(
          targetId: $targetId
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VersionsByTargetIdAndTypeQuery>(
      response.data.versionsByTargetIdAndType
    );
  }
  async VersionsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByAssessmentIdQuery> {
    const statement = `query VersionsByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VersionsByAssessmentIdQuery>response.data.versionsByAssessmentId;
  }
  async VersionsByAssessmentIdAndType(
    assessmentId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByAssessmentIdAndTypeQuery> {
    const statement = `query VersionsByAssessmentIdAndType($assessmentId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByAssessmentIdAndType(
          assessmentId: $assessmentId
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <VersionsByAssessmentIdAndTypeQuery>(
      response.data.versionsByAssessmentIdAndType
    );
  }
  async GetDataState(id: string): Promise<GetDataStateQuery> {
    const statement = `query GetDataState($id: ID!) {
        getDataState(id: $id) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDataStateQuery>response.data.getDataState;
  }
  async ListDataStates(
    filter?: ModelDataStateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDataStatesQuery> {
    const statement = `query ListDataStates($filter: ModelDataStateFilterInput, $limit: Int, $nextToken: String) {
        listDataStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            state
            parentId
            message
            detail
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDataStatesQuery>response.data.listDataStates;
  }
  async DataStatesByParentId(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDataStateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DataStatesByParentIdQuery> {
    const statement = `query DataStatesByParentId($parentId: String!, $sortDirection: ModelSortDirection, $filter: ModelDataStateFilterInput, $limit: Int, $nextToken: String) {
        dataStatesByParentId(
          parentId: $parentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            state
            parentId
            message
            detail
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DataStatesByParentIdQuery>response.data.dataStatesByParentId;
  }
  async GetSmartMappingDetail(id: string): Promise<GetSmartMappingDetailQuery> {
    const statement = `query GetSmartMappingDetail($id: ID!) {
        getSmartMappingDetail(id: $id) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSmartMappingDetailQuery>response.data.getSmartMappingDetail;
  }
  async ListSmartMappingDetails(
    filter?: ModelSmartMappingDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSmartMappingDetailsQuery> {
    const statement = `query ListSmartMappingDetails($filter: ModelSmartMappingDetailFilterInput, $limit: Int, $nextToken: String) {
        listSmartMappingDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            uniqueId
            metadata
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSmartMappingDetailsQuery>response.data.listSmartMappingDetails;
  }
  async MetadataBySmartMapId(
    uniqueId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSmartMappingDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MetadataBySmartMapIdQuery> {
    const statement = `query MetadataBySmartMapId($uniqueId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSmartMappingDetailFilterInput, $limit: Int, $nextToken: String) {
        metadataBySmartMapId(
          uniqueId: $uniqueId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            uniqueId
            metadata
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uniqueId
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MetadataBySmartMapIdQuery>response.data.metadataBySmartMapId;
  }
  async GetApiCallRegistry(id: string): Promise<GetApiCallRegistryQuery> {
    const statement = `query GetApiCallRegistry($id: ID!) {
        getApiCallRegistry(id: $id) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetApiCallRegistryQuery>response.data.getApiCallRegistry;
  }
  async ListApiCallRegistries(
    filter?: ModelApiCallRegistryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListApiCallRegistriesQuery> {
    const statement = `query ListApiCallRegistries($filter: ModelApiCallRegistryFilterInput, $limit: Int, $nextToken: String) {
        listApiCallRegistries(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            callMaker
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListApiCallRegistriesQuery>response.data.listApiCallRegistries;
  }
  async RecordByCallMaker(
    callMaker: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelApiCallRegistryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RecordByCallMakerQuery> {
    const statement = `query RecordByCallMaker($callMaker: ID!, $sortDirection: ModelSortDirection, $filter: ModelApiCallRegistryFilterInput, $limit: Int, $nextToken: String) {
        recordByCallMaker(
          callMaker: $callMaker
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            callMaker
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      callMaker
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RecordByCallMakerQuery>response.data.recordByCallMaker;
  }
  async GetBreachData(id: string): Promise<GetBreachDataQuery> {
    const statement = `query GetBreachData($id: ID!) {
        getBreachData(id: $id) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBreachDataQuery>response.data.getBreachData;
  }
  async ListBreachData(
    filter?: ModelBreachDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBreachDataQuery> {
    const statement = `query ListBreachData($filter: ModelBreachDataFilterInput, $limit: Int, $nextToken: String) {
        listBreachData(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            domain
            breaches
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBreachDataQuery>response.data.listBreachData;
  }
  async BreachesByDomain(
    domain: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelBreachDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BreachesByDomainQuery> {
    const statement = `query BreachesByDomain($domain: String!, $sortDirection: ModelSortDirection, $filter: ModelBreachDataFilterInput, $limit: Int, $nextToken: String) {
        breachesByDomain(
          domain: $domain
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            domain
            breaches
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BreachesByDomainQuery>response.data.breachesByDomain;
  }
  OnUpdateEntityByRootIdListener(
    id: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEntityByRootId">
    >
  > {
    const statement = `subscription OnUpdateEntityByRootId($id: String!) {
        onUpdateEntityByRootId(id: $id) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateEntityByRootId">
      >
    >;
  }

  OnCreateUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
  > {
    const statement = `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
        onCreateUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUser">>
    >;
  }

  OnUpdateUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
  > {
    const statement = `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
        onUpdateUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUser">>
    >;
  }

  OnDeleteUserListener(
    filter?: ModelSubscriptionUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
  > {
    const statement = `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
        onDeleteUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUser">>
    >;
  }

  OnCreateTagsListener(
    filter?: ModelSubscriptionTagsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTags">>
  > {
    const statement = `subscription OnCreateTags($filter: ModelSubscriptionTagsFilterInput) {
        onCreateTags(filter: $filter) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTags">>
    >;
  }

  OnUpdateTagsListener(
    filter?: ModelSubscriptionTagsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTags">>
  > {
    const statement = `subscription OnUpdateTags($filter: ModelSubscriptionTagsFilterInput) {
        onUpdateTags(filter: $filter) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTags">>
    >;
  }

  OnDeleteTagsListener(
    filter?: ModelSubscriptionTagsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTags">>
  > {
    const statement = `subscription OnDeleteTags($filter: ModelSubscriptionTagsFilterInput) {
        onDeleteTags(filter: $filter) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTags">>
    >;
  }

  OnCreateRoleListener(
    filter?: ModelSubscriptionRoleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
  > {
    const statement = `subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
        onCreateRole(filter: $filter) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
    >;
  }

  OnUpdateRoleListener(
    filter?: ModelSubscriptionRoleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRole">>
  > {
    const statement = `subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
        onUpdateRole(filter: $filter) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRole">>
    >;
  }

  OnDeleteRoleListener(
    filter?: ModelSubscriptionRoleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
  > {
    const statement = `subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
        onDeleteRole(filter: $filter) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
    >;
  }

  OnCreateRiskLineChartListener(
    filter?: ModelSubscriptionRiskLineChartFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRiskLineChart">>
  > {
    const statement = `subscription OnCreateRiskLineChart($filter: ModelSubscriptionRiskLineChartFilterInput) {
        onCreateRiskLineChart(filter: $filter) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRiskLineChart">
      >
    >;
  }

  OnUpdateRiskLineChartListener(
    filter?: ModelSubscriptionRiskLineChartFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRiskLineChart">>
  > {
    const statement = `subscription OnUpdateRiskLineChart($filter: ModelSubscriptionRiskLineChartFilterInput) {
        onUpdateRiskLineChart(filter: $filter) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRiskLineChart">
      >
    >;
  }

  OnDeleteRiskLineChartListener(
    filter?: ModelSubscriptionRiskLineChartFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRiskLineChart">>
  > {
    const statement = `subscription OnDeleteRiskLineChart($filter: ModelSubscriptionRiskLineChartFilterInput) {
        onDeleteRiskLineChart(filter: $filter) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRiskLineChart">
      >
    >;
  }

  OnCreateGroupListener(
    filter?: ModelSubscriptionGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateGroup">>
  > {
    const statement = `subscription OnCreateGroup($filter: ModelSubscriptionGroupFilterInput) {
        onCreateGroup(filter: $filter) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateGroup">>
    >;
  }

  OnUpdateGroupListener(
    filter?: ModelSubscriptionGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateGroup">>
  > {
    const statement = `subscription OnUpdateGroup($filter: ModelSubscriptionGroupFilterInput) {
        onUpdateGroup(filter: $filter) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateGroup">>
    >;
  }

  OnDeleteGroupListener(
    filter?: ModelSubscriptionGroupFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteGroup">>
  > {
    const statement = `subscription OnDeleteGroup($filter: ModelSubscriptionGroupFilterInput) {
        onDeleteGroup(filter: $filter) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteGroup">>
    >;
  }

  OnCreateQuestionDataListener(
    filter?: ModelSubscriptionQuestionDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateQuestionData">>
  > {
    const statement = `subscription OnCreateQuestionData($filter: ModelSubscriptionQuestionDataFilterInput) {
        onCreateQuestionData(filter: $filter) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateQuestionData">
      >
    >;
  }

  OnUpdateQuestionDataListener(
    filter?: ModelSubscriptionQuestionDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateQuestionData">>
  > {
    const statement = `subscription OnUpdateQuestionData($filter: ModelSubscriptionQuestionDataFilterInput) {
        onUpdateQuestionData(filter: $filter) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateQuestionData">
      >
    >;
  }

  OnDeleteQuestionDataListener(
    filter?: ModelSubscriptionQuestionDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteQuestionData">>
  > {
    const statement = `subscription OnDeleteQuestionData($filter: ModelSubscriptionQuestionDataFilterInput) {
        onDeleteQuestionData(filter: $filter) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteQuestionData">
      >
    >;
  }

  OnCreateArchivedUserListener(
    filter?: ModelSubscriptionArchivedUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateArchivedUser">>
  > {
    const statement = `subscription OnCreateArchivedUser($filter: ModelSubscriptionArchivedUserFilterInput) {
        onCreateArchivedUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateArchivedUser">
      >
    >;
  }

  OnUpdateArchivedUserListener(
    filter?: ModelSubscriptionArchivedUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateArchivedUser">>
  > {
    const statement = `subscription OnUpdateArchivedUser($filter: ModelSubscriptionArchivedUserFilterInput) {
        onUpdateArchivedUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateArchivedUser">
      >
    >;
  }

  OnDeleteArchivedUserListener(
    filter?: ModelSubscriptionArchivedUserFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteArchivedUser">>
  > {
    const statement = `subscription OnDeleteArchivedUser($filter: ModelSubscriptionArchivedUserFilterInput) {
        onDeleteArchivedUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteArchivedUser">
      >
    >;
  }

  OnCreateEntityListener(
    filter?: ModelSubscriptionEntityFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEntity">>
  > {
    const statement = `subscription OnCreateEntity($filter: ModelSubscriptionEntityFilterInput) {
        onCreateEntity(filter: $filter) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEntity">>
    >;
  }

  OnUpdateEntityListener(
    filter?: ModelSubscriptionEntityFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEntity">>
  > {
    const statement = `subscription OnUpdateEntity($filter: ModelSubscriptionEntityFilterInput) {
        onUpdateEntity(filter: $filter) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEntity">>
    >;
  }

  OnDeleteEntityListener(
    filter?: ModelSubscriptionEntityFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEntity">>
  > {
    const statement = `subscription OnDeleteEntity($filter: ModelSubscriptionEntityFilterInput) {
        onDeleteEntity(filter: $filter) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            frequencyDays
            assessmentDays
            isAutoReassessment
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            selectedLevel
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            tempUsersData
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          domainScanned
          domainScanTimes
          domainScanEnabledForEntity
          solutionStatus {
            __typename
            firstParty
            thirdParty
            boardView
          }
          vendors {
            __typename
            vendorsTotalCount
            totalVendorsCreated
          }
          frameworkSettings
          usedFrameworkUniqueCount
          spiderChartSetting
          isVendorInLimit
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEntity">>
    >;
  }

  OnCreateGraphListener(
    filter?: ModelSubscriptionGraphFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateGraph">>
  > {
    const statement = `subscription OnCreateGraph($filter: ModelSubscriptionGraphFilterInput) {
        onCreateGraph(filter: $filter) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateGraph">>
    >;
  }

  OnUpdateGraphListener(
    filter?: ModelSubscriptionGraphFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateGraph">>
  > {
    const statement = `subscription OnUpdateGraph($filter: ModelSubscriptionGraphFilterInput) {
        onUpdateGraph(filter: $filter) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateGraph">>
    >;
  }

  OnDeleteGraphListener(
    filter?: ModelSubscriptionGraphFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteGraph">>
  > {
    const statement = `subscription OnDeleteGraph($filter: ModelSubscriptionGraphFilterInput) {
        onDeleteGraph(filter: $filter) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteGraph">>
    >;
  }

  OnCreateRevisionListener(
    filter?: ModelSubscriptionRevisionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRevision">>
  > {
    const statement = `subscription OnCreateRevision($filter: ModelSubscriptionRevisionFilterInput) {
        onCreateRevision(filter: $filter) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRevision">>
    >;
  }

  OnUpdateRevisionListener(
    filter?: ModelSubscriptionRevisionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRevision">>
  > {
    const statement = `subscription OnUpdateRevision($filter: ModelSubscriptionRevisionFilterInput) {
        onUpdateRevision(filter: $filter) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRevision">>
    >;
  }

  OnDeleteRevisionListener(
    filter?: ModelSubscriptionRevisionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRevision">>
  > {
    const statement = `subscription OnDeleteRevision($filter: ModelSubscriptionRevisionFilterInput) {
        onDeleteRevision(filter: $filter) {
          __typename
          id
          frameworkKey
          assessmentId
          groupId
          date
          rootId
          tagId
          scores
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRevision">>
    >;
  }

  OnCreateVendorsDetailListener(
    filter?: ModelSubscriptionVendorsDetailFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVendorsDetail">>
  > {
    const statement = `subscription OnCreateVendorsDetail($filter: ModelSubscriptionVendorsDetailFilterInput) {
        onCreateVendorsDetail(filter: $filter) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateVendorsDetail">
      >
    >;
  }

  OnUpdateVendorsDetailListener(
    filter?: ModelSubscriptionVendorsDetailFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVendorsDetail">>
  > {
    const statement = `subscription OnUpdateVendorsDetail($filter: ModelSubscriptionVendorsDetailFilterInput) {
        onUpdateVendorsDetail(filter: $filter) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateVendorsDetail">
      >
    >;
  }

  OnDeleteVendorsDetailListener(
    filter?: ModelSubscriptionVendorsDetailFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVendorsDetail">>
  > {
    const statement = `subscription OnDeleteVendorsDetail($filter: ModelSubscriptionVendorsDetailFilterInput) {
        onDeleteVendorsDetail(filter: $filter) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          tempUsersData
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteVendorsDetail">
      >
    >;
  }

  OnCreateFrameworkScoresListener(
    filter?: ModelSubscriptionFrameworkScoresFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateFrameworkScores">
    >
  > {
    const statement = `subscription OnCreateFrameworkScores($filter: ModelSubscriptionFrameworkScoresFilterInput) {
        onCreateFrameworkScores(filter: $filter) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateFrameworkScores">
      >
    >;
  }

  OnUpdateFrameworkScoresListener(
    filter?: ModelSubscriptionFrameworkScoresFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateFrameworkScores">
    >
  > {
    const statement = `subscription OnUpdateFrameworkScores($filter: ModelSubscriptionFrameworkScoresFilterInput) {
        onUpdateFrameworkScores(filter: $filter) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateFrameworkScores">
      >
    >;
  }

  OnDeleteFrameworkScoresListener(
    filter?: ModelSubscriptionFrameworkScoresFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteFrameworkScores">
    >
  > {
    const statement = `subscription OnDeleteFrameworkScores($filter: ModelSubscriptionFrameworkScoresFilterInput) {
        onDeleteFrameworkScores(filter: $filter) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteFrameworkScores">
      >
    >;
  }

  OnCreateIntelligenceListener(
    filter?: ModelSubscriptionIntelligenceFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateIntelligence">>
  > {
    const statement = `subscription OnCreateIntelligence($filter: ModelSubscriptionIntelligenceFilterInput) {
        onCreateIntelligence(filter: $filter) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIntelligence">
      >
    >;
  }

  OnUpdateIntelligenceListener(
    filter?: ModelSubscriptionIntelligenceFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateIntelligence">>
  > {
    const statement = `subscription OnUpdateIntelligence($filter: ModelSubscriptionIntelligenceFilterInput) {
        onUpdateIntelligence(filter: $filter) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIntelligence">
      >
    >;
  }

  OnDeleteIntelligenceListener(
    filter?: ModelSubscriptionIntelligenceFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteIntelligence">>
  > {
    const statement = `subscription OnDeleteIntelligence($filter: ModelSubscriptionIntelligenceFilterInput) {
        onDeleteIntelligence(filter: $filter) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIntelligence">
      >
    >;
  }

  OnCreateBreachesListener(
    filter?: ModelSubscriptionBreachesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBreaches">>
  > {
    const statement = `subscription OnCreateBreaches($filter: ModelSubscriptionBreachesFilterInput) {
        onCreateBreaches(filter: $filter) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBreaches">>
    >;
  }

  OnUpdateBreachesListener(
    filter?: ModelSubscriptionBreachesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBreaches">>
  > {
    const statement = `subscription OnUpdateBreaches($filter: ModelSubscriptionBreachesFilterInput) {
        onUpdateBreaches(filter: $filter) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBreaches">>
    >;
  }

  OnDeleteBreachesListener(
    filter?: ModelSubscriptionBreachesFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBreaches">>
  > {
    const statement = `subscription OnDeleteBreaches($filter: ModelSubscriptionBreachesFilterInput) {
        onDeleteBreaches(filter: $filter) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBreaches">>
    >;
  }

  OnCreateExternalThreatsListener(
    filter?: ModelSubscriptionExternalThreatsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExternalThreats">
    >
  > {
    const statement = `subscription OnCreateExternalThreats($filter: ModelSubscriptionExternalThreatsFilterInput) {
        onCreateExternalThreats(filter: $filter) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateExternalThreats">
      >
    >;
  }

  OnUpdateExternalThreatsListener(
    filter?: ModelSubscriptionExternalThreatsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExternalThreats">
    >
  > {
    const statement = `subscription OnUpdateExternalThreats($filter: ModelSubscriptionExternalThreatsFilterInput) {
        onUpdateExternalThreats(filter: $filter) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateExternalThreats">
      >
    >;
  }

  OnDeleteExternalThreatsListener(
    filter?: ModelSubscriptionExternalThreatsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExternalThreats">
    >
  > {
    const statement = `subscription OnDeleteExternalThreats($filter: ModelSubscriptionExternalThreatsFilterInput) {
        onDeleteExternalThreats(filter: $filter) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteExternalThreats">
      >
    >;
  }

  OnCreateOthersListener(
    filter?: ModelSubscriptionOthersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOthers">>
  > {
    const statement = `subscription OnCreateOthers($filter: ModelSubscriptionOthersFilterInput) {
        onCreateOthers(filter: $filter) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateOthers">>
    >;
  }

  OnUpdateOthersListener(
    filter?: ModelSubscriptionOthersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOthers">>
  > {
    const statement = `subscription OnUpdateOthers($filter: ModelSubscriptionOthersFilterInput) {
        onUpdateOthers(filter: $filter) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateOthers">>
    >;
  }

  OnDeleteOthersListener(
    filter?: ModelSubscriptionOthersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOthers">>
  > {
    const statement = `subscription OnDeleteOthers($filter: ModelSubscriptionOthersFilterInput) {
        onDeleteOthers(filter: $filter) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteOthers">>
    >;
  }

  OnCreateQuestionnaireCompletionListener(
    filter?: ModelSubscriptionQuestionnaireCompletionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateQuestionnaireCompletion">
    >
  > {
    const statement = `subscription OnCreateQuestionnaireCompletion($filter: ModelSubscriptionQuestionnaireCompletionFilterInput) {
        onCreateQuestionnaireCompletion(filter: $filter) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateQuestionnaireCompletion">
      >
    >;
  }

  OnUpdateQuestionnaireCompletionListener(
    filter?: ModelSubscriptionQuestionnaireCompletionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateQuestionnaireCompletion">
    >
  > {
    const statement = `subscription OnUpdateQuestionnaireCompletion($filter: ModelSubscriptionQuestionnaireCompletionFilterInput) {
        onUpdateQuestionnaireCompletion(filter: $filter) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateQuestionnaireCompletion">
      >
    >;
  }

  OnDeleteQuestionnaireCompletionListener(
    filter?: ModelSubscriptionQuestionnaireCompletionFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteQuestionnaireCompletion">
    >
  > {
    const statement = `subscription OnDeleteQuestionnaireCompletion($filter: ModelSubscriptionQuestionnaireCompletionFilterInput) {
        onDeleteQuestionnaireCompletion(filter: $filter) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteQuestionnaireCompletion">
      >
    >;
  }

  OnCreateAnswerListener(
    filter?: ModelSubscriptionAnswerFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAnswer">>
  > {
    const statement = `subscription OnCreateAnswer($filter: ModelSubscriptionAnswerFilterInput) {
        onCreateAnswer(filter: $filter) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAnswer">>
    >;
  }

  OnUpdateAnswerListener(
    filter?: ModelSubscriptionAnswerFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAnswer">>
  > {
    const statement = `subscription OnUpdateAnswer($filter: ModelSubscriptionAnswerFilterInput) {
        onUpdateAnswer(filter: $filter) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAnswer">>
    >;
  }

  OnDeleteAnswerListener(
    filter?: ModelSubscriptionAnswerFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAnswer">>
  > {
    const statement = `subscription OnDeleteAnswer($filter: ModelSubscriptionAnswerFilterInput) {
        onDeleteAnswer(filter: $filter) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAnswer">>
    >;
  }

  OnCreateAssignmentListener(
    filter?: ModelSubscriptionAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssignment">>
  > {
    const statement = `subscription OnCreateAssignment($filter: ModelSubscriptionAssignmentFilterInput) {
        onCreateAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssignment">>
    >;
  }

  OnUpdateAssignmentListener(
    filter?: ModelSubscriptionAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssignment">>
  > {
    const statement = `subscription OnUpdateAssignment($filter: ModelSubscriptionAssignmentFilterInput) {
        onUpdateAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssignment">>
    >;
  }

  OnDeleteAssignmentListener(
    filter?: ModelSubscriptionAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssignment">>
  > {
    const statement = `subscription OnDeleteAssignment($filter: ModelSubscriptionAssignmentFilterInput) {
        onDeleteAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
          frameworkKey
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssignment">>
    >;
  }

  OnCreateDisableAssignmentListener(
    filter?: ModelSubscriptionDisableAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateDisableAssignment">
    >
  > {
    const statement = `subscription OnCreateDisableAssignment($filter: ModelSubscriptionDisableAssignmentFilterInput) {
        onCreateDisableAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateDisableAssignment">
      >
    >;
  }

  OnUpdateDisableAssignmentListener(
    filter?: ModelSubscriptionDisableAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateDisableAssignment">
    >
  > {
    const statement = `subscription OnUpdateDisableAssignment($filter: ModelSubscriptionDisableAssignmentFilterInput) {
        onUpdateDisableAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateDisableAssignment">
      >
    >;
  }

  OnDeleteDisableAssignmentListener(
    filter?: ModelSubscriptionDisableAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteDisableAssignment">
    >
  > {
    const statement = `subscription OnDeleteDisableAssignment($filter: ModelSubscriptionDisableAssignmentFilterInput) {
        onDeleteDisableAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteDisableAssignment">
      >
    >;
  }

  OnCreateAssessmentListener(
    filter?: ModelSubscriptionAssessmentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssessment">>
  > {
    const statement = `subscription OnCreateAssessment($filter: ModelSubscriptionAssessmentFilterInput) {
        onCreateAssessment(filter: $filter) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAssessment">>
    >;
  }

  OnUpdateAssessmentListener(
    filter?: ModelSubscriptionAssessmentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssessment">>
  > {
    const statement = `subscription OnUpdateAssessment($filter: ModelSubscriptionAssessmentFilterInput) {
        onUpdateAssessment(filter: $filter) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAssessment">>
    >;
  }

  OnDeleteAssessmentListener(
    filter?: ModelSubscriptionAssessmentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssessment">>
  > {
    const statement = `subscription OnDeleteAssessment($filter: ModelSubscriptionAssessmentFilterInput) {
        onDeleteAssessment(filter: $filter) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAssessment">>
    >;
  }

  OnCreateIntegrationMappingsListener(
    filter?: ModelSubscriptionIntegrationMappingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateIntegrationMappings">
    >
  > {
    const statement = `subscription OnCreateIntegrationMappings($filter: ModelSubscriptionIntegrationMappingsFilterInput) {
        onCreateIntegrationMappings(filter: $filter) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateIntegrationMappings">
      >
    >;
  }

  OnUpdateIntegrationMappingsListener(
    filter?: ModelSubscriptionIntegrationMappingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateIntegrationMappings">
    >
  > {
    const statement = `subscription OnUpdateIntegrationMappings($filter: ModelSubscriptionIntegrationMappingsFilterInput) {
        onUpdateIntegrationMappings(filter: $filter) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateIntegrationMappings">
      >
    >;
  }

  OnDeleteIntegrationMappingsListener(
    filter?: ModelSubscriptionIntegrationMappingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteIntegrationMappings">
    >
  > {
    const statement = `subscription OnDeleteIntegrationMappings($filter: ModelSubscriptionIntegrationMappingsFilterInput) {
        onDeleteIntegrationMappings(filter: $filter) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteIntegrationMappings">
      >
    >;
  }

  OnCreateQuestionSettingsListener(
    filter?: ModelSubscriptionQuestionSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateQuestionSettings">
    >
  > {
    const statement = `subscription OnCreateQuestionSettings($filter: ModelSubscriptionQuestionSettingsFilterInput) {
        onCreateQuestionSettings(filter: $filter) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateQuestionSettings">
      >
    >;
  }

  OnUpdateQuestionSettingsListener(
    filter?: ModelSubscriptionQuestionSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateQuestionSettings">
    >
  > {
    const statement = `subscription OnUpdateQuestionSettings($filter: ModelSubscriptionQuestionSettingsFilterInput) {
        onUpdateQuestionSettings(filter: $filter) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateQuestionSettings">
      >
    >;
  }

  OnDeleteQuestionSettingsListener(
    filter?: ModelSubscriptionQuestionSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteQuestionSettings">
    >
  > {
    const statement = `subscription OnDeleteQuestionSettings($filter: ModelSubscriptionQuestionSettingsFilterInput) {
        onDeleteQuestionSettings(filter: $filter) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteQuestionSettings">
      >
    >;
  }

  OnCreateTaskActionListener(
    filter?: ModelSubscriptionTaskActionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaskAction">>
  > {
    const statement = `subscription OnCreateTaskAction($filter: ModelSubscriptionTaskActionFilterInput) {
        onCreateTaskAction(filter: $filter) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTaskAction">>
    >;
  }

  OnUpdateTaskActionListener(
    filter?: ModelSubscriptionTaskActionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaskAction">>
  > {
    const statement = `subscription OnUpdateTaskAction($filter: ModelSubscriptionTaskActionFilterInput) {
        onUpdateTaskAction(filter: $filter) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTaskAction">>
    >;
  }

  OnDeleteTaskActionListener(
    filter?: ModelSubscriptionTaskActionFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaskAction">>
  > {
    const statement = `subscription OnDeleteTaskAction($filter: ModelSubscriptionTaskActionFilterInput) {
        onDeleteTaskAction(filter: $filter) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTaskAction">>
    >;
  }

  OnCreateUsersAssignmentMapperListener(
    filter?: ModelSubscriptionUsersAssignmentMapperFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateUsersAssignmentMapper">
    >
  > {
    const statement = `subscription OnCreateUsersAssignmentMapper($filter: ModelSubscriptionUsersAssignmentMapperFilterInput) {
        onCreateUsersAssignmentMapper(filter: $filter) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateUsersAssignmentMapper">
      >
    >;
  }

  OnUpdateUsersAssignmentMapperListener(
    filter?: ModelSubscriptionUsersAssignmentMapperFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateUsersAssignmentMapper">
    >
  > {
    const statement = `subscription OnUpdateUsersAssignmentMapper($filter: ModelSubscriptionUsersAssignmentMapperFilterInput) {
        onUpdateUsersAssignmentMapper(filter: $filter) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateUsersAssignmentMapper">
      >
    >;
  }

  OnDeleteUsersAssignmentMapperListener(
    filter?: ModelSubscriptionUsersAssignmentMapperFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteUsersAssignmentMapper">
    >
  > {
    const statement = `subscription OnDeleteUsersAssignmentMapper($filter: ModelSubscriptionUsersAssignmentMapperFilterInput) {
        onDeleteUsersAssignmentMapper(filter: $filter) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteUsersAssignmentMapper">
      >
    >;
  }

  OnCreateFrameworkSettingsListener(
    filter?: ModelSubscriptionFrameworkSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateFrameworkSettings">
    >
  > {
    const statement = `subscription OnCreateFrameworkSettings($filter: ModelSubscriptionFrameworkSettingsFilterInput) {
        onCreateFrameworkSettings(filter: $filter) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateFrameworkSettings">
      >
    >;
  }

  OnUpdateFrameworkSettingsListener(
    filter?: ModelSubscriptionFrameworkSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateFrameworkSettings">
    >
  > {
    const statement = `subscription OnUpdateFrameworkSettings($filter: ModelSubscriptionFrameworkSettingsFilterInput) {
        onUpdateFrameworkSettings(filter: $filter) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateFrameworkSettings">
      >
    >;
  }

  OnDeleteFrameworkSettingsListener(
    filter?: ModelSubscriptionFrameworkSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteFrameworkSettings">
    >
  > {
    const statement = `subscription OnDeleteFrameworkSettings($filter: ModelSubscriptionFrameworkSettingsFilterInput) {
        onDeleteFrameworkSettings(filter: $filter) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteFrameworkSettings">
      >
    >;
  }

  OnCreateCustomTaskListener(
    filter?: ModelSubscriptionCustomTaskFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomTask">>
  > {
    const statement = `subscription OnCreateCustomTask($filter: ModelSubscriptionCustomTaskFilterInput) {
        onCreateCustomTask(filter: $filter) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCustomTask">>
    >;
  }

  OnUpdateCustomTaskListener(
    filter?: ModelSubscriptionCustomTaskFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomTask">>
  > {
    const statement = `subscription OnUpdateCustomTask($filter: ModelSubscriptionCustomTaskFilterInput) {
        onUpdateCustomTask(filter: $filter) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCustomTask">>
    >;
  }

  OnDeleteCustomTaskListener(
    filter?: ModelSubscriptionCustomTaskFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomTask">>
  > {
    const statement = `subscription OnDeleteCustomTask($filter: ModelSubscriptionCustomTaskFilterInput) {
        onDeleteCustomTask(filter: $filter) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCustomTask">>
    >;
  }

  OnCreateCustomTaskAssignmentListener(
    filter?: ModelSubscriptionCustomTaskAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCustomTaskAssignment">
    >
  > {
    const statement = `subscription OnCreateCustomTaskAssignment($filter: ModelSubscriptionCustomTaskAssignmentFilterInput) {
        onCreateCustomTaskAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCustomTaskAssignment">
      >
    >;
  }

  OnUpdateCustomTaskAssignmentListener(
    filter?: ModelSubscriptionCustomTaskAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCustomTaskAssignment">
    >
  > {
    const statement = `subscription OnUpdateCustomTaskAssignment($filter: ModelSubscriptionCustomTaskAssignmentFilterInput) {
        onUpdateCustomTaskAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCustomTaskAssignment">
      >
    >;
  }

  OnDeleteCustomTaskAssignmentListener(
    filter?: ModelSubscriptionCustomTaskAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCustomTaskAssignment">
    >
  > {
    const statement = `subscription OnDeleteCustomTaskAssignment($filter: ModelSubscriptionCustomTaskAssignmentFilterInput) {
        onDeleteCustomTaskAssignment(filter: $filter) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCustomTaskAssignment">
      >
    >;
  }

  OnCreateAssessmentStandardFrameworkListener(
    filter?: ModelSubscriptionAssessmentStandardFrameworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateAssessmentStandardFramework">
    >
  > {
    const statement = `subscription OnCreateAssessmentStandardFramework($filter: ModelSubscriptionAssessmentStandardFrameworkFilterInput) {
        onCreateAssessmentStandardFramework(filter: $filter) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateAssessmentStandardFramework">
      >
    >;
  }

  OnUpdateAssessmentStandardFrameworkListener(
    filter?: ModelSubscriptionAssessmentStandardFrameworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateAssessmentStandardFramework">
    >
  > {
    const statement = `subscription OnUpdateAssessmentStandardFramework($filter: ModelSubscriptionAssessmentStandardFrameworkFilterInput) {
        onUpdateAssessmentStandardFramework(filter: $filter) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateAssessmentStandardFramework">
      >
    >;
  }

  OnDeleteAssessmentStandardFrameworkListener(
    filter?: ModelSubscriptionAssessmentStandardFrameworkFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteAssessmentStandardFramework">
    >
  > {
    const statement = `subscription OnDeleteAssessmentStandardFramework($filter: ModelSubscriptionAssessmentStandardFrameworkFilterInput) {
        onDeleteAssessmentStandardFramework(filter: $filter) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteAssessmentStandardFramework">
      >
    >;
  }

  OnCreateFrameworkManagerListener(
    filter?: ModelSubscriptionFrameworkManagerFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateFrameworkManager">
    >
  > {
    const statement = `subscription OnCreateFrameworkManager($filter: ModelSubscriptionFrameworkManagerFilterInput) {
        onCreateFrameworkManager(filter: $filter) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateFrameworkManager">
      >
    >;
  }

  OnUpdateFrameworkManagerListener(
    filter?: ModelSubscriptionFrameworkManagerFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateFrameworkManager">
    >
  > {
    const statement = `subscription OnUpdateFrameworkManager($filter: ModelSubscriptionFrameworkManagerFilterInput) {
        onUpdateFrameworkManager(filter: $filter) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateFrameworkManager">
      >
    >;
  }

  OnDeleteFrameworkManagerListener(
    filter?: ModelSubscriptionFrameworkManagerFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteFrameworkManager">
    >
  > {
    const statement = `subscription OnDeleteFrameworkManager($filter: ModelSubscriptionFrameworkManagerFilterInput) {
        onDeleteFrameworkManager(filter: $filter) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          left
          right
          removedQuestions
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteFrameworkManager">
      >
    >;
  }

  OnCreateCommentListener(
    filter?: ModelSubscriptionCommentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateComment">>
  > {
    const statement = `subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
        onCreateComment(filter: $filter) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateComment">>
    >;
  }

  OnUpdateCommentListener(
    filter?: ModelSubscriptionCommentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateComment">>
  > {
    const statement = `subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
        onUpdateComment(filter: $filter) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateComment">>
    >;
  }

  OnDeleteCommentListener(
    filter?: ModelSubscriptionCommentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteComment">>
  > {
    const statement = `subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
        onDeleteComment(filter: $filter) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          archivedUser {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteComment">>
    >;
  }

  OnCreateExportListener(
    filter?: ModelSubscriptionExportFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateExport">>
  > {
    const statement = `subscription OnCreateExport($filter: ModelSubscriptionExportFilterInput) {
        onCreateExport(filter: $filter) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateExport">>
    >;
  }

  OnUpdateExportListener(
    filter?: ModelSubscriptionExportFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateExport">>
  > {
    const statement = `subscription OnUpdateExport($filter: ModelSubscriptionExportFilterInput) {
        onUpdateExport(filter: $filter) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateExport">>
    >;
  }

  OnDeleteExportListener(
    filter?: ModelSubscriptionExportFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteExport">>
  > {
    const statement = `subscription OnDeleteExport($filter: ModelSubscriptionExportFilterInput) {
        onDeleteExport(filter: $filter) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteExport">>
    >;
  }

  OnCreateReportListener(
    filter?: ModelSubscriptionReportFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateReport">>
  > {
    const statement = `subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
        onCreateReport(filter: $filter) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateReport">>
    >;
  }

  OnUpdateReportListener(
    filter?: ModelSubscriptionReportFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateReport">>
  > {
    const statement = `subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
        onUpdateReport(filter: $filter) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateReport">>
    >;
  }

  OnDeleteReportListener(
    filter?: ModelSubscriptionReportFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteReport">>
  > {
    const statement = `subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
        onDeleteReport(filter: $filter) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteReport">>
    >;
  }

  OnCreateRiskListener(
    filter?: ModelSubscriptionRiskFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRisk">>
  > {
    const statement = `subscription OnCreateRisk($filter: ModelSubscriptionRiskFilterInput) {
        onCreateRisk(filter: $filter) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRisk">>
    >;
  }

  OnUpdateRiskListener(
    filter?: ModelSubscriptionRiskFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRisk">>
  > {
    const statement = `subscription OnUpdateRisk($filter: ModelSubscriptionRiskFilterInput) {
        onUpdateRisk(filter: $filter) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateRisk">>
    >;
  }

  OnDeleteRiskListener(
    filter?: ModelSubscriptionRiskFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRisk">>
  > {
    const statement = `subscription OnDeleteRisk($filter: ModelSubscriptionRiskFilterInput) {
        onDeleteRisk(filter: $filter) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRisk">>
    >;
  }

  OnCreateRiskOwnerAssignmentListener(
    filter?: ModelSubscriptionRiskOwnerAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateRiskOwnerAssignment">
    >
  > {
    const statement = `subscription OnCreateRiskOwnerAssignment($filter: ModelSubscriptionRiskOwnerAssignmentFilterInput) {
        onCreateRiskOwnerAssignment(filter: $filter) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateRiskOwnerAssignment">
      >
    >;
  }

  OnUpdateRiskOwnerAssignmentListener(
    filter?: ModelSubscriptionRiskOwnerAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateRiskOwnerAssignment">
    >
  > {
    const statement = `subscription OnUpdateRiskOwnerAssignment($filter: ModelSubscriptionRiskOwnerAssignmentFilterInput) {
        onUpdateRiskOwnerAssignment(filter: $filter) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateRiskOwnerAssignment">
      >
    >;
  }

  OnDeleteRiskOwnerAssignmentListener(
    filter?: ModelSubscriptionRiskOwnerAssignmentFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteRiskOwnerAssignment">
    >
  > {
    const statement = `subscription OnDeleteRiskOwnerAssignment($filter: ModelSubscriptionRiskOwnerAssignmentFilterInput) {
        onDeleteRiskOwnerAssignment(filter: $filter) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                frequencyDays
                assessmentDays
                isAutoReassessment
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                selectedLevel
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                phoneNumber
                projectManagerPhoneNumber
                businessUnits
                category
                dealValue
                products
                ticketID
                tempUsersData
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              AI {
                __typename
                credits
                date
                isActive
                usedCredits
                userName
              }
              upperdeckRoute
              isLocked
              eSignFinalized
              weightageScore
              upperdeckSetting
              domainScanned
              domainScanTimes
              domainScanEnabledForEntity
              solutionStatus {
                __typename
                firstParty
                thirdParty
                boardView
              }
              vendors {
                __typename
                vendorsTotalCount
                totalVendorsCreated
              }
              frameworkSettings
              usedFrameworkUniqueCount
              spiderChartSetting
              isVendorInLimit
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteRiskOwnerAssignment">
      >
    >;
  }

  OnCreateSavedFilterListener(
    filter?: ModelSubscriptionSavedFilterFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSavedFilter">>
  > {
    const statement = `subscription OnCreateSavedFilter($filter: ModelSubscriptionSavedFilterFilterInput) {
        onCreateSavedFilter(filter: $filter) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSavedFilter">>
    >;
  }

  OnUpdateSavedFilterListener(
    filter?: ModelSubscriptionSavedFilterFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSavedFilter">>
  > {
    const statement = `subscription OnUpdateSavedFilter($filter: ModelSubscriptionSavedFilterFilterInput) {
        onUpdateSavedFilter(filter: $filter) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSavedFilter">>
    >;
  }

  OnDeleteSavedFilterListener(
    filter?: ModelSubscriptionSavedFilterFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSavedFilter">>
  > {
    const statement = `subscription OnDeleteSavedFilter($filter: ModelSubscriptionSavedFilterFilterInput) {
        onDeleteSavedFilter(filter: $filter) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSavedFilter">>
    >;
  }

  OnCreateDomainSettingsListener(
    filter?: ModelSubscriptionDomainSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateDomainSettings">
    >
  > {
    const statement = `subscription OnCreateDomainSettings($filter: ModelSubscriptionDomainSettingsFilterInput) {
        onCreateDomainSettings(filter: $filter) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateDomainSettings">
      >
    >;
  }

  OnUpdateDomainSettingsListener(
    filter?: ModelSubscriptionDomainSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateDomainSettings">
    >
  > {
    const statement = `subscription OnUpdateDomainSettings($filter: ModelSubscriptionDomainSettingsFilterInput) {
        onUpdateDomainSettings(filter: $filter) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateDomainSettings">
      >
    >;
  }

  OnDeleteDomainSettingsListener(
    filter?: ModelSubscriptionDomainSettingsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteDomainSettings">
    >
  > {
    const statement = `subscription OnDeleteDomainSettings($filter: ModelSubscriptionDomainSettingsFilterInput) {
        onDeleteDomainSettings(filter: $filter) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          settings
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteDomainSettings">
      >
    >;
  }

  OnCreateExecutiveSummaryListener(
    filter?: ModelSubscriptionExecutiveSummaryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateExecutiveSummary">
    >
  > {
    const statement = `subscription OnCreateExecutiveSummary($filter: ModelSubscriptionExecutiveSummaryFilterInput) {
        onCreateExecutiveSummary(filter: $filter) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateExecutiveSummary">
      >
    >;
  }

  OnUpdateExecutiveSummaryListener(
    filter?: ModelSubscriptionExecutiveSummaryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateExecutiveSummary">
    >
  > {
    const statement = `subscription OnUpdateExecutiveSummary($filter: ModelSubscriptionExecutiveSummaryFilterInput) {
        onUpdateExecutiveSummary(filter: $filter) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateExecutiveSummary">
      >
    >;
  }

  OnDeleteExecutiveSummaryListener(
    filter?: ModelSubscriptionExecutiveSummaryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteExecutiveSummary">
    >
  > {
    const statement = `subscription OnDeleteExecutiveSummary($filter: ModelSubscriptionExecutiveSummaryFilterInput) {
        onDeleteExecutiveSummary(filter: $filter) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteExecutiveSummary">
      >
    >;
  }

  OnCreateNetskopeProjectListener(
    filter?: ModelSubscriptionNetskopeProjectFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateNetskopeProject">
    >
  > {
    const statement = `subscription OnCreateNetskopeProject($filter: ModelSubscriptionNetskopeProjectFilterInput) {
        onCreateNetskopeProject(filter: $filter) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateNetskopeProject">
      >
    >;
  }

  OnUpdateNetskopeProjectListener(
    filter?: ModelSubscriptionNetskopeProjectFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateNetskopeProject">
    >
  > {
    const statement = `subscription OnUpdateNetskopeProject($filter: ModelSubscriptionNetskopeProjectFilterInput) {
        onUpdateNetskopeProject(filter: $filter) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateNetskopeProject">
      >
    >;
  }

  OnDeleteNetskopeProjectListener(
    filter?: ModelSubscriptionNetskopeProjectFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteNetskopeProject">
    >
  > {
    const statement = `subscription OnDeleteNetskopeProject($filter: ModelSubscriptionNetskopeProjectFilterInput) {
        onDeleteNetskopeProject(filter: $filter) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteNetskopeProject">
      >
    >;
  }

  OnCreateEntityLayersListener(
    filter?: ModelSubscriptionEntityLayersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEntityLayers">>
  > {
    const statement = `subscription OnCreateEntityLayers($filter: ModelSubscriptionEntityLayersFilterInput) {
        onCreateEntityLayers(filter: $filter) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateEntityLayers">
      >
    >;
  }

  OnUpdateEntityLayersListener(
    filter?: ModelSubscriptionEntityLayersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEntityLayers">>
  > {
    const statement = `subscription OnUpdateEntityLayers($filter: ModelSubscriptionEntityLayersFilterInput) {
        onUpdateEntityLayers(filter: $filter) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateEntityLayers">
      >
    >;
  }

  OnDeleteEntityLayersListener(
    filter?: ModelSubscriptionEntityLayersFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEntityLayers">>
  > {
    const statement = `subscription OnDeleteEntityLayers($filter: ModelSubscriptionEntityLayersFilterInput) {
        onDeleteEntityLayers(filter: $filter) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteEntityLayers">
      >
    >;
  }

  OnCreateEntityWizardListener(
    filter?: ModelSubscriptionEntityWizardFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEntityWizard">>
  > {
    const statement = `subscription OnCreateEntityWizard($filter: ModelSubscriptionEntityWizardFilterInput) {
        onCreateEntityWizard(filter: $filter) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateEntityWizard">
      >
    >;
  }

  OnUpdateEntityWizardListener(
    filter?: ModelSubscriptionEntityWizardFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEntityWizard">>
  > {
    const statement = `subscription OnUpdateEntityWizard($filter: ModelSubscriptionEntityWizardFilterInput) {
        onUpdateEntityWizard(filter: $filter) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateEntityWizard">
      >
    >;
  }

  OnDeleteEntityWizardListener(
    filter?: ModelSubscriptionEntityWizardFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEntityWizard">>
  > {
    const statement = `subscription OnDeleteEntityWizard($filter: ModelSubscriptionEntityWizardFilterInput) {
        onDeleteEntityWizard(filter: $filter) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              frequencyDays
              assessmentDays
              isAutoReassessment
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              selectedLevel
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              ticketID
              tempUsersData
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            domainScanned
            domainScanTimes
            domainScanEnabledForEntity
            solutionStatus {
              __typename
              firstParty
              thirdParty
              boardView
            }
            vendors {
              __typename
              vendorsTotalCount
              totalVendorsCreated
            }
            frameworkSettings
            usedFrameworkUniqueCount
            spiderChartSetting
            isVendorInLimit
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteEntityWizard">
      >
    >;
  }

  OnCreateLogsListener(
    filter?: ModelSubscriptionLogsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLogs">>
  > {
    const statement = `subscription OnCreateLogs($filter: ModelSubscriptionLogsFilterInput) {
        onCreateLogs(filter: $filter) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateLogs">>
    >;
  }

  OnUpdateLogsListener(
    filter?: ModelSubscriptionLogsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLogs">>
  > {
    const statement = `subscription OnUpdateLogs($filter: ModelSubscriptionLogsFilterInput) {
        onUpdateLogs(filter: $filter) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateLogs">>
    >;
  }

  OnDeleteLogsListener(
    filter?: ModelSubscriptionLogsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLogs">>
  > {
    const statement = `subscription OnDeleteLogs($filter: ModelSubscriptionLogsFilterInput) {
        onDeleteLogs(filter: $filter) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteLogs">>
    >;
  }

  OnCreateVersionsListener(
    filter?: ModelSubscriptionVersionsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVersions">>
  > {
    const statement = `subscription OnCreateVersions($filter: ModelSubscriptionVersionsFilterInput) {
        onCreateVersions(filter: $filter) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVersions">>
    >;
  }

  OnUpdateVersionsListener(
    filter?: ModelSubscriptionVersionsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVersions">>
  > {
    const statement = `subscription OnUpdateVersions($filter: ModelSubscriptionVersionsFilterInput) {
        onUpdateVersions(filter: $filter) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVersions">>
    >;
  }

  OnDeleteVersionsListener(
    filter?: ModelSubscriptionVersionsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVersions">>
  > {
    const statement = `subscription OnDeleteVersions($filter: ModelSubscriptionVersionsFilterInput) {
        onDeleteVersions(filter: $filter) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVersions">>
    >;
  }

  OnCreateDataStateListener(
    filter?: ModelSubscriptionDataStateFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDataState">>
  > {
    const statement = `subscription OnCreateDataState($filter: ModelSubscriptionDataStateFilterInput) {
        onCreateDataState(filter: $filter) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDataState">>
    >;
  }

  OnUpdateDataStateListener(
    filter?: ModelSubscriptionDataStateFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDataState">>
  > {
    const statement = `subscription OnUpdateDataState($filter: ModelSubscriptionDataStateFilterInput) {
        onUpdateDataState(filter: $filter) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDataState">>
    >;
  }

  OnDeleteDataStateListener(
    filter?: ModelSubscriptionDataStateFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDataState">>
  > {
    const statement = `subscription OnDeleteDataState($filter: ModelSubscriptionDataStateFilterInput) {
        onDeleteDataState(filter: $filter) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDataState">>
    >;
  }

  OnCreateSmartMappingDetailListener(
    filter?: ModelSubscriptionSmartMappingDetailFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSmartMappingDetail">
    >
  > {
    const statement = `subscription OnCreateSmartMappingDetail($filter: ModelSubscriptionSmartMappingDetailFilterInput) {
        onCreateSmartMappingDetail(filter: $filter) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSmartMappingDetail">
      >
    >;
  }

  OnUpdateSmartMappingDetailListener(
    filter?: ModelSubscriptionSmartMappingDetailFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSmartMappingDetail">
    >
  > {
    const statement = `subscription OnUpdateSmartMappingDetail($filter: ModelSubscriptionSmartMappingDetailFilterInput) {
        onUpdateSmartMappingDetail(filter: $filter) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSmartMappingDetail">
      >
    >;
  }

  OnDeleteSmartMappingDetailListener(
    filter?: ModelSubscriptionSmartMappingDetailFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSmartMappingDetail">
    >
  > {
    const statement = `subscription OnDeleteSmartMappingDetail($filter: ModelSubscriptionSmartMappingDetailFilterInput) {
        onDeleteSmartMappingDetail(filter: $filter) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSmartMappingDetail">
      >
    >;
  }

  OnCreateApiCallRegistryListener(
    filter?: ModelSubscriptionApiCallRegistryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateApiCallRegistry">
    >
  > {
    const statement = `subscription OnCreateApiCallRegistry($filter: ModelSubscriptionApiCallRegistryFilterInput) {
        onCreateApiCallRegistry(filter: $filter) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateApiCallRegistry">
      >
    >;
  }

  OnUpdateApiCallRegistryListener(
    filter?: ModelSubscriptionApiCallRegistryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateApiCallRegistry">
    >
  > {
    const statement = `subscription OnUpdateApiCallRegistry($filter: ModelSubscriptionApiCallRegistryFilterInput) {
        onUpdateApiCallRegistry(filter: $filter) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateApiCallRegistry">
      >
    >;
  }

  OnDeleteApiCallRegistryListener(
    filter?: ModelSubscriptionApiCallRegistryFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteApiCallRegistry">
    >
  > {
    const statement = `subscription OnDeleteApiCallRegistry($filter: ModelSubscriptionApiCallRegistryFilterInput) {
        onDeleteApiCallRegistry(filter: $filter) {
          __typename
          id
          callMaker
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteApiCallRegistry">
      >
    >;
  }

  OnCreateBreachDataListener(
    filter?: ModelSubscriptionBreachDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBreachData">>
  > {
    const statement = `subscription OnCreateBreachData($filter: ModelSubscriptionBreachDataFilterInput) {
        onCreateBreachData(filter: $filter) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBreachData">>
    >;
  }

  OnUpdateBreachDataListener(
    filter?: ModelSubscriptionBreachDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBreachData">>
  > {
    const statement = `subscription OnUpdateBreachData($filter: ModelSubscriptionBreachDataFilterInput) {
        onUpdateBreachData(filter: $filter) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBreachData">>
    >;
  }

  OnDeleteBreachDataListener(
    filter?: ModelSubscriptionBreachDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBreachData">>
  > {
    const statement = `subscription OnDeleteBreachData($filter: ModelSubscriptionBreachDataFilterInput) {
        onDeleteBreachData(filter: $filter) {
          __typename
          id
          domain
          breaches
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBreachData">>
    >;
  }
}
