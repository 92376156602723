<div class="modal-body">
  <div class="modal-header">
    <div class="lato-24-b-vw capitalize">Confirmation</div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="activeModal.close()">
    </div>
  </div>
  <div class="modal-main">
    <div>
      <span class="lato-18-b-vw ">{{ message }}</span>
    </div>

    <div class="btn-container">
      <button type="button" class="btn btn-primary uppercase confirm-btn"
        (click)="modalResult.emit(true)">
        Yes
      </button>
      <button type="button" class="btn btn-primary uppercase confirm-btn"
        (click)="activeModal.close()">
        No
      </button>
    </div>
  </div>
</div>