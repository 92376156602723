import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AdditionalRequirements } from 'app/shared/enums/common.enum';
import { REQUIREMENTS } from 'app/third-party/third-party.constants';
import { FileUploader } from 'ng2-file-upload';
import { FileService } from 'app/shared/file.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import * as JSZip from 'jszip';
import { FileTypeEnum } from 'app/API.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewCustomFrameworkComponent } from 'app/shared/add-new-custom-framework/add-new-custom-framework.component';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';

@Component({
  selector: 'cygov-require-certif-section',
  templateUrl: './require-certif-section.component.html',
  styleUrls: ['./require-certif-section.component.scss'],
})
export class RequireCertifSectionComponent implements OnInit {
  // STATIC DATA
  @Input() additionalData: any = {
    requirements: [],
    certifications: [],
    isThirdParty: false,
    isFourthParty: false,
    domains: [],
    customRequirements: [],
    customCertifications: [],
  };
  @Input() isDefaultSetting: boolean = false;
  @Input() vendorId: string = '';
  @Input() isNewVendor = false;
  @Output() additionalDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() customDefaultTypes = {
    SCANS: [],
    DOCUMENTS: [],
    OTHERS: [],
    CERTIFICATIONS: [],
  };
  customVendorReqs = {
    SCANS: [],
    DOCUMENTS: [],
    OTHERS: [],
    CERTIFICATIONS: [],
  };
  reqList = Object.values(REQUIREMENTS).map(val => {
    return { name: val, isQualify: false, templateURL: null };
  });
  certList: any;

  totalArtifactList = null;
  totalCertificateList = null;
  // MAIN VARIABLES
  isThird = true;
  isFourth = false;

  newActiveDomains = [];
  uploader: FileUploader;
  uploadPath = '';
  modalRef = null;
  isInformatica = UtilsService.isInformatica || UtilsService.isInformaticaUat;
  frameworkTypes = {
    requirement: 'Requirement',
    certificate: 'Certification',
  };
  customReqs = {
    SCANS: [],
    DOCUMENTS: [],
    OTHERS: [],
  };
  customCerifs = {
    CERTIFICATIONS: [],
  };
  CERTIFICATIONS: any;
  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fileService: FileService
  ) {
    this.CERTIFICATIONS = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.CERTIFICATIONS_ENUM);
  }

  async ngOnInit(): Promise<void> {
    const rootEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.certList = Object.values(this.CERTIFICATIONS).map(val => {
      return { name: val, isQualify: false, templateURL: null };
    });
    this.uploader = new FileUploader({ url: this.uploadPath });

    this.additionalData.requirements = !this.additionalData?.requirements?.length
      ? this.reqList
      : JSON.parse(JSON.stringify(this.additionalData.requirements));

    this.additionalData.certifications = !this.additionalData?.certifications?.length
      ? this.certList
      : JSON.parse(JSON.stringify(this.additionalData.certifications));

    this.additionalData.customRequirements = !this.additionalData?.customRequirements?.length
      ? []
      : JSON.parse(JSON.stringify(this.additionalData.customRequirements));

    this.additionalData.customCertifications = !this.additionalData?.customCertifications?.length
      ? []
      : JSON.parse(JSON.stringify(this.additionalData.customCertifications));
    this.additionalData.customRequirements = this.additionalData.customRequirements.map(data => {
      return {
        ...data,
        isQualify: false,
      };
    });
    this.additionalData.customCertifications = this.additionalData.customCertifications.map(data => {
      return {
        ...data,
        isQualify: false,
      };
    });
    this.additionalData.domains = Array.isArray(this.additionalData.domains)
      ? this.additionalData.domains
      : JSON.parse(this.additionalData.domains);
    this.totalArtifactList = [...this.additionalData.customRequirements, ...this.additionalData.requirements];
    this.totalCertificateList = [...this.additionalData.customCertifications, ...this.additionalData.certifications];
    const path = `DOCUMENT_TYPES/${rootEntityId}/${rootEntityId}.json`;
    await this.checkIfCustomFrameworksExist(path);
  }

  // check if custom framework files exist in s3, if exists get them
  async checkIfCustomFrameworksExist(path: string): Promise<void> {
    const itemsList = await this.fileService.checkIfPathExistsS3(path);
    if (itemsList && itemsList.length) {
      // /
      const typesList = await this.fileService.getFileByPathFromS3(path);
      Object.keys(typesList).map(type => {
        const items = typesList[type];
        const res = items.map(item => {
          return {
            name: item,
            value: item,
            selected: false,
          };
        });
        if (res.length) {
          this.customDefaultTypes[type] = res;
        }
      });
    }
  }

  updateRequirements(requirement: any): void {
    if (this.additionalData.requirements.find(req => req.name === requirement.name)) {
      this.additionalDataChange.emit({
        data: this.additionalData.requirements,
        type: AdditionalRequirements.REQUIREMENTS,
      });
    } else {
      this.updateCustomRequirements();
    }
  }

  updateCertifications(certification: any): void {
    if (this.additionalData.certifications.find(cert => cert.name === certification.name)) {
      this.additionalDataChange.emit({
        data: this.additionalData.certifications,
        type: AdditionalRequirements.CERTIFICATIONS,
      });
    } else {
      this.updateCustomCertifications();
    }
  }

  updateCustomRequirements(): void {
    this.additionalDataChange.emit({
      data: this.additionalData.customRequirements,
      type: AdditionalRequirements.CUSTOM_REQUIREMENTS,
    });
  }

  removeUserAddedCert(deletedDocument, type) {
    if (type === AdditionalRequirements.REQUIREMENTS) {
      const idx = this.totalArtifactList.findIndex(artifact => artifact.name === deletedDocument.name);
      if (idx > -1) {
        this.totalArtifactList.splice(idx, 1);
      }
    } else {
      const idx = this.totalCertificateList.findIndex(cert => cert.name === deletedDocument.name);
      if (idx > -1) {
        this.totalCertificateList.splice(idx, 1);
      }
    }
  }

  updateCustomCertifications(): void {
    this.additionalDataChange.emit({
      data: this.additionalData.customCertifications,
      type: AdditionalRequirements.CUSTOM_CERTIFICATIONS,
    });
  }
  updateDomains(index: number, value: string): void {
    let domains = this.additionalData.domains.map(d => d.value);
    domains[index] = value;
    domains = domains.filter(d => d);
    this.additionalDataChange.emit({ data: domains, type: AdditionalRequirements.DOMAINS });
  }

  deleteDomains(idx) {
    this.additionalData.domains.splice(idx, 1);
    this.additionalDataChange.emit({ data: this.additionalData.domains, type: AdditionalRequirements.DOMAINS });
  }

  updateIsThirdParty(event): void {
    this.additionalData.isThirdParty = event;
    this.additionalDataChange.emit({ data: event, type: AdditionalRequirements.ISTHIRDPARTY });
  }

  updateIsFourthParty(event): void {
    this.additionalData.isFourthParty = event;
    this.additionalDataChange.emit({ data: event, type: AdditionalRequirements.ISFOURTHPARTY });
  }

  /**
   * This function will be triggered if the user uploads a template for Artifacts
   * @param {any} eventFile this list will contain the file to upload
   */
  async uploadTemplateArtifacts(eventFile: any, artiName: any): Promise<void> {
    try {
      await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE);

      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      const zipped = new JSZip().file(eventFile[0].name, eventFile[0]);
      const zip = (await zipped.generateAsync({ type: 'blob' })) as any;
      const s3Object: any = {
        name: eventFile[0].name,
        entityId,
        fileType: FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE,
        contentType: zip.type,
        body: zip,
        date: Date.now(),
      };
      const uploadedObject = await this.fileService.uploadToS3(s3Object);
      let defaultIndex = this.additionalData.requirements.findIndex(requirement => requirement.name === artiName.name);
      if (defaultIndex > -1) {
        this.additionalData.requirements[defaultIndex].templateURL = uploadedObject;
      } else {
        defaultIndex = this.additionalData.customRequirements.findIndex(
          requirement => requirement.name === artiName.name
        );
        if (defaultIndex > -1) {
          this.additionalData.customRequirements[defaultIndex].templateURL = uploadedObject;
        }
      }
      this.updateRequirements(this.additionalData.requirements[defaultIndex]);
    } catch (e) {
      this.toastr.error(e);
      eventFile = null;
    }
  }

  /** *
   * helper function used to delete the template of the artifact/ requirement
   */
  deleteTemplate(obj: any): void {
    try {
      this.toastr.info('Removing the template. Please Wait... ');
      const index = this.additionalData.requirements.findIndex(req => req.name === obj.name);
      if (index > -1) {
        this.additionalData.requirements[index].templateURL = null;
        this.updateRequirements(this.additionalData.requirements[index]);
        this.toastr.success('Template Removed!');
      }
    } catch (e) {
      this.toastr.error('Template cannot be removed!');
    }
  }

  openAddVendorModal(type: string): void {
    const modalComponent = AddNewCustomFrameworkComponent;
    this.modalRef = this.modalService.open(modalComponent, {
      centered: true,
      size: 'lg',
      windowClass: this.isNewVendor ? 'new-custom-framework-popup-window-add-new' : 'new-custom-framework-popup-window',
      backdropClass: this.isNewVendor
        ? 'new-custom-framework-popup-backdrop-modal-add-new'
        : 'new-custom-framework-popup-backdrop-modal',
      backdrop: 'static',
    });
    this.modalRef.componentInstance.isDefaultSetting = this.isDefaultSetting;
    this.modalRef.componentInstance.customDefaultTypes = this.customDefaultTypes;
    this.modalRef.componentInstance.customVendorReqs = this.customVendorReqs;
    this.modalRef.componentInstance.type = type;
    this.modalRef.componentInstance.vendorId = this.vendorId;
    this.modalRef.componentInstance.closeModal.subscribe(val => {
      if (val) {
        const obj = {
          name: val.name,
          isQualify: true,
          documentCategory: val.type ? val.type.toUpperCase() : null,
          documentType: val.subType ? val.subType.toLowerCase().replaceAll(' ', '_') : val.subType,
          templateURL: val.templateURL,
          selectedTemplateType: val.selectedTemplateType,
          level: 'FULL',
          isUserAdded: true,
        };
        if (type === this.frameworkTypes.requirement) {
          this.additionalData.customRequirements.push(obj);
          this.totalArtifactList = [...this.additionalData.customRequirements, ...this.additionalData.requirements];
          this.updateCustomRequirements();
        } else {
          this.additionalData.customCertifications.push(obj);
          this.totalCertificateList = [
            ...this.additionalData.customCertifications,
            ...this.additionalData.certifications,
          ];
          this.updateCustomCertifications();
        }
      }
      this.modalRef.close();
    });
  }
}
