import { statusList, statusListForParticipant } from './filters-modal/filters-const';
import {
  GetQuestionDataQuery,
  ReassessmentEnum,
  TagsByAssessmentFrameworkKeyQuery,
  UpdateFrameworkManagerInput,
  UpdateQuestionSettingsMutation,
} from './../API.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  GetAnswerQuery,
  GetAssignmentQuery,
  GetUserQuery,
  GetEntityQuery,
  RoleEnum,
  GetQuestionSettingsQuery,
  QuestionSettingsByAssessmentIdQuery,
  UpdateQuestionSettingsInput,
  GetFrameworkManagerQuery,
  CreateFrameworkManagerInput,
  DeleteFrameworkManagerInput,
  ExportSourceEnum,
  GetCommentQuery,
  FrameworkScoresByAssessmentQuery,
  GetDisableAssignmentQuery,
  DisableAssignmentsByAssessmentIdQuery,
  AssignmentsByUserIdAndAssessmentQuery,
  GetRoleQuery,
  GetTagsQuery,
  GetLogsQuery,
  TagsByEntityIdQuery,
  LogsByTargetIdQuery,
  VersionsByTargetIdQuery,
  GetVersionsQuery,
  UpdateEntityInput,
  UpdateAssessmentInput,
  UpdateAssessmentStandardFrameworkInput,
  CreateAssessmentStandardFrameworkInput,
  GetAssessmentStandardFrameworkQuery,
  StandardType,
} from 'app/API.service';
import { ControlObj, QuestionObj } from './collection.model';
import { AnswerEnum } from 'app/shared/enums/answer.enum';
import { QuestionnaireService } from 'app/questionnaire/questionnaire.service';
import { UtilsService } from 'app/shared/utils.service';
import { Subject, Observable, BehaviorSubject, Subscription, from } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CollectionDeleteUserModalComponent } from './collection-delete-user-modal/collection-delete-user-modal.component';
import { CollectionTabs, CollectionQuestion, Question, IQuestionIdSet } from 'models/questionnaire.model';
import { EntityService } from 'app/shared/entity.service';
import { AssignmentTarget } from 'models/assignment-target.model';
import { APIService, DeleteAnswerInput, EntityQuestionSettings } from '../API.service';
import { ToastrService } from 'ngx-toastr';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { Auth } from 'aws-amplify';
import { ActivatedRoute } from '@angular/router';
import {
  CollectionConstant,
  HITRUST_SHEET_VAL,
  PCI_FRAMEWORKS,
  PCI_TOASTER_MESSAGE,
  BEAZLEY_TOASTER_MESSAGE,
  REPORT_CONTROLS,
  REASSESSMENT_CONFIRM_All,
  INSURANCE_APP_FRAMEWORKS,
  EntityNotifyTypeEnum,
} from './collection.constant';
import { v4 as uuid } from 'uuid';
// import { RiskRegisterService } from 'app/risk-register/risk-register.service';
import { SingleTypeQuestionActiveUser } from 'app/shared/types&Interfaces';
import * as JSZip from 'jszip';
import { FileService } from 'app/shared/file.service';
import * as Excel from 'exceljs';
import * as moment from 'moment';
import { Integration } from 'app/shared/enums/integration.enum';
import { ReportsService } from 'app/shared/reports.service';
import { UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { saveAs } from 'file-saver';
import { getLogMessage } from 'app/shared/helpers/logs.helper';
import { LogsKeyEnum, LogsTypeEnum } from 'app/shared/enums/logsKey.enum';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import Lambda from 'aws-sdk/clients/lambda';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { FiltersEnum } from 'app/shared/enums/filters.enum';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { environment } from 'environments/environment';
import { generateCollectionExcelFile, generateCollectionCSVFile } from './collection-csv-report-helper';
import { HttpClient } from '@angular/common/http';

/**
 *                  READ ME ( Most Important according to new architecture of Performance Optimization)
 * NOTE 1 :
 *         Any Functionality Related to Question ( Comments , Answers , Logs , Tags , Settings )
 *         don't create any new list you can get these data in questionSetCollectionCache
 */

class QuestionData {
  id: string;
  comments: GetCommentQuery[];
  answers: GetAnswerQuery[];
  questionLogs: GetLogsQuery[];
  settings: GetQuestionSettingsQuery[];
  tags: GetTagsQuery[];
  questionData: GetQuestionDataQuery[];
  constructor(id: string) {
    this.id = id;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CollectionService implements OnDestroy {
  private answeredQuestions: GetAnswerQuery[];
  private assignments: GetAssignmentQuery[] = [];
  private comments: GetCommentQuery[] = [];
  private questionSetCollectionCache: Map<string, QuestionData> = new Map<string, QuestionData>();
  private questionSetsStatus: Map<string, boolean> = new Map<string, boolean>();
  private answeredQuestionsMap: Map<string, GetAnswerQuery[]>;
  private questionsSettings: GetQuestionSettingsQuery[] = [];
  private currentUser: GetUserQuery;
  private usersList: GetUserQuery[] = [];
  private subEntity: GetEntityQuery;
  private frameworkSubEntitiesList: GetEntityQuery[];
  private selectedFramework = null;
  private artifactsMap: Map<string, any> = new Map<string, any>();
  private currentSubentity: GetEntityQuery;
  private isManager = false;
  public isParticipant = false;
  private isVendor = false;
  private activeTabObservable = new Subject();
  private currentSelectedControl;
  private currentQuestionSet: BehaviorSubject<[IQuestionIdSet[]]>;
  private selectedQuestionSet: BehaviorSubject<IQuestionIdSet[]>;
  public currentFramework: BehaviorSubject<any>;
  public isDataFound: BehaviorSubject<boolean>;
  public isRemediationAnswerChanged: BehaviorSubject<boolean>;
  public currentVersion: BehaviorSubject<any>;
  private isVendorQuestionnaire = false;
  public activeTabSubscriber = this.activeTabObservable.asObservable();
  public exportCSVTrigger: Subject<boolean> = new Subject();
  public disableSetting: boolean = false;
  public toggleVersion: Subject<any> = new Subject();
  public closeVersion: Subject<any> = new Subject();
  public exportCSVTriggerActivityLogs: Subject<boolean> = new Subject();
  public allSubEntities = {};
  public cardType = CollectionConstant.LABELS.allFrameworks.replace(/ /g, '_');
  public isGrouped = true;
  public singleFrameworkSubId: string;
  public isAllSubGenerated = false;
  public editMode = new BehaviorSubject(false); // edit mode for collection framework
  public allFrameworksOnce = [];
  public frameworkIntegrationMappingObject = {};
  public integrationsResponses = {};
  public integrationMappingByQuestion = {};
  public availableIntegrationQuestions = {};
  private openTabAndScroll = new Subject<any>(); // tab to be opened
  private firstNonAnsweredId = '';
  private selectedTags = {};
  private updatedQuestion: CollectionQuestion;
  private updatedQuestions: CollectionQuestion[] = [];
  private managersList: GetFrameworkManagerQuery[] = [];
  private CollectionIntegrations = {};
  private currViewPortQuestion: Question = null; // contained curr question in curr view port in integration tab
  private selectedFrameworkIntegrationsMapping: any = {};
  private selectedFrameworkSettings: any = {};
  private deniedList: any[] = [];
  private artifactList: any[] = [];
  private filters: any[] = [];
  private questionTemplateMapper: boolean[] = [];
  private isHashFound: boolean = false;
  private withHash: string = '';
  private selectedFrameworkManagers = [];
  onLogsCreate: Subject<GetLogsQuery> = new Subject<GetLogsQuery>();
  onVersionsCreate: Subject<GetVersionsQuery> = new Subject<GetVersionsQuery>();
  onTagsCreate: Subject<GetTagsQuery> = new Subject<GetTagsQuery>();
  onTagsDelete: Subject<GetTagsQuery> = new Subject<GetTagsQuery>();
  isIntegrationRun: Subject<boolean> = new Subject<boolean>();
  saveForAllGuide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  saveForAllGuide$ = this.saveForAllGuide.asObservable();
  selectedControl: BehaviorSubject<null>;

  collectionLoadingPercentage: Subject<any> = new Subject<any>();
  public onReassessAllSubject: Subject<boolean> = new Subject<boolean>();

  //* Answers related Subjects
  onUpdateAnswerSub: Subject<GetAnswerQuery> = new Subject<GetAnswerQuery>();
  onCreateNewAnswerSub: Subject<GetAnswerQuery> = new Subject<GetAnswerQuery>();
  onDeleteAnswerSub: Subject<GetAnswerQuery> = new Subject<GetAnswerQuery>();

  //* Assignments related Subjects used for subscriptions
  onCreateNewAssignmentSub: Subject<GetAssignmentQuery> = new Subject<GetAssignmentQuery>();
  onDeleteAssignmentSub: Subject<GetAssignmentQuery> = new Subject<GetAssignmentQuery>();

  collectionLoadedValue: number = 0;
  isFromLeftMenu: boolean = false;
  cachedTagsList = [];
  public questionsCount: BehaviorSubject<number>;
  private infoSec_exist: boolean = false;
  public isUserParticipant: boolean = false;
  tagsAgainstAllQuestions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  updateIntegrationTabs$: Subject<any> = new Subject<any>();
  public draftMsgCheck: any = {};
  private currFrameworkAssessmentId: string;
  commentAnswerMapper = {};
  mandatoryMessageMapper = {};
  isFromRiskReg: any;
  previousAnswer = {
    answer: '',
    selectedAnswer: '',
    event: '',
  };
  onEntitySelectionSmartMapping$: Subject<any> = new Subject<any>();
  smartMapRelMeta: any = {};
  smartMapPercentage: any = {};
  standardFrameWorks: any = {};
  leftControlClicked: boolean = false;
  domain: string;
  isCardOpened: boolean = false;
  // frameworkDetails: any = [];
  openedQuestionId: string = '';
  private enableFinalize: boolean = false;
  private enableReassessConfirmAll: boolean = false;
  private isAssessmentLocked: any = {};
  eSignatureSubscription: Subject<any> = new Subject<any>();
  integrationCaching: any = {};
  modalClosedSource = new Subject<void>();
  statusFilters: any = [];
  filterAssignedUser: any;
  setDisableFinalize(isFinalized: boolean) {
    this.enableFinalize = isFinalized;
  }
  getDisableFinalize() {
    return this.enableFinalize;
  }
  setAssessmentLocked(isLocked: boolean, assessmentId: string) {
    this.isAssessmentLocked[assessmentId] = { isLocked };
  }
  getAssessmentLocked(assessmentId: string) {
    return this.isAssessmentLocked[assessmentId]?.isLocked ?? false;
  }
  setDisableReassessConfirmAll(b: boolean) {
    this.enableReassessConfirmAll = b;
  }

  getDisableReassessConfirmAll(): boolean {
    return this.enableReassessConfirmAll;
  }
  tagsObj = {};

  private fetchAssignmentPromises: { [parentId: string]: Promise<GetAssignmentQuery[]> } = {};
  setSelectedTags(assessmentAndFramework: string, allTags: any[]): void {
    this.selectedTags[assessmentAndFramework] = allTags;
  }
  getSelectedTags(assessmentAndFramework: string): any[] {
    return this.selectedTags[assessmentAndFramework];
  }
  setTagsAgainstAllQuestions(assessmentAndFramework: string, allTags: any[]): void {
    this.tagsObj[assessmentAndFramework] = allTags;
    this.tagsAgainstAllQuestions.next(this.tagsObj);
  }

  getTagsAgainstAllQuestions(activeAssessmentId): any {
    const allTags = [];
    const tagsAgainstAllQuestions = this.tagsAgainstAllQuestions.getValue();
    if (UtilsService.isDefined(tagsAgainstAllQuestions)) {
      let tags = [];
      Object.entries(tagsAgainstAllQuestions).map(([key, value]) => {
        tags = value as [];
        if (key.split('#')[0] === activeAssessmentId) {
          tags.forEach(obj => {
            allTags.push({
              name: obj.name,
              selected: false,
            });
          });
        }
      });
    }

    return allTags;
  }

  updateGuideSections(control) {
    if (control) {
      const framework: any = this.currentFramework?.value;
      framework.children?.forEach(node => this.addGuideSections(node, control));
      this.currentFramework.next(framework);
    }
  }

  addGuideSections = (node, control) => {
    // If the node is a CONTROL, add matching guide sections
    if (node?.guideSection?.length) {
      if (control.name === node.name) {
        node.guideSection = control.guideSection;
      }
    }
    // If the node has children, recursively process each child
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => this.addGuideSections(child, control));
    }
  };

  // Note:
  // Only Single type question will be present here, with active tab user assignment.
  // So you can see required user's active tab after update answer
  public singleTypeQuestionActiveUsers: SingleTypeQuestionActiveUser = {};

  subscriptions: Subscription[];
  // impactFilterChange: Subject<string> = new Subject<string>();
  searchArtifactsChange: Subject<string> = new Subject<string>();
  // answerFilterChange: Subject<string> = new Subject<string>();
  allFiltersChange: Subject<any> = new Subject<any>();
  disableUserAssignmentVal: string = 'DisableAssignment';

  cardSelectedType: Subject<(string | boolean)[]> = new Subject<(string | boolean)[]>();

  startSetup: Subject<any> = new Subject<any>();

  onUpdateFrameworkScores: Subject<any> = new Subject<any>();
  OnUpdateAssessmentStandardFramework: Subject<any> = new Subject<any>();
  assignmentsChange: Subject<GetAssignmentQuery[]> = new Subject<GetAssignmentQuery[]>();
  usersListChange: Subject<GetUserQuery[]> = new Subject<GetUserQuery[]>();
  answeredQuestionsMapsChange: Subject<Map<string, GetAnswerQuery[]>> = new Subject<Map<string, GetAnswerQuery[]>>();
  artifactsMapChange: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  // type issues even though they have same properties.
  // questionsSettingsChange = new Subject<UpdateQuestionSettingsInput[]>();
  questionsSettingsChange = new Subject<GetQuestionSettingsQuery[]>();
  activeTab: Subject<any> = new Subject<any>();
  controlClicked: Subject<any> = new Subject<any>(); // used for scroll on click integration tab
  modalReference: NgbModalRef;
  ngbModalOptions: NgbModalOptions = {
    windowClass: 'collection-modal-windowClass',
    backdropClass: 'collection-modal-backdropClass',
  };
  currentSelectedSet: IQuestionIdSet[] = [];
  public isQuestionnaireAssigned = new BehaviorSubject(null); // To check if there is any questionnaire assigned to the current role

  frameworkZip = null;
  blobArray = [];
  fileNames: any = [];

  hiTrustUniqueIDS = {};
  hiTrustTemplate = null;
  metaDataLoaded = false;

  currentUserRole: GetRoleQuery; // Adding currentUserRole which will be removed and replaced with user.role after making connection.
  isArchivedMode: boolean = false; // Check for current screen is archive or active
  intFolderExists: boolean = false;
  currentRootId = null;
  selectedQuestionId: string;
  currentControlFirstID: string;
  timer: ReturnType<typeof setTimeout>;
  // code for fourth Party
  vendorDetails: any;
  allTagsForEntity: any = {};
  allTagsEntityId: any = {};
  allFrameworksAnswersObj: any = {};
  isAssignmentInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  assessmentStandardFrameworkMap: {
    [id: string]: GetAssessmentStandardFrameworkQuery;
  } = {};
  selectedControlSet: any = [];
  frameworkQuestionIdsCache = {};
  HITRUST = 'HITRUST';
  selectedQuestion: any;
  isMidMarketUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reAssessmentStarted$: Subject<any> = new Subject<any>();
  questionsData: any = [];
  filteredQuestionIDs = []; //* for excel report filtered questions
  /**
   *     GlobaL Promises To fetch the Data in the back Ground JOB
   */
  backGroundPromises = [];
  cachedQuestionnnaireCompletion = {};
  cachedFrameworkScores = {};
  currentUserToken: string;
  constructor(
    private domainFrameworkService: DomainFrameworkService,
    private questionnaireService: QuestionnaireService,
    private authService: AuthService,
    private entityService: EntityService,
    private apiService: APIService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    // private riskService: RiskRegisterService,
    private fileService: FileService,
    private reportService: ReportsService,
    private userService: UserService,
    private clientLambdaService: ClientLambdaService,
    private customApi: CUSTOMAPIService,
    private httpClient: HttpClient
  ) {
    from(Auth.currentSession()).subscribe((currentSession: any) => {
      if (!currentSession) {
        return;
      }
      this.currentUserToken = currentSession.getAccessToken().getJwtToken();
      this.loadSubscriptions();
    });

    this.customApi.webSocketConnectionListner$.subscribe(hubState => {
      if (hubState?.isConnectionClosed) {
        this.subscriptions.forEach(listener => listener?.unsubscribe());
        this.subscriptions = [];
      } else if (!hubState?.isConnectionClosed && !this.subscriptions?.length) {
        this.loadSubscriptions();
      }
    });
    this.currentQuestionSet = new BehaviorSubject<[IQuestionIdSet[]]>([[]]);
    this.selectedQuestionSet = new BehaviorSubject<IQuestionIdSet[]>([]);
    this.selectedControl = new BehaviorSubject<any>(null);
    this.currentFramework = new BehaviorSubject<any>(null);
    this.currentVersion = new BehaviorSubject<any>(null);
    this.isDataFound = new BehaviorSubject<boolean>(true);
    this.isRemediationAnswerChanged = new BehaviorSubject<boolean>(false);
    this.questionsCount = new BehaviorSubject<number>(0);
    const currentUser = this.userService.getCurrentRole();
    this.domain = window.location.hostname;
    if (currentUser) {
      // Setting participant value before loading other things to avoid unnecessary permissions
      this.isParticipant = currentUser.name?.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase();
    }
  }

  async getFrameworksDetails(userId: string): Promise<any> {
    const result: any = await this.customApi.FrameworkManagersByManagerId(userId);
    return result?.items?.length ? result?.items : [];
  }

  async initializeCollectionService(subEntity: GetEntityQuery, vendorUser, isThirdParty = false): Promise<void> {
    // const loggedInUser: any = this.userService.currentUser;
    // this.frameworkDetails = await this.getFrameworksDetails(loggedInUser.id);
    // making this list empty because it's not being used in remediation
    // and creates inconsistent, conflicting data of answers in collection-question-card
    this.updatedQuestions = [];
    this.currentSubentity = subEntity;

    await Promise.all([this.setCurrentUser(vendorUser ? vendorUser : null)]);
    this.isVendor = this.currentUserRole.name.toLowerCase() === RoleEnum.VENDOR.toLowerCase();
    this.isParticipant = this.currentUserRole.name.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase();
    this.isManager = this.isUserManager(this.currentUser.id);
    const promises = [];
    // Stop the Answers to get All answers against the current Assessment
    // promises.push(this.setInitialAnsweredQuestions(subEntity.activeAssessmentId));
    // promises.push(this.setInitialAssignments(subEntity.activeAssessmentId, this.currentUser));
    // promises.push(this.setInitialQuestionsSettings(subEntity.activeAssessmentId)); for temporary purpose we have stopped it
    if (!this.getShowAllFrameworks()) {
      // promises.push(this.riskService.getControlsList(subEntity.activeAssessmentId));
    }
    // if (this.hasPermission() || this.isParticipant || this.isVendorPOC()) {
    //   promises.push(this.setUsersList(subEntity.parentId, subEntity.id, this.getCurrentRole(), isThirdParty));
    // }

    this.setSubEntity(subEntity);
    await Promise.all(promises);
    // THis Function Should Not be executed Here
    // this.entityService.setArchivedUserToDeletedUserAns(
    //   this.assignments,
    //   this.answeredQuestions,
    //   this.answeredQuestionsMap
    // );

    this.managersList = [];
    const subEntityCollectionData = {
      answeredQuestions:
        this.allSubEntities[this.subEntity.id] && this.allSubEntities[this.subEntity.id]?.answeredQuestions
          ? this.allSubEntities[this.subEntity.id]?.answeredQuestions
          : [],
      answeredQuestionsMap:
        this.allSubEntities[this.subEntity.id] && this.allSubEntities[this.subEntity.id]?.answeredQuestionsMap
          ? this.allSubEntities[this.subEntity.id]?.answeredQuestionsMap
          : [],
      assignments:
        this.allSubEntities[this.subEntity.id] && this.allSubEntities[this.subEntity.id]?.assignments
          ? this.allSubEntities[this.subEntity.id]?.assignments
          : [],
      managersList:
        this.allSubEntities[this.subEntity.id] && this.allSubEntities[this.subEntity.id]?.managersList
          ? this.allSubEntities[this.subEntity.id]?.managersList
          : [],
      entityId: this.subEntity.id,
      entityName: this.subEntity.name,
      entityDetails: this.subEntity,
    };
    this.allSubEntities[this.subEntity.id] = subEntityCollectionData;

    // Disable code to Verify With multiple integrations with different frameworks
    // ==============================================================
    // this.frameworkIntegrationMappingObject = {
    //   NIST_CSF: {
    //     RAPID7: { 1: ['a1', 'a2'], 95: ['a4', 'a5'] },
    //   },
    //   FFIEC: {
    //     TENABLE: { 45: [1111, 24325], 489: [32323535, 34646574] },
    //   },
    // };
  }

  // Commenting this code as this logic shifted to other function on demand.
  // Only we will check if integration is applied or not.
  /**
   * Function to load integrations
   */
  async loadIntegrationInBackground(): Promise<void> {
    // constructing data for INTEGRATION tab
    // const { JIRA, SERVICE_NOW, ...rest } = Integration;
    // this.CollectionIntegrations = rest;

    this.currentRootId = await UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');

    /**
     * BugFix for Bug(produces rarely)
     * ============================
     * Bug: integration tab active even if no integration is connected.
     */
    const intFolderExists = await this.fileService.checkIfPathExistsS3(`CLIENTS/${this.currentRootId}/INTEGRATIONS`);
    this.intFolderExists = !!(intFolderExists && intFolderExists.length);
    // pass filter that Contains the EntityType as Child_entity

    // if child entities are not fetched already make an api call
    // this will handle the case for refreshing on the collection tab
    // if (!this.entityService.subEntitiesListByParent?.length) {
    //   this.entityService.subEntitiesListByParent = await this.entityService.listChildEntitysByParentId(
    //     this.currentRootId
    //   );
    // }

    // await Promise.all([
    //   ...Object.keys(this.CollectionIntegrations).map(async serviceName => {
    //     const currServiceName = serviceName === Azure.AZURE ? Integration.AZURE.toUpperCase() : serviceName;
    //     // await this.findAllFrameworksOnce(this.entityService.subEntitiesListByParent);
    //     // await this.createFrameworkIntegrationMappingObject(currServiceName);
    //     // await this.loadIntegrationAPIResponse(currServiceName);
    //   }),
    // ]);

    // this.indexMappingObjByQuestion();
    // this.addIntegrationDetails();
  }

  reInitializeCollectionService(subEntityId: string, controls: any): void {
    this.setPreviousSub();
    this.setCurrentSub(subEntityId);
    this.setArtifactsMap(controls);
    // this.riskService.setControlsList(this.getSubEntity().activeAssessmentId);
  }

  setQuestionsCount(count): void {
    this.questionsCount.next(count);
  }
  setPreviousSub(): void {
    const previousSub = this.getSubEntity();
    if (!UtilsService.isIdFake(previousSub.id)) {
      const previousSubDate = {
        answeredQuestions: this.answeredQuestions,
        answeredQuestionsMap: this.answeredQuestionsMap,
        assignments: this.allSubEntities[previousSub.id]?.assignments?.length
          ? this.allSubEntities[previousSub.id]?.assignments
          : [],
        questionsSettings: this.questionsSettings,
        usersList: this.usersList,
        entityId: this.subEntity.id,
        entityName: this.subEntity.name,
        entityDetails: this.subEntity,
        managersList: this.allSubEntities[previousSub.id]?.managersList?.length
          ? this.allSubEntities[previousSub.id]?.managersList
          : [],
      };

      this.allSubEntities[previousSub.id] = previousSubDate;
    }
  }

  getIsArchiveMode(): boolean {
    return this.isArchivedMode;
  }

  setCurrentSub(subId: string): void {
    this.answeredQuestions = this.allSubEntities[subId].answeredQuestions;
    this.answeredQuestionsMap = this.allSubEntities[subId].answeredQuestionsMap;
    this.assignments = this.allSubEntities[subId].assignments;
    this.questionsSettings = this.allSubEntities[subId].questionsSettings;
    this.usersList = this.allSubEntities[subId].usersList;
    this.subEntity = this.allSubEntities[subId].entityDetails;
    this.managersList = this.allSubEntities[subId].managersList;
  }

  setSingleTypeQuestionActiveUsers(key: string, value: GetAssignmentQuery): void {
    this.singleTypeQuestionActiveUsers[key] = value;
  }

  getSingleTypeQuestionActiveUsers(questionId: string): GetAssignmentQuery {
    return this.singleTypeQuestionActiveUsers[questionId];
  }

  async initializeCollectionServiceAll(subEntityList: GetEntityQuery[]): Promise<void> {
    this.updatedQuestions = [];
    await this.setCurrentUser(null);
    this.isVendor = this.currentUserRole.name.toLowerCase() === RoleEnum.VENDOR.toLowerCase();
    this.isParticipant = this.currentUserRole.name.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase();
    // const promises = [];
    // this.allSubEntities = {};

    const assessmentIds = [];
    // const assignmentsPromises = [];
    subEntityList.map((sub, index) => {
      if (!UtilsService.isIdFake(sub.id)) {
        // start from here
        assessmentIds.push({ assessId: sub?.activeAssessmentId, subEntityId: sub.id });
        const subEntityCollectionData = {
          answeredQuestions:
            this.allSubEntities[sub.id] && this.allSubEntities[sub.id]?.answeredQuestions
              ? this.allSubEntities[sub.id]?.answeredQuestions
              : [],
          answeredQuestionsMap:
            this.allSubEntities[sub.id] && this.allSubEntities[sub.id]?.answeredQuestionsMap
              ? this.allSubEntities[sub.id]?.answeredQuestionsMap
              : [],
          assignments:
            this.allSubEntities[sub.id] && this.allSubEntities[sub.id]?.assignments
              ? this.allSubEntities[sub.id]?.assignments
              : [],
          managersList:
            this.allSubEntities[sub.id] && this.allSubEntities[sub.id]?.managersList
              ? this.allSubEntities[sub.id]?.managersList
              : [],
          entityId: sub.id,
          entityName: sub.name,
          entityDetails: sub,
        };
        this.allSubEntities[sub.id] = subEntityCollectionData;

        if (index !== 0) {
          // This Function Need Optimization ( promise Handler is Calling same API UsersByRoleId subEntities.length times )
          // that Must be called only one time for the Default roles. that make issues.
          // In Phase 2 we will get only the necessary users.
          // 5 get activrAssement IDs ,and push them in array ,and pass them to promiseHandler
          // promises.push(this.promiseHandler(sub));
          // }
        }
      }
    });
    // for (let i = 0; i < assessmentIds.length; i += 5) {
    //   assignmentsPromises.push(this.fetchAssignments(assessmentIds.slice(i, i + 5)));
    // }
    // await Promise.all(promises);
    // await Promise.all(assignmentsPromises);
    this.isAllSubGenerated = true;

    // Object.keys(this.allSubEntities).map(async subEntityId => {
    //   const { activeAssessmentId: assessmentId } = this.allSubEntities[subEntityId].entityDetails;
    //   await this.riskService.getControlsList(assessmentId, true);
    // });

    this.startSetup.next(this.allSubEntities);
  }

  getIsSubGenerated(): boolean {
    return this.isAllSubGenerated;
  }

  // Not in use anymore
  // findAllFrameworksOnce = async subEntitiesResp => {
  //   let subEntities = [];
  //   const allFrameworksFilterON = this.getShowAllFrameworks();
  //   if (allFrameworksFilterON) {
  //     if (!subEntitiesResp) {
  //       console.log('could not fetch sub entities of root entity');
  //       return;
  //     }
  //     subEntities = subEntitiesResp;
  //   } else {
  //     // fetch only frameworks inside current sub entity
  //     const subEntityId = await UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
  //     const subEntity = await this.customApi.GetEntity(subEntityId);
  //     subEntities.push(subEntity);
  //   }

  //   // put frameworks in array (without duplication)
  //   subEntities.forEach(subEntity => {
  //     if (
  //       subEntity &&
  //       subEntity.activeAssessment &&
  //       subEntity.activeAssessment.standardFrameworkList &&
  //       subEntity.activeAssessment.standardFrameworkList.items &&
  //       subEntity.activeAssessment.standardFrameworkList.items.length
  //     ) {
  //       subEntity.activeAssessment.standardFrameworkList.items.forEach(framework => {
  //         // only risk frameworks are incorporated with integrations, so filter any other framework
  //         if (framework.type === StandardType.RISK_FRAMEWORK) {
  //           this.allFrameworksOnce.push(framework.key);
  //         }
  //       });

  //       // multiple assessmnets having nist csf will add framework twice
  //       this.allFrameworksOnce = [...new Set(this.allFrameworksOnce)];
  //     }
  //   });
  // };

  /**
   * Below function will run over the the root entity integrations array.
   * And it will get mapping files of enabled integrations against the current risk framework at the moment.
   *
   * @param integrations => is the array of integrations enabled in the current root entity.
   * @param currentFramework => is RISK Framework against which we will get the mapping files.
   * @return void => as it will only popualate
   */
  async initializeIntegrationProcess(
    integrations: any[] = [],
    currentFramework: string = null,
    rootEntityId: string = null
  ): Promise<void> {
    try {
      if (integrations?.length) {
        await Promise.all(
          integrations.map(async integration => {
            if (integration.isEnable) {
              let serviceName = null;
              Object.values(Integration).forEach(value => {
                if (integration.name.includes(value)) {
                  serviceName = value.toUpperCase();
                }
              });
              if (!this.integrationCaching[`${serviceName}_${currentFramework}`]) {
                this.integrationCaching[`${serviceName}_${currentFramework}`] = true;
                await this.createFrameworkIntegrationMappingObject(serviceName, currentFramework);
                await this.loadIntegrationAPIResponse(serviceName, rootEntityId);
              }
            }
          })
        );
        this.indexMappingObjByQuestion();
        this.addIntegrationDetails();
      }
    } catch (e) {
      console.log('Cannot initialize the integrations process due to ----> ', e);
      return null;
    }
  }

  emitSaveForAllGuide(value: boolean): void {
    this.saveForAllGuide.next(value);
  }
  createFrameworkIntegrationMappingObject = async (
    serviceName: string,
    currentFrameworkName: string
  ): Promise<void> => {
    try {
      const filePath = this.getIntegrationFrameworkPath(serviceName, currentFrameworkName);
      const existingFiles = await this.fileService.checkIfPathExistsS3(filePath);

      if (!existingFiles || !existingFiles.length) {
        // integration framework not saved for this framework
        // need to place csv in s3 RELATIONS/INTEGRATIONS/{integrationName}/FRAMEWORK_FILES
        return;
      }

      const url = await this.fileService.getFileUrlFromBucket(filePath, {
        contentType: 'application/json',
        level: 'public',
      });

      const frameworkIntegrationMapping: string = await this.fileService.getFile(url);
      const frameworkIntegrationMappingJSON: any = JSON.parse(frameworkIntegrationMapping);

      if (!frameworkIntegrationMappingJSON || !frameworkIntegrationMappingJSON.framework) {
        console.log(`Could not fetch question Indexed with ${serviceName}`);
        return;
      }

      // questions mapped to service record IDs
      const indexedQuestions = frameworkIntegrationMappingJSON.framework;
      // will display(later) ONLY those questions which are NOT EMPTY
      this.frameworkIntegrationMappingObject[currentFrameworkName] = {
        ...this.frameworkIntegrationMappingObject[currentFrameworkName],
        ...{
          [serviceName]: Object.keys(indexedQuestions).reduce((acc, curr) => {
            return indexedQuestions[curr].length
              ? {
                  ...acc,
                  [curr]: frameworkIntegrationMappingJSON.framework[curr],
                }
              : acc;
          }, {}),
        },
      };
    } catch (err) {
      console.log('createFrameworkIntegrationMappingObject error - ', err);
      return;
    }
  };

  indexMappingObjByQuestion = () => {
    this.integrationMappingByQuestion = this.indexByQuestionRecursive(
      this.frameworkIntegrationMappingObject,
      Object.keys(this.frameworkIntegrationMappingObject),
      [],
      {}
    );

    // Object Structure: integrationMappingByQuestion
    // ==============================================
    //
    //      NIST_CSF
    //          └──12
    //          \    └──TENABLE
    //          \    \     └──[122222,222224,1000003]
    //          \    \
    //          \    └──RAPID7
    //          \          └──['dd1.4','dd1.6']
    //          └──16
    //              └──TENABLE
    //              \      └──[100034]
    //              \
    //              └──RAPID7
    //                     └──['dd1.3']
  };

  indexByQuestionRecursive = (object, keys, references, result) => {
    if (!Array.isArray(object)) {
      keys.map(key => {
        references.push(key);
        this.indexByQuestionRecursive(object[key], Object.keys(object[key]), references, result);
        references.pop();
      });
    } else {
      const [framework, serviceName, question] = references;

      result[framework] = !result[framework] ? {} : result[framework];

      result[framework][question] = !result[framework][question]
        ? {
            [serviceName]: this.frameworkIntegrationMappingObject[framework][serviceName][question],
          }
        : {
            ...result[framework][question],
            [serviceName]: this.frameworkIntegrationMappingObject[framework][serviceName][question],
          };
    }
    return result;
  };

  addIntegrationDetails = () => {
    this.findAndLoadDetailsRecursive(
      this.integrationMappingByQuestion,
      Object.keys(this.integrationMappingByQuestion),
      []
    );
  };

  findAndLoadDetailsRecursive = (object, keys, references) => {
    if (!Array.isArray(object)) {
      keys.map(key => {
        references.push(key);
        this.findAndLoadDetailsRecursive(object[key], Object.keys(object[key]), references);
        references.pop();
      });
    } else {
      const [framework, question, serviceName] = references;
      switch (serviceName.toLowerCase()) {
        case Integration.TENABLE:
          if (!this.integrationsResponses[serviceName.toUpperCase()]) {
            // Could not fetch saved response of tenable from s3.
            return;
          }

          this.availableIntegrationQuestions[framework] = !this.availableIntegrationQuestions[framework]
            ? {}
            : this.availableIntegrationQuestions[framework];
          this.availableIntegrationQuestions[framework][question] = !this.availableIntegrationQuestions[framework][
            question
          ]
            ? {}
            : this.availableIntegrationQuestions[framework][question];

          this.availableIntegrationQuestions[framework][question][serviceName] = this.integrationMappingByQuestion[
            framework
          ][question][serviceName]
            .map(ID => this.integrationsResponses[serviceName].filter(record => parseInt(record.pluginID, 10) === ID))
            .flat();

          // deleting questions whose tenable mapped ID are not found in Response obj
          if (!this.availableIntegrationQuestions[framework][question][serviceName].length) {
            delete this.availableIntegrationQuestions[framework][question];
            // deleted Order: question
          }
          break;
        case Integration.CROWDSTRIKE:
          if (!this.integrationsResponses[serviceName.toUpperCase()]) {
            // Could not fetch saved response of CS from s3.
            return;
          }

          this.availableIntegrationQuestions[framework] = !this.availableIntegrationQuestions[framework]
            ? {}
            : this.availableIntegrationQuestions[framework];
          this.availableIntegrationQuestions[framework][question] = !this.availableIntegrationQuestions[framework][
            question
          ]
            ? {}
            : this.availableIntegrationQuestions[framework][question];

          this.availableIntegrationQuestions[framework][question][serviceName] = this.integrationMappingByQuestion[
            framework
          ][question][serviceName]
            .map(ID =>
              this.integrationsResponses[serviceName].filter(
                record => record.behavior && parseInt(record.behavior.behavior_id, 10) === parseInt(ID, 10)
              )
            )
            .flat();

          // deleting questions whose tenable mapped ID are not found in Response obj
          if (!this.availableIntegrationQuestions[framework][question][serviceName].length) {
            delete this.availableIntegrationQuestions[framework][question];
            // deleted Order: question
          }
          break;
        case Integration.RAPID7:
          if (!this.integrationsResponses[serviceName.toUpperCase()]) {
            // Could not fetch saved response of r7 from s3.
            return;
          }

          this.availableIntegrationQuestions[framework] = !this.availableIntegrationQuestions[framework]
            ? {}
            : this.availableIntegrationQuestions[framework];
          this.availableIntegrationQuestions[framework][question] = !this.availableIntegrationQuestions[framework][
            question
          ]
            ? {}
            : this.availableIntegrationQuestions[framework][question];

          this.availableIntegrationQuestions[framework][question][serviceName] = this.integrationMappingByQuestion[
            framework
          ][question][serviceName]
            .map(ID => this.integrationsResponses[serviceName].filter(record => record.id === ID))
            .flat();

          // deleting questions whose tenable mapped ID are not found in Response obj
          if (!this.availableIntegrationQuestions[framework][question][serviceName].length) {
            delete this.availableIntegrationQuestions[framework][question];
            // deleted Order: question
          }
          break;
        case Integration.AZURE:
          if (!this.integrationsResponses[serviceName.toUpperCase()]) {
            // Could not fetch saved response of r7 from s3.
            return;
          }

          this.availableIntegrationQuestions[framework] = !this.availableIntegrationQuestions[framework]
            ? {}
            : this.availableIntegrationQuestions[framework];
          this.availableIntegrationQuestions[framework][question] = !this.availableIntegrationQuestions[framework][
            question
          ]
            ? {}
            : this.availableIntegrationQuestions[framework][question];

          this.availableIntegrationQuestions[framework][question][serviceName] = this.integrationMappingByQuestion[
            framework
          ][question][serviceName]
            .map(ID => this.integrationsResponses[serviceName].filter(record => record.uid === ID))
            .flat();

          // deleting questions whose uid mapped ID are not found in Response obj
          if (!this.availableIntegrationQuestions[framework][question][serviceName].length) {
            delete this.availableIntegrationQuestions[framework][question];
            // deleted Order: question
          }
          break;
        case Integration.MICROSOFT:
          if (!this.integrationsResponses[serviceName.toUpperCase()]) {
            // Could not fetch saved response of r7 from s3.
            return;
          }

          this.availableIntegrationQuestions[framework] = !this.availableIntegrationQuestions[framework]
            ? {}
            : this.availableIntegrationQuestions[framework];
          this.availableIntegrationQuestions[framework][question] = !this.availableIntegrationQuestions[framework][
            question
          ]
            ? {}
            : this.availableIntegrationQuestions[framework][question];

          this.availableIntegrationQuestions[framework][question][serviceName] = this.integrationMappingByQuestion[
            framework
          ][question][serviceName]
            .map(ID => this.integrationsResponses[serviceName].filter(record => record.id === ID))
            .flat();

          // deleting questions whose mapped ID are not found in Response obj
          if (!this.availableIntegrationQuestions[framework][question][serviceName].length) {
            delete this.availableIntegrationQuestions[framework][question];
            // deleted Order: question
          }
          break;
      }
    }
  };

  loadIntegrationAPIResponse = async (serviceName: string, rootEntityId: string = null) => {
    try {
      if (!this.integrationsResponses[serviceName]) {
        const path = this.getIntegrationResponsePath(serviceName, rootEntityId);
        const fileExist = await this.fileService.checkIfPathExistsS3(path);

        if (!fileExist || !fileExist.length) {
          // api file response not saved for this root entity
          // Need to 'RUN' from collection screen
          // console.log(`api response for ${serviceName} for this root entity does not exist`);
          return;
        }

        const url = await this.fileService.getFileUrlFromBucket(path, {
          contentType: 'application/json',
          level: 'public',
        });
        const apiResponse: string = await this.fileService.getFile(url);
        this.integrationsResponses[serviceName] = JSON.parse(apiResponse);
      }
    } catch (error) {
      console.log('loadIntegrationAPIResponse error - ', error);
    }
  };

  getIntegrationFrameworkPath = (serviceName: string, frameworkKey: string): string => {
    return `RELATIONS/INTEGRATIONS/${serviceName}/${frameworkKey}.json`;
  };

  getIntegrationResponsePath = (serviceName: string, rootId) => {
    if (serviceName.toLowerCase() === Integration.AZURE.toLowerCase()) {
      return `CLIENTS/${rootId}/INTEGRATIONS/azure_defender_frontend.json`;
    }
    return `CLIENTS/${rootId}/INTEGRATIONS/${serviceName.toLowerCase()}.json`;
  };

  async promiseHandler(subEntity): Promise<void> {
    const [managersListLocalObj] = await Promise.all([
      this.customApi.FrameworkManagersByAssessmentId(subEntity?.activeAssessmentId),
    ]);
    const managersListLocal = managersListLocalObj.items;
    this.allSubEntities[subEntity.id].managersList = managersListLocal;
  }

  async fetchAssignmentsByAssessmentId(assessmentId: string = null, subEntityId: string = null): Promise<any> {
    try {
      // eslint-disable-next-line
      if (!this.fetchAssignmentPromises[assessmentId]) {
        this.fetchAssignmentPromises[assessmentId] = this.fetchAssignments(assessmentId, subEntityId);
      }
      await this.fetchAssignmentPromises[assessmentId];
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchAssignments(assessmentId: string = null, subEntityId: string = null): Promise<any> {
    try {
      if (subEntityId[0] !== 'F') {
        if (this.allSubEntities[subEntityId.toLowerCase()]?.assignments?.length) {
          return this.allSubEntities[subEntityId.toLowerCase()]?.assignments;
        }
        if (this.hasPermission()) {
          const assessmentIds = [
            {
              assessId: assessmentId,
              subEntityId,
            },
          ];
          const assignmentsPromises = [];
          for (let i = 0; i < assessmentIds.length; i += 2) {
            const ids = assessmentIds.slice(i, i + 2);
            assignmentsPromises.push(this.clientLambdaService.invokeLambda('fetchAssignments', ids));
          }
          const assignmentsResponse: any = await Promise.all(assignmentsPromises);
          if (assignmentsResponse.errorMessage) {
            return;
          } else {
            assignmentsResponse.map(assignmentMapper => {
              if (assignmentMapper.errorMessage) {
                return;
              }
              const res = JSON.parse(assignmentMapper.Payload);
              if (Object.keys(res).length) {
                Object.keys(res).map(key => {
                  this.allSubEntities[res[key].subEntityId].assignments = res[key].assignments;
                  this.entityService.setArchivedUserToDeletedUserAns(
                    this.allSubEntities[res[key].subEntityId].assignments
                  );
                });
              }
            });
          }

          // commenting this because Client wants to show the Admin avatar on Vendor questionnaire.

          // if (this.isVendorPOC()) {
          //   // To Filter Those assignments whose user role is Either Participant or Vendor
          //   const roleIds = await this.getVendorRoleIds();
          //   this.allSubEntities[subEntityId].assignments = this.allSubEntities[subEntityId]?.assignments?.filter(
          //     assessment => roleIds.includes(assessment?.user?.roleId)
          //   );
          // }
        } else if (!this.hasPermission()) {
          console.log('989');
          const assignments = await this.questionnaireService.getAssignmentsByUserId(this.currentUser.id, assessmentId);
          this.allSubEntities[subEntityId].assignments = assignments;
        }
        return this.allSubEntities[subEntityId.toLowerCase()]?.assignments;
      }
    } catch (e) {
      console.log('Cannot fetch the assignments due to .... ', e);
      return;
    }
  }
  async fetchFrameworkScoresByAssessment(assessmentId: string): Promise<FrameworkScoresByAssessmentQuery> {
    try {
      if (!this.cachedFrameworkScores[assessmentId] || !this.cachedFrameworkScores[assessmentId]?.length) {
        let nextToken;
        const scores = {
          items: [],
        };
        do {
          const frameworkScores = await this.customApi.FrameworkScoresByAssessment(
            assessmentId,
            null,
            null,
            1000,
            nextToken
          );

          scores.items = scores?.items.concat(frameworkScores.items as any);
          nextToken = frameworkScores.nextToken;
        } while (nextToken);
        this.cachedFrameworkScores[assessmentId] = scores?.items?.length ? scores?.items : [];
      }
      return this.cachedFrameworkScores[assessmentId];
    } catch (err) {
      console.log('Err:: Failed to get framework score list with next token');
    }
  }
  getAllSubEntities(): any {
    return this.allSubEntities;
  }
  setFrameworkSubEntitiesList(subEntities: GetEntityQuery[]): any {
    this.frameworkSubEntitiesList = [...subEntities];
  }
  getFrameworkSubEntitiesList(): GetEntityQuery[] {
    return this.frameworkSubEntitiesList;
  }

  calculateGroupedScores(allGroups): any {
    let allGroupedScores = [];
    allGroups.forEach(group => {
      const allFrameworkScores = [];
      group.allSubFrameworks.forEach(frame => {
        // Below condition will only work if frameworkScores object is not created first time after creation of framework.
        if (!Object.keys(frame?.scores)?.length) {
          frame.scores = {
            collection: 0,
            remediation: 0,
            total: 0,
          };
        }
        allFrameworkScores.push(frame.scores);
      });
      group.scores = this.compareAverageScores(allFrameworkScores);
      allGroupedScores.push(group.scores);
    });
    // hotfix, remove scores which doesn't have collection, total, remediation properties
    allGroupedScores = allGroupedScores.filter(
      score =>
        (score.collection || score.collection === 0) &&
        (score.total || score.total === 0) &&
        (score.remediation || score.remediation === 0)
    );
    const allScoresAverage = this.compareAverageScores(allGroupedScores);

    const allFrameworkCard = {
      name: 'All Frameworks',
      scores: allScoresAverage,
    };
    // reassessmentScore is not required for 'All Frameworks'
    delete allFrameworkCard.scores?.reassessment;
    allGroups.unshift(allFrameworkCard);
    return allGroups;
  }

  compareAverageScores(allScores): any {
    const averageScore = {};
    if (allScores && allScores.length) {
      Object.keys(allScores[0]).forEach(scoreType => {
        if (this.shouldCalculateMean(scoreType)) {
          averageScore[scoreType] = UtilsService.averageOfProp(allScores, scoreType);
        } else {
          if (scoreType === 'target' || scoreType === 'notApplicable') {
            averageScore[scoreType] = allScores[0][scoreType];
          } else {
            averageScore[scoreType] = allScores.reduce((acc, curr) => acc + curr[scoreType], 0);
          }
        }
      });
    }
    return averageScore;
  }

  shouldCalculateMean(scoreType: string): boolean {
    let calculateAverage = false;
    switch (scoreType) {
      case 'collection':
      case 'remediation':
      case 'total':
        calculateAverage = true;
    }
    return calculateAverage;
  }

  // onUpdateFrameworkScores(assessmentId: string, frameworkId: string): any {
  //   // Observable<any>
  //   return API.graphql(
  //     graphqlOperation(
  //       `subscription onFrameworkScoresUpdated($id: ID!) {
  //         onFrameworkScoresUpdated(id: $id) {
  //           __typename
  //           id
  //           assessmentId
  //           type
  //           total
  //           target
  //           collection
  //           remediation
  //           reassessment
  //         }
  //       }`,
  //       { id: `${assessmentId}_${frameworkId}` }
  //     )
  //   ); // Observable<any>
  // }

  async setInitialAnsweredQuestions(assessmentId: string): Promise<void> {
    const answers = await this.questionnaireService.fetchAssessmentsAnswers([assessmentId]);
    this.answeredQuestions = UtilsService.sortByProp(answers, 'date', SortDirectionEnum.DESCENDING);
    // if answer doesnt have user but has userId, then it means user has been deleted.
    // use archivedUser table to get required data and paste it in user property of answer
    const modifiedAnswers = this.answeredQuestions.map(async (ans, indx) => {
      if (!ans.user && ans.userId) {
        const archivedUser = await this.entityService.getArchivedUserById(ans.userId);
        this.answeredQuestions[indx].user = archivedUser as any;
        return archivedUser;
      }
      return ans;
    });
    await Promise.all(modifiedAnswers);
    this.createAssignmentMap();
  }

  getAnsweredQuestions(): GetAnswerQuery[] {
    return this.answeredQuestions;
  }

  createAssignmentMap(): void {
    this.answeredQuestionsMap = UtilsService.groupBy(this.answeredQuestions, 'questionId');
  }

  getAnswersMap(): Map<string, GetAnswerQuery[]> {
    // According to New Architecture answeredQuestionMap will be empty
    // this function is not in use any more
    return this.answeredQuestionsMap;
  }

  getAllAnswersMap(subId: string): Map<string, GetAnswerQuery[]> {
    return this.allSubEntities[subId].answeredQuestionsMap;
  }

  async setFrameworkManagersList(assessmentId: string, subId?: string, fetchFromApi = false): Promise<void> {
    this.managersList =
      !fetchFromApi && this.allSubEntities[subId || this.subEntity.id]?.managersList?.length
        ? this.allSubEntities[subId || this.subEntity.id]?.managersList
        : (await this.customApi.FrameworkManagersByAssessmentId(assessmentId)).items;
    this.allSubEntities[subId || this.subEntity.id].managersList = this.managersList;
  }

  // Not In Use AnyMore - Need to remove
  // async setInitialAssignments(assessmentId: string, user: GetUserQuery): Promise<void> {
  //   if (this.hasPermission() || this.isVendorPOC()) {
  //     console.log('inside setInitialAssignments');
  //     this.assignments = await this.questionnaireService.getAssignmentsByAssessmentId(assessmentId);
  //     const disabledAssignments = await this.fetchDisableAssignmentsByAssessmentId(assessmentId);
  //     this.assignments = this.assignments.concat(disabledAssignments as any[]);
  //   } else {
  //     this.assignments = await this.questionnaireService.getAssignmentsByUserId(user.id, assessmentId);
  //   }
  //   if (this.isVendorPOC()) {
  //     // To Filter Those assignments whose user role is Either Participant or Vendor
  //     const roleIds = await this.getVendorRoleIds();
  //     this.assignments = this.assignments.filter(assessment => roleIds.includes(assessment?.user?.roleId));
  //   }
  // }

  getAssignments(): GetAssignmentQuery[] {
    return this.assignments;
  }

  getSingleSubFrameworkAssignments(framework): GetAssignmentQuery[] {
    return (
      (this.allSubEntities[framework.belongsTo.subId] && this.allSubEntities[framework.belongsTo.subId].assignments) ||
      []
    );
  }

  updateAssignmentsAllSubEntities(newAssignments): void {
    this.allSubEntities[this.subEntity.id].assignments = newAssignments;
  }

  async setInitialQuestionsSettings(assessmentId: string): Promise<void> {
    this.questionsSettings = await this.getQuestionSettingsbyAssessment(assessmentId);
  }

  getQuestionSettings(): GetQuestionSettingsQuery[] {
    return this.questionsSettings;
  }

  async setCurrentUser(vendorUser): Promise<void> {
    if (vendorUser) {
      this.currentUser = vendorUser;
      this.currentUserRole =
        this.currentUser && this.currentUser.roleId ? await this.customApi.GetRole(this.currentUser.roleId) : null;
    } else {
      this.currentUser = await this.userService.getCurrentUser();
      this.currentUserRole = this.userService.getCurrentRole();
    }
  }

  getCurrentRole(): GetRoleQuery {
    return this.currentUserRole ? this.currentUserRole : this.userService.getCurrentRole();
  }
  /**
   * check either given question is assigned to current user or not
   * if given question is assigned then user has access to answer else
   * @params question and all assignments of selected entity
   * user can't update that question.
   * @returns boolean true/false
   */

  checkPermissionToAnswerQuestion(question?: any, assignments?: any): boolean {
    const user = this.getCurrentUser();
    if (!(question && assignments && assignments.length)) {
      return false;
    }
    const findValue = assignments.find(
      assign => assign?.left <= question?.left && assign?.right >= question?.right && user?.id === assign?.userId
    );
    return !!findValue;
  }

  getCurrentUser(): GetUserQuery {
    return this.currentUser;
  }

  async setUsersList(
    entityId: string,
    subEntityId: string,
    userRole: GetRoleQuery,
    isThirdParty = false
  ): Promise<any> {
    // getting the users from the list.\
    if (this.isVendorPOC() || isThirdParty) {
      this.usersList = await this.userService.getAssignedUsersInEntity(subEntityId);
      // To Filter Those users whose role is Either Participant or Vendor

      const roleIds = await this.getVendorRoleIds();
      this.usersList = this.usersList.filter(user => roleIds.includes(user?.roleId));
    } else {
      this.usersList = await this.userService.getAllUsersForEntityWithRoleId(entityId, [subEntityId], userRole);
    }
  }

  /**
   * Helper function to get the list of the users.
   * @param rootEntityId => current root entity.
   * @param childEntityIds  => list of all the sub Entities in the specific root entity.
   * @param currentUserRole => current user role.
   * @returns nothing => updates the list of users for the collection.
   */
  async getUsers(rootEntityId, subEntityId, isCollectionEdit: boolean = false): Promise<any> {
    try {
      // If we have the data already present in the cache then we will return back the data as it is.
      if (this.allSubEntities[subEntityId]?.usersList) {
        return this.allSubEntities[subEntityId]?.usersList;
      }
      // If we don't have the data then we will get the data from the Dynamo DB and save it in cache.
      const data = await this.userService.getAllUsersForEntityWithRoleId(
        rootEntityId,
        subEntityId,
        this.getCurrentRole(),
        true,
        isCollectionEdit // for empty modal fix
      );
      this.usersList = data.usersList;
      const roles = data.roles;

      this.usersList.forEach(user => {
        const findRole = roles.find(role => user.roleId === role.id);
        if (findRole) {
          user.role = findRole.name;
        }
      });

      if (!this.allSubEntities[subEntityId]) {
        this.allSubEntities[subEntityId] = {};
      }
      // Saving the data in the list.
      if (this.usersList?.length) {
        this.allSubEntities[subEntityId].usersList = this.usersList;
      }
      return this.usersList;
    } catch (e) {
      console.log('Cannot get the users list -getUsers-', e);
      return [];
    }
  }

  getUsersList(): GetUserQuery[] {
    return this.usersList;
  }

  setSubEntity(subEntity: GetEntityQuery): void {
    this.subEntity = subEntity;
  }

  async setQuestionSettings(
    setting: UpdateQuestionSettingsInput,
    question = null
  ): Promise<UpdateQuestionSettingsMutation> {
    if (question) {
      this.updatedQuestion = question;

      // adding additional checks to see if any one of the question settings is undefined ,
      // (meaning the value has not changed) then use the existing question settings value
      const existSets = question.settings;
      setting.isApprovalRequired =
        setting.isApprovalRequired === undefined ? existSets.isApprovalRequired : setting.isApprovalRequired;
      setting.isCollaborative =
        setting.isCollaborative === undefined ? existSets.isCollaborative : setting.isCollaborative;
      setting.isArtifactsMandatory =
        setting.isArtifactsMandatory === undefined ? existSets.isArtifactsMandatory : setting.isArtifactsMandatory;
      setting.isCommentsMandatory =
        setting.isCommentsMandatory === undefined ? existSets.isCommentsMandatory : setting.isCommentsMandatory;
      setting.isInfoSec = setting.isInfoSec === undefined ? existSets.isInfoSec : setting.isInfoSec;
    }
    const response = await this.customApi.UpdateQuestionSettings(setting);
    this.updateQuestionsSettings(response, question);
    return response;
  }

  getSubEntity(): GetEntityQuery {
    return this.subEntity;
  }

  getSubQuestionSettings(subId: string): any {
    return this.allSubEntities[subId].questionsSettings;
  }

  getAssessmentManagers(): string[] {
    return this.subEntity.activeAssessment ? this.subEntity.activeAssessment.managers : [];
  }

  getManagersList(subId: string): GetFrameworkManagerQuery[] {
    return this.allSubEntities[subId]?.managersList || [];
  }

  setSelectedFramework(framework): void {
    if (framework.isCollapsed) {
      this.selectedFramework = null;
    } else {
      this.selectedFramework = framework;
      const score = Math.round(this.selectedFramework?.scores?.collection);
      const finalize = this.selectedFramework ? (score >= 100 ? false : true) : false;
      this.setDisableFinalize(finalize);
      //* Reassessment confirm All related updates
      const reassessmentScore = Math.round(this.selectedFramework?.scores?.reassessment);
      const reassessConfirmAll = score ? (reassessmentScore >= 100 ? true : false) : false;
      this.setDisableReassessConfirmAll(reassessConfirmAll);
    }
  }

  /**
   * Function to get framework score and settings
   * @param framework - current framework
   */
  async getFrameworkScoresAndSettings(framework): Promise<void> {
    try {
      const assessmentId = this.subEntity?.activeAssessmentId;
      const fId = assessmentId + '#' + framework?.frameworkName;
      // const fwId = assessmentId + '_' + framework?.frameworkName;
      const currentFramework = await this.getAssessmentStandardFramework(fId);
      const frameworkSettings = currentFramework?.defaultQuestionSettings;
      if (frameworkSettings) {
        delete frameworkSettings.__typename;
      }
      this.selectedFrameworkSettings = frameworkSettings;
    } catch (e) {
      console.log('Error - getFrameworkScoresAndSettings: ', e);
    }
  }

  getSelectedFramework(): any {
    return this.selectedFramework;
  }

  getSelectedFrameworkSettings(): any {
    return this.selectedFrameworkSettings;
  }

  getSelectedFrameworkManagers(): any {
    return this.selectedFrameworkManagers;
  }

  setSelectedFrameworkManagers(managers): any {
    return (this.selectedFrameworkManagers = managers);
  }

  async getFrameworkSettings(fId: string): Promise<EntityQuestionSettings> {
    const currentFramework = await this.getAssessmentStandardFramework(fId);
    const frameworkSettings = currentFramework?.defaultQuestionSettings;
    return frameworkSettings;
  }

  setSelectedControl(control): void {
    if (!UtilsService.isDefined(this.currentSelectedControl)) {
      this.currentSelectedControl = control;
      this.selectedControl.next(control);
    } else {
      if (this.currentSelectedControl && control) {
        this.currentSelectedControl = control;
        this.selectedControl.next(control);
      }
    }
  }

  getSelectedControl(): Observable<any> {
    return this.selectedControl;
  }

  setArtifactsMap(controls): void {
    this.artifactsMap = new Map<string, any>();
    controls.forEach(control => {
      if (!this.artifactsMap[control.selectionPath[0]]) {
        this.artifactsMap[control.selectionPath[0]] = [];
      }
      control?.questions?.forEach(question => {
        this.addAnswersInArtifactsMap(question, control);
      });
    });
  }

  /**
   * To get the artifacts map against the current framework.
   * If framework's data is not loaded, it will fetch the data using the api.
   * Populate the mapper object.
   * @param framework => current framework against which data needs to be loaded.
   * @returns the mapper object of the artifacts.
   */
  async getArtifactsMap(framework: any): Promise<Map<string, any>> {
    // get all the answers of the current framework and assessment id.
    if (this.artifactsMap) {
      const answers = await this.getAnswersData(framework.assessmentId, framework.frameworkName, null);
      if (answers?.length) {
        this.artifactsMap[framework.frameworkName] = {};
      }
      for (const answer of answers) {
        this.getSelectionPath(framework, answer);
        if (!this.artifactsMap[framework.frameworkName][answer.questionId]) {
          this.artifactsMap[framework.frameworkName][answer.questionId] = [answer];
        } else {
          this.artifactsMap[framework.frameworkName][answer.questionId].push(answer);
        }
      }
    }
    return this.artifactsMap;
  }

  /**
   * Helper recursion function to populate the answers object with the selection path.
   * @param node => current framework node against which iteration will be done.
   * @param answer => to update the selectionPath of this answer.
   * @returns nothing only update the answer object with the data.
   */
  getSelectionPath(node: any, answer: any): void {
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => {
        this.getSelectionPath(child, answer);
      });
    } else {
      if (node.questions && node.questions.length > 0) {
        node.questions.forEach(question => {
          if (question.id === answer.questionId) {
            answer.selectionPath = node.selectionPath;
          }
        });
      }
    }
  }

  refreshState(): void {
    this.assignmentsChange.next(this.allSubEntities[this.subEntity?.id]?.assignments);
    this.answeredQuestionsMapsChange.next(this.answeredQuestionsMap);
    this.usersListChange.next(this.usersList);
  }

  refreshQuestionsSettings(): void {
    this.questionsSettingsChange.next(this.questionsSettings);
  }

  private addAnswersInArtifactsMap(question, control): void {
    // removed incorrect #1 tag from framework path
    if (!this.artifactsMap[control.selectionPath[0]]) {
      control.selectionPath[0] = control.selectionPath[0].replace(/#1/g, '');
    }
    if (question.answers && question.answers.length) {
      question.answers.forEach(answer => {
        if (answer.file && answer.file.length) {
          if (!this.artifactsMap[control.selectionPath[0]][question.id]) {
            this.artifactsMap[control.selectionPath[0]][question.id] = [];
          }
          answer.selectionPath = control.selectionPath;
          answer.controlId = control.id;
          const index = this.artifactsMap[control.selectionPath[0]][question.id].findIndex(
            artifactAnswer => artifactAnswer.id === answer.id
          );
          if (index > -1) {
            this.artifactsMap[control.selectionPath[0]][question.id][index] = answer;
          } else {
            this.artifactsMap[control.selectionPath[0]][question.id].push(answer);
          }
        }
      });
    }
  }

  async getAssessmentStandardFramework(id): Promise<GetAssessmentStandardFrameworkQuery> {
    try {
      let fw = null;
      if (this.frameworkSubEntitiesList?.length) {
        const ids = id.split('#');
        const subEnt = this.frameworkSubEntitiesList.find(subEntity => subEntity.activeAssessmentId === ids[0]);
        if (subEnt) {
          fw = subEnt.activeAssessment.standardFrameworkList.items.find(
            framework => framework.key.split('#')[0].toUpperCase() === ids[1] && !framework.archived
          );
        }
      }
      if (!fw && !id?.includes(StandardType.COMPLIANCE_FRAMEWORK)) {
        fw = await this.customApi.GetAssessmentStandardFramework(id);
      }
      return fw;
    } catch (e) {
      console.log('cannot get the assessmentStandardFramework ====>', e);
    }
  }

  async deleteArtifacts(selectedFiles): Promise<void> {
    const updateAnswersPromises = [];
    selectedFiles.forEach(artifactFile => {
      // Updating AnsweredQuetionsMap state
      const questionAnswers = this.getAnswersByQuestionId(artifactFile.questionId);
      if (questionAnswers) {
        questionAnswers.forEach(answer => {
          if (answer.id === artifactFile.answerId) {
            if (answer.file) {
              answer.file.forEach((eachFile, fileIndex) => {
                if (eachFile.id === artifactFile.fileId) {
                  if (eachFile.__typename) {
                    delete eachFile.__typename;
                  }
                  // Updating answers in db
                  const updatedAnswer = {
                    id: answer.id,
                    answer: answer.answer,
                    comment: answer.comment ? answer.comment : null,
                    file: answer.file,
                    riskAction: answer.riskAction,
                    isActionTaken: answer.isActionTaken,
                    origin: null,
                  };
                  updatedAnswer.file.forEach(updatedAnswerFile => {
                    if (updatedAnswerFile.__typename) {
                      delete updatedAnswerFile.__typename;
                    }
                  });
                  updateAnswersPromises.push(
                    this.customApi.UpdateAnswer(updatedAnswer, { assessmentId: { eq: answer.assessmentId } })
                  );
                  answer.file.splice(fileIndex, 1);
                }
              });
            }
          }
        });
      }

      // Updating artifactMap
      const artifactMapIndex = this.artifactsMap[this.selectedFramework.frameworkName];
      if (artifactMapIndex[artifactFile.questionId]) {
        artifactMapIndex[artifactFile.questionId].forEach(artifact => {
          if (artifact.file) {
            artifact.file.forEach((eachFile, fileIndex) => {
              if (eachFile.id === artifactFile.fileId) {
                artifact.file.splice(fileIndex, 1);
              }
            });
          }
        });
      }
    });

    await Promise.all(updateAnswersPromises);

    this.artifactsMapChange.next(this.artifactsMap);
    this.refreshState();
  }

  updateArtifactsMap(question: CollectionQuestion): void {
    if (this.currentSelectedControl.path) {
      this.currentSelectedControl.selectionPath = this.currentSelectedControl.path;
    }
    this.addAnswersInArtifactsMap(question, this.currentSelectedControl);
    this.artifactsMapChange.next(this.artifactsMap);
  }

  getUpdatedQuestion(): CollectionQuestion {
    return this.updatedQuestion;
  }

  getUpdatedQuestions(): CollectionQuestion[] {
    return this.updatedQuestions;
  }

  onUpdateFrameworkScore(): any {
    return this.customApi.OnUpdateFrameworkScoresListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        // restricting the score listener only for the currently
        // opened sub-entity / sub-entities
        const assessmentId = res?.value?.data?.onUpdateFrameworkScores?.assessmentId;
        let foundAssessment = false;
        Object.keys(this.allSubEntities).map(key => {
          if (this.allSubEntities[key].activeAssessmentId === assessmentId) {
            foundAssessment = true;
          }
        });
        if (foundAssessment || this.subEntity?.activeAssessmentId === assessmentId) {
          if (this.timer != null) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            if (this.cachedFrameworkScores[assessmentId]?.length) {
              const index = this.cachedFrameworkScores[assessmentId].findIndex(
                f => f.id === res?.value?.data?.onUpdateFrameworkScores?.id
              );
              if (index > -1) {
                delete res?.value?.data?.onUpdateFrameworkScores?.top20;
                this.cachedFrameworkScores[assessmentId][index] = res?.value?.data?.onUpdateFrameworkScores;
              } else {
                this.cachedFrameworkScores[assessmentId].push(res?.value?.data?.onUpdateFrameworkScores);
              }
            }
            this.onUpdateFrameworkScores.next(res);
          }, 500);
        }
      },
    });
    // });
  }

  onUpdateAssessmentStandardFrameworkListener(): any {
    return this.customApi.OnUpdateAssessmentStandardFrameworkListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
          this.eSignatureSubscription.next(res);
        }
        if (this.timer != null) {
          clearTimeout(this.timer);
        } else {
          this.timer = setTimeout(() => {
            this.OnUpdateAssessmentStandardFramework.next(res);
          }, 500);
        }
      },
    });
    // });
  }

  updateAnswerMap(
    question: CollectionQuestion,
    userId: string,
    isDeselected: boolean = false,
    answerId: string = null,
    answerQuestionId: string = null
  ): void {
    this.updatedQuestion = question;
    const questionIndex = this.updatedQuestions.findIndex(updatedQuestion => updatedQuestion.id === question.id);
    if (questionIndex > -1) {
      this.updatedQuestions.splice(questionIndex, 1);
    }
    this.updatedQuestions.push(question);
    const answer = question.answers.find(userAnswer => userAnswer.userId === userId);
    let key = answer?.assessmentId + '#' + answer?.frameworkName; // all framework answers obj key format
    if (answer && this.questionSetCollectionCache[answer?.questionId]?.answers) {
      const index = this.questionSetCollectionCache[answer?.questionId].answers.findIndex(
        mapAnswer => mapAnswer.userId === answer.userId
      );
      if (index > -1) {
        this.questionSetCollectionCache[answer?.questionId].answers.splice(index, 1);
      }
      if (!isDeselected) {
        this.questionSetCollectionCache[answer?.questionId].answers.push(answer);
        if (this.updatedQuestion.managerAnswers?.length) {
          //* removes the current answer and updates with new one
          const managerIndex = this.updatedQuestion.managerAnswers.findIndex(
            mapAnswer => mapAnswer.userId === answer.userId
          );
          if (managerIndex > -1) {
            this.updatedQuestion.managerAnswers.splice(index, 1);
          }
          this.updatedQuestion.managerAnswers.unshift(answer);
        } else {
          this.updatedQuestion.managerAnswers = [answer];
        }
      }
      this.allFrameworksAnswersObj[key] = true; // user has answered - checked variable ( Hide New Tag )
    } else if (!isDeselected) {
      // this.answeredQuestionsMap[answer.questionId] = [answer];
      this.addNewAnswerInCache(answer?.questionId, answer);
      this.allFrameworksAnswersObj[key] = true; // user has answered - checked variable  ( Hide New Tag )
      //
    } else if (isDeselected && answerQuestionId && answerId) {
      const idx = this.questionSetCollectionCache[answerQuestionId]?.answers.findIndex(obj => obj.id === answerId);
      let ans = null;
      if (idx > -1) {
        ans = this.questionSetCollectionCache[answerQuestionId].answers[idx];
        this.questionSetCollectionCache[answerQuestionId].answers.splice(idx, 1);
      }
      // store found in object assesmentId#frameworkName
      key = ans?.assessmentId + '#' + ans?.frameworkName;
      const found = this.checkIfAnyOtherQuestionAnswered(ans);
      this.allFrameworksAnswersObj[key] = found;
    }
    if (!isDeselected && answer?.riskAction) {
      this.updateAnswersOnRiskAction(question, userId);
    }
    this.refreshState();
  }
  /**
   * returning boolean value if answeredQuestionsMap has the passed answer assessment and framework name
   * @param ans answer
   * @returns boolean
   */
  checkIfAnyOtherQuestionAnswered(ans): boolean {
    // (Note- Collection Optimization) Need to Update it according to new Architecture
    let found = false;
    const assessId = ans?.assessmentId;
    const frameName = ans?.frameworkName;
    // iterate answered Question Map if found the answer assessment and framework name return true else false
    // Updated the code with questionSetCollectionCache
    Object.values(this.questionSetCollectionCache).forEach(values => {
      if (values.answers && values.answers.length) {
        const existingAnswer = values.answers[0];
        if (existingAnswer?.assessmentId === assessId && existingAnswer?.frameworkName === frameName) {
          found = true;
        }
      }
      if (found) {
        return;
      }
    });
    return found;
  }
  updateAnswersMapOnRemoveAssignment(deletedAssignments: GetAssignmentQuery[]): void {
    // This function has been updated according to the new architecture of the collection (optimized)
    // Using questionSetCollectionCache object instead of answeredQuestionsMap
    deletedAssignments.forEach(assignment => {
      const questionsToRemove = this.answeredQuestionsMap
        ? Object.keys(this.answeredQuestionsMap).filter(
            questionId =>
              this.answeredQuestionsMap[questionId] &&
              this.answeredQuestionsMap[questionId].answers &&
              this.answeredQuestionsMap[questionId].answers.length &&
              this.answeredQuestionsMap[questionId].answers[0].left >= assignment.left &&
              this.answeredQuestionsMap[questionId].answers[0].right <= assignment.right
          )
        : [];
      questionsToRemove.forEach(questionId => {
        this.answeredQuestionsMap[questionId].answers = this.answeredQuestionsMap[questionId].answers.filter(
          answer => answer.userId !== assignment.userId
        );
      });
    });
  }

  updateAnswersOnRiskAction(question: CollectionQuestion, userId: string): void {
    const answer = question.answers.find(userAnswer => userAnswer.userId === userId);
    // NOTE:
    // it will changed the answers of question for all users according to answer of current user answer.
    // so it should be done only in collaborative not in individual questions.
    if (question && question.settings && question.settings.isCollaborative) {
      this.questionnaireService
        .getAssignedUsersList(this.assignments, question)
        .filter(assignment => assignment.userId !== answer.userId)
        .forEach((assignment: GetAssignmentQuery) => {
          const index = (this.questionSetCollectionCache[answer.questionId]?.answers as GetAnswerQuery[]).findIndex(
            qAnswer => qAnswer.userId === assignment.userId
          );
          if (index > -1) {
            const currentUserAnswerIndex = this.questionSetCollectionCache[answer.questionId]?.answers.findIndex(
              qs => qs.userId === userId
            );
            if (currentUserAnswerIndex > -1) {
              const returnRequiredAnswer = this.questionSetCollectionCache[answer.questionId]?.answers.splice(
                currentUserAnswerIndex,
                1
              )[0];
              this.questionSetCollectionCache[answer.questionId]?.answers.unshift(returnRequiredAnswer);
            }
          } else {
            /**
             * commenting this out cause it creates problem in deselection of answer in collaborative
             * questions, incase of multiple assignments
             */
            // const answerToPush: GetAnswerQuery = { ...answer, userId: assignment.userId };
            // answerToPush.id = '';
            // answerToPush.file = [];
            // answerToPush.user = assignment.user;
            // this.answeredQuestionsMap[answer.questionId].push(answerToPush);
          }
        });
    }
  }

  async restartAnswers(allAnswers): Promise<void> {
    try {
      const promises = [];

      allAnswers.forEach(answer => {
        const answerID: DeleteAnswerInput = { id: answer.id };
        promises.push(this.customApi.DeleteAnswer(answerID, { assessmentId: { eq: answer.assessmentId } }));
      });
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    }
  }

  updateAssignments(
    assignmentsToAdd?: GetAssignmentQuery[],
    assignmentsToRemove?: GetAssignmentQuery[],
    userId?: string
  ): void {
    const currentSubentityId = this.subEntity.id[0] === 'F' ? this.subEntity.id.substring(1) : this.subEntity.id;
    if (assignmentsToRemove && assignmentsToRemove.length > 0) {
      let assignments = this.allSubEntities[currentSubentityId]?.assignments;
      assignments = assignments.filter(
        assignment => !assignmentsToRemove.some(assignmentRem => assignmentRem.id === assignment.id)
      );
      this.allSubEntities[currentSubentityId].assignments = assignments;
      this.updateAnswersMapOnRemoveAssignment(assignmentsToRemove);

      console.log('Removing comments of user');
      this.deleteCommentByUserId(userId);
    }

    if (assignmentsToAdd && assignmentsToAdd.length > 0) {
      this.allSubEntities[currentSubentityId].assignments = [
        ...this.allSubEntities[currentSubentityId].assignments,
        ...assignmentsToAdd,
      ];
    }
    this.assignmentsChange.next(this.allSubEntities[currentSubentityId].assignments);
    this.answeredQuestionsMapsChange.next(this.answeredQuestionsMap);
    // this.refreshState();
  }

  /**
   * *new Assignment listener
   */
  private onCreateNewAssignmentListener() {
    this.apiService.OnCreateAssignmentListener().subscribe({
      next: (res: any) => {
        const createAssignment: GetAssignmentQuery = res.value.data.onCreateAssignment;
        if (UtilsService.isDefined(createAssignment)) {
          this.onCreateNewAssignmentSub.next(createAssignment);
        }
      },
    });
  }

  /**
   * *Delete Assignment listener
   */
  private onDeleteAssignmentListener() {
    this.apiService.OnDeleteAssignmentListener().subscribe({
      next: (res: any) => {
        const deleteAssignment: GetAssignmentQuery = res.value.data.onDeleteAssignment;
        if (UtilsService.isDefined(deleteAssignment)) {
          this.onDeleteAssignmentSub.next(deleteAssignment);
        }
      },
    });
  }

  /**
   * Function to get assigned users from assignments of the current framework
   * @returns - Assigned user of current framework
   */
  initializeAssignedUsers(unOpenedFramework = {}): any[] {
    const currentFramework: any = this.getCurrentFramework();
    currentFramework._value = UtilsService.isDefined(currentFramework._value)
      ? currentFramework._value
      : unOpenedFramework;
    let requestedEntity;
    if (this.isVendor || (!currentFramework?._value?.childId && this.subEntity?.id)) {
      requestedEntity = this.subEntity.id;
    } else {
      requestedEntity = currentFramework?._value?.childId;
    }
    const allAssignments = this.allSubEntities[requestedEntity]?.assignments;
    const assignedUsersMapper = {};
    if (currentFramework && currentFramework._value) {
      const framework = currentFramework._value;
      allAssignments.map(assignment => {
        if (
          (assignment.left >= framework.left && assignment.right <= framework.right) ||
          (framework.left <= assignment.left && assignment.right >= framework.right)
        ) {
          if (!assignedUsersMapper[assignment.userId]) {
            // Storing only unique users
            assignedUsersMapper[assignment.userId] = assignment.user;
          }
        }
      });
    }
    this.filterAssignedUser = Object.values(assignedUsersMapper);
    return this.filterAssignedUser;
  }

  async createAssignment(userId: string, target: AssignmentTarget): Promise<boolean> {
    if (!this.isAlreadyAssigned(userId, target, this.subEntity?.activeAssessmentId)) {
      const payload = {
        targetId: this.subEntity?.activeAssessmentId,
        right: target.right,
        left: target.left,
        assessmentId: this.subEntity?.activeAssessmentId,
        userId,
      };

      const assignmentResult = await this.customApi.AssignUser(payload);

      /* Get user detail to check if participant or SubEntityLeader
      was promoted to EntityLeader after its sub-entity was deleted */
      const user = await this.authService.getUserById(userId);
      if (user && (user.role.toLowerCase() === 'participant' || user.temporaryHigherRank)) {
        if (this.subEntity && user.entityId === this.subEntity.parentId) {
          const updatedUser = {
            id: user.id,
            entityId: this.subEntity.id,
          };
          // Limit user to its sub-entity again and demote it back to its role in sub-entity
          await this.customApi
            .UpdateUser(updatedUser)
            .then(res => console.log('User entity updated', res))
            .catch(error => console.log(error));
        }
      }

      this.updateAssignments(assignmentResult.createdAssignments, assignmentResult.deletedAssignments);
      this.refreshState();
      return true;
    }
    return false;
  }

  updateUserList(user: GetUserQuery, isRemove: boolean = false): void {
    // Add new user
    if (!isRemove) {
      this.usersList.push(user);
      this.refreshState();
    } else {
      // Delete User
      this.usersList = this.usersList.filter(eachUser => {
        return eachUser.id !== user.id;
      });
      this.updateAssignments(
        null,
        this.assignments.filter(assignment => assignment.userId === user.id)
      );
      this.refreshState();
    }
  }

  async assignNewFrameworkManager(manager: CreateFrameworkManagerInput): Promise<void> {
    const newManager = await this.customApi.CreateFrameworkManager(manager);
    this.managersList.push(newManager);
    if (this.subEntity) {
      const currentSubentityId = this.subEntity.id[0] === 'F' ? this.subEntity.id.substring(1) : this.subEntity.id;
      this.allSubEntities[currentSubentityId].managersList = this.managersList;
    }
  }

  async assignNewReviewer(manager: any, reviewer: any): Promise<void> {
    await this.customApi.CreateFrameworkManager(manager);
    this.managersList.push({ ...manager, user: reviewer });
    if (this.subEntity) {
      const currentSubentityId = this.subEntity.id[0] === 'F' ? this.subEntity.id.substring(1) : this.subEntity.id;
      this.allSubEntities[currentSubentityId].managersList = this.managersList;
    }
  }

  async deleteFrameworkManager(
    managerDetailsId: DeleteFrameworkManagerInput,
    activeAssessmentId: string
  ): Promise<void> {
    const deletedReviewer = await this.customApi.DeleteFrameworkManager(managerDetailsId, {
      assessmentId: { eq: activeAssessmentId },
    });
    const index = this.managersList.findIndex(manager => manager.managerId === deletedReviewer.managerId);
    if (index > -1) {
      this.managersList.splice(index, 1);
    }
    this.allSubEntities[this.subEntity.id].managersList = this.managersList;
  }

  async updateFrameworkManager(manager: UpdateFrameworkManagerInput): Promise<void> {
    await this.customApi.UpdateFrameworkManager(manager);
    const index = this.managersList.findIndex(selectedReviewer => selectedReviewer.id === manager.id);
    if (index > -1) {
      this.managersList[index].removedQuestions = manager.removedQuestions;
    }
    this.allSubEntities[this.subEntity.id].managersList = this.managersList;
  }

  async deleteAssessmentFramework(framework): Promise<void> {
    await this.customApi.DeleteAssessmentStandardFramework(
      { id: framework.id },
      { assessmentId: { eq: framework.assessmentId } }
    );
  }
  async deleteFrameworkScores(frameworkId): Promise<void> {
    await this.customApi.DeleteFrameworkScores({ id: frameworkId });
  }

  isAlreadyAssigned(userId, target: AssignmentTarget, assessmentId: string): boolean {
    return this.assignments.findIndex(
      assignment =>
        assignment.userId === userId &&
        assignment.assessmentId === assessmentId &&
        assignment.left <= target.left &&
        assignment.right >= target.right
    ) > -1
      ? true
      : false;
  }

  async filterTreeForParticipant(frameworkTrees): Promise<void> {
    if (!this.hasPermission()) {
      const assignments = await this.questionnaireService.getAssignmentsByUserId(
        this.currentUser.id,
        this.subEntity.activeAssessmentId
      );
      const currentUser = this.authService.getCurrentUserSync();
      // For vendor there is no assignment so that's why we are not filtering.
      if (currentUser && this.currentUserRole?.name?.toLowerCase() !== AppLevelRoleEnum.VENDOR) {
        frameworkTrees.forEach(node => {
          this.filterTreeOnAssignments(assignments, node);
        });
      }

      frameworkTrees = frameworkTrees.filter(tree => tree.children);
      this.isQuestionnaireAssigned.next(frameworkTrees.length > 0);
    }
  }

  filterTreeOnAssignments(assignments: GetAssignmentQuery[], node: any): boolean {
    if (node.children) {
      node.children = node.children.filter(childNode => this.filterTreeOnAssignments(assignments, childNode));
      if (!node.children || node.children.length === 0) {
        for (const prop of Object.getOwnPropertyNames(node)) {
          delete node[prop];
        }
        return false;
      } else {
        return true;
      }
    } else if (node.questions) {
      node.questions.forEach(question => {
        const questionAssignments = assignments.filter(assignment => {
          return question.left >= assignment.left && question.right <= assignment.right;
        });
        question.assignments = questionAssignments;
      });
      node.questions = node.questions.filter(question => question.assignments && question.assignments.length > 0);
      if (!node.questions || node.questions.length === 0) {
        return false;
      }
      return true;
    }
  }

  calcScoresForParticipant(userId: string, node: any): { collection; remediation; reassessment? } {
    if (node.children) {
      const childrenScores = node.children.map(child => this.calcScoresForParticipant(userId, child));
      const collection = childrenScores.reduce((acc, curr) => acc + curr.collection, 0) / childrenScores.length;
      const remediation = childrenScores.reduce((acc, curr) => acc + curr.remediation, 0) / childrenScores.length;

      return { collection, remediation };
    } else if (node.questions) {
      const totalQuestions = node.questions.length;
      let answeredQuestions = 0;
      let answersRequireRemediation = 0;

      node.questions.forEach((question: Question) => {
        if (UtilsService.isDefined(question.answers)) {
          let userAnswer: GetAnswerQuery;
          if (question.settings.isCollaborative) {
            if (UtilsService.isDefined(question.answers) && question.answers.length > 0) {
              userAnswer = question.answers[0];
            }
          } else {
            userAnswer = question.answers.find(answer => answer.userId === userId);
          }

          if (UtilsService.isDefined(userAnswer)) {
            if (UtilsService.isDefined(userAnswer.answer)) {
              answeredQuestions++;
              if (userAnswer.answer > 9) {
                answersRequireRemediation++;
              }
            }
          }
        }
      });

      const collection = (answeredQuestions / totalQuestions) * 100;
      const remediation = (answersRequireRemediation / totalQuestions) * 100;
      return { collection, remediation };
    }
    return { collection: 0, remediation: 0 };
  }

  async disableUserAssignment(node: any, userAssignment: GetAssignmentQuery): Promise<void> {
    if (node && node.ids && !!node.ids.length && userAssignment) {
      // existing disabledAssignments of that user
      // let disabledAssignments = await this.fetchDisableAssignmentsByAssessmentIdAndUserId(
      //   userAssignment.userId,
      //   userAssignment.assessmentId
      // );
      const selectedFramework = this.selectedFramework;

      // fetch only disabled assignment within selected framework
      // disabledAssignments = disabledAssignments.filter(
      //   da => selectedFramework.left <= da.left && selectedFramework.right >= da.right
      // );
      // node belongs to single question
      const left = node.leftArr[0];
      const right = node.rightArr[0];
      const assignment = {
        id: userAssignment.id,
        userId: userAssignment.userId,
        assessmentId: userAssignment.assessmentId,
        targetId: node.id,
        left,
        right,
      };

      const needTocreateDisableAssignment = node.answers.some(answer => answer.user.id === userAssignment.user.id);
      await this.formatAssignmentOrDisableAssignmentsOperations(
        assignment,
        'disabledassignment',
        selectedFramework,
        false,
        needTocreateDisableAssignment
      );
      const infoSecAllowed = UtilsService.isBnB;
      // handling the infoSec cases.
      if (infoSecAllowed) {
        // handling the scenario for question
        if (node && node.description && node.impact) {
          this.checkForInfoSecCase(null, 'infoSecAssignment', 'disableAssignment', userAssignment.userId, node);
        } else {
          this.checkForInfoSecCase(node, 'infoSecAssignment', 'disableAssignment', userAssignment.userId);
        }
      }
    }
  }

  async removeUserAssignment(node: any, userAssignment: GetAssignmentQuery): Promise<void> {
    const promises = [];
    for (let index = 0; index < node.ids.length; index++) {
      const payload = {
        assessmentId: this.subEntity.activeAssessmentId,
        left: node.leftArr[index],
        right: node.rightArr[index],
        targetId: node.ids[index],
        userId: userAssignment.userId,
      };
      promises.push(this.customApi.RemoveUserAssignment(payload));
      const results = await Promise.all(promises);
      const createdAssignments = [];
      const deletedAssignments = [];
      results.forEach(result => {
        createdAssignments.push(...result.createdAssignments);
        deletedAssignments.push(...result.deletedAssignments);
      });
      this.updateAssignments(createdAssignments, deletedAssignments, userAssignment.userId);
      this.refreshState();
    }
  }

  isUserManager(userId: string, frameworkName: string = null): boolean {
    let frameworkManagerList = [];
    // If Framework name is given, check if the user is Manager for that specific framework
    if (UtilsService.isDefined(frameworkName)) {
      frameworkName = frameworkName.includes('#') ? frameworkName.split('#')[0] : frameworkName;
      frameworkManagerList = this.managersList.filter(
        managerObject =>
          managerObject.managerId === userId &&
          (managerObject.standardFrameworkId.includes('CRB')
            ? managerObject.standardFrameworkId.split('#')[2] === frameworkName
            : managerObject.standardFrameworkId.split('#')[1] === frameworkName)
      );
    } else {
      // Search in all frameworks if the user is a manager
      if (this.getShowAllFrameworks()) {
        Object.keys(this.allSubEntities).forEach(sub => {
          const frameworkManagers = this.allSubEntities[sub].managersList.filter(
            managerObject => managerObject.managerId === userId
          );
          frameworkManagerList = [...frameworkManagerList, ...frameworkManagers];
        });
      } else {
        frameworkManagerList = this.managersList.filter(managerObject => managerObject.managerId === userId);
      }
    }

    return frameworkManagerList && frameworkManagerList.length > 0 ? true : false;
  }

  hasPermission(): boolean {
    /**
     * Removing the Manager rights to all participants users as they are shown and given permission to almost all
     * rights as an MSSP/ADMIN in collection screen. like Adding new framework , Edit Framework , Assigning users , e.t.c
     */
    // return (this.isParticipant && this.isManager) || (!this.isParticipant && !this.isVendor);
    return !this.isParticipant;
  }

  showExportCSVbtn(): boolean {
    return this.hasPermission() || this.isVendor;
  }

  updateQuestionsSettings(newSetting: GetQuestionSettingsQuery, question = null): void {
    const cache = this.questionSetCollectionCache[newSetting.questionId].settings;
    const triggerInfoSec = !!cache.isInfoSec !== !!newSetting.isInfoSec;
    this.questionSetCollectionCache[newSetting.questionId].settings = [newSetting];
    // Commenting the code for the optimization purpose.
    // const index = this.questionsSettings.findIndex(
    //   setting => setting.questionId === newSetting.questionId && setting.assessmentId === newSetting.assessmentId
    // );
    // // need to check previous value of info sec whether to load the answers or not
    // let triggerInfoSec = false;
    // if (index > -1) {
    //   const cache = JSON.parse(JSON.stringify(this.questionsSettings[index]));
    //   triggerInfoSec = !!cache.isInfoSec !== !!newSetting.isInfoSec;
    //   this.questionsSettings[index] = newSetting;
    // } else {
    //   triggerInfoSec = !!newSetting.isInfoSec;
    //   this.questionsSettings.push(newSetting);
    // }

    this.refreshQuestionsSettings();

    // Handling BNB Case , if info sec is enabled then populate the Question with other info answer
    // Only the latest answer on other info sec question will be shared on the current one
    // this is an Async Function , deliberately not adding await to it.
    this.loadInfoSecAnswers(triggerInfoSec, newSetting, question);
  }

  /**
   * The function determines the current newly info sec question and adjusts the answers on it.
   * @param createAns a boolean to show to create a new info answer or not
   * @param settings the settings object of the current question
   * @param question the question object itself
   */
  async loadInfoSecAnswers(createAns: boolean, settings = null, question = null): Promise<void> {
    try {
      const isInfo = UtilsService.isBnB;
      if (
        isInfo &&
        createAns &&
        settings.isInfoSec &&
        settings.frameworkName === 'NIST_CSF_BB' &&
        question &&
        this.hasPermission()
      ) {
        this.toastr.info('Please wait while we Enable Info Sec for this Question . . .');

        let infoAnswer = null;
        let infoQuestion = null;
        const currentSubId = this.getSubEntity().id;

        /**
         * FINDING OTHER INFO SEC QUESTIONS.
         * FIRST CHECKING FROM LOCAL VARIABLES.
         * THEN ALL SUBENTITIES OBJ VARIABLE
         * THEN FINDING THEM THROUGH API CALL WITH THE DOMAIN RESTRICTION
         */

        // CHECKING FROM LOCAL VARIABLES ( Not checking same framework questions -
        // as InfoSec is enabled for same questions across entities)
        // if (this.questionsSettings && this.questionsSettings.length) {
        //   infoQuestion = this.questionsSettings.find(set => set.questionId !== question.id && !!set.isInfoSec);
        // }
        // CHECKING FROM ALL SUB ENTITIES OBJECT
        if (!infoQuestion && Object.keys(this.allSubEntities).length) {
          // iterate all subentities and search in question settings.
          Object.keys(this.allSubEntities).forEach(key => {
            const currSub = this.allSubEntities[key];
            if (
              !infoQuestion &&
              currSub?.entityId !== currentSubId &&
              !!currSub.questionsSettings &&
              !!currSub.questionsSettings.length
            ) {
              infoQuestion = currSub.questionsSettings.find(set => set.questionId !== question.id && !!set.isInfoSec);
            }
          });
        }

        // GETTING INFO SEC QUESTION FROM API CALL
        if (!infoQuestion) {
          // if all else fails....get the nearest question settings with info sec from DB API Call
          // making sure to get another question settings with infoSec enabled ,
          // is from NIST CSF BB, is from the same domain and is not the current question which just got updated
          const filter = {
            isInfoSec: {
              eq: true,
            },
            frameworkName: {
              eq: 'NIST_CSF_BB',
            },
            questionId: {
              ne: question.id,
            },
            left: {
              ne: settings.left,
            },
            right: {
              ne: settings.right,
            },
          };
          const items = await this.getQuestionSettingsListForInfo(filter);
          if (items && items.length) {
            // now we need to filter out the question settings by domain
            // firstly get the question settings assessment , through that assessment we get the sub-entity
            // through that sub-entity we can get the domain. We'll iterate through each unique assessment of question settings
            // till we get the first assessment which has the same domain.
            const assessmentCheckedList = [];
            const domain = DomainFrameworkService.getDomain();

            for (let counter = 0; counter < items.length; counter++) {
              const currAs = items[counter].assessmentId;
              if (!assessmentCheckedList.includes(currAs)) {
                assessmentCheckedList.push(currAs);
                // getting the sub-entity as that only has the domain reference
                const res = await this.customApi.EntityByAssessmentId(currAs);
                if (res?.items?.length && res.items[0].domain === domain) {
                  // break the loop and store the answer
                  infoQuestion = items[counter];
                  counter = items.length;
                }
              }
            }
          }
        }

        // once the other info sec question is found , we'll start processing the existing info sec answers.
        if (infoQuestion) {
          // make the answer API Call to get all answers of that info sec question
          let ansList = [];
          ansList = await this.getAnswersForInfoSec(infoQuestion.assessmentId, infoQuestion.questionId);

          const finalList = ansList.filter(ans => ans.origin === 'INFO_SEC_AUTO' || ans.origin === 'INFO_SEC');
          if (finalList && finalList.length) {
            // get the latest answer on the foreign info sec question
            const latestAno = UtilsService.sortByProp(finalList, 'date', SortDirectionEnum.DESCENDING)[0];

            const comment = 'This Question Has Been Answered By Infosec';
            const createdComment = await this.createComment(question.id, latestAno.userId, comment, 'user', 'infosec');
            // update global state of comments
            this.updateCommentState(createdComment);

            // this will be our new info sec answer to store on the current question
            infoAnswer = {
              questionId: question.id,
              frameworkName: 'NIST_CSF_BB',
              right: question.right,
              userId: latestAno.userId,
              isActionTaken: latestAno.isActionTaken,
              file: latestAno.file,
              answer: latestAno.answer,
              left: question.left,
              riskAction: latestAno.riskAction,
              comment: latestAno.comment,
              id: `${latestAno.userId}#${question.id}#${settings.assessmentId}`,
              date: Date.now(),
              assessmentId: settings.assessmentId,
              origin: 'INFO_SEC_AUTO',
            };

            if (infoAnswer.file && infoAnswer.file.length) {
              infoAnswer.file.forEach(updatedAnswerFile => {
                if (updatedAnswerFile.__typename) {
                  delete updatedAnswerFile.__typename;
                }
              });
            }

            const assessmentId = settings.assessmentId;
            const infoUser = infoAnswer.userId;

            const questionAnswers: GetAnswerQuery[] = UtilsService.isDefined(question.answers)
              ? UtilsService.copyArrayDeep(question.answers)
              : [];

            // making sure the assignment of that foreign info user has an assignment on the question.
            let assignment = await this.questionnaireService.getQuestionUserAssignments(
              question,
              infoUser,
              assessmentId
            );
            if (!(assignment && Object.keys(assignment).length)) {
              assignment = await this.questionnaireService.createAssignment(
                { targetId: question.id, left: question.left, right: question.right },
                infoAnswer.userId,
                assessmentId
              );
              this.assignments.push(assignment);
            }

            // now making sure if the foreign info user doesn't have an existing answer on the question.
            // if so then we'll update it. if not then we'll create it.
            let answerToUpdate;
            if (question && question.answers && question.answers.length) {
              answerToUpdate = question.answers.find(
                answer =>
                  answer.userId === infoUser &&
                  answer.left &&
                  answer.right &&
                  answer.left >= assignment.left &&
                  answer.right <= assignment.right
              );
            }

            // the answer was found so updating it.
            if (question.answers && question.answers.length > 0 && answerToUpdate) {
              const updatedAnswer = (await this.customApi.UpdateAnswer(infoAnswer, {
                assessmentId: { eq: infoAnswer.assessmentId },
              })) as any;
              const existingAnswerIndex = questionAnswers.findIndex(
                answerItem => answerItem.userId === updatedAnswer.userId
              );
              if (existingAnswerIndex > -1) {
                questionAnswers[existingAnswerIndex] = updatedAnswer;
              } else {
                questionAnswers.push(updatedAnswer);
              }
            } else {
              // the answer was not found , so creating the answer.
              infoAnswer.assignmentID = assignment.id;
              infoAnswer.origin = 'INFO_SEC_AUTO';
              const createdAnswer = await this.customApi.CreateAnswer(infoAnswer);
              questionAnswers.push(createdAnswer);
            }

            // storing in back in the original question so that the answer is shown to the user
            question.answers = JSON.parse(JSON.stringify(questionAnswers));

            this.updateAnswerMap(question, infoUser);
          }
        } else {
          // no other questions settings with info sec so do nothing
        }
      }
    } catch (e) {
      console.log(e);
      this.toastr.error('Failed to load Info Sec Answers . . .');
    }
  }

  doesQuestionTemplateExists(path): boolean {
    return this.questionTemplateMapper[path];
  }

  setQuestionTemplateStatus(path, status): void {
    this.questionTemplateMapper[path] = status;
  }

  /**
   * The function gets all the answer of active assessment id and Framework
   * @param assessmentId the assessment ID of the question
   * @param frameworks the freamework
   * @returns a list of all the answers with keys
   */
  answerValueBoolCheck(frameworkAssessmentId, frameworkkey, totalGaps, totalQuestions) {
    const answer = totalGaps + totalQuestions;
    const key = `${frameworkAssessmentId}#${frameworkkey}`;
    this.allFrameworksAnswersObj[key] = answer > 0;
  }
  /**
   * The function gets all the answer of that specific info sec question
   * @param assessmentId the assessment ID of the question
   * @param questionId the question ID of the info sec question
   * @returns a list of all the answers under that question
   */
  async getAnswersForInfoSec(assessmentId: string, questionId: string): Promise<any> {
    try {
      const answer = await this.customApi.AnswersByAssessmentIdAndQuestionId(
        assessmentId,
        {
          eq: questionId,
        },
        null,
        null,
        1000,
        null
      );
      return answer.items;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  /**
   *
   * @param filter the filter options to get the question settings of info sec
   * @returns a list of question settings which has Info Sec True.
   */
  async getQuestionSettingsListForInfo(filter): Promise<GetQuestionSettingsQuery[]> {
    try {
      let settingsList: GetQuestionSettingsQuery[] = [];
      let nextToken: string = null;
      do {
        const settingsResponse = await this.customApi.ListQuestionSettings(filter, 1000);
        settingsList = settingsList.concat(settingsResponse.items);
        nextToken = settingsResponse.nextToken;
      } while (nextToken && !settingsList.length);
      return settingsList;
    } catch (err) {
      console.log('Failed to get Q Settings Info Sec !!');
      return [];
    }
  }

  handleCollapse(control: any, event: boolean, index: number, tab: CollectionTabs): void {
    this.collapseAll(control, tab);
    control.questions[index][tab] = event;
  }

  handleSettings(control: any, event: boolean, index: number): void {
    control.questions[index].showSettings = event;
  }

  handleVersion(control: any, event: boolean, index: number): void {
    control.questions[index].showVersions = event;
  }

  collapseAll(control: any, tab: CollectionTabs): void {
    control.questions.forEach((question: CollectionQuestion) => {
      question[tab] = true;
      question.showSettings = false;
      question.showVersions = false;
    });
  }

  checkIsLastManager(userId, frameworkName): boolean {
    // Check if the manager that is being removed is the last manager of that framework

    const allSpecificFrameworks = this.managersList.filter(managerObject =>
      managerObject.standardFrameworkId.includes('CRB')
        ? managerObject.standardFrameworkId.split('#')[2] === frameworkName
        : managerObject.standardFrameworkId.split('#')[1] === frameworkName
    );
    if (allSpecificFrameworks && allSpecificFrameworks.length === 1) {
      return allSpecificFrameworks[0].managerId === userId ? true : false;
    }
    return false;
  }

  async openDeleteUserModal(
    userAssignment: GetAssignmentQuery,
    node: any,
    fromManagerSection: boolean = false,
    targetId?: string,
    type?: string,
    assessmentId?: string
  ) {
    // Firstly check if the user is being removed from the top of the card.
    // If it is, then check if it is a manager or not.
    const isLastManager = false;
    const removeAssignmentWithConstantRanges = false;

    const activeAssessmentId = assessmentId ? assessmentId : this.subEntity.activeAssessmentId;
    await this.setFrameworkManagersList(activeAssessmentId);
    const isUserManager = this.isUserManager(userAssignment.user.id, node.frameworkName);
    // if (fromManagerSection) {
    //   if (isUserManager) {
    //     const { left, right } = userAssignment;
    //     // If user is manager and is being removed from the whole framework, remove the initial assignment with constant ranges
    //     removeAssignmentWithConstantRanges = node.leftArr[0] === left && right > node.rightArr[0];

    //     isLastManager = this.checkIsLastManager(userAssignment.user.id, node.frameworkName);
    //     if (isLastManager) {
    //       this.toastr.info('Cannot Remove Last Manager');
    //       return;
    //     }
    //   }
    // } else if (isUserManager) {
    //   this.toastr.info('Cannot Remove Manager at this level');
    //   return;
    // }
    this.modalReference = this.modalService.open(CollectionDeleteUserModalComponent, this.ngbModalOptions);
    this.modalReference.componentInstance.userAssignment = userAssignment;
    this.modalClosedSource.next();
    this.modalReference.componentInstance.modalResult.subscribe(async response => {
      if (response) {
        try {
          this.toastr.info('Removing assignment...');
          if (fromManagerSection && !isLastManager && isUserManager) {
            await this.removeManager(node.frameworkName, userAssignment.user.id, activeAssessmentId);
          }
          // Perform check to remove the initial assignment with constant ranges
          if (removeAssignmentWithConstantRanges) {
            await this.customApi.DeleteAssignment(
              { id: userAssignment.id },
              { assessmentId: { eq: userAssignment.assessmentId } }
            );
          }
          const removedUserName = userAssignment.user.name;
          await this.disableUserAssignment(node, userAssignment);
          const logMessage = getLogMessage(LogsKeyEnum.REMOVED_ASSIGNMENT, this.currentUser.name, removedUserName);
          this.entityService.addLog(
            userAssignment.assessmentId,
            userAssignment.assessmentId,
            LogsKeyEnum.REMOVED_ASSIGNMENT,
            logMessage
          );
          this.toastr.success('Assignment removed');

          // If we are in bnb and the removed user is global participant then we
          // need to update the entityIds as well in the user.
          if (UtilsService.isBnB && userAssignment && userAssignment?.user && userAssignment?.user?.roleId) {
            const allRoles = this.userService.allRoles;
            const isParticipantUser =
              allRoles && allRoles.length
                ? allRoles.find(
                    role => userAssignment.user.roleId === role.id && role.name === AppLevelRoleEnum.PARTICIPANT
                  )
                : {};
            if (isParticipantUser) {
              const userSpecificList = this.assignments.filter(
                assignment =>
                  assignment.userId === userAssignment.user.id &&
                  assignment.__typename !== this.disableUserAssignmentVal
              );
              if (!userSpecificList || !userSpecificList.length) {
                // if the list is undefined then we will update the user entityIds.
                const entityIndex = userAssignment.user.entityIds.findIndex(entity => entity === this.subEntity.id);
                if (entityIndex > -1) {
                  userAssignment.user.entityIds.splice(entityIndex, 1);
                }
                const updatedUser = {
                  id: userAssignment.user.id,
                  entityId: userAssignment.user.entityId,
                  entityIds: userAssignment.user.entityIds,
                  isViewOnly: userAssignment.user.isViewOnly,
                  name: userAssignment.user.name,
                  phone: userAssignment.user.phone,
                  role: userAssignment.user.role,
                  roleId: userAssignment.user.roleId,
                };
                await this.customApi.UpdateUser(updatedUser);
              }
            }
          }
        } catch (err) {
          console.log('error', err);
          this.toastr.error('Failed to remove assignment');
        }
      }
    });
  }

  async removeManager(frameworkName: string, userId: string, activeAssessmentId: string): Promise<void> {
    // First remove manager from list then from database.
    const index = this.managersList.findIndex(
      managerObject =>
        managerObject.standardFrameworkId.split('#')[1] === frameworkName && managerObject.managerId === userId
    );
    const removedManagerArray = this.managersList.splice(index, 1);
    const managerDetailsId = {
      id: removedManagerArray[0].standardFrameworkId + '#' + userId,
    };
    await this.deleteFrameworkManager(managerDetailsId, activeAssessmentId);
  }

  async getQuestionSettingsbyAssessment(assessmentId: string): Promise<GetQuestionSettingsQuery[]> {
    try {
      let settingsList: GetQuestionSettingsQuery[] = [];
      let nextToken: string = null;
      do {
        const settingsResponse: QuestionSettingsByAssessmentIdQuery =
          await this.customApi.QuestionSettingsByAssessmentId(assessmentId, null, null, null, nextToken);
        settingsList = settingsList.concat(settingsResponse.items);
        nextToken = settingsResponse.nextToken;
      } while (nextToken);
      return settingsList;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  emitActiveTabStatus(activeTab: string): void {
    this.activeTabObservable.next(activeTab);
  }

  searchKeywordChange(search: string, clear: boolean): void {
    if (clear) {
      this.searchArtifactsChange.next('');
    } else {
      this.searchArtifactsChange.next(search);
    }
  }

  changeAllFilters(filters) {
    this.allFiltersChange.next(filters);
  }
  getShowAllFrameworks(): boolean {
    return !UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
  }

  /**
   * Whenever the left Framework/Group card is selected in collection , this is triggered
   *  in order to update the Collection arcs
   * @param cardType is the Name of the Framework/Group Card
   * @param isGrouped shows if the card selected if a framework card or a group of frameworks card
   * @param subId is the subentity Id of the current Framework Card selected
   * @param totalQuestions is the number of Total Questions in that Framework
   */
  setCardSelected(cardType: string, isGrouped: string, subId: string, totalQuestions = null): void {
    if (cardType === 'All Frameworks' || cardType === 'All_Frameworks') {
      this.cardType = CollectionConstant.LABELS.allFrameworks.replace(/ /g, '_');
      this.isGrouped = true;
    } else {
      this.isHashFound = false;
      this.withHash = '';
      if (cardType.includes('#')) {
        // cardType contains framework name with Hash (#) when re-assessed
        // But we have stored all frameworks names without Hash(#)
        // So in order to get right framework we need to (removeHash) from the (cardType)variable
        cardType = this.removeHashFromReassessed(cardType);
        this.isHashFound = true;
      }
      const isFrameworkKey = this.domainFrameworkService.isFrameworkKey(cardType);
      const VendorCardsEnum = Object.keys(StandardType);
      let incomingCard = isFrameworkKey ? cardType : this.domainFrameworkService.getFrameworkKey(cardType);
      const isIncomingGrouped = isGrouped === 'group' ? true : false;
      if (
        this.cardType !== incomingCard ||
        this.isGrouped !== isIncomingGrouped ||
        this.singleFrameworkSubId !== subId
      ) {
        if (!incomingCard && VendorCardsEnum.includes(cardType)) {
          incomingCard = cardType;
        }
        this.cardType = incomingCard;
        this.isGrouped = isIncomingGrouped;
        this.singleFrameworkSubId = subId;
        if (this.isHashFound) {
          // Here we are again adding value with Hash Part that we had trimmed earlier
          // Because we need to set Arcs of collection according to reassessment name of FrameWork;
          this.cardType += this.withHash;
        }
      }
    }
    const type = [this.cardType, this.isGrouped, subId, totalQuestions];
    this.cardSelectedType.next(type);
  }

  getCardType(): string {
    return this.cardType;
  }

  getIsGrouped(): boolean {
    return this.isGrouped;
  }
  getSubIdSingleFramework(): string {
    return this.singleFrameworkSubId;
  }

  setActiveTab = tab => {
    this.activeTab.next(tab);
  };

  setControlClicked = bool => {
    this.leftControlClicked = bool;
    this.controlClicked.next(bool);
  };

  setCurrentQuestionSet(
    set: [IQuestionIdSet[]],
    currSetIndex: number,
    assessmentId: string,
    frameworkKey: string
  ): void {
    this.currFrameworkAssessmentId = assessmentId;
    if (currSetIndex > -1) {
      this.addNewBackGroundPromises(set[currSetIndex], frameworkKey);
      if (currSetIndex - 1 > -1) {
        this.addNewBackGroundPromises(set[currSetIndex - 1], frameworkKey);
      }
      if (currSetIndex + 1 < set.length) {
        this.addNewBackGroundPromises(set[currSetIndex + 1], frameworkKey);
      }
    }
    this.currentQuestionSet.next(set);
  }

  setOpenTabAndScroll = (tab, question) => {
    return this.openTabAndScroll.next({ tab, question });
  };

  getOpenTabAndScroll = () => this.openTabAndScroll;

  getCurrentQuestionSet(): Observable<[IQuestionIdSet[]]> {
    return this.currentQuestionSet;
  }

  setSelectedQuestionSet(set: IQuestionIdSet[]): void {
    this.currentSelectedSet = set;
    this.selectedQuestionSet.next(set);
  }

  getRecentSelectedQuestionSet(): IQuestionIdSet[] {
    return this.currentSelectedSet;
  }

  getSelectedQuestionSet(): Observable<IQuestionIdSet[]> {
    return this.selectedQuestionSet;
  }

  setCurrentFramework(framework: any): void {
    this.currFrameworkAssessmentId = framework.assessmentId;
    this.currentFramework.next(framework);
  }

  getCurrentFramework(): Observable<any> {
    return this.currentFramework;
  }

  setCurrentVersion(version: any): void {
    this.currentVersion.next(version);
  }

  getCurrentVersion(): Observable<any> {
    return this.currentVersion;
  }
  setDataFound(value: boolean): void {
    this.isDataFound.next(value);
  }

  setCurrentSubentity(subentity: GetEntityQuery): void {
    this.currentSubentity = subentity;
  }

  getDataFound(): Observable<boolean> {
    return this.isDataFound;
  }

  resetCardSelection(): void {
    this.singleFrameworkSubId = null;
    this.isGrouped = true;
    this.cardType = CollectionConstant.LABELS.allFrameworks.replace(/ /g, '_');
  }

  findAndSetControl(id): void {
    this.subscriptions.push(
      this.getCurrentFramework().subscribe(framework => {
        let found = false;
        for (const p4 of framework.children) {
          for (const p3 of p4.children) {
            for (const p2 of p3.children) {
              for (const p1 of p2.children) {
                for (const q of p1.questions) {
                  if (q.id === id) {
                    this.selectedQuestionId = q.id;
                    this.setSelectedControl(p1);
                    found = true;
                  }
                  if (found) {
                    break;
                  }
                }
                if (found) {
                  break;
                }
              }
              if (found) {
                break;
              }
            }
            if (found) {
              break;
            }
          }
          if (found) {
            break;
          }
        }
      })
    );
  }

  allChecked(selectedControlSet): boolean {
    const answers = UtilsService.isBnBCyberSite
      ? Object.values(AnswerEnum).filter(val => val !== AnswerEnum.PARTIAL)
      : Object.values(AnswerEnum);
    let allChecked = true;
    controlSetLoop: for (const control of selectedControlSet) {
      controlLoop: for (const question of control.questions) {
        if (question.answers && question.answers.length > 0) {
          const userAnswer = question.answers.filter(answer => answer.user.id === this.currentUser.id);
          const filteredAnswer =
            userAnswer && userAnswer.length > 0 && (userAnswer[0].answer || userAnswer[0].answer === 0)
              ? userAnswer[0].answer
              : null;
          allChecked = !userAnswer
            ? false
            : userAnswer.length <= 0
              ? false
              : answers.includes(UtilsService.answerNumToEnum(filteredAnswer));
        } else {
          allChecked = false;
        }
        if (!allChecked) {
          break controlLoop;
        }
      }
      if (!allChecked) {
        break controlSetLoop;
      }
    }
    return allChecked;
  }

  createComment = (
    questionId: string,
    userId,
    comment: string,
    type: string,
    framework: string,
    sType: string = null,
    vendorAssessmentId: string = ''
  ): Promise<GetCommentQuery> => {
    const createComment = {
      id: uuid(),
      userId,
      questionId,
      assessmentId: vendorAssessmentId ? vendorAssessmentId : this.subEntity.activeAssessmentId,
      latestSource: ExportSourceEnum.CYGOV,
      comment,
      date: Date.now(),
      type,
      framework,
      sType,
    };
    return this.customApi.CreateComment(createComment);
  };

  // Need to improve it according to New Architecture ( Performance Optimization)
  deleteCommentByUserId = async (userId): Promise<void> => {
    const commentsToDelete = [];
    if (this.comments && this.comments.length > 0) {
      this.comments.forEach((comment, index) => {
        // remove comment from local state
        if (comment.userId === userId) {
          commentsToDelete.push({
            id: comment.id,
            assessmentId: comment.assessmentId,
          });
          // Remove it fro the QuestionSet Collection cache
          this.questionSetCollectionCache[comment?.questionId].comments.splice(index, 1);
        }
      });
    }
    // remove comment from db
    await Promise.all(
      commentsToDelete.map(commentToDelete => {
        this.customApi.DeleteComment(
          { id: commentToDelete.id },
          { assessmentId: { eq: commentToDelete.assessmentId } }
        );
      })
    );
  };

  setIsVendorQuestionnaire(isVendorScreen: boolean): void {
    this.isVendorQuestionnaire = isVendorScreen;
  }

  getIsVendorQuestionnaire(): boolean {
    return this.isVendorQuestionnaire;
  }

  getIsVendor(): boolean {
    return this.isVendor;
  }

  setFirstNonAnswered(Id: string): void {
    this.firstNonAnsweredId = Id;
  }

  getFirstNonAnswered(): string {
    return this.firstNonAnsweredId;
  }

  // sets current viewport question in integration tab
  setCurrViewPortQuestion = (question: Question) => {
    this.currViewPortQuestion = question;
  };

  getCurrViewPortQuestion = () => this.currViewPortQuestion;

  getQuestionByOrder = (data, questions, smartMappingId: string) => {
    if (data && data.children) {
      data.children.forEach(childNode => {
        if (childNode.entityType !== 'GROUP') {
          this.getQuestionByOrder(childNode, questions, smartMappingId);
        }
      });
    }
    if (data && data.questions) {
      data.questions.forEach(question => {
        if (question?.smartMappingId === smartMappingId) {
          question.controlName = data.name;
          question.controlLabel = data.label;
          questions.push(question);
        }
      });
    }
    return questions;
  };

  getControlByControlName = (data, controlName, result) => {
    if (data && data.children) {
      data.children.forEach(childNode => {
        this.getControlByControlName(childNode, controlName, result);
      });
    } else if (data && !data.children && data.name.toLowerCase() === controlName.toLowerCase()) {
      result.push(data);
    }
    return result;
  };

  // Not in use anymore.
  // riskGenerator(question: any): void {
  //   const currentUser = this.getCurrentUser();
  //   const currentUserRole = this.getCurrentRole();
  //   if (
  //     currentUserRole &&
  //     currentUserRole.name &&
  //     currentUserRole.name.toLowerCase() !== RoleEnum.VENDOR.toLowerCase()
  //   ) {
  //     // intentionally not using await here because we want to run this on background.
  //     this.riskService.deduceAnswerRisk(currentUser, question, currentUserRole);
  //   }
  // }

  async downloadFrameworkArtifacts(): Promise<void> {
    // check if Sub Entity is not empty and it has an Assessment ID then download
    const frameName =
      this.selectedFramework && this.selectedFramework.frameworkName ? this.selectedFramework.frameworkName : null;
    if (frameName) {
      this.frameworkZip = new JSZip();
      const promises = [];
      this.blobArray = [];
      this.fileNames = [];
      const questionAnswers = await this.getArtifactsMap(this.selectedFramework);
      const frameworkQuestionAnswers = questionAnswers[frameName];
      const allAnswers = [];
      if (frameworkQuestionAnswers && Object.keys(frameworkQuestionAnswers)?.length) {
        this.toastr.info('All Artifacts will be downloaded in a couple of seconds ....');
        Object.keys(frameworkQuestionAnswers).forEach(key => {
          frameworkQuestionAnswers[key].forEach(answer => {
            allAnswers.push(answer);
          });
        });
      }
      allAnswers.forEach(answer => {
        promises.push(this.retrieveFileObject(answer));
      });
      await Promise.all(promises);

      // storing all file blobs in a zip and in their respective framework folder
      if (this.blobArray && this.blobArray.length) {
        this.blobArray.forEach(file => {
          let filename;
          // current file detail object to push
          const fileDetailObject = { name: file.name, framework: file.frameworkName };
          if (
            !this.fileNames.find(
              singleFile =>
                singleFile.name === fileDetailObject.name && singleFile.framework === fileDetailObject.framework
            )
          ) {
            // if current file is not present in fileNames then its new file
            this.fileNames.push(fileDetailObject);
            // same name as file name if new file comes
            filename = file.name;
          } else {
            // if a file with same name comes again
            this.fileNames.push(fileDetailObject);
            // getting the count of how much times file name repeated
            const counter =
              this.fileNames.filter(
                fileDetail => fileDetail.name === file.name && fileDetail.framework === file.frameworkName
              ).length - 1;
            // appending file name to with a counter so jsZip considers it as a new file
            filename = counter + '-' + file.name;
          }
          const name = 'Artifact-' + filename + '.zip';
          this.frameworkZip.file(name, file.blob);
        });
        // generating the zip file
        const zipFile = await this.frameworkZip.generateAsync({ type: 'blob' });
        // saving the zip file
        saveAs(zipFile, `${frameName}-Artifacts.zip`);
      } else {
        setTimeout(() => {
          this.toastr.error('There are no Artifacts to download');
        }, 1000);
      }
    }
  }

  /**
   * To Retrieve the S3 File Object from the Answers
   * @param answer the object contains the answer details
   */
  async retrieveFileObject(answer: any): Promise<void> {
    const promises = [];
    // check if the answer contains any artifacts
    if (answer.file && answer.file.length) {
      // download each file that is in the answer object
      answer.file.forEach(artifact => {
        promises.push(this.downloadFile(artifact, answer.frameworkName));
      });
      await Promise.all(promises);
    }
  }

  /**
   * @param artifact the S3 file object
   * @param frameworkName the framework name from where the artifact is uploaded
   */
  async downloadFile(artifact: any, frameworkName: string): Promise<any> {
    try {
      const url = await this.fileService.downloadFromS3(artifact);
      const response = await fetch(url);
      const blob = await response.blob();
      this.blobArray.push({
        blob,
        name: artifact.name,
        frameworkName,
      });
    } catch (e) {
      console.error('downloadFile - Error: ', e);
    }
  }

  triggerExportCSV(isHiTrust: boolean): void {
    this.exportCSVTrigger.next(isHiTrust);
  }

  triggerActivityLogsExportCSV(isHiTrust: boolean): void {
    this.exportCSVTriggerActivityLogs.next(isHiTrust);
  }

  /**
   *
   * @param question The question holding all answers and information of that question
   * @returns The answer score
   */
  retrieveQuestionScore(question: any, isHitrust = true): number {
    let userAnswer = null;

    // Checking if Collaborative or Single Question
    if (!question.settings || (question.settings && question.settings.isCollaborative)) {
      const latestAnswerIndex = 0;
      // Getting latest Managers Answer
      if (UtilsService.isDefined(question.managerAnswers) && question.managerAnswers.length > 0) {
        userAnswer = question.managerAnswers[latestAnswerIndex];
      } else if (UtilsService.isDefined(question.answers) && question.answers.length > 0) {
        // if no manager answers exist , then simply get the latest answer
        userAnswer = question.answers[latestAnswerIndex];
      }
    } else {
      // If single , get the latest manager answer . If not
      // then accumulate all answers to get an average
      const latestAnswerIndex = 0;
      if (UtilsService.isDefined(question.managerAnswers) && question.managerAnswers.length > 0) {
        // if manager answer found , get latest one.
        userAnswer = question.managerAnswers[latestAnswerIndex];
      }
      // getting all assignments of that question
      const questionAssignments = this.assignments.filter((assignment: GetAssignmentQuery) => {
        return (
          assignment.left <= question.left &&
          assignment.right >= question.right &&
          assignment.user.role === RoleEnum.PARTICIPANT
        );
      });

      let totalAssignments = questionAssignments.length;
      question.assignments = questionAssignments;

      // filtering all answers that have been answered
      const questionAnswers = question.answers.filter(answer => UtilsService.isDefined(answer.answer));

      if (!questionAnswers || !(questionAnswers && questionAnswers.length > 0)) {
        userAnswer = null;
      }

      const nonParticipantAnswers = questionAnswers.filter(answer => answer.user.role !== RoleEnum.PARTICIPANT);

      let totalScore;
      // getting the answer of non participant answers as they have more priority
      if (nonParticipantAnswers.length > 0) {
        userAnswer = UtilsService.sortByProp(nonParticipantAnswers, 'date', SortDirectionEnum.DESCENDING)[0];
      } else {
        // if there are only participants on this question , simply take average of all the answers.
        const notApplicableAnswersCount = questionAnswers.filter(answer => answer.answer === 0).length;
        totalAssignments -= notApplicableAnswersCount;

        if (!totalAssignments) {
          userAnswer = null;
        }

        totalScore = questionAnswers.reduce((acc, curr) => {
          acc += curr.answer;
          return acc;
        }, 0);
        totalScore /= totalAssignments;
        userAnswer = {
          answer: totalScore,
        };
      }
    }
    if (
      !UtilsService.isDefined(userAnswer) ||
      !(UtilsService.isDefined(userAnswer) && UtilsService.isDefined(userAnswer.answer))
    ) {
      return !isHitrust ? -1 : 0;
    } else {
      if (userAnswer.answer < 0 || userAnswer.answer > 10) {
        return 0;
      }
      return userAnswer.answer;
    }
  }

  /**
   *
   * @param frameworkName: current framework name
   */
  exportCSVForSelectedFramework(frameworkName: string): void {
    if (frameworkName) {
      this.toastr.info('The report will be generated in a couple of seconds ....');
      this.reportService
        .generateCSVReport(this.subEntity.id, frameworkName)
        .then(data => {
          window.open(JSON.parse(data.Payload.toString()).downloadUrl);
          this.toastr.info('The report is ready!');
        })
        // eslint-disable-next-line quotes
        .catch(() => this.toastr.info("Can't build the report!"));
    } else {
      this.toastr.info('Cannot Export CSV file!');
    }
  }

  /**
   *
   * @param node The tree Node
   * @param sheetData The data holding all questions in their respective category
   * @returns The complete Category Array containing all questions with their scores
   */
  reduceTreeToQuestions(node: any, sheetData: any = {}): void {
    if (node.children && node.children.length) {
      node.children.forEach(nodeChild => {
        this.reduceTreeToQuestions(nodeChild, sheetData);
      });
    } else if (node.questions) {
      let questArray = [];
      let accScore = 0;

      node.questions.forEach((question, i) => {
        const newQuestionObj: QuestionObj = {
          order: question.order,
          labelName: UtilsService.hiTrustLabelName(question.order),
          points: 0,
          answer: 0,
          totalScore: 0,
        };

        // calculating score for the current question
        newQuestionObj.points = CollectionConstant.HITRUST_POINTS[newQuestionObj.labelName.toUpperCase()];
        newQuestionObj.answer = this.retrieveQuestionScore(question) / 10;
        newQuestionObj.totalScore = newQuestionObj.answer * newQuestionObj.points;
        accScore = accScore + newQuestionObj.totalScore;
        questArray.push(newQuestionObj);

        // after every 5th question , a new Entry starts.
        // so storing 5 questions and their data in control object
        if (i !== 0 && (i + 1) % 5 === 0) {
          const controlObj: ControlObj = {
            pageName: node.selectionPath[3],
            pageNameKey: '',
            completeAccScore: 0,
            maturityRating: 0,
            riskRating: '',
            orderKey: '',
            codeVal: 0,
            uniqueID: '',
            questArr: [],
          };

          // getting some constant values for further calculation
          const actualOrder = question.order;
          controlObj.orderKey = 'ORDER_' + actualOrder;
          controlObj.codeVal = this.hiTrustUniqueIDS[controlObj.orderKey].codeVal;
          controlObj.uniqueID = this.hiTrustUniqueIDS[controlObj.orderKey].uniqueID;

          // Getting the page name key , which is the Category Name
          const regex = new RegExp(/&/, 'g');
          const keyName = controlObj.pageName.toUpperCase();
          const newValue = keyName.replaceAll(regex, 'AND');

          const regex2 = new RegExp(/ /, 'g');
          const newValue2 = newValue.replaceAll(regex2, '_');

          const regexFinal = new RegExp(/,/, 'g');
          const newValueFinal = newValue2.replaceAll(regexFinal, '');
          controlObj.pageNameKey = newValueFinal;

          // Calculating the score values for the current Control which has 5 Questions.
          controlObj.completeAccScore = JSON.parse(JSON.stringify(accScore));
          controlObj.riskRating = this.calculateResidualRating(controlObj.codeVal, controlObj.completeAccScore);
          controlObj.questArr = JSON.parse(JSON.stringify(questArray));
          controlObj.maturityRating = this.calculatePrismaScoreLevel(controlObj.completeAccScore);

          // storing them into the Sheet Array.
          const pager = controlObj.pageNameKey;
          if (sheetData[pager] && sheetData[pager].length) {
            sheetData[pager].push(controlObj);
          } else {
            sheetData[pager] = [];
            sheetData[pager].push(controlObj);
          }

          questArray = [];
          accScore = 0;
        }
      });
    }
    return sheetData;
  }

  parseValue(value): string {
    return value.split('"').join('');
  }

  getCommentJoinedList(assessmentId: string, questionId: string) {
    const commentsResult = this.getCommentsByQuestionId(questionId);
    const usersComments = commentsResult.filter(c => !c?.type || c?.type === 'user');
    const commentsList = usersComments.map(c => {
      const userName = c.user.items.length ? c.user.items[0].name : 'Unknown User';
      return `${userName}:  ${c.comment}`;
    });
    return commentsList.join(', ');
  }

  async getLogsJoinedList(assessmentId: string, questionId: string) {
    const logs = await this.getLogs(assessmentId, questionId);
    const concatLogs = logs.reduce((acc, curr) => (acc += `${curr.createdAt.split('T')[0]}: ${curr.message}, `), '');
    return concatLogs;
  }

  async getLogs(assessmentId, questionId): Promise<any[]> {
    try {
      const targetId = `${assessmentId}#${questionId}`;
      const logs = await this.getLogsByTargetId(targetId, assessmentId);
      if (logs && logs.items) {
        return logs.items;
      }
      return [];
    } catch (error) {
      console.log('❌ error while fetching logs', error);
      return [];
    }
  }

  createTreeDataArray(node: any, data, assessmentId) {
    if (UtilsService.isDefined(node.children)) {
      node.children.forEach(child => {
        this.createTreeDataArray(child, data, assessmentId);
      });
    } else {
      node.questions.forEach(question => {
        const score = this.retrieveQuestionScore(question, false);
        const commentJoinedList = this.getCommentJoinedList(assessmentId, question.id);
        data.push([
          `"${node?.selectionPath?.length > 5 ? this.parseValue(node.selectionPath[2]) : ''}"`,
          `"${node?.selectionPath?.length > 5 ? this.parseValue(node.selectionPath[3]) : ''}"`,
          `"${node?.name ? this.parseValue(node.name) : ''}"`,
          `"${node?.label ? this.parseValue(node.label) : ''}"`,
          `"${question?.name ? this.parseValue(question.name) : ''}"`,
          `"${question?.description ? this.parseValue(question.description) : ''}"`,
          `"${question?.impact ? question.impact.toUpperCase() : ''}"`,
          `"${score > -1 ? UtilsService.detailedAnswerNumToEnum(score).replaceAll('_', ' ') : 'Not Answered'}"`,
          `"${commentJoinedList ? commentJoinedList : ''}"`,
        ]);
      });
    }
  }

  async createActivitiesDataArray(node: any, data, assessmentId, isExportActivityLogs) {
    if (UtilsService.isDefined(node.children)) {
      for (const child of node.children) {
        await this.createActivitiesDataArray(child, data, assessmentId, isExportActivityLogs);
      }
    } else {
      for (const question of node.questions) {
        const logsList = await this.getLogsJoinedList(assessmentId, question.id);
        data.push([
          `"${node?.selectionPath?.length > 5 ? this.parseValue(node.selectionPath[2]) : ''}"`,
          `"${node?.selectionPath?.length > 5 ? this.parseValue(node.selectionPath[3]) : ''}"`,
          `"${node?.name ? this.parseValue(node.name) : ''}"`,
          `"${node?.label ? this.parseValue(node.label) : ''}"`,
          `"${question?.name ? this.parseValue(question.name) : ''}"`,
          `"${question?.description ? this.parseValue(question.description) : ''}"`,
          `"${logsList ? logsList : ''}"`,
        ]);
      }
    }
  }

  async exportTreeCSV(framework: any, isExportActivityLogs: boolean = false) {
    console.log('framework: ', framework);
    let headers = [];
    let namePostFix = 'Report';
    const commonHeaders = [
      'Function',
      'Process Sub-Area',
      'Control Name',
      'Control Label',
      'Control Objectives',
      'Supplemented Guide',
    ];

    if (!isExportActivityLogs) {
      headers = [[...commonHeaders, 'Severity', 'Answer', 'Comments']];
    } else {
      headers = [[...commonHeaders, 'Logs']];
      namePostFix = 'Logs';
    }

    // If framework doesn't have the belongsTo attribute, then it should use activeAssessmentId.
    // Only in case of 'All Frameworks' View , belongsTo is found , in case of single sub-entity view from drop down.
    // Use the this.subentity variable.
    const assessmentId = framework?.belongsTo?.assessmentId
      ? framework.belongsTo.assessmentId
      : this.subEntity.activeAssessmentId;
    if (isExportActivityLogs) {
      this.toastr.info('Creating activity logs may take time');
      await this.createActivitiesDataArray(framework, headers, assessmentId, isExportActivityLogs);
    } else {
      this.createTreeDataArray(framework, headers, assessmentId);
    }
    const currDate = new Date();
    const formattedDate = `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`;
    const csvContent = headers.map(info => info.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    saveAs(blob, `${formattedDate} ${framework.name} ${namePostFix}.csv`);
  }

  // This function is no more used as the HITRUST report logic has been moved to generateReport lambda
  async exportHiTrustTreeCSV(frameworkTree: any): Promise<void> {
    this.toastr.info('Creating Export File. Please Wait...');
    try {
      // getting the meta data and template format for the current Framework
      await this.getHITRUSTMetaData();

      // Reducing the Tree structure to Category Section Structure
      const sheetObj = this.reduceTreeToQuestions(frameworkTree);
      // Getting the template
      const workbook = this.hiTrustTemplate;
      let categoryID = '';

      const currDate = moment(new Date());
      const createdOn = 'Created On: ' + currDate.format('MM/DD/YYYY');
      const fileName = 'MyCSF Self Assessment ' + currDate.format('MMMM') + ' ' + currDate.format('YYYY') + '.xlsx';
      const sheetIDS = HITRUST_SHEET_VAL;

      // Iterating each Sheet in the Template then each Row to store the values
      workbook.eachSheet((worksheet, sheetId) => {
        categoryID = 'D' + sheetId;

        const currSheet = sheetObj[sheetIDS[categoryID]] as [ControlObj];
        const totalControls = currSheet.length;

        // The range of rows that have the actual values that we need to edit
        const startingRow = sheetId === 1 ? 19 : 9;
        const endingRow = startingRow + totalControls;
        worksheet.eachRow((row, rowid) => {
          // this is the Creation Date that we need to update
          if (sheetId === 1 && rowid === 15) {
            for (let idx2 = 1; idx2 <= 7; idx2++) {
              const cellNum = idx2 + 1;
              row.getCell(cellNum).value = createdOn;
            }
            row.commit();
          }

          // Storing into their respective rows and columns
          if (rowid >= startingRow && rowid <= endingRow) {
            const uniqueName = row.getCell(1).value;
            const data = currSheet.find(val => val.uniqueID === uniqueName);
            let zeroCounter = 0;
            if (data && data.questArr && data.questArr.length > 0) {
              const questionArray = data.questArr as QuestionObj[];
              questionArray.forEach((question, idx) => {
                const cellNum = idx + 9;
                if (question.totalScore !== 0) {
                  row.getCell(cellNum).value = question.totalScore;
                } else {
                  zeroCounter++;
                }
              });

              // Making sure if all answers under a Control are 0 then don't Process it.
              // At least 1 answer must be given to store the Maturity and Risk score.
              if (zeroCounter !== 5) {
                row.getCell(14).value = data.maturityRating;
                row.getCell(15).value = data.riskRating;
              } else {
                row.getCell(15).value = 'NULL';
              }

              row.commit();
            }
          }
        });
      });

      // Saving the File.
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), fileName);
    } catch (e) {
      this.toastr.error('Failed to Export File');
      console.log(e);
    }
  }

  /**
   * This function is no more used as the HITRUST report logic has been moved to generateReport lambda
   * @param value The score of the Control
   * @returns The Maturity Score Level
   */
  calculatePrismaScoreLevel(value: number): number {
    let level = 0;
    if (value >= 0 && value < 27) {
      level = 1;
    } else if (value < 53) {
      level = 2;
    } else if (value < 79) {
      level = 3;
    } else if (value < 90) {
      level = 4;
    } else if (value <= 100) {
      level = 5;
    }
    return level;
  }

  /**
   * This function is no more used as the HITRUST report logic has been moved to generateReport lambda
   * @param code The constant value for that control to use in formula
   * @param maturityScore The Maturity Score or Prisma Score
   * @returns The Residual Rating state
   */
  calculateResidualRating(code: number, maturityScore: number): string {
    const impact = (code - 1) * 25;
    const likely = (100 - maturityScore) / 100;
    const result = impact * likely;
    let final = '';
    if (result >= 0 && result <= 10) {
      final = 'Very Low';
    } else if (result >= 11 && result <= 20) {
      final = 'Low';
    } else if (result >= 21 && result <= 30) {
      final = 'Moderate';
    } else if (result >= 31 && result <= 40) {
      final = 'High';
    } else if (result >= 41 && result <= 100) {
      final = 'Critical';
    }
    return final;
  }

  /** This function is no more used as the HITRUST report logic has been moved to generateReport lambda
   * Getting the meta data for Hi Trust Framework for Export File
   */
  async getHITRUSTMetaData(): Promise<void> {
    if (!this.metaDataLoaded) {
      const [urlJSON, urlTemplate] = await Promise.all([
        this.fileService.getFileUrlFromBucket('FRAMEWORK_EXPORT_TEMPLATE/HITRUST/uniqueIDS.json', {
          level: 'public',
        }),
        this.fileService.getFileUrlFromBucket('FRAMEWORK_EXPORT_TEMPLATE/HITRUST/template.xlsx', {
          level: 'public',
        }),
      ]);
      if (urlJSON && urlTemplate) {
        this.hiTrustUniqueIDS = JSON.parse(await this.fileService.getFile(urlJSON));
        const response = await fetch(urlTemplate);
        const reader = response.body.getReader();
        let completion = false;
        const chunks = [];
        while (!completion) {
          const { done, value } = await reader.read();
          if (done) {
            completion = true;
          } else {
            chunks.push(value);
          }
        }
        const blob = new Blob(chunks);
        const buffer = await blob.arrayBuffer();
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(buffer);
        this.hiTrustTemplate = workbook;
      }
      this.metaDataLoaded = true;
    }
  }

  async getVersionsByTargetId(targetId: string, assessmentId: string): Promise<VersionsByTargetIdQuery> {
    try {
      const filter = {
        assessmentId: {
          eq: assessmentId,
        },
      };
      const versions: VersionsByTargetIdQuery = await this.customApi.VersionsByTargetId(targetId, null, filter);
      return versions;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async getLogsByTargetId(targetId: string, assessmentId: string): Promise<LogsByTargetIdQuery> {
    try {
      const filter = {
        assessmentId: {
          eq: assessmentId,
        },
      };
      const logs: LogsByTargetIdQuery = await this.customApi.LogsByTargetId(targetId, null, filter);
      return logs;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  onCreateLogsListener(): any {
    return this.customApi.OnCreateLogsListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const updatedLog: GetLogsQuery = res.value.data.onCreateLogs;
        if (UtilsService.isDefined(updatedLog)) {
          this.onLogsCreate.next(updatedLog);
        }
      },
    });
  }
  onCreateVersionsListener(): any {
    return this.customApi.OnCreateVersionsListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const updatedVersion: GetVersionsQuery = res.value.data.onCreateVersions;
        if (UtilsService.isDefined(updatedVersion)) {
          this.onVersionsCreate.next(updatedVersion);
        }
      },
    });
  }
  onCreateTagsListener(): any {
    return this.customApi.OnCreateTagsListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const createdTag: GetTagsQuery = res.value.data.onCreateTags;
        if (UtilsService.isDefined(createdTag)) {
          this.onTagsCreate.next(createdTag);
        }
      },
    });
  }

  /**
   * It will listen when user delete a tag (Will remove it from tag list in filter modal)
   */
  onDeleteTagsListener(): any {
    return this.customApi.OnDeleteTagsListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const deletedTag: GetTagsQuery = res.value.data.onDeleteTags;
        if (UtilsService.isDefined(deletedTag)) {
          this.onTagsDelete.next(deletedTag);
        }
      },
    });
  }

  /**
   * *Answer update Listener
   */
  onUpdateAnswerListener() {
    // { assessmentId: { eq: this.currFrameworkAssessmentId } }
    return this.customApi.OnUpdateAnswerListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const updatedAnswer: GetAnswerQuery = res.value.data.onUpdateAnswer;
        if (UtilsService.isDefined(updatedAnswer)) {
          this.onUpdateAnswerSub.next(updatedAnswer);
        }
      },
    });
  }

  /**
   * *new Answer listener
   */
  onCreateNewAnswerListener() {
    // { assessmentId: { eq: this.currFrameworkAssessmentId } }
    return this.customApi.OnCreateAnswerListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const createAnswer: GetAnswerQuery = res.value.data.onCreateAnswer;
        if (UtilsService.isDefined(createAnswer)) {
          this.onCreateNewAnswerSub.next(createAnswer);
        }
      },
    });
  }

  /**
   * *deselected /deleted answer listener
   */
  onDeleteAnswerListener() {
    // { assessmentId: { eq: this.currFrameworkAssessmentId } }
    return this.customApi.OnDeleteAnswerListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const deleteAnswer: GetAnswerQuery = res.value.data.onDeleteAnswer;
        if (UtilsService.isDefined(deleteAnswer)) {
          this.onDeleteAnswerSub.next(deleteAnswer);
        }
      },
    });
  }

  onUpdateQuestionnaireCompletionListener() {
    return this.customApi.OnUpdateQuestionnaireCompletionListener(this.currentUserToken).subscribe((data: any) => {
      if (data && !data?.value?.data?.onUpdateQuestionnaireCompletion?.id?.includes('smart')) {
        const assessmentId = data.value.data.onUpdateQuestionnaireCompletion.assessmentId;
        const updatedCompletion = data.value.data.onUpdateQuestionnaireCompletion;

        if (this.cachedQuestionnnaireCompletion[assessmentId]) {
          const index = this.cachedQuestionnnaireCompletion[assessmentId].findIndex(completionObj => {
            return completionObj.id === updatedCompletion.id;
          });

          if (index !== -1) {
            this.cachedQuestionnnaireCompletion[assessmentId][index] = updatedCompletion;
          } else {
            this.cachedQuestionnnaireCompletion[assessmentId].push(updatedCompletion);
          }
        }
      }
    }) as any;
    // });
  }

  async getTagsByEntityId(entityId: string, filter = null): Promise<any> {
    try {
      const limit = 300;
      let nextToken;
      let totalTags = [];
      do {
        const { items: tags, nextToken: rnextToken }: TagsByEntityIdQuery = await this.customApi.TagsByEntityId(
          entityId,
          null,
          filter,
          limit,
          nextToken
        );
        nextToken = rnextToken;
        totalTags = totalTags.concat(tags);
        this.setTagsAgainstAllQuestions(entityId, totalTags);
        this.allTagsEntityId[entityId] = totalTags;
      } while (nextToken);
      return totalTags;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  getTagsByQuestionId(targetId: string): GetTagsQuery[] {
    const ids = targetId.split('#');
    const questionId = ids?.length >= 2 ? ids[1] : null;
    const tags = this.questionSetCollectionCache[questionId]?.tags;
    return tags?.length ? tags : [];
  }

  async fetchDisableAssignmentsByAssessmentId(assessmentId: string): Promise<GetDisableAssignmentQuery[]> {
    // DisableAssignmentsByAssessmentIdQuery
    try {
      let disableAssignments: GetDisableAssignmentQuery[] = [];
      let nextToken: string = null;
      do {
        const disabledAssignmentsObj: DisableAssignmentsByAssessmentIdQuery =
          await this.customApi.DisableAssignmentsByAssessmentId(assessmentId);
        disableAssignments = disableAssignments.concat(disabledAssignmentsObj.items);
        nextToken = disabledAssignmentsObj.nextToken;
      } while (nextToken);
      return disableAssignments;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async fetchDisableAssignmentsByAssessmentIdAndUserId(
    userId: string,
    assessmentId: string
  ): Promise<GetDisableAssignmentQuery[]> {
    // DisableAssignmentsByAssessmentIdQuery
    try {
      let disableAssignments: GetDisableAssignmentQuery[] = [];
      let nextToken: string = null;
      do {
        const disabledAssignmentsObj: DisableAssignmentsByAssessmentIdQuery =
          await this.customApi.DisableAssignmentsByUserIdAndAssessment(userId, { eq: assessmentId });
        disableAssignments = disableAssignments.concat(disabledAssignmentsObj.items);
        nextToken = disabledAssignmentsObj.nextToken;
      } while (nextToken);
      return disableAssignments;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async fetchAllAssignmentsByAssessmentIdAndUserId(userId, assessmentId): Promise<GetAssignmentQuery[]> {
    try {
      let assignments: GetAssignmentQuery[] = [];
      let nextToken: string = null;
      do {
        const assignmentsObj: AssignmentsByUserIdAndAssessmentQuery =
          await this.customApi.AssignmentsByUserIdAndAssessment(
            userId,
            { eq: assessmentId },
            null,
            null,
            100,
            nextToken
          );
        assignments = assignments.concat(assignmentsObj.items);
        nextToken = assignmentsObj.nextToken;
      } while (nextToken);
      return assignments;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async assignQuestionToUserSendEmailNotification(user, rootEntityId, subEntityId, isThirdParty) {
    const json = {
      type: EntityNotifyTypeEnum.NOTIFY_USER_NEW_QUESTION_ASSIGN,
      user,
      rootEntityId,
      subEntityId,
      isThirdParty,
    };
    try {
      await this.clientLambdaService.invokeLambda('sendNotification', json);
    } catch (error) {
      console.error(
        `Issue While Sending Notification Email${EntityNotifyTypeEnum.NOTIFY_USER_NEW_QUESTION_ASSIGN}` +
          JSON.stringify(error)
      );
    }
  }

  async reassessmentStartedSendEmailNotification(user, rootEntityId, subEntityId, frameworkName) {
    const json = {
      type: EntityNotifyTypeEnum.NOTIFY_USER_REASSESSMENT_STARTED,
      user,
      rootEntityId,
      subEntityId,
      frameworkName,
    };
    try {
      await this.clientLambdaService.invokeLambda('sendNotification', json);
    } catch (error) {
      console.error(
        `Issue While Sending Notification Email${EntityNotifyTypeEnum.NOTIFY_USER_REASSESSMENT_STARTED}` +
          JSON.stringify(error)
      );
    }
  }

  async formatAssignmentOrDisableAssignmentsOperations(
    assignment,
    type,
    currentFramework = null,
    isThirdParty = false,
    needTocreateDisableAssignment = true
  ): Promise<void> {
    const left = assignment.left;
    const right = assignment.right;
    const frameworkKey = assignment.frameworkKey;
    const assignmentsToAdd = [];
    let user: GetUserQuery = null;
    let role: GetRoleQuery = null;
    let assignmentsToDelete = [];
    const childId =
      currentFramework && currentFramework.childId
        ? currentFramework.childId
        : this.subEntity
          ? this.subEntity.id
          : null;
    if (childId) {
      switch (type.toLowerCase()) {
        // ================================ Assignment =========================================
        case 'assignment': {
          // ============================================================================
          if (!this.allSubEntities[childId]?.assignments?.length) {
            await this.fetchAssignmentsByAssessmentId(assignment.assessmentId, assignment.childId);
          }
          let dbAssignments = this.allSubEntities[childId]?.assignments;
          dbAssignments = dbAssignments.filter(
            dbAssign => dbAssign.__typename === 'Assignment' && dbAssign.userId === assignment.userId
          );
          if (currentFramework) {
            // fetch only current open framework
            dbAssignments = dbAssignments.filter(
              dba => currentFramework.left <= dba.left && currentFramework.right >= dba.right
            );
          }
          // ============================================================================
          // delete assignments which come within current new Assignment
          const smallerRangeAssignments = dbAssignments.filter(dba => left <= dba.left && right >= dba.right);
          if (smallerRangeAssignments && !!smallerRangeAssignments.length) {
            assignmentsToDelete = assignmentsToDelete.concat(smallerRangeAssignments);
            await Promise.all(
              smallerRangeAssignments.map(sra =>
                this.customApi.DeleteAssignment({ id: sra.id }, { assessmentId: { eq: assignment.assessmentId } })
              )
            );
          }

          // ============================================================================
          // check if current Assignment related ranges already exist in db, then format them if not then simply create new one
          const rangeAssignments = dbAssignments.filter(da => da.right === left - 1 || da.left === right + 1);
          console.log('rangeAssignments', rangeAssignments);
          // Format Assignments of same user if it was multiple times assign or unassign
          if (rangeAssignments && !!rangeAssignments.length) {
            if (rangeAssignments.length === 2) {
              // incoming assignment 'left - 1' is another assignment right and incoming assignment 'right + 1'
              // is another assignment left
              const rangeAssignmentFirst = rangeAssignments.find(da => da.right === left - 1);
              const rangeAssignmentSecond = rangeAssignments.find(da => da.left === right + 1);
              let newAssignment: any = {
                ...rangeAssignmentFirst,
                left: rangeAssignmentFirst.left,
                right: rangeAssignmentSecond.right,
                frameworkKey,
              };
              delete newAssignment.id;
              delete newAssignment.__typename;
              delete newAssignment.createdAt;
              delete newAssignment.user;
              // creating new Assignment
              newAssignment = await this.customApi.CreateAssignment(newAssignment);
              assignmentsToAdd.push(newAssignment);

              if (newAssignment && newAssignment.id) {
                // remove old assignment because its range includes in above new one
                await this.customApi.DeleteAssignment(
                  { id: rangeAssignmentFirst.id },
                  { assessmentId: { eq: assignment.assessmentId } }
                );
                await this.customApi.DeleteAssignment(
                  { id: rangeAssignmentSecond.id },
                  { assessmentId: { eq: assignment.assessmentId } }
                );
                assignmentsToDelete.push(rangeAssignmentFirst);
                assignmentsToDelete.push(rangeAssignmentSecond);
              }
              // if userAssignment is question level and it has same left and right,
              // otherwise if assignment is control/category level then dont.
              // if (userAssignment.left === left && userAssignment.right === right) {
              //   await this.apiService.DeleteAssignment({
              //     id: userAssignment.id,
              //   });
              // }
            } else if (rangeAssignments.length === 1) {
              // it means that user was unassign or reassign first question or last question or question whose siblings also unassign
              const secondQuestion = rangeAssignments.find(ra => ra.left === right + 1);
              if (secondQuestion) {
                // secondQuestion means, assignment start from secondQuestion and incoming assigned question
                // is the first question of control
                let newAssignment: any = {
                  ...secondQuestion,
                  left,
                  right: secondQuestion.right,
                  frameworkKey,
                };
                delete newAssignment.id;
                delete newAssignment.__typename;
                delete newAssignment.createdAt;
                delete newAssignment.user;
                // creating new Assignment
                newAssignment = await this.customApi.CreateAssignment(newAssignment);
                assignmentsToAdd.push(newAssignment);

                if (newAssignment && newAssignment.id) {
                  // remove old assignment because its range includes in above new one
                  await this.customApi.DeleteAssignment(
                    { id: secondQuestion.id },
                    { assessmentId: { eq: assignment.assessmentId } }
                  );
                  assignmentsToDelete.push(secondQuestion);
                }
              } else {
                const secondLastQuestion = rangeAssignments.find(ra => ra.right === left - 1);
                // secondLastQuestion means, assignment end from secondLastQuestion and incoming assigned question
                // is the last question of control
                let newAssignment: any = {
                  ...secondLastQuestion,
                  left: secondLastQuestion.left,
                  right,
                  frameworkKey,
                };
                delete newAssignment.id;
                delete newAssignment.__typename;
                delete newAssignment.createdAt;
                delete newAssignment.user;
                // creating new Assignment
                newAssignment = await this.customApi.CreateAssignment(newAssignment);
                assignmentsToAdd.push(newAssignment);

                if (newAssignment && newAssignment.id) {
                  // remove old assignment because its range includes in above new one
                  await this.customApi.DeleteAssignment(
                    { id: secondLastQuestion.id },
                    { assessmentId: { eq: assignment.assessmentId } }
                  );
                  assignmentsToDelete.push(secondLastQuestion);
                }
              }
            }
          } else {
            // ============================================================================
            assignmentsToDelete.push({ ...assignment });
            // Simply create new assignment
            delete assignment.id;
            delete assignment.__typename;
            delete assignment.user;
            const newAssignment = await this.customApi.CreateAssignment(assignment);
            assignmentsToAdd.push(newAssignment);
          }

          // ============================================================================
          // Delete disable assignment of newAssignment if exist.
          let disabledAssignments = this.allSubEntities[childId]?.assignments;
          disabledAssignments = disabledAssignments.filter(
            disableAssignment =>
              disableAssignment.__typename === 'DisableAssignment' && disableAssignment.userId === assignment.userId
          );

          if (currentFramework) {
            // fetch only current open framework
            disabledAssignments = disabledAssignments.filter(
              dba => currentFramework.left <= dba.left && currentFramework.right >= dba.right
            );
          }

          // ============================================================================
          // Check disable assignments of which lies within new assignment left and right then delete it
          // Check disable assignments of same left and right then delete it
          const existedDisableAssignments = disabledAssignments.filter(da => left <= da.left && right >= da.right);
          if (existedDisableAssignments && !!existedDisableAssignments.length) {
            console.log('removing within disable assignments');
            await Promise.all(
              existedDisableAssignments.map(eda =>
                this.customApi.DeleteDisableAssignment(
                  { id: eda.id },
                  { assessmentId: { eq: assignment.assessmentId } }
                )
              )
            );
            assignmentsToDelete = assignmentsToDelete.concat(existedDisableAssignments);
            // removing deleted disabled assignments from local array
            disabledAssignments = disabledAssignments.filter(
              da => !existedDisableAssignments.find(eda => eda.id === da.id)
            );
          }

          // ============================================================================
          // Check current assignment is within disable assignment then remove it by separate disable assignment into two record
          // removing current assignment left and right
          const existedDisableAssignmentObj: any = disabledAssignments.find(da => da.left <= left && da.right >= right);
          if (existedDisableAssignmentObj) {
            // backup for adding into disabled assignmentsToDelete for frontend
            const existedDisabledAssignmentBackup = { ...existedDisableAssignmentObj };

            if (existedDisableAssignmentObj.left === left && existedDisableAssignmentObj.right > right) {
              console.log('removing disable assignments left same and right not');
              existedDisableAssignmentObj.left = right + 1;
            } else if (existedDisableAssignmentObj.left < left && existedDisableAssignmentObj.right === right) {
              console.log('removing disable assignments left different but right same');
              existedDisableAssignmentObj.right = left - 1;
            } else {
              console.log('removing same left right disable assignments');
              const newDisabledAssignmentObj = {
                ...existedDisableAssignmentObj,
                right: left - 1,
              };
              existedDisableAssignmentObj.left = right + 1;

              // checking if the new assingment doesn't contain garbage value range.
              if (newDisabledAssignmentObj.left !== newDisabledAssignmentObj.right) {
                delete newDisabledAssignmentObj.__typename;
                delete newDisabledAssignmentObj.user;
                delete newDisabledAssignmentObj.id;
                const newAssignment = await this.customApi.CreateDisableAssignment(newDisabledAssignmentObj);
                assignmentsToAdd.push(newAssignment);
              }
            }
            if (existedDisableAssignmentObj.left === existedDisableAssignmentObj.right) {
              // garbage data
              assignmentsToDelete.push(existedDisableAssignmentObj);
              await this.customApi.DeleteDisableAssignment(
                { id: existedDisableAssignmentObj.id },
                { assessmentId: { eq: assignment.assessmentId } }
              );
            } else {
              if (existedDisabledAssignmentBackup) {
                // Remove old existing Assignment before adding updated assignment
                assignmentsToDelete.push({ ...existedDisabledAssignmentBackup });
              }
              assignmentsToAdd.push({ ...existedDisableAssignmentObj });
              delete existedDisableAssignmentObj.__typename;
              delete existedDisableAssignmentObj.user;
              await this.customApi.UpdateDisableAssignment(existedDisableAssignmentObj, {
                assessmentId: { eq: assignment.assessmentId },
              });
            }
          }
          break;
        }

        // ================================ Disabled Assignment =========================================
        case 'disabledassignment': {
          // ============================================================================
          if (!this.allSubEntities[childId]?.assignments?.length) {
            await this.fetchAssignmentsByAssessmentId(assignment.assessmentId, assignment.childId);
          }
          let dbDisabledAssignments = this.allSubEntities[childId]?.assignments;
          dbDisabledAssignments = dbDisabledAssignments.filter(
            disableAssignment =>
              disableAssignment.__typename === 'DisableAssignment' && disableAssignment.userId === assignment.userId
          );

          if (currentFramework) {
            // fetch only current open framework
            dbDisabledAssignments = dbDisabledAssignments.filter(
              dba => currentFramework.left <= dba.left && currentFramework.right >= dba.right
            );
          }
          // ============================================================================
          // delete disabled assignments which come within current new Assignment
          const smallerRangeAssignments = dbDisabledAssignments.filter(dba => left <= dba.left && right >= dba.right);
          if (smallerRangeAssignments && !!smallerRangeAssignments.length) {
            await Promise.all(
              smallerRangeAssignments.map(sra => this.customApi.DeleteDisableAssignment({ id: sra.id }), {
                assessmentId: { eq: assignment.assessmentId },
              })
            );
            assignmentsToDelete = assignmentsToDelete.concat(smallerRangeAssignments);
            dbDisabledAssignments = dbDisabledAssignments.filter(
              da => !smallerRangeAssignments.some(a => a.id === da.id)
            );
          }

          // ============================================================================
          // check if current Assignment related ranges already exist in db, then format them if not then simply create new one
          const rangeAssignments = dbDisabledAssignments.filter(da => da.right === left - 1 || da.left === right + 1);

          // Format Assignments of same user if it was multiple times assign or unassign
          if (rangeAssignments && !!rangeAssignments.length) {
            if (rangeAssignments.length === 2) {
              // incoming assignment 'left - 1' is another assignment right and incoming assignment 'right + 1'
              // is another assignment left
              const rangeAssignmentFirst = rangeAssignments.find(da => da.right === left - 1);
              const rangeAssignmentSecond = rangeAssignments.find(da => da.left === right + 1);
              let newDisabledAssignment: any = {
                ...rangeAssignmentFirst,
                left: rangeAssignmentFirst.left,
                right: rangeAssignmentSecond.right,
                disable: true,
              };
              delete newDisabledAssignment.id;
              delete newDisabledAssignment.__typename;
              delete newDisabledAssignment.createdAt;
              delete newDisabledAssignment.user;
              // creating new Assignment
              newDisabledAssignment = await this.customApi.CreateDisableAssignment(newDisabledAssignment);
              assignmentsToAdd.push(newDisabledAssignment);

              if (newDisabledAssignment && newDisabledAssignment.id) {
                // remove old assignment because its range includes in above new one
                await this.customApi.DeleteDisableAssignment(
                  { id: rangeAssignmentFirst.id },
                  { assessmentId: { eq: assignment.assessmentId } }
                );
                await this.customApi.DeleteDisableAssignment(
                  { id: rangeAssignmentSecond.id },
                  { assessmentId: { eq: assignment.assessmentId } }
                );
                assignmentsToDelete.push(rangeAssignmentFirst);
                assignmentsToDelete.push(rangeAssignmentSecond);
              }
            } else if (rangeAssignments.length === 1) {
              // it means that user was unassign or reassign first question or last question or question whose siblings also unassign
              const secondQuestion = rangeAssignments.find(ra => ra.left === right + 1);
              if (secondQuestion) {
                // secondQuestion means, disabledAssignments start from secondQuestion and incoming assigned question
                // is the first question of control
                let newDisabledAssignment: any = {
                  ...secondQuestion,
                  left,
                  right: secondQuestion.right,
                  disable: true,
                };
                delete newDisabledAssignment.id;
                delete newDisabledAssignment.__typename;
                delete newDisabledAssignment.createdAt;
                delete newDisabledAssignment.user;
                // creating new Assignment
                newDisabledAssignment = await this.customApi.CreateDisableAssignment(newDisabledAssignment);
                assignmentsToAdd.push(newDisabledAssignment);

                if (newDisabledAssignment && newDisabledAssignment.id) {
                  // remove old assignment because its range includes in above new one
                  await this.customApi.DeleteDisableAssignment(
                    { id: secondQuestion.id },
                    { assessmentId: { eq: assignment.assessmentId } }
                  );
                  assignmentsToDelete.push(secondQuestion);
                }
              } else {
                const secondLastQuestion = rangeAssignments.find(ra => ra.right === left - 1);
                // secondLastQuestion means, assignment end from secondLastQuestion and incoming assigned question
                // is the last question of control
                let newDisabledAssignment: any = {
                  ...secondLastQuestion,
                  left: secondLastQuestion.left,
                  right,
                  disable: true,
                };
                delete newDisabledAssignment.id;
                delete newDisabledAssignment.__typename;
                delete newDisabledAssignment.createdAt;
                delete newDisabledAssignment.user;
                // creating new Assignment
                newDisabledAssignment = await this.customApi.CreateDisableAssignment(newDisabledAssignment);
                assignmentsToAdd.push(newDisabledAssignment);

                if (newDisabledAssignment && newDisabledAssignment.id) {
                  // remove old assignment because its range includes in above new one
                  await this.customApi.DeleteDisableAssignment(
                    { id: secondLastQuestion.id },
                    { assessmentId: { eq: assignment.assessmentId } }
                  );
                  assignmentsToDelete.push(secondLastQuestion);
                }
              }
            }
          } else if (needTocreateDisableAssignment) {
            // ============================================================================
            // Simply create new disabled assignment
            assignmentsToDelete.push({ ...assignment });
            delete assignment.id;
            delete assignment.__typename;
            delete assignment.user;
            let newAssignment = {
              ...assignment,
              disable: true,
            };
            newAssignment = await this.customApi.CreateDisableAssignment(newAssignment);
            assignmentsToAdd.push(newAssignment);
          }

          // ============================================================================
          // Delete assignment of newAssignment if exist.
          let assignments = this.allSubEntities[childId]?.assignments;
          assignments = assignments.filter(
            assign1 => assign1.__typename === 'Assignment' && assign1.userId === assignment.userId
          );
          if (currentFramework) {
            // fetch only current open framework
            assignments = assignments.filter(
              dba => currentFramework.left <= dba.left && currentFramework.right >= dba.right
            );
          }

          // ============================================================================
          // Check assignments of which lies within new assignment left and right then delete it
          // Check assignments of same left and right then delete it
          const existedAssignments = assignments.filter(da => left <= da.left && right >= da.right);
          if (existedAssignments && !!existedAssignments.length) {
            console.log('removing within assignments');
            await Promise.all(
              existedAssignments.map(eda =>
                this.customApi.DeleteAssignment({ id: eda.id }, { assessmentId: { eq: assignment.assessmentId } })
              )
            );
            assignmentsToDelete = assignmentsToDelete.concat(existedAssignments);
            // removing deleted assignments from local array
            assignments = assignments.filter(da => !existedAssignments.find(eda => eda.id === da.id));
          }

          // ============================================================================
          // Check current assignment is within assignment range then remove it by separate db assignment into two records
          // removing current assignment left and right
          const existedAssignmentObj: any = assignments.find(da => da.left <= left && da.right >= right);
          if (existedAssignmentObj) {
            // backup for adding into assignmentsToDelete for frontend
            const existedAssignmentBackup = { ...existedAssignmentObj };

            if (existedAssignmentObj.left === left && existedAssignmentObj.right > right) {
              console.log('removing assignments left same and right not');
              existedAssignmentObj.left = right + 1;
            } else if (existedAssignmentObj.left < left && existedAssignmentObj.right === right) {
              console.log('removing assignments left different but right same');
              existedAssignmentObj.right = left - 1;
            } else {
              console.log('removing assignments middle of range');
              const newAssignmentObj = {
                ...existedAssignmentObj,
                right: left - 1,
              };
              existedAssignmentObj.left = right + 1;

              // do a garbage check here as well , left and right range cannot be same,
              // if they aren't same then create assignment
              if (newAssignmentObj.left !== newAssignmentObj.right) {
                delete newAssignmentObj.__typename;
                delete newAssignmentObj.user;
                delete newAssignmentObj.id;
                const newAssignment = await this.customApi.CreateAssignment(newAssignmentObj);
                assignmentsToAdd.push(newAssignment);
              }
            }
            if (existedAssignmentObj.left === existedAssignmentObj.right) {
              // garbage data
              assignmentsToDelete.push(existedAssignmentObj);
              await this.customApi.DeleteAssignment(
                { id: existedAssignmentObj.id },
                { assessmentId: { eq: assignment.assessmentId } }
              );
            } else {
              if (existedAssignmentBackup) {
                // Remove old existing Assignment before adding updated assignment
                assignmentsToDelete.push({ ...existedAssignmentBackup });
              }
              assignmentsToAdd.push({ ...existedAssignmentObj });
              delete existedAssignmentObj.__typename;
              delete existedAssignmentObj.user;
              await this.customApi.UpdateAssignment(existedAssignmentObj, {
                assessmentId: { eq: assignment.assessmentId },
              });
            }
          }
          break;
        }
      }
    } else {
      this.toastr.error('No sub-entity found');
    }
    // if the user is global participant then we will add the entityIds.
    // a global participant is a user where it's sub-entity or entity is deleted
    // but the user still exists , hence when re-assigning him to another entity,
    // make sure to add the entity id/ids to that user's DB record.
    if (assignment && assignment.userId) {
      // Getting the user from the db.
      user = await this.customApi.GetUser(assignment.userId);
      if (user && user.roleId) {
        role = await this.customApi.GetRole(user.roleId);
        this.assignQuestionToUserSendEmailNotification(
          { id: user.id, name: user.name, domain: user.domain, email: user.email },
          this.subEntity?.rootEntityId,
          this.subEntity?.id,
          isThirdParty
        );
      }
      // if User is participant/sub-entity leader then update the entity ids of the user.
      if (
        user &&
        role &&
        (role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase() ||
          role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()) &&
        this.subEntity?.id &&
        ((user.entityIds && !user.entityIds.includes(this.subEntity.id)) || !user.entityIds)
      ) {
        // if we're adding a new sub-entity where it belongs to a different root
        // then add the root id in entity Id
        let resetEntity = false;
        if (!user.entityIds) {
          user.entityIds = [];
          resetEntity = true;
        }
        // first check if already subEntity is present in user list.
        user.entityIds.push(this.subEntity.id);
        const updatedUser = {
          id: user.id,
          entityId: resetEntity ? this.subEntity.parentId : user.entityIds[0],
          entityIds: user.entityIds,
          // entityLayersIds: user.entityLayerIds && user.entityLayerIds.length ? user.entityLayerIds : null,
        };
        await this.customApi.UpdateUser(updatedUser);
      }

      // now checking if the user is an Entity Leader -
      // we only need to verify it has the rootID
      if (
        user &&
        role &&
        (role?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() ||
          role?.name?.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase()) &&
        this.subEntity?.parentId &&
        (user.entityId !== this.subEntity.parentId || !user.entityId)
      ) {
        // we need to make sure the Entity leader has the permission to the current
        // entity it's being assigned to.
        const updatedUser = {
          id: user.id,
          entityId: this.subEntity.parentId,
          entityIds: [this.subEntity.parentId],
        };
        await this.customApi.UpdateUser(updatedUser);
      }
    }
    this.updateAssignments(assignmentsToAdd, assignmentsToDelete);
  }

  get frameworkIntMapping() {
    return this.selectedFrameworkIntegrationsMapping;
  }

  set frameworkIntMapping(obj) {
    this.selectedFrameworkIntegrationsMapping = JSON.parse(JSON.stringify(obj));
  }

  getDeniedList(): any[] {
    return this.deniedList;
  }

  setDeniedList(list) {
    this.deniedList = list;
  }

  setArtifactList(list) {
    this.artifactList = list;
  }

  getArtifactList(): any[] {
    return this.artifactList;
  }

  /**
   * Function to invoke calcAssessmentScore for whole framework
   * @param assessment_id
   * @param frameworkName
   */
  async invokeCalcAssessmentScore(assessment_id: string, frameworkName: string): Promise<void> {
    const json = {
      source: 'update.scores',
      detail: {
        assessmentId: assessment_id,
        frameworkName,
        questionId: [],
      },
    };
    try {
      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      const params = {
        FunctionName: `calcAssessmentScore-${environment.name}`,
        Payload: JSON.stringify({ ...json }),
      };
      const response = await lambda.invoke(params).promise();
      if (response) {
        this.toastr.success('Framework Reassessed');
      }
    } catch (e) {
      this.toastr.error('Error while reassessing framework');
      console.log('Error - invokeCalcAssessmentScore');
    }
  }

  /**
   * Function to delete tasks for the selected framework
   * @param assessment_id - Assessment ID
   * @param frameworkName - Framework Name for which tasks needs to be deleted
   */
  async deleteTasksOfFramework(assessment_id: string, frameworkName: string): Promise<void> {
    let tasks_list = [];
    try {
      // we must have to use frameworkName inside the query to add filter that will be more efficient
      const filter = {
        frameworkName: {
          eq: frameworkName,
        },
      };
      let nextToken: string = null;
      do {
        const taskActionsRes = await this.customApi.TaskActionsByAssessmentId(assessment_id, null, filter);
        tasks_list = tasks_list.concat(taskActionsRes.items);
        nextToken = taskActionsRes.nextToken;
      } while (nextToken && !tasks_list.length);
      if (tasks_list && tasks_list.length) {
        // delete all the tasks asynchronous
        const promises = [];
        tasks_list.map(item => {
          promises.push(this.customApi.DeleteTaskAction({ id: item.id }, { assessmentId: { eq: assessment_id } }));
        });
        await Promise.all(promises);
      }
    } catch (e) {
      console.log('Error - deleteTasksOfFramework', e);
    }
  }

  /**
   * Function to delete risks if the framework is archived or replaced
   * @param assessmentId
   */
  async deleteRisksOfFramework(assessmentId: string = ''): Promise<void> {
    let risksList = [];
    try {
      const result = await this.customApi.RiskByAssessmentId(assessmentId);
      if (result?.items) {
        risksList = result.items;
        const promises = [];
        risksList.map(item => {
          promises.push(this.customApi.DeleteRisk({ id: item.id }, { assessmentId: { eq: assessmentId } }));
        });
        await Promise.all(promises);
      }
    } catch (e) {
      console.log('Error: deleteRisksOfFramework: ', e);
    }
  }
  /**
   * Helper function to iterate over the frameworkTree to find the infoSec question.
   */
  filterQuestionTree(node: any): boolean {
    if (node.children && node.children.length > 0 && !this.infoSec_exist) {
      return node.children.forEach(child => {
        if (!this.infoSec_exist) {
          this.filterQuestionTree(child);
        }
      });
    } else {
      if (node.questions && node.questions.length > 0 && !this.infoSec_exist) {
        this.infoSec_exist = node.questions.some((question: any) => question?.settings?.isInfoSec === true);
      }
    }
  }

  /**
   * Function will update Timeline. collectionDate value of assessment and subentity
   * @param assessmentStandardFramework - current framework
   * @param assessment_id
   */
  async updateTimeline(
    assessmentStandardFramework: any,
    assessment_id: string,
    isReassessQuestionnaire: boolean = false
  ): Promise<void> {
    try {
      const promises = [];
      // Update Timeline for Assessment Standard Framework
      delete assessmentStandardFramework.timeline.__typename;
      const params: UpdateAssessmentStandardFrameworkInput = {
        id: assessmentStandardFramework.id,
        timeline: assessmentStandardFramework.timeline,
        assessmentId: assessmentStandardFramework.assessmentId,
      };
      promises.push(this.customApi.UpdateAssessmentStandardFramework(params));

      // Update Timeline for Assessment
      const assessment = await this.customApi.GetAssessment(assessment_id);
      const assessmentUpdate: UpdateAssessmentInput = {
        id: assessment.id,
        timeline: assessmentStandardFramework.timeline,
      };
      promises.push(this.customApi.UpdateAssessment(assessmentUpdate));

      // update cached risk framework for this assessment if already cached
      this.updateCachedRiskFramework(assessment_id, assessment?.standardFrameworkList);

      // Update TImeline for sub entity
      if (!isReassessQuestionnaire) {
        const subEntity = await this.customApi.EntityByAssessmentId(assessment_id);
        const initiationDate = UtilsService.getDateInNgbDateStructFormat(
          assessmentStandardFramework.timeline?.initiationDate
        );
        const initiationDateStamp = UtilsService.getDateFromNgbDateStruct(initiationDate).getTime();
        const collectionDate = UtilsService.getDateInNgbDateStructFormat(
          assessmentStandardFramework.timeline?.collectionDate
        );
        const collectionDateStamp = UtilsService.getDateFromNgbDateStruct(collectionDate).getTime();
        const subEntityUpdate: UpdateEntityInput = {
          id: subEntity.items[0].id,
          timeline: {
            initiationDate: initiationDateStamp,
            collectionDate: collectionDateStamp,
          },
          projectDeadline: assessmentStandardFramework.timeline?.collectionDate,
        };
        promises.push(this.customApi.UpdateEntity(subEntityUpdate));
      }
      // Update all.
      await Promise.all(promises);
    } catch (e) {
      console.log('Error - updateTimeline', e);
    }
  }

  async generateKeyForNewAssessment(assessment_id: string, frameworkKey: string): Promise<string> {
    const frameworkName = frameworkKey.split('#')[0];
    const filter = {
      id: {
        beginsWith: assessment_id + '#' + frameworkName,
      },
    };
    try {
      const result = await this.customApi.StandardFrameworksByAssessmentId(assessment_id, null, filter);
      const frameworks = result.items;
      const count = frameworks && frameworks.length ? frameworks.length : 0;
      return `${frameworkName}#${count}`;
    } catch (e) {
      console.log('Error - generateIdForNewAssessment', e);
    }
  }

  async launchNewAssessment(assessmentId: string, managerId: string, requiredStandard, isRisk = false): Promise<any> {
    try {
      const newKey = await this.generateKeyForNewAssessment(assessmentId, requiredStandard.key);
      delete requiredStandard.timeline.__typename;
      if (requiredStandard?.filter?.__typename) {
        delete requiredStandard.filter.__typename;
      }
      const framework: CreateAssessmentStandardFrameworkInput = {
        key: newKey,
        type: requiredStandard.type,
        assessmentId,
        managerId,
        timeline: requiredStandard.timeline,
        id: `${assessmentId}#${newKey}`,
        selectedChapters: requiredStandard.selectedChapters,
        level: requiredStandard.level,
        reassessmentStatus: isRisk ? ReassessmentEnum.IN_PROGRESS : ReassessmentEnum.NOT_INITIATED,
      };
      if (UtilsService.isDefined(requiredStandard.fileName)) {
        framework.fileName = requiredStandard.fileName;
      }
      if (UtilsService.isDefined(requiredStandard.filter)) {
        framework.filter = requiredStandard.filter;
      }
      const createdFramework = await this.customApi.CreateAssessmentStandardFramework(framework);
      return createdFramework;
    } catch (e) {
      console.log('Error - launchNewAssessment', e);
    }
  }

  /**
   * Function to archive the framework
   * @param framework - current framework
   */
  async archiveFramework(framework: any): Promise<any> {
    const params: UpdateAssessmentStandardFrameworkInput = {
      id: framework.id,
      archived: true,
      assessmentId: framework.assessmentId,
    };
    try {
      // Update framework and make it archived
      return await this.customApi.UpdateAssessmentStandardFramework(params);
    } catch (e) {
      console.log('Error - archiveFramework: ', e);
    }
  }

  clearFilters(list) {
    if (list) {
      for (const iList of list) {
        iList.forEach(item => {
          item.selected = false;
        });
      }
    }
  }

  setFillters(filters) {
    this.filters = filters;
  }
  getFilters() {
    return this.filters;
  }
  /**
   * helper function to invoke the queueHandler lambda function for infoSec handlings
   */
  checkForInfoSecCase(target: any = null, type: string, action: string, userId: string, question = null): void {
    /**  first we will check if it is a framework/category/control/question.
    if condition ===> traverse over the tree for checking the infosec case.
      1- for framework== check entityType && children
      2- for category== check !type.entityType && type.children
      3- for control== check !type.entityType && !type.children && type.questions

    else case
      4- handling the scenario for single question
    */

    // disabling auto assignment feature for info sec ( 12 September 2022 )
    // we need to only assign related info sec questions of the same question in other entities.
    const qRange = {
      left: 0,
      right: 0,
    };
    if (
      (target && target.entityType && target.children && target.children.length) ||
      (target && !target.entityType && target.children && target.children.length) ||
      (target && !target.entityType && !target.children && target.questions && target.questions.length)
    ) {
      this.infoSec_exist = false;
      this.filterQuestionTree(target);
      qRange.left = target.leftArr[0];
      qRange.right = target.rightArr[0];
      // getting the ranges for all those questions in the the target
    } else if (question && question.settings && question.settings.isInfoSec) {
      this.infoSec_exist = true;
      qRange.left = question.settings.left;
      qRange.right = question.settings.right;
    }

    // if infosec_exist variable is true then we need to trigger the lambda.
    // also checking that only NIST CSF BB are handled.
    const frameName =
      this.selectedFramework && this.selectedFramework.frameworkName ? this.selectedFramework.frameworkName : null;
    if (this.infoSec_exist && frameName === 'NIST_CSF_BB') {
      const domain = DomainFrameworkService.getDomain();
      // payload for invoking the lambda function.
      const payload = {
        type,
        action,
        userId,
        domain,
        qRange,
      };

      // invoking the lambda function.
      // deliberately not added await on this function
      this.invokeQueueHandlerTrigger(payload);
    }
    this.infoSec_exist = false;
  }
  /**
   * Function to launch fresh new Assessment.
   */

  async launchFreshNewAssessment(
    assessmentId: string,
    managerId: string,
    requiredStandard,
    isRisk = false
  ): Promise<any> {
    try {
      const newKey = await this.generateKeyForNewAssessment(assessmentId, requiredStandard.key);
      delete requiredStandard.timeline.__typename;
      if (requiredStandard?.filter?.__typename) {
        delete requiredStandard.filter.__typename;
      }
      const framework: CreateAssessmentStandardFrameworkInput = {
        key: newKey,
        type: requiredStandard.type,
        reassessmentStatus: ReassessmentEnum.NOT_INITIATED,
        assessmentId,
        managerId,
        timeline: requiredStandard.timeline,
        id: `${assessmentId}#${newKey}`,
        selectedChapters: requiredStandard.selectedChapters,
        level: requiredStandard.level,
        defaultQuestionSettings: requiredStandard.defaultQuestionSettings,
      };
      if (UtilsService.isDefined(requiredStandard.fileName)) {
        framework.fileName = requiredStandard.fileName;
      }
      if (UtilsService.isDefined(requiredStandard.filter)) {
        framework.filter = requiredStandard.filter;
      }
      const createdFramework = await this.customApi.CreateAssessmentStandardFramework(framework);
      return createdFramework;
    } catch (e) {
      console.log('Error - launchNewAssessment', e);
    }
  }

  /**
   * to download beazley report with generateReport lambda function call
   */
  async downloadBeazleyReport(): Promise<void> {
    this.toastr.info(BEAZLEY_TOASTER_MESSAGE, '', { timeOut: 3000 });
    const framework = this.getSelectedFramework();
    // const subEntityName =
    //   this.currentSubentity && this.currentSubentity.scName ? this.currentSubentity.scName.toLowerCase() : '';
    const subEntityName = framework?.belongsTo?.subName || this.subEntity.name;
    const payLoad = {
      type: 'beazley-report',
      body: {
        assessmentId: framework.assessmentId,
        frameworkKey: framework.frameworkName, // Passing framework name to download different frameworks reports
        subEntityName, // As SubEntityName is needed for Beazley OT report
      },
    };
    try {
      const response = await this.clientLambdaService.invokeLambda('generateReport', payLoad);
      const dataObject = JSON.parse(response.Payload);
      const buffer = Buffer.from(dataObject.data);
      const blob = new Blob([buffer], { type: 'text/docx' });
      this.toastr.success('File saved successfully');
      if (UtilsService.extractFrameworkName(framework.frameworkName) === INSURANCE_APP_FRAMEWORKS.BEAZLEY_TEO) {
        saveAs(blob, 'Cyber Application with Tech E&O.docx');
      } else if (
        UtilsService.extractFrameworkName(framework.frameworkName) === INSURANCE_APP_FRAMEWORKS.InsuranceWithOTAndTEO
      ) {
        saveAs(blob, 'Cyber Application +OT +Tech E&O.docx');
      } else if (UtilsService.extractFrameworkName(framework.frameworkName) === INSURANCE_APP_FRAMEWORKS.BEAZLEY) {
        saveAs(blob, 'Cyber Application.docx');
      } else if (
        UtilsService.extractFrameworkName(framework.frameworkName) === INSURANCE_APP_FRAMEWORKS.InsuranceWithOT
      ) {
        saveAs(blob, 'Cyber Application with OT.docx');
      }
    } catch (e) {
      this.toastr.error('Error while downloading report');
    }
  }

  /**
   * to download mid market report with generateReport lambda function call
   */
  async downloadMidmarketReport(): Promise<void> {
    this.toastr.info('Creating report..');
    const framework = this.getSelectedFramework();
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    const payLoad = {
      type: 'midmarket-report',
      body: {
        entityId,
        assessmentId: framework.assessmentId,
        frameworkKey: framework.frameworkName, // Passing framework name to download different frameworks reports
      },
    };
    try {
      const fileName = `${framework.name}.docx`;
      let response = await this.clientLambdaService.invokeLambda('generateReport', payLoad);
      // Check if response has Payload before parsing
      if (!response.Payload) {
        throw new Error('Empty response from Lambda function!');
      }
      // eslint-disable-next-line prefer-const
      response = JSON.parse(response.Payload);
      const presignedUrl = response.presignedUrl;
      const filePath = response.fileName;
      // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-misused-promises
      this.httpClient.get(presignedUrl, { responseType: 'blob' }).subscribe(async (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        // eslint-disable-next-line prefer-const
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastr.success('File saved successfully');
        try {
          await this.fileService.deleteFromS3({ key: filePath });
        } catch (err) {
          this.toastr.error('File Deletion Failed');
          console.log('Error', err);
        }
      });
      // const dataObject = JSON.parse(response.Payload);
      // const buffer = Buffer.from(dataObject.data);
      // const blob = new Blob([buffer], { type: 'text/docx' });
      // saveAs(blob, fileName);
    } catch (e) {
      this.toastr.error('Error while downloading report');
    }
  }

  // Function to download report for PCI frameworks
  async downloadReport(_isPciDssFullReport: boolean = false, _isPciDssFullSuppReport = false): Promise<void> {
    const framework = this.getSelectedFramework();
    const message =
      framework.key === PCI_FRAMEWORKS.PCI_DSS_4_0
        ? PCI_TOASTER_MESSAGE
        : 'Downloading the report. This process may take several minutes...';
    this.toastr.info(message, '', { timeOut: 5000 });
    const subEntityName = this.currentSubentity?.scName?.toLowerCase() || '';
    const payload = {
      type: framework.frameworkName,
      body: {
        assessmentId: framework.assessmentId,
        frameworkName: framework.frameworkName,
        subEntityName,
        isPciDssFullReport: _isPciDssFullReport,
        isPciDssFullSuppReport: _isPciDssFullSuppReport,
      },
    };

    let filename = `${framework.frameworkName}.docx`;

    try {
      const response = await this.clientLambdaService.invokeLambda('createReport', payload, 'RequestResponse', 300000);
      const dataObject = JSON.parse(response.Payload);

      if (_isPciDssFullReport && _isPciDssFullSuppReport) {
        await this.saveMultipleFiles(dataObject, framework.frameworkName);
      } else {
        if (_isPciDssFullSuppReport) {
          filename = `${framework.frameworkName}_Supplemental.docx`;
        }
        await this.saveSingleFile(dataObject, filename, framework.frameworkName);
      }

      this.toastr.success('File saved successfully');
    } catch (error) {
      this.toastr.error('Error while downloading report');
    }
  }

  // Function to download report for CMMC frameworks
  async downloadCMMCReport(): Promise<void> {
    const framework = this.getSelectedFramework();
    const message = 'Downloading the report. This process may take several minutes...';
    this.toastr.info(message, '', { timeOut: 5000 });

    const payload = {
      type: 'CMMC_SSP',
      body: {
        assessmentId: framework.assessmentId,
        frameworkName: framework.frameworkName,
        entityId: this.currentRootId,
      },
    };

    const filename = `${framework.frameworkName}.docx`;

    try {
      const response = await this.clientLambdaService.invokeLambda('createReport', payload, 'RequestResponse', 300000);
      const dataObject = JSON.parse(response.Payload);

      await this.downloadAndDeleteFile(dataObject.presignedUrl, filename, dataObject.fileName);

      this.toastr.success('File saved successfully');
    } catch (error) {
      this.toastr.error('Error while downloading report');
    }
  }

  // Function to download report for CMMC SSP POA&M frameworks
  async downloadCMMCPOAMReport(isDocxReport): Promise<void> {
    const framework = this.getSelectedFramework();
    const message = 'Downloading the report. This process may take several minutes...';
    this.toastr.info(message, '', { timeOut: 5000 });

    const payload = {
      type: 'CMMC_SSP_POAM',
      body: {
        reportType: isDocxReport ? 'DOCX' : 'XLSX',
        assessmentId: framework.assessmentId,
        frameworkName: framework.frameworkName,
        entityId: this.currentRootId,
      },
    };

    const filename = isDocxReport ? 'CMMC_SSP_POA&M.docx' : 'CMMC_SSP_POA&M.xlsx';

    try {
      const response = await this.clientLambdaService.invokeLambda('createReport', payload, 'RequestResponse', 300000);
      const dataObject = JSON.parse(response.Payload);

      await this.downloadAndDeleteFile(dataObject.presignedUrl, filename, dataObject.fileName);

      this.toastr.success('File saved successfully');
    } catch (error) {
      this.toastr.error('Error while downloading report');
    }
  }

  private async saveSingleFile(dataObject: any, filename: string, frameworkName: string): Promise<void> {
    if (frameworkName === 'PCI_DSS_4.0') {
      await this.downloadAndDeleteFile(dataObject.presignedUrl, filename, dataObject.fileName);
    } else {
      this.saveFileFromData(dataObject.data, filename);
    }
  }

  private async saveMultipleFiles(dataObject: any, frameworkName: string): Promise<void> {
    if (frameworkName === 'PCI_DSS_4.0') {
      await this.downloadAndDeleteFile(dataObject.primaryFile, `${frameworkName}.docx`, dataObject.fileName1);
      await this.downloadAndDeleteFile(
        dataObject.supplementaryFile,
        `${frameworkName}_Supplemental.docx`,
        dataObject.fileName2
      );
    } else {
      this.saveFileFromData(dataObject.primaryFile.data, `${frameworkName}.docx`);
      this.saveFileFromData(dataObject.supplementaryFile.data, `${frameworkName}_Supplemental.docx`);
    }
  }

  private async downloadAndDeleteFile(presignedUrl: string, filename: string, filePath: string): Promise<void> {
    try {
      const response = await this.httpClient.get(presignedUrl, { responseType: 'blob' }).toPromise();
      this.createDownloadLink(response, filename);
      await this.fileService.deleteFromS3({ key: filePath });
    } catch (err) {
      this.toastr.error('File Deletion Failed');
      console.log('Error', err);
    }
  }

  private createDownloadLink(blob: Blob, filename: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private saveFileFromData(data: string, filename: string): void {
    const buffer = Buffer.from(data);
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    saveAs(blob, filename);
  }

  /**
   * Function to download Export CSV and All Logs report
   * @param report - type
   */
  async downloadExportCSVReport(report: string): Promise<void> {
    this.toastr.info('Creating report ..');
    const framework = this.getSelectedFramework();
    const subEntityName = framework?.belongsTo?.subName || this.subEntity.name;
    const isHiTrust = framework.frameworkName === this.HITRUST;
    if (isHiTrust) {
      report = 'export-hitrust';
    }
    let namePostFix = 'Report';
    if (report.includes('logs')) {
      namePostFix = 'Logs';
    }
    // console.log(this.filteredQuestionIDs);
    const payload = {
      type: 'export-report',
      body: {
        assessmentId: framework.assessmentId,
        frameworkName: framework.frameworkName,
        type: report,
        isBNB: UtilsService.isBnB,
        subEntityId: this.subEntity?.id,
        filteredQuestionIDs: this.filteredQuestionIDs,
      },
    };
    try {
      const response = await this.clientLambdaService.invokeLambda('generateReport', payload);
      this.filteredQuestionIDs = []; //* clearning the filtered question IDs
      const headers = JSON.parse(response.Payload); // headers data is for normal export reports

      const currDate = new Date();
      const formattedDate = `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`;
      const collectionFileName = `${formattedDate} ${subEntityName} - ${framework.name} ${namePostFix}.csv`;
      //* Collection CSV Report
      if (report === 'export-csv') {
        return generateCollectionCSVFile(headers, collectionFileName);
      }

      if (isHiTrust) {
        const buffer = Buffer.from(headers.data); // buffer is for HiTrust report (as it has a template)
        this.hiTrustReport(buffer);
        return;
      }

      const csvContent = headers.map(info => info.join(',')).join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      this.toastr.success('File saved successfully');
      saveAs(blob, `${formattedDate} ${subEntityName} - ${framework.name} ${namePostFix}.csv`);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * * Function to download Excel report
   */
  async downloadExportExcelReport(): Promise<void> {
    this.toastr.info('Creating Excel report ..');
    const framework = this.getSelectedFramework();
    const subEntityName = framework?.belongsTo?.subName || this.subEntity.name;
    const frameworkAnswers = UtilsService.getFrameworkAnswers(framework.frameworkName);

    const payload = {
      type: 'export-report',
      body: {
        assessmentId: framework.assessmentId,
        frameworkName: framework.frameworkName,
        type: 'export-csv',
        isBNB: UtilsService.isBnB,
        subEntityId: this.subEntity?.id,
        filteredQuestionIDs: this.filteredQuestionIDs,
        frameworkAnswers,
      },
    };
    try {
      const response = await this.clientLambdaService.invokeLambda('generateReport', payload);
      this.filteredQuestionIDs = []; //* clear the filtered question IDs
      const headers = JSON.parse(response.Payload);

      const currDate = new Date();
      const formattedDate = `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`;
      const collectionFileName = `${formattedDate} ${subEntityName} - ${framework.name} Report.xlsx`;

      return await generateCollectionExcelFile(headers, collectionFileName);
    } catch (e) {
      console.error(e);
    }
  }
  /*
   * to download NIS-D framework report with generateReport lambda function call
   */
  async downloadNISDReport(): Promise<void> {
    this.toastr.info('Creating report..');
    const framework = this.getSelectedFramework();
    const payLoad = {
      type: 'nis-d-report',
      body: {
        assessmentId: framework.assessmentId,
      },
    };
    try {
      const response = await this.clientLambdaService.invokeLambda('generateReport', payLoad);
      const dataObject = JSON.parse(response.Payload);
      const buffer = Buffer.from(dataObject.data);
      const blob = new Blob([buffer], { type: 'text/xlsx' });
      this.toastr.success('File saved successfully');
      saveAs(blob, 'NIS-DReport.xlsx');
    } catch (e) {
      this.toastr.error('Error while downloading report');
    }
  }

  /**
   * Function to download HiTrust framework report
   * @param buffer - content for file
   */
  hiTrustReport(buffer: any): void {
    try {
      const currDate = moment(new Date());
      const fileName = 'MyCSF Self Assessment ' + currDate.format('MMMM') + ' ' + currDate.format('YYYY') + '.xlsx';
      const blob = new Blob([buffer], { type: 'text/xlsx' });
      saveAs(blob, fileName);
    } catch (e) {
      console.log('Error - hiTrustReport: ', e);
    }
  }

  /**
   * helper function to invoke the lambda of queuehandler for assignments handling
   */
  async invokeQueueHandlerTrigger(payload): Promise<any> {
    const credentials = await Auth.currentCredentials();
    // add region and credentials for access permission to execute lambda
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: environment.region,
    });
    // Information which will be send as parameters like event.json in lambda
    const params = {
      FunctionName: `queueHandler-${environment.name}`,
      Payload: JSON.stringify(payload),
    };

    // Invoking lambda
    try {
      const result = await lambda.invoke(params).promise();
      console.log(result);
      return result;
    } catch (e) {
      console.log(' Error : ', e);
      return null;
    }
  }
  /**
   * checking if current frameworks have answers, set the obj value true/false
   * @param assessmentId framework assessment id
   * @param frameworkName framework name
   * @param isShowAllFrameworks boolean value of if all/grouped frameworks or not
   * @param subId sub entity id
   */
  setIfFrameworkHasAnswers(
    assessmentId: string,
    frameworkName: string,
    isShowAllFrameworks: boolean,
    subId: string
  ): void {
    let entityAnswers;
    if (isShowAllFrameworks) {
      entityAnswers = this.allSubEntities[subId].answeredQuestions;
    } else {
      entityAnswers = this.answeredQuestions;
    }
    const index = entityAnswers?.findIndex(
      ans => ans?.assessmentId === assessmentId && ans?.frameworkName?.toLowerCase() === frameworkName?.toLowerCase()
    );
    const key = `${assessmentId}#${frameworkName}`;
    this.allFrameworksAnswersObj[key] = index >= 0;
  }

  getAllFrameworksAnswersObj(): any {
    return this.allFrameworksAnswersObj;
  }

  getVendorDetails(): any {
    return this.vendorDetails;
  }

  setVendorDetails(vendorDetails: any): void {
    this.vendorDetails = vendorDetails;
  }

  ngOnDestroy(): void {
    console.log('destroyed');
    this.subscriptions.forEach(listener => listener.unsubscribe());
  }

  /**
   ***********************   Vendor Phase 2  ************************
   * Vendor Phase 2 New Functionality for collection. There will be some new and some Exiting will be
   * re-written because we don't want to change the Existing.( Already very complex )
   */

  /**
   * Check Either Current User is Vendor and POC
   * @returns true/false
   */
  isVendorPOC(): boolean {
    return (
      this.isVendor && this.vendorDetails?.primaryPoc?.email?.toLowerCase() === this.currentUser?.email.toLowerCase()
    );
  }

  /**
   * In case of vendor POC only return Vendor and Participant Roles.
   * Vendor POC only access these two types of users
   * @returns roles list
   */
  async getVendorRoles(): Promise<GetRoleQuery[]> {
    const defaultRoles = await this.userService.getDefaultRoles();
    return defaultRoles.length
      ? defaultRoles.filter(
          role =>
            role.name.toLowerCase() === RoleEnum.VENDOR.toLowerCase() ||
            role.name.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase()
        )
      : [];
  }
  async getParticipantRole(): Promise<GetRoleQuery[]> {
    const defaultRoles = await this.userService.getDefaultRoles();
    return defaultRoles.length
      ? defaultRoles.filter(role => role.name.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase())
      : [];
  }

  /**
   * This Function Just Map Vendor Roles to RoleIds
   * @returns list of roleIds
   */
  async getVendorRoleIds(): Promise<string[]> {
    const vendorRoles = await this.getVendorRoles();
    return vendorRoles?.length ? vendorRoles.map(role => role.id) : [];
  }

  getFrameworkScoreObject(item: any): any {
    if (this.isParticipant) {
      item = {};
    }
    return {
      total: item.total ? item.total : 0,
      assignmentCount: item.assignmentCount ? item.assignmentCount : 0,
      collection: item.collection ? item.collection : 0,
      completedQuestions: item.completedQuestions ? item.completedQuestions : 0,
      criticalGaps: item.criticalGaps ? item.criticalGaps : 0,
      maturity: item.maturity ? item.maturity : 0,
      notApplicable: item.notApplicable ? item.notApplicable : 0,
      remediation: item.remediation ? item.remediation : 0,
      target: item.target ? item.target : 0,
      totalGaps: item.totalGaps ? item.totalGaps : 0,
      totalQuestions: item.totalQuestions ? item.totalQuestions : 0,
    };
  }
  removeHashFromReassessed(card: string): string {
    const idx = card.indexOf('#');
    if (idx === -1) {
      return card;
    }
    this.withHash = card.slice(idx, card.length);
    return card.slice(0, idx);
  }

  deleteDraftComment(id: string): void {
    delete this.draftMsgCheck[id];
  }

  isCommentDrafted(id: string): string {
    return this.draftMsgCheck[id] ? this.draftMsgCheck[id] : '';
  }

  saveDraft(answerComments): void {
    this.commentAnswerMapper = answerComments;
  }

  saveMandatoryMessageDraft(mandatoryMessage): void {
    this.mandatoryMessageMapper = mandatoryMessage;
  }

  saveCommentDraft(id: string, comment: string): void {
    this.draftMsgCheck[id] = comment;
  }

  /*
   *          CyGov Optimization Code
   */

  async loadQuestionSetDataFromLambda(
    questions: any[],
    assessmentId: string,
    frameworkKey: string,
    filters: any = null
  ): Promise<void> {
    // Skip those questions ids whose data already cached
    if (!filters) {
      questions = questions.filter(q => this.questionSetCollectionCache[q.QID] === undefined);
    }
    // check list of questions ids should not empty to invoke the lambda
    if (questions?.length) {
      // Invoke the lambda input ( questionsIds )
      const payLoad = {
        input: {
          questions,
          assessmentId,
          frameworkKey,
          filters,
          participantUserId: this.userService.isParticipant() ? this.userService?.currentUser?.id : null,
        },
      };
      const lambdaResponse = await this.clientLambdaService.invokeLambda(
        'getQuestionsDataSet',
        payLoad,
        'RequestResponse'
      );
      const questionData = JSON.parse(lambdaResponse.Payload).questionsData;
      this.addNewQuestionSetIntoCollectionCache(questionData);
      this.setFrameworkQuestionIds(frameworkKey, null, questionData);
    }
  }

  /**
   * Add New QuestionsSet Data into the question set collection cache
   * @param questionSet
   */
  private addNewQuestionSetIntoCollectionCache(questionSet: QuestionData[]): void {
    questionSet?.forEach(questionData => {
      // Add one by one Question data inside the question  Collection cache
      this.questionSetCollectionCache[questionData.id] = questionData;
      this.questionSetCollectionCache[questionData.id].isDataFetched = true;
    });
  }

  /** ********************************************************************************************************
   * Here will be the individual functionality to get the Data (Comments , Answers , Logs , Tags , Settings) *
   * According to new Architecture for Performance Optimization          ( From Cache)                       *
   * *********************************************************************************************************
   */

  /**
   * get Comments from the QuestionSet Collection Cache
   * @param questionId
   * @returns
   */
  getCommentsByQuestionId(questionId: string): GetCommentQuery[] {
    return this.questionSetCollectionCache[questionId]?.comments
      ? this.questionSetCollectionCache[questionId].comments
      : [];
  }
  /**
   * Updated the Existing functionality with new one to update the cache
   * whenever a new comment added or existing comment is updated
   * @param comment
   */
  updateCommentState = (comment: GetCommentQuery): void => {
    const questionData = this.questionSetCollectionCache[comment?.questionId];
    // if the data is loaded inside the cache
    if (questionData) {
      questionData.comments = questionData.comments ? questionData.comments : [];
      const existingCommentIndex = questionData?.comments.findIndex(c => c.id === comment.id);
      if (existingCommentIndex > -1) {
        // Update the Existing comment
        this.questionSetCollectionCache[comment?.questionId].comments[existingCommentIndex] = comment;
      } else {
        // Add comment at the top of the list to show on top of All comments
        this.questionSetCollectionCache[comment?.questionId].comments.unshift(comment);
      }
    }
  };

  updateTagsState = (tags, isDeleted: boolean, questionId): void => {
    if (!isDeleted) {
      // Adding the tags at questions level => only for cache front end.
      tags.forEach(tag => {
        this.questionSetCollectionCache[questionId].tags.push(tag);
        // Adding the tag at assessment level => only for the cache,
        const isAlreadyExist = this.allTagsEntityId[tag.entityId].find(
          t => t?.name?.toLowerCase() === tag?.name?.toLowerCase() && t?.questionRefId === tag?.questionRefId
        );
        if (!isAlreadyExist) {
          this.allTagsEntityId[tag.entityId].push(tag);
        }
      });
    }

    if (isDeleted) {
      // const tempTags = [...this.questionSetCollectionCache[questionId].tags];
      this.questionSetCollectionCache[questionId]?.tags?.forEach(savedTag => {
        tags.forEach(deletedTag => {
          let tagIndex = this.questionSetCollectionCache[questionId]?.tags?.findIndex(
            () => deletedTag.id === savedTag.id
          );
          if (tagIndex > -1) {
            this.questionSetCollectionCache[questionId]?.tags?.splice(tagIndex, 1);
          }
          tagIndex = this.allTagsEntityId[deletedTag?.entityId]?.findIndex(() => deletedTag.id === savedTag.id);
          if (tagIndex > -1) {
            this.allTagsEntityId[deletedTag?.entityId]?.splice(tagIndex, 1);
          }
        });
      });
    }
  };
  /**
   * Updated the Existing functionality with new one to update the cache
   * whenever a new log added or existing logs is updated
   * @param log
   */
  updateLogState = (log: GetLogsQuery): void => {
    const arr = log.targetId.split('#');
    const questionId = arr[1];

    let alreadyExists = false;
    if (this.questionSetCollectionCache[questionId]) {
      for (const questionLog of this.questionSetCollectionCache[questionId].questionLogs) {
        if (questionLog.id === log.id) {
          alreadyExists = true;
        }
      }
      if (!alreadyExists) {
        this.questionSetCollectionCache[questionId].questionLogs.unshift(log);
      }
    }
  };
  /**
   * Delete Comment from the QuestionSet Collection cache
   * @param comment
   */
  deleteComment(comment: GetCommentQuery): void {
    const questionData = this.questionSetCollectionCache[comment?.questionId];
    if (questionData?.comments?.length) {
      questionData.comments.forEach((currComment, index) => {
        // remove comment from local state
        if (currComment.id === comment.id) {
          questionData.comments.splice(index, 1);
          if (this.questionSetCollectionCache[comment?.questionId].comments) {
            this.questionSetCollectionCache[comment?.questionId].comments = questionData.comments;
          }
        }
      });
    }
  }

  getAnswersByQuestionId(questionId: string): GetAnswerQuery[] {
    return this.questionSetCollectionCache[questionId]?.answers
      ? this.questionSetCollectionCache[questionId].answers
      : [];
  }

  getQuestionDataByQuestionId(questionId: string): GetQuestionDataQuery {
    return this.questionSetCollectionCache[questionId]?.questionData
      ? this.questionSetCollectionCache[questionId].questionData
      : null;
  }

  addNewQuestionDataInCache(questionId, questionData: GetQuestionDataQuery) {
    if (this.questionSetCollectionCache[questionId] === undefined) {
      this.questionSetCollectionCache[questionId].questionData = new QuestionData(questionId);
    }
    this.questionSetCollectionCache[questionId].questionData = questionData;
  }

  updateQuestionDataInCache(questionId, questionData: any) {
    this.questionSetCollectionCache[questionId].questionData = {
      ...this.questionSetCollectionCache[questionId].questionData,
      ...questionData,
    };
  }

  addNewAnswerInCache(questionId: string, answer: any, fromRemediation: boolean = false): void {
    if (this.questionSetCollectionCache[questionId] === undefined) {
      this.questionSetCollectionCache[questionId] = new QuestionData(questionId);
    }

    if (fromRemediation) {
      this.questionSetCollectionCache[questionId].answers = [...answer];

      this.isRemediationAnswerChanged.next(true);
    } else if (this.questionSetCollectionCache[questionId]?.answers?.length) {
      this.questionSetCollectionCache[questionId].answers.push(answer);
    } else {
      this.questionSetCollectionCache[questionId].answers = [answer];
    }
  }

  /**
   * * Functions updates Answers cache fro subscriptions
   * @param questionId updated question ID
   * @param answer updated answer object
   * @param isDeselected answer deselected flag
   */
  updateAnswerCache(questionId: string, answer: any, isDeselected: boolean = false) {
    if (!this.questionSetCollectionCache[questionId] && !isDeselected) {
      this.questionSetCollectionCache[questionId] = new QuestionData(questionId);
    }

    const userID = answer?.userId;
    const cachedQuestion = this.questionSetCollectionCache[questionId];

    if (!cachedQuestion) {
      return;
    } //* No need to proceed if there's no cache for the question

    const answers = cachedQuestion.answers || [];

    const answerIndex = answers.findIndex(userAnswer => userAnswer.userId === userID);

    //* Remove the old answer if it exists
    if (answerIndex !== -1) {
      answers.splice(answerIndex, 1);
    }

    if (!isDeselected) {
      //* Add the new answer at the 0 index if not deselected
      answers.unshift(answer);
    }

    //* Ensure the answers array is updated (even if deselected to clear it)
    cachedQuestion.answers = answers;
  }

  getSettingsByQuestionId(questionId: string): GetQuestionSettingsQuery[] {
    return this.questionSetCollectionCache[questionId]?.settings
      ? this.questionSetCollectionCache[questionId].settings
      : [];
  }

  getLogsByQuestionId(questionId: string): GetQuestionSettingsQuery[] {
    return this.questionSetCollectionCache[questionId]?.questionLogs
      ? this.questionSetCollectionCache[questionId].questionLogs
      : [];
  }

  async loadDataForQuestionSet(set: IQuestionIdSet[], frameworkKey: string): Promise<void> {
    const assessmentId = this.currFrameworkAssessmentId;
    const isGivenSetInProcess = this.isQuestionSetDataLoaded(set);
    if (set.length && isGivenSetInProcess === undefined) {
      const setKey = set[0].QID + '$' + set[set.length - 1].QID;
      // check either the status of current set.
      if (this.questionSetsStatus[setKey] === undefined) {
        // Set is New load the data from Lambda function
        this.questionSetsStatus[setKey] = false; // Data is loading
        // const questionIds = set.map(question => question.QID);
        await this.loadQuestionSetDataFromLambda(set, assessmentId, frameworkKey);
        this.questionSetsStatus[setKey] = true;
      }
    }
  }

  isQuestionSetDataLoaded(set: IQuestionIdSet[]): boolean {
    // check Data of current data set is loaded or not
    const setKey = set && set.length && set[0].QID + '$' + set[set.length - 1].QID;
    return this.questionSetsStatus[setKey];
  }

  async resolveBackGroundPromises(): Promise<void> {
    try {
      const batchSize = 6;
      for (let i = 0; i < this.backGroundPromises.length; i += batchSize) {
        const batchPromises = this.backGroundPromises.slice(i, i + batchSize);
        await Promise.all(batchPromises);
      }
      this.backGroundPromises = [];
    } catch (err) {
      console.log('Failed to resolve promises in background');
    }
  }

  /**
   * Load Data in BackGround Job for Given Question Set.
   * @param set
   */
  addNewBackGroundPromises(set: IQuestionIdSet[], frameworkKey: string): void {
    this.backGroundPromises.push(this.loadDataForQuestionSet(set, frameworkKey));
  }

  /**
   * Helper function to add the questionIds against the framework.
   * @params frameworkKey => current framework against which the data is populated. used as key.
   * @params questionIdsSet => object of arrays contains the list of the current questionIds.
   * @returns nothing => just populate the data of the global Object.
   */
  setFrameworkQuestionIds(frameworkKey: string, questionIdsSet: any, questionsData: any = []): void {
    // 1- Checking if the current framework exist or not.
    // Populating the ids.
    if (!this.frameworkQuestionIdsCache[frameworkKey]) {
      this.frameworkQuestionIdsCache[frameworkKey] = questionIdsSet?.length ? questionIdsSet.flat() : [];
    }

    // 2- We have to update the object which contains the data.
    if (questionsData?.length) {
      // 2.1 If it already contains the framework then
      this.frameworkQuestionIdsCache[frameworkKey].forEach(question => {
        const foundQuestion = questionsData?.find(ques => question.QID === ques.id);
        if (foundQuestion) {
          question.isDataFetched = true;
        }
      });
    }
  }

  /**
   * Helper function to see if the cache framework has all the questions data.
   * @returns true/false => tells if the cache framework has all the answers.
   */
  checkIfFrameworkHasData(frameworkKey: string): boolean {
    let isAllQuestionsFetched = true;
    if (this.frameworkQuestionIdsCache[frameworkKey]) {
      isAllQuestionsFetched = this.frameworkQuestionIdsCache[frameworkKey]?.every(question => question?.isDataFetched);
    }
    return isAllQuestionsFetched;
  }

  mapFiltersObject(dataArray): any {
    const finalObj = {};
    for (const data of dataArray) {
      const questionId = data?.questionId ? data.questionId : data.targetId.split('#')[1];
      // if the global object doesnt have the data of the current question
      if (finalObj[questionId]) {
        finalObj[questionId].push(data);
      } else {
        finalObj[questionId] = [data];
        // updating the questions array.
      }
    }
    return finalObj;
  }

  /**
   *@param filters => object of filters contains different type of filters.
   *@params frameworkKey => current frameworkKey against which filter is applied.
   *@params assessmentId => current assessment Id against which filter is applied.
   *@returns filterObject => An object of filters which will be applied to fetch the specific data.
   */
  async getFilteredData(filters: any, frameworkKey: string, assessmentId: string): Promise<any> {
    let filterObject = {
      or: [],
    };
    let isUnansweredFilterSelected = false;
    let isAllAnswersSelected = false;
    const result = {
      versions: {},
      questionSettings: {},
      answers: {},
      questionsData: {},
    };

    // Handling the case of answers filter.
    if (filters && filters.selectedAnswerList && filters.selectedAnswerList.length) {
      // 1- If selected all filter. => we don't need any filters here and we will not apply any calls. (Normal Flow)
      isAllAnswersSelected = filters.selectedAnswerList.find(fil => fil === FiltersEnum.ALL);
      isUnansweredFilterSelected = filters.selectedAnswerList.find(filt => filt === FiltersEnum.UNANSWERED);
      const frameworkAnswers = UtilsService.getFrameworkAnswers(this.selectedFramework.frameworkName);
      if (!isAllAnswersSelected) {
        // As BNB answer mapping is different so need to send different filters for the given answer
        if (UtilsService.isBnB) {
          filters.selectedAnswerList.forEach(answer => {
            const values = UtilsService.bnbAnswersRangeForEnum(answer);
            const orArray = [];
            values.forEach(value => {
              orArray.push({
                answer: {
                  eq: value,
                },
              });
            });
            filterObject.or.push({
              or: orArray,
            });
          });
        } else {
          filters.selectedAnswerList.forEach(answer => {
            filterObject.or.push({
              answer: {
                eq: UtilsService.getScoreOrKey(answer.toUpperCase(), frameworkAnswers),
              },
            });
          });
        }
        filterObject = isUnansweredFilterSelected ? null : filterObject;
      }
    }
    // Handling the case of status filter.
    if (filters && filters.selectedStatusList && filters.selectedStatusList.length) {
      const tempArray = filters.selectedStatusList.filter(status => status !== FiltersEnum.ASSIGNED_TO_ME);
      if (tempArray?.length) {
        this.questionsData = this.questionsData.length
          ? this.questionsData
          : await this.customApi.QuestionsByAssessmentId(assessmentId);
        result.questionsData = (this.questionsData?.items || []).reduce((acc, questionData) => {
          acc[questionData.id] = questionData;
          return acc;
        }, {});
      }
    }
    // Handling the case of others filter
    if (filters?.selectedOthers?.length) {
      for (const otherFilter of filters.selectedOthers) {
        if (otherFilter !== 'all') {
          switch (otherFilter) {
            case FiltersEnum.VERSIONS:
              {
                const versions = await this.customApi.VersionsByAssessmentId(assessmentId);
                result.versions = versions?.items?.length ? this.mapFiltersObject(versions.items) : {};
              }
              break;
            case FiltersEnum.ARTIFACTS:
              filterObject = null;
              break;
            case FiltersEnum.INTEGRATION_SOURCES:
              {
                const questionSettings = await this.getQuestionSettingsbyAssessment(assessmentId);
                result.questionSettings = questionSettings?.length ? this.mapFiltersObject(questionSettings) : {};
              }
              break;
          }
        }
      }
    }

    // if all filter for answers is selected then we don't need to get the answers.
    if (!filterObject || filterObject?.or?.length) {
      let answers = await this.getAnswersData(assessmentId, frameworkKey, filterObject);
      // If assigned users filter is applied, we have to filter the answers accordingly.
      if (filters && filters.selectedUsersList?.length) {
        answers = answers.filter(answer => filters.selectedUsersList.includes(answer.userId));
      }
      result.answers = this.mapFiltersObject(answers);
    }
    return result;
  }

  /**
   *
   * @param assessmentId => assessmentId of the current questions set.
   * @param frameworkKey => frameworkName of the current questions set.
   * @param filterObj => object which will be passed into the query for getting the answers.
   * @returns the list of the answers.
   */

  getAnswersData = async (assessmentId, frameworkKey, filterObj) => {
    try {
      const frameworkName = {
        eq: frameworkKey,
      };
      let nextToken;
      let answers: any = [];
      do {
        const answersResult = await this.customApi.AnswersByAssessmentIdAndFrameworkKey(
          assessmentId,
          frameworkName,
          null,
          filterObj,
          100,
          nextToken
        );

        nextToken = answersResult && answersResult.nextToken ? answersResult.nextToken : null;
        answers = answers.concat(
          answersResult && answersResult.items && answersResult.items.length ? answersResult.items : []
        );
      } while (nextToken);
      return answers;
    } catch (e) {
      console.log('Cannot fetch the answers ==> getAnswersData ==> ', e);
      return [];
    }
  };

  /**
   *
   * @param assessmentId => assessmentId of the current questions set.
   * @returns the list of the versions.
   */
  getVersionsByAssessmentId = async assessmentId => {
    try {
      const versions = await this.customApi.VersionsByAssessmentId(assessmentId);
      return versions?.items?.length ? versions.items : [];
    } catch (e) {
      console.log('Cannot fetch the versions ==> getAnswersData ==> ', e);
      return [];
    }
  };

  async getRelationsMeta(riskFramework: string): Promise<any> {
    try {
      if (!UtilsService.isDefined(this.smartMapRelMeta[riskFramework])) {
        const url = await this.fileService.getFileUrlFromBucket(
          `public/RELATIONS/RELATIONS_META/${riskFramework}_METADATA.json`,
          {
            contentType: 'application/json',
            level: 'public',
          }
        );
        const metaData = await this.fileService.getFile(url);
        this.smartMapRelMeta[riskFramework] = JSON.parse(metaData);
      }
      return this.smartMapRelMeta[riskFramework];
    } catch (e) {
      return {};
    }
  }
  async getFrameworkScore(riskFramework: string): Promise<any> {
    try {
      if (!UtilsService.isDefined(this.smartMapPercentage[riskFramework])) {
        const url = await this.fileService.getFileUrlFromBucket(
          `public/RELATIONS/POTENTIAL_MAPPING/${riskFramework}_POTENTIAL.json`,
          {
            contentType: 'application/json',
            level: 'public',
          }
        );
        const metaData = await this.fileService.getFile(url);
        this.smartMapPercentage[riskFramework] = JSON.parse(metaData);
      }
      return this.smartMapPercentage[riskFramework];
    } catch (e) {
      console.log('Cannot fetch the Scores metadata ==> getFrameworkScore ==> ', e);
      return {};
    }
  }

  /**
   * Function to check if this control/question has artifact that is a part of Report or not
   * @param frameworkName - Current Framework
   * @param controlName - Current question
   * @returns true of matches with Report Controls
   */
  isFileControl(frameworkName: string, controlName: string): boolean {
    return REPORT_CONTROLS[frameworkName] && REPORT_CONTROLS[frameworkName].includes(controlName);
  }

  async getCurrentAssessmentRisk(assessmentId: string): Promise<string> {
    try {
      if (!UtilsService.isDefined(this.standardFrameWorks[assessmentId])) {
        const frameworks = await this.customApi.GetAssessment(assessmentId);

        const riskFramework = frameworks?.standardFrameworkList?.items.find(
          fwrk => fwrk.type === 'RISK_FRAMEWORK' && !fwrk.not_added && !fwrk.archived
        )?.key;
        if (riskFramework) {
          this.standardFrameWorks[assessmentId] = riskFramework;
        }
      }
      return this.standardFrameWorks[assessmentId];
    } catch (e) {
      console.log('Cannot get current assessment risk ==> getCurrentAssessmentRisk ==> ', e);
    }
  }

  async getCurrentQuestionActiveMapping(uniqueID: string): Promise<any> {
    try {
      let nextToken: string = null;
      let activeMappingList = [];
      do {
        const activeMappingResponse = await this.customApi.MetadataBySmartMapId(uniqueID);
        activeMappingList = activeMappingList.concat(activeMappingResponse.items);
        nextToken = activeMappingResponse.nextToken;
      } while (nextToken && !activeMappingList.length);

      return activeMappingList;
    } catch (e) {
      console.log('Cannot get current current question active mapping ==> getCurrentQuestionActiveMapping ==> ', e);
    }
  }

  updateCachedRiskFramework(assessmentId: string, standardFrameworkList: any): void {
    if (!UtilsService.isDefined(this.standardFrameWorks[assessmentId])) {
      let riskFramework = standardFrameworkList?.items.find(
        fwrk => fwrk.type === 'RISK_FRAMEWORK' && !fwrk.not_added && !fwrk.archived
      );
      if (riskFramework) {
        riskFramework = JSON.parse(JSON.stringify(riskFramework));
        riskFramework.key = riskFramework.key.includes('#') ? riskFramework.key.split('#')[0] : riskFramework.key;
        this.standardFrameWorks[assessmentId] = riskFramework;
      }
    }
  }

  getRootEntityIntegrationStatus(): Observable<any> {
    return from(this.entityService.getEntity(this.currentRootId));
  }

  emptyTagsCache() {
    this.cachedTagsList = [];
  }
  async getTagsListFromDb(filter = null): Promise<any[]> {
    try {
      let tagList = [];
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      if (!entityId) {
        return [];
      }
      if (!this.cachedTagsList?.length) {
        const limit = 300;
        let nextToken;
        do {
          const { items: tags, nextToken: rnextToken }: TagsByEntityIdQuery = await this.customApi.TagsByEntityId(
            entityId,
            null,
            filter,
            limit,
            nextToken
          );
          nextToken = rnextToken;
          tagList = tagList.concat(tags);
        } while (nextToken);
        this.cachedTagsList = tagList.length ? tagList : [];
      }
      return this.cachedTagsList;
    } catch (e) {
      console.log('Error - getTagsListFromDb: ', e);
    }
  }

  async addNewTagsinDb(
    newTagsList: any,
    targetId?: string,
    assessmentFrameworkKey?: string,
    referenceId?: string
  ): Promise<any> {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      if (entityId) {
        const promise = [];
        const newlyCreatedTags = [];
        if (newTagsList?.length && !UtilsService.isEmpty(targetId)) {
          newTagsList.map(tag => {
            const tagObj = {
              name: tag && tag?.title ? tag.title : tag,
              entityId,
              domain: this.domain.toLowerCase(),
              targetId,
              assessmentFrameworkKey,
              referenceId,
              questionRefId: referenceId,
            };

            newlyCreatedTags.push(tagObj);
            promise.push(tagObj);
          });
        } else {
          newTagsList?.map(tag => {
            const tagObj = {
              name: tag && tag?.title ? tag.title : tag,
              entityId,
              domain: this.domain.toLowerCase(),
              targetId: '_Global',
              assessmentFrameworkKey: '_Global',
              referenceId: '_Global',
            };

            newlyCreatedTags.push(tagObj);
            promise.push(tagObj);
          });
        }
        this.cachedTagsList.push(...newlyCreatedTags);
        const batchSize = 15;
        let responses = [];
        for (let i = 0; i < promise.length; i += batchSize) {
          const batch = promise.slice(i, i + batchSize);
          const batchPromises = batch.map(tag => this.customApi.CreateTags(tag));

          responses = [...responses, ...(await Promise.all(batchPromises))];
        }
        return responses;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async lockAssessment(lock: any): Promise<void> {
    try {
      this.toastr.info(`${lock.payloadObj.isLocked ? 'Locking' : 'UnLocking'} Is In Progress...`);
      await this.customApi.UpdateAssessmentStandardFramework(lock.payloadObj);
      await this.customApi.CreateLogs({
        message: lock.payloadObj.isLocked
          ? `${this.currentUser.name} locked this assessment.`
          : `${this.currentUser.name} unlocked this assessment.`,
        assessmentId: lock.assessmentID,
        type: lock.payloadObj.isLocked ? LogsTypeEnum.ASSESSMENT_LOCKED : LogsTypeEnum.ASSESSMENT_UNLOCK,
        userID: this.currentUser.id,
        targetId: lock.payloadObj.id,
      });
      this.toastr.success(`Assessment ${lock.payloadObj.isLocked ? 'Locked' : 'UnLocked'} Successfully.`);
    } catch (err) {
      this.toastr.error(`Error While ${lock.payloadObj.isLocked ? 'Locking' : 'UnLocking'} the Assessment`);
      console.log('Error while updating assessment');
    }
  }
  async assessmentEmailNotification(type: string) {
    if (this.entityService.brokersEmail.length) {
      const json = {
        type,
        assignedUsers: this.entityService.brokersEmail,
        clientName: this.currentUser.name,
      };
      try {
        await this.clientLambdaService.invokeLambda('sendNotification', json);
        this.toastr.success('Email Sent to Broker Successfully!!');
      } catch (error) {
        this.toastr.error('Issue While Sending Notification Email');
        console.log('Issue While Sending Notification Email ' + JSON.stringify(error));
      }
    }
  }

  async unlockRequestEmailNotification(type: string, lockedBy: any, comment: string) {
    if (lockedBy) {
      const json = {
        type,
        assignedUsers: [{ email: lockedBy.email }],
        clientName: this.currentUser.name,
        lockByUser: lockedBy.name,
        userComment: comment,
      };
      try {
        await this.clientLambdaService.invokeLambda('sendNotification', json);
        this.toastr.success('Email Sent to Broker Successfully!!');
      } catch (error) {
        this.toastr.error('Issue While Sending Notification Email');
        console.log('Issue While Sending Notification Email ' + JSON.stringify(error));
      }
    } else {
      this.toastr.error('Please Firstly Lock the Assessment.');
    }
  }

  //* BB Reassessment Confirm All Lambda invocation
  async reassessConfirmAll(userName: string) {
    try {
      const framework = this.getSelectedFramework();
      const assessmentId = framework.assessmentId;
      const frameworkName = framework.frameworkName;
      const payload = {
        type: REASSESSMENT_CONFIRM_All.EVENT_TYPE_CONFIRM_ALL,
        body: {
          assessmentId,
          frameworkName,
          userName,
        },
      };
      this.toastr.info(REASSESSMENT_CONFIRM_All.INFO_MESSAGE, '', { timeOut: 5000 });
      const response = await this.clientLambdaService.invokeLambda(REASSESSMENT_CONFIRM_All.LAMBDA_NAME, payload);
      if (response?.StatusCode === 200) {
        this.resetQuestionsCache();
        this.onReassessAllSubject.next(true);
        this.toastr.success('Reassessed successfully!');
      } else {
        throw new Error('Confirm All failed.');
      }
    } catch (error) {
      this.toastr.error(error.message || 'An issue occurred while confirming all.');
      console.error(error);
    }
  }

  //* Resets Question Cache
  resetQuestionsCache() {
    this.questionSetCollectionCache = new Map<string, QuestionData>();
    this.questionSetsStatus = new Map<string, boolean>();
  }

  async getListOfStandardFrameworkListWithNextToken(assessmentId: string, providedNextToken: string): Promise<any> {
    try {
      let nextToken = providedNextToken;
      let result = [];
      do {
        const standardFrameworkResponse = await this.customApi.StandardFrameworksByAssessmentId(
          assessmentId,
          null,
          null,
          1000,
          nextToken
        );

        result = [...result, ...standardFrameworkResponse.items];
        nextToken = standardFrameworkResponse.nextToken;
      } while (nextToken);

      return result;
    } catch (err) {
      console.log('Err:: Failed to get assessment standard framework list with next token');
    }
  }
  deleteFetchAssignmentPromise(assignmentId: string): void {
    // eslint-disable-next-line
    if (this.fetchAssignmentPromises[assignmentId]) {
      delete this.fetchAssignmentPromises[assignmentId];
    }
  }
  updateCommentAnswerMapper(answerComment): void {
    if (this.commentAnswerMapper[answerComment.questionId]) {
      this.commentAnswerMapper[answerComment.questionId] = answerComment;
    }
  }
  loadSubscriptions() {
    this.subscriptions = [
      this.onCreateLogsListener(),
      this.onCreateVersionsListener(),
      this.onCreateTagsListener(),
      this.onDeleteTagsListener(),
      this.onUpdateQuestionnaireCompletionListener(),
      this.onUpdateFrameworkScore(),
      this.onUpdateAssessmentStandardFrameworkListener(),
      this.onUpdateAnswerListener(),
      this.onCreateNewAnswerListener(),
      this.onDeleteAnswerListener(),
    ];
  }
  defaultFilteration(filterBy: any) {
    // Create a new array with updated statuses
    const list =
      this.currentUserRole.name.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase()
        ? statusListForParticipant
        : statusList;
    this.statusFilters = list.map(status => {
      if (filterBy.some(f => f.toLowerCase() === status.name.toLowerCase())) {
        return { ...status, selected: true, applied: true };
      }
      return status;
    });
  }
}
