<div class="add-framework">
  <div class="layout" *cygovLoader="loading">
    <div class="header">
      <div class="lato-18-n-vw uppercase header-name">
        Framework Reviewers
      </div>
      <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
        (click)="closeModalFunction()">
      </div>
    </div>

    <div class="square-border">
      <div class="left-section">

        <div class="new-user">
          <p class="lato-16-n-vw">Current:</p>
        </div>
        <div class="manager-selection">

          <div class="search">
            <div class="search-icon" cygovSvgIcon [svgName]="'search-icon'"></div>
            <input type="text" class="form-control search-input" aria-label="Search"
              placeholder="Search" spellcheck="true" name="search-manager" id="search-manager"
              [(ngModel)]="searchManagerText" (keyup)="searchManager()" />
          </div>

          <div class="users-list" id="users-list">
            <div *ngFor="let user of filteredUserList, index as i" class="user-row">
              <cygov-checkbox [isChecked]="user.checked" [id]=" 'user-'+(i+1)"
                (changed)="changeSelectedManager(user, $event, i)" [width]="1.8" [height]="1.8"
                [useViewPortDimensions]="true">
              </cygov-checkbox>
              <cygov-username-icon [username]="user.name"></cygov-username-icon>
              <div class="lato-14-n-vw username" [ngClass]="{'text-checked': user.checked}">
                {{user?.name }}
              </div>
              <div class="lato-14-n-vw" [ngClass]="{'text-checked': user.checked}"> &nbsp;|&nbsp;
                <span *ngIf="user.role === 'mssp'">MSSP</span>
                <span *ngIf="user.role !== 'mssp'">{{user.role | titlecase}}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <hr class="horizontal-separator-left-section">

      <div class="archive-footer">
        <cygov-button class="margin-right" [buttonText]="'SAVE'" [buttonWidth]="'5.1vw'"
          (click)="addReviewers()">
        </cygov-button>
        <cygov-button class="border" [buttonText]="'CANCEL'" [buttonWidth]="'5.1vw'"
          [buttonColor]="'#030715'" (click)="closeModalFunction()">
        </cygov-button>
      </div>

    </div>

  </div>
</div>