<div *cygovLoader="loading">
  <div class="add-entity" *ngIf="newEntity">
    <div class="layout">
      <div class="header">
        <div class="lato-16-n-vw uppercase header-name">
          add new entity
        </div>
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
          (click)="activeModal.close()">
        </div>
      </div>
      <div [ngStyle]="{height : auditMode ? '67vh':'79vh'}" class="content">
        <div class="left-section">
          <div class="input-group lato-16-n-vw">
            <div class="section input-section upper-section">
              <div class="type-input-section">
                <div class="section-heading">
                  Type
                </div>
                <div class="btn-group audit-regular-buttons">
                  <button type="button" class="audit lato-16-n-vw"
                    [ngClass]="{'active': auditMode, 'un-active': !auditMode, 'disable-audit': !isMpl}"
                    (click)="isAudit(true)">
                    Audit
                  </button>
                  <button type="button" class="regular lato-16-n-vw"
                    [ngClass]="!auditMode ? 'active':'un-active'" (click)="isAudit(false)"
                    [disabled]="rootEntity.scName.includes('mpl')">
                    Entity
                  </button>
                </div>
              </div>
            </div>

            <div class="section input-section upper-section">
              <div class="name-input-section">
                <div class="section-heading">
                  Name
                </div>
                <div class="section-content lato-14-n-vw">
                  <input type="text" class="form-control lato-16-n-vw" [(ngModel)]="newEntity.name"
                    id="name" spellcheck="true" />
                </div>
              </div>
            </div>
            <div *ngIf="auditMode ; then chapter
              else regularEntity">
            </div>
          </div>
        </div>

        <div class="right-section">
          <div *ngIf="auditMode; then auditUsersDiv
              else regularUsersDiv">
          </div>
          <div class="assessment-dates" [ngClass]="{'auditModeAssessment': auditMode}">
            <div class="assement-header">
              <span class="lato-16-n-vw ">Assessment Duration</span>
            </div>
            <div class="calenders" [ngClass]="{'calenders-audit': auditMode }">
              <div class="start-calender">
                <cygov-ngb-calendar [(selectedDate)]="selectedStartDate"
                  [displayName]="'Start Date'"
                  (selectedDateChange)="dateChanged($event, ASSESSMENT_DATE)"
                  [inputMaxDate]="selectedEndDate" [showCustomNavigation]="true"
                  [dateType]="'startDate'" [toggleButton]="false" [openDefault]="true"
                  (calendarRef)="this.startCalendar = $event">
                </cygov-ngb-calendar>
              </div>
              <div class="end-calender">
                <cygov-ngb-calendar [(selectedDate)]="selectedEndDate" [displayName]="'End Date'"
                  (selectedDateChange)="dateChanged($event, DEADLINE_DATE)"
                  [inputMinDate]="selectedStartDate" [showCustomNavigation]="true"
                  [dateType]="'endDate'" [toggleButton]="false" [openDefault]="true"
                  (calendarRef)="this.endCalendar = $event">
                </cygov-ngb-calendar>
              </div>
            </div>
          </div>
          <!-- Adding the question settings for the audit mode here -->
          <div *ngIf="auditMode ; then questionSettingsAuditMode">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="buttons">
          <cygov-button class="button-margin save-button" [buttonText]="'ADD'"
            [buttonWidth]="'5.1vw'" [buttonColor]="'#29a9eb'"
            (click)="isValid(newEntity) && emitEntity(newEntity, s3Input, standardList, newAddedUsers)"
            [hoverColor]="'#0F92E3'">
          </cygov-button>
          <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#151A2F'"
            [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.1vw'" [textColor]="'#C5C9D1'"
            (click)="activeModal.close()">
          </cygov-button>
        </div>
      </div>
    </div>
    <ng-template #chapter>
      <div class="section input-section chapters-section">
        <div class="section-heading">
          Chapters
        </div>
        <div class="section-content select-chapter">
          <div *ngFor="let chapter of chapters" class="capitalize chapter"
            (click)="addToSelectedChapters(chapter)"
            [ngClass]="{'chapter-selected': selectedChapters.includes(chapter.name)}">
            <div class='tick-btn' *ngIf="selectedChapters.includes(chapter.name)" cygovSvgIcon
              [svgName]="'right-tick'"></div>
            <span class="chapter-name">{{chapter.name}}</span>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #regularEntity>
      <div class=" section input-section framework-section">
        <div class="section-heading">
          Choose Framework
        </div>
        <div class="section-content select-frameworks">
          <cygov-select-frameworks [firstTabList]="riskFrameWorkOpt" [secondTabList]="standardList"
            (outputfirstTabUpdatedList)="handleFirstTabUpdatedListOutput($event)" class="w-100"
            (outputsecondTabUpdatedList)="handleSecondTabUpdatedListOutput($event)">
          </cygov-select-frameworks>
        </div>
        <div class="section-content select-frameworks lower-section">
          <div class="question-settings-options">
            <div *ngIf="!auditMode ; then questionSettingsAuditMode"></div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #regularUsersDiv>
      <div class="list-section">
        <cygov-user-listing [users]="managerUsers" [listLabel]="'Assign Manager'"
          (selectedUser)="assignManager($event)" [currentUser]="currentUser">
        </cygov-user-listing>
      </div>
    </ng-template>
    <ng-template #auditUsersDiv>
      <div class="user-listing">
        <cygov-user-listing [listLabel]="'Users'" [auditMode]="auditMode"
          (newUsers)="addUser($event)">
        </cygov-user-listing>
      </div>
      </ng-template>
      <ng-template #questionSettingsAuditMode>
        <div class="footer-section">
          <ng-container *ngFor="let questionSetting of questionSettingsRadioOptions;let i=index">
            <div [class]="'radio-group radio-group'+i" [ngClass]="{'isCollaborative': !i && auditMode}">
              <div class=" heading lato-16-n-vw">
                {{questionSetting.title}}
              </div>
              <div class="type-radio round-checkbox">
                <span class="radio-span-setting-options"
                  *ngFor="let option of questionSetting.options; let idx = index">
                  <input type="radio" class="question-type-checkbox" [name]="questionSetting.id"
                    [value]="option.name" [checked]="option.checked"
                    (click)="selectedQuestionSettingsEmit(option)" [id]="questionSetting.id + idx+i" />
                  <label [for]="questionSetting.id + idx+i" class='label netskope-overview lato-14-n-vw'>
                    {{option.name}}</label>
                </span>
              </div>
            </div>
            <div *ngIf="i<questionSettingsRadioOptions.length-1" class="vertical-line">
            </div>
      
          </ng-container>
        </div>
    </ng-template>
  </div>

</div>