// eslint-disable-next-line no-shadow
export enum CommentActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum QuestionSettingsEnum {
  COLLABORATIVE = 'Collaborative',
  SINGLE = 'Single',
  OPTIONAL = 'Optional',
  MANDATORY = 'Mandatory',
  SUGGESTED = 'suggested',
  MANAGER_APPROVAL = 'mandatoryReviewer',
  NOT_REQUIRED = 'Not Required',
  ARTIFACTS = 'suggestedArtifact',
  COMMENTS = 'suggestedComments',
  ALL = 'all',
  NONE = 'none',
}

export enum SmartTab {
  POTENTIAL = 'Potential',
  ACTIVE = 'Active',
}
