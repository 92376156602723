//* All Constants Related to filters Modal
export const answersList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes',
    selected: false,
    applied: false,
  },
  {
    name: 'Partial_25',
    selected: false,
    applied: false,
  },
  {
    name: 'Partial_50',
    selected: false,
    applied: false,
  },
  {
    name: 'Partial_75',
    selected: false,
    applied: false,
  },
  {
    name: 'Partial_90',
    selected: false,
    applied: false,
  },
  {
    name: 'No',
    selected: false,
    applied: false,
  },
  {
    name: 'Unanswered',
    selected: false,
    applied: false,
  },
  {
    name: 'Not_Applicable',
    selected: false,
    applied: false,
  },
];

export const bnbAnswersList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes (2)',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes (3)',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes (4)',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes (5)',
    selected: false,
    applied: false,
  },
  {
    name: 'Partial',
    selected: false,
    applied: false,
  },
  {
    name: 'No',
    selected: false,
    applied: false,
  },
  {
    name: 'Unanswered',
    selected: false,
    applied: false,
  },
  {
    name: 'InfoSec',
    selected: false,
    applied: false,
  },
];

//* Midmarket Answers list
export const midMarketAnswersList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes',
    selected: false,
    applied: false,
  },
  {
    name: 'No',
    selected: false,
    applied: false,
  },
  {
    name: 'Unanswered',
    selected: false,
    applied: false,
  },
  {
    name: 'Not_Applicable',
    selected: false,
    applied: false,
  },
];

export const pciAnswersList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes - In place',
    selected: false,
    applied: false,
  },
  {
    name: 'Yes - With CCW',
    selected: false,
    applied: false,
  },
  {
    name: 'Not Applicable',
    selected: false,
    applied: false,
  },
  {
    name: 'Not Tested',
    selected: false,
    applied: false,
  },
  {
    name: 'Not in Place',
    selected: false,
    applied: false,
  },
  {
    name: 'Unanswered',
    selected: false,
    applied: false,
  },
];

export const statusList = [
  { name: 'All', selected: false, applied: false },
  { name: 'Waiting For Reviewer', selected: false, applied: false },
  { name: 'Assigned To me', selected: false, applied: false },
  { name: 'Approved', selected: false, applied: false },
  { name: 'Denied', selected: false, applied: false },
];

export const statusListForParticipant = [
  { name: 'All', selected: false, applied: false },
  { name: 'Assigned To me', selected: false, applied: false },
  { name: 'Waiting For Reviewer', selected: false, applied: false },
  { name: 'Denied By Reviewer', selected: false, applied: false },
];

export const impactList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Low',
    selected: false,
    applied: false,
  },
  {
    name: 'Medium',
    selected: false,
    applied: false,
  },
  {
    name: 'High',
    selected: false,
    applied: false,
  },
  {
    name: 'Critical',
    selected: false,
    applied: false,
  },
  {
    name: 'N/A',
    selected: false,
    applied: false,
  },
];

export const othersList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Integration Sources',
    selected: false,
    applied: false,
  },
  { name: 'Smart Mapping', selected: false, applied: false },
  {
    name: 'Artifacts',
    selected: false,
    applied: false,
  },
  {
    name: 'Versions',
    selected: false,
    applied: false,
  },
];

export const filteredOthersList = [
  {
    name: 'All',
    selected: false,
    applied: false,
  },
  {
    name: 'Artifacts',
    selected: false,
    applied: false,
  },
  {
    name: 'Versions',
    selected: false,
    applied: false,
  },
];
