<div class="modal-body" *ngIf="newSubEntity">
  <div class="modal-header">
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="activeModal.close()">
    </div>
  </div>
  <div class="modal-main">
    <div class="input-group">
      <input type="text" class="form-control" [(ngModel)]="newSubEntity.name" id="name"
        [placeholder]="isCrbAdjustments ? 'MPL Name' : 'Sub Entity Name'" spellcheck="true" />

      <input type="date" class="form-control" aria-label="date"
        [(ngModel)]="newSubEntity.timeline.collectionDate" ngbTooltip="Due Date" placement="top" />
    </div>
    <div class="input-group" *ngIf="isCrbAdjustments">
      <div class="input-wrapper" *ngFor="let funcName of functionsOpts; let i = index">
        <input type="checkbox" id="checkbox-{{ i }}" (change)="toggleSelected(funcName)" />
        <label for="checkbox-{{ i }}">{{ funcName }}</label>
      </div>

      <!-- <cygov-select [items]="functionsOpts" placeholder="Select Survey Chapter"
        [(ngModelValue)]="selectedSurveyFunction">
      </cygov-select> -->
    </div>
    <div class="user-invitations" *ngIf="isCrbAdjustments">
      <div class="lato-16-n-vw invitations">Send invitations</div>
      <div class="input-group">
        <input type="text" class="form-control" placeholder="User Name"
          [(ngModel)]="userList[0].name" spellcheck="true" />
        <input type="email" class="form-control" placeholder="Email" [(ngModel)]="userList[0].email"
          spellcheck="true" />
      </div>
      <div class="input-group">
        <input type="text" class="form-control" placeholder="User Name"
          [(ngModel)]="userList[1].name" />
        <input type="email" class="form-control" placeholder="Email" [(ngModel)]="userList[1].email"
          spellcheck="true" />
      </div>
      <div class="input-group">
        <input type="text" class="form-control" placeholder="User Name"
          [(ngModel)]="userList[2].name" spellcheck="true" />
        <input type="email" class="form-control" placeholder="Email" [(ngModel)]="userList[2].email"
          spellcheck="true" />
      </div>
    </div>

    <button type="button" class="btn btn-primary uppercase add-entity-btn"
      (click)="isValid(newSubEntity) && emitSubEntity(newSubEntity, userList)">
      <span class="lato-14-n-vw uppercase" *ngIf="!isCrbAdjustments">Add Sub Entity</span>
      <span class="lato-14-n-vw uppercase" *ngIf="isCrbAdjustments">Add MPL</span>
    </button>
  </div>
</div>