import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

enum TabsNum {
  FIRST = 'first',
  SECOND = 'second',
}

@Component({
  selector: 'cygov-select-frameworks',
  templateUrl: './select-frameworks.component.html',
  styleUrls: ['./select-frameworks.component.scss'],
})
export class SelectFrameworksComponent implements OnInit {
  @Input() headerTitle = 'Choose Framework';
  /*
    firstTabList Data should be like this
    [{
      name: 'NIST CSF',
      checked: false,
    }],

  */
  @Input() firstTabList = [];
  /*
    secondTabList Data should be like this
    [{
      name: 'GDPR',
      checked: false,
    }],
  */
  @Input() secondTabList = [];

  // Tab name
  @Input() firstTabTitle = 'Risk';
  @Input() secondTabTitle = 'Compliance';

  // Tab svg icon name
  @Input() firstTabIcon = 'Detect';
  @Input() secondTabIcon = 'Detect';

  // input to enable or disable multi select
  @Input() firstListMultiSelect = false;
  @Input() secondListMultiSelect = true;

  // select any firstTab/secondTab data, array will return
  @Output() outputfirstTabUpdatedList = new EventEmitter<any[]>();
  @Output() outputsecondTabUpdatedList = new EventEmitter<any[]>();

  cachedFirstTabList = [];
  cachedSecondTabList = [];
  tabsNum = TabsNum;

  showFrameworkNameTooltip: boolean = false;
  selectedKey: string = '';

  ngOnInit(): void {
    if (this.firstTabList && !!this.firstTabList.length) {
      this.cachedFirstTabList = this.firstTabList;
      // default risk framework NIST CSF
      this.selectedItemSetAndEmit(TabsNum.FIRST, 0);
    }
    if (this.secondTabList && !!this.secondTabList.length) {
      this.cachedSecondTabList = this.secondTabList;
    }
  }

  selectedItemSetAndEmit(type: string, radioButtonIndex: number): void {
    switch (type.toLowerCase()) {
      case TabsNum.FIRST: {
        this.firstTabList[radioButtonIndex].checked = !this.firstTabList[radioButtonIndex].checked;
        if (!this.firstListMultiSelect) {
          this.firstTabList = this.firstTabList.map((item, index) => {
            if (index === radioButtonIndex) {
              return item;
            }
            item.checked = false;
            return item;
          });
        }
        this.updateCachedRiskList(this.firstTabList[radioButtonIndex]);
        // cachedFirstTabList is referencing same data as firstTabList
        this.outputfirstTabUpdatedList.emit(this.cachedFirstTabList);
        break;
      }
      case TabsNum.SECOND: {
        this.secondTabList[radioButtonIndex].checked = !this.secondTabList[radioButtonIndex].checked;
        if (!this.secondListMultiSelect) {
          this.secondTabList = this.secondTabList.map((item, index) => {
            if (index === radioButtonIndex) {
              return item;
            }
            item.checked = false;
            return item;
          });
        }
        // cachedSecondTabList is referencing same data as secondTabList
        this.outputsecondTabUpdatedList.emit(this.cachedSecondTabList);
        break;
      }
    }
  }

  /**
   * Function to update cachedList for risk frameworks
   * @param framework - Currently selected risk framework
   */
  updateCachedRiskList(framework: any): void {
    if (framework.checked) {
      this.selectedKey = framework.key;
      if (this.selectedKey?.length) {
        this.cachedFirstTabList = this.cachedFirstTabList.map(fw => {
          fw.checked = fw.key === this.selectedKey;
          return fw;
        });
      }
    }
  }

  searchDataFromList(event): void {
    const searchedText = event?.target?.value?.toLowerCase();
    if (searchedText && !!searchedText.length) {
      this.firstTabList = this.cachedFirstTabList.filter(cft => cft.name.toLowerCase().includes(searchedText));
      this.secondTabList = this.cachedSecondTabList.filter(cst => cst.name.toLowerCase().includes(searchedText));
    } else {
      this.firstTabList = this.cachedFirstTabList;
      this.secondTabList = this.cachedSecondTabList;
    }
  }

  checkIfEllipsis(element: HTMLElement): void {
    this.showFrameworkNameTooltip = element.scrollWidth > element.clientWidth;
  }
}
