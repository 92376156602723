import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { RouterModule } from '@angular/router';
import { ToggleButtonModule } from 'app/shared/toggle-button/toggle-button.module';
import { CheckboxModule } from 'app/shared/checkbox/checkbox.module';
// remove and add import - build-optimization-1
import { ThirdPartyService } from 'app/third-party/third-party.service';
// import { ThirdPartyModule } from 'app/third-party/third-party.module';

@NgModule({
  declarations: [SidenavComponent],
  providers: [ThirdPartyService],
  imports: [
    CommonModule,
    RouterModule,
    SvgIconModule,
    ToggleButtonModule,
    CheckboxModule,
    FormsModule,
    // ThirdPartyModule,
  ],
  exports: [SidenavComponent],
})
export class SidenavModule {}
