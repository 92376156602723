// This component is workable to get Rapid7 and Tenable Information.

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { APIService, GetEntityQuery } from 'app/API.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityService } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { Services, ServiceName } from '../../constants';
import { Azure, Integration } from 'app/shared/enums/integration.enum';
import { UtilsService } from '../utils.service';
import { CURR_VIEW } from 'app/shared/enums/shared.enum';
import { CollectionService } from 'app/collection/collection.service';
import { CUSTOMAPIService } from 'app/custom-api.service';

interface IntegrationModal {
  name: string;
  credentials?: string;
  isEnable?: boolean;
  isAuth?: boolean;
  inheritFromParent?: boolean;
}

interface IntegrationCreds {
  url?: string;
  username?: string;
  password?: string;
  accessKey?: string;
  secretKey?: string;
  currView?: View;
  tenantId?: string;
  clientSecret?: string;
  subscriptionId?: string;
  clientId?: string;
}

type View = 'accessKey' | 'username' | null;

const currViewMap = {
  tenable: CURR_VIEW.ACCESSKEY,
  rapid7: CURR_VIEW.USERNAME,
};

@Component({
  selector: 'cygov-scan-integration-service-modal',
  templateUrl: './scan-integration-service-modal.component.html',
  styleUrls: ['./scan-integration-service-modal.component.scss'],
})
export class ScanIntegrationServiceModalComponent implements OnInit {
  @Input() entityId: string;
  @Input() isTenable: boolean;
  @Input() serviceName: string;
  @Output() accountEntity: EventEmitter<any> = new EventEmitter();
  entity: GetEntityQuery;
  formData: FormGroup;
  serviceType: string;
  formDisabled = false;
  integrations: IntegrationModal[] = [];
  r7Placeholder: string = 'https://rapid7.com/api/3';
  tenablePlaceholder: string = 'https://sc.tenalab.online/rest';
  crowdstrikePlaceholder: string = 'api.us-2.crowdstrike.com';
  placeholder: string = this.tenablePlaceholder; // by default

  // tenable var
  currView: View = CURR_VIEW.ACCESSKEY;
  Integration = Integration; // enum;
  title: string;
  constructor(
    public activeModal: NgbActiveModal,
    private entityService: EntityService,
    private toastr: ToastrService,
    private collectionService: CollectionService,
    private apiService: APIService,
    private customApi: CUSTOMAPIService
  ) {}

  async ngOnInit(): Promise<any> {
    this.entity = await this.getEntity();
    this.serviceType = Services[this.serviceName.toUpperCase()];
    let credentials: IntegrationCreds = { url: null, username: null, password: null };

    switch (this.serviceName) {
      case Integration.AZURE:
        this.title = Azure.AZURE;
        break;
      case Integration.MICROSOFT:
        this.title = Azure.MICROSOFT;
        break;
      default:
        this.title = this.serviceName;
        break;
    }

    this.setPlaceholder(this.serviceName);
    this.currView = currViewMap[this.serviceName] ? currViewMap[this.serviceName] : CURR_VIEW.USERNAME;

    if (this.entity?.integrations) {
      // call lambda to decrypt the credentials and sent back to frontend
      const payload = {
        id: this.entityId,
        service: this.serviceType,
        type: 'Query',
        source: 'frontend',
      };

      try {
        const integration = await this.customApi.HandleIntegrationDetails(JSON.stringify(payload));
        if (integration) {
          const integrationData = integration;

          credentials = JSON.parse(integrationData.credentials);
          if (credentials?.currView) {
            this.currView = credentials.currView;
          }

          const idx = this.entity.integrations.findIndex(int => int.name === integrationData.name);
          this.entity.integrations[idx].credentials = integrationData.credentials;
        }
      } catch (e) {
        console.log('Issue in integrat', e);
      }
    }

    if (this.serviceName === ServiceName.AZUREDEFENDER || this.serviceName === ServiceName.MICROSOFTDEFENDER) {
      this.formData = new FormGroup({
        // eslint-disable-next-line @typescript-eslint/unbound-method
        subscriptionId: new FormControl(credentials.subscriptionId),

        // eslint-disable-next-line @typescript-eslint/unbound-method
        tenantId: new FormControl(credentials.tenantId),

        // eslint-disable-next-line @typescript-eslint/unbound-method
        clientId: new FormControl(credentials.clientId),

        // eslint-disable-next-line @typescript-eslint/unbound-method
        clientSecret: new FormControl(credentials.clientSecret),
      });
    } else {
      this.formData = new FormGroup({
        // eslint-disable-next-line @typescript-eslint/unbound-method
        url: new FormControl(credentials.url),

        // eslint-disable-next-line @typescript-eslint/unbound-method
        username: new FormControl(credentials.username),
        // eslint-disable-next-line @typescript-eslint/unbound-method
        password: new FormControl(credentials.password),

        // eslint-disable-next-line @typescript-eslint/unbound-method
        accessKey: new FormControl(credentials.accessKey),
        // eslint-disable-next-line @typescript-eslint/unbound-method
        secretKey: new FormControl(credentials.secretKey),
      });
    }
  }

  async onSubmit(): Promise<void> {
    this.formDisabled = true;
    const formDataControl = this.formData.controls;

    let integrationArray: IntegrationModal[] = [];
    if (
      ((this.serviceName === ServiceName.AZUREDEFENDER || this.serviceName === ServiceName.MICROSOFTDEFENDER) &&
        this.isDefenderFormValid(formDataControl)) ||
      (formDataControl?.url?.value &&
        ((this.currView === CURR_VIEW.USERNAME &&
          formDataControl?.username?.value &&
          formDataControl?.password?.value) ||
          (this.currView === CURR_VIEW.ACCESSKEY &&
            formDataControl?.accessKey?.value &&
            formDataControl?.secretKey?.value)))
    ) {
      let info: IntegrationCreds = {
        url: formDataControl?.url?.value,
        currView: this.currView,
      };

      if (this.serviceName === ServiceName.AZUREDEFENDER || this.serviceName === ServiceName.MICROSOFTDEFENDER) {
        info = {};
        info.subscriptionId = formDataControl.subscriptionId.value;
        info.tenantId = formDataControl.tenantId.value;
        info.clientId = formDataControl.clientId.value;
        info.clientSecret = formDataControl.clientSecret.value;
      } else if (this.currView === CURR_VIEW.USERNAME) {
        info.username = formDataControl.username.value;
        info.password = formDataControl.password.value;
      } else {
        info.accessKey = formDataControl.accessKey.value;
        info.secretKey = formDataControl.secretKey.value;
      }
      if (this.entity?.integrations) {
        integrationArray = this.entity.integrations.map(integration => {
          delete integration.__typename;
          return { ...integration };
        });
      }
      let integrationInfo = this.setIntegrationInfo(info);
      let isExistingCredentials: boolean = false;
      if (integrationArray.length === 0) {
        integrationArray = [integrationInfo];
      } else {
        const existingItemIndex = integrationArray.findIndex(elem => elem.name === this.serviceType);

        if (existingItemIndex > -1) {
          const existingIntegrationInfo: IntegrationCreds = JSON.parse(integrationArray[existingItemIndex].credentials);
          this.updateExistingCredentials(existingIntegrationInfo, formDataControl);
          integrationInfo = this.setIntegrationInfo(existingIntegrationInfo);
          integrationArray[existingItemIndex] = integrationInfo;
          isExistingCredentials = true;
        } else {
          this.integrations = [];
          this.integrations.push(integrationInfo);
          integrationArray.push(integrationInfo);
        }
      }

      const payload = {
        id: this.entityId,
        newlyAddedIntegration: integrationInfo,
        type: 'Mutation',
        source: 'frontend',
      };
      this.entity = await this.customApi.HandleIntegrationDetails(JSON.stringify(payload));

      // this.entity = JSON.parse(updatedIntegration);

      if (isExistingCredentials) {
        this.collectionService.updateIntegrationTabs$.next(this.entity);
      }
      this.toastr.success('Information has been saved.');
      this.runGetSites();
    } else {
      this.toastr.error('Missing required fields');
    }
    this.formDisabled = false;
  }

  runGetSites(): void {
    const eventPayload: any = { entity: this.entity, currService: this.serviceName };
    this.accountEntity.emit(eventPayload);
  }

  setIntegrationInfo(info): IntegrationModal {
    return {
      name: this.serviceType,
      credentials: JSON.stringify(info),
      isEnable: true,
      isAuth: null,
      inheritFromParent: null,
    };
  }

  async getEntity(): Promise<GetEntityQuery> {
    return await this.entityService.getEntity(this.entityId, false);
  }

  closeModal(): void {
    this.activeModal.close();
  }

  setPlaceholder(serviceName: string): void {
    switch (serviceName && serviceName.toLowerCase()) {
      case Integration.TENABLE:
        this.placeholder = this.tenablePlaceholder;
        break;
      case Integration.CROWDSTRIKE:
        this.placeholder = this.crowdstrikePlaceholder;
        break;
      case Integration.RAPID7:
        this.placeholder = this.r7Placeholder;
        break;

      default:
        break;
    }
  }

  toggleTenableSwitch(view: View): void {
    this.currView = view;
  }

  updateExistingCredentials(existingIntegrationInfo: IntegrationCreds, formDataControl: any): void {
    if (this.serviceName === ServiceName.AZUREDEFENDER || this.serviceName === ServiceName.MICROSOFTDEFENDER) {
      existingIntegrationInfo.subscriptionId = formDataControl.subscriptionId.value;
      existingIntegrationInfo.tenantId = formDataControl.tenantId.value;
      existingIntegrationInfo.clientId = formDataControl.clientId.value;
      existingIntegrationInfo.clientSecret = formDataControl.clientSecret.value;
      return;
    }

    if (!UtilsService.isDefined(existingIntegrationInfo.currView) || this.currView === CURR_VIEW.USERNAME) {
      existingIntegrationInfo.username = formDataControl.username.value;
      existingIntegrationInfo.password = formDataControl.password.value;
    } else {
      existingIntegrationInfo.accessKey = formDataControl.accessKey.value;
      existingIntegrationInfo.secretKey = formDataControl.secretKey.value;
    }
    existingIntegrationInfo.url = formDataControl.url.value;
    existingIntegrationInfo.currView = this.currView;
  }

  async updateCredentials(
    existingCredentials: IntegrationCreds,
    existingItemIndex: number,
    integrationArray: IntegrationModal[]
  ): Promise<void> {
    if (existingCredentials?.currView !== this.currView) {
      existingCredentials.currView = this.currView;
      integrationArray[existingItemIndex] = this.setIntegrationInfo(existingCredentials);
      await this.entityService.updateEntity({
        id: this.entity.id,
        integrations: integrationArray,
      });
    }
  }

  isDefenderFormValid(credentials): boolean {
    if (
      (credentials?.subscriptionId?.value &&
        credentials?.tenantId?.value &&
        credentials?.clientId?.value &&
        credentials?.clientSecret?.value) ||
      (credentials?.subscriptionId && credentials?.tenantId && credentials?.clientId && credentials?.clientSecret)
    ) {
      return true;
    }

    return false;
  }
}
