<div class="questions" *ngIf="questions">
  <div class="single-question"
    *ngFor="let question of questions; let questionIdx = index; let last = last">
    <div class="single-question-container">
      <div class="lato-16-n-vw question-content" [ngClass]="langDirectionClass">
        <span class="question-num">{{ questionIdx + 1 }}.</span>
        <span class="question-text">{{ question.name | capitalizeFirst }}</span>
        <span class="info-icon" cygovSvgIcon [svgName]="'info-icon'" *ngIf="question.description"
          ngbTooltip="{{ question.description | capitalizeFirst }}" placement="left"></span>
      </div>

      <div class="answer-list">
        <div class="round-checkbox" *ngFor="let answer of possibleAnswers; let answerIdx = index">
          <input [id]="'label-Q' + question.id + 'A' + answerIdx + 'AS' + question.assessmentIds[0]"
            type="radio" [name]="question.id + '_' + question.assessmentIds[0]"
            [disabled]="showQuestionWithoutUser" [value]="answer"
            [checked]="answerNumToEnum(answers[questionIdx].answer) === answer"
            (change)="answers[questionIdx].answer = answerEnumToNum((answer))" />
          <label
            [for]="'label-Q' + question.id + 'A' + answerIdx + 'AS' + question.assessmentIds[0]"
            class="lato-16-n-vw capitalize label target answer-label">{{ answer.toLowerCase()
            | capitalizeFirst | replace:'_':' ' }}</label>
        </div>
      </div>

      <div class="additional-info">
        <textarea rows="4" [name]="question.id + '_' + question.assessmentIds[0]"
          [(ngModel)]="answers[questionIdx].comment" [placeholder]="'Please explain your answer'"
          [disabled]="showQuestionWithoutUser">
        </textarea>

        <div class="artifact-file">
          <div class="lato-16-b-vw capitalize">
            <!-- <span>{{ isCrbAdjustments ? 'Artifacts ' : 'Evidence ' }}</span> -->
            <span>{{ 'Artifacts ' }}</span>
            <span>({{ question.settings?.isArtifactsMandatory ? 'Mandatory' : 'Optional' }})</span>
          </div>
          <div class="lowercase file-counter" [ngClass]="{ visible: answers[questionIdx]?.file }"
            ngbTooltip="{{ answers[questionIdx]?.file?.names }}" placement="top">
            ({{
            answers[questionIdx]?.file?.length
            ? answers[questionIdx]?.file?.length > 1
            ? answers[questionIdx]?.file?.length + ' files'
            : answers[questionIdx]?.file?.length + ' file'
            : ''
            }})
          </div>
          <div class="btns-group">
            <button class="btn-type-link" cygovSvgIcon [svgName]="'upload_collection'"
              ngbTooltip="Upload" placement="top" (click)="uploadArtifact.click()"
              [disabled]="showQuestionWithoutUser"></button>
            <div class="vertical-separator"></div>
            <button class="btn-type-link" cygovSvgIcon [svgName]="'download-exfiles-icon'"
              ngbTooltip="Download" placement="top"
              (click)="downloadFile(answers[questionIdx].file)"
              [disabled]="!answers[questionIdx]?.file?.length || showQuestionWithoutUser"></button>
            <div class="vertical-separator"></div>
            <button class="btn-type-link" cygovSvgIcon [svgName]="'trash-icon'" ngbTooltip="Delete"
              placement="right" (click)="openModalDeleteArtifact(answers[questionIdx])"
              [disabled]="!answers[questionIdx]?.file?.length || showQuestionWithoutUser"></button>
          </div>
          <input #uploadArtifact class="file-input" type="file" name="artifact"
            accept=".gif, .jpeg, .jpg, .png, .doc, .docx, .csv, .pdf, .xls, .xlsx, .ppt, .txt"
            (change)="onFilePicked($event, questionIdx)" multiple />
        </div>
      </div>
      <div class="approve-denied" *ngIf="hasApprovalPermission">
        <div class="audit-comment">
          <input type="text" placeholder="Audit comment..."
            [(ngModel)]="question.managerAnswers[questionIdx].comment"
            [disabled]="showQuestionWithoutUser" spellcheck="true" />
        </div>
        <div class="approve-answer">
          <div class="round-checkbox">
            <input [id]="'label-Q' + question.id + '-Approve'" type="radio"
              [name]="'label-Q' + question.id + '-Approve'" [value]="true" [checked]="
                isDefined(question.managerAnswers[questionIdx].answer) &&
                answerNumToEnum(question.managerAnswers[questionIdx].answer) === answerEnum.YES
              " (change)="approvalChanged(question, answerEnum.YES , questionIdx)"
              [disabled]="showQuestionWithoutUser" />
            <label [for]="'label-Q' + question.id + '-Approve'"
              class="lato-16-n-vw capitalize label target">Approved</label>
          </div>
          <div class="round-checkbox">
            <input [id]="'label-Q' + question.id + '-Denied'" type="radio"
              [name]="'label-Q' + question.id + '-Denied'" [value]="false" [checked]="
                isDefined(question.managerAnswers[questionIdx].answer) &&
                answerNumToEnum(question.managerAnswers[questionIdx].answer) === answerEnum.NO
              " (change)="approvalChanged(question, answerEnum.NO , questionIdx)"
              [disabled]="showQuestionWithoutUser" />
            <label [for]="'label-Q' + question.id + '-Denied'"
              class="lato-16-n-vw capitalize label target">Denied</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-wrapper" *ngIf="!showQuestionWithoutUser && questions && questions.length > 0">
    <button (click)="onSave()" class="lato-18-n-vw capitalize btn btn-submit" [disabled]="saving">
      Save
    </button>
  </div>
</div>