<div class="denied">
  <div class="critically">
    <label for="issueLevel">Issue Critically</label>
    <cygov-select [items]="issueLevels" placeholder="Level" name="issueLevel" id="issueLevel"
      [(ngModelValue)]="question.denied.issue.impact" [clearable]="false">
    </cygov-select>
  </div>
  <div class="remarks">
    <div class="panel">
      <label for="issueDescription">Issue Description</label>
      <textarea name="issueDescription" [(ngModel)]="question.denied.issue.description"></textarea>
    </div>
    <div class="panel">
      <label for="rootCause">Root Cause</label>
      <textarea name="rootCause" [(ngModel)]="question.denied.rootCause"></textarea>
    </div>
    <div class="panel">
      <label for="impact">Impact</label>
      <textarea name="impact" [(ngModel)]="question.denied.impact"></textarea>
    </div>
    <div class="panel">
      <label for="recommendation">Recommendation</label>
      <textarea name="recommendation" [(ngModel)]="question.denied.recommendation"></textarea>
    </div>
    <div class="panel">
      <label for="actionPlan">Remedial Action Plan and Target Date</label>
      <textarea name="actionPlan" [(ngModel)]="question.denied.actionPlan"></textarea>
    </div>
    <div class="panel">
      <label for="owner">Issue Owner</label>
      <textarea name="owner" [(ngModel)]="question.denied.owner"> </textarea>
    </div>
  </div>
  <div class="btns-group">
    <button class="btn btn-confirm" (click)="modalResult.emit(true)">Save</button>
    <button class="btn btn-cancel" (click)="activeModal.close()">Cancel</button>
  </div>
</div>