<div class='selection-tabs-cont'>


  <div class="tabs-n-listing">
    <div class='tabs-cont'>
      <div ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- First Tab Content -->
        <div ngbNavItem>
          <a ngbNavLink>
            <div class='firstTab-title-cont'>
              <div cygovSvgIcon [svgName]="firstTabIcon" class='tab-title-icon'></div>
              <span class='tab-title-text lato-18-n-vw'>{{ firstTabTitle }}</span>
              <!-- Exclamation Mark -->
              <div class="info-circle">
                <div class="info-marker" [ngbTooltip]="riskTooltipContent" placement="right"
                  cygovSvgIcon [svgName]="'exclamation-mark'"
                  tooltipClass="choose-framework-tooltip"></div>
              </div>
            </div>
          </a>
          <ng-template #riskTooltipContent>
            <div class="tooltip-container">
              <p>
                The cyber security risk calculation will be based on the current framework.

              </p>
              <p class="tooltip-text">
                Please note: you are only able to choose one risk framework per entity
              </p>

            </div>
          </ng-template>
          <ng-template ngbNavContent>
            <div class='firstTab-list-cont'>
              <div class='firstTab-name round-checkbox'
                *ngFor="let item of firstTabList; let i=index;">
                <input type="radio"
                  [name]="firstListMultiSelect ? 'firstTab-item-'+ i : 'firstTab-item'"
                  [value]="item.name" [checked]="item.checked" [id]="'firstTab-label-' + i"
                  (click)="selectedItemSetAndEmit(tabsNum.FIRST, i)"
                  class="firstTab-item-checkbox" />
                <label [for]="'firstTab-label-' + i" class='label netskope-overview lato-14-n-vw'>
                  <div class="text-area-ellipsis" [ngbTooltip]="item.name" #fwRadioLabel
                    [disableTooltip]="!showFrameworkNameTooltip"
                    [tooltipClass]="'large-number-tooltip'"
                    (mouseenter)="checkIfEllipsis(fwRadioLabel)" container="body">{{ item.name }}
                  </div>
                </label>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Second Tab Content -->
        <div ngbNavItem>
          <a ngbNavLink>
            <div class='firstTab-title-cont'>
              <div cygovSvgIcon [svgName]="'compilance'" class='tab-title-icon'></div>
              <span class="tab-title-text lato-18-n-vw">{{ secondTabTitle }}</span>
              <!-- Exclamation Mark -->
              <div class="info-circle">
                <div class="info-marker" cygovSvgIcon [svgName]="'exclamation-mark'"
                  tooltipClass="choose-framework-tooltip" [ngbTooltip]="complianceTooltipContent"
                  placement="left"></div>
              </div>
            </div>
          </a>
          <ng-template #complianceTooltipContent>
            <div class="tooltip-container">
              <p>
                Selection of a compliance framework will be added in a parallel to your risk
                framework.

              </p>
              <p class="tooltip-text">
                Please note: you are able to choose multiple compliance frameworks per entity
              </p>

            </div>
          </ng-template>
          <ng-template ngbNavContent>
            <div class='secondTab-list-cont'>
              <div class='secondTab-name round-checkbox'
                *ngFor="let item of secondTabList; let i=index;">
                <input type="radio"
                  [name]="secondListMultiSelect ? 'secondTab-item-'+ i : 'secondTab-item'"
                  [value]="item.name" [checked]="item.checked" [id]="'secondTab-label-' + i"
                  (click)="selectedItemSetAndEmit(tabsNum.SECOND, i)"
                  class="secondTab-item-checkbox" />
                <label [for]="'secondTab-label-' + i" class='label netskope-overview lato-14-n-vw'>
                  <div class="text-area-ellipsis " [ngbTooltip]="item.name"
                    [tooltipClass]="'large-number-tooltip'"
                    [disableTooltip]="!showFrameworkNameTooltip" #fwRadioLabel2
                    (mouseenter)="checkIfEllipsis(fwRadioLabel2)" container="body">{{ item.name }}
                  </div>
                </label>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="searching-bar">
        <input type="text" class='searching-input-field' placeholder="Search"
          (keyup)="searchDataFromList($event)" />
        <div cygovSvgIcon [svgName]="'search-icon'" class="searching-icon"></div>
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>