import { Injectable } from '@angular/core';
import { FileService } from 'app/shared/file.service';
import JSZip from 'jszip';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Storage } from 'aws-amplify';
import { ThirdPartyConstants, BINARY_EDGE_ENUM } from 'app/third-party/third-party.constants';
import { TaskStatusEnum } from 'app/API.service';

@Injectable({
  providedIn: 'root',
})
export class IntelligenceService {
  allS3Data: any = {};
  othersS3Data: any = {};
  combinedAllData: any = {};
  combinedData: any = {
    cves: {},
    openPorts: {},
    emailAddresses: {},
    breaches: {},
    otherFindings: {},
  };
  emptyData: any = {
    cves: {},
    openPorts: {},
    emailAddresses: {},
    breaches: {},
    otherFindings: {},
  };
  score: number = 0;
  tableNamesMapper = ThirdPartyConstants.TableNamesMapper;
  addDataTypeMapper = ThirdPartyConstants.AddDataTypeMapper;
  cves: any[] = [];
  openPorts: any[] = [];
  otherFindings: any[] = [];
  breaches: any[] = [];
  emailAddresses: any[] = [];

  public intelligenceArray = new Subject<any>();
  public intelligenceList = this.intelligenceArray.asObservable();

  constructor(
    private fileService: FileService,
    private toastr: ToastrService
  ) {}

  async populateIntelligenceDataFromS3(vendor, customPath = null): Promise<any> {
    try {
      const path = customPath ? customPath : `VENDORS/${vendor.id}`;
      const itemsList = await this.fileService.checkIfPathExistsS3(path);
      // check if there is domain
      if (itemsList && itemsList.length) {
        this.allS3Data = {};
        let promises = [];
        const otherPromises = [];
        // for each domain zip , we will download them all
        itemsList.map(zipPath => {
          const domName = zipPath.key.split('/')[2];
          if ((domName !== BINARY_EDGE_ENUM.FOURTH_PARTY && domName !== BINARY_EDGE_ENUM.OTHERS) || customPath) {
            promises.push(this.getFileFromS3(zipPath.key));
          }
          if (zipPath.key.includes(BINARY_EDGE_ENUM.OTHERS)) {
            otherPromises.push(this.getFilesFromS3(zipPath.key));
          }
        });
        const zipList = await Promise.all(promises);
        this.othersS3Data = await Promise.all(otherPromises);
        promises = [];
        // for each domain zip , we will extract all 5 tables data
        zipList.map((zipped, index) => {
          promises.push(this.zipExtractionScans(zipped, index));
        });
        await Promise.all(promises);
        // combine all domain data
        this.combineAllDomainData();
        if (!customPath) {
          this.setAllS3Data(this.allS3Data, this.combinedData);
        }
        return { allS3Data: this.allS3Data, combinedData: this.combinedData, score: this.score };
      } else {
        return { allS3Data: this.emptyData, combinedData: this.emptyData, score: 0 };
      }
    } catch (err) {
      console.log(err);
      return { allS3Data: this.emptyData, combinedData: this.emptyData, score: 0 };
    }
  }
  /**
   * The function downloads the zip file from S3
   * @param key The key is the path to that zip file
   * @returns a Promise or The Zip file blob
   */
  async getFileFromS3(key: string): Promise<void> {
    try {
      const res = await this.fileService.downloadFileFromS3(key);
      if (res) {
        return await this.fileService.getFile(res, { responseType: 'blob' });
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  async getFilesFromS3(filePath): Promise<any> {
    try {
      const options: any = {
        download: true,
        Recursive: true,
        contentType: 'application/json',
        level: 'public',
        cacheControl: 'no-cache',
      };
      const s3Data: any = await Storage.get(filePath, options);
      const response = await new Response(s3Data.Body).json();
      return response;
    } catch (err) {
      return null;
    }
  }

  /**
   * The function extracts the files from the ZIP and add them to a single Global Object 'allS3Data'
   * @param zipped The Zip Blob file which we get from S3
   * @param zipNumber The hierarchal number from the Object keys list from S3 Storage
   */
  async zipExtractionScans(zipped, zipNumber: number): Promise<void> {
    try {
      const zip = new JSZip();
      const originalFile = await zip.loadAsync(zipped);
      const name = 'zip' + zipNumber;
      this.allS3Data[name] = {};

      const promises = Object.keys(originalFile.files).map(async filename => {
        const tableName = filename.split('.json')[0];
        const stringJSON = await originalFile.files[filename].async('string');
        try {
          this.allS3Data[name][tableName] = JSON.parse(stringJSON);
        } catch (e) {
          this.allS3Data[name][tableName] = { idCount: 0, allList: [], domain: '' };
        }
      });
      await Promise.all(promises);
    } catch (err) {
      console.log('zipExtractionScans Error ==>', err);
      return;
    }
  }

  setAllS3Data(s3Data, combinedData) {
    this.allS3Data = JSON.parse(JSON.stringify(s3Data));
    this.combinedAllData = JSON.parse(JSON.stringify(combinedData));
    // this.intelligenceArray.next(this.combinedAllData);
  }
  getAllS3Data() {
    return this.allS3Data;
  }

  /**
   * The function saves the updated scan tables to S3
   * @param updatedDoms An Object containing the updated Table list with Domains
   */
  async saveScanToS3(updatedDoms: any, message: string, type: string, vendor: any): Promise<void> {
    try {
      let promises = [];
      const domList = [];
      Object.keys(updatedDoms).forEach(dom => {
        // creating zip for each updated domains and their tables
        const zip = JSZip();
        zip.file('emailAddresses.json', JSON.stringify(updatedDoms[dom].emailAddresses));
        zip.file('breaches.json', JSON.stringify(updatedDoms[dom].breaches));
        zip.file('openPorts.json', JSON.stringify(updatedDoms[dom].openPorts));
        zip.file('cves.json', JSON.stringify(updatedDoms[dom].cves));
        zip.file('otherFindings.json', JSON.stringify(updatedDoms[dom].otherFindings));
        promises.push(zip.generateAsync({ type: 'blob' }));
        domList.push(dom);
      });
      const savedZips = await Promise.all(promises);
      promises = [];
      savedZips.forEach((zip, index) => {
        // uploading them to S3 files
        const config = {
          contentType: 'application/zip',
          download: true,
          level: 'public',
        };
        const path = `VENDORS/${vendor.id}/${domList[index]}/${domList[index]}.zip`;
        const obj = zip;
        promises.push(this.fileService.uploadToS3CustomPath(path, obj, config));
      });
      await Promise.all(promises);
      switch (message) {
        case BINARY_EDGE_ENUM.DELETE.toLowerCase():
          this.toastr.success('Selected Scans Deleted !');
          break;
        case BINARY_EDGE_ENUM.ADDED.toLowerCase():
          this.toastr.success(`${type} ${message} succesfully!`);
          break;
        case BINARY_EDGE_ENUM.UPDATED.toLowerCase():
          this.toastr.success(`${type} ${message} succesfully!`);
          break;
      }
    } catch (e) {
      console.log(e);
      this.toastr.error(`Failed to ${message} scans...`);
    }
  }

  /**
   * The function combines all the table data for each domain to 1 single Array List
   * We are also appending the ID's as well depending which domain they are from
   */
  combineAllDomainData(): void {
    this.resetAllData();
    const otherIdCount = {
      cves: 0,
      openPorts: 0,
      emailAddresses: 0,
      breaches: 0,
      otherFindings: 0,
    };
    const idCount = {
      cves: 0,
      openPorts: 0,
      emailAddresses: 0,
      breaches: 0,
      otherFindings: 0,
    };
    const allTidCounts = {
      // tId of all the domains data in tables
      cves: 0,
      openPorts: 0,
      emailAddresses: 0,
      breaches: 0,
      otherFindings: 0,
    };
    const combinedDomainScore: any = {};
    const combinedOthersScore: any = {
      cves: 0,
      openPorts: 0,
      emailAddresses: 0,
      breaches: 0,
      otherFindings: 0,
    };
    Object.keys(this.allS3Data).forEach((key, index) => {
      const name = 'zipScore' + index;
      combinedDomainScore[name] = {
        cves: 0,
        openPorts: 0,
        emailAddresses: 0,
        breaches: 0,
        otherFindings: 0,
      };
      // key = zip0, zip1,...
      // key2 = breaches, open ports, cves etc
      Object.keys(this.allS3Data[key]).forEach(key2 => {
        const arrList = JSON.parse(JSON.stringify(this.allS3Data[key][key2].allList));
        const dom = this.allS3Data[key][key2].domain;
        if (index === 0) {
          this[key2] = [];
        }
        arrList.forEach(data => {
          // meta data for rows
          data.key = key;
          data.key2 = key2;
          data.s3Id = data.id;
          data.domain = dom;
          if (!data.deleted) {
            allTidCounts[key2] = allTidCounts[key2] + 1;
            data.tId = `${this.addDataTypeMapper[data.key2][0]}-${
              allTidCounts[key2] > 99 ? allTidCounts[key2] : '0' + +allTidCounts[key2]
            }`;
            this[key2].push(data);
            combinedDomainScore[name][key2] =
              combinedDomainScore[name][key2] + (data?.riskScore ? +data?.riskScore : 0);
            idCount[key2] = idCount[key2] + 1;
          }
        });
        this.combinedData[key2] = { allList: this[key2], idCount: idCount[key2] };
      });
    });
    if (this.othersS3Data && this.othersS3Data.length) {
      this.othersS3Data.map(data => {
        data.key2 = data.addType;
        data.s3Id = data.id;
        if (!data.deleted) {
          this[data.addType].push(data);
          otherIdCount[data.addType] = otherIdCount[data.addType] + 1;
          const tId = data.tId[0];
          data.tId = `${tId}-${this[data.key2].length > 99 ? this[data.key2].length : '0' + +this[data.key2].length}`;
          combinedOthersScore[data.addType] =
            combinedOthersScore[data.addType] + (data?.riskScore ? +data?.riskScore : 0);
        }
        this.combinedData[data.addType] = {
          allList: this[data.addType],
          idCount: idCount[data.addType] + otherIdCount[data.addType],
        };
      });
    }
    Object.keys(combinedDomainScore).forEach(key => {
      Object.keys(combinedDomainScore[key]).forEach(key2 => {
        combinedOthersScore[key2] = combinedOthersScore[key2] + combinedDomainScore[key][key2];
      });
    });

    // checking if all domains combined data exists, if exists calculates the score
    if (
      Object.values(this.combinedData.cves).length ||
      Object.values(this.combinedData.otherFindings).length ||
      Object.values(this.combinedData.breaches).length
    ) {
      const cvesAvg = this.combinedData.cves.idCount
        ? 10 - combinedOthersScore.cves / this.combinedData.cves.idCount
        : 10;
      const otherFinAvg = this.combinedData.otherFindings.idCount
        ? 10 - combinedOthersScore.otherFindings / this.combinedData.otherFindings.idCount
        : 10;

      const breachesAvg = this.combinedData.breaches.idCount
        ? this.calcBreachesScore(this.combinedData.breaches.allList)
        : 10;
      this.score = (breachesAvg + otherFinAvg + cvesAvg) / 3;
    }
    return;
  }

  /**
   * resetting all service data
   */
  resetAllData(): void {
    this.score = 0;
    this.cves = [];
    this.openPorts = [];
    this.otherFindings = [];
    this.breaches = [];
    this.emailAddresses = [];
    this.combinedData = {
      cves: {},
      openPorts: {},
      emailAddresses: {},
      breaches: {},
      otherFindings: {},
    };
  }

  calcBreachesScore(breaches): number {
    if (breaches && breaches.length) {
      const openBreaches = breaches.filter(
        breach => breach.status && breach.status.toLowerCase() === TaskStatusEnum.OPEN.toLowerCase()
      );
      const sum = openBreaches.length > 100 ? 100 : openBreaches.length;
      return 10 - sum / 10;
    } else {
      return 10;
    }
  }

  /**
   * The function saves the new added data to S3
   * @param folder the folder name where to store the added data
   */
  async saveNewDataToS3(folder: any, message: string, data: any, vendor: any): Promise<void> {
    try {
      const config = {
        contentType: 'application',
        download: true,
        level: 'public',
      };
      const path = `VENDORS/${vendor.id}/${folder}/${data.id}.json`;
      const obj = data;
      await this.fileService.uploadToS3CustomPath(path, obj, config);
      switch (message) {
        case BINARY_EDGE_ENUM.DELETE.toLowerCase():
          this.toastr.success('Selected Scans Deleted !');
          break;
        case BINARY_EDGE_ENUM.ADDED.toLowerCase():
          this.toastr.success(`${this.addDataTypeMapper[data.addType]} ${message} successfully!`);
          break;
        case BINARY_EDGE_ENUM.UPDATED.toLowerCase():
          this.toastr.success(`${this.addDataTypeMapper[data.addType]} ${message} successfully!`);
          break;
      }
    } catch (e) {
      console.log(e);
      this.toastr.error(`Failed to ${message} scans...`);
    }
  }

  /**
   * The function saves the updated data to S3
   * @param folder the folder name where to store the added data
   */
  updateDataInS3(rows: any, message: string, vendor: any): void {
    try {
      const config = {
        contentType: 'application',
        download: true,
        level: 'public',
      };
      rows.map(async row => {
        const path = `VENDORS/${vendor.id}/OTHERS/${row.id}.json`;
        const obj = row;
        await this.fileService.uploadToS3CustomPath(path, obj, config);
        switch (message) {
          case BINARY_EDGE_ENUM.DELETE.toLowerCase():
            this.toastr.success('Selected Scans Deleted !');
            break;
          case BINARY_EDGE_ENUM.UPDATED.toLowerCase():
            this.toastr.success(`${this.addDataTypeMapper[row.addType]} ${message} successfully!`);
            break;
        }
      });
    } catch (e) {
      console.log(e);
      this.toastr.error(`Failed to ${message} scans...`);
    }
  }
}
