<div class="large-number-component cell-style number-type lato-14-n-vw"
    *ngIf="!useControlValueAccessor; else useCVA" [ngClass]="{ 'overflow-hidden': !largeArrow}">
    <div *ngIf="!editNumber && !originalValue" (click)="showEditNumber()" class="span-container">
        <span [ngStyle]="{'font-size':fontSize+'vh', color:fontColor}"
            [ngClass]="{ 'lato-14-b-vw': bold}" class='align-number'>
            <ng-container *ngIf="+valueDigit && +valueDigit<0 ; else showPlaceholder">
                {{0 |
                largeNumberShort:appendOnLeft:appendOnRight:restrictFloat}}
            </ng-container>
            <ng-template #showPlaceholder>{{valueDigit || 0 |
                largeNumberShort:appendOnLeft:appendOnRight:restrictFloat }} <span
                    *ngIf="showDays">{{ getDaySuffix(+valueDigit) || '' }}</span></ng-template>
        </span>
    </div>
    <div *ngIf="!editNumber && originalValue" (click)="showEditNumber()" class="span-container">
        <span [ngStyle]="{'font-size':fontSize+'vh', color:fontColor}"
            [ngClass]="{ 'lato-14-b-vw': bold}" class='align-number'>
            <ng-container *ngIf="+valueDigit && +valueDigit<0 ; else showPlaceholder">
                {{0}}
            </ng-container>
            <ng-template #showPlaceholder>{{valueDigit || 0 }}</ng-template>
        </span>
    </div>
    <div class="input-area" [ngClass]="{'display-none':!editNumber}">
        <input class="input-styling lato-14-n-vw" [ngStyle]="{'font-size':fontSize+'vh'}"
            onKeyPress="if(this.value.length==9) return false;" type="number"
            [(ngModel)]="valueDigit" (ngModelChange)="valueCheck($event)" [name]="'largeNumber'"
            #numberInputTag
            (focusout)="editNumber = false;inputFocusOut.emit({ focus:true, value: valueDigit })"
            (focusin)="editNumber = true;inputFocusIn.emit({ focus:true, value: valueDigit })"
            [step]="stepJump" [disabled]="isLocked" />
    </div>
    <div *ngIf="editable && !multiEntityEdit && showUpDownArrrow" class="number-change-buttons">
        <div class="incr-dcr-icon" [ngClass]="{'large-arrow-up': largeArrow}"
            (click)="stepper(true)" cygovSvgIcon [svgName]="'arrow-up-type1'">
        </div>
        <div class="incr-dcr-icon down-arrow" [ngClass]="{'large-arrow-down': largeArrow}"
            (click)="stepper(false)" cygovSvgIcon [svgName]="'arrow-up-type1'"></div>
    </div>
</div>

<ng-template #useCVA>
    <div class="large-number-component cell-style number-type lato-14-n-vw"
        *ngIf="useControlValueAccessor" [ngClass]="{ 'overflow-hidden': !largeArrow}">
        <div *ngIf="!editNumber && !originalValue" (click)="showEditNumber()"
            class="span-container">
            <span [ngStyle]="{'font-size':fontSize+'vh', color:fontColor}"
                [ngClass]="{ 'lato-14-b-vw': bold }" class='align-number'>{{valueDigit || 0 |
                largeNumberShort:appendOnLeft:appendOnRight }}</span>
        </div>
        <div *ngIf="!editNumber && originalValue" (click)="showEditNumber()" class="span-container">
            <span [ngStyle]="{'font-size':fontSize+'vh', color:fontColor}"
                [ngClass]="{ 'lato-14-b-vw': bold }" class='align-number'>{{valueDigit || 0
                }}</span>
        </div>
        <div class="input-area" [ngClass]="{'display-none':!editNumber}">
            <input class="input-styling lato-14-n-vw" [ngStyle]="{'font-size':fontSize+'vh'}"
                [value]="valueDigit" type="number" #numberInputTag (focusout)="editNumber = false;"
                [step]="stepJump" (change)="valueCheck(numberInputTag?.value)" />
        </div>
        <div *ngIf="editable" class="number-change-buttons">
            <div class="incr-dcr-icon" [ngClass]="{'large-arrow-up': largeArrow}"
                (click)="stepper(true)" cygovSvgIcon [svgName]="'arrow-up-type1'">
            </div>
            <div class="incr-dcr-icon down-arrow" [ngClass]="{'large-arrow-down': largeArrow}"
                (click)="stepper(false)" cygovSvgIcon [svgName]="'arrow-up-type1'"></div>
        </div>
    </div>
</ng-template>