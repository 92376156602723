import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-welcome-guide-modal',
  templateUrl: './welcome-guide-modal.component.html',
  styleUrls: ['./welcome-guide-modal.component.scss'],
})
export class WelcomeGuideModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}
  @Output() modalResult = new EventEmitter<boolean>();

  ngOnInit(): void {}

  closeModal(): void {
    this.modalResult.emit(true);
  }
}
