import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { UploadStateEnum } from '../../upload-file.enum';
import { UploadFileService } from '../../upload-file.service';
import * as JSZip from 'jszip';
import { ExistingFiles } from 'app/shared/existing-files/existing-files.component';
import { FileTypeEnum } from 'app/API.service';

@Component({
  selector: 'cygov-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss'],
})
export class UploadTemplateComponent implements OnInit {
  @Output() closeModalClicked: EventEmitter<any> = new EventEmitter();
  @Output() returnUploadedFiles: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() uploadPath;
  @Input() requiredInfo: any;
  @Input() uploadType: string = '';
  @Input() restrictFileDrop: string = '';
  @Input() multiSelect = true;

  uploader: FileUploader;
  readableText = [];
  zipSaved = false;
  rawTextFile = [];
  jsZip = null;
  isInformatica: boolean = UtilsService.isInformatica || UtilsService.isInformaticaUat;

  constructor(
    private uploadService: UploadFileService,
    private fileService: FileService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.uploader = new FileUploader({ url: this.uploadPath });
    this.uploader.queue = [];
    this.readableText = [];

    if (this.requiredInfo && this.requiredInfo.fileType === 'SUPPORT_FILES') {
      this.getPreviousFiles();
    }
  }

  /**
   * Getting previous file/files from the given path if there exist any
   */
  getPreviousFiles(): void {
    if (UtilsService.isDefined(this.uploadService.supportZip)) {
      this.jsZip = this.uploadService.getSupportZip();
    } else {
      this.jsZip = null;
    }
  }

  async fileHandler(files: FileList | any): Promise<void> {
    this.zipSaved = false;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type === 'application/pdf') {
        if (await this.fileService.scanPdfFile(files[i])) {
          this.toastr.warning('Your File Contain Malicious Content');
          this.uploader.queue[i]?.remove();
          return null;
        }
      }
      if (FileService.validateFileSize(files[i])) {
        this.uploader.queue[i].remove();
        this.toastr.warning(files[i].name + ' Size can not be greater than 30MB');
      } else if (!FileService.validateFileType(files[i], FileTypeEnum.ARTIFACTS)) {
        this.uploader.queue[i].remove();
        this.toastr.warning(files[i].name + ' File type not supported');
      }
      // if the file is not a CSV then remove it
      if (this.restrictFileDrop && !files[i].name.includes(this.restrictFileDrop)) {
        this.uploader.queue[i].remove();
      }
    }
    if (this.uploader.queue.length !== 0) {
      if (UtilsService.isDefined(this.requiredInfo)) {
        if (this.requiredInfo && this.requiredInfo.fileType === 'SUPPORT_FILES') {
          this.zipAllFiles(files);
        } else if (this.requiredInfo && this.requiredInfo.fileType === 'RISKS_FILE') {
          const filesArr = [];
          this.uploader.queue.forEach(file => {
            const blob = new Blob([file.file.rawFile as Blob]);
            filesArr.push(blob);
          });
          this.uploadService.setFile(filesArr[0]);
          this.uploader.queue = [];
        } else {
          this.uploadService.setFile(files[0]);
        }
      } else {
        await this.convertToRawText();
      }
      setTimeout(() => {
        this.uploadService.setUploadState(UploadStateEnum.UPLOAD_STARTED);
      }, 1000);
    }
  }

  /**
   * Converting the CSV files to raw text array
   * each array index will represent each file
   */
  async convertToRawText(): Promise<void> {
    const promises = [];
    this.uploader.queue.forEach(file => {
      const blob = new Blob([file.file.rawFile as Blob]);
      promises.push(this.getTextFromBlob(blob));
    });
    await Promise.all(promises);
    this.uploadService.setReadableObjectArray(this.readableText);
  }

  /**
   * getting raw text from Blob file
   * @param {Blob} blob a file blob
   */
  async getTextFromBlob(blob): Promise<void> {
    this.readableText.push(await blob.text());
  }

  /**
   * All files will be zipped in a single file and returned
   * @param files
   */
  zipAllFiles(files: FileList): void {
    // check if exiting zip exist
    const JZ = this.jsZip ? this.jsZip : new JSZip();

    // adding files into a single zip
    const filesZipped: ExistingFiles[] = [];
    Array.from(files).map(file => {
      JZ.file(file.name, file);
    });
    const user = this.uploadService.getCurrentUser();
    Object.keys(JZ.files).map(file => {
      filesZipped.push(new ExistingFiles(file, Date.now(), true, user && user.name ? user.name : '', null, false));
    });
    filesZipped.push(new ExistingFiles('totalZipped', Date.now(), true, user && user.name ? user.name : '', JZ, false));
    this.returnUploadedFiles.emit(filesZipped);
    this.uploadService.setSupportZip(JZ);
    this.uploadService.setFile(filesZipped);
  }

  closeModal(): void {
    this.closeModalClicked.emit(true);
  }
}
