import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DomainFrameworkService } from '../domain-framework.service';

@Injectable({
  providedIn: 'root',
})
export class DomainSpecificGuard  {
  constructor(private router: Router, private domainFrameworkService: DomainFrameworkService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyDomain();
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  // only allow if user of a specific domain tries to access this route
  async verifyDomain(): Promise<boolean> {
    const currentDomain = DomainFrameworkService.getHostName();
    // const currentDomain = DomainEnum.NETSKOPE;
    const settings = this.domainFrameworkService.domainSetting;
    if (settings && settings.domain && settings.domain.toLowerCase() === currentDomain.toLowerCase()) {
      return true;
    } else {
      await this.router.navigate(['/clients']);
    }
  }
}
