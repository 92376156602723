import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-denied-modal',
  templateUrl: './denied-modal.component.html',
  styleUrls: ['./denied-modal.component.scss'],
})
export class DeniedModalComponent {
  @Input() question: any;
  @Output() modalResult = new EventEmitter<boolean>();

  issueLevels = ['high', 'medium', 'low'];

  constructor(public activeModal: NgbActiveModal) {}
}
