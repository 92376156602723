<div class="entity-progress-bars" [ngClass]="{ 'horizontal-view': horizontalView }">
  <div class="progress-bar-wrapper">
    <div class="progress-bar-details lato-16-n-vw">
      <div class="name capitalize">collection</div>
      <div class="value">
        <!--        <span class="mini-crown" cygovSvgIcon [svgName]="'mini-crown'"></span>-->
        <span>{{ (scores?.collection ? scores.collection > 100 ? 100 : scores?.collection : 0) |
          percent }}</span>
      </div>
    </div>
    <cygov-progress-bar
      [assessmentLevel]="scores?.collection ? scores.collection > 100 ? 100 : scores?.collection : 0"
      [postFill]="['#29A9EB', '#0B517C']" [height]="height">
    </cygov-progress-bar>
  </div>

  <div class="progress-bar-wrapper">
    <div class="progress-bar-details lato-16-n-vw">
      <div class="name capitalize">remediation</div>
      <div class="value">
        <span>{{ (scores?.remediation > 100 ? 100 : scores?.remediation) | percent }}</span>
        <!--<div class="mini-icon" cygovSvgIcon [svgName]="'mini-crown'"></div>-->
      </div>
    </div>
    <cygov-progress-bar
      [assessmentLevel]="scores?.remediation ? scores.remediation > 100 ? 100 : scores.remediation : 0"
      [postFill]="['#6A11C3', '#340E60']" [height]="height">
    </cygov-progress-bar>
  </div>
  <div class="progress-bar-wrapper"
    *ngIf="showReassessmentScores === reassessmentEnum.IN_PROGRESS && !archiveMode">
    <div class="progress-bar-details lato-16-n-vw">
      <div class="name capitalize">reassessment</div>
      <div class="value">
        <span>{{ (scores?.reassessment > 100 ? 100 : scores?.reassessment) | percent }}</span>
      </div>
    </div>
    <cygov-progress-bar [assessmentLevel]="scores?.reassessment > 100 ? 100 : scores?.reassessment"
      [postFill]="['#FF3D6B', '#570317']" [height]="height">
    </cygov-progress-bar>
  </div>
</div>