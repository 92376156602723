<div *cygovLoader="loading">
  <div class="add-entity" *ngIf="newEntity"
    [ngStyle]="{'display': reviewerModelOpen ? 'none': 'block'}">
    <div class="layout">
      <div class="header">
        <div class="lato-16-n-vw uppercase header-name">
          add new entity
        </div>
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
          (click)="activeModal.close()">
        </div>
      </div>
      <div class="content">
        <div class="left-section">
          <div class="input-group lato-16-n-vw">
            <div class="section input-section upper-section">
              <div class="name-input-section">
                <div class="section-heading lato-16-n-vw">
                  Entity Name
                  <!-- <span class="required-asterisk">*</span> -->
                </div>
                <div class="section-content lato-14-n-vw">
                  <input type="text" class="form-control lato-16-n-vw" [(ngModel)]="newEntity.name"
                    id="name" spellcheck="true" />
                </div>
              </div>
              <div class="logo-input-section">
                <div class="logo-container-wrapper" *ngIf="!this.newLogoUploaded">
                  <div class="add-logo-btn" (click)="uploadLogo.click()">
                    <div class="plus-sign">+</div>
                    <div class="label lato-18-n-vw">Add Logo</div>
                    <input #uploadLogo class="file-input" type="file" name="logo"
                      accept=".gif, .jpg, .jpeg, .bmp, .tif, .png" (change)="imgHandler($event)" />
                  </div>

                </div>
                <div class="section-content lato-14-n-vw">
                  <div class="text-container logo-upload-icon center-upload-picture"
                    *ngIf="this.newLogoUploaded">
                    <div class="logo">
                      <div class="image-container">
                        <img class="vendor-logo" *ngIf="uploadedLogo" [src]="uploadedLogo"
                          alt="{{ newEntity.name }}" />
                        <span class="lato-16-n-vw capitalize" *ngIf="!uploadedLogo">{{
                          newEntity.name
                          }}</span>
                      </div>
                    </div>
                    <div class="pencil-icon-wrapper" *ngIf="this.newLogoUploaded">
                      <div class="pencil-icon" cygovSvgIcon [svgName]="'edit-button'"
                        (click)="uploadLogo.click()">
                        <input #uploadLogo class="file-input" type="file" name="logo"
                          accept=".gif, .jpg, .jpeg, .bmp, .tif, .png"
                          (change)="imgHandler($event)" />
                      </div>
                    </div>
                    <!-- <div class="update-uploaded-logo-btn" *ngIf="this.newLogoUploaded">
                      <div class="camera-icon" cygovSvgIcon [svgName]="'camera-icon'"></div>
                      <span (click)="uploadLogo.click()" class="lato-18-b-vw btn-text">Upload
                        Logo</span>
                    </div> -->

                    <!-- <input #uploadLogo class="file-input" type="file" name="logo"
                      accept=".gif, .jpg, .png" (change)="imgHandler($event)" /> -->


                  </div>

                  <!-- <div class="text-container mt-15 center-upload-button"
                    *ngIf="!this.newLogoUploaded">
                    <button class="btn-type-link capitalize logo-btn" (click)="uploadLogo.click()">
                      <span class="camera-icon" cygovSvgIcon [svgName]="'camera-icon'"></span>
                      <span class="lato-18-b-vw btn-text">Upload Logo</span>
                    </button>

                    <input #uploadLogo class="file-input" type="file" name="logo"
                      accept=".gif, .jpg, .png" (change)="imgHandler($event)" />

                  </div> -->
                </div>
              </div>
              <div class="absolute-button tick-container"
                *ngIf="sectionsCompletion[currentActiveSectionIndex] && newEntity.name">
                <div class="tick" cygovSvgIcon [svgName]="'right-tick'"></div>
              </div>
              <div class="absolute-button next lato-14-n-vw"
                *ngIf="!sectionsCompletion[currentActiveSectionIndex] || !newEntity.name"
                (click)="onSectionCompletion(0)">
                Next</div>

            </div>

            <div class="section input-section framework-section"
              [ngClass]="{'low-opacity-content': !sectionsCompletion[currentActiveSectionIndex]}">
              <div class="section-heading lato-16-n-vw">
                Choose Framework
              </div>
              <div class="section-content select-frameworks">
                <cygov-select-frameworks [firstTabList]="riskFrameWorkOpt"
                  [secondTabList]="standardList"
                  (outputfirstTabUpdatedList)="handleFirstTabUpdatedListOutput($event)"
                  class="w-100"
                  (outputsecondTabUpdatedList)="handleSecondTabUpdatedListOutput($event)">
                </cygov-select-frameworks>
              </div>
              <hr class="horizontal-separator-left-section">
              <div class="absolute-button tick-container"
                *ngIf="sectionsCompletion[currentActiveSectionIndex]  && (currentActiveSectionIndex == 2 || currentActiveSectionIndex == 3 || currentActiveSectionIndex == 4) ">
                <div class="tick" cygovSvgIcon [svgName]="'right-tick'"></div>
              </div>
              <div class="absolute-button next lato-14-n-vw"
                *ngIf="(!sectionsCompletion[currentActiveSectionIndex] || isFrameworkValid ) &&  currentActiveSectionIndex == 1"
                (click)="onSectionCompletion(1)">
                Next</div>
            </div>
          </div>
        </div>

        <div class="right-section">
          <!-- <div class="list-section">
            <cygov-user-listing [users]="managerUsers" [listLabel]="'Assign Manager'"
              (selectedUser)="assignManager($event)" [currentUser]="currentUser">
            </cygov-user-listing>
          </div> -->
          <div class="assessment-dates"
            [ngClass]="{'low-opacity-content': currentActiveSectionIndex == 0 || currentActiveSectionIndex == 1}">
            <div class="assement-header">
              <div class="lato-16-n-vw section-space">Assessment Duration</div>

            </div>
            <hr class="horizontal-separator-right-section">
            <div class="calenders edit-multientity-calendar">
              <div class="start-calender">
                <cygov-ngb-calendar [(selectedDate)]="selectedStartDate"
                  [displayName]="'Start Date'"
                  (selectedDateChange)="dateChanged($event, ASSESSMENT_DATE)"
                  [inputMinDate]="currentDate" [inputMaxDate]="selectedEndDate"
                  [showCustomNavigation]="true" [dateType]="'startDate'" [toggleButton]="false"
                  [openDefault]="true" (calendarRef)="this.startCalendar = $event">
                </cygov-ngb-calendar>
              </div>
              <div class="end-calender">
                <cygov-ngb-calendar [(selectedDate)]="selectedEndDate" [displayName]="'End Date'"
                  (selectedDateChange)="dateChanged($event, DEADLINE_DATE)"
                  [inputMinDate]="endDateLimit" [showCustomNavigation]="true" [dateType]="'endDate'"
                  [toggleButton]="false" [openDefault]="true"
                  (calendarRef)="this.endCalendar = $event" [openDefault]="true">
                </cygov-ngb-calendar>
              </div>
            </div>
            <div class="absolute-button tick-container"
              *ngIf="sectionsCompletion[2] &&  currentActiveSectionIndex == 3">
              <div class="tick" cygovSvgIcon [svgName]="'right-tick'"></div>
            </div>
            <div class="absolute-button next lato-14-n-vw"
              *ngIf="(!sectionsCompletion[currentActiveSectionIndex] || isFrameworkValid ) &&  currentActiveSectionIndex == 2"
              (click)="onSectionCompletion(2)">
              Next</div>
          </div>
          <div class="lower-section" [ngClass]=" {'low-opacity-content':
            currentActiveSectionIndex==0 || currentActiveSectionIndex==1 ||
            currentActiveSectionIndex==2}">
            <div class="section-heading section-heading-framework lato-16-n-vw section-space">
              Framework Settings
            </div>
            <hr class="horizontal-separator">
            <div class="radio-group padding-top-bottom">
              <div class="type-radio round-checkbox" style="display: flex;">
                <span class="radio-span-setting-options"
                  *ngFor="let option of frameworkQuestionTypeOptions; let idx = index">
                  <input type="radio" class="question-type-checkbox" name="question-type"
                    [value]="option.name"
                    (change)="selectedQuestionSettingsEmit($event,option.name)"
                    [checked]="option.checked" [id]="'question-type' + idx"
                    [disabled]="option?.readonly" />

                  <label [for]="'question-type' + idx" class='label netskope-overview lato-14-n-vw'>
                    {{option.name}}</label>
                </span>
                <ng-template #questiontypeContent>
                  <div class="tooltip-container">
                    <p>Multi-User assignment of questions.</p>
                    <p><b class="tooltip-heading">Collaborative</b> -
                      two or more users
                      work together to provide a shared answer back to the
                      organization. Any update to the answer will overwrite the
                      previous answer.</p>
                    <p><b class="tooltip-heading">Single</b> - two or
                      more users work
                      separately to provide back their own individual answers to the
                      question. The answers are automatically averaged and a a “AVG”
                      user is added to provide the final result.</p>

                  </div>
                </ng-template>
                <div class="lato-14-n-vw info-icon-tooltip" [ngbTooltip]="questiontypeContent"
                  [placement]="frameworkSettingsTooltips['questionType'].placement" cygovSvgIcon
                  [svgName]="'info-board-icon'" tooltipClass="add-new-entity-question-type-tooltip">
                </div>
              </div>
            </div>
            <hr class="horizontal-separator">
            <div></div>
            <div class="question-settings-options">
              <div class="footer-section footer-mandatory-type">
                <ng-container
                  *ngFor="let questionSetting of questionSettingsRadioOptions;let i=index">
                  <div [class]="'radio-group radio-group'+i"
                    [ngClass]="{'toggle-btn-style': questionSetting.type === 'toggle'}">
                    <ng-container *ngIf="questionSetting.type==='radio';else toggleBtn">
                      <div style="display: flex;">
                        <div class="lato-16-n-vw">
                          {{questionSetting.title | titlecase}}
                        </div>
                        <div class="lato-14-n-vw info-icon margin-left-info-icon"
                          [ngbTooltip]="frameworkSettingsTooltips[questionSetting.id].text"
                          [placement]="frameworkSettingsTooltips[questionSetting.id].placement"
                          cygovSvgIcon [svgName]="'info-board-icon'"
                          tooltipClass="add-new-entity-mandatory-type-tooltip">
                        </div>
                      </div>

                      <div class="type-radio round-checkbox">
                        <span class="radio-span-setting-options"
                          *ngFor="let option of questionSetting.options; let idx = index">
                          <ng-container *ngIf="questionSetting.type==='radio';else toggleBtn">
                            <input type="radio" class="question-type-checkbox"
                              [name]="questionSetting.id" [value]="option.name"
                              [checked]="option.checked" [id]="questionSetting.id + idx+i"
                              (change)="selectedQuestionSettingsEmit($event, questionSetting.id)" />
                            <label [for]="questionSetting.id + idx+i"
                              class='label netskope-overview lato-14-n-vw'>
                              {{option.name | titlecase}}</label>
                          </ng-container>
                        </span>
                      </div>
                    </ng-container>

                    <ng-template #toggleBtn>
                      <div class="type-radio round-checkbox">
                        <span class="radio-span-setting-options custom-toggle-bg">
                          <cygov-toggle-button [id]="questionSetting.id"
                            [isChecked]="questionSetting.isChecked"
                            (changed)="handleToggleChange($event, questionSetting.key)">
                          </cygov-toggle-button>
                        </span>
                      </div>
                      <div class="lato-14-n-vw top-margin">
                        {{questionSetting.title}}
                      </div>

                    </ng-template>
                  </div>
                  <div *ngIf="i<questionSettingsRadioOptions.length-1" class="vertical-line">
                  </div>

                </ng-container>
              </div>
              <hr class="horizontal-separator">
              <div class="footer-section">
                <ng-container *ngFor="let settings of frameworkSettings;let i=index">
                  <div class="lower-footer-toggle custom-toggle-bg"
                    [class]="{'custom-margin-left': i === 1}">
                    <cygov-toggle-button [id]="settings.id" [isChecked]="settings.isChecked"
                      [isDisabled]="settings?.readonly"
                      (changed)="handleToggleChange($event, settings.key)">
                    </cygov-toggle-button>
                    <label class="remove-margin lato-14-n-vw"
                      [class]="{'custom-margin-left': i === 1}"
                      [for]="settings.id">{{settings.title}}</label>
                    <div class="lato-14-n-vw info-icon margin-left-framework-setting"
                      [ngbTooltip]="frameworkSettingsTooltips[settings.id].text"
                      [placement]="frameworkSettingsTooltips[settings.id].placement" cygovSvgIcon
                      [svgName]="'info-board-icon'"
                      tooltipClass="add-new-entity-mandatory-type-tooltip">
                    </div>
                  </div>
                  <div *ngIf="i<frameworkSettings.length-1" class="vertical-line">
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="absolute-button tick-container">
              <div class="tick" cygovSvgIcon [svgName]="'right-tick'"></div>
            </div>
          </div>
          <div class="edit-mode-select-manager">
            <div class="select-manager back-color-default lato-16-n-vw"
              [ngClass]="{'low-opacity-content': this.newEntity.defaultQuestionSettings['isApprovalRequired'] === false}">

              <div class="manager-section-header">
                <div class="display-flex">
                  <span class="lato-16-n-vw title">The Framework Reviewer is:</span>
                  <div id="edit-manager" class="name-with-edit-button"
                    (click)="openReviewerModal()">
                    <span
                      *ngIf="selectedManagers?.length && this.newEntity.defaultQuestionSettings['isApprovalRequired'] === true"
                      class="lato-16-n-vw name">{{selectedManagers[0]?.name}}</span>
                    <div
                      *ngIf="selectedManagers?.length && this.newEntity.defaultQuestionSettings['isApprovalRequired'] === true"
                      class="edit-icon" cygovSvgIcon [svgName]="'edit-icon'">
                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="editManager" class="close-manager" cygovSvgIcon
                        [svgName]="'x-icon-type2'" (click)="closeManagerSelection()">
                    </div> -->
              </div>

              <div class="top-padding display-flex">
                <cygov-checkbox [width]=" 1.8" [height]="1.8" [useViewPortDimensions]="true"
                  [isChecked]="reviewerAsParticipant"
                  (changed)="this.reviewerAsParticipant = $event">
                </cygov-checkbox>

                <span class="lato-14-n-vw title left-padding">Assign reviewer to answer all
                  questions</span>

              </div>
              <div class="absolute-button tick-container">
                <div class="tick" cygovSvgIcon [svgName]="'right-tick'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="buttons">
          <cygov-button class="button-margin save-button" [buttonText]="'ADD'"
            [buttonWidth]="'5.1vw'" [buttonColor]="'#29a9eb'"
            (click)="currentActiveSectionIndex ===3 && isValid(newEntity) && emitEntity(newEntity, s3Input, standardList)"
            [disableClick]="currentActiveSectionIndex !==3" [hoverColor]="'#0F92E3'">
          </cygov-button>
          <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#151A2F'"
            [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.1vw'" [textColor]="'#C5C9D1'"
            (click)="activeModal.close()">
          </cygov-button>
        </div>
      </div>
    </div>
  </div>
</div>