import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CygovSliderComponent } from './cygov-slider.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
@NgModule({
  declarations: [CygovSliderComponent],
  imports: [CommonModule, NgbModule, FormsModule, PipesModule, SvgIconModule],
  exports: [CygovSliderComponent],
})
export class CygovSliderModule {}
