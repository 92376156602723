<div *ngIf="formData" class='modal-main-content-cont'>
  <ng-container *ngTemplateOutlet="header"></ng-container>

  <div class="modal-body">
    <div class="container-fluid">

      <ng-container [ngSwitch]="serviceName">

        <ng-container *ngSwitchCase="'tenable'">
          <ng-container *ngTemplateOutlet="switchableModal; context: {formData: formData}">
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="Integration.AZURE">
          <ng-container *ngTemplateOutlet="azureModal; context: {formData: formData}">
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="Integration.MICROSOFT">
          <ng-container *ngTemplateOutlet="azureModal; context: {formData: formData}">
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="normalModal; context: {formData: formData}">
          </ng-container>
        </ng-container>

      </ng-container>

    </div>
  </div>


</div>



<ng-template #normalModal let-formData="formData">
  <form [formGroup]='formData' (ngSubmit)="onSubmit()">

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="">URL</label>
          <input type="text" formControlName='url' [placeholder]="placeholder" [ngClass]="{'form-control': true,
                  'error': formData.get('url').touched && !formData.get('url').valid}">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="">Username</label>
          <input type="text" formControlName='username' placeholder='johndoe' [ngClass]="{'form-control': true,
                  'error': formData.get('username').touched && !formData.get('username').valid}">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Password</label>
          <input type="password" formControlName='password' autocomplete="off"
            placeholder='password' [ngClass]="{'form-control': true,
                  'error': formData.get('password').touched && !formData.get('password').valid}">
        </div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="footer; context: {formData: formData}"></ng-container>
  </form>
</ng-template>

<ng-template #switchableModal let-formData="formData">

  <div class="switch-btns mb-4">
    <button class="switch-btn btn btn-outline-dark mr-2 "
      [ngClass]="{activeColor: currView!=='username'}"
      (click)="toggleTenableSwitch('accessKey')">Access
      Key</button>
    <button class="switch-btn btn btn-outline-dark "
      [ngClass]="{activeColor: currView==='username'}"
      (click)="toggleTenableSwitch('username')">Username</button>
  </div>

  <form [formGroup]='formData' (ngSubmit)="onSubmit()">

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="">URL</label>
          <input type="text" formControlName='url' [placeholder]="placeholder" [ngClass]="{'form-control': true,
                  'error': formData.get('url').touched && !formData.get('url').valid}">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <ng-container *ngIf="currView==='username'; else accessKeyControl">
            <label for="">Username</label>
            <input type="text" formControlName='username' placeholder='johndoe' [ngClass]="{'form-control': true,
                    'error': formData.get('username').touched && !formData.get('username').valid}">
          </ng-container>
          <ng-template #accessKeyControl>
            <label for="">Access key</label>
            <input type="text" formControlName='accessKey'
              placeholder='z267481232954fefb2010fbd8efa4454d'
              [ngClass]="{'form-control': true,
                    'error': formData.get('accessKey').touched && !formData.get('accessKey').valid}">
          </ng-template>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <ng-container *ngIf="currView==='username'; else apiSecret">
            <label for="">Password</label>
            <input type="password" formControlName='password' autocomplete="off"
              placeholder='password' [ngClass]="{'form-control': true,
                    'error': formData.get('password').touched && !formData.get('password').valid}">
          </ng-container>
          <ng-template #apiSecret>
            <label for="">Secret Key</label>
            <input type="password" formControlName='secretKey' autocomplete="off"
              placeholder='21149c7a7f4b4dg45b019gd469f124g664'
              [ngClass]="{'form-control': true,
                    'error': formData.get('secretKey').touched && !formData.get('secretKey').valid}">
          </ng-template>

        </div>
      </div>
    </div>


    <ng-container *ngTemplateOutlet="footer; context: {formData: formData}"></ng-container>

  </form>

</ng-template>

<ng-template #azureModal let-formData="formData">
  <form [formGroup]='formData' (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="">Azure Subscription Id</label>
          <input type="text" formControlName='subscriptionId' placeholder='Subscription Id'
            [ngClass]="{'form-control': true,
                  'error': formData.get('subscriptionId').touched && !formData.get('subscriptionId').valid}">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Tenant Id</label>
          <input type="text" formControlName='tenantId' autocomplete="off" placeholder='Tenant Id'
            [ngClass]="{'form-control': true,
                  'error': formData.get('tenantId').touched && !formData.get('tenantId').valid}">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="">Client Id</label>
          <input type="text" formControlName='clientId' placeholder='Client Id' [ngClass]="{'form-control': true,
                  'error': formData.get('clientId').touched && !formData.get('clientId').valid}">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Client Secret</label>
          <input type="password" formControlName='clientSecret' autocomplete="off"
            placeholder='Client Secret'
            [ngClass]="{'form-control': true,
                  'error': formData.get('clientSecret').touched && !formData.get('clientSecret').valid}">
        </div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="footer; context: {formData: formData}"></ng-container>
  </form>
</ng-template>


<ng-template #header>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ title | titlecase }} Account Information
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span inert>×</span>
    </button>
  </div>
</ng-template>

<ng-template #footer let-formData="formData">
  <div class=" modal-footer">
    <button type="submit" class="btn btn-outline-dark" [disabled]='!formData.valid || formDisabled'
      cygovUserPermission [hiddenMode]="1">Save & Run</button>
    <button type="button" (click)="closeModal()" class="btn btn-outline-dark">Close</button>
  </div>
</ng-template>