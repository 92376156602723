export enum ProbabilityEnum {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum ImpactEnum {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export enum RiskMapEnum {
  IMPACT = 'impact',
  PROBABILITY = 'probability',
}

export enum EnableDisableEnum {
  ENABLE = 'Enabled',
  DISABLE = 'Disabled',
}

export enum StatusEnum {
  MITIGATED = 'Mitigated',
  ACCEPTED = 'Accepted',
  TRANSFERRED = 'Transferred',
  IGNORED = 'Ignored',
  OPEN = 'Open',
  ESCALATED = 'Escalated',
}

export enum RiskStatusEnum {
  MITIGATED = 'Mitigated',
  ACCEPTED = 'Accepted',
  TRANSFERRED = 'Transferred',
  OPEN = 'Open',
}

export enum TagEnum {
  OPERATIONS = 'Operations',
  VENDORS = 'Vendors',
  COMPLIANCE = 'Compliance',
  PAYMENT = 'Payment',
}

export enum RiskColumnMappersEnum {
  name = 'riskTitle',
  affectedAssets = 'relatedAssets',
  priority = 'riskPriority',
  impact = 'riskImpact',
  status = 'riskStatus',
  probability = 'riskProbability',
  residual = 'residualRisk',
  inherent = 'inherentRisk',
  tag = 'riskTags',
}
