import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOMAPIService, MidMarketEnum } from 'app/custom-api.service';
import { MidmarketWizardPopUpComponent } from 'app/shared/midmarket-wizard-pop-up/midmarket-wizard-pop-up.component';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'cygov-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnInit {
  loading: boolean = false;
  progress: number = 10;
  @Input() wizardData: any = null;
  @Output() nextButtonClick = new EventEmitter<any>();
  ngbMidMarketWizardModalOptions: NgbModalOptions = {
    size: 'large',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'mid-market-entity-wizard-popup-window',
    backdropClass: 'mid-market-entity-wizard-popup-backdrop-modal',
    backdrop: 'static',
  };

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private customApi: CUSTOMAPIService
  ) {}

  ngOnInit(): void {
    const modalRef = this.modalService.open(MidmarketWizardPopUpComponent, this.ngbMidMarketWizardModalOptions);
    modalRef.componentInstance.modalInstance = modalRef;
    modalRef.componentInstance.wizardData = this.wizardData;
    modalRef.componentInstance.updateWizard = true;
    modalRef.componentInstance.isClient = true;
    modalRef.componentInstance.completeClient.subscribe(event => {
      // update user status here and also complete or save the draft
      this.userService.updateMidMarketUser(MidMarketEnum.STEP_2, this.wizardData.id);
      this.loading = true;
      this.nextButtonHandler();
    });
  }

  nextButtonHandler(): void {
    setTimeout(() => {
      this.progress = 50;
    }, 1000);
    setTimeout(() => {
      this.progress = 70;
    }, 90000);
    setTimeout(() => {
      this.progress = 100;
      this.nextButtonClick.emit('welcome-video');
    }, 3000);
  }
}
