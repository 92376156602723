import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MidMarketEnum } from 'app/API.service';
import { FileService } from 'app/shared/file.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'cygov-welcome-video',
  templateUrl: './welcome-video.component.html',
  styleUrls: ['./welcome-video.component.scss'],
})
export class WelcomeVideoComponent implements OnInit {
  videoPath: string = '';
  videoTitle: string = 'BB Mid Market Intro.mp4';
  isVideoPlayed: boolean = false;
  videoPoster: string = 'management-video-poster.png';
  SOURCE_FOLDER: string = 'WALKTHROUGH_VIDEOS';
  IMAGES_SOURCE: string = 'assets/images/';
  @Output() nextButtonClick = new EventEmitter<any>();
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @Input() wizardData: any;

  constructor(
    private fileService: FileService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fileService
      .downloadFromS3({
        key: `${this.SOURCE_FOLDER}/${this.videoTitle}`,
        contentType: 'video/mp4',
      })
      .then(data => {
        this.videoPath = data;
      });
    this.videoPoster = `${this.IMAGES_SOURCE}${this.videoPoster}`;
  }

  async nextButtonHandler(): Promise<void> {
    await this.userService.updateMidMarketUser(MidMarketEnum.COMPLETED, this.wizardData.id);
    await this.userService.navigateMidMarketUser(true);
  }

  onPlay() {
    if (this.videoTitle.length) {
      this.isVideoPlayed = true;
      this.videoPlayer.nativeElement.play();
    }
  }
  onPause() {
    this.isVideoPlayed = false;
  }
}
