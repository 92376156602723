<div class="modal-body" *ngIf="newEntity">
  <div class="modal-header">
    <div class="user-icon-title-container">
      <span class="lato-20-n-vw message">
        {{ isEditMode ? 'EDIT CLIENT' : 'NEW CLIENT' }}
      </span>
    </div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
      (click)="modalResult.emit(true);">
    </div>
  </div>
  <div class='modal-content-body'>

    <div class="instance-settings-container">
      <div class="add-client-container">
        <div class="left-side">
          <div class="client-section-container">
            <div class="input-box-wrapper">
              <div class="label lato-18-n-vw text-white">Client Name</div>
              <input type="text" class="text-box" [(ngModel)]="newEntity.name" id="name"
                spellcheck="true" autocomplete="off" />
            </div>
            <div class="logo-upload-container">
              <div class="logo-container-wrapper" *ngIf="!uploadedLogo">
                <div class="add-logo-btn" (click)="uploadLogo.click()">
                  <div class="plus-sign">+</div>
                  <div class="label lato-18-n-vw">Add Logo</div>
                  <input #uploadLogo class="file-input" type="file" name="logo"
                    accept=".gif, .jpg, .jpeg, .bmp, .tif, .png" (change)="imgHandler($event)" />
                </div>

              </div>
              <div class="text-container logo-upload-icon center-upload-picture"
                *ngIf="this.uploadedLogo">
                <div class="logo">
                  <div class="image-container">
                    <img class="vendor-logo" *ngIf="uploadedLogo" [src]="uploadedLogo"
                      alt="{{ newEntity.name }}" />
                    <span class="lato-16-n-vw capitalize" *ngIf="!uploadedLogo">{{ newEntity.name
                      }}</span>
                  </div>
                </div>
                <div class="pencil-icon-wrapper" *ngIf="this.uploadedLogo">
                  <div class="pencil-icon" cygovSvgIcon [svgName]="'edit-button'"
                    (click)="uploadLogo.click()">
                    <input #uploadLogo class="file-input" type="file" name="logo"
                      accept=".gif, .jpg, .jpeg, .bmp, .tif, .png" (change)="imgHandler($event)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="industry-dropdown-container">
              <ng-container *ngIf="businessDropDown">
                <div class="drop-down-backdrop">
                  <div class="custom-dropdown-header">
                    <div class="label-name lato-18-n-vw text-white">Industry</div>

                    <div class="arrow-down lato-16-n-vw" cygovSvgIcon [svgName]="'arrow-down-type1'"
                      (click)="closeBusinessDetailDropdown()"></div>
                  </div>
                  <div class="search-container-wrapper">
                    <div cygovSvgIcon [svgName]="'new-search-icon'" class="search-icon"></div>
                    <input type="text" placeholder="Search" class="search-text-box"
                      (keyup)="searchInDropdownList($event)">
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div>
                  <div class=" label-name lato-18-n-vw text-white">Industry
                  </div>

                </div>
                <ng-container
                  *ngTemplateOutlet="dropDownInput; context: {$implicit: industryDropdown}">
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="solution-section-container">
            <div class="solution-section-header lato-18-n-vw text-white">Solution</div>
            <ng-container *ngFor="let item of solutionObject | keyvalue">
              <div class="solution-item">
                <cygov-checkbox [id]="item.key" [width]="1.9" [height]="1.9"
                  [useViewPortDimensions]="true" [isChecked]="item.value.checked"
                  (changed)="checkboxChangeHandler($event, item)"
                  (clicked)="beforeCheckBoxChnaged($event)">
                </cygov-checkbox>
                <div class="lato-16-n-vw toggle-text"
                  [ngClass]="{'item-text-bold': !item.value.checked}">{{
                  item.key }}</div>
              </div>

            </ng-container>
          </div>
          <div class="first-party-section-container"
            [ngClass]="{'first-party-section-container-disabled': !solutionObject['1st Party'].checked}">
            <div class="first-party-section-header lato-18-n-vw text-white">
              1st Party
            </div>
            <div class="attack-surface-toggle-container">
              <div class="heading-with-togggle">
                <div class="toggle-btn" [ngStyle]="{'z-index': businessDropDown ? '-1' : ''}">
                  <cygov-toggle-button [id]="'1'" [isChecked]="newEntity.domainScanEnabledForEntity"
                    (changed)="newEntity.domainScanned = !newEntity.domainScanned; newEntity.domainScanEnabledForEntity =  newEntity.domainScanned; newEntity.domainScanned === false && this.DomainScanValue = null">
                  </cygov-toggle-button>
                </div>
                <div class="lato-16-n-vw title-text"
                  [ngStyle]="{'opacity': !newEntity.domainScanned ? '.5' : ''}">Attack Surface</div>
              </div>
              <div class="attack-surface-input-container">

                <input type="text" [(ngModel)]="this.DomainScanValue"
                  class="input-filed lato-18-n-vw" [disabled]="!newEntity.domainScanned">
              </div>
            </div>
            <div class="horizontal-seprator-center"></div>

            <div class="ai-credits-container">
              <div class="heading-with-info-icon">
                <div class="heading lato-16-n-vw text-white">Number of AI Credits</div>
                <div cygovSvgIcon [svgName]="'info-board-icon'" class="info-icon-ai-credits"
                  [ngbTooltip]="'Available in packages of 100'" [placement]="'right'"
                  tooltipClass="tooltip-ai-credits"></div>
              </div>
              <ng-container
                *ngTemplateOutlet="counterItem; context: { $implicit: this.newEntity?.AI?.credits, label: 'AI_CREDITS' }">
              </ng-container>
            </div>
          </div>
          <div class="third-party-section-container"
            [ngClass]="{'third-party-section-container-disabled': !solutionObject['3rd Party'].checked}">
            <div class="third-party-section-header lato-18-n-vw text-white">
              3rd Party
            </div>
            <div class="vendor-credits-container">
              <div class="heading-with-info-icon">
                <div class="heading lato-16-n-vw text-white">Number of Vendors</div>
                <div cygovSvgIcon [svgName]="'info-board-icon'" class="info-icon-ai-credits"
                  [ngbTooltip]="'Available in packages of 50'" [placement]="'right'"
                  tooltipClass="tooltip-ai-credits"></div>
              </div>
              <ng-container
                *ngTemplateOutlet="counterItem; context: { $implicit: this.newEntity?.vendors?.vendorsTotalCount, label: 'VENDORS_COUNT' }">
              </ng-container>
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="framework-listings">
            <cygov-domain-frameworks-listings
              [isFirstPartyOn]="this.solutionObject['1st Party'].checked"
              [isThirdPartyOn]="this.solutionObject['3rd Party'].checked"
              [title]="'Choose framework'" [riskFrameWorkItems]="riskFrameWorkOpt"
              [complianceItems]="standardList" [vendorFrameworkItems]="vendorList"
              (clickOnSaveButton)="emitEntity($event)" [showCheckbox]="true"
              [isSelectAllVisible]="true" [toggleInactiveTextColor]="'#C5C9D1'"
              [showFrameworkCounts]="true"></cygov-domain-frameworks-listings>

          </div>
          <div class="delete-client-container" *ngIf="isEditMode">
            <div class="delete-client-button" (click)="deleteClientModal()">
              <div class="delete-icon" cygovSvgIcon [svgName]="'trash-icon'"></div>
              <div class="delete-text lato-16-n-vw">Delete Client</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div class="modal-content-footer">
    <div class="d-flex">
      <cygov-button class="button-margin" [buttonText]="isEditMode ? 'SAVE' : 'ADD CLIENT'"
        [buttonWidth]="isEditMode ? '6vw' : '6.6vw'" [buttonColor]="'#29a9eb'"
        [hoverColor]="'#0F92E3'" (click)="isValid(newEntity) && saveButtonClicked()"
        [textSize]="'14'">
      </cygov-button>
    </div>
  </div>
</div>


<!-- Custom dropdown content -->
<ng-template #dropDownInput let-obj let-idx="idx">
  <div class="custom-drop-down" [ngClass]="{ 'paddting-top-business': businessDropDown}">
    <div class="drop-down-label lato-16-n-vw" (click)="openBusinessDropdown(obj)"
      [ngClass]="{'drop-down-opened' : obj.isDropDownOpened,'placeholder-color': !obj.value}">

      <div class="drop-down-label-text text-ellipsis">{{obj.value ? obj.value:'Choose'}}</div>
      <div class="arrow-icon-right" cygovSvgIcon [svgName]="'arrow-right-type3'"></div>
    </div>
    <ng-container *ngIf="obj.isDropDownOpened">
      <div class="expanded-list" [ngClass]="{'expanded-list-opened' : obj.isDropDownOpened}">
        <div *ngFor="let item of obj.searchList; let indx=index" class="expanded-list-item">
          <span class="item lato-12-n-vw" (click)="saveBusinessDropdownItem(obj, indx)"
            [ngClass]="{'blue-text': obj.value === item}">{{item.name}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Custom dropdown content -->

<!-- counter -->
<ng-template #counterItem let-credits let-label="label">
  <div class="body-item-edit">
    <div class="counter">
      <div class="incrementor-decrementor-wrapper" (click)="decrement(label, inputFieldRef)"
        [ngClass]="{
       'decremental-disabled': 
       (label === 'AI_CREDITS' && credits === 100) || 
       (label === 'VENDORS_COUNT' && credits === 50)
     }">
        <div class="decrementor" cygovSvgIcon [svgName]="'arrow-down-type1'"></div>

      </div>
      <input class="risk-editable-input lato-12-n-vw" #inputFieldRef [disabled]="true"
        [value]="credits" type="number" min="0" />
      <div class="incrementor-decrementor-wrapper" (click)="increment(label, inputFieldRef)">
        <div class=" incrementor" cygovSvgIcon [svgName]="'arrow-up-type1'">

        </div>
      </div>
    </div>
  </div>
</ng-template>