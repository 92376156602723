import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecurityControlDetailsService {
  assignedUsers = new BehaviorSubject<any[]>(null);
  userChanged = new BehaviorSubject<boolean>(false);

  constructor() {}

  setAssignedUsers(users: any[]) {
    this.assignedUsers.next(users);
  }

  getAssignedUsers(): BehaviorSubject<any[]> {
    return this.assignedUsers;
  }

  setUserChangeStatus(change: boolean) {
    this.userChanged.next(change);
  }

  getUserChangeStatus(): BehaviorSubject<boolean> {
    return this.userChanged;
  }
}
