import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { UserService } from 'app/shared/user.service';
import { AuthService } from 'app/auth/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private authService:AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivate: canActivateChild was called');
    // maintaining state of the url
    this.authService.redirectURL=state.url
    return this.isAuthenticated();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivateChild: canActivateChild was called');
    // maintaining state of the url
    this.authService.redirectURL=state.url
    return this.isAuthenticated();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canLoad: canLoad was called');
     // maintaining state of the url
    this.authService.redirectURL= `/${segments.map(segment => segment.path).join('/')}`
    return this.isAuthenticated();
  }

  async isAuthenticated() {
    try {
      const isAuthenticated: boolean = await this.userService.isAuthenticated();
      if (isAuthenticated) {
        return true;
      }
      this.toastr.error('Unauthenticated');
    } catch (e) {
      this.logger.error('isAuthenticated - Error: ', e);
    }
    await this.router.navigate(['/login']);
    return Promise.reject(false);
  }
}
