import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { UtilsService } from 'app/shared/utils.service';
import JSZip from 'jszip';
import { S3FileInput, FileTypeEnum, GetAnswerQuery, GetAssignmentQuery, RiskAction } from 'app/API.service';
import { FileService } from '../file.service';
import { NGXLogger } from 'ngx-logger';
import { Question } from 'models/questionnaire.model';
import { QuestionnaireService } from 'app/questionnaire/questionnaire.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { RiskRegisteryEnum } from '../enums/risk-registery.enum';
import { AnswerEnum } from '../enums/answer.enum';
import { UserService } from '../user.service';
import { AppLevelRoleEnum } from '../enums/appLevelRoles.enum';

@Component({
  selector: 'cygov-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit, OnChanges {
  @Input() practices: any;
  @Input() questions: Question[];
  @Input() riskRegistery: RiskRegisteryEnum = RiskRegisteryEnum.Normal;
  @Input() showQuestionWithoutUser: boolean;
  @Input() userId: string;
  @Input() comment = '';
  @Input() answersFilter: string[] = [];
  @Input() assignments: GetAssignmentQuery[] = [];
  @Output() save = new EventEmitter<Question[]>();
  @ViewChild('uploadArtifact') uploadInput: ElementRef;

  answerEnum = AnswerEnum;
  hasApprovalPermission = false;
  confirmationMessage = 'Do you really want to delete the selected artifact?';
  selectedUserAnswer: GetAnswerQuery;
  possibleAnswers: AnswerEnum[];
  answers: any[] = []; // GetAnswerQuery[] = [];
  saving = false;
  changedAnswers: Map<number, GetAnswerQuery> = new Map();
  entityId: string;
  showError: boolean;
  langDirectionClass: string;

  constructor(
    private route: ActivatedRoute,
    private questionnaireService: QuestionnaireService,
    private fileService: FileService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private logger: NGXLogger,
    private userService: UserService
  ) {}

  ngOnChanges(): void {
    this.buildAnswerList();
  }

  ngOnInit(): void {
    this.langDirectionClass = this.questions[0].frameworkName.indexOf('ICDM') !== -1 ? 'rtl' : 'ltr';
    this.possibleAnswers = UtilsService.isBnBCyberSite
      ? Object.values(AnswerEnum).filter(val => val !== AnswerEnum.PARTIAL)
      : Object.values(AnswerEnum);

    this.buildAnswerList();

    this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (UtilsService.isCRB && this.userService.hasPermissionHierarchy(AppLevelRoleEnum.MSSP)) {
      this.hasApprovalPermission = true;
    }
  }

  buildAnswerList(): void {
    this.answers = [];
    this.questions.forEach((question, index) => {
      this.answers[index] = {} as GetAnswerQuery;
      if (this.userId) {
        if (question.answers && question.answers.length > 0) {
          this.answers[index] = question.answers[0];
        } else if (!!question.managerAnswers && question.managerAnswers.length > 0) {
          this.answers[index].answer = question.managerAnswers[0].answer;
        }
      } else {
        const allAnswers = question.answers;
        if (allAnswers && allAnswers.length > 0) {
          const averageAnswerValue = allAnswers.reduce((acc, curr) => acc + curr.answer, 0) / allAnswers.length;
          this.answers[index] = allAnswers[0];
          this.answers[index].answer = averageAnswerValue;
        }
      }
    });
  }

  openModalDeleteArtifact(userAnswer: GetAnswerQuery): void {
    this.selectedUserAnswer = userAnswer;
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.modalResult.message = this.confirmationMessage;
    modalRef.componentInstance.modalResult.subscribe(() => {
      this.deleteFile(this.selectedUserAnswer);
      modalRef.close();
    });
  }

  isDefined(variable: any): boolean {
    return UtilsService.isDefined(variable);
  }

  async onSave(): Promise<void> {
    this.saving = true;
    this.showError = false;
    const updatedQuestionsList: Question[] = [];
    const questionPromises = this.questions.map(this.answerQuestion(updatedQuestionsList));
    await Promise.all(questionPromises);
    this.saving = false;
    if (!this.showError) {
      this.toastr.success('Saved Successfully');
    }
    this.save.emit(updatedQuestionsList);
  }
  answerQuestion =
    (updatedQuestionsList: Question[]) =>
    async (question: Question, index): Promise<void> => {
      // Return if not answered
      if (!UtilsService.isDefined(this.answers[index].answer)) {
        return;
      }

      // If artifact files not uploaded when mandatory while answering a question YES
      let settingsObject = false;
      for (const prop in question) {
        if (prop === 'settings') {
          settingsObject = true;
          break;
        }
      }
      if (
        settingsObject &&
        question.settings.isArtifactsMandatory &&
        UtilsService.answerNumToEnum(this.answers[index].answer) === AnswerEnum.YES &&
        !UtilsService.isDefined(this.answers[index].file)
      ) {
        this.toastr.error('Artifacts are mandatory');
        this.showError = true;
        return;
      }

      // Initialize answer array if not exists
      if (!question.answers) {
        question.answers = [];
      }

      // Add answer in question answers array
      let userAnswer = this.answers[index];

      // Remove typename for updating s3 files fetched
      if (userAnswer.file) {
        userAnswer.file.forEach(input => delete input.__typename);
      }

      // Update answer if already answered or add new answer if unanswered
      userAnswer = {
        ...userAnswer,
        comment: `${this.comment}${userAnswer.comment ? userAnswer.comment : ''}\n`,
      };
      question.riskAction = this.comment ? RiskAction.REMEDIATED : null;
      userAnswer.isActionTaken = this.comment ? true : false;
      const userId = this.userId ? this.userId : (await this.userService.getCurrentUser()).id;
      await this.questionnaireService.addAnswerWithAssignments(question, userId, userAnswer, this.assignments);
      updatedQuestionsList.push(question);
    };

  approvalChanged(question: any, answer: AnswerEnum, questionAnswerIndex: number): void {
    question.managerAnswers[questionAnswerIndex].answer = this.answerEnumToNum(answer);
  }

  async onFilePicked(event: any, questionIdx: number): Promise<void> {
    let isAnyFileToUpload = false;
    if (event.target.files.length) {
      try {
        // compress file to zip and upload to s3 bucket
        // storing zipfiles multiple instances
        const zipFiles = (
          await Promise.all(
            Array.from(event.target.files).map(async (file: File) => {
              if (file.type === 'application/pdf') {
                if (await this.fileService.scanPdfFile(file)) {
                  this.toastr.warning('Your File Contain Malicious Content');
                  return null;
                }
              }
              if (FileService.validateFileSize(file)) {
                this.toastr.warning(file.name + ' Size can not be greater than 30MB');
                return null;
              } else if (!FileService.validateFileType(file, FileTypeEnum.ARTIFACTS)) {
                this.toastr.warning(file.name + ' File type not supported');
                return null;
              } else {
                isAnyFileToUpload = true;
                const fileInstance = new JSZip().file(file.name, file);
                return {
                  name: file.name,
                  file: (await fileInstance.generateAsync({ type: 'blob' })) as any,
                };
              }
            })
          )
        ).filter(file => UtilsService.isDefined(file));
        if (isAnyFileToUpload) {
          this.toastr.info('Uploading...');

          // upload zip files to s3 bucket
          const s3Inputs = zipFiles.map(this.mapS3FileInput);

          if (!UtilsService.isDefined(this.answers[questionIdx].file)) {
            this.answers[questionIdx].file = [];
          }

          await Promise.all(
            s3Inputs.map(async input => {
              this.answers[questionIdx].file.push(await this.fileService.uploadToS3(input));
            })
          );

          this.toastr.success('File successfully uploaded');
        }
      } catch (e) {
        this.logger.error('uploadFiles - Error: ', e);
        this.toastr.error('Failed to upload file');
      }
    }
  }

  mapS3FileInput = (file): S3FileInput => {
    return {
      name: file.name,
      entityId: this.entityId,
      fileType: FileTypeEnum.ARTIFACTS,
      contentType: file.file.type,
      body: file.file,
      date: Date.now(),
    };
  };

  async downloadFile(s3Inputs: S3FileInput[]): Promise<void> {
    try {
      this.toastr.info('Preparing file...');
      const blobs = await Promise.all(s3Inputs.map(this.createBlob));
      // Creating one zip to be downloaded
      const zip = new JSZip();
      blobs.forEach(file => {
        zip.file(file.name.concat('.zip'), file.blob);
      });
      const zipFile = await zip.generateAsync({ type: 'blob' });

      saveAs(zipFile, `Artifacts-${this.entityId}.zip`);
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed downloading file');
    }
  }

  createBlob = async (s3Input: S3FileInput): Promise<{ blob: any; name: string }> => {
    const url = await this.fileService.downloadFromS3(s3Input);
    const response = await fetch(url);
    const blob = await response.blob();
    return {
      blob,
      name: s3Input.name,
    };
  };

  async deleteFile(userAnswer: GetAnswerQuery): Promise<void> {
    try {
      await Promise.all(
        userAnswer.file.map(file => {
          return this.fileService.deleteFromS3(file);
        })
      );
      userAnswer.file = null;
      this.toastr.success('File deleted');
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed to delete file');
    }
  }

  answerNumToEnum(value: number): AnswerEnum {
    return UtilsService.answerNumToEnum(value);
  }

  answerEnumToNum(value: AnswerEnum): number {
    return UtilsService.answerEnumToNum(value);
  }
}
