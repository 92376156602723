<!-- prettier-ignore -->
<div class="circular-progress">
  <div class="target-incrementor" *ngIf="showIncrementCounter && isMaturityOption">
    <div class="mini-icon" cygovSvgIcon [svgName]="'mini-target'">
    </div>
    <!-- <div class="target-value"> {{capOrTool.target}}</div> -->
    <div class="target-value">
      <span *ngIf="!editNumber2" (click)="showEditNumber(true)">{{capOrTool.target}}</span>
      <input *ngIf="editNumber2" [maxlength]="1" [min]="1" name="target"
        [(ngModel)]="capOrTool.target" [max]="5" step="1" type="number" #numberInputTag2
        (focusout)="editNumber2 = false;" (ngModelChange)="valueCheck($event,true)">
    </div>
    <div class="number-change-buttons">
      <div class="incr-dcr-icon" cygovSvgIcon (click)="valueCheck(capOrTool.target+0.5,true)"
        [svgName]="'arrow-up-type1'">
      </div>
      <div class="incr-dcr-icon down-arrow" cygovSvgIcon
        (click)="valueCheck(capOrTool.target-0.5 , true)" [svgName]="'arrow-up-type1'"></div>
    </div>
  </div>
  <div class="score-incrementor" *ngIf="showIncrementCounter && isMaturityOption">
    <div class="digit-section">
      <span class="main-number lato-30-n-vw">
        <span *ngIf="!editNumber" (click)="showEditNumber()">{{capOrTool.maturity}}</span>
        <input *ngIf="editNumber" [maxlength]="1" [min]="1" name="maturity"
          [(ngModel)]="capOrTool.maturity" [max]="5" step="1" type="number" #numberInputTag
          (focusout)="editNumber = false;" (ngModelChange)="valueCheck($event)">
        <!-- {{capOrTool.maturity}} -->
      </span>
      <span class="total-number lato-13-n-vw">/ 5</span>
    </div>
    <div class="number-change-buttons">
      <div class="incr-dcr-icon" cygovSvgIcon (click)="valueCheck(capOrTool.maturity+0.5)"
        [svgName]="'arrow-up-type1'">
      </div>
      <div class="incr-dcr-icon down-arrow" cygovSvgIcon
        (click)="valueCheck(capOrTool.maturity-0.5)" [svgName]="'arrow-up-type1'"></div>
    </div>
  </div>
  <svg [ngClass]="scoreStrokeClassColor" id="svg" [attr.viewBox]="svgViewBox">
    <linearGradient id="gradientRed" x1="0" y1="0" x2="1" y2="1">
      <stop offset="0%" stop-color="#F75F57"></stop>
      <stop offset="100%" stop-color="#7C130E"></stop>
    </linearGradient>
    <linearGradient id="gradientYellow" x1="0" y1="0" x2="1" y2="1">
      <stop offset="0%" stop-color="#F7D857"></stop>
      <stop offset="100%" stop-color="#93780C"></stop>
    </linearGradient>
    <linearGradient id="gradientGreen" x1="0" y1="0" x2="1" y2="1">
      <stop offset="0%" stop-color="#18D360"></stop>
      <stop offset="100%" stop-color="#0C6A30"></stop>
    </linearGradient>

    <!----------------------BNB GRADIENTS------------------------->
    <linearGradient id="gradientBnbBlue" x1="0" y1="1" x2="0" y2="0">
      <stop offset="0%" stop-color="#3591ff"></stop>
      <stop offset="100%" stop-color="#0073fd"></stop>
    </linearGradient>
    <linearGradient id="gradientBnbGreen" x1="0" y1="1" x2="0" y2="0">
      <stop offset="0%" stop-color="#6fff70"></stop>
      <stop offset="100%" stop-color="#57e558"></stop>
    </linearGradient>
    <linearGradient id="gradientBnbGold" x1="0" y1="1" x2="0" y2="0">
      <stop offset="0%" stop-color="#ffe33c"></stop>
      <stop offset="100%" stop-color="#f4d100"></stop>
    </linearGradient>
    <linearGradient id="gradientBnbOrgange" x1="0" y1="1" x2="0" y2="0">
      <stop offset="0%" stop-color="#ff9926"></stop>
      <stop offset="100%" stop-color="#f98400"></stop>
    </linearGradient>
    <linearGradient id="gradientBnbRed" x1="0" y1="1" x2="0" y2="0">
      <stop offset="0%" stop-color="#ff2828"></stop>
      <stop offset="100%" stop-color="#fa0000"></stop>
    </linearGradient>
    <!----------------------BNB GRADIENTS------------------------->

    <defs>
      <filter id="shadow" x="0" y="0" width="250%" height="250%">
        <feOffset result="offOut" in="SourceAlpha" dx="0" dy="5" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2.5" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
    </defs>
    <circle *ngIf="backgroundCircle" cx="0" cy="0" r="0" stroke="none"
      [attr.fill]="circularProgressHover ? hoverFill : bgFill" filter="url(#shadow)" />
    <text class="lato-14-n-vw min-score" [attr.x]="minScoreAxisX" alignment-baseline="middle"
      text-anchor="middle" [attr.y]="minScoreAxisY" class='arc-min-score'>
      {{ MIN_SCORE }}
    </text>

    <text class="lato-14-n-vw max-score" [attr.x]="maxScoreAxisX" alignment-baseline="middle"
      text-anchor="middle" [attr.y]="maxScoreAxisY" class='arc-max-score'>
      {{ utils.totalScoreOf5(MAX_SCORE) }}
    </text>

    <path id="backgroundArc" fill="none" [attr.stroke]="remainingProgressColor"
      stroke-linecap="square" [attr.stroke-width]="BG_ARC_STROKE" [attr.d]="backgroundArcData"
      class='remaining-background-arc' />

    <path id="scoreArc" class="score-arc" cygovScoreColor [maxScore]="MAX_SCORE" [score]="score"
      [attr.d]="scoreArcData" [attr.stroke-width]="fgArcThickness ? fgArcThickness: FG_ARC_STROKE"
      fill="none" [attr.stroke]="applyGradient ? gradientID : ''" stroke-linecap="round" />

    <text [attr.x]="centerX" [attr.y]="centerY" text-anchor="middle">
      <tspan class="lato-66-n-vw custom-span-font-min" cygovScoreColor [maxScore]="MAX_SCORE"
        [score]="score" [isText]="true" [removeIsTextDependency]="removeIsTextDependency">
        {{ score ? utils.totalScoreOf5(score) : 0 }}</tspan>
      <tspan class="max-score lato-18-l-vw custom-span-font-max" [attr.maxScore]="MAX_SCORE"
        [attr.score]="score" [attr.isText]="true"
        [attr.removeIsTextDependency]="removeIsTextDependency">/ {{
        utils.totalScoreOf5(MAX_SCORE) }}
      </tspan>
    </text>
    <g *ngIf="showTarget && !isMidMarket && !thirdPartyCheck">
      <circle *ngIf="target && targetLabel" [attr.cx]="targetCoordinates.x"
        [attr.cy]="targetCoordinates.y" r="5" class="target-inner-circle"></circle>
      <circle *ngIf="target && targetLabel" [ngbTooltip]="targetLabel" container="body"
        [attr.cx]="targetCoordinates.x" [attr.cy]="targetCoordinates.y" r="11"
        class="target-outer-circle"></circle>
    </g>
    <g *ngIf="showAverage && !isMidMarket">
      <circle *ngIf="average && averageLabel" [attr.cx]="averageCoordinates.x"
        [attr.cy]="averageCoordinates.y" r="5" class="average-inner-circle"></circle>
      <circle *ngIf="average && averageLabel" [ngbTooltip]="averageLabel" container="body"
        [attr.cx]="averageCoordinates.x" [attr.cy]="averageCoordinates.y" r="11"
        class="average-outer-circle"></circle>
    </g>
  </svg>
</div>