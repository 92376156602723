/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import {
  GetUserQuery,
  APIService,
  CreateUserInput,
  RoleEnum,
  GetAssessmentQuery,
  GetAssignmentQuery,
  GetAnswerQuery,
  CreateAssignmentMutation,
  CreateAnswerInput,
  CreateAssignmentInput,
  UpdateAnswerInput,
  CreateCommentInput,
  UpdateCommentInput,
  GetCommentQuery,
  GetEntityQuery,
  ModelIDKeyConditionInput,
  GetQuestionnaireCompletionQuery,
  RiskAction,
  GetQuestionSettingsQuery,
  GetCustomTaskQuery,
  GetCustomTaskAssignmentQuery,
  CommentByAssessmentIdQuery,
  GetTaskActionQuery,
  UpdateTaskActionInput,
  CreateTaskActionInput,
  CreateCustomTaskAssignmentInput,
  GetAssessmentStandardFrameworkQuery,
  CreateCustomTaskAssignmentMutation,
  TaskAction,
  CustomTask,
  TaskStatusEnum,
  CreateVersionsInput,
  StandardType,
  AssignmentsByUserIdAndAssessmentQuery,
  ReviewerActions,
} from '../API.service';
import { EntityService, FrameworkScore } from 'app/shared/entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { FrameworkTreeService } from 'app/shared/framework-tree.service';
import { Question, Control, Comment } from 'models/questionnaire.model';
import { AssignmentTarget } from 'models/assignment-target.model';
import { ManagerActionEnum } from 'app/shared/enums/manager-action.enum';
import { AnswerEnum, FRAMEWORKS_ANSWERS } from 'app/shared/enums/answer.enum';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { NodeTypeEnum } from 'app/shared/enums/node-type.enum';
import { AuditorActionTypeEnum } from 'app/shared/enums/auditor-action-type.enum';
import { getLogMessage } from 'app/shared/helpers/logs.helper';
import { LogsKeyEnum } from 'app/shared/enums/logsKey.enum';
import { FileService } from 'app/shared/file.service';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { CUSTOMAPIService } from 'app/custom-api.service';

export interface FrameworkQuestionnaire {
  id?: string;
  name?: string;
  order?: number;
  children?: FrameworkQuestionnaire[];
  questions?: Question[];
  entityType?: string; // TODO: Replace with new enum
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  public isParticipant: boolean;
  isUploadingArtifacts: boolean = false; // to check if uploading the artifact or not
  artifactsFile: any = null; // to store artifacts file records if uploading artifacts
  StandardEnum: any;
  currentUpdatedAnswer: any;
  constructor(
    private authService: AuthService,

    private api: APIService,
    private entityService: EntityService,
    private customApi: CUSTOMAPIService,
    private frameworkService: FrameworkTreeService,
    private router: Router,
    private fileService: FileService,
    private domainFrameworkService: DomainFrameworkService
  ) {
    this.StandardEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.STANDARD_ENUM);
  }

  async getCRBParticipant(subEntityId: string): Promise<GetUserQuery> {
    let CRBParticipant: GetUserQuery = null;
    const subEntity = subEntityId ? await this.entityService.getEntity(subEntityId) : null;
    if (subEntity) {
      CRBParticipant = await this.authService.getUserByEmail(
        `${subEntity.name.split(' ').join('-')}-Participant-${subEntity.id}@example.com`
      );
      if (!CRBParticipant) {
        const newUser: CreateUserInput = {
          name: `${subEntity.name} Participant`,
          email: `${subEntity.name.split(' ').join('-')}-Participant-${subEntity.id}@example.com`,
          role: RoleEnum.PARTICIPANT,
          entityId: subEntityId,
        };
        CRBParticipant = await this.customApi.CreateUser(newUser);
      }
    }

    return CRBParticipant;
  }

  async buildQuestionnaireFromAssessment(
    assessment: GetAssessmentQuery,
    userId: string,
    frameworkName?: string,
    answerFilter?: string[]
  ): Promise<FrameworkQuestionnaire[]> {
    let filteredStandardList: GetAssessmentStandardFrameworkQuery[] = [];

    if (frameworkName) {
      filteredStandardList = [
        assessment.standardFrameworkList.items.find(
          standard => standard.key.toLowerCase() === frameworkName.toLowerCase()
        ),
      ] as GetAssessmentStandardFrameworkQuery[];
    } else {
      filteredStandardList = assessment.standardFrameworkList.items.map(
        standard => standard
      ) as GetAssessmentStandardFrameworkQuery[];
    }

    const answersPromise = this.getAnswersForAssessment(assessment.id, userId);
    const questionSettingsPromise = this.getQuestionSettingsForAssessment(assessment.id);
    const subEntityPromise = this.entityService.getEntity(assessment.childId);
    const assignmentPromise =
      userId === null
        ? this.getAssignmentsByAssessmentId(assessment.id)
        : this.getAssignmentsByUserId(userId, assessment.id);
    const [assignments, answers, questionSettings, subEntity] = await Promise.all([
      assignmentPromise,
      answersPromise,
      questionSettingsPromise,
      subEntityPromise,
    ]);
    const subEntityQuestionSettings = subEntity.defaultQuestionSettings;

    let frameworkTrees = await Promise.all(
      filteredStandardList.map(async standard => {
        return this.frameworkService.getAssessmentTreeFromS3(assessment.id, standard);
      })
    );
    const answerMap: Map<string, GetAnswerQuery[]> = UtilsService.groupBy(answers, 'questionId');
    const settingsMap: Map<string, GetQuestionSettingsQuery> = UtilsService.mapBy(questionSettings, 'questionId');
    frameworkTrees.forEach((frameworkTree, index) => {
      if (UtilsService.isDefined(frameworkTree?.children.filter(child => child.entityType !== NodeTypeEnum.GROUP))) {
        frameworkTree.children = frameworkTree.children.filter(child => child.entityType !== NodeTypeEnum.GROUP);
        frameworkTree.name = filteredStandardList[index].key;
        frameworkTree.type = filteredStandardList[index].type;
        this.filterTreeOnAssignments(
          frameworkTree.name,
          assignments,
          frameworkTree,
          assessment.id,
          answerMap,
          settingsMap,
          answerFilter,
          subEntityQuestionSettings
        );
      }
    });
    frameworkTrees = frameworkTrees.filter(tree => tree?.children);

    return frameworkTrees;
  }

  async getAnswer(questionId: string, userId: string): Promise<GetAnswerQuery> {
    const userAnswers = await this.customApi.AnswersByQuestionIdAndUser(questionId, { eq: userId });
    if (userAnswers.items.length > 0) {
      return userAnswers.items[0];
    } else {
      return null;
    }
  }

  async getAnswersForAssessment(assessmentId: string, userId?: string): Promise<GetAnswerQuery[]> {
    let results = [];
    let nextToken = null;
    let condition: ModelIDKeyConditionInput;
    if (userId) {
      condition = { eq: userId };
    }
    do {
      const result = await this.customApi.AnswersByAssessmentIdAndUserId(
        assessmentId,
        condition,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getQuestionSettingsForAssessment(assessmentId: string): Promise<GetQuestionSettingsQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.QuestionSettingsByAssessmentId(assessmentId, null, null, 10000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getAnswers(questionId: string): Promise<GetAnswerQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.AnswersByQuestionIdAndUser(questionId, null, null, null, 1000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getCommentsByAssessment(assessmentId: string): Promise<CommentByAssessmentIdQuery[]> {
    // CommentByAssessmentId
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.CommentByAssessmentId(assessmentId, null, null, 10000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getAssignmentsByAssessmentId(assessmentId: string): Promise<GetAssignmentQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.AssignmentsByAssessmentId(assessmentId, null, null, 10000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getTaskActionsById(questionId: string): Promise<GetTaskActionQuery> {
    const result = await this.customApi.GetTaskAction(questionId);
    delete result.__typename;
    result.controlsConnected.map(c => delete c.__typename);
    return result;
  }

  async getCustomTaskById(questionId: string): Promise<GetCustomTaskQuery> {
    try {
      const result = await this.customApi.GetCustomTask(questionId);
      delete result.__typename;
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async getCustomTasksByAssessmentId(assessmentId: string): Promise<GetCustomTaskQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.CustomTasksByAssessmentId(assessmentId, null, null, 10000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getCustomTaskAssignmentsByAssessmentId(assessmentId: string): Promise<GetCustomTaskAssignmentQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.CustomTaskAssignmentsByAssessmentId(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getQuestionnaireCompletionByAssessmentId(assessmentId: string): Promise<GetQuestionnaireCompletionQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.CompletionByAssessment(assessmentId, null, null, 10000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getAssignmentsByUserId(userId: string, assessmentId?: string): Promise<GetAssignmentQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.AssignmentsByUserIdAndAssessment(
        userId,
        { eq: assessmentId },
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  setIsUserParticipant(isParticipant: boolean): void {
    this.isParticipant = isParticipant;
  }

  filterTreeOnAssignments(
    frameworkName: string,
    assignments: GetAssignmentQuery[],
    node: any,
    assessmentId: string,
    answerMap: Map<string, GetAnswerQuery[]>,
    settingsMap: Map<string, GetQuestionSettingsQuery>,
    answerFilter?: string[],
    subEntityQuestionSettings?: any
  ): boolean {
    if (node.children) {
      node.children = node.children.filter(childNode =>
        this.filterTreeOnAssignments(
          frameworkName,
          assignments,
          childNode,
          assessmentId,
          answerMap,
          settingsMap,
          answerFilter,
          subEntityQuestionSettings
        )
      );

      if (!node.children || node.children.length === 0) {
        for (const prop of Object.getOwnPropertyNames(node)) {
          delete node[prop];
        }
        return false;
      } else {
        return true;
      }
    } else if (node.questions) {
      node.questions.forEach(question => {
        const questionAssignments = assignments.filter(assignment => {
          if (question.left >= assignment.left && question.right <= assignment.right) {
            return true;
          } else {
            return false;
          }
        });
        if (settingsMap[question.id]) {
          question.settings = settingsMap[question.id];
        } else {
          question.settings = subEntityQuestionSettings ? subEntityQuestionSettings : {};
        }
        question.parentId = node.id;
        question.assignments = questionAssignments;
        question.answers = answerMap[question.id];
        question.assessmentId = assessmentId;
        question.assessmentIds = [assessmentId];
        question.frameworkName = frameworkName;
      });
      node.questions = node.questions.filter(question => question.assignments && question.assignments.length > 0);
      if (!!answerFilter && answerFilter.length > 0) {
        node.questions.forEach(question => {
          if (question.answers) {
            question.answers = question.answers.filter(
              userAnswer =>
                userAnswer.answer !== null && answerFilter.includes(UtilsService.answerNumToEnum(userAnswer.answer))
            );
          }
        });
        node.questions = node.questions.filter(question => !!question.answers && question.answers.length > 0);
      }
      if (!node.questions || node.questions.length === 0) {
        return false;
      }
      return true;
    }
  }

  getQuestionsFromTree(node: FrameworkQuestionnaire): Question[] {
    if (node.children) {
      const questions = node.children.map(childNode => this.getQuestionsFromTree(childNode));
      return questions.flat();
    } else if (node.questions) {
      return node.questions.map(question => {
        question.controlName = node.name;
        return question;
      });
    }
  }

  getQuestionsFromTreeForRemediation(
    node: FrameworkQuestionnaire[],
    frameworkAssessment: FrameworkScore,
    answeredQuestions: GetAnswerQuery[],
    subEntityList: GetEntityQuery[],
    assignmentsMap: any,
    taskAssignmentMap: any,
    settingsMap: Map<string, GetQuestionSettingsQuery>,
    commentsMap: Map<string, Comment[]>
  ): Question[] {
    // if (node.children) {
    //   const questions = node.children.map(childNode =>
    //     this.getQuestionsFromTreeForRemediation(
    //       childNode,
    //       frameworkAssessment,
    //       answeredQuestions,
    //       subEntityList,
    //       assignmentsMap,
    //       taskAssignmentMap,
    //       settingsMap,
    //       commentsMap,
    //       userId
    //     )
    //   );
    //   return questions.flat();
    // }
    return node
      .map(singleNode => {
        if (singleNode.questions) {
          return singleNode.questions.reduce(
            this.filterUnAnsweredControlQuestions(
              answeredQuestions,
              singleNode,
              frameworkAssessment,
              subEntityList,
              assignmentsMap,
              taskAssignmentMap,
              settingsMap,
              commentsMap
            ),
            []
          );
        }
        return [];
      })
      .flat();
  }

  filterUnAnsweredControlQuestions =
    (
      answeredQuestions: GetAnswerQuery[],
      node,
      frameworkAssessment: FrameworkScore,
      subEntityList: GetEntityQuery[],
      assignmentsMap: any,
      taskAssignmentMap: any,
      settingsMap: Map<string, GetQuestionSettingsQuery>,
      commentsMap: Map<string, Comment[]>
    ) =>
    (acc: Question[], currQuestion: Question): Question[] => {
      const assessmentAssignments = assignmentsMap[frameworkAssessment.assessmentId]
        ? assignmentsMap[frameworkAssessment.assessmentId]
        : [];
      if (taskAssignmentMap[currQuestion.id]) {
        currQuestion.taskAssignments = taskAssignmentMap[currQuestion.id];
      }
      if (
        !this.isQuestionRemediated(
          answeredQuestions,
          frameworkAssessment.assessmentId,
          currQuestion,
          assessmentAssignments
        )
      ) {
        acc.push({
          ...currQuestion,
          parentId: node.id,
          assessmentIds: [frameworkAssessment.assessmentId],
          standards: this.getFrameworkNameByType({
            name: frameworkAssessment.name,
            type: frameworkAssessment.type,
          }),
          frameworkName: frameworkAssessment.name,
          controlName: node.name,
          controlLabel: node.label,
          rootId: frameworkAssessment.rootId,
          childIds: [frameworkAssessment.childId],
          childNames: [subEntityList.find(subEntity => subEntity.id === frameworkAssessment.childId).name],
          answers: answeredQuestions.filter(
            answer => answer.assessmentId === frameworkAssessment.assessmentId && answer.questionId === currQuestion.id
          ),
          comments: commentsMap[currQuestion.id] ? commentsMap[currQuestion.id] : [],
          settings:
            settingsMap[currQuestion.id] &&
            settingsMap[currQuestion.id].assessmentId === frameworkAssessment.assessmentId
              ? settingsMap[currQuestion.id]
              : [],
        });
      }
      return acc;
    };

  excludeManagers = (managerIds: string[], assignmentsCount: number, assignedUserId: string): boolean => {
    if (assignmentsCount > 1) {
      if (managerIds && managerIds.includes(assignedUserId)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  isQuestionRemediated = (
    answeredQuestions: GetAnswerQuery[],
    assessmentId: string,
    question: Question,
    assignments: GetAssignmentQuery[],
    isRemediation: boolean = false
  ): boolean => {
    if (isRemediation && question.answers) {
      question.left = question.answers[0].left;
      question.right = question.answers[0].right;
      const ids = question.id.split('#');
      question.id = ids[1];
    } else if (isRemediation && !question.answers) {
      return question.riskAction === 'IGNORED';
    }
    const questionAssignments = assignments.filter((assignment: GetAssignmentQuery) => {
      return (
        assignment.left <= question.left &&
        assignment.right >= question.right &&
        assignment.user &&
        assignment.user.role === RoleEnum.PARTICIPANT
      );
    });

    let totalAssignments = questionAssignments.length;
    question.assignments = questionAssignments;

    const questionAnswers = answeredQuestions.filter(
      answer =>
        answer.assessmentId === assessmentId &&
        answer.questionId === question.id &&
        UtilsService.isDefined(answer.answer)
    );

    // authenticated user role is not participant
    if (!questionAnswers || (!questionAnswers.length && !this.isParticipant)) {
      return true;
    }

    if (question.taskAssignments && question.taskAssignments.length > 0) {
      console.log('taskAssignments', question.taskAssignments);
      console.log('answers', questionAnswers);
    }
    /**
     * Check if any user completed the task
     */
    if (
      questionAnswers &&
      questionAnswers.length > 0 &&
      question.taskAssignments &&
      question.taskAssignments.length > 0
    ) {
      // Find answers from users who are assigned to this task
      const taskCompletionAnswers = questionAnswers.filter(answer =>
        question.taskAssignments.some(assignment => assignment.userId === answer.userId)
      );
      console.log('task completion answers', taskCompletionAnswers);
      // Did any user complete this task?
      if (taskCompletionAnswers.some(answer => answer.answer && answer.answer === 10)) {
        return true;
      } else {
        return false;
      }
    }

    const nonParticipantAnswers = questionAnswers.filter(
      answer => answer.user && answer.user.role !== RoleEnum.PARTICIPANT
    );

    let totalScore;
    if (nonParticipantAnswers.length > 0) {
      totalScore = UtilsService.sortByProp(nonParticipantAnswers, 'date', SortDirectionEnum.DESCENDING)[0].answer;
    } else {
      const notApplicableAnswersCount = questionAnswers.filter(answer => answer.answer === 0).length;
      totalAssignments -= notApplicableAnswersCount;

      if (!totalAssignments) {
        return false;
      }

      if (question.settings && question.settings.isCollaborative) {
        if (questionAnswers.length === 0) {
          return false;
        }
        totalScore = UtilsService.sortByProp(questionAnswers, 'date', SortDirectionEnum.DESCENDING)[0].answer;
      } else {
        totalScore = questionAnswers.reduce((acc, curr) => {
          acc += curr.answer;
          return acc;
        }, 0);
        totalScore /= totalAssignments;
      }
    }
    return totalScore !== 0 && totalScore < 10 ? false : true;
  };

  getControlsFromTree(node: FrameworkQuestionnaire[]): Control[] {
    if (!node || node.length === 0) {
      return [];
    }
    const nestedControls = node.map(item => {
      // TODO: Replace this with Enum

      if (item.entityType === 'CONTROL') {
        return item;
      } else {
        return this.getControlsFromTree(item.children);
      }
    });
    return nestedControls.flat() as Control[];
  }

  getCompletionStatus(tree: FrameworkQuestionnaire, userId?: string): { completedCount: number; totalCount: number } {
    const questions = this.getQuestionsFromTree(tree);
    const totalCount = questions.length;
    let completedCount = 0;

    questions.forEach(question => {
      if (question.answers) {
        const userAnswers: GetAnswerQuery[] = UtilsService.getUniqueListBy(question.answers, 'userId');
        if (userAnswers) {
          if (userId) {
            if (userAnswers.length > 0) {
              completedCount++;
            }
          } else {
            if (userAnswers.length === question.assignments.length) {
              completedCount++;
            }
          }
        }
      }
    });

    return { totalCount, completedCount };
  }

  getCompletionStatusMultiple(
    tree: FrameworkQuestionnaire[],
    userId?: string
  ): { completedCount: number; totalCount: number } {
    const statusList = tree.map(node => this.getCompletionStatus(node, userId));
    const totalStatus = statusList.reduce(
      (acc, curr) => {
        acc.completedCount += curr.completedCount;
        acc.totalCount += curr.totalCount;
        return acc;
      },
      { completedCount: 0, totalCount: 0 }
    );
    return totalStatus;
  }

  getAssignedUsersList(assignments: GetAssignmentQuery[], question: Question): GetAssignmentQuery[] {
    return assignments
      .filter(assignment => {
        const typename = assignment.__typename as string;
        return (
          (typename !== 'Assignment' && typename !== 'DisableAssignment') ||
          ((typename === 'Assignment' || typename === 'DisableAssignment') &&
            question.assessmentIds &&
            question.assessmentIds.includes(assignment.assessmentId) &&
            question.left &&
            question.right &&
            assignment.left <= question.left &&
            assignment.right >= question.right)
        );
      })
      .reduce((users, currentAssignment) => {
        if (currentAssignment.userId) {
          users.push(currentAssignment);
        }
        return users;
      }, [] as GetAssignmentQuery[]);
  }

  updateTaskAction(
    userTaskAction: GetTaskActionQuery,
    taskActionToUpdate: GetTaskActionQuery,
    userId,
    questionId?: string
  ): Promise<GetTaskActionQuery> {
    const updateTaskAction: UpdateTaskActionInput = {
      id: taskActionToUpdate.id,
      title: taskActionToUpdate.title,
      description: taskActionToUpdate.description,
      maturity: taskActionToUpdate.maturity,
      impact: taskActionToUpdate.impact,
      controlsConnected: taskActionToUpdate.controlsConnected,
      type: taskActionToUpdate.type ? taskActionToUpdate.type : 'question',
      tabName: taskActionToUpdate.tabName,
      date: new Date().getTime(),
      score: userTaskAction.score,
      managerAction: userTaskAction.action,
      action: taskActionToUpdate.action,
      assessmentId: taskActionToUpdate.assessmentId,
      questionIds: questionId ? [questionId] : taskActionToUpdate.questionIds,
      userId,
      frameworkName: taskActionToUpdate.frameworkName,
    };
    return this.customApi.UpdateTaskAction(updateTaskAction);
  }

  updateCustomTask(
    userCustomTask: GetCustomTaskQuery,
    customTaskToUpdate: GetCustomTaskQuery
  ): Promise<GetCustomTaskQuery> {
    return this.customApi.UpdateCustomTask({
      id: customTaskToUpdate.id,
      status: userCustomTask.status,
      assessmentId: userCustomTask?.assessmentId,
    });
  }

  updateAnswer(
    question: Question,
    userAnswer: GetAnswerQuery,
    answerToUpdate: GetAnswerQuery,
    questionId?: string,
    auditStatus?: string
  ): Promise<GetAnswerQuery> {
    const updateAnswer: UpdateAnswerInput = {
      id: answerToUpdate.id,
      answer: userAnswer.answer,
      answerName: userAnswer.answerName,
      comment: userAnswer.comment ? userAnswer.comment : null,
      assessmentId: answerToUpdate.assessmentId,
      questionId: questionId ? questionId : answerToUpdate.questionId,
      userId: answerToUpdate.userId,
      file: userAnswer.file,
      riskAction: question.riskAction,
      date: Date.now(),
      isActionTaken: userAnswer.isActionTaken,
      isUptodate: userAnswer.isUptodate,
      auditStatus,
      reviewerActionStatus: question.settings.isApprovalRequired ? ReviewerActions.WAITING : null,
      origin: null,
    };
    return this.customApi.UpdateAnswer(updateAnswer, { assessmentId: { eq: updateAnswer.assessmentId } });
  }

  async deleteAnswersFiles(question: Question, filesIds: string[]): Promise<void> {
    const promises = [];
    question.allAnswers.forEach((answer, answerIndex) => {
      if (answer.file) {
        answer.file.forEach((eachFile, fileIndex) => {
          if (eachFile.__typename) {
            delete eachFile.__typename;
          }
          if (filesIds.includes(eachFile.id)) {
            question.allAnswers[answerIndex].file.splice(fileIndex, 1);
          }
        });
      }
      promises.push(this.updateAnswer(question, answer, answer));
    });
    await Promise.all(promises);
  }

  createAssignment = async (
    target: AssignmentTarget,
    userId: string,
    assessmentId: string
  ): Promise<CreateAssignmentMutation> => {
    const assignment: CreateAssignmentInput = {
      id: uuid(),
      userId,
      assessmentId,
      targetId: assessmentId,
      left: target.left,
      right: target.right,
    };
    const createdAssignment = await this.customApi.CreateAssignment(assignment);
    return createdAssignment;
  };

  createCustomTaskAssignment = async (
    targetId: string,
    assessmentId: string,
    userId: string
  ): Promise<CreateCustomTaskAssignmentMutation> => {
    const customTaskAssignment: CreateCustomTaskAssignmentInput = {
      id: uuid(),
      userId,
      assessmentId,
      targetId,
    };
    const createdCustomTaskAssignment = await this.customApi.CreateCustomTaskAssignment(customTaskAssignment);
    return createdCustomTaskAssignment;
  };

  createTaskAction = async (question, userTaskAction: GetTaskActionQuery, userId): Promise<GetTaskActionQuery> => {
    const createTaskAction: CreateTaskActionInput = {
      id: `${question.assessmentId}#${question.id}`,
      score: userTaskAction.score,
      managerAction: userTaskAction.action,
      action: question.action,
      assessmentId: question.assessmentId,
      questionIds: [question.id],
      userId,
      frameworkName: question.frameworkName,
      title: question.title,
      description: question.description,
      maturity: question.maturity,
      impact: question.impact,
      controlsConnected: [
        {
          id: question.controlId,
          label: question.controlLabel,
          name: question.controlName,
        },
      ],
      type: question.type ? question.type : 'question',
      tabName: Object.keys(this.StandardEnum)
        .map(key => this.StandardEnum[key])
        .includes(question.frameworkName)
        ? 'Compliance'
        : 'Readiness',
      date: new Date().getTime(),
    };
    return await this.customApi.CreateTaskAction(createTaskAction);
  };

  createAnswer = async (
    question: Question,
    userAnswer: GetAnswerQuery,
    userAssignment: CreateAssignmentMutation,
    questionId?: string,
    auditStatus?: string
  ): Promise<GetAnswerQuery> => {
    const createAnswer: CreateAnswerInput = {
      id: uuid(),
      assessmentId: userAssignment.assessmentId,
      assignmentID: userAssignment.id,
      userId: userAssignment.userId,
      answer: userAnswer.answer,
      answerName: userAnswer.answerName,
      comment: userAnswer.comment ? userAnswer.comment : null,
      file: this.isUploadingArtifacts ? this.artifactsFile : userAnswer.file,
      questionId: questionId ? questionId : question.id,
      date: Date.now(),
      frameworkName: question.frameworkName,
      riskAction: question.riskAction,
      left: question.left,
      right: question.right,
      isActionTaken: userAnswer.isActionTaken,
      isUptodate: userAnswer.isUptodate,
      auditStatus,
      reviewerActionStatus: question.settings.isApprovalRequired ? ReviewerActions.WAITING : null,
    };
    // updating the name of the framework from combined framework to original framework if it is:
    // 1- third party
    // 2- combined compliance framework/artifact
    if (question.frameworkName.toUpperCase() === StandardType.COMPLIANCE_FRAMEWORK) {
      createAnswer.frameworkName = question.controlName
        .replace(' Certification', '')
        .split(' ')
        .join('_')
        .toUpperCase();
    } else if (question.frameworkName.toUpperCase() === StandardType.ARTIFACT) {
      createAnswer.frameworkName = question.controlName.replace(' Documents', '').split(' ').join('_').toUpperCase();
    }
    return await this.customApi.CreateAnswer(createAnswer);
  };

  async createComment(comment: CreateCommentInput): Promise<GetCommentQuery> {
    return await this.customApi.CreateComment(comment);
  }

  async updateComment(comment: UpdateCommentInput): Promise<GetCommentQuery> {
    return await this.customApi.UpdateComment(comment);
  }

  async getQuestionUserAssignments(question: Question, userId, assessmentId): Promise<GetAssignmentQuery> {
    // this code is specific for assignment creation/updation when user answer from remediation
    // need to optimize it
    if (!question?.assignments) {
      question.assignments = [];
      const assessmentIdFilter = {
        eq: question?.assessmentIds[0],
      };
      const assignments: AssignmentsByUserIdAndAssessmentQuery = await this.customApi.AssignmentsByUserIdAndAssessment(
        userId,
        assessmentIdFilter
      );

      const requiredQuesAssignment = assignments?.items?.filter(assignment => {
        if (assignment?.left <= question?.left && assignment?.right >= question?.right) {
          return assignment;
        }
      });
      question.assignments = requiredQuesAssignment;
    }

    let userAssignments: GetAssignmentQuery[] = [];
    if (question.assignments?.length) {
      userAssignments = question.assignments.filter(
        assignment => assignment.userId === userId && assignment.assessmentId === assessmentId
      );
    }
    return userAssignments && userAssignments.length > 0 ? userAssignments[0] : null;
  }

  getQuestionUserCustomTaskAssignments(question: Question, userId, assessmentId): GetCustomTaskAssignmentQuery {
    let userAssignments: GetCustomTaskAssignmentQuery[] = [];
    if (question.taskAssignments) {
      userAssignments = question.taskAssignments.filter(
        assignment => assignment.userId === userId && assignment.assessmentId === assessmentId
      );
    }
    return userAssignments && userAssignments.length > 0 ? userAssignments[0] : null;
  }

  /**
   * Function to get Log message based on answer for BNB
   * @param score
   * @returns - answer option against the given score
   */
  getLogMessagesForBNB(score: number): string {
    if (UtilsService.isDefined(score)) {
      if ([4, 6, 8, 10].includes(score)) {
        return LogsKeyEnum.YES_ANSWER;
      } else if ([2, 2.5, 5, 7.5, 9].includes(score)) {
        return LogsKeyEnum.PARTIAL_ANSWER;
      } else if ([0, 1].includes(score)) {
        return LogsKeyEnum.NO_ANSWER;
      }
    }
  }

  /**
   * Returns the log message key based on the provided score for Beecher and MidMarket.
   *
   * @param {number} score - The score to determine the log message key.
   * @returns {string} The log message key corresponding to the given score.
   *
   * @remarks
   * - If the score is 0, it returns `LogsKeyEnum.NA_ANSWER`.
   * - If the score is 1, it returns `LogsKeyEnum.NO_ANSWER`.
   * - If the score is 10, it returns `LogsKeyEnum.YES_ANSWER`.
   * - If the score is not defined or does not match any case, it returns `null`.
   */
  getLogMessagesForBeecherAndMidMarket(score: number): string {
    if (UtilsService.isDefined(score)) {
      switch (score) {
        case 0:
          return LogsKeyEnum.NA_ANSWER;
        case 1:
          return LogsKeyEnum.NO_ANSWER;
        case 10:
          return LogsKeyEnum.YES_ANSWER;
        default:
          return null;
      }
    }
  }

  addAnswerWithAssignments = async (
    question: Question,
    userId: string,
    userAnswer: GetAnswerQuery,
    assignments: GetAssignmentQuery[],
    questionId?: string,
    auditStatus?: string,
    targetId?: string,
    type?: string,
    currUserName?: string,
    isAuditStatusSelected: boolean = false,
    isVersion: boolean = true,
    isArtifactUpload: boolean = false
  ): Promise<any> => {
    try {
      let questionAnswer = null;
      const questionAnswers: GetAnswerQuery[] = UtilsService.isDefined(question.answers)
        ? UtilsService.copyArrayDeep(question.answers)
        : [];
      assignments = question?.assignments;
      for (const assessmentId of question.assessmentIds) {
        let assignment = await this.getQuestionUserAssignments(question, userId, assessmentId);
        if (!assignment) {
          assignment = await this.createAssignment(
            { targetId: questionId ? questionId : question.id, left: question.left, right: question.right },
            userId,
            assessmentId
          );
          if (assignment) {
            const message = getLogMessage(LogsKeyEnum.SELF_ASSIGNMENT, currUserName);
            this.entityService.addLog(targetId, assessmentId, type, message);
          }
          assignments.push(assignment);
        } else if (assignment && assignment.disable) {
          // scenario: If a user remove an assignment from question so user avatar become disable
          // If the same user whose assignment is removed, again answered on the same question so avatar is not getting enabled.
          const disabledAssignmentIndex = assignments.findIndex(a => a.id === assignment.id);
          if (disabledAssignmentIndex > -1) {
            assignments[disabledAssignmentIndex].disable = false;
            await this.customApi.UpdateDisableAssignment(
              { id: assignment.id, disable: assignment.disable },
              {
                assessmentId: { eq: assignment.assessmentId },
              }
            );
          }
        }
        // Array of Non-Partial Answers Values
        const ansNonPartialValues = [0, 1, 10, 998, 999];
        let logKey = null;
        if (UtilsService.isDefined(userAnswer.answer) && userAnswer.answer >= 0) {
          if (ansNonPartialValues.includes(userAnswer.answer)) {
            switch (userAnswer.answer) {
              case 999:
                logKey = LogsKeyEnum.NA_ANSWER;
                break;
              case 0:
                logKey = LogsKeyEnum.NO_ANSWER;
                break;
              case 10:
                logKey = LogsKeyEnum.YES_ANSWER;
                break;
              default:
                logKey = null;
                break;
            }
          } else {
            logKey = LogsKeyEnum.PARTIAL_ANSWER;
          }
        } else if (isArtifactUpload) {
          logKey = LogsKeyEnum.ARTIFACT_UPLOAD;
        }
        if (UtilsService.isBnB) {
          logKey = this.getLogMessagesForBNB(userAnswer.answer);
        }

        //* Handling the scenario for Beecher and MidMarket
        if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
          logKey = this.getLogMessagesForBeecherAndMidMarket(userAnswer.answer);
        }

        if (FRAMEWORKS_ANSWERS[question.frameworkName]) {
          logKey = userAnswer.answerName;
        }

        /**
         * Getting all previous files from the current answer object and saving only current users artifacts.
         * If artifacts is getting upload, then pass the files otherwise just update/create the answer.
         */

        if (userAnswer?.file) {
          const previousArtifactsFile = JSON.parse(JSON.stringify(userAnswer?.file));
          this.artifactsFile = previousArtifactsFile.filter(f => f?.userId === userId);
          this.artifactsFile = this.artifactsFile?.map(f => {
            delete f?.userId;
            return f;
          });
          userAnswer.file = userAnswer?.file.map((f: any) => {
            delete f?.userId;
            return f;
          });
          this.isUploadingArtifacts = !!this.artifactsFile?.length;
        }
        let answerToUpdate;
        if (question?.answers?.length) {
          answerToUpdate = question.answers.find(
            answer =>
              answer?.user?.id === userId && answer?.left >= assignment.left && answer?.right <= assignment.right
          );
        }
        if (!answerToUpdate && question?.managerAnswers?.length) {
          answerToUpdate = question.managerAnswers.find(
            answer =>
              answer?.user?.id === userId &&
              answer?.left &&
              answer?.right &&
              answer?.left >= assignment?.left &&
              answer?.right <= assignment?.right
          );
        }
        if ((question?.answers?.length > 0 || question?.managerAnswers?.length > 0) && answerToUpdate) {
          const updatedAnswer = await this.updateAnswer(question, userAnswer, answerToUpdate, questionId, auditStatus);
          questionAnswer = { ...updatedAnswer };
          this.currentUpdatedAnswer = { ...updatedAnswer };

          // data against questionId in QuestionData Table exists only if tags against that questions exists
          // check before updation either the data is exists in QuestionData Table or not
          const questionData = await this.customApi.GetQuestionData(question.id);
          if (questionData) {
            await this.customApi.UpdateQuestionData(
              { id: question.id, score: userAnswer.answer },
              { assessmentId: { eq: question.assessmentIds[0] } }
            );
          }
          updatedAnswer.file = userAnswer.file;
          if (updatedAnswer && auditStatus === null && !isAuditStatusSelected && !isArtifactUpload) {
            const message = getLogMessage(
              logKey,
              currUserName,
              null,
              null,
              ansNonPartialValues.includes(userAnswer.answer) ? null : userAnswer.answer
            );
            this.entityService.addLog(targetId, assessmentId, type, message);
            if (isVersion) {
              await this.createAnswerVersion(updatedAnswer, targetId, assessmentId, type);
            }
          }
          const existingAnswerIndex = questionAnswers.findIndex(
            answerItem => answerItem.userId === updatedAnswer.userId
          );
          if (existingAnswerIndex > -1) {
            questionAnswers[existingAnswerIndex] = updatedAnswer;
          } else {
            questionAnswers.push(updatedAnswer);
          }
        } else {
          // // handling the scenario for the combined compliances and artifacts
          let complianceOrArtifact = null;
          if (
            question?.frameworkName === StandardType.COMPLIANCE_FRAMEWORK ||
            question?.frameworkName === StandardType.ARTIFACT
          ) {
            complianceOrArtifact = question.frameworkName;
          }
          const createdAnswer = await this.createAnswer(question, userAnswer, assignment, questionId, auditStatus);

          questionAnswer = { ...createdAnswer };
          this.currentUpdatedAnswer = { ...createdAnswer };
          const questionData = await this.customApi.GetQuestionData(question.id);
          if (questionData) {
            await this.customApi.UpdateQuestionData(
              { id: question.id, score: userAnswer.answer },
              { assessmentId: { eq: questionData.assessmentId } }
            );
          }
          createdAnswer.file = this.artifactsFile;
          if (complianceOrArtifact) {
            createdAnswer.frameworkName = complianceOrArtifact;
          }
          if (isVersion) {
            await this.createAnswerVersion(createdAnswer, targetId, assessmentId, type);
          }
          if (UtilsService.isDefined(userAnswer.answer) && createdAnswer && !isArtifactUpload) {
            const message = getLogMessage(
              logKey,
              currUserName,
              null,
              null,
              ansNonPartialValues.includes(userAnswer.answer) ? null : userAnswer.answer
            );
            this.entityService.addLog(targetId, assessmentId, type, message);
          }
          questionAnswers.push({ ...questionAnswer });
        }
      }
      question.assignments = assignments;
      question.answers = [...questionAnswers];
      question.answers.splice(
        question.answers.findIndex(ans => ans.id === questionAnswer.id),
        1
      );
      question.answers.unshift({ ...questionAnswer });
      return question;
    } catch (e) {
      console.log('Cannot create answers', e);
    }
  };

  createAnswerVersion = async (answer, targetId, assessmentId, type) => {
    const version: CreateVersionsInput = {
      id: uuid(),
      targetId,
      type,
      assessmentId,
      data: JSON.stringify(answer),
    };
    await this.customApi.CreateVersions(version);
  };

  deleteUserAnswer = async (question: Question, userId: string, id: string): Promise<void> => {
    const questionAnswers: GetAnswerQuery[] = UtilsService.isDefined(question.answers)
      ? UtilsService.copyArrayDeep(question.answers)
      : [];
    await Promise.all(
      question.assessmentIds.map(async assessmentId => {
        const assignment = await this.getQuestionUserAssignments(question, userId, assessmentId);
        let answerToUpdate;
        let assignmentsIds;
        if (
          question.answers &&
          question.answers.length > 0 &&
          // not matching userId as we have to delete the answer if answered by the other manager
          // answer.userId === userId &&
          (answerToUpdate = question.answers.find(
            answer => answer.left && answer.right && answer.left >= assignment.left && answer.right <= assignment.right
          )) &&
          (assignmentsIds = question.assignments.find(asignment => asignment.userId === userId))
        ) {
          const deleteAnswer = await this.customApi.DeleteAnswer({ id }, { assessmentId: { eq: assessmentId } });
          const existingAnswerIndex = questionAnswers.findIndex(answerItem => answerItem.id === id);
          if (existingAnswerIndex > -1) {
            questionAnswers.splice(existingAnswerIndex, 1);
          }
        }
      })
    );
    question.answers = questionAnswers;
    if (question.managerAnswers && question.managerAnswers.length) {
      const idx = question.managerAnswers.findIndex(obj => obj.id === id);
      if (idx > -1) {
        question.managerAnswers.splice(idx, 1);
      }
    }
  };

  addTaskActionWithTaskAssignments = async (
    question,
    userId: string,
    userTaskAction: GetTaskActionQuery
  ): Promise<TaskAction> => {
    const taskAction: any = await this.getTaskActionsById(question.id);
    if (
      taskAction &&
      Object.keys(taskAction).length > 0 &&
      taskAction.assessmentId === question.assessmentId &&
      taskAction.frameworkName === question.frameworkName
    ) {
      console.log('task action already exists...');
      return await this.updateTaskAction(userTaskAction, taskAction, userId);
    } else {
      console.log('creating task action...');
      return await this.createTaskAction(question, userTaskAction, userId);
    }
  };

  addCustomTaskWithTaskAssignments = async (question, userTaskAction: GetCustomTaskQuery): Promise<CustomTask> => {
    const taskAction = await this.getCustomTaskById(question.id);
    if (taskAction && Object.keys(taskAction).length > 0 && taskAction.assessmentId === question.assessmentId) {
      // eslint-disable-next-line dot-notation
      if (userTaskAction['action'] === 'IGNORED') {
        userTaskAction.status = TaskStatusEnum.CLOSED;
      }
      return await this.updateCustomTask(userTaskAction, taskAction);
    }
  };

  async fetchCommentsByAssessments(assessmentIds: string[]): Promise<CommentByAssessmentIdQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(async (assessmentId: string): Promise<CommentByAssessmentIdQuery[]> => {
          return await this.getCommentsByAssessment(assessmentId);
        })
      )
    ).flat();
  }

  async fetchAssessmentsAnswers(assessmentIds: string[]): Promise<GetAnswerQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(async (assessmentId: string): Promise<GetAnswerQuery[]> => {
          return await this.getAnswersForAssessment(assessmentId);
        })
      )
    ).flat();
  }

  async fetchAssessmentsAssignments(assessmentIds: string[]): Promise<GetAssignmentQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(async (assessmentId: string): Promise<GetAssignmentQuery[]> => {
          return await this.getAssignmentsByAssessmentId(assessmentId);
        })
      )
    ).flat();
  }

  async fetchCustomTasks(assessmentIds: string[]): Promise<GetCustomTaskQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(async (assessmentId: string): Promise<GetCustomTaskQuery[]> => {
          return await this.getCustomTasksByAssessmentId(assessmentId);
        })
      )
    ).flat();
  }

  async fetchCustomTaskAssignments(assessmentIds: string[]): Promise<GetCustomTaskAssignmentQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(async (assessmentId: string): Promise<GetCustomTaskAssignmentQuery[]> => {
          return await this.getCustomTaskAssignmentsByAssessmentId(assessmentId);
        })
      )
    ).flat();
  }

  async fetchAssessmentQuestionSettings(assessmentIds: string[]): Promise<GetQuestionSettingsQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(async (assessmentId: string): Promise<GetQuestionSettingsQuery[]> => {
          return await this.getQuestionSettingsForAssessment(assessmentId);
        })
      )
    ).flat();
  }

  getUniqueAssessments(frameworks: FrameworkScore[]): string[] {
    return [...new Set(frameworks ? frameworks.map(framework => framework.assessmentId) : [])];
  }

  assignCustomTask(
    question: Question,
    user: any,
    subEntityList: GetEntityQuery[],
    entityId?: string
  ): Promise<CreateCustomTaskAssignmentMutation>[] {
    const assessmentIds: string[] = [];
    if (!entityId || (entityId && entityId === question.rootId)) {
      assessmentIds.push(...question.assessmentIds);
    } else if (entityId && question.childIds.includes(entityId)) {
      const subEntityId = question.childIds.find(childId => childId === entityId);
      const activeAssessmentId = subEntityList.find(subEntity => subEntity.id === subEntityId).activeAssessmentId;
      assessmentIds.push(activeAssessmentId);
    }
    return assessmentIds.map(async (assessmentId: string): Promise<CreateCustomTaskAssignmentMutation> => {
      if (
        question.taskAssignments &&
        question.taskAssignments.find(
          assignment => assignment.assessmentId === assessmentId && assignment.userId === user.userId
        )
      ) {
        return null;
      }
      return await this.customApi.CreateCustomTaskAssignment({
        targetId: question.id,
        assessmentId,
        userId: user.userId,
      });
    });
  }

  assignSingleQuestion(
    question: Question,
    user: any,
    subEntityList: GetEntityQuery[],
    entityId?: string
  ): Promise<CreateAssignmentMutation>[] {
    const assessmentIds: string[] = [];
    if (!entityId || (entityId && entityId === question.rootId)) {
      assessmentIds.push(...question.assessmentIds);
    } else if (entityId && question.childIds.includes(entityId)) {
      const subEntityId = question.childIds.find(childId => childId === entityId);
      const activeAssessmentId = subEntityList.find(subEntity => subEntity.id === subEntityId).activeAssessmentId;
      assessmentIds.push(activeAssessmentId);
    }
    return assessmentIds.map(async (assessmentId: string): Promise<CreateAssignmentMutation> => {
      if (
        question.assignments.find(
          assignment => assignment.assessmentId === assessmentId && assignment.userId === user.userId
        )
      ) {
        return null;
      }
      return await this.createAssignment(
        { targetId: question.id, left: question.left, right: question.right },
        user.userId,
        assessmentId
      );
    });
  }

  getAssignedUsersListForQuestions(questions: Question[], assignments: GetAssignmentQuery[]): string[] {
    let assignedUsers: string[] = [];
    questions.forEach((question, index) => {
      const questionAssignments = this.getAssignedUsersList(assignments, question);
      if (questionAssignments && questionAssignments.length > 0) {
        question.assignments = questionAssignments;
      }
      if (index === 0 && questionAssignments.length > 0) {
        assignedUsers.push(...questionAssignments.map(assignment => assignment.userId));
      } else if (index > 0 && assignedUsers.length > 0) {
        if (!assignedUsers.every(this.isUserAssignedToQuestion(question))) {
          assignedUsers = assignedUsers
            .map(assignedUser => {
              return this.isUserAssignedToQuestion(question)(assignedUser) ? assignedUser : null;
            })
            .filter(UtilsService.filterOutNullElements);
        }
      }
    });
    return assignedUsers;
  }

  isUserAssignedToQuestion =
    (question: Question) =>
    (assignedUser: string): boolean =>
      UtilsService.isDefined(question.assignments) &&
      question.assignments.findIndex(assignment => assignment.userId === assignedUser) > -1;

  getFrameworkNameByType(framework: any): string {
    // return framework.type === StandardType.RISK_FRAMEWORK
    //   ? RiskFrameworkEnum[framework.name]
    //   : StandardEnum[framework.name];

    // TODO: remove to replace(/_/g, ' ') after framework.name will fix
    return framework.name.replace(/_/g, ' ');
  }

  getCurrentManagerAction(answer: any): ManagerActionEnum {
    let managerAction: ManagerActionEnum;
    if (answer && answer.isActionTaken && answer.riskAction === RiskAction.MANAGED) {
      const answerEnumValue = UtilsService.answerNumToEnum(answer.answer);
      switch (answerEnumValue) {
        case AnswerEnum.YES:
          managerAction = ManagerActionEnum.APPROVE;
          break;
        case AnswerEnum.NO:
          managerAction = ManagerActionEnum.DENIED;
          break;
      }
    }
    return managerAction;
  }
  currentAuditorStatus(question) {
    const listStatus = [
      AuditorActionTypeEnum.STRONG,
      AuditorActionTypeEnum.SATISFACTORY,
      AuditorActionTypeEnum.NEEDS_IMPROVEMENT,
      AuditorActionTypeEnum.WEAK,
    ];
    if (question && question.answers && question.answers.length) {
      const auditorAnswer = question.answers.find(
        answer => answer?.isActionTaken && listStatus.includes(answer?.auditStatus)
      );
      return auditorAnswer && auditorAnswer?.auditStatus;
    }
  }
  displayStandardsForITCvendor(standardName): string {
    const isVendorView = this.router.url.includes('gaps');
    if (isVendorView && UtilsService.isITC && standardName === 'NIST CSF') {
      return 'ITC CF';
    } else {
      const isFrameworkKey = this.domainFrameworkService.isFrameworkKey(standardName?.split('#')[0]);
      const frameworkName = isFrameworkKey
        ? this.domainFrameworkService.getFrameworkName(standardName?.split('#')[0])
        : standardName;
      return frameworkName;
    }
  }
}
