// eslint-disable-next-line no-shadow
export enum TabOptions {
  MY_TASKS = 'MY_TASKS',
  READINESS = 'READINESS',
  COMPLIANCE = 'COMPLIANCE',
  INTERNAL_TASKS = 'INTERNAL_TASKS',
  ALL_TASKS = 'ALL_TASKS',
}
export enum RemListEnum {
  ALL_TASKS = 'allTasks',
  MY_LIST = 'myTasksList',
  READ_LIST = 'readinessList',
  COMP_LIST = 'complianceList',
  INT_LIST = 'internalTasksList',
}

export enum ConnectService {
  JIRA = 'jira',
  SERVICE_NOW = 'ServiceNow',
}
