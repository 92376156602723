/* eslint-disable max-len */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/shared/file.service';
import {
  CreateEntityInput,
  EntityTypeEnum,
  GetUserQuery,
  S3FileInput,
  StandardType,
  EntityQuestionSettings,
  GetRoleQuery,
  FileTypeEnum,
} from 'app/API.service';
import { IndustryEnum } from 'app/shared/enums/brownAndBrown.enum';
import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RiskOption } from 'models/generator.model';
import { QuestionSettingsEnum } from 'app/shared/enums/question-card.enum';
import {
  NgbDateParserFormatter,
  NgbActiveModal,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModalRef,
  NgbModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { GetRoleQueryExtended, UserService } from 'app/shared/user.service';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import orderBy from 'lodash/orderBy';
import { ReviewerModalComponent } from 'app/collection/reviewers-modal/reviewers-modal.component';

@Component({
  selector: 'cygov-add-new-client-modal',
  templateUrl: './add-new-client-modal.component.html',
  styleUrls: ['./add-new-client-modal.component.scss'],
})
export class AddNewClientModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<any>();
  newEntity: CreateEntityInput;
  imageFile: any;
  riskFramework: RiskOption;
  s3Input: S3FileInput;
  standardList: RequiredStandard[];
  standardListCopy = [];
  frameworkTypeOpt = [EntityTypeEnum.RISK_FRAMEWORK, EntityTypeEnum.COMPLIANCE_FRAMEWORK];
  displayStandardList: boolean;
  industryOpt = Object.keys(IndustryEnum);
  riskFrameWorkOpt: RiskOption[] = [];
  entityType: EntityTypeEnum;
  startingDate: Date;
  deadlineDate: Date;
  DEADLINE_DATE = 'deadlineDate';
  ASSESSMENT_DATE = 'startingDate';
  currentDate: NgbDateStruct;
  newLogoUploaded = false;
  uploadedLogo: any;
  placeholderMandatory: string = 'Select Industry *';
  addReviewersModalReference: NgbModalRef;

  // Vars for new client modal ui
  questionSettingsRadioOptions = [];
  frameworkSettings = [];
  entityQuestionSettings: EntityQuestionSettings;
  // New Design Code
  selectedStartDate: NgbDateStruct;
  selectedEndDate: NgbDateStruct;
  endCalendar: NgbInputDatepicker;
  startCalendar: NgbInputDatepicker;
  showFrameworkNameTooltip: boolean = false;
  managerUsers: any;
  allRoles: GetRoleQuery[] = [];
  rootEntityId: string;
  currentUser: any;
  currentUserRole: GetRoleQueryExtended;
  loading = true;
  isBnB: boolean = UtilsService.isBnB;
  questionType: string = 'question-type';
  RiskFrameworkEnum: any;
  frameworkQuestionTypeOptions = [];
  frameworkSettingsTooltips: any = {
    suggestedArtifact: {
      text: 'Require an artifact to be included with a "Yes" answer.',
      placement: 'right',
    },
    suggestedComments: {
      text: 'Require a comment to be included with any answer.',
      placement: 'left',
    },
    mandatoryReviewer: {
      text: 'Add an additional workflow of a reviewer who will review, validate and approve the answers, comments and artifacts.',
      placement: 'left',
    },
    questionType: {
      text: 'Restart your assessment with a clean implementation from your current risk framework. All linkage to remediation tasks and risk register will be wiped clean.',
      placement: 'right',
    },
    integration: {
      text: 'If you have connected any integration feeds into your collection processes, you are able to disconnect the auto-answer feature on this specific question. If you disable this the integration will only bring the evidence to the "Integration" tab, but will not participate in answering the question.',
      placement: 'right',
    },
    smartmapping: {
      text: 'This will disable the automatic control crosswalking feature for this question. Mapped questions will no longer receive or send answers to this question.',
      placement: 'left',
    },
  };

  ngbModalReviewers: NgbModalOptions = {
    size: 'lg',
    windowClass: 'collection-add-framework-modal-windowClass',
    backdrop: true,
    keyboard: false,
    backdropClass: 'collection-add-framework-modal-backdropClass',
  };

  selectedManagers = [];
  usersList = [];
  filteredUserList = [];
  reviewerModelOpen = false;
  reviewerAsParticipant: boolean = false;
  isFirstParty: boolean = false;
  sectionsCompletion = [false, false, true, true, true];
  currentActiveSectionIndex = 0;
  isFrameworkValid: boolean = true;
  endDateLimit: any;

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public activeModal: NgbActiveModal,
    private parserFormatter: NgbDateParserFormatter,
    private entityService: EntityService,
    private domainFrameworkService: DomainFrameworkService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.RiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.RISK_FRAMEWORK_ENUM);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.rootEntityId = entityId;
      this.currentUser = await this.userService.getCurrentUser();
      this.currentUserRole = this.userService.getCurrentRole();
      this.currentUser.role =
        this.currentUserRole && this.currentUserRole.name
          ? this.currentUserRole.name.toUpperCase()
          : this.currentUser.role;
      this.managerUsers = await this.getAllUserList();
      this.updateRoles();
      this.entityType = this.router.url.includes('clients') ? EntityTypeEnum.ROOT_ENTITY : EntityTypeEnum.CHILD_ENTITY;
      this.newEntity = EntityService.initEntity(this.entityType, null, entityId);
      this.newEntity.projectManager = this.currentUser.id;
      this.standardList = this.entityService.initStandardList();
      // Copying standardList to standardListCopy because it was not showing other newly added Frameworks in the list (on clientside)
      this.standardListCopy = this.standardList;
      this.displayStandardList = this.router.url.includes('multi-entity');
      this.isFirstParty = this.router.url.includes('first-party');
      this.prepareRiskFrameworkOptions();
      await this.applyFrameworkSettings();
      this.currentDate = UtilsService.getDateInNgbDateStructFormat(new Date());

      this.questionSettingsRadioOptions = [
        {
          id: 'suggestedArtifact',
          title: 'Mandatory Artifact',
          key: 'isArtifactsMandatory',
          type: 'radio',
          options: [
            { name: QuestionSettingsEnum.SUGGESTED, checked: true },
            { name: QuestionSettingsEnum.ALL, checked: false },
            { name: QuestionSettingsEnum.NONE, checked: false },
          ],
        },
        {
          id: 'suggestedComments',
          title: 'Mandatory Comment',
          key: 'isCommentsMandatory',
          type: 'radio',
          options: [
            { name: QuestionSettingsEnum.SUGGESTED, checked: true },
            { name: QuestionSettingsEnum.ALL, checked: false },
            { name: QuestionSettingsEnum.NONE, checked: false },
          ],
        },
        {
          id: 'mandatoryReviewer',
          title: 'Mandatory Reviewer',
          key: 'isApprovalRequired',
          type: 'radio',
          options: [
            { name: QuestionSettingsEnum.ALL, checked: false },
            { name: QuestionSettingsEnum.NONE, checked: true },
          ],
        },
      ];

      this.frameworkSettings = [
        {
          id: 'integration',
          title: 'Integration',
          key: 'isIntegrationActive',
          isChecked: UtilsService.isMidMarket || UtilsService.isBnBCyberSite ? false : true,
          readonly: UtilsService.isMidMarket || UtilsService.isBnBCyberSite,
        },
        {
          id: 'smartmapping',
          title: 'Smart Mapping',
          key: 'isSmartMappingActive',
          type: 'toggle',
          isChecked: UtilsService.isMidMarket || UtilsService.isBnBCyberSite ? false : true,
          readonly: UtilsService.isMidMarket || UtilsService.isBnBCyberSite,
        },
      ];

      // check if we are in bnb then we will hide the question type from modal.
      if (this.isBnB) {
        // we will find the index of the question type and splice it.
        const questionTypeIndex = this.questionSettingsRadioOptions.findIndex(
          option => option?.id === this.questionType
        );
        if (questionTypeIndex > -1) {
          this.questionSettingsRadioOptions.splice(questionTypeIndex, 1);
        }
      }

      // getting the initial default dates
      const localStart = new Date();
      const localEnd = new Date();
      localEnd.setDate(localStart.getDate() + 30);
      this.selectedStartDate = UtilsService.getDateInNgbDateStructFormat(localStart.getTime());
      this.selectedEndDate = UtilsService.getDateInNgbDateStructFormat(localEnd.getTime());
      this.startingDate = JSON.parse(JSON.stringify(this.selectedStartDate));
      this.deadlineDate = JSON.parse(JSON.stringify(this.selectedEndDate));
      this.loading = false;
      this.newEntity.defaultQuestionSettings.isArtifactsMandatory = QuestionSettingsEnum.SUGGESTED as any;
      this.newEntity.defaultQuestionSettings.isCommentsMandatory = QuestionSettingsEnum.SUGGESTED as any;
      this.newEntity.defaultQuestionSettings.isApprovalRequired = false;
      this.newEntity.defaultQuestionSettings.isIntegrationActive =
        UtilsService.isMidMarket || UtilsService.isBnBCyberSite ? false : true;
      this.newEntity.defaultQuestionSettings.isSmartMappingActive =
        UtilsService.isMidMarket || UtilsService.isBnBCyberSite ? false : true;

      this.frameworkQuestionTypeOptions = [
        {
          name: QuestionSettingsEnum.COLLABORATIVE,
          checked: true,
          readonly: UtilsService.isMidMarket || UtilsService.isBnBCyberSite,
        },
        {
          name: QuestionSettingsEnum.SINGLE,
          checked: false,
          readonly: UtilsService.isMidMarket || UtilsService.isBnBCyberSite,
        },
      ];

      this.innitializeManagerUsersList();
      const currentDate = new Date();
      this.endDateLimit = UtilsService.getDateInNgbDateStructFormat(currentDate.getTime() + 86400000);
    } catch (err) {
      console.log('Error ==>>', err);
    }
  }

  /**
   * temporary find to find the role of the user will be removed after the connection
   */
  updateRoles(): void {
    if (this.managerUsers && this.managerUsers.length) {
      this.managerUsers.forEach(user => {
        if (this.userService.getRequiredRole(user.roleId)?.name) {
          user.role = this.userService.getRequiredRole(user.roleId).name.toUpperCase();
        }
      });
    }
  }

  innitializeManagerUsersList(): void {
    this.selectedManagers = [{ checked: true, ...this.currentUser }];
  }

  openReviewerModal() {
    this.reviewerModelOpen = true;
    this.addReviewersModalReference = this.modalService.open(ReviewerModalComponent, this.ngbModalReviewers);

    this.addReviewersModalReference.componentInstance.framework = null;

    this.addReviewersModalReference.componentInstance.rootEntityId = this.rootEntityId;

    this.addReviewersModalReference.componentInstance.frameworkName = this.newEntity.frameworkName;

    this.addReviewersModalReference.componentInstance.preSelectedUsers = this.selectedManagers;

    this.addReviewersModalReference.componentInstance.atleastOneReviewerRequired = true;

    this.addReviewersModalReference.componentInstance.requestComingFromScreen = 'addNewEntity';

    this.addReviewersModalReference.componentInstance.selectedManagers.subscribe(selectedManagers => {
      this.reviewerModelOpen = false;
      this.selectedManagers = selectedManagers;
    });
    this.addReviewersModalReference.componentInstance.closeModal.subscribe(() => {
      this.reviewerModelOpen = false;
    });
  }

  async applyFrameworkSettings() {
    const parentEntity = await this.entityService.getEntity(this.rootEntityId, true);

    const frameworkSettings = parentEntity?.frameworkSettings
      ? JSON.parse(parentEntity.frameworkSettings)
      : this.domainFrameworkService.domainSettingsData;

    const domainSettings = this.domainFrameworkService.domainSettingsData;

    this.riskFrameWorkOpt = [
      ...frameworkSettings.RISK_FRAMEWORKS.filter(
        framework =>
          !!framework.status &&
          domainSettings.RISK_FRAMEWORKS.find(obj => obj.name === framework.name && obj.status === true)
      ),
    ];

    this.standardList = [
      ...frameworkSettings.COMPLIANCE_FRAMEWORKS.filter(
        framework =>
          !!framework.status &&
          domainSettings.COMPLIANCE_FRAMEWORKS.find(obj => obj.name === framework.name && obj.status === true)
      ),
    ];
  }

  prepareRiskFrameworkOptions(): void {
    const riskList = UtilsService.isBnB
      ? [this.RiskFrameworkEnum.NIST_CSF_BB.split(' ').join('_')]
      : Object.keys(this.RiskFrameworkEnum);
    for (const enumMember of riskList) {
      const allow = !this.domainFrameworkService.restrictedFrameworks.includes(
        this.RiskFrameworkEnum[enumMember].toLowerCase()
      );
      if (allow) {
        this.riskFrameWorkOpt.push({
          key: enumMember,
          name: this.RiskFrameworkEnum[enumMember],
          value: this.RiskFrameworkEnum[enumMember],
        });
      }
    }
    this.riskFrameWorkOpt = orderBy(this.riskFrameWorkOpt, 'name', 'asc');
  }

  toggleStandard(event, standard: RequiredStandard): void {
    UtilsService.stopBubbling(event);
    standard.checked = !standard.checked;
  }

  async imgHandler(eventFile: Event): Promise<void> {
    try {
      const value = await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.LOGO);
      if (!value) {
        return;
      }
      this.s3Input = await this.fileService.convertToS3Input(eventFile, this.newEntity.id);
      this.uploadedLogo = await this.fileService.getUploadedImg(eventFile);
      this.newLogoUploaded = true;
    } catch (error) {
      this.logger.error('Image Handler  Error =', error);
      this.toastr.error(error);
    }
  }

  isValid(entity: CreateEntityInput): boolean {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    } else if (UtilsService.checkForHtml(entity.name)) {
      this.toastr.error('HTML tags are not allowed in the entity name');
      valid = false;
    }
    if (this.entityType === EntityTypeEnum.ROOT_ENTITY) {
      if (!entity.industry) {
        this.toastr.error('Entity Industry is required');
        valid = false;
      }
    }
    if (this.displayStandardList && (!this.startingDate || !this.deadlineDate)) {
      this.toastr.error('Assessment date is required');
      valid = false;
    }
    if (!this.riskFramework && entity.entityType !== 'ROOT_ENTITY') {
      this.toastr.error('Risk Framework is required');
      valid = false;
    }
    return valid;
  }

  /**
   * Setting the startingDate or deadlineDate in this function
   * @param date The actual NGB Date Struct from calendar
   * @param dateType The Date type , either Starting Date or Deadline Date
   */
  dateChanged(date, dateType: string): void {
    this[dateType] = date;
  }

  emitEntity(entity: CreateEntityInput, s3Input: S3FileInput, standardList: RequiredStandard[]): void {
    const requiredStandard: RequiredStandard[] = standardList
      .filter(standard => standard.checked)
      .map(framework => ({ ...framework, type: StandardType.COMPLIANCE_FRAMEWORK }));
    if (this.riskFramework) {
      requiredStandard.push({
        key: this.riskFramework.key,
        name: this.riskFramework.value,
        type: StandardType.RISK_FRAMEWORK,
        checked: true,
      });
    }
    entity.timeline.collectionDate = this.assignDate(this.deadlineDate, this.DEADLINE_DATE);
    entity.projectDeadline = entity.timeline.collectionDate;
    entity.timeline.initiationDate = this.assignDate(this.startingDate, this.ASSESSMENT_DATE);
    entity.tierSelected = 4;

    this.modalResult.emit({
      entity,
      s3Input,
      requiredStandard,
      frameworkReviewers: entity.defaultQuestionSettings.isApprovalRequired ? this.selectedManagers : [],
      needToCreateAssignments: this.reviewerAsParticipant,
      isFirstParty: this.isFirstParty,
    });
  }

  assignDate(dateStr, dateType: string): number {
    dateStr = this.parserFormatter.format(dateStr);
    const date = new Date(dateStr).getTime();
    if (dateType === this.DEADLINE_DATE) {
      this.newEntity.projectDeadline = date;
      this.newEntity.timeline.collectionDate = date;
    } else {
      this.newEntity.timeline.initiationDate = date;
    }
    return date;
  }

  handleFirstTabUpdatedListOutput(event) {
    this.riskFramework = event.find(r => r.checked);
    this.isFrameworkValid = this.riskFramework ? true : false;
  }

  handleSecondTabUpdatedListOutput(event) {
    this.standardList = event;
  }

  // Store the selected question setting in the enity object
  selectedQuestionSettingsEmit(event, title): void {
    const name = event.target.value;

    if (name === QuestionSettingsEnum.COLLABORATIVE || name === QuestionSettingsEnum.SINGLE) {
      this.newEntity.defaultQuestionSettings.isCollaborative = name === QuestionSettingsEnum.COLLABORATIVE;
    }

    if (title === QuestionSettingsEnum.ARTIFACTS) {
      this.newEntity.defaultQuestionSettings.isArtifactsMandatory = name;
    }

    if (title === QuestionSettingsEnum.COMMENTS) {
      this.newEntity.defaultQuestionSettings.isCommentsMandatory = name;
    }
    if (title === QuestionSettingsEnum.MANAGER_APPROVAL) {
      this.newEntity.defaultQuestionSettings.isApprovalRequired = name === QuestionSettingsEnum.ALL ? true : false;
    }
  }

  /**
   * In this Method we take the Users from Root Entity and subEntities
   * @returns is type of GetUserQuery List
   */
  async getAllUserList(): Promise<GetUserQuery[]> {
    const subEntities = await this.entityService.getSubEntitiesList(this.rootEntityId, true);
    // getting both the custom roles and default roles.
    await this.userService.getRolesByDefaultOrEntityId(this.rootEntityId, false);
    await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
    const childEntityIds = subEntities.map(entity => {
      return entity.id;
    });
    let users = await this.userService.getAllUsersForEntityWithRoleId(
      this.rootEntityId,
      childEntityIds as [string],
      this.currentUserRole
    );
    const vendorId = this.userService.getRoleId('Vendor');
    const userAdministratorId = UtilsService.isBnB
      ? this.userService.getRoleId(AppLevelRoleEnumBnB.USER_ADMINISTRATOR)
      : '';
    if (users && users.length) {
      users = users.filter(user => {
        return user.roleId && user.roleId !== vendorId && user.roleId !== userAdministratorId;
      });
      return users;
    } else {
      return [];
    }
  }

  /**
   * @param user is type of GetUserQuery
   * this method is invoked on the emit of cygov-user-listing
   * to assign the manager
   */
  assignManager(user: GetUserQuery): void {
    if (user) {
      this.newEntity.projectManager = user.id;
      this.newEntity.projectManagerName = user.name;
    }
  }

  handleToggleChange(selectedSetting, settingType: string): void {
    this.newEntity.defaultQuestionSettings[settingType] = selectedSetting;
  }

  onSectionCompletion(index): void {
    if ((index === 0 && !this.newEntity.name) || (index === 1 && !this.isFrameworkValid)) {
      const message = index === 0 ? 'Please Add Entity Name.' : 'Please Select Atleast 1 Risk Framework';
      this.toastr.info(message);
      return;
    }
    this.currentActiveSectionIndex = this.currentActiveSectionIndex + 1;
    this.sectionsCompletion[this.currentActiveSectionIndex] = true;
  }
}
