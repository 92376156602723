import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MidMarketEnum } from 'app/API.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'cygov-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  currentStep: string = 'welcome-message';
  currentUser: any = {};
  wizardData: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.currentUser = await this.userService.getCurrentUser();
    const onBoardingStatus = this.currentUser.onBoardingStatus;
    if (onBoardingStatus === MidMarketEnum.STEP_1) {
      this.currentStep = 'welcome-message';
    } else if (onBoardingStatus === MidMarketEnum.STEP_2) {
      this.currentStep = 'welcome-video';
    } else if (onBoardingStatus === MidMarketEnum.COMPLETED) {
      this.router.navigate([`first-party/${this.currentUser?.entityId}/collection`]);
      return;
    } else {
      this.currentStep = 'welcome-message';
    }
    this.wizardData = await this.userService.getMidMarketClientWizard();
  }

  nextStep(currentStep) {
    this.currentStep = currentStep;
  }
}
