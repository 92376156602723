import { Pipe, PipeTransform } from '@angular/core';
/*
 * Normalize the risk Score to x.x format.
 *  2779.170166015625 to 2.7
 * Usage:
 * number | normalizeScore
 */
@Pipe({
  name: 'normalizeScore',
})
export class NormalizeScorePipe implements PipeTransform {
  transform(value: string): string {
    const isNumeric = (str: string) => {
      if (typeof str != 'string') {
        return false;
      } // we only process strings!
      return (
        !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    };
    if (isNumeric(value)) {
      const num = +value; // convert string to number
      return (num / 10000).toString();
    } else {
      return '0';
    }
  }
}
