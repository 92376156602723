import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-hitrust-confirmation-modal',
  templateUrl: './hitrust-confirmation-modal.component.html',
  styleUrls: ['./hitrust-confirmation-modal.component.scss'],
})
export class HitrustConfirmationModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  setModalResult(event: boolean) {
    this.modalResult.emit(event);
    this.activeModal.close();
  }
}
