import { Component, Input, OnChanges, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';
import { Router } from '@angular/router';

interface Coordinates {
  x: number;
  y: number;
}

@Component({
  selector: 'cygov-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent implements OnInit, OnChanges {
  @Input() score: number = 0;
  @Input() target: number;
  @Input() isFrommutliEntity: boolean = false;
  @Input() isUpperDeck: boolean = false;
  @Input() fgArcThickness: number;
  @Input() average: number = 0;
  @Input() svgHeight = 440;
  @Input() svgWidth = 440;
  @Input() svgYAxis = 0;
  @Input() circleXDivisor = 2;
  @Input() circleYDivisor = 2;
  @Input() showTarget = true;
  @Input() showAverage = false;
  @Input() backgroundCircle = false;
  @Input() remainingProgressColor = 'black';
  @Input() circularProgressHover: boolean = false;
  @Input() bgFill: string = 'none';
  @Input() hoverFill: string = 'none';
  @Input() removeIsTextDependency: boolean;
  @Input() maxValue: number;
  @Input() applyGradient: boolean = UtilsService.isBnB || UtilsService.isCyberArk;
  @Input() isMaturityOption: boolean = false;
  @Input() showIncrementCounter: boolean = false;
  @Input() adjustGaugeHeight: boolean = false; // flag to alter gauge size incase of overview sections
  @Input() rootSelectedTier: any = null;
  @Input() isMidMarket: boolean = null;
  @Input() thirdPartyCheck: boolean = false;

  @Input() capOrTool: any = {}; // in case of maturity in netskope
  @ViewChild('numberInputTag', { static: false }) inputElement: ElementRef;
  @ViewChild('numberInputTag2', { static: false }) inputElement2: ElementRef;
  @Output() performOperation: EventEmitter<any> = new EventEmitter<any>();
  MIN_SCORE = 0;
  MAX_SCORE = 10;

  STARTING_ANGLE = 35;
  ENDING_ANGLE = 325;

  // For Overview Vendor Widget only.
  SVG_HEIGHT_OFFSET = -100;
  SVG_WIDTH_OFFSET = -100;
  SVG_X_CROP = 70;
  SVG_Y_CROP = 50;

  BG_ARC_STROKE = 6;
  FG_ARC_STROKE = 12;
  LINE_STROKE = 4;
  ARC_RADIUS = this.adjustGaugeHeight ? 160 : 120;
  LINE_LENGTH = 25;
  SCORE_LABEL_OFFSET = 10;
  svgViewBox: string;
  lineLabelRadius: number;

  availableAngle: number;
  centerX: number;
  centerY: number;

  minScoreAxisX: number;
  minScoreAxisY: number;
  maxScoreAxisX: number;
  maxScoreAxisY: number;
  backgroundArcData: string;
  scoreArcData: string;

  targetLabel: string;
  targetCoordinates: Coordinates;

  averageLabel: string;
  averageCoordinates: Coordinates;

  scoreStrokeClassColor: string;

  gradientID: string = '';

  editNumber = false;
  editNumber2 = false;

  isBeecher: boolean = UtilsService.isBnBCyberSite || UtilsService.isMidMarket;
  isBnb: boolean = UtilsService.isBnB;
  isCyberArk: boolean = UtilsService.isCyberArk;
  utils = UtilsService;

  constructor(
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.MAX_SCORE = this.maxValue ? this.maxValue : 10;
    if (this.score < this.MIN_SCORE || this.score > this.MAX_SCORE) {
      this.toastr.error('Invalid Score Value');
      return;
    }
    this.initializeAttributes();

    if (!this.showTarget && !this.isUpperDeck) {
      this.updateSVGViewBox();
    }

    this.setBackgroundArc();
    this.setScoreArc();
    this.setTarget();
    this.setAverage();
  }

  ngOnChanges(changes): void {
    this.ARC_RADIUS = this.adjustGaugeHeight ? 160 : 120;
    if (changes) {
      // This will update the score on updating the tier
      if (UtilsService.isDefined(changes.score) && this.rootSelectedTier && !this.isFrommutliEntity) {
        this.score = UtilsService.calculatePillarsScoreByTier(this.score, this.rootSelectedTier);
      }
      // if score change is detected then update score on widget
      if (changes.score) {
        this.setScoreArc();
      }
      // if target change is detected then update target on widget
      if (changes.target) {
        this.setTarget();
      }
      // if average change is detected then update average on widget
      if (changes.average) {
        this.setAverage();
      }
    }
  }

  private initializeAttributes() {
    this.svgViewBox = `0 ${this.svgYAxis} ${this.svgWidth} ${this.svgHeight}`;
    this.lineLabelRadius = this.ARC_RADIUS + this.LINE_LENGTH + 15;
    this.availableAngle = this.ENDING_ANGLE - this.STARTING_ANGLE;
    this.centerX = this.svgWidth / this.circleXDivisor;
    this.centerY = this.adjustGaugeHeight
      ? this.svgHeight / this.circleYDivisor - 35
      : this.svgHeight / this.circleYDivisor - 10;
  }

  private setBackgroundArc() {
    this.backgroundArcData = this.describeArc(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.STARTING_ANGLE,
      this.ENDING_ANGLE
    );

    const minScoreAxis = this.polarToCartesian(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.STARTING_ANGLE - this.SCORE_LABEL_OFFSET
    );
    this.minScoreAxisX = minScoreAxis.x;
    this.minScoreAxisY = minScoreAxis.y;

    const maxScoreAxis = this.polarToCartesian(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.ENDING_ANGLE + this.SCORE_LABEL_OFFSET
    );
    this.maxScoreAxisX = maxScoreAxis.x;
    this.maxScoreAxisY = maxScoreAxis.y + 10;
  }

  private setScoreArc() {
    let relativeScore = this.score * (this.availableAngle / this.MAX_SCORE) + this.STARTING_ANGLE;
    if (isNaN(relativeScore)) {
      relativeScore = 0;
    }
    this.scoreArcData = this.describeArc(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.STARTING_ANGLE,
      relativeScore
    );
    // * sets color based on flags
    if (this.isBnb) {
      this.scoreStrokeClassColor = this.getBnbScoreClass(this.score);
    } else if (this.isCyberArk) {
      this.scoreStrokeClassColor = this.getCyberArkScoreClass(this.score);
    } else {
      this.scoreStrokeClassColor = this.getScoreClass(this.score);
    }
  }

  private setTarget() {
    let targetAngle = this.target * (this.availableAngle / 10) + this.STARTING_ANGLE;
    if (isNaN(targetAngle)) {
      targetAngle = 0;
    }
    this.targetCoordinates = this.describeCircle(this.centerX, this.centerY, this.ARC_RADIUS, targetAngle);
    // this.targetLabel = this.getTextLabel('Target:', targetAngle);
    this.targetLabel = 'Target:';
    this.targetLabel = this.targetLabel ? `${this.targetLabel} ${this.target?.toFixed(1)}` : this.targetLabel;
    /*
    Actually target bubble is not showing in a set of specific ranges of angle. So,
    Before that: if angle lies in any of those ranges, "getTextLabel" was now showing the label and bubble.
    Now: I am shifting the values from invalid range to the valid range.
    */
    targetAngle = this.setTargetRange(targetAngle);
  }

  private setAverage(): void {
    let avgAngle = this.average * (this.availableAngle / this.MAX_SCORE) + this.STARTING_ANGLE;
    this.averageCoordinates = this.describeCircle(this.centerX, this.centerY, this.ARC_RADIUS, avgAngle);
    // this.averageLabel = this.getTextLabel('Average:', avgAngle);
    this.averageLabel = 'Average:';
    this.averageLabel = this.averageLabel ? `${this.averageLabel} ${this.average.toFixed(1)}` : this.averageLabel;
    //
    avgAngle = this.setTargetRange(avgAngle);
  }

  private getScoreClass(score: number): string {
    let className = null;
    const s = score / this.MAX_SCORE;
    if (this.isBeecher) {
      switch (true) {
        case s < 0.7:
          className = 'score-low-stroke';
          this.gradientID = 'url(#gradientRed)';
          break;
        case s < 0.8:
          className = 'score-medium-stroke';
          this.gradientID = 'url(#gradientYellow)';
          break;
        case s >= 0.8:
          className = 'score-high-stroke';
          this.gradientID = 'url(#gradientGreen)';
          break;
      }
    } else {
      switch (true) {
        case s <= 0.33:
          className = 'score-low-stroke';
          this.gradientID = 'url(#gradientRed)';
          break;
        case s < 0.66:
          className = 'score-medium-stroke';
          this.gradientID = 'url(#gradientYellow)';
          break;
        case s >= 0.66:
          className = 'score-high-stroke';
          this.gradientID = 'url(#gradientGreen)';
          break;
      }
    }
    return className ? className : '';
  }

  private getBnbScoreClass(score: number): string {
    let className = null;
    const s = score / this.MAX_SCORE;
    switch (true) {
      case s <= 0.4:
        className = 'bnb-score-lowest-stroke';
        this.gradientID = 'url(#gradientBnbRed)';
        break;
      case s <= 0.6:
        className = 'bnb-score-low-stroke';
        this.gradientID = 'url(#gradientBnbOrgange)';
        break;
      case s <= 0.8:
        className = 'bnb-score-medium-stroke';
        this.gradientID = 'url(#gradientBnbGold)';
        break;
      case s < 1:
        className = 'bnb-score-high-stroke';
        this.gradientID = 'url(#gradientBnbGreen)';
        break;
      case s === 1:
        className = 'bnb-score-highest-stroke';
        this.gradientID = 'url(#gradientBnbBlue)';
        break;
    }
    return className ? className : '';
  }

  // * returns CyberArk related classes
  private getCyberArkScoreClass(score: number): string {
    let className = null;
    const s = score / this.MAX_SCORE;
    switch (true) {
      case s > 0.9:
        className = 'cyberArk-score-high-stroke';
        this.gradientID = 'url(#gradientGreen)';
        break;
      case s > 0.5:
        className = 'cyberArk-score-medium-stroke';
        this.gradientID = 'url(#gradientYellow)';
        break;
      case s >= 0:
        className = 'cyberArk-score-low-stroke';
        this.gradientID = 'url(#gradientRed)';
        break;
    }
    return className ? className : '';
  }

  private getLabelAnchor(angle): string {
    if (angle <= 70) {
      return 'start';
    } else if (angle <= 100) {
      return 'middle';
    } else if (angle <= 260) {
      return 'end';
    } else if (angle <= 280) {
      return 'middle';
    } else {
      return 'start';
    }
  }

  // private getTextLabel(label: string, angle: number): string {
  //   // I intentionally change lowest angle from 50 to 10, because our lowest value is 0/0.1 and 0.1 is equal to 36.5
  //   // which is less than 50
  //   // Also
  //   // changing first hightest angle limit of first condition from 133 to 209 to show target for lowest values
  //   // as well
  //   if ((angle >= 10 && angle <= 209) || (angle >= 230 && angle <= 315)) {
  //     return label;
  //   }
  //   return '';
  // }

  private setTargetRange(angle: number): number {
    // Comparing with the ranges in which target is not showing.
    if (angle < 10) {
      angle = 10;
    } else if (angle > 209 && angle <= 220) {
      angle = 209;
    } else if (angle > 220 && angle < 230) {
      angle = 230;
    } else if (angle > 315) {
      angle = 315;
    }

    return angle;
  }

  private polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  private describeArc(x, y, radius, startAngle, endAngle) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ');
    return d;
  }

  private describeCircle(x, y, radius, angle): Coordinates {
    const startLine = this.polarToCartesian(x, y, radius, angle);
    return { x: startLine.x, y: startLine.y };
  }

  private updateSVGViewBox(): void {
    this.svgViewBox = `${this.SVG_X_CROP} ${this.SVG_Y_CROP} ${this.svgWidth + this.SVG_WIDTH_OFFSET} ${
      this.svgHeight + this.SVG_HEIGHT_OFFSET
    }`;
  }
  performIncDecOperation(id, type, isMaturity: boolean = true) {
    this.performOperation.next({ id, type, isMaturity });
  }

  showEditNumber(isTarget = false): void {
    if (isTarget) {
      this.editNumber2 = true;
      setTimeout(() => {
        this.inputElement2.nativeElement.focus();
      }, 0);
    } else {
      this.editNumber = true;
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 0);
    }
  }

  valueCheck(value, isTarget = false): void {
    if (!UtilsService.isDefined(value) || value < 0) {
      if (isTarget) {
        this.capOrTool.target = 0;
        if (this.inputElement) {
          this.inputElement2.nativeElement.value = '0';
        }
      } else {
        this.capOrTool.maturity = 0;
        if (this.inputElement) {
          this.inputElement.nativeElement.value = '0';
        }
      }
    } else {
      if (value > 5) {
        value = 5;
      }
      if (Math.ceil(value % 1)) {
        const gf = value + '';
        const decimalPartArray = gf.toString().split('.');
        setTimeout(() => {
          let toEmit;
          if (decimalPartArray[1].length > 1) {
            toEmit = parseFloat(decimalPartArray[0] + '.' + decimalPartArray[1].slice(0, 1));
          } else if (decimalPartArray[1].length === 1) {
            toEmit = parseFloat(decimalPartArray[0] + '.' + decimalPartArray[1]);
          }
          if (isTarget) {
            this.capOrTool.target = toEmit;
          } else {
            this.capOrTool.maturity = toEmit;
          }
        }, 0);
      } else {
        if (isTarget) {
          if (this.inputElement2) {
            this.inputElement2.nativeElement.value = typeof value === 'string' ? value : JSON.stringify(value);
          }
          this.capOrTool.target = value;
        } else {
          if (this.inputElement) {
            this.inputElement.nativeElement.value = typeof value === 'string' ? value : JSON.stringify(value);
          }
          this.capOrTool.maturity = value;
        }
      }
    }
  }
}
