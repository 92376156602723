import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LargeNumberEnum } from 'app/shared/enums/largeNumber.enum';
import { UtilsService } from 'app/shared/utils.service';

/**
 * Mandatory value to pass
 * @valueDigit
 * All other value are optional
 */

@Component({
  selector: 'cygov-large-number-input',
  templateUrl: './large-number-input.component.html',
  styleUrls: ['./large-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LargeNumberInputComponent,
      multi: true,
    },
  ],
})
export class LargeNumberInputComponent implements OnInit, ControlValueAccessor, OnChanges {
  @ViewChild('numberInputTag', { static: false }) inputElement: ElementRef;
  @Input() stepJump = 1; // Value to jump when pressing arrow buttons increment or decrement.
  // By default it is 1, but it will automatically goto 1M - If you want it to be fixed, pass "smallValue" as true.
  @Input() appendOnLeft = ''; // To Append a string on the left side of the value - passing empty will show nothing
  @Input() appendOnRight = ''; // To Append a string on the left side of the value - passing empty will show nothing
  @Input() valueDigit: number | string = 0; // Value that holds the data
  @Input() fontSize = 1.2; // the size only affects the text, you have to manually change the arrow size through NG-Deep
  @Input() editable = true; // To only show the value and restrict user to not change it.
  @Input() useStaticStep = false; // Passing value as "True" will fix the Step Jump to 1 value, by default 1000 or any other passed value.
  @Input() bold = false; // This bool decides if the data should be kept bold or not.
  @Input() fontColor = ''; // pass font color or by default it is smoky color.
  @Input() minVal = -1;
  @Input() maxVal = -1;
  @Input() decimalRestriction = false; // this bool decided either to apply decimal restriction of max two digit or not
  @Output() valueDigitChange: EventEmitter<any> = new EventEmitter<any>(); // Value that is being change will emit.
  @Input() restrictFloat: number = 1; // pass 0 to hide all floating point - 1-9 to show the number of decimals
  @Output() inputFocusOut: EventEmitter<any> = new EventEmitter<any>(); // get the Value latest value once the focus out on input
  @Output() inputFocusIn: EventEmitter<any> = new EventEmitter<any>(); // get the Value latest value once the focus out on input
  @Input() decimalCount = 2;
  @Input() originalValue = false;
  @Input() multiEntityEdit = false;
  @Input() isLocked = false;
  @Input() largeArrow = false;
  @Input() showUpDownArrrow = true;
  @Input() showDays = false; // this check will append the string day or days with value
  maxLimit: number = 100;

  // For new logic ControlValueAccessor
  @Input() useControlValueAccessor = false; // enable it to use ControlValueAccessor
  // we need this func for change detect and pass new value to parent reactive form
  // with help of registerOnChange func
  onChange: (value: number | string) => void;
  // end

  editNumber = false;
  initialValue = 0;

  constructor() {}

  ngOnInit() {
    this.valueDigit = typeof this.valueDigit === 'number' ? this.valueDigit : +this.valueDigit;
    this.initialValue = this.valueDigit;
    this.updateStepJumpValue(this.valueDigit);
  }
  ngOnChanges(changes: SimpleChanges) {
    // This if handles updating value when maxVal or minVal is changed from parent
    if ((changes.maxVal && changes.maxVal.previousValue) || (changes.minVal && changes.minVal.previousValue)) {
      this.valueCheck(this.valueDigit);
    }
  }

  showEditNumber(): void {
    if (this.editable) {
      this.editNumber = true;
      if (+this.valueDigit < 0) {
        this.valueDigitChange.emit((this.valueDigit = 0));
        this.inputElement.nativeElement.value = '0';
        this.initialValue = 0;
      }
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 0);
    }
  }

  valueCheck(value, isStepCounter = false): void {
    if (!isStepCounter) {
      if (this.minVal > -1 && value < this.minVal) {
        this.valueDigit = value = this.minVal;
      }
      if (this.maxVal > -1 && value > this.maxVal) {
        this.valueDigit = value = this.maxVal;
      }
    }
    if (this.initialValue < 0) {
      this.initialValue = value;
      if (isStepCounter) {
        value = value + 1;
      }
    }
    if (!UtilsService.isDefined(value) || value < 0) {
      const modified = value < -1 ? Math.abs(value % 10) : 0;
      if (value < 0) {
        setTimeout(() => {
          this.valueDigit = modified;
        }, 0);
      } else {
        this.inputElement.nativeElement.value = '';
      }
      this.valueDigitChange.emit(modified);
    } else {
      if (this.decimalRestriction && value % 1) {
        if (value > this.maxLimit) {
          this.valueDigit = this.maxLimit;
          this.valueDigitChange.emit(this.valueDigit);
        } else {
          const decimalPartArray = this.valueDigit.toString().split('.');
          setTimeout(() => {
            if (decimalPartArray[1].length > this.decimalCount) {
              const toEmit = parseFloat(decimalPartArray[0] + '.' + decimalPartArray[1].slice(0, this.decimalCount));
              this.valueDigit = toEmit;
            }
          }, 0);
          this.valueDigitChange.emit(this.valueDigit);
        }
      } else {
        this.inputElement.nativeElement.value = typeof value === 'string' ? value : JSON.stringify(value);
        this.valueDigitChange.emit(value);
      }
      this.updateStepJumpValue(value);
    }
    if (this.useControlValueAccessor) {
      setTimeout(() => this.setValue(), 50);
    }
  }

  updateStepJumpValue(value: number): void {
    if (!this.useStaticStep) {
      if (value >= LargeNumberEnum.BILLION && this.stepJump !== LargeNumberEnum.BILLION) {
        this.stepJump = LargeNumberEnum.BILLION;
      } else if (
        value >= LargeNumberEnum.MILLION &&
        value < LargeNumberEnum.BILLION &&
        this.stepJump !== LargeNumberEnum.MILLION
      ) {
        this.stepJump = LargeNumberEnum.MILLION;
      } else if (
        value >= LargeNumberEnum.THOUSAND &&
        value < LargeNumberEnum.MILLION &&
        this.stepJump !== LargeNumberEnum.THOUSAND
      ) {
        this.stepJump = LargeNumberEnum.THOUSAND;
      } else if (value < LargeNumberEnum.THOUSAND) {
        this.stepJump = 1;
      }
    }
  }

  stepper(isIncrease: boolean): void {
    if (typeof this.valueDigit === 'string') {
      this.valueDigit = parseInt(this.valueDigit, 10);
    }
    if (isIncrease) {
      if (this.maxVal > -1 && this.valueDigit + this.stepJump >= this.maxVal) {
        this.valueCheck((this.valueDigit = this.maxVal), true);
      } else {
        this.valueCheck((this.valueDigit = this.valueDigit + this.stepJump), true);
      }
    } else {
      if (this.minVal > -1 && this.valueDigit - this.stepJump <= this.minVal) {
        this.valueCheck((this.valueDigit = this.minVal), true);
      } else {
        this.valueCheck(
          this.valueDigit - this.stepJump > 0
            ? (this.valueDigit = this.valueDigit - this.stepJump)
            : (this.valueDigit = 0),
          true
        );
      }
    }
    this.inputFocusOut.emit({ focus: false, value: this.valueDigit });
  }

  setValue(): void {
    const value = this.inputElement.nativeElement.value;
    this.valueDigit = value ? parseInt(value, 10) : 0;
    this.onChange(this.valueDigit);
  }
  // Below funcs for CVA interface

  // this func write values on required field
  writeValue(obj: any): void {
    // console.log('EValue: ', obj);
    this.valueDigit = obj;
  }
  // this func detech change and pass it to parent reactive form
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  // this func detech touch and pass it to parent reactive form
  // dont need it right now but needed for CVA interface requirement
  registerOnTouched(fn: any): void {}
  // end

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }
  // Method to determine the correct suffix
  getDaySuffix(value: number): string {
    return value > 1 ? 'days' : 'day';
  }
}
