<div class="modal-container">
    <div class="header">
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="closeModal()">
        </div>
    </div>
    <div class="content">
        <div class="logo-icon" cygovSvgIcon [svgName]="'centraleyes-logo1'"></div>
        <div class="lato-20-b-vw heading">Welcome To Centraleyes</div>
        <div class="lato-20-n-vw text">
            Before you get started, we just wanted to show you where our training center and video
            icon is located.
        </div>
    </div>
</div>