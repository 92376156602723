import { PipeTransform, Pipe } from '@angular/core';
/*
 * Appends Pre Zeros before numbers like 001, 021
 * Takes a number as a value, shows it in the form of Hundred or Thousand.
 * Takes an input for maximum number of zeros
 * Usage:
 *  value | appendPreZeros : limit (Number)
 */
@Pipe({ name: 'appendPreZeros' })
export class AppendPreZeros implements PipeTransform {
  transform(value: number, limit: number = 2) {
    let base = 1;
    let next = 10;
    for (let i = 0; i < limit; i++) {
      if (value >= base && value < next) {
        return '0'.repeat(limit - i) + value;
      }
      // 1st time it will compare 1 and 10, then 10 and 100 and so on
      base = base * 10;
      next = next * 10;
    }
    return value;
  }
}
