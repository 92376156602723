import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

interface ICheckPoints {
  score: number;
  position: number;
  label: string;
}
@Component({
  selector: 'cygov-slider',
  templateUrl: './cygov-slider.component.html',
  styleUrls: ['./cygov-slider.component.scss'],
})
export class CygovSliderComponent implements OnInit, AfterViewInit, OnChanges {
  /**
   * Custom Slider with Colored progress bar
   */
  @Input() minValue = 0; // Starting range of slider, it is by default 0
  @Input() maxValue = 100; // End range of slider, it is by default 100
  @Input() leftLabel = ''; // customizable left side label
  @Input() rightLabel = ''; // customizable right side label
  @Input() isDisable = false;
  @Input() incrementStep = 1;
  @Input() showRanges = true; // Optional to show ranges of slider
  @Input() sliderValue = -1; // Initially the slider value will be -1 , will change it to MinValue later in NgOnInit
  @Input() gradient = []; // Initially the slider won't have any gradient
  @Input() checkPoints: boolean = false; // Initially the slider won't have any check point to show
  @Input() bluePointer = false; // Initially the slider pointer will have haiti color
  @Input() appendThumbVal = ''; // add the value on top of thumb,pass post string attachment
  @Input() moveableThumbLabel = false;
  @Input() moveableSignValue = '';
  @Input() idName = ''; // an id for the slider input
  @Input() isVersionClicked = false;
  @Input() disableInitialCalc: boolean = false;
  @Input() isFromRisk: boolean = false;
  @Input() isFromAIRisk: boolean = false;
  @Output() sliderValueChange = new EventEmitter<number>();
  @ViewChild('customRange') range: ElementRef;

  progressValue = -1; // Internal Variable to show the color portion of slider.
  checkPointsPos: ICheckPoints[] = [];
  backgroundGradient = ''; // background gradient to apply.
  thumbVal = 0;

  constructor() {}

  ngOnInit() {
    // Calculate the slider value if initially a value is not passed.

    if (!this.disableInitialCalc) {
      const limitValue =
        this.sliderValue > this.maxValue
          ? this.maxValue
          : this.sliderValue < 0 || this.sliderValue < this.minValue
            ? this.minValue
            : this.sliderValue;

      if (limitValue !== this.sliderValue) {
        if (this.sliderValue === -1) {
          return;
        }
        this.changeSliderValue(limitValue);
      }
    }

    if (this.gradient.length) {
      this.backgroundGradient = `linear-gradient(to right, ${this.gradient[0]}, ${this.gradient[1]})`;
    }
    if (this.appendThumbVal) {
      this.thumbVal = (this.minValue + this.maxValue) / 2;
    }
  }

  ngAfterViewInit() {
    // const rangeWidth = this.range.nativeElement.clientWidth;
    // commenting the code below as we are not using the dynamic positioning points
    // this.checkPoints.forEach(point => {
    //   const scorePercent = (100 * point.score) / this.maxValue;
    //   const pointPos = (100 * scorePercent) / rangeWidth;
    //   this.checkPointsPos.push({
    //     score: point.score ? point.score : '0', // score appears above the pin point on slider
    //     position: pointPos ? pointPos : 0, // position of pins on slider
    //     label: point.label ? point.label : '', // label under the slider
    //   });
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Adjust the slider if one of the ranges is dynamically changed
    if (changes.minValue && !changes.minValue.firstChange && this.sliderValue < changes.minValue.currentValue) {
      // Adjust Slider if minValue is changed.
      this.changeSliderValue(changes.minValue.currentValue);
    }
    if (changes.maxValue && !changes.maxValue.firstChange && this.sliderValue > changes.maxValue.currentValue) {
      // Adjust Slider if maxValue is changed.
      this.changeSliderValue(changes.maxValue.currentValue);
    }
    if (changes.minValue || changes.maxValue) {
      setTimeout(() => {
        // Adjust color progress area of slider.
        this.changeToPercent(this.sliderValue, true);
      }, 0);
    }
  }

  changeSliderValue(newValue: number): void {
    // An emitter for slider value change.
    setTimeout(() => {
      this.sliderValueChange.emit(newValue);
    }, 0);
  }
  sliderClick() {
    if (this.progressValue < 0 || this.thumbVal < 0) {
      this.progressValue = 0;
      this.thumbVal = this.minValue * 25;
      this.sliderValueChange.emit(this.minValue);
    }
  }
  changeToPercent(actualValue, valueChanged = false): void {
    // Changing the slider value to percent for the progress area width to change with it.
    this.progressValue = ((actualValue - this.minValue) / (this.maxValue - this.minValue)) * (100 - 0) + 0;
    this.thumbVal = actualValue * 25;
    if (!valueChanged) {
      this.sliderValueChange.emit(actualValue);
    }
  }
}
