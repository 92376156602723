/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CollectionService } from '../collection.service';
import { Router } from '@angular/router';
import { CUSTOMAPIService, RoleEnum } from 'app/custom-api.service';
import { UserService } from 'app/shared/user.service';
import { EntityService } from 'app/shared/entity.service';
import { APIService, UpdateAssessmentStandardFrameworkInput } from 'app/API.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cygov-reviewers-modal',
  templateUrl: './reviewers-modal.component.html',
  styleUrls: ['./reviewers-modal.component.scss'],
})
export class ReviewerModalComponent implements OnInit {
  @Input() framework: any;
  @Input() frameworkName: any;
  @Input() preSelectedUsers: any[] = [];
  @Input() requestComingFromScreen: string = 'collectionQuestionCard';
  @Input() atleastOneReviewerRequired: boolean = false;
  @Input() rootEntityId: any = null;
  @Output() reviewersEdited: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedManagers: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() closeModal: EventEmitter<any[]> = new EventEmitter<any[]>();

  loading = false;
  managersList = [];
  usersList = [];
  filteredUserList = [];
  cachedUserList = [];
  managerIds = [];
  oldManagerIds = [];
  currentManagers = [];
  isThirdParty = false;
  subEntityId = null;
  searchManagerText = '';
  assessment = null;
  assessmentStandardFramework = null;

  constructor(
    public activeModal: NgbActiveModal,
    private collectionService: CollectionService,
    private userService: UserService,
    private entityService: EntityService,
    private customApi: CUSTOMAPIService,
    private apiService: APIService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.isThirdParty = this.router.url.includes('third-party');

    if (this.framework) {
      this.rootEntityId = this.framework?.rootId;
      this.subEntityId = this.framework?.childId;

      this.assessment = await this.customApi.GetAssessment(this.framework.assessmentId);

      // get assessmentStandardFramework to get managerId
      this.assessmentStandardFramework = this.assessment.standardFrameworkList.items.find(item => {
        return item.key === this.frameworkName;
      });
    }

    this.usersList = await this.getAllUserList();

    this.managersList =
      (this.preSelectedUsers?.length && this.preSelectedUsers) ||
      this.collectionService.getManagersList(this.subEntityId);

    this.currentManagers = this.getSelectedManager(this.usersList, this.managersList);

    this.initializeManagerUsersList();

    this.loading = false;
  }

  higherRoleReviewers = [];

  getSelectedManager(users: any[], managers: any[]): any {
    this.managerIds = managers.map(manager => manager.id);
    this.oldManagerIds = JSON.parse(JSON.stringify(this.managerIds));
    // return users.filter(user => this.managerIds.includes(user?.id));
    return managers.filter(reviewer => {
      const reviewerExists = users.some(user => user.id === reviewer?.id);
      if (reviewerExists) {
        return true;
      } else {
        this.higherRoleReviewers.push(reviewer);
      }
    });
  }

  async getAllUserList(): Promise<any> {
    // if (this.requestComingFromScreen === 'addNewEntity') {
    //   return await this.userService.getUsersByDomain();
    // }
    const subentityList = this.subEntityId ? [this.subEntityId] : [];
    return await this.collectionService.getUsers(this.rootEntityId, subentityList, true);
  }

  initializeManagerUsersList(): void {
    // this.currentManagers = { checked: true, ...this.currentManagers };
    const check = this.userService.returnHelperFunction();
    this.filteredUserList = [];

    this.usersList.forEach(user => {
      if (this.requestComingFromScreen !== 'addNewEntity') {
        if (
          this.isThirdParty ||
          check(user, RoleEnum.ADMIN) ||
          check(user, RoleEnum.MSSP) ||
          (!check(user, RoleEnum.PARTICIPANT) &&
            !check(user, RoleEnum.VENDOR) &&
            // checks if user has entity ids, if exists then checks the subIds
            (user?.entityIds?.includes(this.rootEntityId) || user?.entityIds?.includes(this.subEntityId)))
        ) {
          const userObj = Object.assign({ checked: false }, user);
          if (this.managerIds?.includes(user?.id)) {
            userObj.checked = true;
          }
          this.filteredUserList.push(userObj);
        }
      } else if (this.requestComingFromScreen === 'addNewEntity') {
        const userObj = Object.assign({ checked: false }, user);
        if (this.managerIds?.includes(user?.id)) {
          userObj.checked = true;
        }
        this.filteredUserList.push(userObj);
      }
    });

    this.sortSelectedUsers();
    this.cachedUserList = this.filteredUserList;
  }

  sortSelectedUsers() {
    this.filteredUserList = this.filteredUserList.sort((a, b) => {
      // First, sort based on the checked property
      if (a.checked && !b.checked) {
        return -1; // 'a' comes before 'b'
      } else if (!a.checked && b.checked) {
        return 1; // 'b' comes before 'a'
      } else {
        // If checked property is the same, sort based on name in ascending order

        return a.name.localeCompare(b.name);
      }
    });
  }

  searchManager(): void {
    if (this.searchManagerText === '') {
      this.filteredUserList = this.cachedUserList;
    } else {
      this.filteredUserList = this.cachedUserList.filter(user => {
        return (
          user.name.toLowerCase().includes(this.searchManagerText.toLowerCase()) ||
          user.email.toLowerCase().includes(this.searchManagerText.toLowerCase())
        );
      });
      this.sortSelectedUsers();
    }
  }

  changeSelectedManager(user: any, event, index): void {
    // if (this.atleastOneReviewerRequired && !event && this.managerIds.length === 1) {
    //   this.filteredUserList[index].isDisabled = true;
    //   return;
    // }
    // console.clear();
    if (this.managerIds.includes(user?.id)) {
      this.managerIds.splice(this.managerIds.indexOf(user?.id), 1);
      this.currentManagers.splice(
        this.currentManagers.findIndex(manager => manager?.id === user?.id),
        1
      );
    } else {
      this.managerIds.push(user?.id);
      this.currentManagers.unshift(user);
    }

    this.filteredUserList.forEach(userItem => {
      if (this.managerIds.includes(userItem?.id)) {
        userItem.checked = true;
      } else {
        userItem.checked = false;
      }
    });
  }

  closeModalFunction(): void {
    this.activeModal.close();
    this.closeModal.emit();
  }

  async addReviewers(): Promise<void> {
    if (this.assessmentStandardFramework?.defaultQuestionSettings?.isApprovalRequired && !this.currentManagers.length) {
      this.toastr.error('Please select atleast one reviewer');
      return;
    }
    if (this.requestComingFromScreen === 'addFramework' || this.requestComingFromScreen === 'addNewEntity') {
      this.activeModal.close();
      this.selectedManagers.emit(this.currentManagers);
      return;
    }
    this.loading = true;
    // Remove __typename property
    delete this.assessmentStandardFramework.timeline.__typename;

    // Update standard framework
    const params: UpdateAssessmentStandardFrameworkInput = {
      id: this.assessmentStandardFramework.id,
      managerId: this.currentManagers.length && this.currentManagers[0].id,
      timeline: this.assessmentStandardFramework.timeline,
      assessmentId: this.assessmentStandardFramework.assessmentId,
    };

    await this.customApi.UpdateAssessmentStandardFramework(params);

    // Check for new Managers to Assign
    const newManagersList = this.currentManagers.filter(manager => !this.oldManagerIds.includes(manager?.id));

    // Check for old Managers to remove
    const deleteManagersList = this.oldManagerIds.filter(managerId => !this.managerIds.includes(managerId));

    const frameworkRange = await this.entityService.getFrameworkRange(this.frameworkName);

    // Assign new managers
    const createAssignPromises = newManagersList.map(manager => {
      const managerDetails = {
        assessmentId: this.framework.assessmentId,
        standardFrameworkId: this.assessmentStandardFramework.id,
        managerId: manager?.id,
        left: frameworkRange.left,
        right: frameworkRange.right,
      };

      return Promise.all([this.collectionService.assignNewFrameworkManager(managerDetails)]);
    });

    // Remove old managers
    const removeAssignPromises = deleteManagersList.map(managerId => {
      return Promise.all([
        this.collectionService.deleteFrameworkManager(
          {
            id: `${this.assessmentStandardFramework.id}#${managerId}`,
          },
          this.framework.assessmentId
        ),
      ]);
    });

    // Execute promises
    await Promise.all([...createAssignPromises.flat(), ...removeAssignPromises.flat()] as unknown as Promise<void>[]);
    this.activeModal.close();
    if (createAssignPromises.length || removeAssignPromises.length) {
      this.selectedManagers.emit([...this.currentManagers, ...this.higherRoleReviewers]);
    }
  }
}
