import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FrameworkLevelBackend, LEVELS } from 'app/shared/enums/frameworksLevels.enum';
import { DomainFrameworkService } from '../domain-framework.service';

@Pipe({
  name: 'vendorFrameworkName'
})

@Injectable({
  providedIn: 'root' //* Makes the pipe available across the app as an injectable service
})
export class VendorFrameworkNamePipe implements PipeTransform {

  constructor(private domainFrameworkService: DomainFrameworkService) {}

  /**
   * * Pipe Transforms and returns the required name for vendor risks
   * @param value Framework Name
   * @param _level Boolean to indicate to remove level or not 
   * @returns respected Framework name
   */
  transform(value: string, _level: boolean = false): string | null {
    if (!value) {
      return null;
    }
    
    const [rawValue] = value.split('#');
    const frameworkEnum = this.domainFrameworkService.getFrameworkKey(rawValue);
    const enumKey = this.getEnumKey(FrameworkLevelBackend, frameworkEnum) || rawValue;
    const formattedKey = enumKey.replace(/_/g, ' ');
    
    return _level ? this.removeLevel(formattedKey) : formattedKey;
  }
  

  /**
   * * Removes the LEVEL from framework name
   * @param val input value
   * @returns returns value after level has been removed from string
   */
  private removeLevel(val: string): string {
    LEVELS.forEach(level => {
      const regex = new RegExp(level, 'g'); //* Create a global regex to match each level
      val = val.replace(regex, ''); //* Replace the matching level with an empty string
    });
  
    return val.trim(); //* trimmed to remove extra spaces
  }
  
  /**
   * *Function return enum key based on value 
   * @param enumClass ENUM OBJECT
   * @param value value 
   * @returns ENUM KEY
   */
  private getEnumKey(enumClass: any, value: string | number): string | null {
    const index = Object.values(enumClass).indexOf(value);
    return index !== -1 ? Object.keys(enumClass)[index] : null;
  }
  
}
