import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'cygov-average-box',
  templateUrl: './average-box.component.html',
  styleUrls: ['./average-box.component.scss'],
})
export class AverageBoxComponent implements OnInit {
  @ViewChild('averageBox') averageBox: ElementRef;
  @Input() average: number = 0;
  @Input() total: number = 10;
  SVG_BOX_HEIGHT: number;
  averageBoxPath: string;
  TEXT_START_Y: number;
  className: string = '';
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.SVG_BOX_HEIGHT = this.averageBox.nativeElement.offsetHeight;
      this.averageBoxPath = `M 0 0 L 140 0 L 120 ${this.SVG_BOX_HEIGHT / 2} L 140 ${this.SVG_BOX_HEIGHT} L 0 ${
        this.SVG_BOX_HEIGHT
      } L 20 ${this.SVG_BOX_HEIGHT / 2} z`;
      this.TEXT_START_Y = this.SVG_BOX_HEIGHT / 2 + 6;
    }, 0);
    this.getScoreClass();
  }
  getScoreClass(): void {
    switch (true) {
      case this.average <= 3.33:
        this.className = 'low-stroke';
        break;
      case this.average < 6.66:
        this.className = 'medium-stroke';
        break;
      case this.average >= 7.66:
        this.className = 'high-stroke';
        break;
    }
  }
}
